import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { loadLine } from '../../redux/epack/lines/services';
import { resetLine } from '../../redux/epack/lines/actions';
import { getPalletsIn, getPalletsOut } from '../../redux/epack/lines/selectors';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Paper,
  TextField,
  Tab,
  Tabs,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Empty from '../../components/UI/Empty';
import TabPanel from '../../components/UI/TabPanel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UndoIcon from '@mui/icons-material/Undo';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PalletInIcon from '../../components/UI/icons/PalletInIcon';
import PalletOutIcon from '../../components/UI/icons/PalletOutIcon';
import ClipboardInflowTable from '../../components/epack/ClipboardInflowTable';
import ClipboardOutflowTable from '../../components/epack/ClipboardOutflowTable';
import ClipboardPhotoList from '../../components/epack/ClipboardPhotoList';
import Loading from '../../components/UI/Loading';
import isEmpty from 'is-empty';

const StyledAccordion = styled((props) => (
  <Accordion
    disableGutters
    elevation={0}
    square
    variant='outlined'
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '& .MuiAccordionDetails-root': {
    padding: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-root.Mui-expanded ': {
    minHeight: '48px',
  },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const ProductionLine = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [activeSanitationTab, setActiveSanitationTab] = useState(0);
  const linesState = useSelector((state) => state.lines);
  const PalletsIn = useSelector(getPalletsIn);
  const PalletsOut = useSelector(getPalletsOut);
  const productionLine = useSelector((state) => state.lines.line);

  const handleChangeTab = (event, tab) => {
    setActiveTab(tab);
  };

  const handleChangeSanitationTab = (event, tab) => {
    setActiveSanitationTab(tab);
  };

  useEffect(() => {
    dispatch(loadLine(id));
    return () => {
      dispatch(resetLine());
    };
  }, [dispatch, id]);

  return (
    <>
      <Loading loading={linesState.lineLoading} />
      {!isEmpty(linesState.line) && !linesState.lineLoading && (
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box
              display='flex'
              my={1.5}
              ml={1.5}
              flexGrow={1}
              alignItems='center'
            >
              <AssignmentIcon color='primary' />
              <Typography variant='h6' color='primary' sx={{ marginLeft: 1 }}>
                ePack - Production Line
              </Typography>
            </Box>
          </Box>
          <Divider variant='middle' light />
          <Box component='div' mx={1} my={0.5} mt={1.5}>
            <Box display='flex'>
              <Box>
                <TextField
                  label='Machine'
                  fullWidth
                  disabled
                  value={productionLine.machine.name}
                />
              </Box>
              <Box ml={1}>
                <TextField
                  label='Work Order'
                  fullWidth
                  disabled
                  value={productionLine.workOrder.code}
                />
              </Box>
              <Box ml={1}>
                <TextField
                  label='Commodity'
                  fullWidth
                  disabled
                  value={productionLine.workOrder.commodity.name}
                />
              </Box>
              <Box ml={1}>
                <TextField
                  label='Item Description'
                  disabled
                  value={productionLine.itemDescription}
                  sx={{ width: '250px' }}
                />
              </Box>
              <Box ml={1}>
                <TextField
                  label='Pallet In'
                  disabled
                  sx={{ width: '100px' }}
                  value={PalletsIn.length}
                />
              </Box>
              <Box ml={1}>
                <TextField
                  label='Pallet Out'
                  disabled
                  value={PalletsOut.length}
                  sx={{ width: '100px' }}
                />
              </Box>
            </Box>
          </Box>
          <Box component='div' mx={1} my={0.5}>
            <Tabs value={activeTab} onChange={handleChangeTab}>
              <Tab label='Details' value={0} />
              <Tab label='Photos' value={1} />
              <Tab label='Setup' value={2} />
              <Tab label='Sanitation' value={3} />
            </Tabs>
            <TabPanel value={activeTab} index={0}>
              <Box component='div' my={1}>
                <StyledAccordion
                  defaultExpanded
                  sx={{
                    '& .MuiAccordionDetails-root': {
                      padding: 0,
                    },
                    '& .MuiAccordionSummary-content': {
                      margin: 0,
                    },
                    '& .MuiAccordionSummary-root.Mui-expanded ': {
                      minHeight: '48px',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <Box display='flex' alignItems='center'>
                      <PalletInIcon color='primary' />
                      <Typography
                        variant='h6'
                        color='primary'
                        sx={{ marginLeft: 1 }}
                      >
                        Inflow
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {isEmpty(PalletsIn) ? (
                      <Empty description='No pallets to show' size='small' />
                    ) : (
                      <ClipboardInflowTable rows={PalletsIn} readOnly />
                    )}
                  </AccordionDetails>
                </StyledAccordion>
              </Box>
              <Box component='div' my={0.5}>
                <StyledAccordion
                  defaultExpanded
                  sx={{
                    '& .MuiAccordionDetails-root': {
                      padding: 0,
                    },
                    '& .MuiAccordionSummary-content': {
                      margin: 0,
                    },
                    '& .MuiAccordionSummary-root.Mui-expanded ': {
                      minHeight: '48px',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <Box display='flex' alignItems='center'>
                      <PalletOutIcon color='primary' />
                      <Typography
                        variant='h6'
                        color='primary'
                        sx={{ marginLeft: 1 }}
                      >
                        Outflow
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {isEmpty(PalletsOut) ? (
                      <Empty description='No pallets to show' size='small' />
                    ) : (
                      <ClipboardOutflowTable rows={PalletsOut} readOnly />
                    )}
                  </AccordionDetails>
                </StyledAccordion>
              </Box>
              <Box component='div' my={0.5} display='flex'>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Card
                      elevation={0}
                      variant='outlined'
                      sx={{
                        '& .MuiCardHeader-root': {
                          padding: 0,
                          paddingTop: 1,
                          paddingBottom: 1,
                          paddingLeft: 1,
                        },
                      }}
                    >
                      <CardHeader
                        subheader={
                          <Typography variant='h6' color='primary'>
                            Cat 2
                          </Typography>
                        }
                      />
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <TextField
                              label='Full Pallet Weight (lbs)'
                              type='number'
                              fullWidth
                              disabled
                              value={
                                isEmpty(productionLine.cat2?.fullWeight)
                                  ? 0
                                  : productionLine.cat2.fullWeight
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label='Pallet Weight (lbs)'
                              type='number'
                              fullWidth
                              disabled
                              value={
                                isEmpty(productionLine.cat2?.binWeight)
                                  ? 0
                                  : productionLine.cat2?.binWeight
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label='Number Of Cases'
                              type='number'
                              fullWidth
                              disabled
                              value={
                                isEmpty(productionLine.cat2?.cases)
                                  ? 0
                                  : productionLine.cat2.cases
                              }
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={6}>
                    <Card
                      elevation={0}
                      variant='outlined'
                      sx={{
                        '& .MuiCardHeader-root': {
                          padding: 0,
                          paddingTop: 1,
                          paddingBottom: 1,
                          paddingLeft: 1,
                        },
                      }}
                    >
                      <CardHeader
                        subheader={
                          <Typography variant='h6' color='primary'>
                            Grade Out
                          </Typography>
                        }
                      />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <TextField
                              label='Full Bin Weight (lbs)'
                              type='number'
                              fullWidth
                              disabled
                              value={
                                isEmpty(productionLine.gradeOut?.fullWeight)
                                  ? 0
                                  : productionLine.gradeOut.fullWeight
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label='Bin Weight (lbs)'
                              type='number'
                              fullWidth
                              disabled
                              value={
                                isEmpty(productionLine.gradeOut?.binWeight)
                                  ? 0
                                  : productionLine.gradeOut.binWeight
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label='Product Total'
                              type='number'
                              fullWidth
                              disabled
                              value={
                                isEmpty(productionLine.gradeOut?.fullWeight) &&
                                isEmpty(productionLine.gradeOut?.binWeight)
                                  ? 0
                                  : productionLine.gradeOut?.fullWeight -
                                    productionLine.gradeOut?.binWeight
                              }
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <Box component='div' my={1}>
                {!isEmpty(productionLine.photos) ? (
                  <ClipboardPhotoList photos={productionLine.photos} readOnly />
                ) : (
                  <Empty description='No photos available for this line' />
                )}
              </Box>
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <Box component='div' my={1}>
                {!isEmpty(productionLine.setupCheckListItems) ? (
                  <Paper elevation={0} variant='outlined'>
                    <List>
                      {productionLine.setupCheckListItems.map((item, index) => {
                        const labelId = `checkbox-list-label-${item.name}`;

                        return (
                          <ListItem key={index} role={undefined} dense button>
                            <ListItemIcon>
                              <Checkbox
                                edge='start'
                                checked={item.active}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={item.name} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Paper>
                ) : (
                  <Empty description='No setup items for this line' />
                )}
              </Box>
            </TabPanel>
            <TabPanel value={activeTab} index={3}>
              <Box component='div' mx={1} my={0.5}>
                <Tabs
                  value={activeSanitationTab}
                  onChange={handleChangeSanitationTab}
                  variant='fullWidth'
                >
                  <Tab label='Activities' />
                  <Tab label='Test & Results' />
                </Tabs>
                <TabPanel
                  value={activeSanitationTab}
                  index={0}
                  sx={{ display: 'flex' }}
                >
                  {!isEmpty(productionLine.sanitation) ? (
                    <Paper
                      elevation={0}
                      variant='outlined'
                      sx={{ marginTop: 1 }}
                    >
                      <List>
                        {productionLine.sanitation?.activities.map(
                          (activity, index) => {
                            return (
                              <ListItem key={index} role={undefined} dense>
                                <ListItemText
                                  primary={`${index + 1} - ${activity}`}
                                />
                              </ListItem>
                            );
                          }
                        )}
                      </List>
                    </Paper>
                  ) : (
                    <Empty description='No activities to display' />
                  )}
                </TabPanel>
                <TabPanel value={activeSanitationTab} index={1}>
                  <Grid container spacing={3} sx={{ marginTop: 1 }}>
                    <Grid item xs={12}>
                      <TextField
                        disabled
                        fullWidth
                        label='Test'
                        value={productionLine.sanitation?.test}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        label='Results'
                        disabled
                        value={productionLine.sanitation?.results}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              </Box>
            </TabPanel>
          </Box>
          <Box component='div' mx={1} my={0.5}>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UndoIcon />}
                onClick={() => {
                  navigate(`/epack/lines`);
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
};
export default ProductionLine;
