export const SET_JOB_TYPE = 'SET_JOB_TYPE';
export const JOB_TYPE_LOADING = 'JOB_TYPE_LOADING';
export const JOB_TYPE_RESET = 'JOB_TYPE_RESET';
export const SET_JOB_TYPES = 'SET_JOB_TYPES';
export const JOB_TYPE_LIST_LOADING = 'JOB_TYPE_LIST_LOADING';
export const JOB_TYPE_LIST_RESET = 'JOB_TYPE_LIST_RESET';
export const ADD_JOB_TYPE_TO_LIST = 'ADD_JOB_TYPE_TO_LIST';
export const UPDATE_JOB_TYPE_IN_LIST = 'UPDATE_JOB_TYPE_IN_LIST';
export const DELETE_JOB_TYPE_FROM_LIST = 'DELETE_JOB_TYPE_FROM_LIST';
export const SHOW_ADD_EDIT_JOB_TYPE_FORM = 'SHOW_ADD_EDIT_JOB_TYPE_FORM';
export const SAVING_JOB_TYPE_DATA = 'SAVING_JOB_TYPE_DATA';
