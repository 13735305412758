import React from 'react';
import { Outlet } from 'react-router-dom';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Container } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginTop: 42,
  },
}));

const Main = ({ favoritesOpen }) => {
  const classes = useStyles();

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: favoritesOpen,
      })}
    >
      <Box sx={{ minHeight: '42px' }} />
      <Container maxWidth='lg' disableGutters>
        <Outlet />
      </Container>
    </main>
  );
};

export default Main;
