import {
  SET_LABOR_JOB_VIEW_LIST,
  LABOR_JOB_VIEW_LIST_LOADING,
  LABOR_JOB_VIEW_LIST_RESET,
  LABOR_JOB_VIEW_UPDATE_ITEM,
  LABOR_JOB_VIEW_REMOVE_ITEM,
} from './types';

export const setJobViewList = (list) => {
  return {
    type: SET_LABOR_JOB_VIEW_LIST,
    payload: list,
  };
};

export const resetJobViewList = () => {
  return {
    type: LABOR_JOB_VIEW_LIST_RESET,
  };
};

export const setJobViewListLoading = (payload) => {
  return {
    type: LABOR_JOB_VIEW_LIST_LOADING,
    payload,
  };
};

export const updateJobViewItem = (payload) => {
  return {
    type: LABOR_JOB_VIEW_UPDATE_ITEM,
    payload,
  };
};

export const removeJobViewItem = (payload) => {
  return {
    type: LABOR_JOB_VIEW_REMOVE_ITEM,
    payload,
  };
};
