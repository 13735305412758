import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  resetGateOutList,
  setGateOutNameFilter,
  showGateOutForm,
  toggleSelectGateOutInList,
  setSelectAllGateOutInList,
} from '../../redux/etime/gate-out/actions';
import {
  loadGateOutList,
  gateOutEmployee,
  gateOutEmployeesById,
} from '../../redux/etime/gate-out/services';
import {
  selectLastnameUniqueInitials,
  selectFilteredGateOutList,
  selectIdsForSelectedEmployees,
} from '../../redux/etime/gate-out/selectors';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Paper,
  OutlinedInput,
  Typography,
  Tooltip,
  IconButton,
  InputAdornment,
  InputLabel,
} from '@mui/material';
import LastnameNavigator from '../../components/etime/LastnameNavigator';
import GateOutTileList from '../../components/etime/GateOutTileList';
import Empty from '../../components/UI/Empty';
import SyncIcon from '@mui/icons-material/Sync';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import GateOutForm from '../../components/etime/GateOutForm';
import Loading from '../../components/UI/Loading';
const isEmpty = require('is-empty');

const GateOut = () => {
  const dispatch = useDispatch();
  const [allSelected, setAllSelected] = useState(false);
  const [employeeToGateOut, setEmployeeToGateOut] = useState(null);
  const gateOutState = useSelector((state) => state.gateout);
  const LastNameUniqueInitials = useSelector(selectLastnameUniqueInitials);
  const FilteredGateOutList = useSelector(selectFilteredGateOutList);
  const IdsForSelectedEmployees = useSelector(selectIdsForSelectedEmployees);

  useEffect(() => {
    dispatch(loadGateOutList());
    return () => {
      dispatch(resetGateOutList());
    };
  }, [dispatch]);

  const handleRefresh = () => {
    dispatch(loadGateOutList());
    setAllSelected(false);
  };

  const handleOnChangeNameFilter = (event) => {
    dispatch(setGateOutNameFilter(event.target.value));
  };

  const handleClearNameFilter = () => {
    dispatch(setGateOutNameFilter(''));
  };

  const handleClickOnSelectTile = (employee) => {
    setAllSelected(false);
    dispatch(toggleSelectGateOutInList(employee));
  };

  const handleClickOnSelectAll = () => {
    const setValue = !allSelected;
    setAllSelected(setValue);
    dispatch(setSelectAllGateOutInList(setValue));
  };

  const handleOnSubmitGateOutFormDialog = (employee) => {
    dispatch(gateOutEmployee(employee));
  };

  const handleCloseGateOutFormDialog = () => {
    dispatch(showGateOutForm(false));
  };

  const handleOnClickGateOut = (employee) => {
    setEmployeeToGateOut(employee);
    dispatch(showGateOutForm(true));
  };

  const handleOnClickGateOutByIds = () => {
    dispatch(gateOutEmployeesById(IdsForSelectedEmployees));
  };

  return (
    <>
      <Loading loading={gateOutState.loading} />
      <Paper elevation={0}>
        <Box component="div" display="flex" mx={1}>
          <Box display="flex" alignContent="center">
            <IconButton
              size="small"
              aria-label="reload"
              onClick={handleRefresh}
            >
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={1.5} ml={1.5} flexGrow={1}>
            <Typography variant="h6" color="primary">
              eTime - Gate Out
            </Typography>
          </Box>
          <Box my={1.5}>
            <FormControl
              sx={{ marginRight: '0.5rem' }}
              variant="outlined"
              size="small"
            >
              <InputLabel htmlFor="search-by-name">Search by name</InputLabel>

              <OutlinedInput
                id="search-by-name"
                value={gateOutState.filter.name}
                onChange={handleOnChangeNameFilter}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearNameFilter} edge="end">
                      <HighlightOffIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search by name"
              />
            </FormControl>
            <Tooltip title="Select All">
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  allSelected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
                }
                sx={{ marginRight: '0.5rem' }}
                onClick={handleClickOnSelectAll}
              >
                Select All
              </Button>
            </Tooltip>
            <Tooltip title="Sign Out">
              <Button
                variant="contained"
                color="secondary"
                disabled={
                  IdsForSelectedEmployees && IdsForSelectedEmployees.length < 2
                }
                startIcon={<LogoutIcon />}
                onClick={handleOnClickGateOutByIds}
              >
                Sign Out
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <Divider variant="middle" light />
        <Box my={1}>
          <Box component="div" ml={1} my={0} display="flex" flexDirection="row">
            <Box>
              {!isEmpty(LastNameUniqueInitials) && (
                <LastnameNavigator lastNameInitials={LastNameUniqueInitials} />
              )}
            </Box>
            <Box flexGrow={1} px={1} ml={5}>
              {!isEmpty(gateOutState.list) && (
                <GateOutTileList
                  list={FilteredGateOutList}
                  handleClickOnSelectTile={handleClickOnSelectTile}
                  handleOnClickGateOut={handleOnClickGateOut}
                />
              )}
              {isEmpty(FilteredGateOutList) && !gateOutState.loading && (
                <Empty description="There are no employees available to gate-out" />
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
      <Dialog
        open={gateOutState.showGateOutForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseGateOutFormDialog();
          }
        }}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <GateOutForm
            employee={employeeToGateOut}
            savingData={gateOutState.savingData}
            handleOnCancel={handleCloseGateOutFormDialog}
            handleOnSubmit={handleOnSubmitGateOutFormDialog}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GateOut;
