import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setEmployerList,
  resetEmployerList,
  setEmployerListLoading,
  addEmployerToList,
  deleteEmployerFromList,
  updateEmployerInList,
  savingEmployerData,
  showAddEditEmployerForm,
} from './actions';

export const loadEmployers = () => (dispatch, getState, api) => {
  dispatch(setEmployerListLoading(true));
  axios
    .get(`${api}/employers?limit=1000000`)
    .then((res) => {
      dispatch(resetEmployerList());
      dispatch(setEmployerList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setEmployerListLoading(false));
    });
};

export const createEmployer = (data) => (dispatch, getState, api) => {
  dispatch(setEmployerListLoading(true));
  dispatch(savingEmployerData(true));
  axios
    .post(`${api}/employers`, data)
    .then((res) => {
      dispatch(addEmployerToList(res.data));
      dispatch(showAddEditEmployerForm(false));
      const notification = {
        message: '👍🏻 Employer created successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setEmployerListLoading(false));
      dispatch(savingEmployerData(false));
    });
};

export const updateEmployer = (id, data) => (dispatch, getState, api) => {
  dispatch(setEmployerListLoading(true));
  dispatch(savingEmployerData(true));
  axios
    .put(`${api}/employers/${id}`, data)
    .then((res) => {
      dispatch(updateEmployerInList(res.data));
      dispatch(showAddEditEmployerForm(false));
      const notification = {
        message: '👍🏻 Employer updated successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setEmployerListLoading(false));
      dispatch(savingEmployerData(false));
    });
};

export const deleteEmployer = (id) => (dispatch, getState, api) => {
  dispatch(setEmployerListLoading(true));
  axios
    .delete(`${api}/employers/${id}`)
    .then((res) => {
      dispatch(deleteEmployerFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Employer deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setEmployerListLoading(false));
};

export const toggleEmployerStatus =
  (id, currentStatus) => (dispatch, getState, api) => {
    dispatch(setEmployerListLoading(true));
    axios
      .post(`${api}/employers/activate/${id}/${!currentStatus}`)
      .then((res) => {
        console.log(res);
        dispatch(loadEmployers());
        const notification = {
          message: '👍🏻 Employer status updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setEmployerListLoading(false));
      });
  };
