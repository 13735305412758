import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { Typography } from '@mui/material';

const Clock = () => {
  const [now, setNow] = useState(moment().format('MMMM Do YYYY, h:mm:ss a'));

  const updateDateTime = useCallback(() => {
    setNow(moment().format('MMMM Do YYYY, h:mm:ss a'));
  }, [setNow]);

  useEffect(() => {
    const tiktok = setInterval(updateDateTime, 1000);
    return () => {
      clearInterval(tiktok);
    };
  }, [updateDateTime]);
  return (
    <Typography
      variant='body2'
      sx={{ marginRight: 2, fontSize: 18, fontWeight: 'medium' }}
    >
      {now}
    </Typography>
  );
};

export default Clock;
