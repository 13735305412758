import PropTypes from 'prop-types';
import { userPermissions } from './userPermissions';

const checkPermissions = (userRole, allowedPermissions) => {
  if (allowedPermissions.length === 0) {
    return false;
  }
  return userPermissions[userRole].some((permission) =>
    allowedPermissions.includes(permission)
  );
};

const MenuVisibilityGate = ({ userRole, allowedPermissions, children }) => {
  const permitted = checkPermissions(userRole, allowedPermissions);

  return permitted ? children : null;
};

MenuVisibilityGate.propTypes = {
  userRole: PropTypes.string.isRequired,
  allowedPermissions: PropTypes.array.isRequired,
};

export default MenuVisibilityGate;
