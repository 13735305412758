import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import PasswordIcon from '@mui/icons-material/Password';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .min(2, 'Password must be at least 2 characters'),
  lastName: Yup.string()
    .required('Required')
    .min(2, 'Password must be at least 2 characters'),
});

const UserSettingsForm = ({
  user,
  handleOnSubmit,
  handleOnCancel,
  handleOnChangePassword,
  savingData,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Box>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={6}>
              <Controller
                name='firstName'
                control={control}
                defaultValue={user.firstName}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label='First Name'
                    fullWidth
                    error={!!errors?.firstName}
                    helperText={errors?.firstName?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name='lastName'
                control={control}
                defaultValue={user.lastName}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label='Last Name'
                    fullWidth
                    error={!!errors?.lastName}
                    helperText={errors?.lastName?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label='Email' value={user.email} fullWidth disabled />
            </Grid>
          </Grid>
        </Box>
        <Box
          component='div'
          mx={1}
          my={0.5}
          display='flex'
          justifyContent='space-between'
        >
          <Box my={1.5}>
            <Button
              variant='contained'
              color='secondary'
              size='large'
              type='submit'
              startIcon={<SaveIcon />}
            >
              {savingData ? 'Saving...' : 'Save'}
            </Button>
          </Box>
          <Box my={1.5}>
            <Button
              variant='contained'
              size='large'
              startIcon={<PasswordIcon />}
              onClick={handleOnChangePassword}
            >
              Change Password
            </Button>
          </Box>
          <Box my={1.5}>
            <Button
              variant='outlined'
              size='large'
              startIcon={<UndoIcon />}
              onClick={() => {
                reset();
                handleOnCancel();
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default UserSettingsForm;
