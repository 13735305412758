import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  resetGateInList,
  setGateInNameFilter,
  showGateInForm,
  toggleSelectGateInInList,
  setSelectAllGateInInList,
} from '../../redux/etime/gate-in/actions';
import {
  loadGateInList,
  gateInEmployee,
  gateInEmployeesById,
} from '../../redux/etime/gate-in/services';
import {
  selectLastnameUniqueInitials,
  selectFilteredGateInList,
  selectIdsForSelectedEmployees,
} from '../../redux/etime/gate-in/selectors';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Paper,
  OutlinedInput,
  Typography,
  Tooltip,
  IconButton,
  InputAdornment,
  InputLabel,
} from '@mui/material';
import LastnameNavigator from '../../components/etime/LastnameNavigator';
import GateInTileList from '../../components/etime/GateInTileList';
import Empty from '../../components/UI/Empty';
import SyncIcon from '@mui/icons-material/Sync';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LoginIcon from '@mui/icons-material/Login';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import GateInForm from '../../components/etime/GateInForm';
import Loading from '../../components/UI/Loading';
const isEmpty = require('is-empty');

const GateIn = () => {
  const dispatch = useDispatch();
  const [allSelected, setAllSelected] = useState(false);
  const [employeeToGateIn, setEmployeeToGateIn] = useState(null);
  const gateInState = useSelector((state) => state.gatein);
  const LastNameUniqueInitials = useSelector(selectLastnameUniqueInitials);
  const FilteredGateInList = useSelector(selectFilteredGateInList);
  const IdsForSelectedEmployees = useSelector(selectIdsForSelectedEmployees);

  useEffect(() => {
    dispatch(loadGateInList());
    return () => {
      dispatch(resetGateInList());
    };
  }, [dispatch]);

  const handleRefresh = () => {
    dispatch(loadGateInList());
    setAllSelected(false);
  };

  const handleOnChangeNameFilter = (event) => {
    dispatch(setGateInNameFilter(event.target.value));
  };

  const handleClearNameFilter = () => {
    dispatch(setGateInNameFilter(''));
  };

  const handleClickOnSelectTile = (employee) => {
    setAllSelected(false);
    dispatch(toggleSelectGateInInList(employee));
  };

  const handleClickOnSelectAll = () => {
    const setValue = !allSelected;
    setAllSelected(setValue);
    dispatch(setSelectAllGateInInList(setValue));
  };

  const handleOnSubmitGateInFormDialog = (employee) => {
    dispatch(gateInEmployee(employee));
  };

  const handleCloseGateInFormDialog = () => {
    dispatch(showGateInForm(false));
  };

  const handleOnClickGateIn = (employee) => {
    setEmployeeToGateIn(employee);
    dispatch(showGateInForm(true));
  };

  const handleOnClickGateInByIds = () => {
    dispatch(gateInEmployeesById(IdsForSelectedEmployees));
  };

  return (
    <>
      <Loading loading={gateInState.loading} />
      <Paper elevation={0}>
        <Box component="div" display="flex" mx={1}>
          <Box display="flex" alignContent="center">
            <IconButton
              size="small"
              aria-label="reload"
              onClick={handleRefresh}
            >
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={1.5} ml={1.5} flexGrow={1}>
            <Typography variant="h6" color="primary">
              eTime - Gate In
            </Typography>
          </Box>
          <Box my={1.5}>
            <FormControl
              sx={{ marginRight: '0.5rem' }}
              variant="outlined"
              size="small"
            >
              <InputLabel htmlFor="search-by-name">Search by name</InputLabel>

              <OutlinedInput
                id="search-by-name"
                value={gateInState.filter.name}
                onChange={handleOnChangeNameFilter}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearNameFilter} edge="end">
                      <HighlightOffIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search by name"
              />
            </FormControl>
            <Tooltip title="Select All">
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  allSelected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
                }
                sx={{ marginRight: '0.5rem' }}
                onClick={handleClickOnSelectAll}
              >
                Select All
              </Button>
            </Tooltip>
            <Tooltip title="Sign In">
              <Button
                variant="contained"
                color="secondary"
                disabled={
                  IdsForSelectedEmployees && IdsForSelectedEmployees.length < 2
                }
                startIcon={<LoginIcon />}
                onClick={handleOnClickGateInByIds}
              >
                Sign In
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <Divider variant="middle" light />
        <Box my={1}>
          <Box component="div" ml={1} my={0} display="flex" flexDirection="row">
            <Box>
              {!isEmpty(LastNameUniqueInitials) && (
                <LastnameNavigator lastNameInitials={LastNameUniqueInitials} />
              )}
            </Box>
            <Box flexGrow={1} px={1} ml={5}>
              {!isEmpty(gateInState.list) && (
                <GateInTileList
                  list={FilteredGateInList}
                  handleClickOnSelectTile={handleClickOnSelectTile}
                  handleOnClickGateIn={handleOnClickGateIn}
                />
              )}
              {isEmpty(FilteredGateInList) && !gateInState.loading && (
                <Empty description="There are no employees available to gate-in" />
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
      <Dialog
        open={gateInState.showGateInForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseGateInFormDialog();
          }
        }}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <GateInForm
            employee={employeeToGateIn}
            savingData={gateInState.savingData}
            handleOnCancel={handleCloseGateInFormDialog}
            handleOnSubmit={handleOnSubmitGateInFormDialog}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GateIn;
