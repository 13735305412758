import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getMachineTypeList = createSelector(
  (state) => state.list_machine_type.list,
  (machineTypeList) => {
    if (isEmpty(machineTypeList)) return [];
    return machineTypeList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
