import React from 'react';
import { Grid, Paper } from '@mui/material';
import LaborLogTile from './LaborLogTile';

const LaborLogTileList = ({
  laborLogs,
  handleClickStartLaborLog,
  handleClickStopLaborLog,
  handleClickMoveLaborLog,
  handleClickChangePosition,
  handleClickWalkOff,
}) => {
  return (
    <Grid container spacing={1}>
      {laborLogs.map((laborLog) => {
        return (
          <Grid item xs={4} key={laborLog.id}>
            <Paper variant="outlined">
              <LaborLogTile
                laborLog={laborLog}
                handleClickStartLaborLog={handleClickStartLaborLog}
                handleClickStopLaborLog={handleClickStopLaborLog}
                handleClickMoveLaborLog={handleClickMoveLaborLog}
                handleClickChangePosition={handleClickChangePosition}
                handleClickWalkOff={handleClickWalkOff}
              />
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default LaborLogTileList;
