import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getPalletTypeList = createSelector(
  (state) => state.pallet_types.list.docs,
  (palletTypesList) => {
    if (isEmpty(palletTypesList)) return [];
    return palletTypesList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
