import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setContactList,
  resetContactList,
  setContactListLoading,
  addContactToList,
  deleteContactFromList,
  updateContactInList,
  savingContactData,
  showAddEditContactForm,
} from './actions';

export const loadContacts = () => (dispatch, getState, api) => {
  dispatch(setContactListLoading(true));
  axios
    .get(`${api}/contacts?limit=1000000`)
    .then((res) => {
      dispatch(resetContactList());
      dispatch(setContactList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setContactListLoading(false));
    });
};

export const createContact = (data) => (dispatch, getState, api) => {
  dispatch(setContactListLoading(true));
  dispatch(savingContactData(true));
  axios
    .post(`${api}/contacts`, data)
    .then((res) => {
      dispatch(addContactToList(res.data));
      dispatch(showAddEditContactForm(false));
      const notification = {
        message: '👍🏻 Contact created successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setContactListLoading(false));
      dispatch(savingContactData(false));
    });
};

export const updateContact = (id, data) => (dispatch, getState, api) => {
  dispatch(setContactListLoading(true));
  dispatch(savingContactData(true));
  axios
    .put(`${api}/contacts/${id}`, data)
    .then((res) => {
      dispatch(updateContactInList(res.data));
      dispatch(showAddEditContactForm(false));
      const notification = {
        message: '👍🏻 Contact updated successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setContactListLoading(false));
      dispatch(savingContactData(false));
    });
};

export const deleteContact = (id) => (dispatch, getState, api) => {
  dispatch(setContactListLoading(true));
  axios
    .delete(`${api}/contacts/${id}`)
    .then((res) => {
      dispatch(deleteContactFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Contact deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setContactListLoading(false));
};
