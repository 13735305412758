import {
  SET_MANUAL_PROFILE,
  MANUAL_PROFILE_LOADING,
  MANUAL_PROFILE_RESET,
  SET_MANUAL_PROFILES,
  MANUAL_PROFILE_LIST_LOADING,
  MANUAL_PROFILE_LIST_RESET,
  ADD_MANUAL_PROFILE_TO_LIST,
  UPDATE_MANUAL_PROFILE_IN_LIST,
  DELETE_MANUAL_PROFILE_FROM_LIST,
  SAVING_MANUAL_PROFILE_DATA,
  SET_MANUAL_PROFILE_FILTERS,
  RESET_MANUAL_PROFILE_FILTERS,
  SHOW_DUPLICATE_MANUAL_PROFILE_FORM,
} from './types';

export const setManualProfile = (item) => {
  return {
    type: SET_MANUAL_PROFILE,
    payload: item,
  };
};

export const resetManualProfile = () => {
  return {
    type: MANUAL_PROFILE_RESET,
  };
};

export const setManualProfileLoading = (payload) => {
  return {
    type: MANUAL_PROFILE_LOADING,
    payload,
  };
};

export const setManualProfileList = (list) => {
  return {
    type: SET_MANUAL_PROFILES,
    payload: list,
  };
};

export const resetManualProfileList = () => {
  return {
    type: MANUAL_PROFILE_LIST_RESET,
  };
};

export const setManualProfileListLoading = (payload) => {
  return {
    type: MANUAL_PROFILE_LIST_LOADING,
    payload,
  };
};

export const addManualProfileToList = (payload) => {
  return {
    type: ADD_MANUAL_PROFILE_TO_LIST,
    payload,
  };
};

export const deleteManualProfileFromList = (payload) => {
  return {
    type: DELETE_MANUAL_PROFILE_FROM_LIST,
    payload,
  };
};

export const updateManualProfileInList = (payload) => {
  return {
    type: UPDATE_MANUAL_PROFILE_IN_LIST,
    payload,
  };
};

export const savingManualProfileData = (payload) => {
  return {
    type: SAVING_MANUAL_PROFILE_DATA,
    payload,
  };
};

export const setManualProfileFilters = (payload) => {
  return {
    type: SET_MANUAL_PROFILE_FILTERS,
    payload,
  };
};

export const resetManualProfileFilters = () => {
  return {
    type: RESET_MANUAL_PROFILE_FILTERS,
  };
};

export const showDuplicateManualProfileForm = (payload) => {
  return {
    type: SHOW_DUPLICATE_MANUAL_PROFILE_FORM,
    payload,
  };
};
