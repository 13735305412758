import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetLinesList } from '../../redux/epack/lines/actions';
import {
  loadLinesList,
  activateLine,
  removeLine,
} from '../../redux/epack/lines/services';
import { getLinesList } from '../../redux/epack/lines/selectors';
import { Box, Paper, Typography, IconButton } from '@mui/material';
import Loading from '../../components/UI/Loading';
import Empty from '../../components/UI/Empty';
import LinesTable from '../../components/epack/LinesTable';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import SyncIcon from '@mui/icons-material/Sync';
const isEmpty = require('is-empty');

const Lines = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const linesState = useSelector((state) => state.lines);
  const LinesList = useSelector(getLinesList);
  const [lineIdToRemove, setLineIdToRemove] = useState(null);
  const [openRemoveLineDialog, setOpenRemoveLineDialog] = useState(false);

  useEffect(() => {
    dispatch(loadLinesList());
    return () => {
      dispatch(resetLinesList());
    };
  }, [dispatch]);

  const handleClickView = (id) => {
    navigate(`/epack/line/${id}`);
  };

  const handleRefresh = () => {
    dispatch(loadLinesList());
  };

  const handleClickActivate = (id) => {
    dispatch(activateLine(id));
  };

  const handleClickRemove = (id) => {
    setLineIdToRemove(id);
    setOpenRemoveLineDialog(true);
  };

  const onRemoveLine = () => {
    dispatch(removeLine(lineIdToRemove));
    setLineIdToRemove(null);
    setOpenRemoveLineDialog(false);
  };

  return (
    <>
      <Loading loading={linesState.loading} />
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box display='flex' alignContent='center'>
            <IconButton aria-label='reload' onClick={handleRefresh}>
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              ePack - Production Lines (Read Only Mode)
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          {!isEmpty(linesState.list) && (
            <LinesTable
              rows={LinesList}
              handleClickView={handleClickView}
              handleClickActivate={handleClickActivate}
              handleClickRemove={handleClickRemove}
            />
          )}
          {isEmpty(linesState.list) && !linesState.loading && (
            <Empty description='There are no lines to show' />
          )}
        </Box>
      </Paper>
      <ConfirmationDialog
        open={openRemoveLineDialog}
        handleNo={() => {
          setOpenRemoveLineDialog(false);
        }}
        handleYes={onRemoveLine}
        title='ePack - Remove Production Line'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          Are you sure you want to remove this line?
        </Typography>
      </ConfirmationDialog>
    </>
  );
};

export default Lines;
