import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getCommodityList = createSelector(
  (state) => state.commodities.list.docs,
  (commoditiesList) => {
    if (isEmpty(commoditiesList)) return [];
    return commoditiesList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
