export const chartColors = [
  '#1F77B4',
  '#FF7F0E',
  '#2CA02C',
  '#D62728',
  '#9467BD',
  '#8C564B',
  '#CFECF9',
  '#7F7F7F',
  '#BCBD22',
  '#17BECF',
];

export const chartLightColors = [
  '#3698dd',
  '#ffa557',
  '#6fd86f',
  '#e36464',
  '#a47fc7',
  '#bd8e84',
  '#CFECF9',
  '#a6a6a6',
  '#e3e363',
  '#5adeed',
];
