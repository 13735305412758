import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .min(6, 'Password must be at least 6 characters'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

const ValidationWithAgreementSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .min(6, 'Password must be at least 6 characters'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
  agreement: Yup.bool().oneOf(
    [true],
    'You must accept the terms and conditions'
  ),
});

const ResetPasswordForm = ({
  handleOnSubmit,
  actionButtonTitle,
  showAgreement,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      showAgreement ? ValidationWithAgreementSchema : ValidationSchema
    ),
  });

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          handleOnSubmit({ password: data.password });
        })}
      >
        <Box>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <Controller
                name='password'
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label='Password'
                    fullWidth
                    type='password'
                    error={!!errors?.password}
                    helperText={errors?.password?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='confirmPassword'
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label='Confirm Password'
                    fullWidth
                    type='password'
                    error={!!errors?.confirmPassword}
                    helperText={errors?.confirmPassword?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        {showAgreement ? (
          <Box component='div' my={1.5}>
            <Controller
              control={control}
              name='agreement'
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value}
                      onClick={(e) => field.onChange(!field.value)}
                    />
                  }
                  label={
                    <Typography variant='body2'>
                      I agree to the{' '}
                      <a
                        href='https://www.ehawkware.com/service-agreement'
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        eHawk Terms
                      </a>
                      . Learn about how we use and protect your data in our{' '}
                      <a
                        href='https://www.ehawkware.com/privacy-policy'
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        Privacy Policy
                      </a>
                      .
                    </Typography>
                  }
                />
              )}
            />
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {errors?.agreement?.message}
            </FormHelperText>
          </Box>
        ) : null}
        <Box component='div' my={0.5} display='flex'>
          <Box my={1.5} flexGrow={1}>
            <Button
              variant='contained'
              color='secondary'
              size='large'
              type='submit'
              startIcon={<PasswordIcon />}
            >
              {actionButtonTitle}
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ResetPasswordForm;
