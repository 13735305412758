import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DifferenceIcon from '@mui/icons-material/Difference';
import Empty from '../UI/Empty';
import isEmpty from 'is-empty';
import { Box } from '@mui/system';
import { getMonthName } from '../../utils/commonFunctions';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const MORProfileMonthsTable = ({
  rows,
  handleClickDelete,
  handleClickEdit,
  handleClickDuplicate,
}) => {
  return (
    <Table aria-label='inflow table' size='small'>
      <TableHead>
        <TableRow>
          <TableCell>Month</TableCell>
          <TableCell>Mfg. Availability</TableCell>
          <TableCell>Allowance</TableCell>
          <TableCell>Yield</TableCell>
          <TableCell>Cap. Availability</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isEmpty(rows) ? (
          <Box display='flex' justifyContent='center' flexGrow={1}>
            <Box>
              <Empty description='No months to show, please add some' />
            </Box>
          </Box>
        ) : (
          rows.map((row) => (
            <StyledTableRow key={row.id}>
              <TableCell component='th' scope='row'>
                {getMonthName(row.month)}
              </TableCell>
              <TableCell>{`${row.manufacturingAvailability * 100}%`}</TableCell>
              <TableCell>{`${row.allowance * 100}%`}</TableCell>
              <TableCell>{`${row.yield * 100}%`}</TableCell>
              <TableCell>{`${row.capacityAvailability * 100}%`}</TableCell>
              <TableCell>
                <Tooltip title='Duplicate'>
                  <IconButton
                    size='small'
                    onClick={() => handleClickDuplicate(row)}
                    color='primary'
                  >
                    <DifferenceIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Edit'>
                  <IconButton
                    size='small'
                    onClick={() => handleClickEdit(row)}
                    color='primary'
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Delete'>
                  <IconButton
                    size='small'
                    onClick={() => handleClickDelete(row)}
                    sx={{
                      color: 'error.main',
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </StyledTableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export default MORProfileMonthsTable;
