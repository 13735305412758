import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DifferenceIcon from '@mui/icons-material/Difference';
import Empty from '../UI/Empty';
import isEmpty from 'is-empty';
import { Box } from '@mui/system';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const money = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const ProfitProfileSuppliesTable = ({
  rows,
  handleClickDelete,
  handleClickEdit,
  handleClickDuplicate,
}) => {
  return (
    <Table aria-label='inflow table' size='small'>
      <TableHead>
        <TableRow>
          <TableCell>Item</TableCell>
          <TableCell>Amount</TableCell>
          <TableCell>Unit</TableCell>
          <TableCell>$ / Unit</TableCell>
          <TableCell>Total Cost</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isEmpty(rows) ? (
          <Box display='flex' justifyContent='center' flexGrow={1}>
            <Box>
              <Empty description='No supplies to show, please add some' />
            </Box>
          </Box>
        ) : (
          rows.map((row) => (
            <StyledTableRow key={row.id}>
              <TableCell component='th' scope='row'>
                {row.item}
              </TableCell>
              <TableCell>{row.amount}</TableCell>
              <TableCell>{row.unitAmount + ' ' + row.umo}</TableCell>
              <TableCell>{money.format(row.costPerUnit)}</TableCell>
              <TableCell>
                {money.format((row.amount / row.unitAmount) * row.costPerUnit)}
              </TableCell>
              <TableCell>
                <Tooltip title='Duplicate'>
                  <IconButton
                    size='small'
                    onClick={() => handleClickDuplicate(row)}
                    color='primary'
                  >
                    <DifferenceIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Edit'>
                  <IconButton
                    size='small'
                    onClick={() => handleClickEdit(row)}
                    color='primary'
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Delete'>
                  <IconButton
                    size='small'
                    onClick={() => handleClickDelete(row)}
                    sx={{
                      color: 'error.main',
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </StyledTableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export default ProfitProfileSuppliesTable;
