import React from 'react';
import { greetingText } from '../../utils/commonFunctions';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const Welcome = ({ currentUser }) => {
  const welcomeText = greetingText() + ' ' + currentUser.firstName;

  return (
    <Paper elevation={0}>
      <Typography variant='h4' gutterBottom color='primary'>
        {welcomeText}
      </Typography>
    </Paper>
  );
};

export default Welcome;
