import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';

const ConfirmationDialog = ({
  children,
  open,
  title,
  handleNo,
  handleYes,
  YesText = 'Yes',
  NoText = 'No',
}) => {
  return (
    <Dialog open={open} onClose={handleNo}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
      <Divider />
      <Box display="flex" justifyContent="space-between" mx={3} my={2}>
        <Button
          onClick={handleYes}
          color="secondary"
          variant="contained"
          autoFocus
        >
          {YesText}
        </Button>
        <Button onClick={handleNo} color="primary" variant="outlined">
          {NoText}
        </Button>
      </Box>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleYes: PropTypes.func.isRequired,
  handleNo: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ConfirmationDialog;
