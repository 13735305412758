import {
  WORK_ORDER_LOADING,
  SET_WORK_ORDER,
  WORK_ORDER_RESET,
  SET_WORK_ORDERS,
  WORK_ORDERS_LIST_LOADING,
  WORK_ORDERS_LIST_RESET,
  ADD_WORK_ORDER_TO_LIST,
  UPDATE_WORK_ORDER_IN_LIST,
  DELETE_WORK_ORDER_FROM_LIST,
  DELETE_PALLET_FROM_WORK_ORDER_LIST,
  SHOW_ADD_EDIT_WORK_ORDER_FORM,
  SAVING_WORK_ORDER_DATA,
  SHOW_IMPORT_WORK_ORDERS_FORM,
  IMPORTING_WORK_ORDERS_DATA,
} from './types';

export const setWorkOrder = (workOrder) => {
  return {
    type: SET_WORK_ORDER,
    payload: workOrder,
  };
};

export const setWorkOrderList = (list) => {
  return {
    type: SET_WORK_ORDERS,
    payload: list,
  };
};

export const resetWorkOrderList = () => {
  return {
    type: WORK_ORDERS_LIST_RESET,
  };
};

export const resetWorkOrder = () => {
  return {
    type: WORK_ORDER_RESET,
  };
};

export const setWorkOrderListLoading = (payload) => {
  return {
    type: WORK_ORDERS_LIST_LOADING,
    payload,
  };
};

export const setWorkOrderLoading = (payload) => {
  return {
    type: WORK_ORDER_LOADING,
    payload,
  };
};
export const addWorkOrderToList = (payload) => {
  return {
    type: ADD_WORK_ORDER_TO_LIST,
    payload,
  };
};

export const deleteWorkOrderFromList = (payload) => {
  return {
    type: DELETE_WORK_ORDER_FROM_LIST,
    payload,
  };
};

export const deletePalletFromList = (workOrderId, palletId) => {
  return {
    type: DELETE_PALLET_FROM_WORK_ORDER_LIST,
    payload: {
      workOrderId,
      palletId,
    },
  };
};

export const updateWorkOrderInList = (payload) => {
  return {
    type: UPDATE_WORK_ORDER_IN_LIST,
    payload,
  };
};

export const showAddEditWorkOrderForm = (payload) => {
  return {
    type: SHOW_ADD_EDIT_WORK_ORDER_FORM,
    payload,
  };
};

export const savingWorkOrderData = (payload) => {
  return {
    type: SAVING_WORK_ORDER_DATA,
    payload,
  };
};

export const showImportWorkOrdersForm = (payload) => {
  return {
    type: SHOW_IMPORT_WORK_ORDERS_FORM,
    payload,
  };
};

export const importingWorkOrdersData = (payload) => {
  return {
    type: IMPORTING_WORK_ORDERS_DATA,
    payload,
  };
};
