import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Divider,
  Grid,
  CardActions,
  CardContent,
} from '@mui/material';
import EmployeeTile from './EmployeeTile';
import BoltIcon from '@mui/icons-material/Bolt';
import grey from '@mui/material/colors/grey';

const LaborUnallocatedTileList = ({
  list,
  handleClickOnSelectTile,
  handleOnClickAssignJob,
}) => {
  return (
    <Grid container spacing={1}>
      {list.map((employee) => {
        return (
          <Grid item xs={6} md={3} key={employee.id}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
              id={employee.lastName[0]}
            >
              <CardContent sx={{ padding: '0' }}>
                <EmployeeTile
                  flags={employee.flags}
                  photo={employee.photo}
                  active={employee.active}
                  firstName={employee.firstName}
                  lastName={employee.lastName}
                  latestPosition={employee.latestPosition}
                  employer={employee.employer}
                  hasCheckBox={true}
                  handleClickOnCheckBox={() =>
                    handleClickOnSelectTile(employee)
                  }
                  selected={employee.selected}
                />
              </CardContent>
              <Divider />
              <CardActions
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  color: grey['600'],
                }}
              >
                <ButtonGroup variant="text" color="inherit" fullWidth>
                  <Button
                    sx={{ color: grey['800'] }}
                    startIcon={<BoltIcon sx={{ color: 'primary.main' }} />}
                    onClick={() => {
                      handleOnClickAssignJob(employee);
                    }}
                  >
                    Assign
                  </Button>
                </ButtonGroup>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default LaborUnallocatedTileList;
