import React, { useState } from 'react';
import Header from './Header';
import Main from './Main';
import RequireAuth from '../../utils/RequireAuth';

const Layout = () => {
  const [showFavorites, setShowFavorites] = useState(false);
  const handleShowHideFavs = () => {
    setShowFavorites(!showFavorites);
  };
  return (
    <RequireAuth>
      <Header
        showFavorites={showFavorites}
        handleShowHideFavs={handleShowHideFavs}
      />
      <Main favoritesOpen={showFavorites} />
    </RequireAuth>
  );
};

export default Layout;
