import {
  SET_PALLET_TYPE_LIST,
  PALLET_TYPE_LIST_LOADING,
  PALLET_TYPE_LIST_RESET,
} from './types';

export const setPalletTypeList = (list) => {
  return {
    type: SET_PALLET_TYPE_LIST,
    payload: list,
  };
};

export const resetPalletTypeList = () => {
  return {
    type: PALLET_TYPE_LIST_RESET,
  };
};

export const setPalletTypeListLoading = (payload) => {
  return {
    type: PALLET_TYPE_LIST_LOADING,
    payload,
  };
};
