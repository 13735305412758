import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setJobTypeList,
  resetJobTypeList,
  setJobTypeListLoading,
} from './actions';

export const loadJobTypeList = () => (dispatch, getState, api) => {
  dispatch(setJobTypeListLoading(true));
  axios
    .get(`${api}/list/job-types`)
    .then((res) => {
      dispatch(resetJobTypeList());
      dispatch(setJobTypeList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setJobTypeListLoading(false));
    });
};
