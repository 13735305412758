export const SET_GATE_IN_LIST = 'SET_GATE_IN_LIST';
export const GATE_IN_LIST_LOADING = 'GATE_IN_LIST_LOADING';
export const GATE_IN_LIST_RESET = 'GATE_IN_LIST_RESET';
export const SET_GATE_IN_NAME_FILTER = 'SET_GATE_IN_NAME_FILTER';
export const UPDATE_GATE_IN_IN_LIST = 'UPDATE_GATE_IN_IN_LIST';
export const SAVING_GATE_IN_DATA = 'SAVING_GATE_IN_DATA';
export const SHOW_GATE_IN_FORM = 'SHOW_GATE_IN_FORM';
export const TOGGLE_SELECT_GATE_IN_IN_LIST = 'TOGGLE_SELECT_GATE_IN_IN_LIST';
export const SET_SELECT_ALL_GATE_IN_IN_LIST = 'SET_SELECT_ALL_GATE_IN_IN_LIST';
export const DELETE_GATE_IN_FROM_LIST = 'DELETE_GATE_IN_FROM_LIST';
