import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setEmployeePositionList,
  resetEmployeePositionList,
  setEmployeePositionListLoading,
  addEmployeePositionToList,
  deleteEmployeePositionFromList,
  updateEmployeePositionInList,
  savingEmployeePositionData,
  showAddEditEmployeePositionForm,
} from './actions';

export const loadEmployeePositions = () => (dispatch, getState, api) => {
  dispatch(setEmployeePositionListLoading(true));
  axios
    .get(`${api}/employee-positions?limit=1000000`)
    .then((res) => {
      dispatch(resetEmployeePositionList());
      dispatch(setEmployeePositionList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setEmployeePositionListLoading(false));
    });
};

export const createEmployeePosition = (data) => (dispatch, getState, api) => {
  dispatch(setEmployeePositionListLoading(true));
  dispatch(savingEmployeePositionData(true));
  axios
    .post(`${api}/employee-positions`, data)
    .then((res) => {
      dispatch(addEmployeePositionToList(res.data));
      dispatch(showAddEditEmployeePositionForm(false));
      const notification = {
        message: '👍🏻 Employee position created successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setEmployeePositionListLoading(false));
      dispatch(savingEmployeePositionData(false));
    });
};

export const updateEmployeePosition =
  (id, data) => (dispatch, getState, api) => {
    dispatch(setEmployeePositionListLoading(true));
    dispatch(savingEmployeePositionData(true));
    axios
      .put(`${api}/employee-positions/${id}`, data)
      .then((res) => {
        dispatch(updateEmployeePositionInList(res.data));
        dispatch(showAddEditEmployeePositionForm(false));
        const notification = {
          message: '👍🏻 Employee position updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setEmployeePositionListLoading(false));
        dispatch(savingEmployeePositionData(false));
      });
  };

export const deleteEmployeePosition = (id) => (dispatch, getState, api) => {
  dispatch(setEmployeePositionListLoading(true));
  axios
    .delete(`${api}/employee-positions/${id}`)
    .then((res) => {
      dispatch(deleteEmployeePositionFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Employee position deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setEmployeePositionListLoading(false));
};

export const toggleEmployeePositionStatus =
  (id, currentStatus) => (dispatch, getState, api) => {
    dispatch(setEmployeePositionListLoading(true));
    axios
      .post(`${api}/employee-positions/activate/${id}/${!currentStatus}`)
      .then((res) => {
        console.log(res);
        dispatch(loadEmployeePositions());
        const notification = {
          message: '👍🏻 Employee position status updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setEmployeePositionListLoading(false));
      });
  };
