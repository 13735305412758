import {
  SET_PRODUCTION_LINE_ITEM,
  PRODUCTION_LINE_LOADING,
  PRODUCTION_LINE_RESET,
  SET_PRODUCTION_WORK_ORDER_VIEW_LIST,
  PRODUCTION_WORK_ORDER_VIEW_LIST_LOADING,
  PRODUCTION_WORK_ORDER_VIEW_LIST_RESET,
  SET_PRODUCTION_COMMODITY_VIEW_LIST,
  PRODUCTION_COMMODITY_VIEW_LIST_LOADING,
  PRODUCTION_COMMODITY_VIEW_LIST_RESET,
  SET_PRODUCTION_MACHINE_VIEW_LIST,
  PRODUCTION_MACHINE_VIEW_LIST_LOADING,
  PRODUCTION_MACHINE_VIEW_LIST_RESET,
  SET_PRODUCTION_TABLE_VIEW_LIST,
  PRODUCTION_TABLE_VIEW_LIST_LOADING,
  PRODUCTION_TABLE_VIEW_LIST_RESET,
  SET_PRODUCTION_WIP_VIEW,
  PRODUCTION_WIP_VIEW_LOADING,
  PRODUCTION_WIP_VIEW_RESET,
  SET_PRODUCTION_LINE_PALLET,
  PRODUCTION_LINE_PALLET_LOADING,
  PRODUCTION_LINE_PALLET_RESET,
  SHOW_PALLET_ADD_SCAN,
  ADD_PALLET_TO_PRODUCTION_LINE,
  ADD_PHOTO_TO_PRODUCTION_LINE,
} from './types';

export const setProductionLineItem = (item) => {
  return {
    type: SET_PRODUCTION_LINE_ITEM,
    payload: item,
  };
};

export const resetProductionLineItem = () => {
  return {
    type: PRODUCTION_LINE_RESET,
  };
};

export const setProductionLineItemLoading = (payload) => {
  return {
    type: PRODUCTION_LINE_LOADING,
    payload,
  };
};

export const setWorkOrderViewList = (list) => {
  return {
    type: SET_PRODUCTION_WORK_ORDER_VIEW_LIST,
    payload: list,
  };
};

export const resetWorkOrderViewList = () => {
  return {
    type: PRODUCTION_WORK_ORDER_VIEW_LIST_RESET,
  };
};

export const setWorkOrderViewListLoading = (payload) => {
  return {
    type: PRODUCTION_WORK_ORDER_VIEW_LIST_LOADING,
    payload,
  };
};

export const setCommodityViewList = (list) => {
  return {
    type: SET_PRODUCTION_COMMODITY_VIEW_LIST,
    payload: list,
  };
};

export const resetCommodityViewList = () => {
  return {
    type: PRODUCTION_COMMODITY_VIEW_LIST_RESET,
  };
};

export const setCommodityViewListLoading = (payload) => {
  return {
    type: PRODUCTION_COMMODITY_VIEW_LIST_LOADING,
    payload,
  };
};

export const setMachineViewList = (list) => {
  return {
    type: SET_PRODUCTION_MACHINE_VIEW_LIST,
    payload: list,
  };
};

export const resetMachineViewList = () => {
  return {
    type: PRODUCTION_MACHINE_VIEW_LIST_RESET,
  };
};

export const setMachineViewListLoading = (payload) => {
  return {
    type: PRODUCTION_MACHINE_VIEW_LIST_LOADING,
    payload,
  };
};

export const setTableViewList = (list) => {
  return {
    type: SET_PRODUCTION_TABLE_VIEW_LIST,
    payload: list,
  };
};

export const resetTableViewList = () => {
  return {
    type: PRODUCTION_TABLE_VIEW_LIST_RESET,
  };
};

export const setTableViewListLoading = (payload) => {
  return {
    type: PRODUCTION_TABLE_VIEW_LIST_LOADING,
    payload,
  };
};

export const setWIPView = (data) => {
  return {
    type: SET_PRODUCTION_WIP_VIEW,
    payload: data,
  };
};

export const resetWIPView = () => {
  return {
    type: PRODUCTION_WIP_VIEW_RESET,
  };
};

export const setWIPViewLoading = (payload) => {
  return {
    type: PRODUCTION_WIP_VIEW_LOADING,
    payload,
  };
};

export const setProductionLinePallet = (pallet) => {
  return {
    type: SET_PRODUCTION_LINE_PALLET,
    payload: pallet,
  };
};

export const resetProductionLinePallet = () => {
  return {
    type: PRODUCTION_LINE_PALLET_RESET,
  };
};

export const setProductionLinePalletLoading = (payload) => {
  return {
    type: PRODUCTION_LINE_PALLET_LOADING,
    payload,
  };
};

export const showPalletAddScan = (payload) => {
  return {
    type: SHOW_PALLET_ADD_SCAN,
    payload,
  };
};

export const addPalletToProductionLine = (payload) => {
  return {
    type: ADD_PALLET_TO_PRODUCTION_LINE,
    payload,
  };
};

export const addPhotoToProductionLine = (payload) => {
  return {
    type: ADD_PHOTO_TO_PRODUCTION_LINE,
    payload,
  };
};
