import React from 'react';
import { List, ListItem, ListItemButton, Typography } from '@mui/material';
import { Box } from '@mui/system';

const LastnameNavigator = ({ lastNameInitials }) => {
  return (
    <Box sx={{ position: 'fixed' }}>
      <List>
        {lastNameInitials.map((letter) => (
          <ListItem disablePadding key={letter}>
            <ListItemButton
              onClick={() => {
                let element = document.getElementById(letter);
                element.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'nearest',
                });
                element.style.border = '1px solid #f9ae0f';

                element.style.transition = 'border 2s';
                setTimeout(() => {
                  element.style.border = '1px solid rgba(0, 0, 0, 0.12)';
                }, 2000);
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {letter}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default LastnameNavigator;
