import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';
import isEmpty from 'is-empty';

const ValidationSchema = Yup.object().shape({
  code: Yup.string().required('Work Order is required'),
  commodity: Yup.string().required('Commodity is required'),
});

const WorkOrderForm = ({
  workOrder,
  editMode,
  handleOnSubmit,
  handleOnCancel,
  savingData,
}) => {
  const {
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  useEffect(() => {
    editMode && setValue('workOrderId', workOrder.id);
  }, [setValue, workOrder, editMode]);

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box display='flex' my={1.5} flexGrow={1} alignItems='center'>
              <Typography
                color='primary'
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'ePack - Work Order'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='code'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(workOrder?.code) ? workOrder.code : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Work Order'
                      fullWidth
                      error={!!errors?.code}
                      helperText={errors?.code?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='umo'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(workOrder?.umo) ? workOrder.umo : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Model'
                      fullWidth
                      error={!!errors?.model}
                      helperText={errors?.model?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='commodity'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(workOrder?.commodity)
                      ? workOrder.commodity.name
                      : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Commodity'
                      fullWidth
                      error={!!errors?.commodity}
                      helperText={errors?.commodity?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='customer'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(workOrder?.customer)
                      ? workOrder.customer.name
                      : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Customer'
                      fullWidth
                      error={!!errors?.customer}
                      helperText={errors?.customer?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='pallet'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(workOrder?.pallet)
                      ? workOrder.pallet.code
                      : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Pallet Code'
                      fullWidth
                      error={!!errors?.pallet}
                      helperText={errors?.pallet?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='lot'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(workOrder?.pallet)
                      ? workOrder.pallet.lot
                      : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Lot'
                      fullWidth
                      error={!!errors?.lot}
                      helperText={errors?.lot?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='description'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(workOrder?.pallet)
                      ? workOrder.pallet.description
                      : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Description'
                      fullWidth
                      error={!!errors?.description}
                      helperText={errors?.description?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='item'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(workOrder?.pallet)
                      ? workOrder.pallet.item
                      : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Item'
                      fullWidth
                      error={!!errors?.item}
                      helperText={errors?.item?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='gtin'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(workOrder?.gtin) ? workOrder.gtin : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='GTIN'
                      fullWidth
                      error={!!errors?.gtin}
                      helperText={errors?.gtin?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='cases'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(workOrder?.pallet)
                      ? workOrder.pallet.cases
                      : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Cases'
                      fullWidth
                      type='number'
                      error={!!errors?.cases}
                      helperText={errors?.cases?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box component='div' mx={1} my={0.5} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
                startIcon={<SaveIcon />}
              >
                {savingData ? 'Saving...' : 'Save'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UndoIcon />}
                onClick={() => {
                  reset();
                  handleOnCancel();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default WorkOrderForm;
