import axios from 'axios';
import { addNotification } from '../../../system/notifications/actions';
import {
  setSummaryList,
  resetSummaryList,
  setSummaryListLoading,
} from './actions';

export const loadSummaryList = () => (dispatch, getState, api) => {
  dispatch(setSummaryListLoading(true));
  axios
    .get(`${api}/labor/summary`)
    .then((res) => {
      dispatch(resetSummaryList());
      dispatch(setSummaryList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setSummaryListLoading(false));
    });
};
