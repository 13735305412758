import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PalletOutIcon = (props) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 330 330'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ marginTop: 0, marginRight: 0.5 }}
      {...props}
    >
      <rect y='305' width='330' height='25' rx='5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 191C2.23858 191 0 193.239 0 196V286C0 288.761 2.23858 291 5 291H140C142.761 291 145 288.761 145 286V196C145 193.239 142.761 191 140 191H84.5833V224.333H60.4167V191H5Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M189 191C186.239 191 184 193.239 184 196V286C184 288.761 186.239 291 189 291H324C326.761 291 329 288.761 329 286V196C329 193.239 326.761 191 324 191H268.583V224.333H244.417V191H189Z'
      />
      <rect x='136' y='101.111' width='56' height='88.8889' rx='7' />
      <path d='M158.353 5.58483C161.125 2.57683 165.875 2.57683 168.647 5.58483L257.898 102.423C262.032 106.908 258.851 114.167 252.751 114.167H74.2491C68.1493 114.167 64.9679 106.908 69.1018 102.423L158.353 5.58483Z' />
    </SvgIcon>
  );
};

export default PalletOutIcon;
