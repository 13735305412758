import React from 'react';

const SanitationGraph = (props) => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 1000 1000'
      enableBackground='new 0 0 1000 1000'
      {...props}
    >
      <g>
        <g transform='translate(0.000000,511.000000) scale(0.100000,-0.100000)'>
          <path d='M8096.1,4969.3c-87.4-51.2-113-102.3-113-217.4c0-106.6,40.5-174.8,127.9-219.5c113-59.7,255.8-23.4,328.3,78.9c19.2,25.6,34.1,91.7,34.1,144.9c0,110.8-38.4,176.9-127.9,223.8C8262.4,5022.6,8177.1,5020.5,8096.1,4969.3z' />
          <path d='M7373.6,4553.7c-85.3-44.8-136.4-136.4-136.4-245.1c0-72.5,12.8-102.3,72.5-162c61.8-61.8,87.4-72.5,168.4-72.5c179.1,0,300.6,164.1,245.1,330.4C7674.1,4549.4,7507.8,4621.9,7373.6,4553.7z' />
          <path d='M2848.4,4413c-353.8-68.2-697-264.3-963.4-545.7c-147.1-155.6-311.2-387.9-332.5-473.2c-19.2-76.7,12.8-110.8,166.3-172.7c306.9-123.6,543.5-328.3,662.9-569.1c66.1-134.3,70.3-155.6,76.7-419.9c4.3-153.5,17.1-296.3,27.7-315.5c19.2-36.2,72.5-38.4,675.7-38.4c360.2,0,669.3,8.5,686.3,19.2c21.3,14.9,34.1,87.4,44.8,262.2c8.5,132.1,29.8,281.4,49,332.5c151.3,411.4,475.3,707.7,888.8,812.1l153.5,38.4v483.8c-2.1,264.3-4.3,511.6-4.3,545.7l-2.1,64l-1016.7-2.1C3400.4,4432.2,2899.5,4423.7,2848.4,4413z' />
          <path d='M5254.8,3890.8v-545.7l113,12.8c59.7,6.4,185.4,17.1,277.1,25.6c100.2,8.5,179.1,25.6,198.3,44.8c40.5,42.6,44.7,873.9,2.1,931.5c-19.2,27.7-76.7,40.5-228.1,55.4c-110.8,10.7-238.7,21.3-281.4,21.3h-81V3890.8z' />
          <path d='M6606.2,4097.5c-181.2-110.8-134.3-387.9,76.7-451.9c102.3-32,217.4,17.1,277.1,113C7098.6,3988.8,6838.5,4240.4,6606.2,4097.5z' />
          <path d='M8055.6,4065.6c-59.7-59.7-72.5-89.5-72.5-159.9c0-166.3,93.8-266.4,245.1-266.4s245.1,100.2,245.1,266.4c0,70.3-12.8,100.2-72.5,159.9c-61.8,61.8-87.4,72.5-172.6,72.5S8117.5,4127.4,8055.6,4065.6z' />
          <path d='M7371.4,3679.8c-81-34.1-134.3-127.9-134.3-234.5c0-151.3,136.4-262.2,292-238.7c219.6,36.2,281.4,330.4,93.8,456.2C7539.8,3718.1,7469.5,3722.4,7371.4,3679.8z' />
          <path d='M8121.7,3255.6c-89.5-38.4-138.5-121.5-138.5-230.2c0-106.6,40.5-174.8,127.9-219.5c113-59.7,255.8-23.4,328.3,78.9c19.2,25.6,34.1,91.7,34.1,144.9C8473.4,3225.8,8302.9,3334.5,8121.7,3255.6z' />
          <path d='M4783.8,2946.5c-134.3-53.3-279.2-155.6-368.7-264.3c-85.3-104.5-183.3-302.7-183.3-370.9c0-46.9,8.5-49,166.3-49h164.1l115.1-230.2c123.6-238.7,413.5-688.5,490.3-761c70.3-61.8,187.6-53.3,249.4,19.2c27.7,32,51.1,81,51.1,108.7s-53.3,144.9-117.2,260c-191.8,343.2-356,799.3-417.8,1146.7c-14.9,83.1-32,155.6-38.4,164.1C4888.2,2976.4,4837.1,2965.7,4783.8,2946.5z' />
          <path d='M2473.2,1465.1c-206.8-89.5-564.8-850.5-743.9-1579.4c-225.9-931.5-264.3-2088.9-108.7-3325.2c87.4-690.6,136.4-871.8,283.5-1065.8c91.7-121.5,247.3-234.5,366.6-266.4c55.4-14.9,596.8-21.3,1592.2-17.1l1509.1,6.4l106.6,57.6c292,153.5,439.1,462.5,569.1,1187.3c63.9,364.5,70.3,1074.3,10.7,1330c-106.6,451.9-387.9,824.9-1134,1496.3c-622.4,562.7-616,549.9-520.1,856.9c144.9,456.2,63.9,784.4-285.6,1170.2c-164.1,181.2-149.2,179-914.4,179C2701.3,1492.8,2522.2,1486.5,2473.2,1465.1z M3805.4,904.5c91.7-113,170.5-279.2,170.5-360.2c0-36.2-29.8-162-66.1-277.1c-108.7-355.9-68.2-586.2,151.3-856.9c51.2-59.7,251.5-253.7,445.5-428.4c584-522.2,837.7-805.7,980.5-1082.8c159.9-315.5,151.3-1048.7-19.2-1724.4c-76.7-307-142.8-424.2-251.5-456.2c-53.3-17-509.4-21.3-1447.3-17l-1368.4,6.4l-63.9,61.8c-83.1,81-119.4,194-172.7,535C2014.9-2704.1,1995.8-1623.4,2113-807c89.5,620.3,281.4,1242.7,511.6,1654l76.7,136.4h518h520.1L3805.4,904.5z' />
          <path d='M2980.5-97.2c8.5-432.7,25.6-797.2,44.8-954.9c19.2-140.7,34.1-272.8,34.1-292c0-34.1-27.7-38.4-306.9-38.4h-306.9l-17.1-164.1c-42.6-458.3,44.8-1773.4,153.5-2293.5l12.8-57.6h1229.9h1232l44.8,206.8c87.4,394.3,100.2,507.3,100.2,891v385.8l-78.9,157.7c-91.7,181.2-313.3,441.2-598.9,703.4L4334-1382.5h-243c-225.9,0-243-2.1-243-40.5c0-142.8,277.1-1093.5,424.2-1451.6c81-194,81-234.5,10.7-317.6c-42.6-51.1-72.5-66.1-134.3-66.1c-121.5,0-162,44.8-253.6,283.5c-383.7,995.4-537.1,1841.6-537.1,2984.1v590.4h-194h-196.1L2980.5-97.2z' />
        </g>
      </g>
    </svg>
  );
};

export default SanitationGraph;
