import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';
import LaborLogDetails from './LaborLogDetails';

const ValidationSchema = Yup.object().shape({
  workOrderId: Yup.string().required('Work Order is required'),
});

const LaborLogMoveForm = ({
  laborLog,
  workOrder,
  machine,
  jobType,
  workOrderList,
  machineList,
  jobTypeList,
  handleOnSubmit,
  handleOnCancel,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  useEffect(() => {
    setValue('laborLogId', laborLog.id);
  }, [laborLog, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper elevation={0}>
          <Box component="div" display="flex" mx={1} alignItems="center">
            <Box display="flex" my={1.5} flexGrow={1} alignItems="center">
              <Typography
                color="primary"
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'eTime - Move'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <LaborLogDetails
            laborLog={laborLog}
            workOrderCode={workOrder?.code}
            commodityName={workOrder?.commodity}
            machineName={machine?.name}
            jobTypeName={jobType?.name}
          />
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ minWidth: 250 }}>
                  <InputLabel id="wo-select-label">Work Order</InputLabel>
                  <Controller
                    name="workOrderId"
                    control={control}
                    defaultValue={workOrder.id}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="wo-select-label"
                        label={'Work Order'}
                        fullWidth
                        error={!!errors?.workOrderId}
                      >
                        {workOrderList.map((wo) => (
                          <MenuItem key={wo.id} value={wo.id}>
                            {wo.code}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <FormHelperText>{errors?.workOrderId?.message}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ minWidth: 250 }}>
                  <InputLabel id="machine-select-label">Machine</InputLabel>
                  <Controller
                    name="machineId"
                    control={control}
                    defaultValue={machine.id}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="machine-select-label"
                        label={'Machine'}
                        fullWidth
                        error={!!errors?.machineId}
                      >
                        {machineList.map((machine) => (
                          <MenuItem key={machine.id} value={machine.id}>
                            {machine.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <FormHelperText>{errors?.machineId?.message}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ minWidth: 250 }}>
                  <InputLabel id="job-select-label">Job</InputLabel>
                  <Controller
                    name="jobTypeId"
                    control={control}
                    defaultValue={jobType.id}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="job-select-label"
                        label={'Job'}
                        fullWidth
                        error={!!errors?.jobTypeId}
                      >
                        {jobTypeList.map((jobType) => (
                          <MenuItem key={jobType.id} value={jobType.id}>
                            {jobType.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <FormHelperText>{errors?.jobTypeId?.message}</FormHelperText>
              </Grid>
            </Grid>
          </Box>
          <Box component="div" mx={1} my={0.5} display="flex">
            <Box my={1.5} flexGrow={1}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                startIcon={<LoginIcon />}
              >
                {'Yes, Move'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant="outlined"
                size="large"
                startIcon={<UndoIcon />}
                onClick={handleOnCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default LaborLogMoveForm;
