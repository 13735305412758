import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  loadManualProfile,
  updateManualProfile,
} from '../../redux/eplan/profile-manual/services';
import { resetManualProfile } from '../../redux/eplan/profile-manual/actions';
import { Box, Paper, Typography, Divider } from '@mui/material';
import Loading from '../../components/UI/Loading';
import ManualProfileForm from '../../components/eplan/ManualProfileForm';
import isEmpty from 'is-empty';

const UpdateManualProfile = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const manualProfilesState = useSelector((state) => state.manual_profile);
  const manualProfileState = useSelector((state) => state.manual_profile.item);
  const onSubmit = (data) => {
    dispatch(updateManualProfile(id, data, navigate));
  };

  useEffect(() => {
    if (isEmpty(id)) {
      navigate('/eplan/manual_profiles');
    } else {
      dispatch(loadManualProfile(id));
    }
    return () => {
      dispatch(resetManualProfile());
    };
  }, [id, dispatch, navigate]);

  return (
    <>
      <Loading loading={manualProfilesState.loading} />
      <Paper elevation={0}>
        <Box display='flex' mx={1} mt={1}>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              ePlan - Update Manual Profile
            </Typography>
          </Box>
        </Box>
        <Divider variant='middle' light />
        <Box mx={1} mt={1}>
          {!isEmpty(manualProfileState) && !manualProfilesState.loading ? (
            <ManualProfileForm
              commodityId={manualProfileState.commodity.id}
              itemDescription={manualProfileState.itemDescription}
              onSubmit={onSubmit}
              savingData={manualProfilesState.savingData}
              editMode={true}
              manualProfile={manualProfileState}
            />
          ) : null}
        </Box>
      </Paper>
    </>
  );
};

export default UpdateManualProfile;
