import {
  SET_LABOR_LOG_LIST,
  LABOR_LOG_LIST_LOADING,
  LABOR_LOG_LIST_RESET,
  SET_LABOR_LOG,
  LABOR_LOG_LOADING,
  LABOR_LOG_RESET,
  UPDATE_LABOR_LOG_IN_LIST,
  SHOW_ADJUT_LABOR_LOG_FORM,
  SAVING_LABOR_LOG_DATA,
} from './types';

export const setLaborLogList = (list) => {
  return {
    type: SET_LABOR_LOG_LIST,
    payload: list,
  };
};

export const resetLaborLogList = () => {
  return {
    type: LABOR_LOG_LIST_RESET,
  };
};

export const setLaborLogListLoading = (payload) => {
  return {
    type: LABOR_LOG_LIST_LOADING,
    payload,
  };
};

export const setLaborLog = (item) => {
  return {
    type: SET_LABOR_LOG,
    payload: item,
  };
};

export const resetLaborLog = () => {
  return {
    type: LABOR_LOG_RESET,
  };
};

export const setLaborLogLoading = (payload) => {
  return {
    type: LABOR_LOG_LOADING,
    payload,
  };
};

export const updateLaborLogInList = (payload) => {
  return {
    type: UPDATE_LABOR_LOG_IN_LIST,
    payload,
  };
};

export const showAdjustLaborLogForm = (payload) => {
  return {
    type: SHOW_ADJUT_LABOR_LOG_FORM,
    payload,
  };
};

export const savingLaborLogData = (payload) => {
  return {
    type: SAVING_LABOR_LOG_DATA,
    payload,
  };
};
