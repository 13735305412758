import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createProfitProfile } from '../../redux/eprofit/profile-profit/services';
import { Box, Paper, Typography, Divider } from '@mui/material';
import Loading from '../../components/UI/Loading';
import ProfitProfileForm from '../../components/eprofit/ProfitProfileForm';

const UpdateProfitProfile = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const profitProfilesState = useSelector((state) => state.profit_profile);

  const onSubmit = (data) => {
    dispatch(createProfitProfile(data, navigate));
  };

  console.log('state', location.state);

  return (
    <>
      <Loading loading={profitProfilesState.loading} />
      <Paper elevation={0}>
        <Box display='flex' mx={1} mt={1}>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              eProfit - Create Profit Profile
            </Typography>
          </Box>
        </Box>
        <Divider variant='middle' light />
        <Box mx={1} mt={1}>
          <ProfitProfileForm
            machineId={location.state.machine}
            commodityId={location.state.commodity}
            itemDescription={location.state.itemDescription}
            onSubmit={onSubmit}
            savingData={profitProfilesState.savingData}
            editMode={false}
          />
        </Box>
      </Paper>
    </>
  );
};

export default UpdateProfitProfile;
