import React from 'react';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import PalletInIcon from '../UI/icons/PalletInIcon';
import PalletOutIcon from '../UI/icons/PalletOutIcon';
import MachineIcon from '../UI/icons/MachineIcon';
import CommodityIcon from '../UI/icons/CommodityIcon';
import ThroughputIcon from '../UI/icons/ThroughputIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { grey, green, red, orange, blue, purple } from '@mui/material/colors';
import { styled } from '@mui/system';
import MaintenanceGraph from '../UI/MaintenanceGraph';
import SanitationGraph from '../UI/SanitationGraph';
import SetupGraph from '../UI/SetupGraph';

const YieldCircle = styled('span')({
  width: '12px',
  height: '12px',
  backgroundColor: '#D62728',
  borderRadius: '50%',
  display: 'inline-block',
  marginLeft: '5px',
  marginRight: '5px',
});

const AvailabilityCircle = styled('span')({
  width: '12px',
  height: '12px',
  backgroundColor: '#2CA02C',
  borderRadius: '50%',
  display: 'inline-block',
  marginLeft: '5px',
  marginRight: '5px',
});

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const yieldChartOptions = {
  responsive: true,
  circumference: 180,
  rotation: -90,
  cutout: 50,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

const availabilityChartOptions = {
  responsive: true,
  circumference: 180,
  rotation: -90,
  cutout: 50,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

const ProductionDashboardTileItem = ({ workOrder }) => {
  const availability = parseFloat(workOrder.availability * 100).toFixed(1);
  const yieldCases = parseFloat(workOrder.yieldCases * 100).toFixed(1);
  const utilization = parseFloat(workOrder.utilization * 100).toFixed(0);
  const rateCases = parseFloat(workOrder.rateCases).toFixed(1);
  const waste = parseFloat(workOrder.waste * 100).toFixed(1);

  const yieldChartData = {
    labels: ['#D62728', '#e0e0e0'],
    datasets: [
      {
        data: [yieldCases, 100 - yieldCases],
        backgroundColor: ['#D62728', '#e0e0e0'],
        borderColor: ['#D62728', '#e0e0e0'],
      },
    ],
  };

  const availabilityChartData = {
    labels: ['#2CA02C', '#e0e0e0'],
    datasets: [
      {
        data: [availability, 100 - availability],
        backgroundColor: ['#2CA02C', '#e0e0e0'],
        borderColor: ['#2CA02C', '#e0e0e0'],
      },
    ],
  };

  return (
    <Card variant='outlined'>
      <CardContent
        sx={{
          padding: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <Box display='flex' flexDirection='column'>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-evenly'
            sx={{
              backgroundColor: getStatusColor(workOrder.status),
              minHeight: '40px',
            }}
          >
            <Box display='flex' alignItems='center'>
              <MachineIcon
                sx={{
                  color: grey['100'],
                  width: '18px',
                  height: '18px',
                }}
              />
              <Typography
                color={grey['100']}
                sx={{ fontSize: 20, fontWeight: 'medium', marginLeft: 0.5 }}
              >
                {workOrder.machine}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <CommodityIcon
                sx={{
                  color: grey['100'],
                  width: '18px',
                  height: '18px',
                }}
              />

              <Typography
                color={grey['100']}
                sx={{ fontSize: 20, fontWeight: 'medium', marginLeft: 0.5 }}
              >
                {workOrder.commodity}
              </Typography>
            </Box>
          </Box>
          <Divider light />
          {workOrder.status !== 'SETUP' &&
          workOrder.status !== 'SANITATION' &&
          workOrder.status !== 'NOT_AVAILABLE' ? (
            <>
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-around'
              >
                <Box
                  sx={{ height: '150px', width: '150px', position: 'relative' }}
                >
                  <Typography
                    variant='body2'
                    color={grey['700']}
                    sx={{ fontWeight: 'bold' }}
                  >
                    <YieldCircle />
                    Yield
                  </Typography>
                  <Typography
                    variant='h5'
                    sx={{
                      position: 'absolute',
                      top: '90%',
                      left: '50%',
                      transform: 'translate(-50%, -110%)',
                    }}
                  >{`${yieldCases}%`}</Typography>
                  <Doughnut
                    data={yieldChartData}
                    options={yieldChartOptions}
                    style={{
                      transform: 'translateY(-10px)',
                    }}
                  />
                </Box>
                <Box>
                  <Divider light orientation='vertical' />
                </Box>
                <Box
                  sx={{
                    height: '150px',
                    width: '150px',
                    position: 'relative',
                    marginLeft: 0.5,
                  }}
                >
                  <Typography
                    variant='h5'
                    sx={{
                      position: 'absolute',
                      top: '90%',
                      left: '50%',
                      transform: 'translate(-50%, -110%)',
                    }}
                  >{`${availability}%`}</Typography>
                  <Typography
                    variant='body2'
                    color={grey['700']}
                    sx={{ fontWeight: 'bold' }}
                  >
                    <AvailabilityCircle />
                    Availability
                  </Typography>

                  <Doughnut
                    data={availabilityChartData}
                    options={availabilityChartOptions}
                    style={{
                      transform: 'translateY(-10px)',
                    }}
                  />
                </Box>
              </Box>
              <Divider light />
              <Box
                display='flex'
                justifyContent='space-evenly'
                sx={{ backgroundColor: grey['50'] }}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  sx={{ width: '25%' }}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    sx={{ color: green['600'] }}
                  >
                    <Box>
                      <PalletInIcon sx={{ width: '24px', height: '24px' }} />
                    </Box>
                    <Box>
                      <Typography sx={{ marginLeft: 0.5, fontSize: 28 }}>
                        {workOrder.casesIn}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    sx={{ color: red['600'] }}
                  >
                    <Box>
                      <PalletOutIcon sx={{ width: '24px', height: '24px' }} />
                    </Box>
                    <Box>
                      <Typography sx={{ marginLeft: 0.5, fontSize: 28 }}>
                        {workOrder.casesOut}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Divider orientation='vertical' />
                </Box>
                <Box display='flex' flexDirection='row' alignItems='center'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    sx={{ color: purple['600'] }}
                  >
                    <Box display='flex' justifyContent='center'>
                      <ThroughputIcon
                        sx={{
                          width: '28px',
                          height: '28px',
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography sx={{ marginLeft: 0.2, fontSize: 28 }}>
                        {`${rateCases}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Divider orientation='vertical' />
                </Box>
                <Box display='flex' flexDirection='row' alignItems='center'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    sx={{ color: blue['600'] }}
                  >
                    <Box display='flex' justifyContent='center'>
                      <SettingsIcon sx={{ fontSize: 35 }} />
                    </Box>
                    <Box>
                      <Typography sx={{ marginLeft: 0.2, fontSize: 28 }}>
                        {`${utilization}%`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Divider orientation='vertical' />
                </Box>
                <Box display='flex' flexDirection='row' alignItems='center'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    sx={{ color: orange['600'] }}
                  >
                    <Box display='flex' justifyContent='center'>
                      <DeleteOutlineIcon sx={{ fontSize: 35 }} />
                    </Box>
                    <Box>
                      <Typography sx={{ marginLeft: 0.2, fontSize: 28 }}>
                        {`${waste}%`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box display='flex' justifyContent='center'>
              <Box
                sx={{
                  width: '180px',
                  height: '180px',
                  marginTop: '30px',
                  marginBottom: '30px',
                }}
              >
                {workOrder.status === 'SETUP' ? (
                  <SetupGraph
                    style={{ fill: getStatusColor(workOrder.status) }}
                  />
                ) : null}
                {workOrder.status === 'NOT_AVAILABLE' ? (
                  <MaintenanceGraph
                    style={{ fill: getStatusColor(workOrder.status) }}
                  />
                ) : null}
                {workOrder.status === 'SANITATION' ? (
                  <SanitationGraph
                    style={{ fill: getStatusColor(workOrder.status) }}
                  />
                ) : null}
              </Box>
            </Box>
          )}

          <Box
            display='flex'
            flexDirection='row'
            justifyContent='center'
            sx={{
              backgroundColor: getStatusColor(workOrder.status),
            }}
          >
            <Typography
              color={grey['100']}
              sx={{ fontSize: 14, fontWeight: 'medium' }}
            >
              {getStatusText(workOrder.status)}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case 'IN_PROGRESS':
      return '#2CA02C';
    case 'NOT_AVAILABLE':
      return '#D62728';
    case 'SETUP':
      return '#5e35b1';
    case 'SANITATION':
      return '#fb8c00';
    default:
      return '#757575';
  }
};

const getStatusText = (status) => {
  switch (status) {
    case 'AVAILABLE':
      return 'IDLE';
    case 'IN_PROGRESS':
      return 'IN PROGRESS';
    case 'NOT_AVAILABLE':
      return 'UNAVAILABLE';
    default:
      return status;
  }
};

export default ProductionDashboardTileItem;
