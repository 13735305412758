import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const OfflineAlert = ({ open }) => {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        return false;
      }}
      disableEscapeKeyDown
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        <Typography variant='h5'>Network Offline.</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1'>
          Seems like you've gone offline, you might want to wait until your
          network comes back before continuing.
        </Typography>
        <Typography variant='body1'>
          Make sure network cable is connected or Wifi turned on.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

OfflineAlert.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default OfflineAlert;
