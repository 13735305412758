import {
  SET_GATE_IN_LIST,
  GATE_IN_LIST_LOADING,
  GATE_IN_LIST_RESET,
  SET_GATE_IN_NAME_FILTER,
  SAVING_GATE_IN_DATA,
  SHOW_GATE_IN_FORM,
  TOGGLE_SELECT_GATE_IN_IN_LIST,
  SET_SELECT_ALL_GATE_IN_IN_LIST,
  DELETE_GATE_IN_FROM_LIST,
} from './types';
const initialState = {
  list: {},
  loading: false,
  filter: {
    name: '',
  },
  showGateInForm: false,
  savingData: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_GATE_IN_LIST:
      return {
        ...state,
        list: {
          ...action.payload,
          docs: action.payload.docs.map((row) => {
            return { ...row, selected: false };
          }),
        },
      };
    case GATE_IN_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GATE_IN_LIST_RESET:
      return {
        ...state,
        list: {},
      };
    case SET_GATE_IN_NAME_FILTER:
      return {
        ...state,
        filter: {
          name: action.payload,
        },
      };
    case SHOW_GATE_IN_FORM:
      return {
        ...state,
        showGateInForm: action.payload,
      };
    case SAVING_GATE_IN_DATA:
      return {
        ...state,
        savingData: action.payload,
      };
    case TOGGLE_SELECT_GATE_IN_IN_LIST:
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          docs: state.list.docs.map((row) => {
            if (row.id === action.payload.id) {
              return { ...action.payload, selected: !row.selected };
            }
            return row;
          }),
        },
      };
    case SET_SELECT_ALL_GATE_IN_IN_LIST:
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          docs: state.list.docs.map((row) => {
            return { ...row, selected: action.payload };
          }),
        },
      };
    case DELETE_GATE_IN_FROM_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          docs: state.list.docs.filter((item) => item.id !== action.payload),
          totalDocs: state.list.totalDocs - 1,
        },
      };
    default:
      return state;
  }
}
