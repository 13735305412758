import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getListWorkOrderList = createSelector(
  (state) => state.list_work_order.list,
  (workOrderList) => {
    if (isEmpty(workOrderList)) return [];
    return workOrderList.sort((a, b) => (a.code < b.code ? -1 : 1));
  }
);
