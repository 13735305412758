import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setCompany,
  resetCompany,
  setCompanyLoading,
  setCompanyList,
  resetCompanyList,
  setCompanyListLoading,
  addCompanyToList,
  deleteCompanyFromList,
  updateCompanyInList,
  savingCompanyData,
  showAddEditCompanyForm,
  setPrivateToken,
  setPrivateTokenLoading,
} from './actions';

export const loadCompanies = () => (dispatch, getState, api) => {
  dispatch(setCompanyListLoading(true));
  axios
    .get(`${api}/companies?limit=1000000`)
    .then((res) => {
      dispatch(resetCompanyList());
      dispatch(setCompanyList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCompanyListLoading(false));
    });
};

export const loadCompany = (id) => (dispatch, getState, api) => {
  dispatch(setCompanyLoading(true));
  axios
    .get(`${api}/companies/${id}`)
    .then((res) => {
      dispatch(resetCompany());
      dispatch(setCompany(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCompanyLoading(false));
    });
};

export const getCompanyPrivateToken = () => (dispatch, getState, api) => {
  dispatch(setPrivateTokenLoading(true));
  axios
    .post(`${api}/companies/private-token`)
    .then((res) => {
      dispatch(setPrivateToken(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setPrivateTokenLoading(false));
    });
};

export const createCompany = (data) => (dispatch, getState, api) => {
  dispatch(setCompanyListLoading(true));
  dispatch(savingCompanyData(true));
  axios
    .post(`${api}/companies`, data)
    .then((res) => {
      dispatch(addCompanyToList(res.data));
      dispatch(showAddEditCompanyForm(false));
      const notification = {
        message: '👍🏻 Company created successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCompanyListLoading(false));
      dispatch(savingCompanyData(false));
    });
};

export const updateCompany = (id, data) => (dispatch, getState, api) => {
  dispatch(setCompanyListLoading(true));
  dispatch(savingCompanyData(true));
  axios
    .put(`${api}/companies/${id}`, data)
    .then((res) => {
      dispatch(updateCompanyInList(res.data));
      dispatch(showAddEditCompanyForm(false));
      const notification = {
        message: '👍🏻 Company updated successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCompanyListLoading(false));
      dispatch(savingCompanyData(false));
    });
};

export const updateMainCompany = (id, data) => (dispatch, getState, api) => {
  dispatch(setCompanyLoading(true));
  dispatch(savingCompanyData(true));
  axios
    .put(`${api}/companies/${id}`, data)
    .then((res) => {
      const notification = {
        message: '👍🏻 Company updated successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCompanyLoading(false));
      dispatch(savingCompanyData(false));
    });
};

export const deleteCompany = (id) => (dispatch, getState, api) => {
  dispatch(setCompanyListLoading(true));
  axios
    .delete(`${api}/companies/${id}`)
    .then((res) => {
      dispatch(deleteCompanyFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Company deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setCompanyListLoading(false));
};

export const toggleCompanyStatus =
  (id, currentStatus) => (dispatch, getState, api) => {
    dispatch(setCompanyListLoading(true));
    axios
      .post(`${api}/companies/activate/${id}/${!currentStatus}`)
      .then((res) => {
        dispatch(loadCompanies());
        const notification = {
          message: '👍🏻 Company status updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
        dispatch(resetCompany());
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setCompanyListLoading(false));
      });
  };

export const emailRegistration = (id) => (dispatch, getState, api) => {
  dispatch(setCompanyListLoading(true));
  axios
    .post(`${api}/companies/send-registration-approval-email/${id}`)
    .then((res) => {
      dispatch(loadCompanies());
      const notification = {
        message: '👍🏻 Email sent successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
      dispatch(resetCompany());
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong or company already activated',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCompanyListLoading(false));
    });
};

export const approveRegistration = (id) => (dispatch, getState, api) => {
  dispatch(setCompanyListLoading(true));
  axios
    .post(`${api}/companies/approve-registration/${id}`)
    .then((res) => {
      const notification = {
        message: '👍🏻 Company registration approved successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCompanyListLoading(false));
    });
};
