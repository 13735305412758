import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getCustomerList = createSelector(
  (state) => state.customers.list.docs,
  (customerList) => {
    if (isEmpty(customerList)) return [];
    return customerList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
