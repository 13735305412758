import {
  SET_REGISTRATION_LOADING,
  SET_REGISTRATION_COMPLETE,
  SET_COMPANY_NAME_TAKEN,
  SET_COMPANY_EMAIL_TAKEN,
  SET_USER_EMAIL_TAKEN,
  SET_VALIDATING_REGISTRATION_DATA,
} from './types';

export const setRegistrationLoading = (payload) => {
  return {
    type: SET_REGISTRATION_LOADING,
    payload,
  };
};

export const setRegistrationComplete = (payload) => {
  return {
    type: SET_REGISTRATION_COMPLETE,
    payload,
  };
};

export const setCompanyNameTaken = (payload) => {
  return {
    type: SET_COMPANY_NAME_TAKEN,
    payload,
  };
};

export const setCompanyEmailTaken = (payload) => {
  return {
    type: SET_COMPANY_EMAIL_TAKEN,
    payload,
  };
};

export const setUserEmailTaken = (payload) => {
  return {
    type: SET_USER_EMAIL_TAKEN,
    payload,
  };
};

export const setValidationRegistrationData = (payload) => {
  return {
    type: SET_VALIDATING_REGISTRATION_DATA,
    payload,
  };
};
