import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getMachineTypeList = createSelector(
  (state) => state.machine_types.list.docs,
  (machineTypesList) => {
    if (isEmpty(machineTypesList)) return [];
    return machineTypesList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
