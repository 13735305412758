import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setManualProfile,
  resetManualProfile,
  resetManualProfileList,
  setManualProfileFilters,
  showDuplicateManualProfileForm,
} from '../../redux/eplan/profile-manual/actions';
import {
  loadManualProfiles,
  deleteManualProfile,
  duplicateManualProfile,
} from '../../redux/eplan/profile-manual/services';
import {
  selectFilteredManualProfiles,
  selectExistingManualProfile,
} from '../../redux/eplan/profile-manual/selectors';
import { loadCommodityList } from '../../redux/list/commodities/services';
import { resetCommodityList } from '../../redux/list/commodities/actions';
import { getCommodityList } from '../../redux/list/commodities/selectors';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  Divider,
} from '@mui/material';
import Loading from '../../components/UI/Loading';
import ManualProfileFilterForm from '../../components/eplan/ManualProfileFilterForm';
import Empty from '../../components/UI/Empty';
import ManualProfilesTable from '../../components/eplan/ManualProfilesTable';
import ManualProfileDuplicateForm from '../../components/eplan/ManualProfileDuplicateForm';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import SyncIcon from '@mui/icons-material/Sync';

const isEmpty = require('is-empty');

const ManualProfiles = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const manualProfilesState = useSelector((state) => state.manual_profile);
  const manualProfileState = useSelector((state) => state.manual_profile.item);
  const ManualProfilesList = useSelector(selectFilteredManualProfiles);
  const ExistingManualProfile = useSelector(selectExistingManualProfile);
  const CommodityList = useSelector(getCommodityList);
  const [openRemoveManualProfileDialog, setOpenRemoveManualProfileDialog] =
    useState(false);

  useEffect(() => {
    dispatch(loadManualProfiles());
    dispatch(loadCommodityList());
    return () => {
      dispatch(resetManualProfileList());
      dispatch(resetCommodityList());
    };
  }, [dispatch]);

  const handleClickAdd = (machine, commodity, itemDescription) => {
    navigate('/eplan/manual_profiles/create', {
      state: {
        machine: machine,
        commodity: commodity,
        itemDescription: itemDescription,
      },
    });
  };

  const handleClickEdit = (machineProfile) => {
    dispatch(setManualProfile(machineProfile));
    navigate(`/eplan/manual_profiles/update/${machineProfile.id}`);
  };

  const handleOnFilter = (data) => {
    dispatch(setManualProfileFilters(data));
  };

  const handleRefresh = () => {
    dispatch(loadManualProfiles());
  };

  const handleClickRemove = (machineProfile) => {
    dispatch(setManualProfile(machineProfile));
    setOpenRemoveManualProfileDialog(true);
  };

  const onRemoveManualProfile = () => {
    dispatch(deleteManualProfile(manualProfileState.id));
    dispatch(resetManualProfile());
    setOpenRemoveManualProfileDialog(false);
  };

  const handleClickDuplicate = (machineProfile) => {
    dispatch(setManualProfile(machineProfile));
    dispatch(showDuplicateManualProfileForm(true));
  };

  const handleOnDuplicate = (data) => {
    dispatch(duplicateManualProfile(manualProfileState.id, data));
  };

  return (
    <>
      <Loading loading={manualProfilesState.loading} />
      <Paper elevation={0}>
        <Box display='flex' mx={1} mt={1}>
          <Box display='flex' alignContent='center'>
            <IconButton aria-label='reload' onClick={handleRefresh}>
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              ePlan - Manual Machine Profile
            </Typography>
          </Box>
        </Box>
        <Divider variant='middle' light />
        <Box mx={1} mt={1}>
          <ManualProfileFilterForm
            commodities={CommodityList}
            existingManualProfile={ExistingManualProfile}
            handleOnFilter={handleOnFilter}
            handleClickAdd={handleClickAdd}
          />
        </Box>

        <Box mx={1}>
          {!isEmpty(manualProfilesState.list) && (
            <ManualProfilesTable
              rows={ManualProfilesList}
              handleClickEdit={handleClickEdit}
              handleClickRemove={handleClickRemove}
              handleClickDuplicate={handleClickDuplicate}
            />
          )}
          {isEmpty(manualProfilesState.list) &&
            !manualProfilesState.loading && (
              <Empty description='There are no machine profiles to show' />
            )}
        </Box>
      </Paper>
      <ConfirmationDialog
        open={openRemoveManualProfileDialog}
        handleNo={() => {
          setOpenRemoveManualProfileDialog(false);
        }}
        handleYes={onRemoveManualProfile}
        title='ePlan - Remove Manual Machine Profile'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to remove machine profile for machine : ${manualProfileState?.machine?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <Dialog
        open={manualProfilesState.showDuplicateForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            dispatch(showDuplicateManualProfileForm(false));
            dispatch(resetManualProfile());
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <ManualProfileDuplicateForm
            commodities={CommodityList}
            handleOnDuplicate={handleOnDuplicate}
            handleOnCancel={() => {
              dispatch(showDuplicateManualProfileForm(false));
              dispatch(resetManualProfile());
            }}
            savingData={manualProfilesState.savingData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ManualProfiles;
