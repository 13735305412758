import React, { useState, useEffect, createRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  FormHelperText,
  FormControl,
  Card,
  CardHeader,
  CardContent,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import * as Yup from 'yup';
import { states } from '../../utils/states';
import { countries } from '../../utils/countries';
import ReCAPTCHA from 'react-google-recaptcha';

const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string()
    .email('Please provide a valid email')
    .required('Required'),
  userFirstName: Yup.string().required('Required'),
  userLastName: Yup.string().required('Required'),
  userPhone: Yup.string().required('Required'),
  userPosition: Yup.string().required('Required'),
  userEmail: Yup.string()
    .email('Please provide a valid email')
    .required('Required'),
  addressLine1: Yup.string().required('Required'),
  addressCity: Yup.string().required('Required'),
  addressState: Yup.string().required('Required'),
  addressZip: Yup.string().required('Required'),
  addressCountry: Yup.string().required('Required'),
});

const RegisterForm = ({
  handleOnSubmit,
  loading,
  validating,
  companyNameTaken,
  companyEmailTaken,
  userEmailTaken,
  handleOnValidateCompanyName,
  handleOnValidateCompanyEmail,
  handleOnValidateUserEmail,
}) => {
  const [validCaptcha, setValidCaptcha] = useState(false);
  const recaptchaRef = createRef();
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  useEffect(() => {
    if (companyNameTaken === true) {
      setError('name', { type: 'custom', message: 'Company name is taken' });
    } else {
      clearErrors('name');
    }

    if (companyEmailTaken === true) {
      setError('email', { type: 'custom', message: 'Company email is taken' });
    } else {
      clearErrors('email');
    }

    if (userEmailTaken === true) {
      setError('userEmail', { type: 'custom', message: 'User email is taken' });
    } else {
      clearErrors('userEmail');
    }

    return () => {};
  }, [
    companyNameTaken,
    companyEmailTaken,
    userEmailTaken,
    setError,
    clearErrors,
  ]);

  const onChangeCaptcha = (value) => {
    setValidCaptcha(true);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          let companyData = {
            name: data.name,
            email: data.email,
            address: {
              line1: data.addressLine1,
              line2: data.addressLine2,
              city: data.addressCity,
              state: data.addressState,
              zip: data.addressZip,
              country: data.addressCountry,
            },
            user: {
              firstName: data.userFirstName,
              lastName: data.userLastName,
              email: data.userEmail,
              phone: data.userPhone,
              position: data.userPosition,
            },
          };
          handleOnSubmit(companyData);
        })}
      >
        <Paper elevation={0} sx={{ maxWidth: '550px' }}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box display='flex' my={1.5} flexGrow={1} alignItems='center'>
              <Typography
                color='primary'
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'Company Registration Form'}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12}>
                <Controller
                  name='name'
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Name'
                      fullWidth
                      error={!!errors?.name}
                      helperText={errors?.name?.message}
                      onBlur={(e) => {
                        if (e.target.value !== '') {
                          handleOnValidateCompanyName(e.target.value);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name='email'
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Email'
                      fullWidth
                      error={!!errors?.email}
                      helperText={errors?.email?.message}
                      onBlur={(e) => {
                        if (e.target.value !== '') {
                          handleOnValidateCompanyEmail(e.target.value);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Card variant='outlined'>
                  <CardHeader
                    title='Address'
                    sx={{ paddingBottom: 1, paddingTop: 1 }}
                  />
                  <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name='addressLine1'
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='Street'
                              fullWidth
                              error={!!errors?.addressLine1}
                              helperText={errors?.addressLine1?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name='addressLine2'
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='APT/Suite/PO Box'
                              fullWidth
                              error={!!errors?.addressLine2}
                              helperText={errors?.addressLine2?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name='addressCity'
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='City'
                              fullWidth
                              error={!!errors?.addressCity}
                              helperText={errors?.addressCity?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ minWidth: 200 }}>
                          <InputLabel id='state-select-label'>State</InputLabel>
                          <Controller
                            name='addressState'
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <Select
                                {...field}
                                labelId='state-select-label'
                                label={'State'}
                                fullWidth
                                error={!!errors?.addressState}
                              >
                                {states.map((state) => (
                                  <MenuItem
                                    key={state.abbreviation}
                                    value={state.abbreviation}
                                  >
                                    {`${state.name} (${state.abbreviation})`}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {errors?.addressState?.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name='addressZip'
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='ZIP'
                              fullWidth
                              error={!!errors?.addressZip}
                              helperText={errors?.addressZip?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormControl fullWidth sx={{ minWidth: 250 }}>
                          <InputLabel id='state-select-label'>
                            Country
                          </InputLabel>
                          <Controller
                            name='addressCountry'
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <Select
                                {...field}
                                labelId='state-select-label'
                                label={'Country'}
                                fullWidth
                                error={!!errors?.addressCountry}
                              >
                                {countries.map((country) => (
                                  <MenuItem
                                    key={country.code}
                                    value={country.code}
                                  >
                                    {`${country.name} (${country.code})`}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {errors?.addressCountry?.message}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card variant='outlined'>
                  <CardHeader
                    title='User Information'
                    sx={{ paddingBottom: 1, paddingTop: 1 }}
                  />
                  <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name='userFirstName'
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='First Name'
                              fullWidth
                              error={!!errors?.userFirstName}
                              helperText={errors?.userFirstName?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name='userLastName'
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='Last Name'
                              fullWidth
                              error={!!errors?.userLastName}
                              helperText={errors?.userLastName?.message}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Controller
                          name='userPhone'
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='Phone'
                              fullWidth
                              error={!!errors?.userPhone}
                              helperText={errors?.userPhone?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name='userPosition'
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='Position'
                              fullWidth
                              error={!!errors?.userPosition}
                              helperText={errors?.userPosition?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name='userEmail'
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='Email'
                              fullWidth
                              error={!!errors?.userEmail}
                              helperText={errors?.userEmail?.message}
                              onBlur={(e) => {
                                if (e.target.value !== '') {
                                  handleOnValidateUserEmail(e.target.value);
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Box component='div' my={1}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={reCaptchaSiteKey}
              onChange={onChangeCaptcha}
            />
          </Box>
          <Box component='div' my={1} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
                disabled={
                  loading ||
                  validating ||
                  companyNameTaken ||
                  companyEmailTaken ||
                  userEmailTaken ||
                  !validCaptcha
                }
              >
                {loading ? 'Submitting...' : 'Register'}
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default RegisterForm;
