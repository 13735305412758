import {
  SET_JOB_TYPE,
  JOB_TYPE_LOADING,
  JOB_TYPE_RESET,
  SET_JOB_TYPES,
  JOB_TYPE_LIST_LOADING,
  JOB_TYPE_LIST_RESET,
  ADD_JOB_TYPE_TO_LIST,
  UPDATE_JOB_TYPE_IN_LIST,
  DELETE_JOB_TYPE_FROM_LIST,
  SHOW_ADD_EDIT_JOB_TYPE_FORM,
  SAVING_JOB_TYPE_DATA,
} from './types';

export const setJobType = (item) => {
  return {
    type: SET_JOB_TYPE,
    payload: item,
  };
};

export const resetJobType = () => {
  return {
    type: JOB_TYPE_RESET,
  };
};

export const setJobTypeLoading = (payload) => {
  return {
    type: JOB_TYPE_LOADING,
    payload,
  };
};

export const setJobTypeList = (list) => {
  return {
    type: SET_JOB_TYPES,
    payload: list,
  };
};

export const resetJobTypeList = () => {
  return {
    type: JOB_TYPE_LIST_RESET,
  };
};

export const setJobTypeListLoading = (payload) => {
  return {
    type: JOB_TYPE_LIST_LOADING,
    payload,
  };
};

export const addJobTypeToList = (payload) => {
  return {
    type: ADD_JOB_TYPE_TO_LIST,
    payload,
  };
};

export const deleteJobTypeFromList = (payload) => {
  return {
    type: DELETE_JOB_TYPE_FROM_LIST,
    payload,
  };
};

export const updateJobTypeInList = (payload) => {
  return {
    type: UPDATE_JOB_TYPE_IN_LIST,
    payload,
  };
};

export const showAddEditJobTypeForm = (payload) => {
  return {
    type: SHOW_ADD_EDIT_JOB_TYPE_FORM,
    payload,
  };
};

export const savingJobTypeData = (payload) => {
  return {
    type: SAVING_JOB_TYPE_DATA,
    payload,
  };
};
