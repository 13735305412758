import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetCompany } from '../../redux/general/companies/actions';
import { addNotification } from '../../redux/system/notifications/actions';
import {
  loadCompany,
  updateMainCompany,
  getCompanyPrivateToken,
} from '../../redux/general/companies/services';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  Grid,
  Box,
  Paper,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useClipboard } from 'use-clipboard-copy';
import Loading from '../../components/UI/Loading';
import MainCompanyForm from '../../components/company/MainCompanyForm';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import isEmpty from 'is-empty';

const appURL = process.env.REACT_APP_URL;

const Main = () => {
  const dispatch = useDispatch();
  const clipboard = useClipboard();
  const authState = useSelector((state) => state.auth);
  const companiesState = useSelector((state) => state.companies);

  const mainDashboardUrl = isEmpty(companiesState.item.privateToken)
    ? ''
    : `${appURL}dashboard/main/${companiesState.item.privateToken}`;
  const productionDashboardUrl = isEmpty(companiesState.item.privateToken)
    ? ''
    : `${appURL}dashboard/production/${companiesState.item.privateToken}`;
  const laborDashboardUrl = isEmpty(companiesState.item.privateToken)
    ? ''
    : `${appURL}dashboard/labor/${companiesState.item.privateToken}`;

  useEffect(() => {
    dispatch(loadCompany(authState.user.company.id));
    return () => {
      dispatch(resetCompany());
    };
  }, [dispatch, authState.user.company.id]);

  const onSubmit = (data) => {
    dispatch(updateMainCompany(authState.user.company.id, data));
  };

  const handleCopyURL = (url) => {
    clipboard.copy(url);
    const notification = {
      message: 'URL copied to clipboard',
      options: {
        variant: 'success',
      },
    };
    dispatch(addNotification(notification));
  };

  const onGeneratePrivateToken = () => {
    dispatch(getCompanyPrivateToken());
  };

  return (
    <>
      <Loading loading={companiesState.loading} />
      <Paper elevation={0}>
        <Box display='flex' flexDirection='column'>
          <Box display='flex' mx={1} mt={1}>
            {!isEmpty(companiesState.item) && (
              <MainCompanyForm
                company={companiesState.item}
                handleOnSubmit={onSubmit}
                savingData={companiesState.savingData}
              />
            )}
          </Box>

          <Box display='flex' mx={1} my={1}>
            <Accordion
              disableGutters
              elevation={0}
              square
              variant='outlined'
              sx={{ width: '100%' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='dashboard-urls-content'
                id='dashboard-urls-header'
              >
                <Box display='flex' alignItems='center'>
                  <Typography variant='h6' sx={{ marginLeft: 1 }}>
                    Dashboards Private URLs
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    {isEmpty(companiesState.item.privateToken) ? (
                      <Button onClick={onGeneratePrivateToken}>
                        Generate Private Token
                      </Button>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <InputLabel htmlFor='main-dashboard-url'>
                        Main Dashboard URL
                      </InputLabel>
                      <OutlinedInput
                        id='main-dashboard-url'
                        value={mainDashboardUrl}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={() => handleCopyURL(mainDashboardUrl)}
                              edge='end'
                            >
                              <ContentPasteIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }} variant='outlined'>
                      <InputLabel htmlFor='production-dashboard-url'>
                        Production Dashboard URL
                      </InputLabel>
                      <OutlinedInput
                        id='production-dashboard-url'
                        value={productionDashboardUrl}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={() =>
                                handleCopyURL(productionDashboardUrl)
                              }
                              edge='end'
                            >
                              <ContentPasteIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <InputLabel htmlFor='labor-dashboard-url'>
                        Labor Dashboard URL
                      </InputLabel>
                      <OutlinedInput
                        id='labor-dashboard-url'
                        value={laborDashboardUrl}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={() => handleCopyURL(laborDashboardUrl)}
                              edge='end'
                            >
                              <ContentPasteIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default Main;
