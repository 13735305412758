export const SET_EMPLOYER = 'SET_EMPLOYER';
export const EMPLOYER_LOADING = 'EMPLOYER_LOADING';
export const EMPLOYER_RESET = 'EMPLOYER_RESET';
export const SET_EMPLOYERS = 'SET_EMPLOYERS';
export const EMPLOYER_LIST_LOADING = 'EMPLOYER_LIST_LOADING';
export const EMPLOYER_LIST_RESET = 'EMPLOYER_LIST_RESET';
export const ADD_EMPLOYER_TO_LIST = 'ADD_EMPLOYER_TO_LIST';
export const UPDATE_EMPLOYER_IN_LIST = 'UPDATE_EMPLOYER_IN_LIST';
export const DELETE_EMPLOYER_FROM_LIST = 'DELETE_EMPLOYER_FROM_LIST';
export const SHOW_ADD_EDIT_EMPLOYER_FORM = 'SHOW_ADD_EDIT_EMPLOYER_FORM';
export const SAVING_EMPLOYER_DATA = 'SAVING_EMPLOYER_DATA';
