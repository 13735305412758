import { useLocation, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../redux/system/auth/actions';
import axios from 'axios';

const RequireAuth = ({ children }) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const isAuthenticated = localStorage.jwt_Tokens;

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        dispatch(logoutUser(location));
      }
      return Promise.reject(error);
    }
  );

  if (!isAuthenticated) {
    // Redirect them to the login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/auth/login' state={{ from: location }} />;
  }

  return children;
};

export default RequireAuth;
