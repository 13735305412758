import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Menu, MenuItem } from '@mui/material';
import MenuVisibilityGate from '../../utils/MenuVisibilityGate';

const isDemo = process.env.REACT_APP_DEMO === 'true' ? true : false;

const EPlanMenu = ({ anchorEl, open, handleClose, userRole }) => {
  let navigate = useNavigate();
  return (
    <Menu
      id='e-plan-menu'
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      getcontentanchorel={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MenuVisibilityGate
        userRole={userRole}
        allowedPermissions={['eplan:machineprofile']}
      >
        <MenuItem
          disabled={isDemo}
          onClick={() => {
            navigate('/eplan/machine_profiles');
            handleClose();
          }}
        >
          Machine Profile
        </MenuItem>
        <Divider />
      </MenuVisibilityGate>
      <MenuVisibilityGate
        userRole={userRole}
        allowedPermissions={['eplan:mormachine']}
      >
        <MenuItem
          disabled={isDemo}
          onClick={() => {
            navigate('/eplan/mor_profiles');
            handleClose();
          }}
        >
          MOR Machine
        </MenuItem>
        <Divider />
      </MenuVisibilityGate>
      <MenuVisibilityGate
        userRole={userRole}
        allowedPermissions={['eplan:mormanual']}
      >
        <MenuItem
          disabled={isDemo}
          onClick={() => {
            navigate('/eplan/manual_profiles');
            handleClose();
          }}
        >
          MOR Manual
        </MenuItem>
      </MenuVisibilityGate>
    </Menu>
  );
};

export default EPlanMenu;
