import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setListWorkOrderList,
  resetListWorkOrderList,
  setListWorkOrderListLoading,
} from './actions';

export const loadWorkOrderList = () => (dispatch, getState, api) => {
  dispatch(setListWorkOrderListLoading(true));
  axios
    .get(`${api}/list/work-orders`)
    .then((res) => {
      dispatch(resetListWorkOrderList());
      dispatch(setListWorkOrderList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setListWorkOrderListLoading(false));
    });
};
