import React, { Fragment } from 'react';
import {
  Box,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import ProductionLineStatus from './ProductionLineStatus';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PalletInIcon from '../UI/icons/PalletInIcon';
import PalletOutIcon from '../UI/icons/PalletOutIcon';
import MachineIcon from '../UI/icons/MachineIcon';
import CommodityIcon from '../UI/icons/CommodityIcon';
import CleaningIcon from '../UI/icons/CleaningIcon';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ListAltIcon from '@mui/icons-material/ListAlt';
import StopIcon from '@mui/icons-material/Stop';
import { grey } from '@mui/material/colors';
import { disabledByStatus } from '../../utils/disabledByStatus';

const ProductionLineTileList = ({
  productionLines,
  workOrderView,
  commodityView,
  machineView,
  onStartProductionLine,
  onStopProductionLine,
  handleClickCloseProductionLine,
  handleClickUnavailableProductionLine,
  handleClickSetupChecklist,
  handleClickSanitation,
  handleClickClipboard,
  handleClickWIPDashboard,
}) => {
  return (
    <>
      <List
        dense
        sx={{
          paddingTop: '0',
          paddingBottom: '0',
          width: '100%',
        }}
      >
        {productionLines.map((productionLine, index) => (
          <Fragment key={productionLine.id}>
            <ListItem dense>
              <ListItemText
                primary={
                  <Box display='flex'>
                    <Box display='flex' flexGrow={1} mt={0.5}>
                      {workOrderView && (
                        <Box
                          display='flex'
                          alignItems='center'
                          sx={{
                            width: '28%',
                          }}
                        >
                          <MachineIcon
                            sx={{
                              color: grey['600'],
                              width: '18px',
                              height: '18px',
                            }}
                          />
                          <Typography component='span' ml={1}>
                            {productionLine.machine.name}
                          </Typography>
                        </Box>
                      )}
                      {commodityView && (
                        <>
                          <Box
                            display='flex'
                            alignItems='center'
                            sx={{
                              width: '28%',
                            }}
                          >
                            <ListAltIcon
                              sx={{
                                color: grey['600'],
                                width: '18px',
                                height: '18px',
                              }}
                            />
                            <Typography component='span' ml={1}>
                              {productionLine.workOrder.code}
                            </Typography>
                          </Box>
                          <Box
                            display='flex'
                            alignItems='center'
                            sx={{
                              width: '28%',
                            }}
                          >
                            <MachineIcon
                              sx={{
                                color: grey['600'],
                                width: '18px',
                                height: '18px',
                              }}
                            />
                            <Typography component='span' ml={1}>
                              {productionLine.machine.name}
                            </Typography>
                          </Box>
                        </>
                      )}
                      {machineView && (
                        <>
                          <Box
                            display='flex'
                            alignItems='center'
                            sx={{
                              width: '28%',
                            }}
                          >
                            <ListAltIcon
                              sx={{
                                color: grey['600'],
                                width: '18px',
                                height: '18px',
                              }}
                            />
                            <Typography component='span' ml={1}>
                              {productionLine.workOrder.code}
                            </Typography>
                          </Box>
                          <Box
                            display='flex'
                            alignItems='center'
                            sx={{
                              width: '28%',
                            }}
                          >
                            <CommodityIcon
                              sx={{
                                color: grey['600'],
                                width: '18px',
                                height: '18px',
                              }}
                            />
                            <Typography component='span' ml={1}>
                              {productionLine.commodity.name}
                            </Typography>
                          </Box>
                        </>
                      )}
                      <Box
                        sx={{
                          width: '28%',
                        }}
                        display='flex'
                        alignItems='center'
                        ml={2}
                      >
                        <ProductionLineStatus status={productionLine.status} />
                      </Box>
                      <Box
                        sx={{
                          width: '10%',
                        }}
                        display='flex'
                        alignItems='center'
                        ml={2}
                      >
                        <PalletInIcon
                          sx={{
                            color: grey['600'],
                            width: '18px',
                            height: '18px',
                          }}
                        />
                        <Typography
                          component='span'
                          sx={{
                            marginLeft: 1,
                          }}
                        >
                          {` ${productionLine.totalPalletsIn}`}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: '10%',
                        }}
                        display='flex'
                        alignItems='center'
                        ml={2}
                      >
                        <PalletOutIcon
                          sx={{
                            color: grey['600'],
                            width: '18px',
                            height: '18px',
                          }}
                        />
                        <Typography component='span' sx={{ marginLeft: 1 }}>
                          {` ${productionLine.totalPalletsOut}`}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Tooltip title='Setup Check List'>
                        <IconButton
                          onClick={() => {
                            handleClickSetupChecklist(productionLine.id);
                          }}
                          disabled={disabledByStatus(productionLine.status, [
                            'AVAILABLE',
                            'IN_PROGRESS',
                            'NOT_AVAILABLE',
                            'SANITATION',
                          ])}
                          color='primary'
                        >
                          <PlaylistAddCheckIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Start Production Line'>
                        <IconButton
                          onClick={() =>
                            onStartProductionLine(productionLine.id)
                          }
                          disabled={disabledByStatus(productionLine.status, [
                            'IN_PROGRESS',
                            'SANITATION',
                          ])}
                          sx={{ color: 'success.main' }}
                        >
                          <PlayArrowIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Stop Production Line'>
                        <IconButton
                          onClick={() =>
                            onStopProductionLine(productionLine.id)
                          }
                          disabled={disabledByStatus(productionLine.status, [
                            'SETUP',
                            'AVAILABLE',
                            'SANITATION',
                          ])}
                          sx={{ color: 'error.main' }}
                        >
                          <StopIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Production Clipboard'>
                        <IconButton
                          onClick={() =>
                            handleClickClipboard(productionLine.id)
                          }
                          disabled={disabledByStatus(productionLine.status, [
                            'SETUP',
                            'NOT_AVAILABLE',
                            'SANITATION',
                          ])}
                          color='primary'
                        >
                          <AssignmentIcon />
                        </IconButton>
                      </Tooltip>
                      <ThreeDotsMenu
                        data={productionLine}
                        handleClickSanitation={handleClickSanitation}
                        handleClickCloseProductionLine={
                          handleClickCloseProductionLine
                        }
                        handleClickUnavailableProductionLine={
                          handleClickUnavailableProductionLine
                        }
                        handleClickWIPDashboard={handleClickWIPDashboard}
                      />
                    </Box>
                  </Box>
                }
              />
            </ListItem>
            {index !== productionLines.length - 1 ? <Divider /> : null}
          </Fragment>
        ))}
      </List>
    </>
  );
};

const ThreeDotsMenu = ({
  data,
  handleClickSanitation,
  handleClickCloseProductionLine,
  handleClickUnavailableProductionLine,
  handleClickWIPDashboard,
}) => {
  const [anchorElPL, setAnchorElPL] = React.useState(null);
  const openPLMenu = Boolean(anchorElPL);

  const handleMorePLMenuClick = (event) => {
    setAnchorElPL(event.currentTarget);
  };

  const handleClosePLMenu = () => {
    setAnchorElPL(null);
  };

  return (
    <React.Fragment>
      <Tooltip title='More Options'>
        <IconButton onClick={handleMorePLMenuClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id='more-menu'
        anchorEl={anchorElPL}
        keepMounted
        open={openPLMenu}
        onClose={handleClosePLMenu}
      >
        <MenuItem
          onClick={() => {
            handleClickWIPDashboard(data.id);
            handleClosePLMenu();
          }}
          disabled={disabledByStatus(data.status, [
            'SETUP',
            'NOT_AVAILABLE',
            'SANITATION',
          ])}
        >
          <ListItemIcon>
            <DashboardIcon color='primary' />
          </ListItemIcon>
          <ListItemText>WIP Dashboard (Work In Progress)</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClickSanitation(data.id);
            handleClosePLMenu();
          }}
          disabled={disabledByStatus(data.status, ['SETUP', 'NOT_AVAILABLE'])}
        >
          <ListItemIcon>
            <CleaningIcon color='primary' />
          </ListItemIcon>
          <ListItemText>Sanitization Log</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClickCloseProductionLine(data);
            handleClosePLMenu();
          }}
        >
          <ListItemIcon>
            <HighlightOffIcon sx={{ color: 'error.main' }} />
          </ListItemIcon>
          <ListItemText>Close Production Line</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClickUnavailableProductionLine(data);
            handleClosePLMenu();
          }}
          disabled={disabledByStatus(data.status, [
            'SANITATION',
            'NOT_AVAILABLE',
          ])}
        >
          <ListItemIcon>
            <ReportProblemIcon color='error' />
          </ListItemIcon>
          <ListItemText>Report Unavailable</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ProductionLineTileList;
