import React from 'react';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import {
  Box,
  Divider,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  ListItemText,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/system';

const DataGrid = styled(MuiDataGrid)({
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
});

const CompaniesTable = ({
  rows,
  handleClickEdit,
  handleClickRemove,
  handleClickToogleStatus,
  handleClickEmailRegistration,
  handleClickApproveRegistration,
  handleClickSubscription,
}) => {
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      hideable: false,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      hideable: false,
      editable: false,
    },
    {
      field: 'active',
      headerName: 'Active',
      width: 150,
      hideable: false,
      editable: false,
      valueGetter: (params) => {
        return !!params.row.active ? 'Yes' : 'No';
      },
    },
    {
      field: 'needsApproval',
      headerName: 'Needs Approval?',
      width: 150,
      hideable: false,
      editable: false,
      renderCell: (cellValues) => {
        return !!cellValues.row.needsApproval ? (
          <Typography variant='body2' sx={{ color: 'red', fontWeight: 'bold' }}>
            Yes
          </Typography>
        ) : (
          <Typography variant='body2'>No</Typography>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 80,
      type: 'actions',
      hideable: false,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <Box>
            <ThreeDotsMenu
              data={cellValues.row}
              handleClickEdit={handleClickEdit}
              handleClickToogleStatus={handleClickToogleStatus}
              handleClickApproveRegistration={handleClickApproveRegistration}
              handleClickEmailRegistration={handleClickEmailRegistration}
              handleClickRemove={handleClickRemove}
              handleClickSubscription={handleClickSubscription}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box mx={1} my={1} sx={{ height: '550px', width: '98%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
      />
    </Box>
  );
};

const ThreeDotsMenu = ({
  data,
  handleClickEdit,
  handleClickToogleStatus,
  handleClickApproveRegistration,
  handleClickEmailRegistration,
  handleClickRemove,
  handleClickSubscription,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openWOMenu = Boolean(anchorEl);

  const handleClickActionsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Tooltip title='Actions'>
        <IconButton onClick={handleClickActionsMenu}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id={`actions-menu`}
        anchorEl={anchorEl}
        keepMounted
        open={openWOMenu}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            handleClickEdit(data);
            handleCloseMenu();
          }}
        >
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClickToogleStatus(data);
            handleCloseMenu();
          }}
        >
          <ListItemText>{data.active ? 'Deactivate' : 'Activate'}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClickApproveRegistration(data);
            handleCloseMenu();
          }}
        >
          <ListItemText>Approve Registration</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClickSubscription(data);
            handleCloseMenu();
          }}
        >
          <ListItemText>Subscription</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClickEmailRegistration(data);
            handleCloseMenu();
          }}
        >
          <ListItemText>Re-send Approval Email</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClickRemove(data);
            handleCloseMenu();
          }}
        >
          <ListItemText>Remove</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default CompaniesTable;
