import {
  SET_MACHINE_TYPE,
  MACHINE_TYPE_LOADING,
  MACHINE_TYPE_RESET,
  SET_MACHINE_TYPES,
  MACHINE_TYPE_LIST_LOADING,
  MACHINE_TYPE_LIST_RESET,
  ADD_MACHINE_TYPE_TO_LIST,
  UPDATE_MACHINE_TYPE_IN_LIST,
  DELETE_MACHINE_TYPE_FROM_LIST,
  SHOW_ADD_EDIT_MACHINE_TYPE_FORM,
  SAVING_MACHINE_TYPE_DATA,
} from './types';
const initialState = {
  item: {},
  list: {},
  loading: false,
  showAddEditForm: false,
  savingData: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MACHINE_TYPE:
      return {
        ...state,
        item: { ...action.payload },
      };
    case MACHINE_TYPE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case MACHINE_TYPE_RESET:
      return {
        ...state,
        item: {},
      };
    case SET_MACHINE_TYPES:
      return {
        ...state,
        list: { ...action.payload },
      };
    case MACHINE_TYPE_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case MACHINE_TYPE_LIST_RESET:
      return {
        ...state,
        list: {},
      };
    case ADD_MACHINE_TYPE_TO_LIST:
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          docs: [action.payload, ...state.list.docs],
          totalDocs: state.list.totalDocs + 1,
        },
      };
    case DELETE_MACHINE_TYPE_FROM_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          docs: state.list.docs.filter((item) => item.id !== action.payload),
          totalDocs: state.list.totalDocs - 1,
        },
      };
    case SHOW_ADD_EDIT_MACHINE_TYPE_FORM:
      return {
        ...state,
        showAddEditForm: action.payload,
      };
    case SAVING_MACHINE_TYPE_DATA:
      return {
        ...state,
        savingData: action.payload,
      };
    case UPDATE_MACHINE_TYPE_IN_LIST:
      return {
        ...state,
        loading: false,
        list: {
          docs: state.list.docs.map((row) => {
            if (row.id === action.payload.id) {
              return action.payload;
            }
            return row;
          }),
        },
      };

    default:
      return state;
  }
}
