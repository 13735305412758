import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setLaborDashboardData,
  setLaborDashboardDataLoading,
  resetLaborDashboardData,
} from './actions';

export const loadLaborDashboardData = () => (dispatch, getState, api) => {
  dispatch(setLaborDashboardDataLoading(true));
  axios
    .get(`${api}/dashboard/labor`)
    .then((res) => {
      dispatch(resetLaborDashboardData());
      dispatch(setLaborDashboardData(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setLaborDashboardDataLoading(false));
    });
};

export const loadLaborDashboardPrivateData =
  (token) => (dispatch, getState, api) => {
    dispatch(setLaborDashboardDataLoading(true));
    axios
      .get(`${api}/private-company/dashboard/labor/${token}`)
      .then((res) => {
        dispatch(resetLaborDashboardData());
        dispatch(setLaborDashboardData(res.data));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setLaborDashboardDataLoading(false));
      });
  };
