import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Paper,
  Typography,
} from '@mui/material';
import DifferenceIcon from '@mui/icons-material/Difference';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  machineId: Yup.string().required('Required'),
  commodityId: Yup.string().required('Required'),
  itemDescription: Yup.string().required('Required'),
});

const MachineProfileDuplicateForm = ({
  machines,
  commodities,
  handleOnDuplicate,
  handleOnCancel,
  savingData,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          handleOnDuplicate(data);
        })}
      >
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box display='flex' my={1.5} flexGrow={1} alignItems='center'>
              <Typography
                color='primary'
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'ePlan - Duplicate Machine Profile'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Typography variant='body2'>
                  New machine profile will have machine, commodity and item
                  description you select below and values from the one you are
                  duplicating from.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ minWidth: 250 }}>
                  <InputLabel id='machine-select-label'>Machine</InputLabel>
                  <Controller
                    name='machineId'
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId='machine-select-label'
                        label={'Machine'}
                        fullWidth
                        error={!!errors?.machineId}
                      >
                        {machines.map((machine) => (
                          <MenuItem key={machine.id} value={machine.id}>
                            {machine.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <FormHelperText>{errors?.machineId?.message}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ minWidth: 250 }}>
                  <InputLabel id='commodity-select-label'>Commodity</InputLabel>
                  <Controller
                    name='commodityId'
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId='commodity-select-label'
                        label={'Commodity'}
                        fullWidth
                        error={!!errors?.commodityId}
                      >
                        {commodities.map((commodity) => (
                          <MenuItem key={commodity.id} value={commodity.id}>
                            {commodity.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <FormHelperText>{errors?.commodityId?.message}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name='itemDescription'
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Item Description'
                      fullWidth
                      error={!!errors?.itemDescription}
                      helperText={errors?.itemDescription?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Box component='div' mx={1} my={0.5} display='flex'>
          <Box my={1.5} flexGrow={1}>
            <Button
              variant='contained'
              color='secondary'
              size='large'
              type='submit'
              startIcon={<DifferenceIcon />}
            >
              {savingData ? 'Duplicating...' : 'Duplicate'}
            </Button>
          </Box>
          <Box my={1.5}>
            <Button
              variant='outlined'
              size='large'
              startIcon={<UndoIcon />}
              onClick={handleOnCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default MachineProfileDuplicateForm;
