import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSetupChecklistItem,
  resetSetupChecklistItem,
  resetSetupChecklistItemList,
  showAddEditSetupChecklistItemForm,
} from '../../redux/company/setup-checklist-items/actions';
import {
  loadSetupChecklistItems,
  createSetupChecklistItem,
  updateSetupChecklistItem,
  deleteSetupChecklistItem,
  toggleSetupChecklistItemStatus,
} from '../../redux/company/setup-checklist-items/services';
import { getSetupChecklistItemList } from '../../redux/company/setup-checklist-items/selectors';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Paper,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import Loading from '../../components/UI/Loading';
import SetupChecklistItemForm from '../../components/company/SetupChecklistItemForm';
import Empty from '../../components/UI/Empty';
import SetupChecklistItemTable from '../../components/company/SetupChecklistItemTable';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
const isEmpty = require('is-empty');

const SetupChecklistItem = () => {
  const dispatch = useDispatch();
  const setupChecklistItemsState = useSelector(
    (state) => state.setup_checklist_items
  );
  const setupChecklistItemState = useSelector(
    (state) => state.setup_checklist_items.item
  );
  const SetupChecklistItemList = useSelector(getSetupChecklistItemList);
  const [editMode, setEditMode] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [
    openRemoveSetupChecklistItemDialog,
    setOpenRemoveSetupChecklistItemDialog,
  ] = useState(false);

  useEffect(() => {
    dispatch(loadSetupChecklistItems());
    return () => {
      dispatch(resetSetupChecklistItemList());
    };
  }, [dispatch]);

  const handleClickAdd = () => {
    setEditMode(false);
    dispatch(showAddEditSetupChecklistItemForm(true));
  };

  const handleClickEdit = (setupChecklistItem) => {
    setEditMode(true);
    dispatch(setSetupChecklistItem(setupChecklistItem));
    dispatch(showAddEditSetupChecklistItemForm(true));
  };

  const onSubmitAddEdit = (data) => {
    if (editMode) {
      dispatch(updateSetupChecklistItem(setupChecklistItemState.id, data));
    } else {
      dispatch(createSetupChecklistItem(data));
    }
  };

  const handleRefresh = () => {
    dispatch(loadSetupChecklistItems());
  };

  const handleClickRemove = (setupChecklistItem) => {
    dispatch(setSetupChecklistItem(setupChecklistItem));
    setOpenRemoveSetupChecklistItemDialog(true);
  };

  const onRemoveSetupChecklistItem = () => {
    dispatch(deleteSetupChecklistItem(setupChecklistItemState.id));
    dispatch(resetSetupChecklistItem());
    setOpenRemoveSetupChecklistItemDialog(false);
  };

  const handleClickToogleStatus = (setupChecklistItem) => {
    dispatch(setSetupChecklistItem(setupChecklistItem));
    setOpenStatusDialog(true);
  };

  const onToggleStatus = () => {
    dispatch(
      toggleSetupChecklistItemStatus(
        setupChecklistItemState.id,
        setupChecklistItemState.active
      )
    );
    dispatch(resetSetupChecklistItem());
    setOpenStatusDialog(false);
  };

  return (
    <>
      <Loading loading={setupChecklistItemsState.loading} />
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box display='flex' alignContent='center'>
            <IconButton aria-label='reload' onClick={handleRefresh}>
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              Setup Checklist Items
            </Typography>
          </Box>
          <Tooltip title='Add Setup Checklist Item'>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<AddIcon />}
              sx={{ marginRight: '0.5rem' }}
              onClick={handleClickAdd}
            >
              Add
            </Button>
          </Tooltip>
        </Box>
        <Box sx={{ width: '100%' }}>
          {!isEmpty(setupChecklistItemsState.list) && (
            <SetupChecklistItemTable
              rows={SetupChecklistItemList}
              handleClickEdit={handleClickEdit}
              handleClickRemove={handleClickRemove}
              handleClickToogleStatus={handleClickToogleStatus}
            />
          )}
          {isEmpty(setupChecklistItemsState.list) &&
            !setupChecklistItemsState.loading && (
              <Empty description='There are no setup checklist items to show' />
            )}
        </Box>
      </Paper>
      <ConfirmationDialog
        open={openRemoveSetupChecklistItemDialog}
        handleNo={() => {
          setOpenRemoveSetupChecklistItemDialog(false);
        }}
        handleYes={onRemoveSetupChecklistItem}
        title='ePack - Remove Setup Checklist Item'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to remove setup checklist item: ${setupChecklistItemState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openStatusDialog}
        handleNo={() => {
          setOpenStatusDialog(false);
        }}
        handleYes={onToggleStatus}
        title='ePack - Employee Position Status'
        YesText={'Yes, Change'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to ${
            setupChecklistItemState?.active ? 'deactivate' : 'activate'
          } setup checklist item: ${setupChecklistItemState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <Dialog
        open={setupChecklistItemsState.showAddEditForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setEditMode(false);
            dispatch(resetSetupChecklistItem());
            dispatch(showAddEditSetupChecklistItemForm(false));
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <SetupChecklistItemForm
            editMode={editMode}
            setupChecklistItem={setupChecklistItemState}
            handleOnCancel={() => {
              setEditMode(false);
              dispatch(resetSetupChecklistItem());
              dispatch(showAddEditSetupChecklistItemForm(false));
            }}
            handleOnSubmit={onSubmitAddEdit}
            savingData={setupChecklistItemState.savingData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SetupChecklistItem;
