import {
  SET_GATE_OUT_LIST,
  GATE_OUT_LIST_LOADING,
  GATE_OUT_LIST_RESET,
  SET_GATE_OUT_NAME_FILTER,
  SAVING_GATE_OUT_DATA,
  SHOW_GATE_OUT_FORM,
  TOGGLE_SELECT_GATE_OUT_IN_LIST,
  SET_SELECT_ALL_GATE_OUT_IN_LIST,
  DELETE_GATE_OUT_FROM_LIST,
} from './types';

export const setGateOutList = (list) => {
  return {
    type: SET_GATE_OUT_LIST,
    payload: list,
  };
};

export const resetGateOutList = () => {
  return {
    type: GATE_OUT_LIST_RESET,
  };
};

export const setGateOutListLoading = (payload) => {
  return {
    type: GATE_OUT_LIST_LOADING,
    payload,
  };
};

export const setGateOutNameFilter = (payload) => {
  return {
    type: SET_GATE_OUT_NAME_FILTER,
    payload,
  };
};

export const showGateOutForm = (payload) => {
  return {
    type: SHOW_GATE_OUT_FORM,
    payload,
  };
};

export const savingGateOutData = (payload) => {
  return {
    type: SAVING_GATE_OUT_DATA,
    payload,
  };
};

export const toggleSelectGateOutInList = (payload) => {
  return {
    type: TOGGLE_SELECT_GATE_OUT_IN_LIST,
    payload,
  };
};

export const setSelectAllGateOutInList = (payload) => {
  return {
    type: SET_SELECT_ALL_GATE_OUT_IN_LIST,
    payload,
  };
};

export const deleteGateOutFromList = (payload) => {
  return {
    type: DELETE_GATE_OUT_FROM_LIST,
    payload,
  };
};
