import {
  SET_PRODUCTION_DASHBOARD_DATA,
  PRODUCTION_DASHBOARD_DATA_LOADING,
  PRODUCTION_DASHBOARD_DATA_RESET,
} from './types';

export const setProductionDashboardData = (data) => {
  return {
    type: SET_PRODUCTION_DASHBOARD_DATA,
    payload: data,
  };
};

export const setProductionDashboardDataLoading = (payload) => {
  return {
    type: PRODUCTION_DASHBOARD_DATA_LOADING,
    payload,
  };
};

export const resetProductionDashboardData = () => {
  return {
    type: PRODUCTION_DASHBOARD_DATA_RESET,
  };
};
