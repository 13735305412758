import {
  SET_LABOR_DASHBOARD_DATA,
  LABOR_DASHBOARD_DATA_LOADING,
  LABOR_DASHBOARD_DATA_RESET,
} from './types';

export const setLaborDashboardData = (data) => {
  return {
    type: SET_LABOR_DASHBOARD_DATA,
    payload: data,
  };
};

export const setLaborDashboardDataLoading = (payload) => {
  return {
    type: LABOR_DASHBOARD_DATA_LOADING,
    payload,
  };
};

export const resetLaborDashboardData = () => {
  return {
    type: LABOR_DASHBOARD_DATA_RESET,
  };
};
