import axios from 'axios';
import { addNotification } from '../../../system/notifications/actions';
import {
  setUnallocatedList,
  resetUnallocatedList,
  setUnallocatedListLoading,
  showAssignJobForm,
  savingUnallocatedData,
} from './actions';

export const loadUnallocatedList = () => (dispatch, getState, api) => {
  dispatch(setUnallocatedListLoading(true));
  axios
    .get(`${api}/labor/unallocated?limit=1000000`)
    .then((res) => {
      dispatch(resetUnallocatedList());
      dispatch(setUnallocatedList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setUnallocatedListLoading(false));
    });
};

export const assignEmployeeToJob = (data) => (dispatch, getState, api) => {
  dispatch(setUnallocatedListLoading(true));
  dispatch(savingUnallocatedData(true));
  axios
    .post(`${api}/labor/assign`, data)
    .then((res) => {
      dispatch(loadUnallocatedList());
      dispatch(showAssignJobForm(false));
      const notification = {
        message: '👍🏻 Employee(s) assigned successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setUnallocatedListLoading(false));
      dispatch(savingUnallocatedData(false));
    });
};
