import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import InfoIcon from '@mui/icons-material/Info';
import * as Yup from 'yup';
import isEmpty from 'is-empty';

const ValidationSchema = Yup.object().shape({
  operationDaysPerWeek: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  operationHoursPerShift: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  operationShiftsPerDay: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  setupDurationInMinutes: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  setupLotsPerShift: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  breakNumberPerShift: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  breakMealDurationInMinutes: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  changeOverSanitationDurationInMinutes: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  changeOverPerShift: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  sanitationHoursPerDay: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
});

const MachineProfileForm = ({
  machineId,
  commodityId,
  itemDescription,
  onSubmit,
  savingData,
  editMode,
  machineProfile,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
    defaultValues: {
      operationDaysPerWeek: editMode ? machineProfile.operationDaysPerWeek : '',
      operationHoursPerShift: editMode
        ? machineProfile.operationHoursPerShift
        : '',
      operationShiftsPerDay: editMode
        ? machineProfile.operationShiftsPerDay
        : '',
      setupDurationInMinutes: editMode
        ? machineProfile.setupDurationInMinutes
        : '',
      setupLotsPerShift: editMode ? machineProfile.setupLotsPerShift : '',
      breakNumberPerShift: editMode ? machineProfile.breakNumberPerShift : '',
      breakMealDurationInMinutes: editMode
        ? machineProfile.breakMealDurationInMinutes
        : '',
      changeOverSanitationDurationInMinutes: editMode
        ? machineProfile.changeOverSanitationDurationInMinutes
        : '',
      changeOverPerShift: editMode ? machineProfile.changeOverPerShift : '',
      sanitationHoursPerDay: editMode
        ? machineProfile.sanitationHoursPerDay
        : '',
    },
  });
  let navigate = useNavigate();

  useEffect(() => {
    if (editMode === false) {
      setValue('machineId', machineId);
      setValue('commodityId', commodityId);
      isEmpty(itemDescription)
        ? setValue('itemDescription', 'NONE')
        : setValue('itemDescription', itemDescription);
    }
  }, [setValue, machineId, commodityId, itemDescription, editMode]);

  const watchOperationDaysPerWeek = watch('operationDaysPerWeek');
  const watchOperationHoursPerShift = watch('operationHoursPerShift');
  const watchOperationShiftsPerDay = watch('operationShiftsPerDay');
  const watchSetupDurationInMinutes = watch('setupDurationInMinutes');
  const watchSetupLotsPerShift = watch('setupLotsPerShift');
  const watchBreakNumberPerShift = watch('breakNumberPerShift');
  const watchBreakMealDurationInMinutes = watch('breakMealDurationInMinutes');
  const watchChangeOverSanitationDurationInMinutes = watch(
    'changeOverSanitationDurationInMinutes'
  );
  const watchChangeOverPerShift = watch('changeOverPerShift');
  const watchSanitationHoursPerDay = watch('sanitationHoursPerDay');

  const operationManufacturingHoursPerWeek =
    watchOperationDaysPerWeek *
    watchOperationHoursPerShift *
    watchOperationShiftsPerDay;

  const operationCapacityHoursPerWeek = 7 * 24;

  const setupManufacturingHoursPerWeek =
    ((watchSetupDurationInMinutes * watchSetupLotsPerShift) / 60) *
    watchOperationDaysPerWeek *
    watchOperationShiftsPerDay;

  const setupCapacityHoursPerWeek =
    ((watchSetupDurationInMinutes * watchSetupLotsPerShift) / 60) *
    watchOperationShiftsPerDay *
    7;
  const breakManufacturingHoursPerWeek =
    ((watchBreakNumberPerShift * 15 * watchOperationShiftsPerDay) / 60 +
      (watchBreakMealDurationInMinutes * watchOperationShiftsPerDay) / 60) *
    watchOperationDaysPerWeek;

  const breakCapacityHoursPerWeek =
    ((watchBreakNumberPerShift * 15 * watchOperationShiftsPerDay) / 60 +
      (watchBreakMealDurationInMinutes * watchOperationShiftsPerDay) / 60) *
    7;
  const changeOverSanitationManufacturingHoursPerWeek =
    (watchChangeOverSanitationDurationInMinutes / 60) *
    watchChangeOverPerShift *
    watchOperationShiftsPerDay *
    watchOperationDaysPerWeek;

  const changeOverSanitationCapacityHoursPerWeek =
    (watchChangeOverSanitationDurationInMinutes / 60) *
    watchChangeOverPerShift *
    watchOperationShiftsPerDay *
    7;

  const sanitationManufacturingHoursPerWeek =
    watchSanitationHoursPerDay * watchOperationDaysPerWeek;

  const sanitationCapacityHoursPerWeek = watchSanitationHoursPerDay * 7;

  const idleManufacturingHoursPerDay =
    24 -
    watchOperationHoursPerShift * watchOperationShiftsPerDay -
    watchSanitationHoursPerDay;

  const idleManufacturingHoursPerWeek =
    idleManufacturingHoursPerDay * watchOperationDaysPerWeek;

  const productiveManufacturingHoursPerWeek =
    operationManufacturingHoursPerWeek -
    setupManufacturingHoursPerWeek -
    breakManufacturingHoursPerWeek -
    changeOverSanitationManufacturingHoursPerWeek -
    sanitationManufacturingHoursPerWeek;

  const productiveCapacityHoursPerWeek =
    operationCapacityHoursPerWeek -
    setupCapacityHoursPerWeek -
    breakCapacityHoursPerWeek -
    changeOverSanitationCapacityHoursPerWeek -
    sanitationCapacityHoursPerWeek;

  const availabilityManufacturing =
    productiveManufacturingHoursPerWeek / operationManufacturingHoursPerWeek;

  const availabilityCapacity =
    productiveCapacityHoursPerWeek / operationCapacityHoursPerWeek;

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
      >
        <Paper elevation={0}>
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={8}>
                <Typography variant='h6'>HOURS OF OPERATION</Typography>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Typography variant='h6'>Units</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  How many Days Per Week of Production does the Line run?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name='operationDaysPerWeek'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size='small'
                      type='number'
                      error={!!errors?.operationDaysPerWeek}
                      helperText={errors?.operationDaysPerWeek?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Days</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  How many Production Hours per Shift do you run (including
                  breaks)?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name='operationHoursPerShift'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size='small'
                      type='number'
                      error={!!errors?.operationHoursPerShift}
                      helperText={errors?.operationHoursPerShift?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  How many Shifts do you run per day?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name='operationShiftsPerDay'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size='small'
                      type='number'
                      error={!!errors?.operationShiftsPerDay}
                      helperText={errors?.operationShiftsPerDay?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Shifts</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  TOTAL (I) - Manufacturing Hours Per Week
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={
                    isNaN(operationManufacturingHoursPerWeek)
                      ? 0
                      : operationManufacturingHoursPerWeek
                  }
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours/Wk</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  TOTAL (II) - Capacity Hours Per Week
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={operationCapacityHoursPerWeek}
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours/Wk</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider light />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>SETUP</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  How long does it take to Setup the Production Line in Minutes?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name='setupDurationInMinutes'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size='small'
                      type='number'
                      error={!!errors?.setupDurationInMinutes}
                      helperText={errors?.setupDurationInMinutes?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Minutes</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  How many Work Orders/Lots do you typically run per Shift?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name='setupLotsPerShift'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size='small'
                      type='number'
                      error={!!errors?.setupLotsPerShift}
                      helperText={errors?.setupLotsPerShift?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Number</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  TOTAL (I) - Mfg Setup Time in Hrs Per Week
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={
                    isNaN(setupManufacturingHoursPerWeek)
                      ? 0
                      : parseFloat(setupManufacturingHoursPerWeek).toFixed(2)
                  }
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours/Wk</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  TOTAL (II) - Capacity Setup Time in Hrs Per Week
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={
                    isNaN(setupCapacityHoursPerWeek)
                      ? 0
                      : parseFloat(setupCapacityHoursPerWeek).toFixed(2)
                  }
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours/Wk</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider light />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>BREAKS</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  How many 15-Minute Breaks are planned Per Shift?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name='breakNumberPerShift'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size='small'
                      type='number'
                      error={!!errors?.breakNumberPerShift}
                      helperText={errors?.breakNumberPerShift?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Number</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  How long is Lunch/Dinner Break Per Shift (in minutes)?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name='breakMealDurationInMinutes'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size='small'
                      type='number'
                      error={!!errors?.breakMealDurationInMinutes}
                      helperText={errors?.breakMealDurationInMinutes?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Minutes</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  TOTAL (I) - Mfg Break Time in Hrs Per Week
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={
                    isNaN(breakManufacturingHoursPerWeek)
                      ? 0
                      : parseFloat(breakManufacturingHoursPerWeek).toFixed(2)
                  }
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours/Wk</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  TOTAL (II) - Capacity Break Time in Hrs Per Week
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={
                    isNaN(breakCapacityHoursPerWeek)
                      ? 0
                      : parseFloat(breakCapacityHoursPerWeek).toFixed(2)
                  }
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours/Wk</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider light />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>
                  CHANGEOVERS & DAILY SANITATION
                </Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  How long is a Changeover Sanitation in Minutes?
                </Typography>
                <Tooltip
                  disableFocusListener
                  title='Only include time that it takes to clean the Production Line after completing a WO to get it ready for a new Lot or WO.  Do not include Daily Sanitaiton Hours. If Daily Sanitation takes care of the last WO of the day clean-up, then allocate that time to Daily Sanitation")'
                >
                  <InfoIcon color='primary' sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name='changeOverSanitationDurationInMinutes'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size='small'
                      type='number'
                      error={!!errors?.changeOverSanitationDurationInMinutes}
                      helperText={
                        errors?.changeOverSanitationDurationInMinutes?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Minutes</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  How many Changeovers Per Shift do you expect typically?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name='changeOverPerShift'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size='small'
                      type='number'
                      error={!!errors?.changeOverPerShift}
                      helperText={errors?.changeOverPerShift?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Number</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  How many Sanitation Hours Per Day is allocated?
                </Typography>
                <Tooltip
                  disableFocusListener
                  title='Do not include Changeover Sanitation time that happens during Changeover in between Lots/Work Orders.  Only include end of day Daily Sanitation Time.'
                >
                  <InfoIcon color='primary' sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name='sanitationHoursPerDay'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size='small'
                      type='number'
                      error={!!errors?.sanitationHoursPerDay}
                      helperText={errors?.sanitationHoursPerDay?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  TOTAL (I)- Mfg Changeover Sanitation Hours Per Week
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={
                    isNaN(changeOverSanitationManufacturingHoursPerWeek)
                      ? 0
                      : parseFloat(
                          changeOverSanitationManufacturingHoursPerWeek
                        ).toFixed(2)
                  }
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours/Wk</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  TOTAL (I) - Mfg Sanitation Hours Per Week
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={
                    isNaN(changeOverSanitationCapacityHoursPerWeek)
                      ? 0
                      : parseFloat(
                          changeOverSanitationCapacityHoursPerWeek
                        ).toFixed(2)
                  }
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours/Wk</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  TOTAL (II)- Capacity Changeover Sanitation Hours Per Week
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={
                    isNaN(sanitationManufacturingHoursPerWeek)
                      ? 0
                      : parseFloat(sanitationManufacturingHoursPerWeek).toFixed(
                          2
                        )
                  }
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours/Wk</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  TOTAL (II) - Capacity Sanitation Hours Per Week
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={
                    isNaN(sanitationCapacityHoursPerWeek)
                      ? 0
                      : parseFloat(sanitationCapacityHoursPerWeek).toFixed(2)
                  }
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours/Wk</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider light />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>MANUFACURING IDLE TIME</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  Mfg Machine Idle Time Per Day on Production Days
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={
                    isNaN(idleManufacturingHoursPerDay)
                      ? 0
                      : parseFloat(idleManufacturingHoursPerDay).toFixed(2)
                  }
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours/Day</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  TOTAL (I) - Mfg Idle Time Per Week in Hours
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={
                    isNaN(idleManufacturingHoursPerWeek)
                      ? 0
                      : parseFloat(idleManufacturingHoursPerWeek).toFixed(2)
                  }
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours/Wk</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider light />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>
                  PRODUCTIVE HOURS - BREAKS - CHANGEOVER TIME - SANITATION
                </Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  TOTAL (I) - Productive Mfg Hours Per Week:
                </Typography>
                <Tooltip
                  disableFocusListener
                  title='This is the actual number of hours that the facility is producing product under current conditions'
                >
                  <InfoIcon color='primary' sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={
                    isNaN(productiveManufacturingHoursPerWeek)
                      ? 0
                      : parseFloat(productiveManufacturingHoursPerWeek).toFixed(
                          2
                        )
                  }
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours/Wk</Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  TOTAL (II) -Productive Capacity Hours Per Week:
                </Typography>
                <Tooltip
                  disableFocusListener
                  title='This is the number of hours that the facility is capable of producing at full capacity'
                >
                  <InfoIcon color='primary' sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={
                    isNaN(productiveCapacityHoursPerWeek)
                      ? 0
                      : parseFloat(productiveCapacityHoursPerWeek).toFixed(2)
                  }
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                <Typography variant='body1'>Hours/Wk</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider light />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>
                  Availability (excluding machine scheduled maintenance and
                  breakdowns):
                  <Tooltip
                    disableFocusListener
                    title='Mfg Availability excludes hours of the days that you do not produce and includes any expected idle time at the end of the day. Capacity Availability gives you the total possible production percentage that your facility is capable of in the 168 Hrs of the Week'
                  >
                    <InfoIcon color='primary' sx={{ marginLeft: 1 }} />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>
                  Manufacturing Availability
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={
                    isNaN(availabilityManufacturing)
                      ? 0
                      : parseFloat(availabilityManufacturing).toFixed(2)
                  }
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                %
              </Grid>
              <Grid item xs={8} display='flex' alignItems='center'>
                <Typography variant='body1'>Capacity Availability</Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={
                    isNaN(availabilityCapacity)
                      ? 0
                      : parseFloat(availabilityCapacity).toFixed(2)
                  }
                />
              </Grid>
              <Grid item xs={2} display='flex' alignItems='center'>
                %
              </Grid>
              <Grid item xs={12}>
                <Divider light />
              </Grid>
            </Grid>
          </Box>
          <Box mx={1} my={2.5} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
                startIcon={<SaveIcon />}
              >
                {savingData ? 'Saving...' : 'Save'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UndoIcon />}
                onClick={() => {
                  reset();
                  navigate('/eplan/machine_profiles');
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default MachineProfileForm;
