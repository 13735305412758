export const SET_GATE_OUT_LIST = 'SET_GATE_OUT_LIST';
export const GATE_OUT_LIST_LOADING = 'GATE_OUT_LIST_LOADING';
export const GATE_OUT_LIST_RESET = 'GATE_OUT_LIST_RESET';
export const SET_GATE_OUT_NAME_FILTER = 'SET_GATE_OUT_NAME_FILTER';
export const UPDATE_GATE_OUT_IN_LIST = 'UPDATE_GATE_OUT_IN_LIST';
export const SAVING_GATE_OUT_DATA = 'SAVING_GATE_OUT_DATA';
export const SHOW_GATE_OUT_FORM = 'SHOW_GATE_OUT_FORM';
export const TOGGLE_SELECT_GATE_OUT_IN_LIST = 'TOGGLE_SELECT_GATE_OUT_IN_LIST';
export const SET_SELECT_ALL_GATE_OUT_IN_LIST =
  'SET_SELECT_ALL_GATE_OUT_IN_LIST';
export const DELETE_GATE_OUT_FROM_LIST = 'DELETE_GATE_OUT_FROM_LIST';
