import React from 'react';
import { Box, FormControlLabel, Switch, TextField } from '@mui/material';

const CompaniesFilterForm = ({
  filters,
  handleOnChangeNameFilter,
  handleOnChangeIsActiveFilter,
  handleOnChangeNeedsApprovalFilter,
}) => {
  return (
    <>
      <Box display='flex' mx={1} justifyContent='space-evenly'>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FormControlLabel
            control={
              <Switch
                onChange={(e) =>
                  handleOnChangeNeedsApprovalFilter(e.target.checked)
                }
                checked={filters.needsApproval}
              />
            }
            label='Needs Approval?'
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 2,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => handleOnChangeIsActiveFilter(e.target.checked)}
                checked={filters.isActive}
              />
            }
            label='Active?'
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 2,
          }}
        >
          <TextField
            size='small'
            label='Name'
            fullWidth
            value={filters.name}
            onChange={(e) => handleOnChangeNameFilter(e.target.value)}
          />
        </Box>
      </Box>
    </>
  );
};

export default CompaniesFilterForm;
