import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > svg': {
      marginTop: theme.spacing(),
      marginRight: theme.spacing(0.5),
    },
  },
}));

const CleaningIcon = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SvgIcon
        width="24"
        height="24"
        viewBox="0 0 298.359 298.359"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle cx="108.273" cy="36.082" r="24.147" />
        <path
          d="M290.832,198.085c-2.787-9.346-12.353-25.315-17.902-30.935c-14.597-15.633-32.954-25.73-57.369-28.679l-8.884-33.905
				c-1.042-3.977-5.11-6.359-9.09-5.315c-3.978,1.043-6.358,5.112-5.315,9.09l0.205,0.783l-1.548,0.591l-14.381-13.616
				c1.819-2.72,2.411-6.218,1.29-9.56c-1.921-5.728-8.121-8.813-13.852-6.893l-41.415,13.892l0.815-3.588
				c1.996-8.785-3.508-17.525-12.293-19.521l-26.336-5.983c-9.175-2.005-19.072,0.914-21.553,11.831
				c-18.561,81.689,21.381-94.171-18.544,81.615l0.038,0.008l-6.343,46.305L1.726,267.277c-3.535,6.086-1.466,13.886,4.62,17.421
				c6.085,3.535,13.885,1.467,17.42-4.62l37.896-65.249c0.835-1.437,1.38-3.024,1.606-4.671l6.382-46.591l8.988,2.042l20.099,45.701
				l2.112,62.795c0.232,6.889,5.888,12.316,12.729,12.316c0.144,0,0.291-0.003,0.435-0.007c7.034-0.237,12.545-6.131,12.308-13.165
				l-2.195-65.249c-0.055-1.621-0.418-3.217-1.072-4.701l-16.762-38.113l2.982-13.125l-4.443-0.492
				c-9.666-1.07-16.236-8.497-17.551-13.744L76.402,94.437l21.066,40.834c1.68,3.256,4.878,5.455,8.52,5.858l43.445,4.809
				c5.965,0.661,11.409-3.627,12.078-9.671c0.665-6.007-3.665-11.413-9.67-12.078l-37.615-4.164L95.148,83.047
				c1.375,1.531,23.103,25.741,24.216,26.981l17.179,1.902l25.891-8.685l13.055,12.361l-10.144,3.871
				c4.719,4.464,7.37,11.007,6.602,17.946c-1.3,11.743-11.887,20.26-23.672,18.954l-6.732-0.745l5.275,20.13
				c0.632,2.411,2.978,3.959,5.444,3.592l56.412-8.411l0.205,0.783c1.043,3.979,5.114,6.358,9.09,5.315
				c3.978-1.043,6.358-5.112,5.315-9.09l-2.199-8.392c14.847,1.279,27.82,5.772,41.748,17.545
				c7.301,5.767,16.326,20.079,18.383,24.986c6.392,13.955,8.319,21.746,11.285,37.472l0.029,0.157
				c0.272,1.452,1.598,2.498,3.106,2.386c1.619-0.12,2.834-1.53,2.714-3.149C297.101,222.109,295.973,213.764,290.832,198.085z"
        />
        <path
          d="M272.753,228.739c-6.115-8.343-14.571-12.888-24.717-13.886c-5.797-0.521-10.302,4.722-9.046,10.283
				c1.056,4.673,7.165,6.413,10.156,6.334c4.311-0.113,8.689-2.06,16.862,3.643c5.61,4.322,7.765,8.656,9.153,11.188l0.068,0.125
				c0.427,0.777,1.318,1.229,2.238,1.059c1.147-0.213,1.905-1.316,1.692-2.464C278.217,239.948,277.065,234.933,272.753,228.739z"
        />
        <path
          d="M286.83,150.042c0.721,0.515,1.722,0.539,2.478-0.012c0.942-0.689,1.147-2.011,0.458-2.953l-0.083-0.112
				c-3.106-4.248-5.026-7.513-6.252-13.034c-0.803-4.641-0.4-8.963,1.677-13.395c0.525-1.092,1.523-2.848,2.173-3.421
				c0.007,0.004-0.003,0.06-0.004,0.068c3.566-4.386,1.784-11.25-3.764-13.235c-3.249-1.162-9.284-0.925-11.671,7.555
				c-0.403,1.398-0.825,3.324-1.078,5.634c-0.729,7.039,0.508,13.751,4.034,20.199C278.234,143.268,281.838,146.467,286.83,150.042z
				"
        />
      </SvgIcon>
    </div>
  );
};

export default CleaningIcon;
