import React from 'react';
import { Grid } from '@mui/material';
import LaborSummaryTile from './LaborSummaryTile';

const LaborSummaryTileList = ({ list }) => {
  return (
    <>
      <Grid container spacing={1}>
        {list.map((summary) => {
          return (
            <Grid item xs={6} key={summary.id}>
              <LaborSummaryTile workOrder={summary} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default LaborSummaryTileList;
