import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import UndoIcon from '@mui/icons-material/Undo';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PanToolIcon from '@mui/icons-material/PanTool';
import QrCodeIcon from '@mui/icons-material/QrCode';
import EmployeePhoto from './EmployeePhoto';
import truncateString from '../../utils/truncateString';
import isEmpty from 'is-empty';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  barcode: Yup.string().max(50, 'Must be 50 characters or less'),
});

const GateInForm = ({
  employee,
  handleOnSubmit,
  handleOnCancel,
  savingData,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  useEffect(() => {
    setValue('id', employee.id);
  }, [employee, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper elevation={0}>
          <Box component="div" display="flex" mx={1} alignItems="center">
            <Box display="flex" my={1.5} flexGrow={1} alignItems="center">
              <Typography
                color="primary"
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'eTime - Gate In'}
              </Typography>
            </Box>
          </Box>
          <Divider light />
          <Box
            component="div"
            display="flex"
            mt={2}
            justifyContent="center"
            alignItems={'center'}
          >
            <Box sx={{ margin: 5 }}>
              <EmployeePhoto photo={employee.photo} size="large" />
            </Box>
            <Box flexGrow={1}>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={2}>
                  <BadgeIcon color="primary" />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body1">
                    {truncateString(
                      employee.lastName + ' ' + employee.firstName,
                      {
                        length: 25,
                        ending: '...',
                      }
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <AccountBalanceIcon color="primary" />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body1">
                    {truncateString(employee.employer.name)}
                  </Typography>
                </Grid>
                {!isEmpty(employee.latestPosition) && (
                  <>
                    <Grid item xs={2}>
                      <PanToolIcon color="primary" />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1">
                        {truncateString(employee.latestPosition)}
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={2} sx={{ marginTop: 1.5 }}>
                  <QrCodeIcon color="primary" />
                </Grid>
                <Grid item xs={10}>
                  <Controller
                    name="barcode"
                    control={control}
                    defaultValue={
                      isEmpty(employee.barcode) ? '' : employee.barcode
                    }
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Badge Number"
                        fullWidth
                        error={!!errors?.barcode}
                        helperText={errors?.barcode?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box component="div" mx={1} my={0.5} display="flex">
            <Box my={1.5} flexGrow={1}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                startIcon={<LoginIcon />}
                disabled={savingData}
              >
                {savingData ? 'Signing In...' : 'Sign In'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant="outlined"
                size="large"
                startIcon={<UndoIcon />}
                onClick={handleOnCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default GateInForm;
