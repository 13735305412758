import { combineReducers } from 'redux';
import authReducer from './system/auth/reducers';
import registrationReducer from './system/registration/reducers';
import subscriptionReducer from './system/subscription/reducers';
import notificationReducer from './system/notifications/reducers';
import employeeReducer from './etime/employees/reducers';
import gateInReducer from './etime/gate-in/reducers';
import gateOutReducer from './etime/gate-out/reducers';
import unallocatedReducer from './etime/labor/unallocated/reducers';
import laborWorkOrderViewReducer from './etime/labor/work-order-view/reducers';
import laborJobViewReducer from './etime/labor/job-view/reducers';
import laborMachineViewReducer from './etime/labor/machine-view/reducers';
import laborSummaryReducer from './etime/labor/summary/reducers';
import laborLogReducer from './etime/labor-log/reducers';
import listWorkOrderReducer from './list/work-orders/reducers';
import listMachineReducer from './list/machines/reducers';
import listEmployerReducer from './list/employers/reducers';
import listCommoditiesReducer from './list/commodities/reducers';
import listJobTypeReducer from './list/job-types/reducers';
import listMachineTypeReducer from './list/machine-types/reducers';
import listPalletTypeReducer from './list/pallet-types/reducers';
import listEmployeePositionReducer from './list/employee-positions/reducers';
import listSetupChecklistItems from './list/setup-checklist-items/reducers';
import listCompaniesReducer from './list/companies/reducers';
import productionReducer from './epack/production/reducers';
import linesReducer from './epack/lines/reducers';
import machinesReducer from './epack/machines/reducers';
import workOrdersReducer from './epack/work-orders/reducers';
import contactsReducer from './company/contacts/reducers';
import employersReducer from './company/employers/reducers';
import customersReducer from './company/customers/reducers';
import commoditiesReducer from './company/commodities/reducers';
import employeePositionsReducer from './company/employee-positions/reducers';
import jobTypesReducer from './company/job-types/reducers';
import machineTypesReducer from './company/machine-types/reducers';
import palletTypesReducer from './company/pallet-types/reducers';
import setupChecklistItemReducer from './company/setup-checklist-items/reducers';
import usersReducer from './company/users/reducers';
import productionDashboardReducer from './epack/dashboard/reducers';
import laborDashboardReducer from './etime/dashboard/reducers';
import mainDashboardReducer from './dashboard/reducers';
import companiesReducer from './general/companies/reducers';
import machineProfileReducer from './eplan/profile-machine/reducers';
import morProfileReducer from './eplan/profile-mor/reducers';
import manualProfileReducer from './eplan/profile-manual/reducers';
import profitProfileReducer from './eprofit/profile-profit/reducers';
import financialExpensesReducer from './eprofit/financial-expenses/reducers';

export default combineReducers({
  auth: authReducer,
  registration: registrationReducer,
  subscription: subscriptionReducer,
  notifications: notificationReducer,
  employees: employeeReducer,
  gatein: gateInReducer,
  gateout: gateOutReducer,
  labor_unallocated: unallocatedReducer,
  labor_work_order_view: laborWorkOrderViewReducer,
  labor_job_view: laborJobViewReducer,
  labor_log: laborLogReducer,
  labor_machine_view: laborMachineViewReducer,
  labor_summary: laborSummaryReducer,
  list_work_order: listWorkOrderReducer,
  list_machine: listMachineReducer,
  list_employer: listEmployerReducer,
  list_commodities: listCommoditiesReducer,
  list_job_type: listJobTypeReducer,
  list_machine_type: listMachineTypeReducer,
  list_pallet_type: listPalletTypeReducer,
  list_employee_position: listEmployeePositionReducer,
  list_setup_checklist_items: listSetupChecklistItems,
  list_company: listCompaniesReducer,
  production: productionReducer,
  lines: linesReducer,
  machines: machinesReducer,
  work_orders: workOrdersReducer,
  contacts: contactsReducer,
  employers: employersReducer,
  customers: customersReducer,
  commodities: commoditiesReducer,
  companies: companiesReducer,
  employee_positions: employeePositionsReducer,
  job_types: jobTypesReducer,
  machine_types: machineTypesReducer,
  pallet_types: palletTypesReducer,
  setup_checklist_items: setupChecklistItemReducer,
  users: usersReducer,
  production_dashboard: productionDashboardReducer,
  labor_dashboard: laborDashboardReducer,
  main_dashboard: mainDashboardReducer,
  machine_profile: machineProfileReducer,
  mor_profile: morProfileReducer,
  manual_profile: manualProfileReducer,
  profit_profile: profitProfileReducer,
  financial_expenses: financialExpensesReducer,
});
