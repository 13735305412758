import {
  SET_MAIN_DASHBOARD_DATA,
  MAIN_DASHBOARD_DATA_LOADING,
  MAIN_DASHBOARD_DATA_RESET,
} from './types';

export const setMainDashboardData = (data) => {
  return {
    type: SET_MAIN_DASHBOARD_DATA,
    payload: data,
  };
};

export const setMainDashboardDataLoading = (payload) => {
  return {
    type: MAIN_DASHBOARD_DATA_LOADING,
    payload,
  };
};

export const resetMainDashboardData = () => {
  return {
    type: MAIN_DASHBOARD_DATA_RESET,
  };
};
