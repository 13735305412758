export const EMPLOYEE_LOADING = 'EMPLOYEE_LOADING';
export const SET_EMPLOYEE = 'SET_EMPLOYEE';
export const EMPLOYEE_RESET = 'EMPLOYEE_RESET';
export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const EMPLOYEES_LIST_LOADING = 'EMPLOYEES_LIST_LOADING';
export const EMPLOYEES_LIST_RESET = 'EMPLOYEES_LIST_RESET';
export const SET_EMPLOYEE_NAME_FILTER = 'SET_EMPLOYEE_NAME_FILTER';
export const ADD_EMPLOYEE_TO_LIST = 'ADD_EMPLOYEE_TO_LIST';
export const UPDATE_EMPLOYEE_IN_LIST = 'UPDATE_EMPLOYEE_IN_LIST';
export const DELETE_EMPLOYEE_FROM_LIST = 'DELETE_EMPLOYEE_FROM_LIST';
export const SHOW_ADD_EDIT_EMPLOYEE_FORM = 'SHOW_ADD_EDIT_EMPLOYEE_FORM';
export const SAVING_EMPLOYEE_DATA = 'SAVING_EMPLOYEE_DATA';
