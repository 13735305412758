import axios from 'axios';
import { addNotification } from '../notifications/actions';
import {
  setRegistrationLoading,
  setRegistrationComplete,
  setValidationRegistrationData,
  setCompanyNameTaken,
  setCompanyEmailTaken,
  setUserEmailTaken,
} from './actions';

export const registerCompany = (data) => (dispatch, getState, api) => {
  dispatch(setRegistrationLoading(true));
  axios
    .post(`${api}/registration`, data)
    .then((res) => {
      dispatch(setRegistrationComplete(true));
    })
    .catch((err) => {
      console.error(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setRegistrationLoading(false));
    });
};

export const validateCompanyName = (data) => (dispatch, getState, api) => {
  dispatch(setValidationRegistrationData(true));
  axios
    .post(`${api}/registration/company-name-taken`, data)
    .then((res) => {
      if (res.data === true) {
        dispatch(setCompanyNameTaken(true));
      } else {
        dispatch(setCompanyNameTaken(false));
      }
    })
    .catch((err) => {
      console.error(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setValidationRegistrationData(false));
    });
};

export const validateCompanyEmail = (data) => (dispatch, getState, api) => {
  dispatch(setValidationRegistrationData(true));
  axios
    .post(`${api}/registration/company-email-taken`, data)
    .then((res) => {
      if (res.data === true) {
        dispatch(setCompanyEmailTaken(true));
      } else {
        dispatch(setCompanyEmailTaken(false));
      }
    })
    .catch((err) => {
      console.error(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setValidationRegistrationData(false));
    });
};

export const validateUserEmail = (data) => (dispatch, getState, api) => {
  dispatch(setValidationRegistrationData(true));
  axios
    .post(`${api}/registration/user-email-taken`, data)
    .then((res) => {
      if (res.data === true) {
        dispatch(setUserEmailTaken(true));
      } else {
        dispatch(setUserEmailTaken(false));
      }
    })
    .catch((err) => {
      console.error(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setValidationRegistrationData(false));
    });
};
