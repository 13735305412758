import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import Illustration from '../../components/UI/Illustration';
import LogoPNG from '../../assets/logo.png';

const AuthLayout = () => {
  let location = useLocation();
  return (
    <Grid container spacing={0}>
      <Grid container item xs={12} sm={6}>
        <Grid item xs={12}>
          <Box display='flex' mx={1.5} my={1}>
            <Box flexGrow={1}>
              <img src={LogoPNG} alt='Logo' height='40px' />
            </Box>
            <Box>
              {location.pathname.includes('/auth/login') ? (
                <Button
                  variant='outlined'
                  component={Link}
                  to={'/auth/register'}
                >
                  Register
                </Button>
              ) : (
                <Button variant='outlined' component={Link} to={'/auth/login'}>
                  Log In
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' alignItems='center' flexGrow='1'>
            <Box display='flex' flexGrow='1' justifyContent='center'>
              <Box sx={{ maxWidth: '90%' }}>
                <Outlet />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ backgroundColor: 'primary.main', display: 'flex' }}
      >
        <Box
          display='flex'
          flexDirection='column'
          flexGrow={1}
          alignItems='center'
          justifyContent='center'
        >
          <Box>
            <Typography
              variant='h4'
              sx={(theme) => ({
                color: 'white',
                textAlign: 'center',
                marginBottom: '1rem',
                fontWeight: 'bold',
                marginLeft: '20px',
                marginRight: '20px',
                [theme.breakpoints.up('sm')]: {
                  marginLeft: '40px',
                  marginRight: '40px',
                },
              })}
            >
              Management of your repacking facility at your fingertips
            </Typography>
            <Typography
              sx={{
                color: 'white',
                marginBottom: '2rem',
                textAlign: 'center',
              }}
              component='p'
            >
              <Typography sx={{ color: 'secondary.main' }} component='span'>
                e
              </Typography>
              Pack +{' '}
              <Typography sx={{ color: 'secondary.main' }} component='span'>
                e
              </Typography>
              Time +{' '}
              <Typography sx={{ color: 'secondary.main' }} component='span'>
                e
              </Typography>
              Profit +{' '}
              <Typography sx={{ color: 'secondary.main' }} component='span'>
                e
              </Typography>
              Plan
            </Typography>
          </Box>
          <Box>
            <Illustration
              sx={(theme) => ({
                width: '300px',
                height: '300px',
                [theme.breakpoints.up('sm')]: {
                  width: '450px',
                  height: '450px',
                },
              })}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
