import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import ProductionLineStatus from './ProductionLineStatus';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import CleaningIcon from '../UI/icons/CleaningIcon';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { disabledByStatus } from '../../utils/disabledByStatus';

const ProductionTableViewList = ({
  rows,
  handleClickSetupChecklist,
  onStartProductionLine,
  onStopProductionLine,
  handleClickClipboard,
  handleClickSanitation,
  handleClickCloseProductionLine,
  handleClickUnavailableProductionLine,
  handleClickWIPDashboard,
}) => {
  const columns = [
    {
      field: 'workOrder',
      headerName: 'Work Order',
      width: 150,
      hideable: false,
      editable: false,
      valueGetter: (params) => {
        return params.row.workOrder.code;
      },
    },
    {
      field: 'commodity',
      headerName: 'Commodity',
      width: 150,
      hideable: false,
      editable: false,
      valueGetter: (params) => {
        return params.row.commodity.name;
      },
    },
    {
      field: 'machine',
      headerName: 'Machine',
      width: 150,
      editable: false,
      valueGetter: (params) => {
        return params.row.machine.name;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      hideable: false,
      editable: false,
      renderCell: (cellValues) => {
        return <ProductionLineStatus status={cellValues.row.status} />;
      },
    },
    {
      field: 'totalPalletsIn',
      headerName: 'Pallets In',
      width: 100,
      type: 'number',
      hideable: false,
      editable: false,
    },
    {
      field: 'totalPalletsOut',
      headerName: 'Pallets Out',
      width: 100,
      type: 'number',
      hideable: false,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 220,
      type: 'actions',
      hideable: false,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <Box>
            <Tooltip title='Setup Check List'>
              <IconButton
                onClick={() => {
                  handleClickSetupChecklist(cellValues.row.id);
                }}
                disabled={disabledByStatus(cellValues.row.status, [
                  'AVAILABLE',
                  'IN_PROGRESS',
                  'NOT_AVAILABLE',
                  'SANITATION',
                ])}
                color='primary'
              >
                <PlaylistAddCheckIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Start Production Line'>
              <IconButton
                onClick={() => onStartProductionLine(cellValues.row.id)}
                disabled={disabledByStatus(cellValues.row.status, [
                  'IN_PROGRESS',
                  'SANITATION',
                ])}
                sx={{ color: 'success.main' }}
              >
                <PlayArrowIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Stop Production Line'>
              <IconButton
                onClick={() => onStopProductionLine(cellValues.row.id)}
                disabled={disabledByStatus(cellValues.row.status, [
                  'SETUP',
                  'AVAILABLE',
                  'SANITATION',
                ])}
                sx={{ color: 'error.main' }}
              >
                <StopIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Production Clipboard'>
              <IconButton
                onClick={() => handleClickClipboard(cellValues.row.id)}
                disabled={disabledByStatus(cellValues.row.status, [
                  'SETUP',
                  'NOT_AVAILABLE',
                  'SANITATION',
                ])}
                color='primary'
              >
                <AssignmentIcon />
              </IconButton>
            </Tooltip>
            <ThreeDotsMenu
              data={cellValues.row}
              handleClickSanitation={handleClickSanitation}
              handleClickCloseProductionLine={handleClickCloseProductionLine}
              handleClickUnavailableProductionLine={
                handleClickUnavailableProductionLine
              }
              handleClickWIPDashboard={handleClickWIPDashboard}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        getRowClassName={(params) => `ehawk-theme--${params.row.status}`}
      />
    </Box>
  );
};

const ThreeDotsMenu = ({
  data,
  handleClickSanitation,
  handleClickCloseProductionLine,
  handleClickUnavailableProductionLine,
  handleClickWIPDashboard,
}) => {
  const [anchorElPL, setAnchorElPL] = React.useState(null);
  const openPLMenu = Boolean(anchorElPL);

  const handleMorePLMenuClick = (event) => {
    setAnchorElPL(event.currentTarget);
  };

  const handleClosePLMenu = () => {
    setAnchorElPL(null);
  };

  return (
    <React.Fragment>
      <Tooltip title='More Options'>
        <IconButton onClick={handleMorePLMenuClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id='more-menu'
        anchorEl={anchorElPL}
        keepMounted
        open={openPLMenu}
        onClose={handleClosePLMenu}
      >
        <MenuItem
          onClick={() => {
            handleClickWIPDashboard(data.id);
            handleClosePLMenu();
          }}
          disabled={disabledByStatus(data.status, [
            'SETUP',
            'NOT_AVAILABLE',
            'SANITATION',
          ])}
        >
          <ListItemIcon>
            <DashboardIcon color='primary' />
          </ListItemIcon>
          <ListItemText>WIP Dashboard (Work In Progress)</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClickSanitation(data.id);
            handleClosePLMenu();
          }}
          disabled={disabledByStatus(data.status, ['SETUP', 'NOT_AVAILABLE'])}
        >
          <ListItemIcon>
            <CleaningIcon color='primary' />
          </ListItemIcon>
          <ListItemText>Sanitization Log</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClickCloseProductionLine(data);
            handleClosePLMenu();
          }}
        >
          <ListItemIcon>
            <HighlightOffIcon sx={{ color: 'error.main' }} />
          </ListItemIcon>
          <ListItemText>Close Production Line</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClickUnavailableProductionLine(data);
            handleClosePLMenu();
          }}
          disabled={disabledByStatus(data.status, [
            'SANITATION',
            'NOT_AVAILABLE',
          ])}
        >
          <ListItemIcon>
            <ReportProblemIcon color='error' />
          </ListItemIcon>
          <ListItemText>Report Unavailable</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ProductionTableViewList;
