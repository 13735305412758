import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const MachineIcon = (props) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 211.014 211.015"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M90.396,139.128l20.572-12.75l-7.56-12.193c3.875-3.907,7.146-8.45,9.652-13.492l13.997,3.275l5.503-23.581l-13.991-3.275
		c-0.012-5.568-0.91-11.065-2.684-16.343l12.229-7.583l-12.761-20.561l-12.224,7.578c-3.939-3.916-8.467-7.179-13.456-9.646l3.28-14
		L69.38,11.035l-3.266,13.958c-5.704,0.012-11.216,0.955-16.379,2.668l-7.56-12.179L21.61,28.242l7.554,12.173
		c-3.875,3.91-7.149,8.449-9.655,13.491l-13.981-3.28L0,74.207l14,3.286c0.006,5.568,0.907,11.065,2.684,16.334l-12.232,7.581
		l12.752,20.578l12.238-7.583c3.934,3.912,8.456,7.176,13.441,9.652l-3.28,14.002l23.575,5.521l3.274-13.973
		c5.701-0.012,11.216-0.958,16.379-2.672L90.396,139.128z M38.925,70.896c2.997-12.779,14.23-21.707,27.322-21.707
		c2.155,0,4.327,0.248,6.452,0.745c7.312,1.708,13.509,6.174,17.466,12.542c3.96,6.372,5.196,13.914,3.481,21.226
		c-2.997,12.776-14.236,21.705-27.328,21.705c-2.154,0-4.321-0.254-6.452-0.75c-7.306-1.699-13.503-6.168-17.466-12.54
		C38.441,85.741,37.211,78.202,38.925,70.896z M172.222,154.756l5.331-3.304l-9.558-15.439l-5.35,3.311
		c-1.513-1.336-3.15-2.519-4.923-3.547l1.437-6.111l-17.68-4.15l-1.418,6.059c-2.069,0.125-4.079,0.455-6.041,0.976l-3.275-5.272
		l-15.427,9.576l3.228,5.189c-1.342,1.536-2.494,3.238-3.535,5.035l-6.028-1.4l-4.149,17.673l6.1,1.425
		c0.13,2.033,0.449,4.02,0.976,5.993l-5.313,3.299l9.575,15.427l5.308-3.298c1.537,1.354,3.18,2.529,4.941,3.546l-1.431,6.1
		l17.68,4.139l1.412-6.053c2.069-0.131,4.079-0.449,6.041-0.97l3.274,5.278l15.428-9.569l-3.228-5.202
		c1.354-1.536,2.506-3.238,3.534-5.035l6.029,1.406l4.149-17.679l-6.106-1.425C173.067,158.687,172.748,156.7,172.222,154.756z
		 M154.921,165.768c-1.638,6.975-8.742,11.479-15.853,9.823c-3.417-0.81-6.337-2.896-8.187-5.887
		c-1.862-2.979-2.43-6.514-1.638-9.954c1.407-5.999,6.686-10.202,12.814-10.202c1.017,0,2.021,0.131,3.015,0.355
		C152.167,151.594,156.576,158.687,154.921,165.768z M199.961,95.663l8.286,1.936l2.767-11.783l-8.263-1.933
		c0.059-3.352-0.491-6.673-1.62-9.821l7.224-4.486l-6.39-10.282l-7.218,4.478c-2.328-2.397-5.06-4.365-8.086-5.805l1.939-8.26
		l-11.786-2.766l-1.927,8.242c-0.143,0-0.296-0.023-0.443-0.023c-3.293,0-6.467,0.562-9.428,1.605l-4.428-7.161l-10.29,6.371
		l4.479,7.241c-2.363,2.305-4.338,5.009-5.786,8.08l-8.293-1.939l-2.754,11.786l8.251,1.93c-0.059,3.354,0.49,6.67,1.619,9.824
		l-7.211,4.48l6.39,10.276l7.205-4.471c2.335,2.396,5.06,4.368,8.086,5.807l-1.927,8.264l11.774,2.767l1.938-8.252
		c0.148,0,0.296,0.023,0.438,0.023c3.286,0,6.455-0.562,9.422-1.602l4.439,7.17l10.278-6.396l-4.48-7.226
		C196.533,101.443,198.501,98.73,199.961,95.663z M158.692,79.786c1.738-7.376,8.204-12.521,15.759-12.521
		c1.247,0,2.5,0.145,3.724,0.429c4.22,0.993,7.79,3.558,10.071,7.234c2.282,3.674,3.003,8.024,2.01,12.244
		c-2.01,8.577-10.77,14.133-19.481,12.067c-4.209-0.987-7.79-3.558-10.072-7.232C158.42,88.333,157.711,83.992,158.692,79.786z"
        />
      </g>
    </SvgIcon>
  );
};

export default MachineIcon;
