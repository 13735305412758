import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';
import isEmpty from 'is-empty';
import ProfitProfileSuppliesTable from './ProfitProfileSuppliesTable';
import ProfitProfileSupplyForm from './ProfitProfileSupplyForm';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import { green, red } from '@mui/material/colors';

const money = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const ValidationSchema = Yup.object().shape({
  priceCasesProduced: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  priceCasesCat2Produced: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  costLaborPerHour: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
});

const ProfitProfileForm = ({
  machineId,
  commodityId,
  itemDescription,
  onSubmit,
  savingData,
  editMode,
  profitProfile,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
    defaultValues: {
      priceCasesProduced: editMode ? profitProfile.priceCasesProduced : '',
      priceCasesCat2Produced: editMode
        ? profitProfile.priceCasesCat2Produced
        : '',
      costLaborPerHour: editMode ? profitProfile.costLaborPerHour : '',
    },
  });
  const [supply, setSupply] = useState({});
  const [supplies, setSupplies] = useState(
    editMode ? profitProfile.supplies : []
  );
  const [suppliesTotalCost, setSuppliesTotalCost] = useState(0);
  const [editModeSupply, setEditModeSupply] = useState(false);
  const [openAddSupply, setOpenAddEditSupply] = useState(false);
  const [openDeleteSupply, setOpenDeleteSupply] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (editMode === false) {
      setValue('machineId', machineId);
      setValue('commodityId', commodityId);
      isEmpty(itemDescription)
        ? setValue('itemDescription', 'NONE')
        : setValue('itemDescription', itemDescription);
    }
  }, [setValue, machineId, commodityId, itemDescription, editMode]);

  useEffect(() => {
    if (supplies.length > 0) {
      setSuppliesTotalCost(
        supplies.reduce((acc, val) => {
          return acc + val.costTotal;
        }, 0)
      );
    }
  }, [supplies]);

  const handleClickAddSupply = useCallback(() => {
    setEditModeSupply(false);
    setOpenAddEditSupply(true);
  }, [setOpenAddEditSupply]);

  const onAddEditSupply = useCallback(
    (data) => {
      if (editModeSupply) {
        setSupplies((supplies) =>
          supplies.map((supply) => {
            if (supply.id === data.id) {
              return { ...data };
            }
            return supply;
          })
        );
      } else {
        setSupplies([...supplies, data]);
      }
      setOpenAddEditSupply(false);
      setEditModeSupply(false);
    },
    [supplies, setSupplies, setEditModeSupply, editModeSupply]
  );

  const handleClickDelete = useCallback(
    (supplyItem) => {
      setSupply(supplyItem);
      setOpenDeleteSupply(true);
    },
    [setSupply]
  );

  const onDeleteSupply = useCallback(() => {
    setSupplies([...supplies.filter((item) => item.id !== supply.id)]);
    setOpenDeleteSupply(false);
    setSupply({});
  }, [supplies, setSupplies, supply, setOpenDeleteSupply, setSupply]);

  const handleClickEdit = useCallback(
    (supplyItem) => {
      setSupply(supplyItem);
      setEditModeSupply(true);
      setOpenAddEditSupply(true);
    },
    [setSupply, setEditModeSupply, setOpenAddEditSupply]
  );

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          let suppliesList = supplies.map((item) => {
            delete item.id;
            delete item.costTotal;
            return item;
          });
          onSubmit({ ...data, supplies: suppliesList });
        })}
      >
        <Paper elevation={0}>
          <Box>
            <Grid container spacing={2} mt={1}>
              {editMode ? (
                <>
                  <Grid item xs={4}>
                    <TextField
                      size='small'
                      label='Machine'
                      fullWidth
                      value={profitProfile.machine.name}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size='small'
                      label='Commodity'
                      fullWidth
                      value={profitProfile.commodity.name}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size='small'
                      label='Item Description'
                      fullWidth
                      value={profitProfile.itemDescription}
                      disabled
                    />
                  </Grid>
                </>
              ) : null}

              <Grid item xs={6}>
                <Card
                  variant='outlined'
                  sx={{ border: `2px ${green['300']} solid` }}
                >
                  <CardHeader
                    title='Sell Price Per Unit (Revenue)'
                    sx={{
                      paddingBottom: 2,
                      paddingTop: 1,
                      color: green['700'],
                    }}
                  />
                  <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Controller
                          name='priceCasesProduced'
                          control={control}
                          defaultValue={
                            editMode ? profitProfile.priceCasesProduced : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size='small'
                              label='Cases Produced'
                              fullWidth
                              error={!!errors?.priceCasesProduced}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name='priceCasesCat2Produced'
                          control={control}
                          defaultValue={
                            editMode ? profitProfile.priceCasesCat2Produced : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size='small'
                              label='Cases Cat2 Produced'
                              fullWidth
                              error={!!errors?.priceCasesCat2Produced}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  variant='outlined'
                  sx={{ border: `2px ${red['300']} solid` }}
                >
                  <CardHeader
                    title='Direct Cost (Costs)'
                    sx={{
                      paddingBottom: 2,
                      paddingTop: 1,
                      color: red['700'],
                    }}
                  />
                  <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Controller
                          name='costLaborPerHour'
                          control={control}
                          defaultValue={
                            editMode ? profitProfile.costLaborPerHour : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size='small'
                              label='Labor per Hour'
                              fullWidth
                              error={!!errors?.costLaborPerHour}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={money.format(suppliesTotalCost)}
                          size='small'
                          label='Total Supplies'
                          fullWidth
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Box my={2.5}>
            <Card variant='outlined'>
              <CardHeader
                title='Supply Costs'
                sx={{
                  paddingBottom: 2,
                  paddingTop: 1,
                }}
                action={
                  <Button
                    variant='outlined'
                    startIcon={<AddIcon />}
                    onClick={handleClickAddSupply}
                  >
                    Add Supply Cost
                  </Button>
                }
              />
              <ProfitProfileSuppliesTable
                rows={supplies}
                handleClickDelete={handleClickDelete}
                handleClickEdit={handleClickEdit}
              />
            </Card>
          </Box>
          <Box mx={1} my={2.5} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
                startIcon={<SaveIcon />}
              >
                {savingData ? 'Saving...' : 'Save'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UndoIcon />}
                onClick={() => {
                  reset();
                  navigate('/eprofit/profit_profiles');
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
      <Dialog
        open={openAddSupply}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenAddEditSupply(false);
            setEditModeSupply(false);
            setSupply({});
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <ProfitProfileSupplyForm
            editMode={editModeSupply}
            supplyItem={supply}
            handleOnCancel={() => {
              setOpenAddEditSupply(false);
              setEditModeSupply(false);
              setSupply({});
            }}
            handleOnSubmit={onAddEditSupply}
          />
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        open={openDeleteSupply}
        handleNo={() => {
          setOpenDeleteSupply(false);
        }}
        handleYes={onDeleteSupply}
        title='eProfit - Delete Supply'
        YesText={'Yes, Delete'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to delete supply: ${supply?.item}?`}
        </Typography>
      </ConfirmationDialog>
    </>
  );
};

export default ProfitProfileForm;
