import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createMachineProfile } from '../../redux/eplan/profile-machine/services';
import { Box, Paper, Typography, Divider } from '@mui/material';
import Loading from '../../components/UI/Loading';
import MachineProfileForm from '../../components/eplan/MachineProfileForm';

const CreateMachineProfile = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  let navigate = useNavigate();
  const machineProfilesState = useSelector((state) => state.machine_profile);
  const machineProfileState = useSelector(
    (state) => state.machine_profile.item
  );
  const onSubmit = (data) => {
    dispatch(createMachineProfile(data, navigate));
  };

  return (
    <>
      <Loading loading={machineProfileState.loading} />
      <Paper elevation={0}>
        <Box display='flex' mx={1} mt={1}>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              ePlan - Add Machine Profile
            </Typography>
          </Box>
        </Box>
        <Divider variant='middle' light />
        <Box mx={1} mt={1}>
          <MachineProfileForm
            machineId={location.state.machine}
            commodityId={location.state.commodity}
            itemDescription={location.state.itemDescription}
            onSubmit={onSubmit}
            savingData={machineProfilesState.savingData}
            editMode={false}
          />
        </Box>
      </Paper>
    </>
  );
};

export default CreateMachineProfile;
