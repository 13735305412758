import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';
import LaborLogDetails from './LaborLogDetails';

const ValidationSchema = Yup.object().shape({
  employeePositionId: Yup.string().required('Employee Position is required'),
});

const LaborLogPositionForm = ({
  laborLog,
  workOrder,
  machine,
  jobType,
  employeePositions,
  handleOnSubmit,
  handleOnCancel,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  useEffect(() => {
    setValue('laborLogId', laborLog.id);
  }, [laborLog, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper elevation={0}>
          <Box component="div" display="flex" mx={1} alignItems="center">
            <Box display="flex" my={1.5} flexGrow={1} alignItems="center">
              <Typography
                color="primary"
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'eTime - Change Position'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <LaborLogDetails
            laborLog={laborLog}
            workOrderCode={workOrder?.code}
            commodityName={workOrder?.commodity}
            machineName={machine?.name}
            jobTypeName={jobType?.name}
          />
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ minWidth: 250 }}>
                  <InputLabel id="position-select-label">
                    Employee Position
                  </InputLabel>
                  <Controller
                    name="employeePositionId"
                    control={control}
                    defaultValue={laborLog.employeePosition.id}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="position-select-label"
                        label={'Employee Position'}
                        fullWidth
                        error={!!errors?.employeePositionId}
                      >
                        {employeePositions.map((postion) => (
                          <MenuItem key={postion.id} value={postion.id}>
                            {postion.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <FormHelperText>
                  {errors?.employeePositionId?.message}
                </FormHelperText>
              </Grid>
            </Grid>
          </Box>
          <Box component="div" mx={1} my={0.5} display="flex">
            <Box my={1.5} flexGrow={1}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                startIcon={<LoginIcon />}
              >
                {'Yes, Change Position'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant="outlined"
                size="large"
                startIcon={<UndoIcon />}
                onClick={handleOnCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default LaborLogPositionForm;
