import axios from 'axios';
import setAuthToken from '../../../utils/setAuthToken';
import { addNotification } from '../notifications/actions';
import { setCurrentUser, setUserLoading } from './actions';
import { loadSubscriptionSummary } from '../subscription/services';

export const loginUser = (userData) => (dispatch, getState, api) => {
  dispatch(setUserLoading(true));
  axios
    .post(`${api}/auth/login`, userData)
    .then((res) => {
      // Set token to Auth header
      setAuthToken(res.data.tokens.access.token);
      // Set current user
      dispatch(setCurrentUser(res.data.user));
      localStorage.setItem('user', JSON.stringify(res.data.user));
      // Set tokens
      localStorage.setItem('jwt_Tokens', JSON.stringify(res.data.tokens));
      dispatch(loadSubscriptionSummary(res.data.user.company.id));
    })
    .catch((err) => {
      console.error(err);
      let notification;
      if (!err.response) {
        notification = {
          message: '😱 Network error',
          options: {
            variant: 'error',
          },
        };
      } else if (err.response.status === 401) {
        notification = {
          message: '😱 Wrong username or password',
          options: {
            variant: 'error',
          },
        };
      } else {
        notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
      }
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setUserLoading(false));
    });
};

export const switchCompany = (company) => (dispatch, getState, api) => {
  const currentTokens = JSON.parse(localStorage.getItem('jwt_Tokens'));
  const requestData = {
    companyId: company.id,
    refreshToken: currentTokens.refresh.token,
  };
  dispatch(setUserLoading(true));
  axios
    .post(`${api}/auth/switch-company`, requestData)
    .then((res) => {
      // Save to localStorage
      // Set token to Auth header
      setAuthToken(res.data.access.token);
      localStorage.setItem('jwt_Tokens', JSON.stringify(res.data));

      // Update current user
      let user = JSON.parse(localStorage.getItem('user'));
      user.company = company;
      localStorage.setItem('user', JSON.stringify(user));
      dispatch(setCurrentUser(user));

      window.location.href = '/';
    })
    .catch((err) => {
      console.error(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setUserLoading(false));
    });
};

export const resetPassword =
  (token, data, navigate) => (dispatch, getState, api) => {
    axios
      .post(`${api}/auth/reset-password?token=${token}`, data)
      .then((res) => {
        navigate('/auth/login');
        const notification = {
          message: '👍🏻 Password changed successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: Boolean(err.response.data)
            ? err.response.data.message
            : '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      });
  };

export const forgotPassword = (data, navigate) => (dispatch, getState, api) => {
  axios
    .post(`${api}/auth/forgot-password`, data)
    .then((res) => {
      navigate('/auth/login');
      const notification = {
        message: 'An email will be sent to reset password',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
};

export const activateCompanyAndLoginUser =
  (data) => (dispatch, getState, api) => {
    axios
      .post(`${api}/auth/activate-company`, data)
      .then((res) => {
        // Set token to Auth header
        setAuthToken(res.data.tokens.access.token);
        // Set current user
        dispatch(setCurrentUser(res.data.user));
        localStorage.setItem('user', JSON.stringify(res.data.user));
        // Set tokens
        localStorage.setItem('jwt_Tokens', JSON.stringify(res.data.tokens));
        dispatch(loadSubscriptionSummary(res.data.user.company.id));
        window.location.href = '/';
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      });
  };
