import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  resetProductionLineItem,
  resetWorkOrderViewList,
  resetCommodityViewList,
  resetMachineViewList,
  resetTableViewList,
  resetProductionLinePallet,
} from '../../redux/epack/production/actions';
import {
  startAll,
  stopAll,
  unavailableAll,
  closeAll,
  loadWorkOrderViewList,
  loadCommodityViewList,
  loadMachineViewList,
  loadTableViewList,
  addProductionLine,
  closeByWorkOrder,
  closeByCommodity,
  closeByMachine,
  startAllByWorkOrder,
  startAllByCommodity,
  startAllByMachine,
  stopAllByWorkOrder,
  stopAllByCommodity,
  stopAllByMachine,
  unavailableAllByWorkOrder,
  unavailableAllByCommodity,
  unavailableAllByMachine,
  stopProductionLine,
  closeProductionLine,
  unavailableProductionLine,
  loadProductionLine,
  setupProductionLine,
  updateSanitationOnProductionLine,
  loadProductionLinePalletByCode,
  addPalletsToProductionLineFromWorkOrder,
} from '../../redux/epack/production/services';
import { loadSetupChecklistItems } from '../../redux/list/setup-checklist-items/services';
import { resetSetupChecklistItems } from '../../redux/list/setup-checklist-items/actions';
import { getSetupChecklistItems } from '../../redux/list/setup-checklist-items/selectors';
import { startProductionLine } from '../../redux/epack/production/services';
import {
  getWorkOrderViewList,
  getCommodityViewList,
  getMachineViewList,
  getTableViewList,
} from '../../redux/epack/production/selectors';
import { loadWorkOrderList } from '../../redux/list/work-orders/services';
import { resetListWorkOrderList } from '../../redux/list/work-orders/actions';
import { getListWorkOrderList } from '../../redux/list/work-orders/selectors';
import { loadMachineList } from '../../redux/list/machines/services';
import { resetMachineList } from '../../redux/list/machines/actions';
import { getMachineList } from '../../redux/list/machines/selectors';
import {
  Box,
  Button,
  Divider,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  InputAdornment,
  IconButton,
  Slide,
} from '@mui/material';
import ProductionWorkOrderViewTileList from '../../components/epack/ProductionWorkOrderViewTileList';
import ProductionCommodityViewTileList from '../../components/epack/ProductionCommodityViewTileList';
import ProductionMachineViewTileList from '../../components/epack/ProductionMachineViewTileList';
import ProductionTableViewList from '../../components/epack/ProductionTableViewList';
import Empty from '../../components/UI/Empty';
import Loading from '../../components/UI/Loading';
import ProductionLineAddForm from '../../components/epack/ProductionLineAddForm';
import UnavailableForm from '../../components/epack/UnavailableForm';
import ConfirmationDialod from '../../components/UI/ConfirmationDialog';
import ProductionLineCheckList from '../../components/epack/ProductionLineCheckList';
import ProductionLineSanitation from '../../components/epack/ProductionLineSanitation';
import TabPanel from '../../components/UI/TabPanel';
import ScanAddIcon from '../../components/UI/icons/ScanAddIcon';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CloseIcon from '@mui/icons-material/Close';
import MachineIcon from '../../components/UI/icons/MachineIcon';
import CommodityIcon from '../../components/UI/icons/CommodityIcon';
import TableViewIcon from '@mui/icons-material/TableView';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AddIcon from '@mui/icons-material/Add';
import StopIcon from '@mui/icons-material/Stop';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { allowedKeyCodes } from '../../utils/allowKeyCodes';

const isEmpty = require('is-empty');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

let inputString = '';

const ProductionPanel = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [openUnavailableDialog, setOpenUnavailableDialog] = useState(false);
  const [openCloseAllDialog, setOpenCloseAllDialog] = useState(false);
  const [workOrder, setWorkOrder] = useState(null);
  const [commodity, setCommodity] = useState(null);
  const [machine, setMachine] = useState(null);
  const [productionLine, setProductionLine] = useState(null);
  const [expandedWorkOrderTiles, setExpandedWorkOrderTiles] = useState([]);
  const [expandedCommodityTiles, setExpandedCommodityTiles] = useState([]);
  const [expandedMachineTiles, setExpandedMachineTiles] = useState([]);
  const [openAddProductionLine, setOpenAddProductionLine] = useState(false);
  const [
    openAddProductionLineConfirmation,
    setOpenAddProductionLineConfirmation,
  ] = useState(false);
  const [openUnavailableAllDialog, setOpenUnavailableAllDialog] =
    useState(false);
  const [
    openUnavailableProductionLineDialog,
    setOpenUnavailableProductionLineDialog,
  ] = useState(false);
  const [openCloseByWorkOrderDialog, setOpenCloseByWorkOrderDialog] =
    useState(false);
  const [openCloseByCommodityDialog, setOpenCloseByCommodityDialog] =
    useState(false);
  const [openCloseByMachineDialog, setOpenCloseByMachineDialog] =
    useState(false);
  const [openSetupChecklistDialog, setOpenSetupChecklistDialog] =
    useState(false);
  const [openCloseProductionLineDialog, setOpenCloseProductionLineDialog] =
    useState(false);
  const [openSanitationDialog, setOpenSanitationDialog] = useState(false);
  const [
    openAddPalletsFromWorkOrderDialog,
    setOpenAddPalletsFromWorkOrderDialog,
  ] = useState(false);
  const productionState = useSelector((state) => state.production);
  const WorkOrderViewList = useSelector(getWorkOrderViewList);
  const CommodityViewList = useSelector(getCommodityViewList);
  const MachineViewList = useSelector(getMachineViewList);
  const TableViewList = useSelector(getTableViewList);
  const WorkOrderList = useSelector(getListWorkOrderList);
  const MachineList = useSelector(getMachineList);
  const SetupChecklistItems = useSelector(getSetupChecklistItems);

  useEffect(() => {
    dispatch(loadTableViewList());
    dispatch(loadWorkOrderList());
    dispatch(loadMachineList());
    dispatch(loadSetupChecklistItems());
    return () => {
      dispatch(resetTableViewList());
      dispatch(resetWorkOrderViewList());
      dispatch(resetCommodityViewList());
      dispatch(resetMachineViewList());
      dispatch(resetListWorkOrderList());
      dispatch(resetMachineList());
      dispatch(resetSetupChecklistItems());
    };
  }, [dispatch]);

  useEffect(() => {
    setExpandedWorkOrderTiles([
      ...new Set(WorkOrderViewList.map((item) => item.id)),
    ]);
  }, [WorkOrderViewList]);

  useEffect(() => {
    setExpandedMachineTiles([
      ...new Set(MachineViewList.map((item) => item.id)),
    ]);
  }, [MachineViewList]);

  useEffect(() => {
    setExpandedCommodityTiles([
      ...new Set(CommodityViewList.map((item) => item.id)),
    ]);
  }, [CommodityViewList]);

  useEffect(() => {
    if (isEmpty(productionState.pallet)) return;
    if (productionState.palletLoading === true) return;

    /*Shows dialog to add pallet to add production line
    otherwise it will navigate to clipboard*/
    if (
      isEmpty(productionState.pallet.lineId) &&
      location.pathname.includes('epack/panel')
    ) {
      setOpenAddProductionLineConfirmation(true);
    } else {
      navigate(`/epack/production_clipboard/${productionState.pallet.lineId}`);
    }
    return () => {};
  }, [productionState, navigate, location.pathname, dispatch]);

  const handleScanner = useCallback(
    (event) => {
      if (event.code === 'Enter' && inputString.length >= 7) {
        dispatch(loadProductionLinePalletByCode(inputString, location));
        inputString = '';
      } else {
        if (allowedKeyCodes.indexOf(event.code) !== -1) {
          inputString += String.fromCharCode(event.keyCode);
        }
      }
    },
    [dispatch, location]
  );

  useEffect(() => {
    document.addEventListener('keyup', handleScanner);
    return () => {
      document.removeEventListener('keyup', handleScanner);
    };
  }, [handleScanner]);

  const handleChangeTab = (event, tab) => {
    if (tab === 0) {
      dispatch(loadTableViewList());
    } else if (tab === 1) {
      dispatch(loadWorkOrderViewList());
    } else if (tab === 2) {
      dispatch(loadMachineViewList());
    } else if (tab === 3) {
      dispatch(loadCommodityViewList());
    }
    if (activeTab === 0) {
      dispatch(resetTableViewList());
    } else if (activeTab === 1) {
      dispatch(resetWorkOrderViewList());
    } else if (activeTab === 2) {
      dispatch(resetMachineViewList());
    } else if (activeTab === 3) {
      dispatch(resetCommodityViewList());
    }
    setActiveTab(tab);
  };

  const handleClickStartAll = () => {
    dispatch(startAll(activeTab));
  };

  const handleClickStopAll = () => {
    dispatch(stopAll(activeTab));
  };

  const handleClickUnavailableAll = () => {
    setOpenUnavailableDialog(true);
  };

  const onUnavailableAll = (data) => {
    dispatch(unavailableAll(activeTab, data.code));
    setOpenUnavailableDialog(false);
  };

  const handleClickCloseAll = () => {
    setOpenCloseAllDialog(true);
  };

  const onCloseAll = () => {
    dispatch(closeAll(activeTab));
    setOpenCloseAllDialog(false);
  };

  const onStartAllByWorkOrder = (id) => {
    dispatch(startAllByWorkOrder(id));
  };

  const onStartAllByCommodity = (id) => {
    dispatch(startAllByCommodity(id));
  };

  const onStartAllByMachine = (id) => {
    dispatch(startAllByMachine(id));
  };

  const onStopAllByWorkOrder = (id) => {
    dispatch(stopAllByWorkOrder(id));
  };

  const onStopAllByCommodity = (id) => {
    dispatch(stopAllByCommodity(id));
  };

  const onStopAllByMachine = (id) => {
    dispatch(stopAllByMachine(id));
  };

  const handleClicAddProductionLineWorkOrderView = (workOrder) => {
    setWorkOrder(workOrder);
    setOpenAddProductionLine(true);
  };

  const handleClicAddProductionLineCommodityView = (workOrder) => {
    setWorkOrder(workOrder);
    setOpenAddProductionLine(true);
  };

  const handleClicAddProductionLineMachineView = (machine) => {
    setMachine(machine);
    setOpenAddProductionLine(true);
  };

  const onAddProductionLine = (data) => {
    const palletScanned = isEmpty(productionState.pallet) ? false : true;
    dispatch(addProductionLine(activeTab, data, palletScanned));
    setOpenAddProductionLine(false);
    setWorkOrder(null);
    setMachine(null);
  };

  const handleClickCloseByWorkOrder = (workOrder) => {
    setWorkOrder(workOrder);
    setOpenCloseByWorkOrderDialog(true);
  };

  const onCloseByWorkOrder = () => {
    dispatch(closeByWorkOrder(workOrder.id));
    setOpenCloseByWorkOrderDialog(false);
    setWorkOrder(null);
  };

  const handleClickCloseByCommodity = (commodity) => {
    setCommodity(commodity);
    setOpenCloseByCommodityDialog(true);
  };

  const onCloseByCommodity = () => {
    dispatch(closeByCommodity(commodity.id));
    setOpenCloseByCommodityDialog(false);
    setCommodity(null);
  };

  const handleClickCloseByMachine = (machine) => {
    setMachine(machine);
    setOpenCloseByMachineDialog(true);
  };

  const onCloseByMachine = () => {
    dispatch(closeByMachine(machine.id));
    setOpenCloseByMachineDialog(false);
    setMachine(null);
  };

  const handleClickUnavailableAllByWorkOrder = (workOrder) => {
    setWorkOrder(workOrder);
    setOpenUnavailableAllDialog(true);
  };

  const handleClickUnavailableAllByCommodity = (commodity) => {
    setCommodity(commodity);
    setOpenUnavailableAllDialog(true);
  };

  const handleClickUnavailableAllByMachine = (machine) => {
    setMachine(machine);
    setOpenUnavailableAllDialog(true);
  };

  const onUnavailableAllByWorkOrder = (data) => {
    dispatch(unavailableAllByWorkOrder(workOrder.id, data.code));
    setOpenUnavailableAllDialog(false);
    setWorkOrder(null);
  };

  const onUnavailableAllByCommodity = (data) => {
    dispatch(unavailableAllByCommodity(commodity.id, data.code));
    setOpenUnavailableAllDialog(false);
    setCommodity(null);
  };

  const onUnavailableAllByMachine = (data) => {
    dispatch(unavailableAllByMachine(machine.id, data.code));
    setOpenUnavailableAllDialog(false);
    setMachine(null);
  };

  const handleClickUnavailableProductionLine = (productionLine) => {
    setProductionLine(productionLine);
    setOpenUnavailableProductionLineDialog(true);
  };

  const onUnavailableProductionLine = (data) => {
    dispatch(
      unavailableProductionLine(activeTab, productionLine.id, data.code)
    );
    setOpenUnavailableProductionLineDialog(false);
    setProductionLine(null);
  };

  const onStartProductionLine = (id) => {
    dispatch(startProductionLine(activeTab, id));
  };

  const onStartProductionLineFromSetup = (productionLine) => {
    dispatch(startProductionLine(activeTab, productionLine.id));
    setOpenSetupChecklistDialog(false);
    setProductionLine(productionLine);
    setOpenAddPalletsFromWorkOrderDialog(true);
  };

  const onStopProductionLine = (id) => {
    dispatch(stopProductionLine(activeTab, id));
  };

  const handleClickCloseProductionLine = (productionLine) => {
    setProductionLine(productionLine);
    setOpenCloseProductionLineDialog(true);
  };

  const onCloseProductionLine = () => {
    dispatch(closeProductionLine(activeTab, productionLine.id));
    setOpenCloseProductionLineDialog(false);
    setProductionLine(null);
  };
  const handleClickSetupChecklist = (id) => {
    dispatch(loadProductionLine(id));
    setOpenSetupChecklistDialog(true);
  };

  const handleCloseSetupChecklist = () => {
    dispatch(resetProductionLineItem());
    setOpenSetupChecklistDialog(false);
  };

  const onSetupChecklistSave = (id, list) => {
    const setupItemsList = list.map((item) => {
      return {
        active: item.active,
        name: item.name,
      };
    });
    dispatch(setupProductionLine(id, setupItemsList, activeTab));
    dispatch(resetProductionLineItem());
    setOpenSetupChecklistDialog(false);
  };

  const handleClickSanitation = (id) => {
    dispatch(loadProductionLine(id));
    setOpenSanitationDialog(true);
  };

  const handleCloseSanitation = () => {
    dispatch(resetProductionLineItem());
    setOpenSanitationDialog(false);
  };

  const onUpdateSanitation = (id, sanitationData) => {
    dispatch(updateSanitationOnProductionLine(id, sanitationData, activeTab));
    setOpenSanitationDialog(false);
  };

  const handleClickClipboard = (id) => {
    navigate(`/epack/production_clipboard/${id}`);
  };

  const handleOnSubmitUnavailableForm = (data) => {
    if (activeTab === 0) {
      onUnavailableAllByWorkOrder(data);
    } else if (activeTab === 1) {
      onUnavailableAllByCommodity(data);
    } else if (activeTab === 2) {
      onUnavailableAllByMachine(data);
    }
  };

  const onAddPalletsToProductionLineFromWorkOrder = () => {
    dispatch(addPalletsToProductionLineFromWorkOrder(productionLine.id));
    setOpenAddPalletsFromWorkOrderDialog(false);
    setProductionLine(null);
  };

  const handleClickWIPDashboard = (id) => {
    navigate(`/dashboard/wip/${id}`);
  };

  return (
    <>
      <Loading
        loading={
          productionState.palletLoading ||
          productionState.loading ||
          productionState.table_view_list_loading ||
          productionState.work_order_view_list_loading ||
          productionState.machine_view_list_loading ||
          productionState.commodity_view_list_loading
        }
      />
      <Loading loading={productionState.loading} />
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              ePack - Production Panel
            </Typography>
          </Box>
          <Box my={0.5}>
            <TextField
              id='scan-pallet'
              label='Scan Pallet'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <ScanAddIcon />
                  </InputAdornment>
                ),
              }}
              variant='outlined'
              size='small'
              sx={{ width: '180px' }}
            />
            <Tooltip title='Start All'>
              <span>
                <Button
                  color='primary'
                  startIcon={<PlayArrowIcon />}
                  sx={{ marginLeft: '0.5rem', color: 'success.main' }}
                  onClick={handleClickStartAll}
                >
                  Start All
                </Button>
              </span>
            </Tooltip>
            <Tooltip title='Stop All'>
              <span>
                <Button
                  color='primary'
                  startIcon={<StopIcon />}
                  sx={{ marginLeft: '0.5rem', color: 'error.main' }}
                  onClick={handleClickStopAll}
                >
                  Stop All
                </Button>
              </span>
            </Tooltip>
            <Tooltip title='Unavailable All'>
              <span>
                <Button
                  color='primary'
                  startIcon={<ReportProblemIcon />}
                  sx={{ marginLeft: '0.5rem', color: 'error.main' }}
                  onClick={handleClickUnavailableAll}
                >
                  Unavailable All
                </Button>
              </span>
            </Tooltip>
            <Tooltip title='Close All'>
              <Button
                color='primary'
                startIcon={<CloseIcon />}
                sx={{ marginLeft: '0.5rem', color: 'error.main' }}
                onClick={handleClickCloseAll}
              >
                Close All
              </Button>
            </Tooltip>
            {activeTab === 0 && (
              <Tooltip title='Add Production Line'>
                <IconButton
                  sx={{ marginLeft: '0.5rem', color: 'success.main' }}
                  onClick={() => {
                    setOpenAddProductionLine(true);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Divider variant='middle' light />
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={activeTab}
            variant='fullWidth'
            onChange={handleChangeTab}
          >
            <Tab
              icon={<TableViewIcon />}
              iconPosition='start'
              label='Table View'
              value={0}
            />
            <Tab
              icon={<ListAltIcon />}
              iconPosition='start'
              label='WorkOrder View'
              value={1}
            />
            <Tab
              icon={<MachineIcon />}
              iconPosition='start'
              label='Machine View'
              value={2}
            />
            <Tab
              icon={<CommodityIcon />}
              iconPosition='start'
              label='Commodity View'
              value={3}
            />
          </Tabs>
          <TabPanel value={activeTab} index={0}>
            <Box my={1}>
              <Box my={0} display='flex' flexDirection='row'>
                <Box flexGrow={1} mx={1}>
                  {!isEmpty(productionState.table_view_list) && (
                    <ProductionTableViewList
                      rows={TableViewList}
                      handleClickSetupChecklist={handleClickSetupChecklist}
                      onStartProductionLine={onStartProductionLine}
                      onStopProductionLine={onStopProductionLine}
                      handleClickClipboard={handleClickClipboard}
                      handleClickSanitation={handleClickSanitation}
                      handleClickCloseProductionLine={
                        handleClickCloseProductionLine
                      }
                      handleClickUnavailableProductionLine={
                        handleClickUnavailableProductionLine
                      }
                      handleClickWIPDashboard={handleClickWIPDashboard}
                    />
                  )}
                  {isEmpty(productionState.table_view_list) &&
                    !productionState.table_view_list_loading && (
                      <Empty description='There are no items to show' />
                    )}
                </Box>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <Box my={1}>
              <Box my={0} display='flex' flexDirection='row'>
                <Box flexGrow={1} mx={1}>
                  {!isEmpty(productionState.work_order_view_list) && (
                    <ProductionWorkOrderViewTileList
                      workOrders={WorkOrderViewList}
                      expandedWorkOrderTiles={expandedWorkOrderTiles}
                      setExpandedWorkOrderTiles={setExpandedWorkOrderTiles}
                      handleClicAddProductionLine={
                        handleClicAddProductionLineWorkOrderView
                      }
                      handleClickCloseByWorkOrder={handleClickCloseByWorkOrder}
                      onStartAllByWorkOrder={onStartAllByWorkOrder}
                      onStopAllByWorkOrder={onStopAllByWorkOrder}
                      handleClickUnavailableAllByWorkOrder={
                        handleClickUnavailableAllByWorkOrder
                      }
                      onStartProductionLine={onStartProductionLine}
                      onStopProductionLine={onStopProductionLine}
                      handleClickCloseProductionLine={
                        handleClickCloseProductionLine
                      }
                      handleClickUnavailableProductionLine={
                        handleClickUnavailableProductionLine
                      }
                      handleClickSetupChecklist={handleClickSetupChecklist}
                      handleClickSanitation={handleClickSanitation}
                      handleClickClipboard={handleClickClipboard}
                      handleClickWIPDashboard={handleClickWIPDashboard}
                    />
                  )}
                  {isEmpty(WorkOrderViewList) &&
                    !productionState.work_order_view_list_loading && (
                      <Empty description='There are no work orders to show' />
                    )}
                </Box>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <Box my={1}>
              <Box my={0} display='flex' flexDirection='row'>
                <Box flexGrow={1} mx={1}>
                  {!isEmpty(productionState.machine_view_list) && (
                    <ProductionMachineViewTileList
                      machines={MachineViewList}
                      expandedMachineTiles={expandedMachineTiles}
                      setExpandedMachineTiles={setExpandedMachineTiles}
                      handleClicAddProductionLine={
                        handleClicAddProductionLineMachineView
                      }
                      handleClickCloseByMachine={handleClickCloseByMachine}
                      onStartAllByMachine={onStartAllByMachine}
                      onStopAllByMachine={onStopAllByMachine}
                      handleClickUnavailableAllByMachine={
                        handleClickUnavailableAllByMachine
                      }
                      onStartProductionLine={onStartProductionLine}
                      onStopProductionLine={onStopProductionLine}
                      handleClickCloseProductionLine={
                        handleClickCloseProductionLine
                      }
                      handleClickUnavailableProductionLine={
                        handleClickUnavailableProductionLine
                      }
                      handleClickSetupChecklist={handleClickSetupChecklist}
                      handleClickSanitation={handleClickSanitation}
                      handleClickClipboard={handleClickClipboard}
                      handleClickWIPDashboard={handleClickWIPDashboard}
                    />
                  )}
                  {isEmpty(productionState.machine_view_list) &&
                    !productionState.machine_view_list_loading && (
                      <Empty description='There are no machines to show' />
                    )}
                </Box>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={activeTab} index={3}>
            <Box my={1}>
              <Box my={0} display='flex' flexDirection='row'>
                <Box flexGrow={1} mx={1}>
                  {!isEmpty(productionState.commodity_view_list) && (
                    <ProductionCommodityViewTileList
                      commodities={CommodityViewList}
                      expandedCommodityTiles={expandedCommodityTiles}
                      setExpandedCommodityTiles={setExpandedCommodityTiles}
                      handleClicAddProductionLine={
                        handleClicAddProductionLineCommodityView
                      }
                      handleClickCloseByCommodity={handleClickCloseByCommodity}
                      onStartAllByCommodity={onStartAllByCommodity}
                      onStopAllByCommodity={onStopAllByCommodity}
                      handleClickUnavailableAllByCommodity={
                        handleClickUnavailableAllByCommodity
                      }
                      onStartProductionLine={onStartProductionLine}
                      onStopProductionLine={onStopProductionLine}
                      handleClickCloseProductionLine={
                        handleClickCloseProductionLine
                      }
                      handleClickUnavailableProductionLine={
                        handleClickUnavailableProductionLine
                      }
                      handleClickSetupChecklist={handleClickSetupChecklist}
                      handleClickSanitation={handleClickSanitation}
                      handleClickClipboard={handleClickClipboard}
                      handleClickWIPDashboard={handleClickWIPDashboard}
                    />
                  )}
                  {isEmpty(productionState.commodity_view_list) &&
                    !productionState.commodity_view_list_loading && (
                      <Empty description='There are no commodities to show' />
                    )}
                </Box>
              </Box>
            </Box>
          </TabPanel>
        </Box>
        <Dialog
          open={openUnavailableDialog}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpenUnavailableDialog(false);
            }
          }}
          disableEscapeKeyDown
          maxWidth='sm'
          fullWidth
        >
          <DialogContent>
            <UnavailableForm
              handleOnSubmit={onUnavailableAll}
              handleOnCancel={() => setOpenUnavailableDialog(false)}
            />
          </DialogContent>
        </Dialog>
        <ConfirmationDialod
          open={openCloseAllDialog}
          handleNo={() => {
            setOpenCloseAllDialog(false);
          }}
          handleYes={onCloseAll}
          title='ePack - Close All'
          YesText={'Yes, Close'}
          NoText={'No'}
        >
          <Typography variant='body1'>
            Are you sure you want to close ALL lines?
          </Typography>
        </ConfirmationDialod>

        <Dialog
          open={openAddProductionLine}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpenAddProductionLine(false);
            }
          }}
          disableEscapeKeyDown
          maxWidth='sm'
          fullWidth
        >
          <DialogContent>
            <ProductionLineAddForm
              workOrderId={workOrder?.id}
              machineId={machine?.id}
              workOrderList={WorkOrderList}
              machineList={MachineList}
              handleOnSubmit={onAddProductionLine}
              handleOnCancel={() => {
                setWorkOrder(null);
                setMachine(null);
                setOpenAddProductionLine(false);
              }}
            />
          </DialogContent>
        </Dialog>

        <ConfirmationDialod
          open={openAddProductionLineConfirmation}
          handleNo={() => {
            setOpenAddProductionLineConfirmation(false);
            dispatch(resetProductionLinePallet());
          }}
          handleYes={() => {
            setOpenAddProductionLineConfirmation(false);
            handleClicAddProductionLineWorkOrderView(
              productionState.pallet.workOrder
            );
          }}
          title='ePack - Add Production Line'
          YesText={'Yes, Add'}
          NoText={'No'}
        >
          <Typography variant='body1'>
            {`No production line associated with this pallet. Would you like to create one?`}
          </Typography>
        </ConfirmationDialod>

        <Dialog
          fullScreen
          open={openSetupChecklistDialog}
          onClose={handleCloseSetupChecklist}
          TransitionComponent={Transition}
        >
          {!isEmpty(productionState.item) && (
            <ProductionLineCheckList
              productionLine={productionState.item}
              defaultChecklistItems={SetupChecklistItems}
              onSetupChecklistSave={onSetupChecklistSave}
              onStartProductionLine={onStartProductionLineFromSetup}
              handleOnCancel={handleCloseSetupChecklist}
            />
          )}
        </Dialog>

        <Dialog
          fullScreen
          open={openSanitationDialog}
          onClose={handleCloseSanitation}
          TransitionComponent={Transition}
        >
          {!isEmpty(productionState.item) && (
            <ProductionLineSanitation
              productionLine={productionState.item}
              onUpdateSanitation={onUpdateSanitation}
              handleOnCancel={handleCloseSanitation}
            />
          )}
        </Dialog>

        <ConfirmationDialod
          open={openCloseByWorkOrderDialog}
          handleNo={() => {
            setOpenCloseByWorkOrderDialog(false);
          }}
          handleYes={onCloseByWorkOrder}
          title='ePack - Close All'
          YesText={'Yes, Close'}
          NoText={'No'}
        >
          <Typography variant='body1'>
            {`Are you sure you want to close ALL lines for Work Order: ${workOrder?.workOrder}?`}
          </Typography>
        </ConfirmationDialod>

        <ConfirmationDialod
          open={openCloseByCommodityDialog}
          handleNo={() => {
            setOpenCloseByCommodityDialog(false);
          }}
          handleYes={onCloseByCommodity}
          title='ePack - Close All'
          YesText={'Yes, Close'}
          NoText={'No'}
        >
          <Typography variant='body1'>
            {`Are you sure you want to close ALL lines for Commodity: ${commodity?.commodity}?`}
          </Typography>
        </ConfirmationDialod>

        <ConfirmationDialod
          open={openCloseByMachineDialog}
          handleNo={() => {
            setOpenCloseByMachineDialog(false);
          }}
          handleYes={onCloseByMachine}
          title='ePack - Close All'
          YesText={'Yes, Close'}
          NoText={'No'}
        >
          <Typography variant='body1'>
            {`Are you sure you want to close ALL lines for Machine: ${machine?.machine}?`}
          </Typography>
        </ConfirmationDialod>

        <ConfirmationDialod
          open={openCloseProductionLineDialog}
          handleNo={() => {
            setOpenCloseProductionLineDialog(false);
          }}
          handleYes={onCloseProductionLine}
          title='ePack - Close Production Line'
          YesText={'Yes, Close'}
          NoText={'No'}
        >
          <Typography variant='body1'>
            {`Are you sure you want to close this production line?`}
          </Typography>
        </ConfirmationDialod>

        <Dialog
          open={openUnavailableAllDialog}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpenUnavailableAllDialog(false);
            }
          }}
          disableEscapeKeyDown
          maxWidth='sm'
          fullWidth
        >
          <DialogContent>
            <UnavailableForm
              handleOnSubmit={handleOnSubmitUnavailableForm}
              handleOnCancel={() => setOpenUnavailableAllDialog(false)}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          open={openUnavailableProductionLineDialog}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpenUnavailableProductionLineDialog(false);
            }
          }}
          disableEscapeKeyDown
          maxWidth='sm'
          fullWidth
        >
          <DialogContent>
            <UnavailableForm
              handleOnSubmit={onUnavailableProductionLine}
              handleOnCancel={() =>
                setOpenUnavailableProductionLineDialog(false)
              }
            />
          </DialogContent>
        </Dialog>

        <ConfirmationDialod
          open={openAddPalletsFromWorkOrderDialog}
          handleNo={() => {
            setOpenAddPalletsFromWorkOrderDialog(false);
            setProductionLine(null);
          }}
          handleYes={onAddPalletsToProductionLineFromWorkOrder}
          title='ePack - Add Pallets from Work Order'
          YesText={'Yes, Add Pallets'}
          NoText={'No'}
        >
          <Typography variant='body1'>
            Do you want to add all pallets from the work order?
          </Typography>
        </ConfirmationDialod>
      </Paper>
    </>
  );
};

export default ProductionPanel;
