import {
  SET_FINANCIAL_EXPENSE,
  FINANCIAL_EXPENSE_LOADING,
  FINANCIAL_EXPENSE_RESET,
  SET_FINANCIAL_EXPENSES,
  FINANCIAL_EXPENSE_LIST_LOADING,
  FINANCIAL_EXPENSE_LIST_RESET,
  SET_FINANCIAL_EXPENSE_FILTERS,
  RESET_FINANCIAL_EXPENSE_FILTERS,
} from './types';
const initialState = {
  item: {},
  list: {},
  filters: {
    machine: '',
    commodity: '',
    itemDescription: '',
    startDate: '',
    endDate: '',
  },
  loading: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_FINANCIAL_EXPENSE:
      return {
        ...state,
        item: { ...action.payload },
      };
    case FINANCIAL_EXPENSE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case FINANCIAL_EXPENSE_RESET:
      return {
        ...state,
        item: {},
      };
    case SET_FINANCIAL_EXPENSES:
      return {
        ...state,
        list: { ...action.payload },
      };
    case FINANCIAL_EXPENSE_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case FINANCIAL_EXPENSE_LIST_RESET:
      return {
        ...state,
        list: {},
      };
    case SET_FINANCIAL_EXPENSE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case RESET_FINANCIAL_EXPENSE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          machine: '',
          commodity: '',
          itemDescription: '',
        },
      };
    default:
      return state;
  }
}
