import {
  SET_LIST_SETUP_CHECKLIST_ITEMS,
  LIST_SETUP_CHECKLIST_ITEMS_LOADING,
  LIST_SETUP_CHECKLIST_ITEMS_RESET,
} from './types';

export const setSetupChecklistItems = (list) => {
  return {
    type: SET_LIST_SETUP_CHECKLIST_ITEMS,
    payload: list,
  };
};

export const resetSetupChecklistItems = () => {
  return {
    type: LIST_SETUP_CHECKLIST_ITEMS_RESET,
  };
};

export const setSetupChecklistItemsLoading = (payload) => {
  return {
    type: LIST_SETUP_CHECKLIST_ITEMS_LOADING,
    payload,
  };
};
