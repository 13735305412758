export const SET_PRODUCTION_LINE_ITEM = 'SET_PRODUCTION_LINE_ITEM';
export const PRODUCTION_LINE_LOADING = 'PRODUCTION_LINE_LOADING';
export const PRODUCTION_LINE_RESET = 'PRODUCTION_LINE_RESET';

export const SET_PRODUCTION_WORK_ORDER_VIEW_LIST =
  'SET_PRODUCTION_WORK_ORDER_VIEW_LIST';
export const PRODUCTION_WORK_ORDER_VIEW_LIST_LOADING =
  'PRODUCTION_WORK_ORDER_VIEW_LIST_LOADING';
export const PRODUCTION_WORK_ORDER_VIEW_LIST_RESET =
  'PRODUCTION_WORK_ORDER_VIEW_LIST_RESET';

export const SET_PRODUCTION_COMMODITY_VIEW_LIST =
  'SET_PRODUCTION_COMMODITY_VIEW_LIST';
export const PRODUCTION_COMMODITY_VIEW_LIST_LOADING =
  'PRODUCTION_COMMODITY_VIEW_LIST_LOADING';
export const PRODUCTION_COMMODITY_VIEW_LIST_RESET =
  'PRODUCTION_COMMODITY_VIEW_LIST_RESET';

export const SET_PRODUCTION_MACHINE_VIEW_LIST =
  'SET_PRODUCTION_MACHINE_VIEW_LIST';
export const PRODUCTION_MACHINE_VIEW_LIST_LOADING =
  'PRODUCTION_MACHINE_VIEW_LIST_LOADING';
export const PRODUCTION_MACHINE_VIEW_LIST_RESET =
  'PRODUCTION_MACHINE_VIEW_LIST_RESET';

export const SET_PRODUCTION_TABLE_VIEW_LIST = 'SET_PRODUCTION_TABLE_VIEW_LIST';
export const PRODUCTION_TABLE_VIEW_LIST_LOADING =
  'PRODUCTION_TABLE_VIEW_LIST_LOADING';
export const PRODUCTION_TABLE_VIEW_LIST_RESET =
  'PRODUCTION_TABLE_VIEW_LIST_RESET';

export const SET_PRODUCTION_WIP_VIEW = 'SET_PRODUCTION_WIP_VIEW';
export const PRODUCTION_WIP_VIEW_LOADING = 'PRODUCTION_WIP_VIEW_LOADING';
export const PRODUCTION_WIP_VIEW_RESET = 'PRODUCTION_WIP_VIEW_RESET';

export const SET_PRODUCTION_LINE_PALLET = 'SET_PRODUCTION_LINE_PALLET';
export const PRODUCTION_LINE_PALLET_LOADING = 'PRODUCTION_LINE_PALLET_LOADING';
export const PRODUCTION_LINE_PALLET_RESET = 'PRODUCTION_LINE_PALLET_RESET';
export const SHOW_PALLET_ADD_SCAN = 'SHOW_PALLET_ADD_SCAN';
export const ADD_PALLET_TO_PRODUCTION_LINE = 'ADD_PALLET_TO_PRODUCTION_LINE';
export const ADD_PHOTO_TO_PRODUCTION_LINE = 'ADD_PHOTO_TO_PRODUCTION_LINE';
