import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > svg': {
      marginTop: theme.spacing(),
      marginRight: theme.spacing(0.5),
    },
  },
}));

const PlanIcon = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SvgIcon
        width="24"
        height="24"
        viewBox="0 0 44 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M5.08333 7.66669C2.41388 7.66669 0.25 9.84169 0.25 12.5V46.3334C0.25 48.9917 2.41388 51.1667 5.08333 51.1667H19.5833H24.4167H38.9167C41.5871 51.1667 43.75 48.9917 43.75 46.3334V12.5C43.75 9.84169 41.5871 7.66669 38.9167 7.66669H24.4167H19.5833H5.08333Z"
          fill="#BDC3C7"
        />
        <path
          d="M5.08333 5.25C2.41388 5.25 0.25 7.41388 0.25 10.0833V43.9167C0.25 46.5871 2.41388 48.75 5.08333 48.75H19.5833H24.4167H38.9167C41.5871 48.75 43.75 46.5871 43.75 43.9167V10.0833C43.75 7.41388 41.5871 5.25 38.9167 5.25H24.4167H19.5833H5.08333Z"
          fill="#ECF0F1"
        />
        <path
          d="M5.08333 5.25C2.41388 5.25 0.25 7.41388 0.25 10.0833V17.3333V19.75H43.75V17.3333V10.0833C43.75 7.41388 41.5871 5.25 38.9167 5.25H24.4167H19.5833H5.08333Z"
          fill="#E74C3C"
        />
        <path
          d="M11.125 11.2917C11.125 11.7677 11.0312 12.2391 10.8491 12.6789C10.6669 13.1187 10.3999 13.5183 10.0633 13.8549C9.72665 14.1916 9.32703 14.4586 8.88723 14.6407C8.44742 14.8229 7.97604 14.9167 7.5 14.9167C7.02396 14.9167 6.55258 14.8229 6.11277 14.6407C5.67297 14.4586 5.27335 14.1916 4.93674 13.8549C4.60013 13.5183 4.33311 13.1187 4.15094 12.6789C3.96876 12.2391 3.875 11.7677 3.875 11.2917C3.875 10.8156 3.96876 10.3443 4.15094 9.90446C4.33311 9.46465 4.60013 9.06504 4.93674 8.72842C5.27335 8.39181 5.67297 8.1248 6.11277 7.94262C6.55258 7.76045 7.02396 7.66669 7.5 7.66669C7.97604 7.66669 8.44742 7.76045 8.88723 7.94262C9.32703 8.1248 9.72665 8.39181 10.0633 8.72842C10.3999 9.06504 10.6669 9.46465 10.8491 9.90446C11.0312 10.3443 11.125 10.8156 11.125 11.2917Z"
          fill="#C0392B"
        />
        <path
          d="M40.125 11.2917C40.125 11.7677 40.0312 12.2391 39.8491 12.6789C39.6669 13.1187 39.3999 13.5183 39.0633 13.8549C38.7267 14.1916 38.327 14.4586 37.8872 14.6407C37.4474 14.8229 36.976 14.9167 36.5 14.9167C36.024 14.9167 35.5526 14.8229 35.1128 14.6407C34.673 14.4586 34.2733 14.1916 33.9367 13.8549C33.6001 13.5183 33.3331 13.1187 33.1509 12.6789C32.9688 12.2391 32.875 11.7677 32.875 11.2917C32.875 10.8156 32.9688 10.3443 33.1509 9.90446C33.3331 9.46465 33.6001 9.06504 33.9367 8.72842C34.2733 8.39181 34.673 8.1248 35.1128 7.94262C35.5526 7.76045 36.024 7.66669 36.5 7.66669C36.976 7.66669 37.4474 7.76045 37.8872 7.94262C38.327 8.1248 38.7267 8.39181 39.0633 8.72842C39.3999 9.06504 39.6669 9.46465 39.8491 9.90446C40.0312 10.3443 40.125 10.8156 40.125 11.2917Z"
          fill="#C0392B"
        />
        <path
          d="M5.0835 24.5834V29.4167H9.91683V24.5834H5.0835ZM12.3335 24.5834V29.4167H17.1668V24.5834H12.3335ZM19.5835 24.5834V29.4167H24.4168V24.5834H19.5835ZM26.8335 24.5834V29.4167H31.6668V24.5834H26.8335ZM34.0835 24.5834V29.4167H38.9168V24.5834H34.0835Z"
          fill="#BDC3C7"
        />
        <path
          d="M5.0835 31.8334V36.6667H9.91683V31.8334H5.0835ZM12.3335 31.8334V36.6667H17.1668V31.8334H12.3335ZM19.5835 31.8334V36.6667H24.4168V31.8334H19.5835ZM26.8335 31.8334V36.6667H31.6668V31.8334H26.8335ZM34.0835 31.8334V36.6667H38.9168V31.8334H34.0835Z"
          fill="#BDC3C7"
        />
        <path
          d="M5.0835 39.0834V43.9167H9.91683V39.0834H5.0835ZM12.3335 39.0834V43.9167H17.1668V39.0834H12.3335ZM19.5835 39.0834V43.9167H24.4168V39.0834H19.5835ZM26.8335 39.0834V43.9167H31.6668V39.0834H26.8335ZM34.0835 39.0834V43.9167H38.9168V39.0834H34.0835Z"
          fill="#BDC3C7"
        />
        <path
          d="M7.50016 0.416687C6.16544 0.416687 5.0835 1.49863 5.0835 2.83335V10.0834C5.0835 11.4181 6.16544 12.5 7.50016 12.5C8.83489 12.5 9.91683 11.4181 9.91683 10.0834V2.83335C9.91683 1.49863 8.83489 0.416687 7.50016 0.416687ZM36.5002 0.416687C35.1662 0.416687 34.0835 1.49863 34.0835 2.83335V10.0834C34.0835 11.4181 35.1662 12.5 36.5002 12.5C37.8342 12.5 38.9168 11.4181 38.9168 10.0834V2.83335C38.9168 1.49863 37.8342 0.416687 36.5002 0.416687Z"
          fill="#95A5A6"
        />
        <path
          d="M7.50016 0.416687C6.16544 0.416687 5.0835 1.38335 5.0835 2.83335V7.66669H9.91683V2.83335C9.91683 1.38335 8.83489 0.416687 7.50016 0.416687ZM36.5002 0.416687C35.1662 0.416687 34.0835 1.38335 34.0835 2.83335V7.66669H38.9168V2.83335C38.9168 1.38335 37.8342 0.416687 36.5002 0.416687Z"
          fill="#BDC3C7"
        />
      </SvgIcon>
    </div>
  );
};

export default PlanIcon;
