import axios from 'axios';
import { addNotification } from '../../../system/notifications/actions';
import {
  setMachineViewList,
  resetMachineViewList,
  setMachineViewListLoading,
  updateMachineViewItem,
  removeMachineViewItem,
} from './actions';

export const loadMachineViewList = () => (dispatch, getState, api) => {
  dispatch(setMachineViewListLoading(true));
  axios
    .get(`${api}/labor/machine-view`)
    .then((res) => {
      dispatch(resetMachineViewList());
      dispatch(setMachineViewList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineViewListLoading(false));
    });
};

export const startByMachine = (id) => (dispatch, getState, api) => {
  dispatch(setMachineViewListLoading(true));
  axios
    .post(`${api}/labor/start/by-machine/${id}`)
    .then((res) => {
      dispatch(loadMachineViewList());
      const notification = {
        message: '👍🏻 Work for all employees started',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineViewListLoading(false));
    });
};

export const stopByMachine = (id) => (dispatch, getState, api) => {
  dispatch(setMachineViewListLoading(true));
  axios
    .post(`${api}/labor/stop/by-machine/${id}`)
    .then((res) => {
      dispatch(loadMachineViewList());
      const notification = {
        message: '👍🏻 Work for all employees stopped',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineViewListLoading(false));
    });
};

export const startLaborLog = (id) => (dispatch, getState, api) => {
  dispatch(setMachineViewListLoading(true));
  axios
    .post(`${api}/labor/start/by-id/${id}`)
    .then((res) => {
      dispatch(updateMachineViewItem(res.data));
      const notification = {
        message: '👍🏻 Work started for this employee',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineViewListLoading(false));
    });
};

export const stopLaborLog = (id) => (dispatch, getState, api) => {
  dispatch(setMachineViewListLoading(true));
  axios
    .post(`${api}/labor/stop/by-id/${id}`)
    .then((res) => {
      dispatch(updateMachineViewItem(res.data));
      const notification = {
        message: '👍🏻 Work stopped for this employee',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineViewListLoading(false));
    });
};

export const changePosition = (data) => (dispatch, getState, api) => {
  dispatch(setMachineViewListLoading(true));
  axios
    .post(`${api}/labor/change-position`, { ...data })
    .then((res) => {
      dispatch(updateMachineViewItem(res.data));
      const notification = {
        message: '👍🏻 Employee position has been changed successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineViewListLoading(false));
    });
};

export const moveLaborLog = (data) => (dispatch, getState, api) => {
  dispatch(setMachineViewListLoading(true));
  axios
    .post(`${api}/labor/move/${data.laborLogId}`, {
      workOrderId: data.workOrderId,
      machineId: data.machineId,
      jobTypeId: data.jobTypeId,
    })
    .then((res) => {
      dispatch(loadMachineViewList());
      const notification = {
        message: '👍🏻 Employee has been moved successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineViewListLoading(false));
    });
};

export const moveByMachine = (machineId, data) => (dispatch, getState, api) => {
  dispatch(setMachineViewListLoading(true));
  axios
    .post(`${api}/labor/move/by-machine/${machineId}`, {
      workOrderId: data.workOrderId,
      machineId: data.machineId,
      jobTypeId: data.jobTypeId,
    })
    .then((res) => {
      dispatch(loadMachineViewList());
      const notification = {
        message: '👍🏻 Employees have been moved successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineViewListLoading(false));
    });
};

export const walkOff = (machineId, data) => (dispatch, getState, api) => {
  dispatch(setMachineViewListLoading(true));
  axios
    .post(`${api}/labor/walk-off/`, { ...data })
    .then((res) => {
      dispatch(removeMachineViewItem({ machineId: machineId, ...data }));
      const notification = {
        message: '👍🏻 Walk off information saved successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineViewListLoading(false));
    });
};
