import React from 'react';

const MaintenanceGraph = (props) => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 1000 1000'
      enableBackground='new 0 0 1000 1000'
      {...props}
    >
      <g>
        <path d='M644.8,515.2c29.3,12,61.2,18.7,94.8,18.7c138.3,0,250.4-112.1,250.4-250.4c0-14.4-1.5-28.4-3.8-42.2L808.6,431.5L640.9,401l-57.5-160.4L774.8,35.9c-11.5-1.6-23.2-2.7-35.1-2.7c-138.3,0-250.4,112.1-250.4,250.4c0,35.5,7.5,69.3,20.9,99.9C432.8,519.6,317,633.3,271.4,675.3c-14.1-4.3-29.1-6.6-44.6-6.6c-85.4,0-154.6,69.2-154.6,154.6c0,85.4,69.2,154.6,154.6,154.6c85.4,0,154.6-69.2,154.6-154.6c0-20.6-4.1-40.2-11.4-58.2C404.4,719.5,497.6,607.5,644.8,515.2z M226.9,905.6c-45.4,0-82.2-36.8-82.2-82.2c0-45.4,36.8-82.2,82.2-82.2c45.4,0,82.2,36.8,82.2,82.2C309.1,868.7,272.3,905.6,226.9,905.6z M241.7,321.2l146.4,150.2l70.2-70.2L311.9,251l35.1-35.1L150.4,19.3L10,159.7l196.6,196.6L241.7,321.2z M494.2,724.9c0-0.1,0.1-0.1,0.1-0.2C494.2,724.7,494.1,724.7,494.2,724.9z M697.4,548.2c0,0-120.4,32.7-203.1,176.6c6.3,2.1,221.7,226.9,221.7,226.9c38.8,38.8,101.7,38.8,140.4,0l56.2-56.2c38.8-38.8,38.8-101.7,0-140.4L697.4,548.2z M794.4,912.9c-9.7,9.7-25.5,9.7-35.3,0L578.6,732.4c-9.7-9.7-9.7-25.5,0-35.3c9.7-9.7,25.5-9.7,35.3,0l180.5,180.5C804.2,887.4,804.2,903.2,794.4,912.9z M873.9,833.5c-9.7,9.7-25.5,9.7-35.3,0L658.1,652.9c-9.7-9.7-9.7-25.5,0-35.3c9.7-9.7,25.5-9.7,35.3,0l180.5,180.5C883.6,807.9,883.6,823.7,873.9,833.5z' />
      </g>
    </svg>
  );
};

export default MaintenanceGraph;
