import {
  SET_LABOR_MACHINE_VIEW_LIST,
  LABOR_MACHINE_VIEW_LIST_LOADING,
  LABOR_MACHINE_VIEW_LIST_RESET,
  LABOR_MACHINE_VIEW_UPDATE_ITEM,
  LABOR_MACHINE_VIEW_REMOVE_ITEM,
} from './types';

export const setMachineViewList = (list) => {
  return {
    type: SET_LABOR_MACHINE_VIEW_LIST,
    payload: list,
  };
};

export const resetMachineViewList = () => {
  return {
    type: LABOR_MACHINE_VIEW_LIST_RESET,
  };
};

export const setMachineViewListLoading = (payload) => {
  return {
    type: LABOR_MACHINE_VIEW_LIST_LOADING,
    payload,
  };
};

export const updateMachineViewItem = (payload) => {
  return {
    type: LABOR_MACHINE_VIEW_UPDATE_ITEM,
    payload,
  };
};

export const removeMachineViewItem = (payload) => {
  return {
    type: LABOR_MACHINE_VIEW_REMOVE_ITEM,
    payload,
  };
};
