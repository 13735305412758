import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  AppBar,
  Toolbar,
  Tooltip,
  Typography,
  IconButton,
  Grid,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoPNG from '../../assets/logo.png';
import Clock from '../UI/Clock';

const DashboardHeader = ({ isAuthenticated }) => {
  let navigate = useNavigate();

  const location = useLocation();
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (location.pathname.includes('dashboard/production')) {
      setTitle('Production Dashboard');
    } else if (location.pathname.includes('dashboard/labor')) {
      setTitle('Labor Dashboard');
    } else if (location.pathname.includes('dashboard/main')) {
      setTitle('Main Dashboard');
    } else if (location.pathname.includes('dashboard/wip')) {
      setTitle('WIP Dashboard');
    }

    return () => {};
  }, [location.pathname, setTitle]);

  return (
    <AppBar position='fixed' color='inherit' elevation={0}>
      <Toolbar
        variant='dense'
        sx={{
          borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
          minHeight: '40px',
        }}
      >
        <Grid container>
          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={LogoPNG}
              alt='Logo'
              height='40px'
              onClick={() => navigate('/')}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant='h6' color='primary'>
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Clock />
            {isAuthenticated && (
              <Tooltip title='Back to App'>
                <IconButton
                  onClick={() => {
                    location.pathname.includes('dashboard/wip')
                      ? navigate('/epack/panel')
                      : navigate('/');
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardHeader;
