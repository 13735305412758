import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { resizeFile } from '../../utils/imageResizer';
import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  FormControlLabel,
  FormHelperText,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import grey from '@mui/material/colors/grey';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import PalletInIcon from '../UI/icons/PalletInIcon';
import PalletOutIcon from '../UI/icons/PalletOutIcon';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CommodityIcon from '../UI/icons/CommodityIcon';
import MachineIcon from '../UI/icons/MachineIcon';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FALLBACK_IMAGE from '../../assets/no-image.svg';
import IMAGE_PLACEHOLDER from '../../assets/placeholder.svg';
import * as Yup from 'yup';

const onMediaFallback = (event) => (event.target.src = FALLBACK_IMAGE);

const Input = styled('input')({
  display: 'none',
});

const ValidationSchema = Yup.object().shape({
  photoType: Yup.string().required('Photo type required'),
});

const ProductionPhotoForm = ({
  productionLine,
  palletsIn,
  palletsOut,
  handleOnSubmit,
  handleOnCancel,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });
  const [selectedImage, setSelectedImage] = useState();
  const [imagePreview, setImagePreview] = useState(IMAGE_PLACEHOLDER);

  useEffect(() => {
    if (!selectedImage) {
      setImagePreview(IMAGE_PLACEHOLDER);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  const onChangeHandlerFile = async (event) => {
    try {
      const file = event.target.files[0];
      let newFile = await resizeFile(file);
      setSelectedImage(newFile);
      setValue('file', newFile);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box
              display='flex'
              my={1.5}
              ml={1.5}
              flexGrow={1}
              alignItems='center'
            >
              <Typography variant='h6' color='primary' sx={{ marginLeft: 1 }}>
                ePack - Photo
              </Typography>
            </Box>
          </Box>
          <Divider light />
          <Box display='flex' mt={2} justifyContent='space-evenly'>
            <Box display='flex' alignItems='center'>
              <MachineIcon
                sx={{
                  color: grey['500'],
                  width: '18px',
                  height: '18px',
                }}
              />
              <Typography
                component='span'
                sx={{
                  marginLeft: 1,
                  fontSize: '1.3rem',
                }}
              >
                {productionLine.machine.name}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' ml={2}>
              <PalletInIcon
                sx={{
                  color: grey['500'],
                  width: '18px',
                  height: '18px',
                }}
              />
              <Typography
                component='span'
                sx={{
                  marginLeft: 1,
                  fontSize: '1.3rem',
                }}
              >
                {palletsIn}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' ml={2}>
              <PalletOutIcon
                sx={{
                  color: grey['500'],
                  width: '18px',
                  height: '18px',
                }}
              />
              <Typography
                component='span'
                sx={{
                  marginLeft: 1,
                  fontSize: '1.3rem',
                }}
              >
                {palletsOut}
              </Typography>
            </Box>
          </Box>
          <Box display='flex' mt={2} justifyContent='space-evenly'>
            <Box display='flex' alignItems='center' ml={1}>
              <ListAltIcon
                sx={{
                  color: grey['500'],
                  width: '18px',
                  height: '18px',
                }}
              />
              <Typography
                component='span'
                sx={{
                  marginLeft: 1,
                  fontSize: '1.3rem',
                }}
              >
                {` ${productionLine.workOrder.code}`}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <CommodityIcon
                sx={{
                  color: grey['500'],
                  width: '18px',
                  height: '18px',
                }}
              />
              <Typography
                component='span'
                sx={{
                  marginLeft: 1,
                  fontSize: '1.3rem',
                }}
              >
                {` ${productionLine.workOrder.commodity.name}`}
              </Typography>
            </Box>
          </Box>
          <Box display='flex' justifyContent='center' mt={2}>
            <Controller
              control={control}
              name='photoType'
              defaultValue={'PALLET'}
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value='PALLET'
                    control={<Radio />}
                    label='Pallet'
                  />
                  <FormControlLabel
                    value='CAT_2'
                    control={<Radio />}
                    label='Cat 2'
                  />
                  <FormControlLabel
                    value='GRADE_OUT'
                    control={<Radio />}
                    label='Grade Out'
                  />
                </RadioGroup>
              )}
            />
          </Box>
          <Box display='flex' justifyContent='center'>
            <FormHelperText sx={{ color: 'error.main' }}>
              {errors?.photoType?.message}
            </FormHelperText>
          </Box>

          <Box display='flex' mt={2} justifyContent='center'>
            <Card
              elevation={0}
              sx={{
                maxWidth: 300,
              }}
              square
            >
              <CardMedia
                sx={{
                  height: 300,
                  width: 300,
                }}
                image={imagePreview}
                onError={(e) => onMediaFallback(e)}
              />
            </Card>
          </Box>

          <Box display='flex' justifyContent='center' mt={0.5}>
            <label htmlFor='contained-button-file'>
              <Input
                accept='image/jpeg'
                id='contained-button-file'
                type='file'
                name='file'
                onChange={onChangeHandlerFile}
              />
              <Button
                variant='contained'
                component='span'
                startIcon={<CameraAltIcon />}
              >
                Capture
              </Button>
            </label>
          </Box>
          <Box component='div' mx={1} my={0.5} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UndoIcon />}
                onClick={handleOnCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default ProductionPhotoForm;
