import {
  SET_MACHINE_LIST,
  MACHINE_LIST_LOADING,
  MACHINE_LIST_RESET,
} from './types';

export const setMachineList = (list) => {
  return {
    type: SET_MACHINE_LIST,
    payload: list,
  };
};

export const resetMachineList = () => {
  return {
    type: MACHINE_LIST_RESET,
  };
};

export const setMachineListLoading = (payload) => {
  return {
    type: MACHINE_LIST_LOADING,
    payload,
  };
};
