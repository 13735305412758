import React from 'react';
import { Grid } from '@mui/material';
import ProductionDashboardTileItem from './ProductionDashboardTileItem';

const ProductionDashboardTileList = ({ workOrders }) => {
  return (
    <>
      <Grid container spacing={2}>
        {workOrders.map((workOrder) => (
          <Grid
            item
            md={4}
            lg={3}
            key={`${workOrder.machine}${workOrder.commodity}`}
          >
            <ProductionDashboardTileItem workOrder={workOrder} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ProductionDashboardTileList;
