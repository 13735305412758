import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './types';

export const addNotification = (notification) => {
  const key = notification.options && notification.options.key;
  return {
    type: ADD_NOTIFICATION,
    payload: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const removeNotification = (key) => {
  return {
    type: REMOVE_NOTIFICATION,
    payload: key,
  };
};
