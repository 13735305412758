import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, AppBar, Box, Button, Toolbar, Tooltip } from '@mui/material';
import { loadCompanyList } from '../../redux/list/companies/services';
import { resetCompanyList } from '../../redux/list/companies/actions';
import { getCompanyListExceptCurrent } from '../../redux/list/companies/selectors';
import { logoutUser } from '../../redux/system/auth/actions';
import { switchCompany } from '../../redux/system/auth/services';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClockIcon from '../UI/icons/ClockIcon';
import PackIcon from '../UI/icons/PackIcon';
import PlanIcon from '../UI/icons/PlanIcon';
import ProfitIcon from '../UI/icons/ProfitIcon';
import DashboardIcon from '../UI/icons/DashboardIcon';
import TenantMenu from '../layout/TenantMenu';
import EPackMenu from '../layout/EPackMenu';
import ETimeMenu from '../layout/ETimeMenu';
import EPlanMenu from '../layout/EPlanMenu';
import EProfitMenu from '../layout/EProfitMenu';
import DashboardsMenu from '../layout/DashboardsMenu';
import UserMenu from '../layout/UserMenu';
import LogoPNG from '../../assets/logo.png';
import MenuVisibilityGate from '../../utils/MenuVisibilityGate';

// const favorites = [
//   { id: 1, name: 'Production Panel', url: 'epack/panel' },
//   { id: 2, name: 'Production Dashboard', url: 'dashboard/production' },
//   { id: 3, name: 'Time Keeping Clipboard', url: 'etime/labor/unallocated' },
//   { id: 4, name: 'Time Keeping Dashboard', url: 'dashboard/labor' },
//   { id: 5, name: 'Main Dashboard', url: 'dashboard/main' },
// ];

const Header = ({ showFavorites, handleShowHideFavs }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tenantMenuAnchorEl, setTenantMenuAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const CompanyList = useSelector(getCompanyListExceptCurrent);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [eTimeMenuOpen, setETimeMenuOpen] = useState(false);
  const [ePackMenuOpen, setEPackMenuOpen] = useState(false);
  const [ePlanMenuOpen, setEPlanMenuOpen] = useState(false);
  const [eProfitMenuOpen, setEProfitMenuOpen] = useState(false);
  const [DashboardsMenuOpen, setDashboardsMenuOpen] = useState(false);
  const [tenantMenuOpen, setTenantMenuOpen] = useState(false);

  const userNameInitials =
    authState.user.firstName[0] + authState.user.lastName[0];

  const handleOpenUserMenu = (event) => {
    dispatch(loadCompanyList());
    setAnchorEl(event.currentTarget);
    setUserMenuOpen(true);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
    setUserMenuOpen(false);
  };

  const handleOpenETimeMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setETimeMenuOpen(true);
  };

  const handleCloseETimeMenu = () => {
    setAnchorEl(null);
    setETimeMenuOpen(false);
  };

  const handleOpenEPackMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setEPackMenuOpen(true);
  };

  const handleCloseEPackMenu = () => {
    setAnchorEl(null);
    setEPackMenuOpen(false);
  };

  const handleOpenEPlanMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setEPlanMenuOpen(true);
  };

  const handleCloseEPlanMenu = () => {
    setAnchorEl(null);
    setEPlanMenuOpen(false);
  };

  const handleOpenEProfitMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setEProfitMenuOpen(true);
  };

  const handleCloseEProfitMenu = () => {
    setAnchorEl(null);
    setEProfitMenuOpen(false);
  };

  const handleOpenDashboardsMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setDashboardsMenuOpen(true);
  };

  const handleCloseDashboardsMenu = () => {
    setAnchorEl(null);
    setDashboardsMenuOpen(false);
  };

  const handleOpenTenantMenu = (event) => {
    setTenantMenuAnchorEl(event.currentTarget);
    setTenantMenuOpen(true);
  };

  const handleCloseTenantMenu = () => {
    setTenantMenuAnchorEl(null);
    setAnchorEl(null);
    setTenantMenuOpen(false);
    setUserMenuOpen(false);
  };

  const handleSelectTenant = (company) => {
    dispatch(switchCompany(company));
    setAnchorEl(null);
    setTenantMenuOpen(false);
  };

  const handleLogoutClick = () => {
    dispatch(logoutUser());
    navigate('/auth/login');
  };

  return (
    <AppBar position='fixed' color='default' elevation={0}>
      <Toolbar
        variant='dense'
        sx={{
          borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
          minHeight: '40px',
        }}
      >
        <Box display='flex' component='div' mr={5}>
          <img
            src={LogoPNG}
            alt='Logo'
            height='40px'
            onClick={() => navigate('/')}
          />
        </Box>
        <Box display='flex' flexGrow={1} component='div'>
          <MenuVisibilityGate
            userRole={authState.user.role}
            allowedPermissions={['epack']}
          >
            <Tooltip title='ePack'>
              <Button
                onClick={handleOpenEPackMenu}
                sx={{
                  marginLeft: 2,
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
                size='small'
                startIcon={
                  <PackIcon
                    sx={{
                      width: '20px',
                      height: '20px',
                    }}
                  />
                }
                endIcon={<KeyboardArrowDownIcon />}
              >
                ePack
              </Button>
            </Tooltip>
            <EPackMenu
              anchorEl={anchorEl}
              open={ePackMenuOpen}
              handleClose={handleCloseEPackMenu}
              userRole={authState.user.role}
            />
          </MenuVisibilityGate>

          <MenuVisibilityGate
            userRole={authState.user.role}
            allowedPermissions={['etime']}
          >
            <Tooltip title='eTime'>
              <Button
                onClick={handleOpenETimeMenu}
                sx={{
                  marginLeft: 2,
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
                size='small'
                startIcon={
                  <ClockIcon
                    sx={{
                      width: '20px',
                      height: '20px',
                    }}
                  />
                }
                endIcon={<KeyboardArrowDownIcon />}
              >
                eTime
              </Button>
            </Tooltip>
            <ETimeMenu
              anchorEl={anchorEl}
              open={eTimeMenuOpen}
              handleClose={handleCloseETimeMenu}
              userRole={authState.user.role}
            />
          </MenuVisibilityGate>
          <MenuVisibilityGate
            userRole={authState.user.role}
            allowedPermissions={['eprofit']}
          >
            <Tooltip title='eProfit'>
              <Button
                onClick={handleOpenEProfitMenu}
                sx={{
                  marginLeft: 2,
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
                size='small'
                startIcon={
                  <ProfitIcon
                    sx={{
                      width: '20px',
                      height: '20px',
                    }}
                  />
                }
                endIcon={<KeyboardArrowDownIcon />}
              >
                eProfit
              </Button>
            </Tooltip>
            <EProfitMenu
              anchorEl={anchorEl}
              open={eProfitMenuOpen}
              handleClose={handleCloseEProfitMenu}
              userRole={authState.user.role}
            />
          </MenuVisibilityGate>
          <MenuVisibilityGate
            userRole={authState.user.role}
            allowedPermissions={['eplan']}
          >
            <Tooltip title='ePlan'>
              <Button
                onClick={handleOpenEPlanMenu}
                sx={{
                  marginLeft: 2,
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
                size='small'
                startIcon={
                  <PlanIcon
                    sx={{
                      width: '20px',
                      height: '20px',
                    }}
                  />
                }
                endIcon={<KeyboardArrowDownIcon />}
              >
                ePlan
              </Button>
            </Tooltip>
            <EPlanMenu
              anchorEl={anchorEl}
              open={ePlanMenuOpen}
              handleClose={handleCloseEPlanMenu}
              userRole={authState.user.role}
            />
          </MenuVisibilityGate>
          <MenuVisibilityGate
            userRole={authState.user.role}
            allowedPermissions={['dashboards']}
          >
            <Tooltip title='Dashboards'>
              <Button
                onClick={handleOpenDashboardsMenu}
                sx={{
                  marginLeft: 2,
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
                size='small'
                startIcon={
                  <DashboardIcon
                    sx={{
                      width: '18px',
                      height: '18px',
                    }}
                  />
                }
                endIcon={<KeyboardArrowDownIcon />}
              >
                Dashboards
              </Button>
            </Tooltip>
            <DashboardsMenu
              anchorEl={anchorEl}
              open={DashboardsMenuOpen}
              handleClose={handleCloseDashboardsMenu}
              userRole={authState.user.role}
            />
          </MenuVisibilityGate>
        </Box>
        <Box display='flex' alignItems='center' component='div'>
          <Avatar
            sx={{ bgcolor: 'primary.main', width: '35px', height: '35px' }}
            onClick={handleOpenUserMenu}
          >
            {userNameInitials}
          </Avatar>
          <UserMenu
            anchorEl={anchorEl}
            open={userMenuOpen}
            companies={CompanyList}
            handleClose={handleCloseUserMenu}
            handleShowHideFavs={handleShowHideFavs}
            handleLogoutClick={handleLogoutClick}
            handleOpenTenantMenu={handleOpenTenantMenu}
            userRole={authState.user.role}
          />
        </Box>
      </Toolbar>
      {/* {showFavorites && (
        <Toolbar
          variant='dense'
          sx={{
            borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
            minHeight: '40px',
          }}
        >
          <Box display='flex' flexGrow={1} justifyContent='space-around'>
            {favorites.map((favorite) => (
              <Button
                key={favorite.id}
                sx={{
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
                size='small'
                onClick={() => navigate(favorite.url)}
              >
                {favorite.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      )} */}
      <TenantMenu
        companies={CompanyList}
        anchorEl={tenantMenuAnchorEl}
        open={tenantMenuOpen}
        handleClose={handleCloseTenantMenu}
        handleSelectTenant={handleSelectTenant}
      />
    </AppBar>
  );
};

export default Header;
