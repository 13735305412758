import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getProfitProfileList = createSelector(
  (state) => state.profit_profile.list.docs,
  (profitProfilesList) => {
    if (isEmpty(profitProfilesList)) return [];
    return profitProfilesList;
  }
);

export const selectFilteredProfitProfiles = createSelector(
  getProfitProfileList,
  (state) => state.profit_profile.filters,
  (profitProfilesList, filters) => {
    if (isEmpty(profitProfilesList)) return [];

    if (!isEmpty(filters.profit)) {
      profitProfilesList = profitProfilesList.filter(
        (item) => item.profit.id === filters.profit
      );
    }
    if (!isEmpty(filters.commodity)) {
      profitProfilesList = profitProfilesList.filter(
        (item) => item.commodity.id === filters.commodity
      );
    }
    if (!isEmpty(filters.itemDescription)) {
      profitProfilesList = profitProfilesList.filter((item) =>
        item.itemDescription
          .toLowerCase()
          .includes(filters.itemDescription.toLowerCase())
      );
    }
    return profitProfilesList;
  }
);

export const selectExistingProfitProfile = createSelector(
  getProfitProfileList,
  (state) => state.profit_profile.filters,
  (profitProfilesList, filters) => {
    if (isEmpty(profitProfilesList)) return false;
    return (
      profitProfilesList.filter(
        (item) =>
          item.machine.id === filters.machine &&
          item.commodity.id === filters.commodity &&
          item.itemDescription.toLowerCase() ===
            filters.itemDescription.toLowerCase()
      ).length > 0
    );
  }
);
