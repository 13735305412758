import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

const DashboardNumericWidgetWithIconSmall = ({
  children,
  title,
  titleColor = grey[700],
  numericValue,
  numericValueColor = grey[700],
  backgroundImage,
}) => {
  return (
    <Card variant='outlined' sx={{ backgroundImage }}>
      <CardContent
        sx={{
          padding: 1.5,
          '&:last-child': {
            paddingBottom: 0.8,
          },
        }}
      >
        <Typography
          sx={{ fontSize: '16px', fontWeight: 'bold', color: titleColor }}
        >
          {title}
        </Typography>
        <Box display='flex' justifyContent='flex-end' mr={0.5} my={1}>
          <Typography
            sx={{
              fontSize: '36px',
              fontWeight: 'bold',
              lineHeight: 1,
              color: numericValueColor,
            }}
          >
            {children}
            {numericValue}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DashboardNumericWidgetWithIconSmall;
