import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetLaborDashboardData } from '../../redux/etime/dashboard/actions';
import {
  loadLaborDashboardData,
  loadLaborDashboardPrivateData,
} from '../../redux/etime/dashboard/services';
import {
  selectNumberOfActiveEmployees,
  selectNumberOfIdleEmployees,
  selectNumberOfUnallocatedEmployees,
  selectAllocationByJob,
  selectAllocationByPosition,
  selectAllocationByCommodity,
  selectAllocationByMachine,
} from '../../redux/etime/dashboard/selectors';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  BarElement,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
} from 'chart.js';
import { Doughnut, Bar, PolarArea } from 'react-chartjs-2';
import Empty from '../../components/UI/Empty';
import DashboardNumericWidgetWithIcon from '../../components/UI/DashboardNumericWidgetWithIcon';
import PersonIcon from '../../components/UI/icons/PersonIcon';
import { chartColors, chartLightColors } from '../../utils/chartColors';
import { grey, green, red, orange, blue } from '@mui/material/colors';
import LoadingTransparent from '../../components/UI/LoadingTransparent';
const isEmpty = require('is-empty');

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  BarElement,
  CategoryScale,
  LinearScale,
  RadialLinearScale
);

const allocationByStatusChartOptions = {
  responsive: true,
  circumference: 360,
  cutout: 0,
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      enabled: true,
    },
  },
};

const allocationByMachineChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'left',
    },
  },
};

const allocationByCommodityChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'left',
    },
  },
};

const allocationByPositionChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },
};

const allocationByJobChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },
};

const LaborDashboard = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const [firstLoad, setFirstLoad] = useState(true);
  const laborDashboardState = useSelector((state) => state.labor_dashboard);
  const NumberOfActiveEmployees = useSelector(selectNumberOfActiveEmployees);
  const NumberOfIdleEmployees = useSelector(selectNumberOfIdleEmployees);
  const NumberOfUnallocatedEmployees = useSelector(
    selectNumberOfUnallocatedEmployees
  );
  const totalEmployees =
    NumberOfActiveEmployees +
    NumberOfIdleEmployees +
    NumberOfUnallocatedEmployees;
  const AllocationByJob = useSelector(selectAllocationByJob);
  const AllocationByPosition = useSelector(selectAllocationByPosition);
  const AllocationByCommodity = useSelector(selectAllocationByCommodity);
  const AllocationByMachine = useSelector(selectAllocationByMachine);

  const getDashboardData = useCallback(() => {
    if (isEmpty(token)) {
      dispatch(loadLaborDashboardData());
    } else {
      dispatch(loadLaborDashboardPrivateData(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    getDashboardData();
    // refresh data every 2 minutes
    const interval = setInterval(getDashboardData, 120000);
    return () => {
      dispatch(resetLaborDashboardData());
      clearInterval(interval);
    };
  }, [dispatch, getDashboardData]);

  useEffect(() => {
    setTimeout(() => {
      setFirstLoad(false);
    }, 1000);
    return () => {};
  }, [setFirstLoad]);

  const allocationByStatusChartData = {
    labels: ['Working', 'Idle', 'Unallocated'],
    datasets: [
      {
        data: [
          NumberOfActiveEmployees,
          NumberOfIdleEmployees,
          NumberOfUnallocatedEmployees,
        ],
        backgroundColor: ['#2CA02C', '#FF7F0E', '#D62728'],
      },
    ],
  };

  const allocationByPositionChartData = {
    labels: AllocationByPosition.labels,
    datasets: [
      {
        data: AllocationByPosition.values,
        backgroundColor: chartColors,
      },
    ],
  };

  const allocationByJobChartData = {
    labels: AllocationByJob.labels,
    datasets: [
      {
        data: AllocationByJob.values,
        backgroundColor: chartColors,
      },
    ],
  };

  const allocationByCommodityChartData = {
    labels: AllocationByCommodity.labels,
    datasets: [
      {
        data: AllocationByCommodity.values,
        backgroundColor: chartLightColors,
        borderWidth: 3,
      },
    ],
  };

  const allocationByMachineChartData = {
    labels: AllocationByMachine.labels,
    datasets: [
      {
        data: AllocationByMachine.values,
        backgroundColor: chartLightColors,
        borderWidth: 3,
      },
    ],
  };

  return (
    <Container disableGutters sx={{ marginTop: '48px' }} maxWidth='lg'>
      <Paper elevation={0}>
        {firstLoad ? (
          <LoadingTransparent loading={laborDashboardState.loading} />
        ) : null}
        <Box sx={{ width: '100%', paddingLeft: 1, paddingRight: 1 }}>
          {!isEmpty(laborDashboardState.data) && (
            <>
              <Grid
                container
                spacing={2}
                sx={{
                  marginBottom: 2,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Grid item xs={12} sm={5} sx={{ height: '100%' }}>
                  <Card variant='outlined'>
                    <CardContent
                      sx={{
                        padding: 1.5,
                        '&:last-child': {
                          paddingBottom: 0.8,
                        },
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: 'medium',
                        }}
                      >
                        Status
                      </Typography>
                      <Box display='flex' justifyContent='center'>
                        <Box
                          display='flex'
                          sx={(theme) => ({
                            width: '450px',
                          })}
                        >
                          <Doughnut
                            data={allocationByStatusChartData}
                            options={allocationByStatusChartOptions}
                          />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Card variant='outlined' sx={{ height: '100%' }}>
                    <CardContent
                      sx={{
                        padding: 1.5,
                        '&:last-child': {
                          paddingBottom: 0.8,
                        },
                      }}
                    >
                      <Grid container spacing={1} sx={{ marginTop: 0.2 }}>
                        <Grid item xs={12}>
                          <Box>
                            <DashboardNumericWidgetWithIcon
                              title='Total'
                              titleColor={grey['100']}
                              backgroundImage={`linear-gradient( 135deg, ${blue[600]} 10%, ${blue[700]} 100%);`}
                              numericValue={totalEmployees}
                              numericValueColor={grey['100']}
                            >
                              <PersonIcon
                                sx={{
                                  width: '35px',
                                  height: '35px',
                                  marginRight: 2,
                                  color: grey['100'],
                                }}
                              />
                            </DashboardNumericWidgetWithIcon>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <DashboardNumericWidgetWithIcon
                              title='Working'
                              titleColor={grey['100']}
                              backgroundImage={`linear-gradient( 135deg, ${green[600]} 10%, ${green[700]} 100%);`}
                              numericValue={NumberOfActiveEmployees}
                              numericValueColor={grey['100']}
                            >
                              <PersonIcon
                                sx={{
                                  width: '35px',
                                  height: '35px',
                                  marginRight: 2,
                                  color: grey['100'],
                                }}
                              />
                            </DashboardNumericWidgetWithIcon>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <DashboardNumericWidgetWithIcon
                              title='Idle'
                              titleColor={grey['100']}
                              backgroundImage={`linear-gradient( 135deg, ${orange[600]} 10%, ${orange[700]} 100%);`}
                              numericValue={NumberOfIdleEmployees}
                              numericValueColor={grey['100']}
                            >
                              <PersonIcon
                                sx={{
                                  width: '35px',
                                  height: '35px',
                                  marginRight: 2,
                                  color: grey['100'],
                                }}
                              />
                            </DashboardNumericWidgetWithIcon>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <DashboardNumericWidgetWithIcon
                              title='Unallocated'
                              titleColor={grey['100']}
                              numericValue={NumberOfUnallocatedEmployees}
                              backgroundImage={`linear-gradient( 135deg, ${red[600]} 10%, ${red[700]} 100%);`}
                              numericValueColor={grey['100']}
                            >
                              <PersonIcon
                                sx={{
                                  width: '35px',
                                  height: '35px',
                                  marginRight: 2,
                                  color: grey['100'],
                                }}
                              />
                            </DashboardNumericWidgetWithIcon>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid container item xs={12} sm={4}>
                  <Grid item xs={12}>
                    <Card variant='outlined' sx={{ height: '100%' }}>
                      <CardContent
                        sx={{
                          padding: 1.5,
                          '&:last-child': {
                            paddingBottom: 0.8,
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '18px',
                            fontWeight: 'medium',
                          }}
                        >
                          Machine
                        </Typography>
                        <Box
                          display='flex'
                          justifyContent='center'
                          alignContent='center'
                        >
                          <Box
                            sx={{
                              width: '320px',
                              height: '200px',
                            }}
                          >
                            <PolarArea
                              data={allocationByMachineChartData}
                              options={allocationByMachineChartOptions}
                              style={{ transform: 'translateY(-4em)' }}
                            />
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card variant='outlined' sx={{ height: '100%' }}>
                      <CardContent
                        sx={{
                          padding: 1.5,
                          '&:last-child': {
                            paddingBottom: 0.8,
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '18px',
                            fontWeight: 'medium',
                          }}
                        >
                          Commodity
                        </Typography>
                        <Box
                          display='flex'
                          justifyContent='center'
                          alignContent='center'
                        >
                          <Box
                            sx={{
                              width: '320px',
                              height: '200px',
                            }}
                          >
                            <PolarArea
                              data={allocationByCommodityChartData}
                              options={allocationByCommodityChartOptions}
                              style={{ transform: 'translateY(-4em)' }}
                            />
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant='outlined'>
                    <CardContent
                      sx={{
                        padding: 1.5,
                        '&:last-child': {
                          paddingBottom: 0.8,
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: 'medium',
                        }}
                      >
                        Position
                      </Typography>
                      <Box
                        display='flex'
                        justifyContent='center'
                        alignContent='center'
                      >
                        <Box
                          sx={(theme) => ({
                            width: '300px',
                            [theme.breakpoints.up('lg')]: {
                              width: '450px',
                            },
                          })}
                        >
                          <Bar
                            data={allocationByPositionChartData}
                            options={allocationByPositionChartOptions}
                          />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card variant='outlined'>
                    <CardContent
                      sx={{
                        padding: 1.5,
                        '&:last-child': {
                          paddingBottom: 0.8,
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: 'medium',
                        }}
                      >
                        Job
                      </Typography>
                      <Box
                        display='flex'
                        justifyContent='center'
                        alignContent='center'
                      >
                        <Box
                          sx={(theme) => ({
                            width: '300px',
                            [theme.breakpoints.up('lg')]: {
                              width: '450px',
                            },
                          })}
                        >
                          <Bar
                            data={allocationByJobChartData}
                            options={allocationByJobChartOptions}
                          />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
          {isEmpty(laborDashboardState.data) &&
            !laborDashboardState.loading && (
              <Empty description='No data to show' />
            )}
        </Box>
      </Paper>
    </Container>
  );
};

export default LaborDashboard;
