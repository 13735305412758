import {
  SET_LINES_LIST,
  LINES_LIST_LOADING,
  LINES_LIST_RESET,
  SET_LINE_ITEM,
  LINE_LOADING,
  LINE_RESET,
} from './types';

export const setLinesList = (list) => {
  return {
    type: SET_LINES_LIST,
    payload: list,
  };
};

export const resetLinesList = () => {
  return {
    type: LINES_LIST_RESET,
  };
};

export const setLinesListLoading = (payload) => {
  return {
    type: LINES_LIST_LOADING,
    payload,
  };
};

export const setLine = (line) => {
  return {
    type: SET_LINE_ITEM,
    payload: line,
  };
};

export const resetLine = () => {
  return {
    type: LINE_RESET,
  };
};

export const setLineLoading = (payload) => {
  return {
    type: LINE_LOADING,
    payload,
  };
};
