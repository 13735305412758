import React from 'react';

const SetupGraph = (props) => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 1000 1000'
      enableBackground='new 0 0 1000 1000'
      {...props}
    >
      <g>
        <g transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'>
          <path d='M5442,4997c0-45.9-95.7-958.9-99.5-962.8c-11.5-5.7-273.7-103.4-294.8-109.1c-11.5-3.8-181.8,128.3-379,290.9c-195.2,162.7-363.7,298.6-371.3,302.4c-7.7,1.9-193.3-176.1-413.4-396.2l-398.1-400l271.8-340.7c151.2-189.5,283.3-354.1,292.8-367.5c11.5-15.3,0-63.2-42.1-147.4c-32.5-68.9-63.2-145.5-70.8-172.3c-5.7-26.8-19.1-53.6-28.7-59.3c-13.4-7.7-733.1-90-892-103.4c-3.8,0-5.7-258.4-5.7-574.2c0-446,5.7-574.2,24.9-574.2c61.3-1.9,876.7-91.9,880.5-97.6c9.6-13.4,132.1-313.9,139.7-342.6c5.7-17.2-109.1-176.1-277.5-384.7l-285.2-356l392.4-405.8c214.4-222,398.1-403.8,403.9-403.8c7.7,0,120.6,91.9,252.7,202.9c132.1,111,294.8,248.8,365.6,304.3L5032.4,3.2l157-59.3c143.6-55.5,157-65.1,166.5-118.7c7.7-55.5,86.1-872.8,86.1-913c0-9.6,225.9-17.2,570.4-17.2h570.4l44,465.1c22.9,254.6,47.9,478.5,51.7,495.8c5.8,19.1,51.7,45.9,109.1,65.1c55.5,17.2,122.5,44,151.2,59.4c53.6,24.9,57.4,23,421.1-281.4c201-168.4,373.2-306.3,380.9-306.3c19.1,0,782.9,786.7,782.9,807.7c0,9.6-126.3,172.3-279.5,363.7c-172.3,216.3-273.7,359.8-268,377.1c7.6,26.8,130.2,327.3,137.8,342.6c1.9,3.8,170.4,23,373.2,44c204.8,19.1,407.7,40.2,453.6,45.9l80.4,11.5v572.3v572.3l-80.4,11.5c-45.9,5.7-248.8,26.8-453.6,45.9c-202.9,21.1-371.3,40.2-373.2,44c-11.5,17.2-145.4,354.1-145.4,365.6c0,7.7,116.7,162.7,262.2,342.6c143.6,181.8,268,342.6,279.5,354.1c11.5,17.2-93.8,139.7-371.3,426.8c-214.4,220.1-396.2,401.9-405.8,401.9c-7.7,0-178-135.9-377.1-302.4l-363.7-304.3l-160.8,59.3l-158.9,59.3l-44,493.8l-45.9,491.9h-570.4C5568.3,5020,5442,5014.3,5442,4997z M6213.3,3096.4c712-126.3,1127.4-928.3,834.5-1611.6c-99.5-233.5-313.9-457.5-541.7-568.5c-172.3-82.3-308.2-114.8-491.9-112.9c-312,1.9-560.8,105.3-780.9,329.2c-168.4,168.4-254.6,315.8-308.2,530.2c-72.7,281.4-34.5,601,99.5,855.6c72.7,137.8,291,367.5,421.1,442.2c114.8,67,279.5,124.4,415.4,143.6C6016.2,3127,6046.8,3127,6213.3,3096.4z' />
          <path d='M5757.8,2533.6c-486.2-233.5-486.2-918.8,0-1152.3c95.7-45.9,139.7-55.5,260.3-55.5c122.5,0,162.7,9.6,262.2,57.4c386.6,195.2,476.6,689.1,183.8,1014.4C6291.8,2589.1,5993.2,2646.6,5757.8,2533.6z' />
          <path d='M3145.1-1968.2c-250.7-23-578.1-70.8-736.9-109.1l-134-32.5l-5.7-1171.4c-3.8-932.2,0-1173.3,19.1-1173.3c13.4,0,103.4,32.5,197.1,74.6c438.3,187.6,823,277.5,1138.9,266c185.7-7.6,216.3-15.3,411.5-95.7c583.8-243.1,1353.2-434.5,2153.3-535.9c99.5-11.5,336.9-23,526.4-23c289,0,371.3,5.8,509.1,40.2c509.1,128.3,1186.7,509.1,1858.6,1043.2c237.3,189.5,491.9,432.6,581.9,553.2c162.7,222,78.5,342.6-243.1,342.6c-181.8,0-446-72.7-754.2-210.6c-476.6-210.5-937.9-350.3-1454.7-442.1c-214.4-38.3-312-44.1-717.8-44.1c-411.5,0-490,3.8-643.1,40.2c-317.7,74.6-589.5,199-788.6,363.7c-45.9,36.4-78.5,70.8-74.7,74.6c3.8,3.8,191.4-5.8,417.3-24.9c528.3-40.2,1734.1-45.9,1839.4-9.6c191.4,68.9,59.3,275.6-281.4,442.2c-417.3,204.8-1498.7,490-2210.7,583.8C4243.8-1947.2,3593-1928.1,3145.1-1968.2z' />
          <path d='M254.9-3440.2V-4780h669.9h669.9v1339.8v1339.8H924.8H254.9V-3440.2z' />
        </g>
      </g>
    </svg>
  );
};

export default SetupGraph;
