import {
  WORK_ORDER_LOADING,
  SET_WORK_ORDER,
  WORK_ORDER_RESET,
  SET_WORK_ORDERS,
  WORK_ORDERS_LIST_LOADING,
  WORK_ORDERS_LIST_RESET,
  ADD_WORK_ORDER_TO_LIST,
  UPDATE_WORK_ORDER_IN_LIST,
  DELETE_WORK_ORDER_FROM_LIST,
  DELETE_PALLET_FROM_WORK_ORDER_LIST,
  SHOW_ADD_EDIT_WORK_ORDER_FORM,
  SAVING_WORK_ORDER_DATA,
  SHOW_IMPORT_WORK_ORDERS_FORM,
  IMPORTING_WORK_ORDERS_DATA,
} from './types';
const initialState = {
  view: {
    record: {},
  },
  list: {},
  loading: false,
  showAddEditForm: false,
  savingData: false,
  showImportForm: false,
  importingData: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case WORK_ORDER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_WORK_ORDER:
      return {
        ...state,
        view: {
          record: action.payload,
        },
        loading: false,
      };
    case WORK_ORDER_RESET:
      return {
        ...state,
        view: {
          record: {},
        },
        loading: false,
      };
    case SET_WORK_ORDERS:
      return {
        ...state,
        list: { ...action.payload },
      };
    case WORK_ORDERS_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case WORK_ORDERS_LIST_RESET:
      return {
        ...state,
        list: {},
      };
    case ADD_WORK_ORDER_TO_LIST:
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          docs: [action.payload, ...state.list.docs],
          totalDocs: state.list.totalDocs + 1,
        },
      };
    case DELETE_WORK_ORDER_FROM_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          docs: state.list.docs.filter((item) => item.id !== action.payload),
          totalDocs: state.list.totalDocs - 1,
        },
      };
    case DELETE_PALLET_FROM_WORK_ORDER_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          docs: state.list.docs.map((item) => {
            if (item.id === action.payload.workOrderId) {
              return {
                ...item,
                pallets: item.pallets.filter(
                  (pallet) => pallet.id !== action.payload.palletId
                ),
              };
            }
            return item;
          }),
        },
      };
    case SHOW_ADD_EDIT_WORK_ORDER_FORM:
      return {
        ...state,
        showAddEditForm: action.payload,
      };
    case SAVING_WORK_ORDER_DATA:
      return {
        ...state,
        savingData: action.payload,
      };
    case SHOW_IMPORT_WORK_ORDERS_FORM:
      return {
        ...state,
        showImportForm: action.payload,
      };
    case IMPORTING_WORK_ORDERS_DATA:
      return {
        ...state,
        importingData: action.payload,
      };
    case UPDATE_WORK_ORDER_IN_LIST:
      return {
        ...state,
        loading: false,
        list: {
          docs: state.list.docs.map((row) => {
            if (row.id === action.payload.id) {
              return action.payload;
            }
            return row;
          }),
        },
      };

    default:
      return state;
  }
}
