import moment from 'moment';

export const greetingText = () => {
  const now = moment();
  const currentHour = now.local().hour();
  if (currentHour < 12) return 'Good Morning';
  if (currentHour >= 12 && currentHour <= 17) return 'Good Afternoon';
  else return 'Good Evening';
};

export const getMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString(['en-US'], {
    month: 'long',
  });
};
