export const SET_LABOR_UNALLOCATED_LIST = 'SET_LABOR_UNALLOCATED_LIST';
export const LABOR_UNALLOCATED_LIST_LOADING = 'LABOR_UNALLOCATED_LIST_LOADING';
export const LABOR_UNALLOCATED_LIST_RESET = 'LABOR_UNALLOCATED_LIST_RESET';
export const SET_LABOR_UNALLOCATED_NAME_FILTER =
  'SET_LABOR_UNALLOCATED_NAME_FILTER';
export const UPDATE_LABOR_UNALLOCATED_IN_LIST =
  'UPDATE_LABOR_UNALLOCATED_IN_LIST';
export const SAVING_LABOR_UNALLOCATED_DATA = 'SAVING_LABOR_UNALLOCATED_DATA';
export const SHOW_ASSIGN_JOB_FORM = 'SHOW_ASSIGN_JOB_FORM';
export const TOGGLE_SELECT_LABOR_UNALLOCATED_IN_LIST =
  'TOGGLE_SELECT_LABOR_UNALLOCATED_IN_LIST';
export const SET_SELECT_ALL_LABOR_UNALLOCATED_IN_LIST =
  'SET_SELECT_ALL_LABOR_UNALLOCATED_IN_LIST';
