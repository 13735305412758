import {
  SET_PROFIT_PROFILE,
  PROFIT_PROFILE_LOADING,
  PROFIT_PROFILE_RESET,
  SET_PROFIT_PROFILES,
  PROFIT_PROFILE_LIST_LOADING,
  PROFIT_PROFILE_LIST_RESET,
  ADD_PROFIT_PROFILE_TO_LIST,
  UPDATE_PROFIT_PROFILE_IN_LIST,
  DELETE_PROFIT_PROFILE_FROM_LIST,
  SAVING_PROFIT_PROFILE_DATA,
  SET_PROFIT_PROFILE_FILTERS,
  RESET_PROFIT_PROFILE_FILTERS,
  SHOW_DUPLICATE_PROFIT_PROFILE_FORM,
} from './types';
const initialState = {
  item: {},
  list: {},
  filters: {
    machine: '',
    commodity: '',
    itemDescription: '',
  },
  loading: false,
  showDuplicateForm: false,
  savingData: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PROFIT_PROFILE:
      return {
        ...state,
        item: { ...action.payload },
      };
    case PROFIT_PROFILE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case PROFIT_PROFILE_RESET:
      return {
        ...state,
        item: {},
      };
    case SET_PROFIT_PROFILES:
      return {
        ...state,
        list: { ...action.payload },
      };
    case PROFIT_PROFILE_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case PROFIT_PROFILE_LIST_RESET:
      return {
        ...state,
        list: {},
      };
    case ADD_PROFIT_PROFILE_TO_LIST:
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          docs: [action.payload, ...state.list.docs],
          totalDocs: state.list.totalDocs + 1,
        },
      };
    case DELETE_PROFIT_PROFILE_FROM_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          docs: state.list.docs.filter((item) => item.id !== action.payload),
          totalDocs: state.list.totalDocs - 1,
        },
      };
    case SAVING_PROFIT_PROFILE_DATA:
      return {
        ...state,
        savingData: action.payload,
      };
    case SHOW_DUPLICATE_PROFIT_PROFILE_FORM:
      return {
        ...state,
        showDuplicateForm: action.payload,
      };
    case SET_PROFIT_PROFILE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case RESET_PROFIT_PROFILE_FILTERS:
      return {
        ...state,
        filters: {
          machine: '',
          commodity: '',
          itemDescription: '',
        },
      };
    case UPDATE_PROFIT_PROFILE_IN_LIST:
      return {
        ...state,
        loading: false,
        list: {
          docs: state.list.docs.map((row) => {
            if (row.id === action.payload.id) {
              return action.payload;
            }
            return row;
          }),
        },
      };

    default:
      return state;
  }
}
