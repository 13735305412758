import {
  SET_PRODUCTION_DASHBOARD_DATA,
  PRODUCTION_DASHBOARD_DATA_LOADING,
  PRODUCTION_DASHBOARD_DATA_RESET,
} from './types';
const initialState = {
  data: [],
  loading: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCTION_DASHBOARD_DATA:
      return {
        ...state,
        data: { ...action.payload },
      };
    case PRODUCTION_DASHBOARD_DATA_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case PRODUCTION_DASHBOARD_DATA_RESET:
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
}
