import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setMachineTypeList,
  resetMachineTypeList,
  setMachineTypeListLoading,
  addMachineTypeToList,
  deleteMachineTypeFromList,
  updateMachineTypeInList,
  savingMachineTypeData,
  showAddEditMachineTypeForm,
} from './actions';

export const loadMachineTypes = () => (dispatch, getState, api) => {
  dispatch(setMachineTypeListLoading(true));
  axios
    .get(`${api}/machine-types?limit=1000000`)
    .then((res) => {
      dispatch(resetMachineTypeList());
      dispatch(setMachineTypeList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineTypeListLoading(false));
    });
};

export const createMachineType = (data) => (dispatch, getState, api) => {
  dispatch(setMachineTypeListLoading(true));
  dispatch(savingMachineTypeData(true));
  axios
    .post(`${api}/machine-types`, data)
    .then((res) => {
      dispatch(addMachineTypeToList(res.data));
      dispatch(showAddEditMachineTypeForm(false));
      const notification = {
        message: '👍🏻 Machine type created successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineTypeListLoading(false));
      dispatch(savingMachineTypeData(false));
    });
};

export const updateMachineType =
  (id, data) => (dispatch, getState, api) => {
    dispatch(setMachineTypeListLoading(true));
    dispatch(savingMachineTypeData(true));
    axios
      .put(`${api}/machine-types/${id}`, data)
      .then((res) => {
        dispatch(updateMachineTypeInList(res.data));
        dispatch(showAddEditMachineTypeForm(false));
        const notification = {
          message: '👍🏻 Machine type updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setMachineTypeListLoading(false));
        dispatch(savingMachineTypeData(false));
      });
  };

export const deleteMachineType = (id) => (dispatch, getState, api) => {
  dispatch(setMachineTypeListLoading(true));
  axios
    .delete(`${api}/machine-types/${id}`)
    .then((res) => {
      dispatch(deleteMachineTypeFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Machine type deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setMachineTypeListLoading(false));
};

export const toggleMachineTypeStatus =
  (id, currentStatus) => (dispatch, getState, api) => {
    dispatch(setMachineTypeListLoading(true));
    axios
      .post(`${api}/machine-types/activate/${id}/${!currentStatus}`)
      .then((res) => {
        console.log(res);
        dispatch(loadMachineTypes());
        const notification = {
          message: '👍🏻 Machine type status updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setMachineTypeListLoading(false));
      });
  };
