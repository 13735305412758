import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getJobTypeList = createSelector(
  (state) => state.list_job_type.list,
  (jobTypeList) => {
    if (isEmpty(jobTypeList)) return [];
    return jobTypeList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
