export const SET_MACHINE_PROFILE = 'SET_MACHINE_PROFILE';
export const MACHINE_PROFILE_LOADING = 'MACHINE_PROFILE_LOADING';
export const MACHINE_PROFILE_RESET = 'MACHINE_PROFILE_RESET';
export const SET_MACHINE_PROFILES = 'SET_MACHINE_PROFILES';
export const MACHINE_PROFILE_LIST_LOADING = 'MACHINE_PROFILE_LIST_LOADING';
export const MACHINE_PROFILE_LIST_RESET = 'MACHINE_PROFILE_LIST_RESET';
export const ADD_MACHINE_PROFILE_TO_LIST = 'ADD_MACHINE_PROFILE_TO_LIST';
export const UPDATE_MACHINE_PROFILE_IN_LIST = 'UPDATE_MACHINE_PROFILE_IN_LIST';
export const DELETE_MACHINE_PROFILE_FROM_LIST =
  'DELETE_MACHINE_PROFILE_FROM_LIST';
export const SAVING_MACHINE_PROFILE_DATA = 'SAVING_MACHINE_PROFILE_DATA';
export const SET_MACHINE_PROFILE_FILTERS = 'SET_MACHINE_PROFILE_FILTERS';
export const RESET_MACHINE_PROFILE_FILTERS = 'RESET_MACHINE_PROFILE_FILTERS';
export const SHOW_DUPLICATE_MACHINE_PROFILE_FORM =
  'SHOW_DUPLICATE_MACHINE_PROFILE_FORM';
