import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getSetupChecklistItemList = createSelector(
  (state) => state.setup_checklist_items.list.docs,
  (setupChecklistItemList) => {
    if (isEmpty(setupChecklistItemList)) return [];
    return setupChecklistItemList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
