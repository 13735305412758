import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppBar,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  InputLabel,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import PalletInIcon from '../UI/icons/PalletInIcon';
import PalletOutIcon from '../UI/icons/PalletOutIcon';
import CloseIcon from '@mui/icons-material/Close';
import isEmpty from 'is-empty';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  lot: Yup.string().required('Required'),
  item: Yup.string().required('Required'),
  cases: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  flow: Yup.string().required('Required'),
});

const ValidationSchemaByScale = Yup.object().shape({
  code: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  lot: Yup.string().required('Required'),
  item: Yup.string().required('Required'),
  cases: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  flow: Yup.string().required('Required'),
  scaleReading: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  caseWeight: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
});

const ValidationSchemaByPallet = Yup.object().shape({
  code: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  lot: Yup.string().required('Required'),
  item: Yup.string().required('Required'),
  cases: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  flow: Yup.string().required('Required'),
  fullPalletWeight: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  palletTypeWeight: Yup.number()
    .typeError('Must be a number')
    .required('Required'),
  caseWeight: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
});

const ValidationSchemaByCases = Yup.object().shape({
  code: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  lot: Yup.string().required('Required'),
  item: Yup.string().required('Required'),
  cases: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  flow: Yup.string().required('Required'),
  sampleFullCaseWeight1: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  sampleFullCaseWeight2: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  sampleFullCaseWeight3: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  sampleCaseWeight1: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  sampleCaseWeight2: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  sampleCaseWeight3: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
});

const PalletAddScanForm = ({
  pallet,
  productionLine,
  machines,
  palletTypes,
  handleOnSubmit,
  handleOnCancel,
}) => {
  const [validationSchema, setValidationSchema] = useState(ValidationSchema);
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const watchCases = watch('cases');
  const watchWeightType = watch('weightType');
  const watchScaleReading = watch('scaleReading');
  const watchCaseWeight = watch('caseWeight');
  const watchPalletMiscWeight = watch('palletMiscWeight');
  const watchFullPalletWeight = watch('fullPalletWeight');
  const watchPalletTypeWeight = watch('palletTypeWeight');

  const watchSampleFullCaseWeight1 = watch('sampleFullCaseWeight1');
  const watchSampleFullCaseWeight2 = watch('sampleFullCaseWeight2');
  const watchSampleFullCaseWeight3 = watch('sampleFullCaseWeight3');
  const watchSampleCaseWeight1 = watch('sampleCaseWeight1');
  const watchSampleCaseWeight2 = watch('sampleCaseWeight2');
  const watchSampleCaseWeight3 = watch('sampleCaseWeight3');

  const totalSampleWeight1 =
    parseFloat(watchSampleFullCaseWeight1) - parseFloat(watchSampleCaseWeight1);
  const totalSampleWeight2 =
    parseFloat(watchSampleFullCaseWeight2) - parseFloat(watchSampleCaseWeight2);
  const totalSampleWeight3 =
    parseFloat(watchSampleFullCaseWeight3) - parseFloat(watchSampleCaseWeight3);

  const averageCaseWeight = parseFloat(
    (totalSampleWeight1 + totalSampleWeight2 + totalSampleWeight3) / 3
  ).toFixed(2);

  const totalWeightByCases = parseFloat(averageCaseWeight * watchCases).toFixed(
    2
  );

  const totalCaseWeight =
    parseFloat(watchCaseWeight) * parseFloat(watchCases) >= 0
      ? parseFloat(watchCaseWeight) * parseFloat(watchCases)
      : 0;

  const totalWeightByScale =
    parseFloat(watchScaleReading).toFixed(2) -
    parseFloat(totalCaseWeight).toFixed(2) -
    parseFloat(watchPalletMiscWeight).toFixed(2);

  const totalWeightByPallet =
    parseFloat(watchFullPalletWeight).toFixed(2) -
    parseFloat(totalCaseWeight).toFixed(2) -
    parseFloat(watchPalletMiscWeight).toFixed(2);

  useEffect(() => {
    if (isEmpty(pallet?.weight)) {
      setValue('weightType', 'NONE');
    } else {
      setValue('weightType', pallet.weight.weightType);
    }

    return () => {};
  }, [setValue, pallet]);

  useEffect(() => {
    switch (watchWeightType) {
      case 'NONE':
        setValidationSchema(ValidationSchema);
        break;
      case 'BY_SCALE':
        setValidationSchema(ValidationSchemaByScale);
        break;
      case 'BY_PALLET':
        setValidationSchema(ValidationSchemaByPallet);
        break;
      case 'BY_CASES':
        setValidationSchema(ValidationSchemaByCases);
        break;
      default:
        break;
    }
  }, [watchWeightType]);

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const palletData = {
          code: data.code,
          description: data.description,
          lot: data.lot,
          item: data.item,
          cases: data.cases,
          flow: data.flow,
        };
        let weightData = {};
        switch (watchWeightType) {
          case 'BY_SCALE':
            weightData = {
              totalWeight: totalWeightByScale,
            };
            break;
          case 'BY_PALLET':
            weightData = {
              fullWeight: totalWeightByPallet,
              binWeight: parseFloat(data.fullPalletWeight),
              caseBinWeight: totalCaseWeight,
              cases: palletData.cases,
              strapWeight: parseFloat(data.palletMiscWeight),
            };
            break;
          case 'BY_CASES':
            weightData = {
              cases: 3,
              samples: [
                {
                  fullWeight: parseFloat(data.sampleFullCaseWeight1),
                  binWeight: parseFloat(data.sampleCaseWeight1),
                },
                {
                  fullWeight: parseFloat(data.sampleFullCaseWeight2),
                  binWeight: parseFloat(data.sampleCaseWeight2),
                },
                {
                  fullWeight: parseFloat(data.sampleFullCaseWeight2),
                  binWeight: parseFloat(data.sampleCaseWeight2),
                },
              ],
            };
            break;
          default:
            break;
        }

        handleOnSubmit(palletData, weightData, data.weightType);
      })}
    >
      <AppBar color='primary' elevation={0} sx={{ position: 'relative' }}>
        <Toolbar variant='dense'>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleOnCancel}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            ePack - Pallet Scan & Add
          </Typography>
        </Toolbar>
      </AppBar>
      <Box my={0.5} mx={1.5} mt={1} display='flex' flexDirection='column'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <Controller
              name='code'
              control={control}
              defaultValue={isEmpty(pallet) ? '' : pallet.code}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Code'
                  fullWidth
                  size='small'
                  disabled={!isEmpty(pallet)}
                  error={!!errors?.code}
                  helperText={errors?.code?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name='workorder'
              control={control}
              defaultValue={
                isEmpty(productionLine.workOrder)
                  ? ''
                  : productionLine.workOrder.code
              }
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Work Order'
                  disabled
                  fullWidth
                  size='small'
                  error={!!errors?.workorder}
                  helperText={errors?.workorder?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name='commodity'
              control={control}
              defaultValue={
                isEmpty(productionLine.workOrder)
                  ? ''
                  : productionLine.workOrder.commodity.name
              }
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Commodity'
                  fullWidth
                  size='small'
                  disabled
                  error={!!errors?.commodity}
                  helperText={errors?.commodity?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name='description'
              control={control}
              defaultValue={isEmpty(pallet) ? '' : pallet.description}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Description'
                  fullWidth
                  size='small'
                  disabled={!isEmpty(pallet)}
                  error={!!errors?.description}
                  helperText={errors?.description?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name='lot'
              control={control}
              defaultValue={isEmpty(pallet) ? '' : pallet.lot}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Lot'
                  fullWidth
                  size='small'
                  disabled={!isEmpty(pallet)}
                  error={!!errors?.lot}
                  helperText={errors?.lot?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name='gtin'
              control={control}
              defaultValue={isEmpty(pallet?.gtin) ? '' : pallet.gtin}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='GTIN'
                  fullWidth
                  size='small'
                  disabled={!isEmpty(pallet)}
                  error={!!errors?.gtin}
                  helperText={errors?.gtin?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name='item'
              control={control}
              defaultValue={isEmpty(pallet) ? '' : pallet.item}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Item'
                  fullWidth
                  size='small'
                  disabled={!isEmpty(pallet)}
                  error={!!errors?.item}
                  helperText={errors?.item?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name='cases'
              control={control}
              defaultValue={isEmpty(pallet) ? 0 : pallet.cases}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Cases'
                  fullWidth
                  size='small'
                  type='number'
                  disabled={!isEmpty(pallet)}
                  error={!!errors?.cases}
                  helperText={errors?.cases?.message}
                />
              )}
            />
          </Grid>
          <Grid container item xs={12} justifyContent='center'>
            <Grid item xs={12} sm={3}>
              <FormControl sx={{ minWidth: 250 }}>
                <InputLabel id='machine-select-label'>Machine</InputLabel>
                <Controller
                  name='machineId'
                  control={control}
                  defaultValue={
                    isEmpty(productionLine.machine)
                      ? ''
                      : productionLine.machine.id
                  }
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId='machine-select-label'
                      label={'Machine'}
                      fullWidth
                      size='small'
                      disabled={!isEmpty(pallet)}
                      error={!!errors?.machineId}
                    >
                      {machines.map((machine) => (
                        <MenuItem key={machine.id} value={machine.id}>
                          {machine.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <FormHelperText>{errors?.machineId?.message}</FormHelperText>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent='center'
            style={{ marginTop: 10 }}
          >
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                defaultValue={isEmpty(pallet?.flow) ? 'IN' : pallet.flow}
                name='flow'
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value='IN'
                      control={
                        <Radio
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 28,
                            },
                          }}
                          disabled={Boolean(pallet?.id)}
                        />
                      }
                      sx={{ flexGrow: 1 }}
                      label={
                        <PalletInIcon
                          sx={{
                            color: 'success.main',
                            height: '48px',
                            width: '48px',
                          }}
                        />
                      }
                    />

                    <FormControlLabel
                      value='OUT'
                      control={
                        <Radio
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 28,
                            },
                          }}
                          disabled={Boolean(pallet?.id)}
                        />
                      }
                      label={
                        <PalletOutIcon
                          sx={{
                            color: 'error.main',
                            height: '48px',
                            width: '48px',
                          }}
                        />
                      }
                    />
                  </RadioGroup>
                )}
              />
              <FormHelperText>{errors?.flow?.message}</FormHelperText>
            </Grid>
          </Grid>
        </Grid>
        <Divider light />
        <Box display='flex' my={1} justifyContent='center'>
          <Typography variant='h6'>
            Weight Unit of Measurement (UMO) in pounds (lbs)
          </Typography>
        </Box>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name='weightType'
              defaultValue={
                isEmpty(pallet?.weight) ? 'NONE' : pallet.weight.weightType
              }
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  row
                  sx={{ display: 'flex', justifyContent: 'space-evenly' }}
                >
                  <FormControlLabel
                    value='NONE'
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 28,
                          },
                        }}
                      />
                    }
                    label={<Typography variant='h5'>None</Typography>}
                  />
                  <FormControlLabel
                    value='BY_SCALE'
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 28,
                          },
                        }}
                      />
                    }
                    label={<Typography variant='h5'>By Scale</Typography>}
                  />
                  <FormControlLabel
                    value='BY_PALLET'
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 28,
                          },
                        }}
                      />
                    }
                    label={<Typography variant='h5'>By Pallet</Typography>}
                  />
                  <FormControlLabel
                    value='BY_CASES'
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 28,
                          },
                        }}
                      />
                    }
                    label={<Typography variant='h5'>By Cases</Typography>}
                  />
                </RadioGroup>
              )}
            />
            <FormHelperText>{errors?.flow?.message}</FormHelperText>
          </Grid>
        </Grid>
        <Divider light />
        <Grid container spacing={1} sx={{ marginTop: 1 }}>
          {watchWeightType === 'BY_SCALE' && (
            <>
              <Grid item xs={8}></Grid>
              <Grid item xs={2}>
                <Controller
                  name='scaleReading'
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Scale Reading'
                      type='number'
                      size='small'
                      error={!!errors?.scaleReading}
                      helperText={errors?.scaleReading?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h5'>{`= ${watchScaleReading}`}</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Controller
                  name='caseWeight'
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Case Weight'
                      type='number'
                      size='small'
                      error={!!errors?.caseWeight}
                      helperText={errors?.caseWeight?.message}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography variant='h6'>{`* Number of Cases (${watchCases})`}</Typography>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h5'>{`= ${
                  parseFloat(watchCaseWeight) * parseFloat(watchCases)
                }`}</Typography>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingRight: 2,
                }}
              >
                <Typography variant='h6'>
                  {'Are corner boards & straps on the pallet?'}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Controller
                  control={control}
                  defaultValue={0}
                  name='palletMiscWeight'
                  render={({ field }) => (
                    <RadioGroup {...field} row>
                      <FormControlLabel
                        value={5}
                        control={<Radio />}
                        label='Yes'
                      />

                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label='No'
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>

              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h5'>{`= ${watchPalletMiscWeight}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider light />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='h6'>{'Product Weight -'}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h6'>{'Total Case Weight -'}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h6'>{'Board & Straps'}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h6'>{'Estimated Weight'}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h5'>{watchScaleReading}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h5'>{totalCaseWeight}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h5'>{watchPalletMiscWeight}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                {totalWeightByScale >= 0 && (
                  <Typography variant='h5'>
                    {totalWeightByScale.toString()}
                  </Typography>
                )}
              </Grid>
            </>
          )}
          {watchWeightType === 'BY_PALLET' && (
            <>
              <Grid
                item
                xs={4}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Controller
                  name='fullPalletWeight'
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Full Pallet Weight'
                      type='number'
                      size='small'
                      error={!!errors?.fullPalletWeight}
                      helperText={errors?.fullPalletWeight?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h6'>{`-`}</Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ minWidth: 250 }}>
                  <InputLabel id='pallet-type-select-label'>
                    Pallet Type
                  </InputLabel>
                  <Controller
                    name='palletTypeWeight'
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId='pallet-type-select-label'
                        label={'Pallet Type'}
                        fullWidth
                        size='small'
                        error={!!errors?.palletTypeWeight}
                      >
                        {palletTypes.map((palletType) => (
                          <MenuItem
                            key={palletType.id}
                            value={palletType.weight}
                            dense
                          >
                            {palletType.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <FormHelperText>
                  {errors?.palletTypeWeight?.message}
                </FormHelperText>
              </Grid>
              <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h6'>{`(${watchPalletTypeWeight})`}</Typography>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h5'>{`= ${
                  parseFloat(watchFullPalletWeight) -
                  parseFloat(watchPalletTypeWeight)
                }`}</Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Controller
                  name='caseWeight'
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Case Weight'
                      type='number'
                      size='small'
                      error={!!errors?.caseWeight}
                      helperText={errors?.caseWeight?.message}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography variant='h6'>{`* Number of Cases (${watchCases})`}</Typography>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h5'>{`= ${
                  parseFloat(watchCaseWeight) * parseFloat(watchCases)
                }`}</Typography>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingRight: 2,
                }}
              >
                <Typography variant='h6'>
                  {'Are corner boards & straps on the pallet?'}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Controller
                  control={control}
                  defaultValue={0}
                  name='palletMiscWeight'
                  render={({ field }) => (
                    <RadioGroup {...field} row>
                      <FormControlLabel
                        value={5}
                        control={<Radio />}
                        label='Yes'
                      />

                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label='No'
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>

              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h5'>{`= ${watchPalletMiscWeight}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider light />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='h6'>{'Product Weight -'}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h6'>{'Total Case Weight -'}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h6'>{'Board & Straps'}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h6'>{'Estimated Weight'}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h5'>{watchFullPalletWeight}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h5'>{totalCaseWeight}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h5'>{watchPalletMiscWeight}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                {watchFullPalletWeight >= 0 && (
                  <Typography variant='h5'>
                    {totalWeightByPallet.toString()}
                  </Typography>
                )}
              </Grid>
            </>
          )}
          {watchWeightType === 'BY_CASES' && (
            <>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography variant='h5'>{'Sample # 1'}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name={'sampleFullCaseWeight1'}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Full Case Weight'
                      type='number'
                      size='small'
                      error={!!errors?.sampleFullCaseWeight1}
                      helperText={errors?.sampleFullCaseWeight1?.message}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='h5'>{`-`}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name={'sampleCaseWeight1'}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Case Weight'
                      type='number'
                      size='small'
                      error={!!errors?.sampleCaseWeight1}
                      helperText={errors?.sampleCaseWeight1?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                {totalSampleWeight1 >= 0 && (
                  <Typography variant='h5'>
                    {` = ${parseFloat(totalSampleWeight1).toFixed(2)}`}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography variant='h5'>{'Sample # 2'}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name={'sampleFullCaseWeight2'}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Full Case Weight'
                      type='number'
                      size='small'
                      error={!!errors?.sampleFullCaseWeight2}
                      helperText={errors?.sampleFullCaseWeight2?.message}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='h5'>{`-`}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name={'sampleCaseWeight2'}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Case Weight'
                      type='number'
                      size='small'
                      error={!!errors?.sampleCaseWeight2}
                      helperText={errors?.sampleCaseWeight2?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                {totalSampleWeight2 >= 0 && (
                  <Typography variant='h5'>
                    {` = ${parseFloat(totalSampleWeight2).toFixed(2)}`}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography variant='h5'>{'Sample # 3'}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name={'sampleFullCaseWeight3'}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Full Case Weight'
                      type='number'
                      size='small'
                      error={!!errors?.sampleFullCaseWeight3}
                      helperText={errors?.sampleFullCaseWeight3?.message}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='h5'>{`-`}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name={'sampleCaseWeight3'}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Case Weight'
                      type='number'
                      size='small'
                      error={!!errors?.sampleCaseWeight3}
                      helperText={errors?.sampleCaseWeight3?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                {totalSampleWeight3 >= 0 && (
                  <Typography variant='h5'>
                    {` = ${parseFloat(totalSampleWeight3).toFixed(2)}`}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider light />
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h6'>{`${averageCaseWeight} Average Case Weight`}</Typography>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h6'>{'*'}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h6'>{`${watchCases} Number of Cases`}</Typography>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant='h6'>{'='}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                {totalWeightByCases >= 0 && (
                  <Typography variant='h5'>
                    {totalWeightByCases.toString()}
                  </Typography>
                )}
              </Grid>
            </>
          )}
        </Grid>
        <Box component='div' mx={1} my={0.5} display='flex'>
          <Box my={1.5} flexGrow={1}>
            <Button
              variant='contained'
              color='secondary'
              size='large'
              type='submit'
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Box>
          <Box my={1.5}>
            <Button
              variant='outlined'
              size='large'
              startIcon={<UndoIcon />}
              onClick={handleOnCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default PalletAddScanForm;
