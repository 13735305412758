import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PalletIcon = (props) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 330 330'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M95 0C92.2386 0 90 2.23858 90 5V115C90 117.761 92.2386 120 95 120H235C237.761 120 240 117.761 240 115V5C240 2.23858 237.761 0 235 0H177.5V40H152.5V0H95Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 151C2.23858 151 0 153.239 0 156V266C0 268.761 2.23858 271 5 271H145C147.761 271 150 268.761 150 266V156C150 153.239 147.761 151 145 151H87.5V191H62.5V151H5Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M185 151C182.239 151 180 153.239 180 156V266C180 268.761 182.239 271 185 271H325C327.761 271 330 268.761 330 266V156C330 153.239 327.761 151 325 151H267.5V191H242.5V151H185Z'
      />
      <rect y='304' width='330' height='25' rx='5' />
    </SvgIcon>
  );
};

export default PalletIcon;
