import axios from 'axios';
import isEmpty from 'is-empty';
import { addNotification } from '../../system/notifications/actions';
import {
  setEmployees,
  resetEmployeeList,
  setEmployeeListLoading,
  addEmployeeToList,
  updateEmployeeinList,
  deleteEmployeeFromList,
  savingEmployeeData,
  showAddEditEmployeeForm,
} from './actions';

export const loadEmployees = () => (dispatch, getState, api) => {
  dispatch(setEmployeeListLoading(true));

  axios
    .get(`${api}/employees?limit=1000000`)
    .then((res) => {
      dispatch(resetEmployeeList());
      dispatch(setEmployees(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setEmployeeListLoading(false));
    });
};

export const createEmployee = (data) => (dispatch, getState, api) => {
  dispatch(setEmployeeListLoading(true));
  dispatch(savingEmployeeData(true));
  if (!isEmpty(data.file)) {
    const formData = new FormData();
    formData.append('file', data.file);
    axios
      .post(`${api}/employees`, {
        firstName: data.firstName,
        lastName: data.lastName,
        employerId: data.employerId,
      })
      .then((res) => {
        axios
          .post(`${api}/employees/photo/${res.data.id}`, formData)
          .then((res) => {
            dispatch(addEmployeeToList(res.data));
            dispatch(showAddEditEmployeeForm(false));
            const notification = {
              message: '👍🏻 Employee created successfully',
              options: {
                variant: 'success',
              },
            };
            dispatch(addNotification(notification));
          })
          .catch((err) => {
            console.log(err);
            const notification = {
              message: '😱 Something went wrong, please try again',
              options: {
                variant: 'error',
              },
            };
            dispatch(addNotification(notification));
          });
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setEmployeeListLoading(false));
        dispatch(savingEmployeeData(false));
      });
  } else {
    axios
      .post(`${api}/employees`, {
        firstName: data.firstName,
        lastName: data.lastName,
        employerId: data.employerId,
      })
      .then((res) => {
        dispatch(addEmployeeToList(res.data));
        dispatch(showAddEditEmployeeForm(false));
        const notification = {
          message: '👍🏻 Employee created successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setEmployeeListLoading(false));
        dispatch(savingEmployeeData(false));
      });
  }
};

export const updateEmployee = (data) => (dispatch, getState, api) => {
  dispatch(setEmployeeListLoading(true));
  dispatch(savingEmployeeData(true));
  if (!isEmpty(data.file)) {
    const formData = new FormData();
    formData.append('file', data.file);
    axios
      .put(`${api}/employees/${data.id}`, {
        firstName: data.firstName,
        lastName: data.lastName,
        employerId: data.employerId,
      })
      .then((res) => {
        axios
          .post(`${api}/employees/photo/${data.id}`, formData)
          .then((res) => {
            dispatch(updateEmployeeinList(res.data));
            dispatch(showAddEditEmployeeForm(false));
            const notification = {
              message: '👍🏻 Employee updated successfully',
              options: {
                variant: 'success',
              },
            };
            dispatch(addNotification(notification));
          })
          .catch((err) => {
            console.log(err);
            const notification = {
              message: '😱 Something went wrong, please try again',
              options: {
                variant: 'error',
              },
            };
            dispatch(addNotification(notification));
          });
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setEmployeeListLoading(false));
        dispatch(savingEmployeeData(false));
      });
  } else {
    axios
      .put(`${api}/employees/${data.id}`, {
        firstName: data.firstName,
        lastName: data.lastName,
        employerId: data.employerId,
      })
      .then((res) => {
        dispatch(updateEmployeeinList(res.data));
        dispatch(showAddEditEmployeeForm(false));
        const notification = {
          message: '👍🏻 Employee updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setEmployeeListLoading(false));
        dispatch(savingEmployeeData(false));
      });
  }
};

export const deleteEmployee = (id) => (dispatch, getState, api) => {
  dispatch(setEmployeeListLoading(true));
  axios
    .delete(`${api}/employees/${id}`)
    .then((res) => {
      dispatch(deleteEmployeeFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Employee deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setEmployeeListLoading(false));
};
