import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './types';

const initialState = {
  notifications: [],
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.payload,
          },
        ],
      };

    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.payload
        ),
      };

    default:
      return state;
  }
};
