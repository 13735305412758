import React from 'react';
import { Menu, MenuItem } from '@mui/material';

const TenantMenu = ({
  companies,
  anchorEl,
  open,
  handleClose,
  handleSelectTenant,
}) => {
  return (
    <Menu
      id='company-menu-appbar'
      disabled
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      getcontentanchorel={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {companies.map((company) => {
        return (
          <MenuItem
            key={company.id}
            onClick={() => handleSelectTenant(company)}
          >
            {company.name}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default TenantMenu;
