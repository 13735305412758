import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';
import isEmpty from 'is-empty';
import ManualProfileActivitiesTable from './ManualProfileActivitiesTable';
import ManualProfileActivityForm from './ManualProfileActivityForm';
import ConfirmationDialog from '../UI/ConfirmationDialog';

const ValidationSchema = Yup.object().shape({
  volumePallets: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  volumeCasesPerPallet: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  volumeUnitsPerCase: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  volumeSubUnitsPerUnit: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  productiveSetupTimeMinutes: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  productiveHoursPerShift: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  productiveTotalHoursPerShift: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
});

const ManualProfileForm = ({
  commodityId,
  itemDescription,
  onSubmit,
  savingData,
  editMode,
  manualProfile,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
    defaultValues: {
      volumePallets: editMode ? manualProfile.volumePallets : '',
      volumeCasesPerPallet: editMode ? manualProfile.volumeCasesPerPallet : '',
      volumeUnitsPerCase: editMode ? manualProfile.volumeUnitsPerCase : '',
      volumeSubUnitsPerUnit: editMode
        ? manualProfile.volumeSubUnitsPerUnit
        : '',
      productiveSetupTimeMinutes: editMode
        ? manualProfile.productiveSetupTimeMinutes
        : '',
      productiveHoursPerShift: editMode
        ? manualProfile.productiveHoursPerShift
        : '',
      productiveTotalHoursPerShift: editMode
        ? manualProfile.productiveHoursPerShift
        : '',
    },
  });
  const [activity, setActivity] = useState({});
  const [activities, setActivities] = useState(
    editMode ? manualProfile.activities : []
  );
  const [editModeActivity, setEditModeActivity] = useState(false);
  const [openAddActivity, setOpenAddEditActivity] = useState(false);
  const [openDeleteActivity, setOpenDeleteActivity] = useState(false);
  let navigate = useNavigate();

  const watchVolumePallets = watch('volumePallets');
  const watchVolumeCasesPerPallet = watch('volumeCasesPerPallet');
  const watchVolumeUnitsPerCase = watch('volumeUnitsPerCase');
  const watchVolumeSubUnitsPerUnit = watch('volumeSubUnitsPerUnit');

  const watchProductiveHoursPerShift = watch('productiveHoursPerShift');
  const watchProductiveTotalHoursPerShift = watch(
    'productiveTotalHoursPerShift'
  );

  const productiveTotal =
    watchProductiveHoursPerShift - watchProductiveTotalHoursPerShift / 60;

  const volumeTotal =
    watchVolumePallets *
    watchVolumeCasesPerPallet *
    watchVolumeUnitsPerCase *
    watchVolumeSubUnitsPerUnit;

  useEffect(() => {
    if (editMode === false) {
      setValue('commodityId', commodityId);
      isEmpty(itemDescription)
        ? setValue('itemDescription', 'NONE')
        : setValue('itemDescription', itemDescription);
    }
  }, [setValue, commodityId, itemDescription, editMode]);

  const handleClickAddActivity = useCallback(() => {
    setEditModeActivity(false);
    setOpenAddEditActivity(true);
  }, [setOpenAddEditActivity]);

  const onAddEditActivity = useCallback(
    (data) => {
      if (editModeActivity) {
        setActivities((activities) =>
          activities.map((activity) => {
            if (activity.id === data.id) {
              return { ...data };
            }
            return activity;
          })
        );
      } else {
        setActivities([...activities, data]);
      }
      setOpenAddEditActivity(false);
      setEditModeActivity(false);
    },
    [activities, setActivities, setEditModeActivity, editModeActivity]
  );

  const handleClickDelete = useCallback(
    (activity) => {
      setActivity(activity);
      setOpenDeleteActivity(true);
    },
    [setActivity]
  );

  const onDeleteActivity = useCallback(() => {
    setActivities([...activities.filter((item) => item.id !== activity.id)]);
    setOpenDeleteActivity(false);
    setActivity({});
  }, [activities, setActivities, activity, setOpenDeleteActivity, setActivity]);

  const handleClickEdit = useCallback(
    (activity) => {
      setActivity(activity);
      setEditModeActivity(true);
      setOpenAddEditActivity(true);
    },
    [setActivity, setEditModeActivity, setOpenAddEditActivity]
  );

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          let activitiesList = activities.map((item) => {
            return {
              activity: item.activity,
              allowance: item.allowance,
              yield: item.yield,
            };
          });
          onSubmit({ ...data, activities: activitiesList });
        })}
      >
        <Paper elevation={0}>
          <Box>
            <Grid container spacing={2} mt={1}>
              {editMode ? (
                <>
                  <Grid item xs={4}>
                    <TextField
                      size='small'
                      label='Commodity'
                      fullWidth
                      value={manualProfile.commodity.name}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size='small'
                      label='Item Description'
                      fullWidth
                      value={manualProfile.itemDescription}
                      disabled
                    />
                  </Grid>
                </>
              ) : null}

              <Grid item xs={6}>
                <Card variant='outlined'>
                  <CardHeader
                    title='Volume of product to be produced'
                    sx={{
                      paddingBottom: 2,
                      paddingTop: 1,
                    }}
                  />
                  <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          name='volumePallets'
                          control={control}
                          defaultValue={
                            editMode ? manualProfile.volumePallets : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size='small'
                              label='Number Pallets'
                              fullWidth
                              type='number'
                              error={!!errors?.volumePallets}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name='volumeCasesPerPallet'
                          control={control}
                          defaultValue={
                            editMode ? manualProfile.volumeCasesPerPallet : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size='small'
                              label='Cases Per Pallet'
                              fullWidth
                              type='number'
                              error={!!errors?.volumeCasesPerPallet}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name='volumeUnitsPerCase'
                          control={control}
                          defaultValue={
                            editMode ? manualProfile.volumeUnitsPerCase : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size='small'
                              label='Units Per Case (Ex. Bags Per Case)'
                              fullWidth
                              type='number'
                              error={!!errors?.volumeUnitsPerCase}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name='volumeSubUnitsPerUnit'
                          control={control}
                          defaultValue={
                            editMode ? manualProfile.volumeSubUnitsPerUnit : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size='small'
                              label='Sub-Units Per Unit (Ex. 12 Apples Per Bag)'
                              fullWidth
                              type='number'
                              error={!!errors?.volumeSubUnitsPerUnit}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          size='small'
                          label='Total Units or Sub-Units'
                          value={parseFloat(volumeTotal).toFixed(0)}
                          fullWidth
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card variant='outlined'>
                  <CardContent sx={{}}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          name='productiveSetupTimeMinutes'
                          control={control}
                          defaultValue={
                            editMode
                              ? manualProfile.productiveSetupTimeMinutes
                              : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size='small'
                              label='How long is Setup Time for this Work Order (Mins)?'
                              fullWidth
                              type='number'
                              error={!!errors?.productiveSetupTimeMinutes}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name='productiveHoursPerShift'
                          control={control}
                          defaultValue={
                            editMode
                              ? manualProfile.productiveHoursPerShift
                              : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size='small'
                              label='How many hours are there in a Shift excluding Breaks?'
                              fullWidth
                              type='number'
                              error={!!errors?.productiveHoursPerShift}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name='productiveTotalHoursPerShift'
                          control={control}
                          defaultValue={
                            editMode
                              ? manualProfile.productiveTotalHoursPerShift
                              : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size='small'
                              label='How many total hours in a Shift?'
                              fullWidth
                              type='number'
                              error={!!errors?.productiveTotalHoursPerShift}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          size='small'
                          label='Total Productive Hours'
                          value={parseFloat(productiveTotal).toFixed(1)}
                          fullWidth
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Box my={2.5}>
            <Card variant='outlined'>
              <CardHeader
                title='Activities'
                sx={{
                  paddingBottom: 2,
                  paddingTop: 1,
                }}
                action={
                  <Button
                    variant='outlined'
                    startIcon={<AddIcon />}
                    onClick={handleClickAddActivity}
                  >
                    Add Activity
                  </Button>
                }
              />
              <ManualProfileActivitiesTable
                rows={activities}
                handleClickDelete={handleClickDelete}
                handleClickEdit={handleClickEdit}
              />
            </Card>
          </Box>
          <Box mx={1} my={2.5} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
                startIcon={<SaveIcon />}
              >
                {savingData ? 'Saving...' : 'Save'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UndoIcon />}
                onClick={() => {
                  reset();
                  navigate('/eplan/manual_profiles');
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
      <Dialog
        open={openAddActivity}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenAddEditActivity(false);
            setEditModeActivity(false);
            setActivity({});
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <ManualProfileActivityForm
            editMode={editModeActivity}
            activity={activity}
            handleOnCancel={() => {
              setOpenAddEditActivity(false);
              setEditModeActivity(false);
              setActivity({});
            }}
            handleOnSubmit={onAddEditActivity}
          />
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        open={openDeleteActivity}
        handleNo={() => {
          setOpenDeleteActivity(false);
        }}
        handleYes={onDeleteActivity}
        title='ePlan - Delete Activity'
        YesText={'Yes, Delete'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to delete activity: ${activity?.description}?`}
        </Typography>
      </ConfirmationDialog>
    </>
  );
};

export default ManualProfileForm;
