import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setContact,
  resetContact,
  resetContactList,
  showAddEditContactForm,
} from '../../redux/company/contacts/actions';
import {
  loadContacts,
  createContact,
  updateContact,
  deleteContact,
} from '../../redux/company/contacts/services';
import { getContactList } from '../../redux/company/contacts/selectors';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Paper,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import Loading from '../../components/UI/Loading';
import ContactForm from '../../components/company/ContactForm';
import Empty from '../../components/UI/Empty';
import ContactsTable from '../../components/company/ContactsTable';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
const isEmpty = require('is-empty');

const Contacts = () => {
  const dispatch = useDispatch();
  const contactsState = useSelector((state) => state.contacts);
  const contactState = useSelector((state) => state.contacts.item);
  const ContactsList = useSelector(getContactList);
  const [editMode, setEditMode] = useState(false);
  const [openRemoveContactDialog, setOpenRemoveContactDialog] = useState(false);

  useEffect(() => {
    dispatch(loadContacts());
    return () => {
      dispatch(resetContactList());
    };
  }, [dispatch]);

  const handleClickAdd = () => {
    setEditMode(false);
    dispatch(showAddEditContactForm(true));
  };

  const handleClickEdit = (machine) => {
    setEditMode(true);
    dispatch(setContact(machine));
    dispatch(showAddEditContactForm(true));
  };

  const onSubmitAddEdit = (data) => {
    if (editMode) {
      dispatch(updateContact(contactState.id, data));
    } else {
      dispatch(createContact(data));
    }
  };

  const handleRefresh = () => {
    dispatch(loadContacts());
  };

  const handleClickRemove = (contact) => {
    dispatch(setContact(contact));
    setOpenRemoveContactDialog(true);
  };

  const onRemoveContact = () => {
    dispatch(deleteContact(contactState.id));
    dispatch(resetContact());
    setOpenRemoveContactDialog(false);
  };

  return (
    <>
      <Loading loading={contactsState.loading} />
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box display='flex' alignContent='center'>
            <IconButton aria-label='reload' onClick={handleRefresh}>
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              Contacts
            </Typography>
          </Box>
          <Tooltip title='Add Contact'>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<AddIcon />}
              sx={{ marginRight: '0.5rem' }}
              onClick={handleClickAdd}
            >
              Add
            </Button>
          </Tooltip>
        </Box>
        <Box sx={{ width: '100%' }}>
          {!isEmpty(contactsState.list) && (
            <ContactsTable
              rows={ContactsList}
              handleClickEdit={handleClickEdit}
              handleClickRemove={handleClickRemove}
            />
          )}
          {isEmpty(contactsState.list) && !contactsState.loading && (
            <Empty description='There are no contacts to show' />
          )}
        </Box>
      </Paper>
      <ConfirmationDialog
        open={openRemoveContactDialog}
        handleNo={() => {
          setOpenRemoveContactDialog(false);
        }}
        handleYes={onRemoveContact}
        title='ePack - Remove Contact'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to remove contact: ${contactState?.firstName} ${contactState?.lastName}?`}
        </Typography>
      </ConfirmationDialog>

      <Dialog
        open={contactsState.showAddEditForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setEditMode(false);
            dispatch(resetContact());
            dispatch(showAddEditContactForm(false));
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <ContactForm
            editMode={editMode}
            contact={contactState}
            handleOnCancel={() => {
              setEditMode(false);
              dispatch(resetContact());
              dispatch(showAddEditContactForm(false));
            }}
            handleOnSubmit={onSubmitAddEdit}
            savingData={contactsState.savingData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Contacts;
