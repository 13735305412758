import React, { useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import EmployeeStatusRing from './EmployeeStatusRing';
import EmployeePhoto from './EmployeePhoto';
import PanToolIcon from '@mui/icons-material/PanTool';
import BadgeIcon from '@mui/icons-material/Badge';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BuildIcon from '@mui/icons-material/Build';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import WarningIcon from '@mui/icons-material/Warning';
import truncateString from '../../utils/truncateString';
import { orange, green, grey } from '@mui/material/colors';

const LaborLogTile = ({
  laborLog,
  handleClickStartLaborLog,
  handleClickStopLaborLog,
  handleClickMoveLaborLog,
  handleClickChangePosition,
  handleClickWalkOff,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMoreMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" mt={0.5} flexDirection="column">
      <Box display="flex" flexDirection="row">
        <Box ml={1}>
          <Box display="flex" justifyContent="space-between">
            <Box mt={2} sx={{ width: '20%' }}>
              {laborLog.isIdle ? (
                <EmployeeStatusRing color={orange['400']} size="small">
                  <EmployeePhoto photo={laborLog.photo} size="small" />
                </EmployeeStatusRing>
              ) : (
                <EmployeeStatusRing color={green['500']} size="small">
                  <EmployeePhoto photo={laborLog.photo} size="small" />
                </EmployeeStatusRing>
              )}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              ml={2.5}
              sx={{ width: '50%' }}
            >
              <Grid container spacing={0.8}>
                <Grid item xs={2}>
                  <BadgeIcon
                    sx={{ color: grey['500'], width: '18px', height: '18px' }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body2">
                    {truncateString(
                      laborLog.lastName + ' ' + laborLog.firstName,
                      {
                        length: 20,
                        ending: '...',
                      }
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <PanToolIcon
                    sx={{ color: grey['500'], width: '18px', height: '18px' }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body2">
                    {truncateString(laborLog.employeePosition.name, {
                      length: 20,
                      ending: '...',
                    })}
                  </Typography>
                </Grid>
                {laborLog.jobType && (
                  <>
                    <Grid item xs={2}>
                      <BuildIcon
                        sx={{
                          color: grey['500'],
                          width: '18px',
                          height: '18px',
                        }}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body2">
                        {truncateString(laborLog.jobType.name, {
                          length: 20,
                          ending: '...',
                        })}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{ width: '20%' }}
            >
              <Tooltip title="More options">
                <IconButton onClick={handleMoreMenuClick}>
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Move">
                <IconButton
                  onClick={() => handleClickMoveLaborLog(laborLog)}
                  sx={{ color: 'primary.main' }}
                >
                  <ShuffleIcon />
                </IconButton>
              </Tooltip>
              {laborLog.isIdle ? (
                <Tooltip title="Start">
                  <IconButton
                    onClick={() => handleClickStartLaborLog(laborLog.id)}
                    sx={{ color: 'success.main' }}
                  >
                    <PlayArrowIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Stop">
                  <IconButton
                    onClick={() => handleClickStopLaborLog(laborLog.id)}
                    sx={{ color: 'error.main' }}
                  >
                    <StopIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Menu
                id="more-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openMenu}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  onClick={() => {
                    handleClickChangePosition(laborLog);
                    handleCloseMenu();
                  }}
                >
                  <ListItemIcon>
                    <CompareArrowsIcon sx={{ color: 'primary.main' }} />
                  </ListItemIcon>
                  <ListItemText>Change Position</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    handleClickWalkOff(laborLog);
                    handleCloseMenu();
                  }}
                >
                  <ListItemIcon>
                    <WarningIcon sx={{ color: 'error.main' }} />
                  </ListItemIcon>
                  <ListItemText>Walk Off</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LaborLogTile;
