import {
  SET_COMMODITY_LIST,
  COMMODITY_LIST_LOADING,
  COMMODITY_LIST_RESET,
} from './types';

export const setCommodityList = (list) => {
  return {
    type: SET_COMMODITY_LIST,
    payload: list,
  };
};

export const resetCommodityList = () => {
  return {
    type: COMMODITY_LIST_RESET,
  };
};

export const setCommodityListLoading = (payload) => {
  return {
    type: COMMODITY_LIST_LOADING,
    payload,
  };
};
