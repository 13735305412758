import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { resetProductionDashboardData } from '../../redux/epack/dashboard/actions';
import {
  loadProductionDashboardData,
  loadProductionDashboardPrivateData,
} from '../../redux/epack/dashboard/services';
import {
  getProductionDashboardData,
  selectNumberOfAvailableProductionLines,
  selectNumberOfUnavailableProductionLines,
  selectNumberOfInProgressProductionLines,
  selectNumberOfSetupProductionLines,
  selectNumberOfSanitationProductionLines,
  selectNumberOfCasesInProduction,
  selectNumberOfCasesOutProduction,
  selectNumberOfPalletsInProduction,
  selectNumberOfPalletsOutProduction,
} from '../../redux/epack/dashboard/selectors';
import { Box, Container, Paper } from '@mui/material';
import Empty from '../../components/UI/Empty';
import ProductionDashboardTileList from '../../components/epack/ProductionDashboardTileList';
import DashboardNumericWidgetWithIconSmall from '../../components/UI/DashboardNumericWidgetWithIconSmall';
import { grey, green, red, deepPurple, teal, cyan } from '@mui/material/colors';
import LoadingTransparent from '../../components/UI/LoadingTransparent';
const isEmpty = require('is-empty');

const ProductionDashboard = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const [firstLoad, setFirstLoad] = useState(true);
  const productionDashboardState = useSelector(
    (state) => state.production_dashboard
  );
  const ProductionDashboardData = useSelector(getProductionDashboardData);
  const NumberOfUnavailableProductionLines = useSelector(
    selectNumberOfUnavailableProductionLines
  );
  const NumberOfAvailableProductionLines = useSelector(
    selectNumberOfAvailableProductionLines
  );
  const NumberOfInProgressProductionLines = useSelector(
    selectNumberOfInProgressProductionLines
  );
  const NumberOfSetupProductionLines = useSelector(
    selectNumberOfSetupProductionLines
  );
  const NumberOfSanitationProductionLines = useSelector(
    selectNumberOfSanitationProductionLines
  );
  const NumberOfCasesInProduction = useSelector(
    selectNumberOfCasesInProduction
  );
  const NumberOfCasesOutProduction = useSelector(
    selectNumberOfCasesOutProduction
  );
  const NumberOfPalletsInProduction = useSelector(
    selectNumberOfPalletsInProduction
  );
  const NumberOfPalletsOutProduction = useSelector(
    selectNumberOfPalletsOutProduction
  );

  const getDashboardData = useCallback(() => {
    if (isEmpty(token)) {
      dispatch(loadProductionDashboardData());
    } else {
      dispatch(loadProductionDashboardPrivateData(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    getDashboardData();

    // refresh data every 2 minutes
    const interval = setInterval(getDashboardData, 120000);
    return () => {
      dispatch(resetProductionDashboardData());
      clearInterval(interval);
    };
  }, [dispatch, getDashboardData]);

  useEffect(() => {
    setTimeout(() => {
      setFirstLoad(false);
    }, 1000);
    return () => {};
  }, [setFirstLoad]);

  return (
    <Container disableGutters sx={{ marginTop: '48px' }} maxWidth='xl'>
      <Paper elevation={0}>
        {firstLoad ? (
          <LoadingTransparent loading={productionDashboardState.loading} />
        ) : null}
        <Box sx={{ width: '100%', padding: 2 }}>
          {!isEmpty(productionDashboardState.data.production) && (
            <>
              <Box display='flex' justifyContent='space-between' mb={2}>
                <Box sx={{ width: '12%' }}>
                  <DashboardNumericWidgetWithIconSmall
                    title='Idle'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${grey[600]} 10%, ${grey[700]} 100%);`}
                    numericValue={NumberOfAvailableProductionLines}
                    numericValueColor={grey['100']}
                  />
                </Box>
                <Box sx={{ width: '12%' }}>
                  <DashboardNumericWidgetWithIconSmall
                    title='Unavailable'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${red[600]} 10%, ${red[700]} 100%);`}
                    numericValue={
                      NumberOfUnavailableProductionLines +
                      NumberOfSanitationProductionLines
                    }
                    numericValueColor={grey['100']}
                  />
                </Box>
                <Box sx={{ width: '12%' }}>
                  <DashboardNumericWidgetWithIconSmall
                    title='In Progress'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${green[600]} 10%, ${green[700]} 100%);`}
                    numericValue={NumberOfInProgressProductionLines}
                    numericValueColor={grey['100']}
                  />
                </Box>
                <Box sx={{ width: '12%' }}>
                  <DashboardNumericWidgetWithIconSmall
                    title='Setup'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${deepPurple[600]} 10%, ${deepPurple[700]} 100%);`}
                    numericValue={NumberOfSetupProductionLines}
                    numericValueColor={grey['100']}
                  />
                </Box>
                <Box sx={{ width: '12%' }}>
                  <DashboardNumericWidgetWithIconSmall
                    title='Pallets In'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${teal[800]} 10%, ${teal[900]} 100%);`}
                    numericValue={NumberOfPalletsInProduction}
                    numericValueColor={grey['100']}
                  />
                </Box>
                <Box sx={{ width: '12%' }}>
                  <DashboardNumericWidgetWithIconSmall
                    title='Pallets Out'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${teal[800]} 10%, ${teal[900]} 100%);`}
                    numericValue={NumberOfPalletsOutProduction}
                    numericValueColor={grey['100']}
                  />
                </Box>
                <Box sx={{ width: '12%' }}>
                  <DashboardNumericWidgetWithIconSmall
                    title='Cases In'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${cyan[600]} 10%, ${cyan[700]} 100%);`}
                    numericValue={NumberOfCasesInProduction}
                    numericValueColor={grey['100']}
                  />
                </Box>
                <Box sx={{ width: '12%' }}>
                  <DashboardNumericWidgetWithIconSmall
                    title='Cases Out'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${cyan[600]} 10%, ${cyan[700]} 100%);`}
                    numericValue={NumberOfCasesOutProduction}
                    numericValueColor={grey['100']}
                  />
                </Box>
              </Box>
              <ProductionDashboardTileList
                workOrders={ProductionDashboardData}
              />
            </>
          )}
          {isEmpty(productionDashboardState.data.production) &&
            !productionDashboardState.loading && (
              <Empty description='No data to show' />
            )}
        </Box>
      </Paper>
    </Container>
  );
};

export default ProductionDashboard;
