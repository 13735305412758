import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getMachineViewList = createSelector(
  (state) => state.labor_machine_view.list,
  (machineViewList) => {
    if (isEmpty(machineViewList)) return [];
    return machineViewList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
