import {
  SET_LABOR_LOG_LIST,
  LABOR_LOG_LIST_LOADING,
  LABOR_LOG_LIST_RESET,
  SET_LABOR_LOG,
  LABOR_LOG_LOADING,
  LABOR_LOG_RESET,
  UPDATE_LABOR_LOG_IN_LIST,
  SHOW_ADJUT_LABOR_LOG_FORM,
  SAVING_LABOR_LOG_DATA,
} from './types';
const initialState = {
  list: {},
  loading: false,
  item: {},
  showAdjustForm: false,
  savingData: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LABOR_LOG_LIST:
      return {
        ...state,
        list: { ...action.payload },
      };
    case LABOR_LOG_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LABOR_LOG_LIST_RESET:
      return {
        ...state,
        list: {},
      };
    case SET_LABOR_LOG:
      return {
        ...state,
        item: { ...action.payload },
      };
    case LABOR_LOG_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LABOR_LOG_RESET:
      return {
        ...state,
        item: {},
      };
    case UPDATE_LABOR_LOG_IN_LIST:
      return {
        ...state,
        loading: false,
        list: {
          docs: state.list.docs.map((row) => {
            if (row.id === action.payload.id) {
              return action.payload;
            }
            return row;
          }),
        },
      };
    case SHOW_ADJUT_LABOR_LOG_FORM:
      return {
        ...state,
        showAdjustForm: action.payload,
      };
    case SAVING_LABOR_LOG_DATA:
      return {
        ...state,
        savingData: action.payload,
      };
    default:
      return state;
  }
}
