import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadEmployees,
  createEmployee,
  updateEmployee,
  deleteEmployee,
} from '../../redux/etime/employees/services';
import { loadEmployerList } from '../../redux/list/employers/services';
import { resetEmployerList } from '../../redux/list/employers/actions';
import { getEmployerList } from '../../redux/list/employers/selectors';
import {
  resetEmployeeList,
  setEmployeeNameFilter,
  showAddEditEmployeeForm,
  setEmployee,
} from '../../redux/etime/employees/actions';
import {
  selectLastnameUniqueInitials,
  selectFilteredEmployee,
} from '../../redux/etime/employees/selectors';

import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Paper,
  OutlinedInput,
  Typography,
  Tooltip,
  IconButton,
  InputAdornment,
  InputLabel,
} from '@mui/material';
import Empty from '../../components/UI/Empty';
import LastnameNavigator from '../../components/etime/LastnameNavigator';
import EmployeesTileList from '../../components/etime/EmployeesTileList';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EmployeeForm from '../../components/etime/EmployeeForm';
import EmployeeDetails from '../../components/etime/EmployeeDetails';
import Loading from '../../components/UI/Loading';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
const isEmpty = require('is-empty');

const Employees = () => {
  const dispatch = useDispatch();
  const [employeeToRemove, setEmployeeToRemove] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const employeesState = useSelector((state) => state.employees);
  const employersState = useSelector((state) => state.employers);
  const LastNameUniqueInitials = useSelector(selectLastnameUniqueInitials);
  const FilteredEmployee = useSelector(selectFilteredEmployee);
  const EmployersList = useSelector(getEmployerList);

  useEffect(() => {
    dispatch(loadEmployees());
    dispatch(loadEmployerList());
    return () => {
      dispatch(resetEmployeeList());
      dispatch(resetEmployerList());
    };
  }, [dispatch]);

  const handleRefresh = () => {
    dispatch(loadEmployees());
  };

  const handleClickAdd = () => {
    dispatch(showAddEditEmployeeForm(true));
  };

  const handleClickUpdate = (employee) => {
    dispatch(setEmployee(employee));
    setEditMode(true);
    dispatch(showAddEditEmployeeForm(true));
  };

  const onChangeNameFilter = (event) => {
    dispatch(setEmployeeNameFilter(event.target.value));
  };

  const handleClearNameFilter = () => {
    dispatch(setEmployeeNameFilter(''));
  };

  const handleCloseAddUpdateFormDialog = () => {
    dispatch(showAddEditEmployeeForm(false));
    setEditMode(false);
  };

  const onSubmitAddUpdate = (values) => {
    if (editMode) {
      dispatch(updateEmployee(values));
    } else {
      dispatch(createEmployee(values));
    }
    setEditMode(false);
  };

  const handleClickRemove = (employee) => {
    setEmployeeToRemove(employee);
    setOpenRemoveDialog(true);
  };

  const onRemoveEmployee = () => {
    dispatch(deleteEmployee(employeeToRemove.id));
    setOpenRemoveDialog(false);
  };

  const handleClickLastnameNavigatorItem = (event, inital) => {
    document.getElementById(inital).scrollIntoView();
  };

  return (
    <>
      <Loading loading={employeesState.loading} />
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1}>
          <Box display='flex' alignContent='center'>
            <IconButton aria-label='reload' onClick={handleRefresh}>
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={1.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              eTime - Employees
            </Typography>
          </Box>
          <Box my={1.5}>
            <FormControl
              sx={{ marginRight: '0.5rem' }}
              variant='outlined'
              size='small'
            >
              <InputLabel htmlFor='search-by-name'>Search by name</InputLabel>

              <OutlinedInput
                id='search-by-name'
                value={employeesState.filter.name}
                onChange={onChangeNameFilter}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton onClick={handleClearNameFilter} edge='end'>
                      <HighlightOffIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label='Search by name'
              />
            </FormControl>
            <Tooltip title='Add Employee'>
              <Button
                variant='contained'
                color='secondary'
                startIcon={<AddIcon />}
                sx={{ marginRight: '0.5rem' }}
                onClick={handleClickAdd}
              >
                Add
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <Divider variant='middle' light />
        <Box my={1}>
          <Box ml={1} my={0} display='flex' flexDirection='row'>
            <Box>
              {!isEmpty(LastNameUniqueInitials) && (
                <LastnameNavigator
                  lastNameInitials={LastNameUniqueInitials}
                  handleClick={handleClickLastnameNavigatorItem}
                />
              )}
            </Box>
            <Box flexGrow={1} px={1} ml={5}>
              {!isEmpty(employeesState.list) && (
                <EmployeesTileList
                  employees={FilteredEmployee}
                  handleClickUpdate={handleClickUpdate}
                  handleClickRemove={handleClickRemove}
                />
              )}
              {isEmpty(employeesState.list) && !employeesState.loading && (
                <Empty description='No employees to show' />
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
      <Dialog
        open={employeesState.showAddEditForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseAddUpdateFormDialog();
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <EmployeeForm
            editMode={editMode}
            employee={employeesState.view.record}
            employers={EmployersList}
            loadingEmployers={employersState.loading}
            savingData={employeesState.savingData}
            handleOnCancel={handleCloseAddUpdateFormDialog}
            handleOnSubmit={onSubmitAddUpdate}
          />
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        open={openRemoveDialog}
        handleNo={() => {
          setOpenRemoveDialog(false);
        }}
        handleYes={onRemoveEmployee}
        title='eTime - Remove'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <EmployeeDetails employee={employeeToRemove} />
      </ConfirmationDialog>
    </>
  );
};

export default Employees;
