import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PersonIcon = (props) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 1200 1200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M719.57,272.035c18.461,0,35.451,3.565,50.975,10.698
	c46.078,23.193,73.285,67.272,73.631,116.424V737.73c-8.643,53.824-70.25,39.188-71.742,0V418.037
	c-5.49-18.231-40.693-10.672-41.535,0v733.785c-8.951,74.928-105.773,52.934-108.242-1.258c0-149.778,0-299.558,0-449.334
	c-6.191-28.982-46.936-15.813-47.829,1.259c1.728,149.353,1.259,298.715,1.259,448.075c-9.656,74.543-106.007,55.47-108.243,1.258
	l-1.258-733.785c-5.643-17.838-38.263-10.996-39.019,0V737.73c-8.644,53.824-70.25,39.188-71.742,0V399.157
	c0.871-47.056,18.117-94.197,59.156-116.424c13.426-7.133,29.788-10.699,49.087-10.699
	C549.235,272.035,634.402,272.035,719.57,272.035L719.57,272.035z M718.301,118.277c0,65.323-52.955,118.278-118.278,118.278
	c-65.322,0-118.277-52.955-118.277-118.278C481.745,52.955,534.7,0,600.021,0C665.346,0,718.301,52.955,718.301,118.277z'
      />
    </SvgIcon>
  );
};

export default PersonIcon;
