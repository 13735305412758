import React, { useState } from 'react';
import { Box, Checkbox, IconButton, Popover, Typography } from '@mui/material';
import EmployeePhoto from './EmployeePhoto';
import EmployeeStatusRing from './EmployeeStatusRing';
import moment from 'moment';
import isEmpty from 'is-empty';
import truncateString from '../../utils/truncateString';

import InfoIcon from '@mui/icons-material/Info';
import grey from '@mui/material/colors/grey';

const EmployeeTile = ({
  flags,
  photo,
  active,
  firstName,
  lastName,
  latestPosition,
  employer,
  hasCheckBox,
  handleClickOnCheckBox,
  selected,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const flag = flags?.length > 0 ? true : false;

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex">
        <Box sx={{ width: '20%' }}>
          {hasCheckBox && (
            <Checkbox checked={selected} onClick={handleClickOnCheckBox} />
          )}
        </Box>
        <Box sx={{ width: '80%' }}>
          <Box
            sx={{
              marginTop: '15px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box>
              {(() => {
                if (flag) {
                  return (
                    <EmployeeStatusRing color="red">
                      <EmployeePhoto photo={photo} />
                    </EmployeeStatusRing>
                  );
                } else if (!active) {
                  return (
                    <EmployeeStatusRing color={grey['400']}>
                      <EmployeePhoto photo={photo} />
                    </EmployeeStatusRing>
                  );
                } else {
                  return (
                    <EmployeeStatusRing>
                      <EmployeePhoto photo={photo} />
                    </EmployeeStatusRing>
                  );
                }
              })()}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: '20%',
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          {flag && (
            <Box>
              <IconButton
                size="small"
                edge="start"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{ color: 'red' }}
              >
                <InfoIcon />
              </IconButton>
              <Popover
                id="flag-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                elevation={0}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Box sx={{ padding: '15px' }}>
                  <Typography sx={{ p: 1 }}>
                    {'Flag Reason: ' + flags[flags.length - 1].reason}
                  </Typography>
                  <Typography sx={{ p: 1 }}>
                    {'Flagged By: ' +
                      flags[flags.length - 1].by.firstName +
                      ' ' +
                      flags[flags.length - 1].by.lastName}
                  </Typography>
                  <Typography sx={{ p: 1 }}>
                    {' On: ' +
                      moment(flags[flags.length - 1].flaggedOn).format(
                        'MM/DD/YYYY hh:mm a'
                      )}
                  </Typography>
                </Box>
              </Popover>
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" flexDirection="column" mt={0.5}>
        <Typography variant="body1">
          {truncateString(lastName + ' ' + firstName, {
            length: 25,
            ending: '...',
          })}
        </Typography>
        {isEmpty(latestPosition) ? (
          <Typography variant="caption">
            {truncateString(employer.name)}
          </Typography>
        ) : (
          <Typography variant="caption" component="div">
            {truncateString(employer.name + ' - ' + latestPosition)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default EmployeeTile;
