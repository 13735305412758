import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getProductionDashboardData = createSelector(
  (state) => state.production_dashboard.data.production,
  (list) => {
    if (isEmpty(list)) return [];
    list = list.sort((a, b) => (a.workOrder < b.workOrder ? -1 : 1));
    const inProgress = list.filter((item) => item.status === 'IN_PROGRESS');
    const available = list.filter((item) => item.status === 'AVAILABLE');
    const setup = list.filter((item) => item.status === 'SETUP');
    const sanitation = list.filter((item) => item.status === 'SANITATION');
    const unavailable = list.filter((item) => item.status === 'NOT_AVAILABLE');
    return [
      ...inProgress,
      ...available,
      ...setup,
      ...sanitation,
      ...unavailable,
    ];
  }
);

export const selectTotalNumberOfProductionLines = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.length;
  }
);

export const selectNumberOfAvailableProductionLines = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter((item) => item.status === 'AVAILABLE').length;
  }
);

export const selectNumberOfUnavailableProductionLines = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter((item) => item.status === 'NOT_AVAILABLE').length;
  }
);

export const selectNumberOfInProgressProductionLines = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter((item) => item.status === 'IN_PROGRESS').length;
  }
);

export const selectNumberOfSetupProductionLines = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter((item) => item.status === 'SETUP').length;
  }
);

export const selectNumberOfSanitationProductionLines = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter((item) => item.status === 'SANITATION').length;
  }
);

export const selectNumberOfCasesInProduction = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.reduce((acc, val) => {
      return acc + parseFloat(val.casesIn);
    }, 0);
  }
);

export const selectNumberOfCasesOutProduction = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.reduce((acc, val) => {
      return acc + parseFloat(val.casesOut);
    }, 0);
  }
);
export const selectNumberOfPalletsInProduction = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.reduce((acc, val) => {
      return acc + parseFloat(val.palletsIn);
    }, 0);
  }
);

export const selectNumberOfPalletsOutProduction = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.reduce((acc, val) => {
      return acc + parseFloat(val.palletsOut);
    }, 0);
  }
);

export const getProductionDashboardWorkOrdersData = createSelector(
  (state) => state.production_dashboard.data.workOrders,
  (list) => {
    if (isEmpty(list)) return [];
    return list.sort((a, b) => (a.workOrder < b.workOrder ? -1 : 1));
  }
);

export const selectTotalNumberOfWorkOrders = createSelector(
  getProductionDashboardWorkOrdersData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.length;
  }
);

export const selectNumberOfWorkOrdersInProduction = createSelector(
  getProductionDashboardWorkOrdersData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter((item) => item.inProduction === true).length;
  }
);

export const selectNumberOfCommoditiesInWorkOrders = createSelector(
  getProductionDashboardWorkOrdersData,
  (list) => {
    if (isEmpty(list)) return 0;
    return [...new Set(list.map((item) => item.commodity))].length;
  }
);

export const selectNumberOfPalletsInWorkOrders = createSelector(
  getProductionDashboardWorkOrdersData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.reduce((acc, val) => {
      return acc + parseFloat(val.totalPallets);
    }, 0);
  }
);
export const selectNumberOfCasesInWorkOrders = createSelector(
  getProductionDashboardWorkOrdersData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.reduce((acc, val) => {
      return acc + parseFloat(val.totalCases);
    }, 0);
  }
);
