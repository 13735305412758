import {
  SET_LINES_LIST,
  LINES_LIST_LOADING,
  LINES_LIST_RESET,
  SET_LINE_ITEM,
  LINE_LOADING,
  LINE_RESET,
} from './types';
const initialState = {
  list: {},
  loading: false,
  line: {},
  lineLoading: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LINES_LIST:
      return {
        ...state,
        list: { ...action.payload },
      };
    case LINES_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LINES_LIST_RESET:
      return {
        ...state,
        list: {},
      };
    case SET_LINE_ITEM:
      return {
        ...state,
        line: { ...action.payload },
      };
    case LINE_LOADING:
      return {
        ...state,
        lineLoading: action.payload,
      };
    case LINE_RESET:
      return {
        ...state,
        line: {},
      };
    default:
      return state;
  }
}
