import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PalletInIcon = (props) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 330 330'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ marginTop: 0, marginRight: 0.5 }}
      {...props}
    >
      <rect y='304' width='330' height='25' rx='5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 190C2.23858 190 0 192.239 0 195V285C0 287.761 2.23858 290 5 290H140C142.761 290 145 287.761 145 285V195C145 192.239 142.761 190 140 190H84.5833V223.333H60.4167V190H5Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M189 190C186.239 190 184 192.239 184 195V285C184 287.761 186.239 290 189 290H324C326.761 290 329 287.761 329 285V195C329 192.239 326.761 190 324 190H268.583V223.333H244.417V190H189Z'
      />
      <rect
        x='191'
        y='90.8889'
        width='56'
        height='88.8889'
        rx='7'
        transform='rotate(-180 191 90.8889)'
      />
      <path d='M168.647 186.415C165.875 189.423 161.125 189.423 158.353 186.415L69.1018 89.5773C64.9679 85.0921 68.1494 77.8333 74.2491 77.8333L252.751 77.8333C258.851 77.8333 262.032 85.0921 257.898 89.5773L168.647 186.415Z' />
    </SvgIcon>
  );
};

export default PalletInIcon;
