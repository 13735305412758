import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

const getFilters = (state) => state.companies.filters;

export const getCompanyList = createSelector(
  (state) => state.companies.list.docs,
  getFilters,
  (companiesList, filters) => {
    if (isEmpty(companiesList)) return [];
    let filteredList = companiesList;
    if (!isEmpty(filters.name)) {
      filteredList = filteredList.filter((item) =>
        item.name.toLowerCase().includes(filters.name.toLowerCase())
      );
    }
    if (filters.isActive === true) {
      filteredList = filteredList.filter((item) => item.active === true);
    }
    if (filters.needsApproval === true) {
      filteredList = filteredList.filter((item) => item.needsApproval === true);
    }
    return filteredList;
  }
);
