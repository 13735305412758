export const SET_PROFIT_PROFILE = 'SET_PROFIT_PROFILE';
export const PROFIT_PROFILE_LOADING = 'PROFIT_PROFILE_LOADING';
export const PROFIT_PROFILE_RESET = 'PROFIT_PROFILE_RESET';
export const SET_PROFIT_PROFILES = 'SET_PROFIT_PROFILES';
export const PROFIT_PROFILE_LIST_LOADING = 'PROFIT_PROFILE_LIST_LOADING';
export const PROFIT_PROFILE_LIST_RESET = 'PROFIT_PROFILE_LIST_RESET';
export const ADD_PROFIT_PROFILE_TO_LIST = 'ADD_PROFIT_PROFILE_TO_LIST';
export const UPDATE_PROFIT_PROFILE_IN_LIST = 'UPDATE_PROFIT_PROFILE_IN_LIST';
export const DELETE_PROFIT_PROFILE_FROM_LIST =
  'DELETE_PROFIT_PROFILE_FROM_LIST';
export const SAVING_PROFIT_PROFILE_DATA = 'SAVING_PROFIT_PROFILE_DATA';
export const SET_PROFIT_PROFILE_FILTERS = 'SET_PROFIT_PROFILE_FILTERS';
export const RESET_PROFIT_PROFILE_FILTERS = 'RESET_PROFIT_PROFILE_FILTERS';
export const SHOW_DUPLICATE_PROFIT_PROFILE_FORM =
  'SHOW_DUPLICATE_PROFIT_PROFILE_FORM';
