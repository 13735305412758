import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  resetWIPView,
  resetTableViewList,
} from '../../redux/epack/production/actions';
import {
  loadWIPView,
  loadTableViewList,
} from '../../redux/epack/production/services';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Empty from '../../components/UI/Empty';
import WIPWidgetWithIcon from '../../components/UI/WIPWidgetWithIcon';
import PalletIcon from '../../components/UI/icons/PalletIcon';
import WeightIcon from '../../components/UI/icons/WeightIcon';
import PalletInIcon from '../../components/UI/icons/PalletInIcon';
import PalletOutIcon from '../../components/UI/icons/PalletOutIcon';
import {
  grey,
  green,
  red,
  orange,
  blue,
  deepPurple,
} from '@mui/material/colors';
import LoadingTransparent from '../../components/UI/LoadingTransparent';
import moment from 'moment';
const isEmpty = require('is-empty');

ChartJS.register(ArcElement, Tooltip, Legend, Title, CategoryScale);

const averageChartsOptions = {
  responsive: true,
  circumference: 180,
  rotation: -90,
  cutout: 35,
  plugins: {
    legend: {
      display: true,
      fullSize: false,
      align: 'start',
      labels: {
        boxWidth: 10,
        fontSize: 20,
        fontColor: 'gray',
        usePointStyle: true,
      },
    },
    tooltip: {
      enabled: false,
    },
    title: {
      display: false,
    },
  },
};

const WIPDashboard = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const productionState = useSelector((state) => state.production);

  const yieldPercentage = parseFloat(
    productionState.wip_view.yieldCases * 100
  ).toFixed(1);

  const morYield = parseFloat(productionState.wip_view.MOR_Yield * 100).toFixed(
    1
  );

  const availability = parseFloat(
    productionState.wip_view.availability * 100
  ).toFixed(1);

  const morAvailability = parseFloat(
    productionState.wip_view.MOR_ManufacturingAvailability * 100
  ).toFixed(1);

  const throughput = parseFloat(productionState.wip_view.rateCases).toFixed(1);

  const morThroughput = parseFloat(
    productionState.wip_view.MOR_Throughput
  ).toFixed(1);

  const getDashboardData = useCallback(() => {
    if (isEmpty(id)) {
      navigate('/epack/panel');
    } else {
      dispatch(loadWIPView(id));
    }
  }, [dispatch, id, navigate]);

  useEffect(() => {
    getDashboardData();
    dispatch(loadTableViewList());
    // refresh data every 2 minutes
    const interval = setInterval(getDashboardData, 60000);
    return () => {
      dispatch(resetWIPView());
      dispatch(resetTableViewList());
      clearInterval(interval);
    };
  }, [dispatch, getDashboardData]);

  const timeElapsed = moment
    .utc(
      moment(new Date()).diff(moment(productionState.wip_view.startDateTime))
    )
    .format('HH:mm:ss');

  return (
    <Container disableGutters sx={{ marginTop: '48px' }} maxWidth='xl'>
      <Paper elevation={0}>
        <LoadingTransparent loading={productionState.wip_view_loading} />

        {isEmpty(productionState.wip_view) ? null : (
          <Box>
            {/*TIME VALUES*/}
            <Box display='flex' justifyContent='space-around' my={3}>
              <Box>
                <Typography variant='h6'>
                  Start Time:{' '}
                  {moment(productionState.wip_view.startDateTime).format(
                    'hh:mm A'
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography variant='h6'>
                  End Time:{' '}
                  {productionState.wip_view.status === 'IN_PROGRESS'
                    ? 'In Progress'
                    : moment(productionState.wip_view.startDateTime)
                        .add(
                          parseFloat(
                            productionState.wip_view.durationTotal
                          ).toFixed(0),
                          'minutes'
                        )
                        .format('hh:mm A')}
                </Typography>
              </Box>
              <Box>
                <Typography variant='h6'>
                  Time Elapsed: {timeElapsed}
                </Typography>
              </Box>
              <Box>
                <Typography variant='h6'>
                  Cases Ordered: {productionState.wip_view.casesOrdered}
                </Typography>
              </Box>
            </Box>
            {/*GRAPHS*/}
            <Box display='flex' justifyContent='center' my={3}>
              <Card variant='outlined'>
                <CardContent
                  sx={{
                    padding: 0,
                    '&:last-child': {
                      paddingBottom: 0.8,
                    },
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant='h6'
                        sx={{
                          paddingTop: 1,
                          paddingBottom: 1,
                          paddingLeft: 2,
                          backgroundImage: getStatusColor(
                            productionState.wip_view.status
                          ),
                          color: 'white',
                        }}
                      >
                        Machine
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '200px',
                      }}
                    >
                      <Box
                        sx={{
                          height: '180px',
                          width: '180px',
                          position: 'relative',
                        }}
                      >
                        <Typography
                          variant='h5'
                          sx={{
                            position: 'absolute',
                            top: '90%',
                            left: '50%',
                            transform: 'translate(-50%, -110%)',
                          }}
                        >{`${yieldPercentage}%`}</Typography>
                        <Doughnut
                          data={{
                            labels: [
                              `Yield: ${yieldPercentage}%`,
                              `MOR: ${morYield}%`,
                            ],
                            datasets: [
                              {
                                data: [yieldPercentage, 100 - yieldPercentage],
                                backgroundColor: ['#D62728', '#bdbdbd'],
                                borderColor: ['#fff', '#fff'],
                              },
                              {
                                data: [morYield, 100 - morYield],
                                backgroundColor: ['#7F7F7F', '#bdbdbd'],
                                borderColor: ['#fff', '#fff'],
                              },
                            ],
                          }}
                          options={averageChartsOptions}
                          style={{
                            transform: 'translateY(-7px)',
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '140px',
                      }}
                    >
                      <Box
                        sx={{
                          height: '180px',
                          width: '180px',
                          position: 'relative',
                        }}
                      >
                        <Typography
                          variant='h5'
                          sx={{
                            position: 'absolute',
                            top: '90%',
                            left: '50%',
                            transform: 'translate(-50%, -110%)',
                          }}
                        >{`${availability}%`}</Typography>
                        <Doughnut
                          data={{
                            labels: [
                              `Availability: ${availability}%`,
                              `MOR: ${morAvailability}%`,
                            ],
                            datasets: [
                              {
                                data: [availability, 100 - availability],
                                backgroundColor: ['#2CA02C', '#bdbdbd'],
                                borderColor: ['#fff', '#fff'],
                              },
                              {
                                data: [morAvailability, 100 - morAvailability],
                                backgroundColor: ['#7F7F7F', '#bdbdbd'],
                                borderColor: ['#fff', '#fff'],
                              },
                            ],
                          }}
                          options={averageChartsOptions}
                          style={{
                            transform: 'translateY(-7px)',
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '140px',
                      }}
                    >
                      <Box
                        sx={{
                          height: '180px',
                          width: '180px',
                          position: 'relative',
                        }}
                      >
                        <Typography
                          variant='h6'
                          sx={{
                            position: 'absolute',
                            top: '90%',
                            left: '50%',
                            transform: 'translate(-50%, -110%)',
                          }}
                        >{`${throughput}`}</Typography>
                        <Doughnut
                          data={{
                            labels: [
                              `Throughput: ${throughput}`,
                              `MOR: ${morThroughput}`,
                            ],
                            datasets: [
                              {
                                data: [throughput, 100 - throughput],
                                backgroundColor: ['#5e35b1', '#bdbdbd'],
                                borderColor: ['#fff', '#fff'],
                              },
                              {
                                data: [morThroughput, 100 - morThroughput],
                                backgroundColor: ['#7F7F7F', '#bdbdbd'],
                                borderColor: ['#fff', '#fff'],
                              },
                            ],
                          }}
                          options={averageChartsOptions}
                          style={{
                            transform: 'translateY(-7px)',
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
            {/*STATS BOXES*/}
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='center'
              m={2}
              sx={{}}
            >
              {/*BULK*/}
              <Box mx={1} sx={{ width: '30%', maxWidth: '300px' }}>
                <Card variant='outlined'>
                  <Typography
                    variant='h6'
                    sx={{
                      paddingTop: 1,
                      paddingBottom: 1,
                      paddingLeft: 2,
                      backgroundImage: `linear-gradient( 135deg, ${deepPurple[600]} 10%, ${deepPurple[700]} 100%);`,
                      color: 'white',
                    }}
                  >
                    Bulk
                  </Typography>
                  <CardContent
                    sx={{
                      padding: 1,
                      '&:last-child': {
                        paddingBottom: 0.8,
                      },
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <WIPWidgetWithIcon
                          title='Pallets'
                          numericValue={productionState.wip_view.palletsIn}
                        >
                          <PalletInIcon
                            sx={{
                              width: '35px',
                              height: '35px',
                              color: grey['700'],
                            }}
                          />
                        </WIPWidgetWithIcon>
                      </Grid>
                      <Grid item xs={4}>
                        <WIPWidgetWithIcon
                          title='Cases'
                          numericValue={productionState.wip_view.casesIn}
                        >
                          <PalletIcon
                            sx={{
                              width: '35px',
                              height: '35px',
                              color: grey['700'],
                            }}
                          />
                        </WIPWidgetWithIcon>
                      </Grid>
                      <Grid item xs={4}>
                        <WIPWidgetWithIcon
                          title='Weight'
                          numericValue={parseFloat(
                            productionState.wip_view.weightIn
                          ).toFixed(0)}
                          umo={productionState.wip_view.umo}
                        >
                          <WeightIcon
                            sx={{
                              width: '35px',
                              height: '35px',
                              color: grey['700'],
                            }}
                          />
                        </WIPWidgetWithIcon>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              {/*FINISHED PRODUCT*/}
              <Box mx={1} sx={{ width: '30%', maxWidth: '300px' }}>
                <Card variant='outlined'>
                  <Typography
                    variant='h6'
                    sx={{
                      paddingTop: 1,
                      paddingBottom: 1,
                      paddingLeft: 2,
                      backgroundImage: `linear-gradient( 135deg, ${blue[600]} 10%, ${blue[700]} 100%);`,
                      color: 'white',
                    }}
                  >
                    Finished Product
                  </Typography>
                  <CardContent
                    sx={{
                      padding: 1,
                      '&:last-child': {
                        paddingBottom: 0.8,
                      },
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <WIPWidgetWithIcon
                          title='Pallets'
                          numericValue={productionState.wip_view.palletsOut}
                        >
                          <PalletOutIcon
                            sx={{
                              width: '35px',
                              height: '35px',
                              color: grey['700'],
                            }}
                          />
                        </WIPWidgetWithIcon>
                      </Grid>
                      <Grid item xs={4}>
                        <WIPWidgetWithIcon
                          title='Cases'
                          numericValue={productionState.wip_view.casesOut}
                        >
                          <PalletIcon
                            sx={{
                              width: '35px',
                              height: '35px',
                              color: grey['700'],
                            }}
                          />
                        </WIPWidgetWithIcon>
                      </Grid>
                      <Grid item xs={4}>
                        <WIPWidgetWithIcon
                          title='Weight'
                          numericValue={parseFloat(
                            productionState.wip_view.weightOut
                          ).toFixed(0)}
                          umo={productionState.wip_view.umo}
                        >
                          <WeightIcon
                            sx={{
                              width: '35px',
                              height: '35px',

                              color: grey['700'],
                            }}
                          />
                        </WIPWidgetWithIcon>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              {/*CAT 2*/}
              <Box mx={1} sx={{ width: '20%', maxWidth: '200px' }}>
                <Card variant='outlined'>
                  <Typography
                    variant='h6'
                    sx={{
                      paddingTop: 1,
                      paddingBottom: 1,
                      paddingLeft: 2,
                      backgroundImage: `linear-gradient( 135deg, ${orange[600]} 10%, ${orange[700]} 100%);`,
                      color: 'white',
                    }}
                  >
                    Category 2
                  </Typography>
                  <CardContent
                    sx={{
                      padding: 1,
                      '&:last-child': {
                        paddingBottom: 0.8,
                      },
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <WIPWidgetWithIcon
                          title='Cases'
                          numericValue={productionState.wip_view.casesCat2}
                        >
                          <PalletIcon
                            sx={{
                              width: '35px',
                              height: '35px',
                              color: grey['700'],
                            }}
                          />
                        </WIPWidgetWithIcon>
                      </Grid>
                      <Grid item xs={6}>
                        <WIPWidgetWithIcon
                          title='Weight'
                          numericValue={parseFloat(
                            productionState.wip_view.weightCat2
                          ).toFixed(0)}
                          umo={productionState.wip_view.umo}
                        >
                          <WeightIcon
                            sx={{
                              width: '35px',
                              height: '35px',
                              color: grey['700'],
                            }}
                          />
                        </WIPWidgetWithIcon>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              {/*GRADE OUT*/}
              <Box mx={1} sx={{ width: '15%', maxWidth: '150px' }}>
                <Card variant='outlined'>
                  <Typography
                    variant='h6'
                    sx={{
                      paddingTop: 1,
                      paddingBottom: 1,
                      paddingLeft: 2,
                      backgroundImage: `linear-gradient( 135deg, ${red[600]} 10%, ${red[700]} 100%);`,
                      color: 'white',
                    }}
                  >
                    Grade Out
                  </Typography>
                  <CardContent
                    sx={{
                      padding: 1,
                      '&:last-child': {
                        paddingBottom: 0.8,
                      },
                    }}
                  >
                    <WIPWidgetWithIcon
                      title='Weight'
                      numericValue={parseFloat(
                        productionState.wip_view.weightGradeOut
                      ).toFixed(0)}
                      umo={productionState.wip_view.umo}
                    >
                      <WeightIcon
                        sx={{
                          width: '35px',
                          height: '35px',

                          color: grey['700'],
                        }}
                      />
                    </WIPWidgetWithIcon>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </Box>
        )}

        <Box sx={{ width: '100%', padding: 2 }}>
          {isEmpty(productionState.wip_view) &&
            !productionState.wip_view_loading && (
              <Empty description='No data to show' />
            )}
        </Box>
      </Paper>
    </Container>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case 'IN_PROGRESS':
      return `linear-gradient( 135deg, ${green[600]} 10%, ${green[700]} 100%);`;
    default:
      return `linear-gradient( 135deg, ${grey[600]} 10%, ${grey[700]} 100%);`;
  }
};

export default WIPDashboard;
