import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  loadMachineProfile,
  updateMachineProfile,
} from '../../redux/eplan/profile-machine/services';
import { resetMachineProfile } from '../../redux/eplan/profile-machine/actions';
import { Box, Paper, Typography, Divider } from '@mui/material';
import Loading from '../../components/UI/Loading';
import MachineProfileForm from '../../components/eplan/MachineProfileForm';
import isEmpty from 'is-empty';

const UpdateMachineProfile = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const machineProfilesState = useSelector((state) => state.machine_profile);
  const machineProfileState = useSelector(
    (state) => state.machine_profile.item
  );
  const onSubmit = (data) => {
    dispatch(updateMachineProfile(id, data, navigate));
  };

  useEffect(() => {
    if (isEmpty(id)) {
      navigate('/eplan/machine_profiles');
    } else {
      dispatch(loadMachineProfile(id));
    }
    return () => {
      dispatch(resetMachineProfile());
    };
  }, [id, dispatch, navigate]);

  return (
    <>
      <Loading loading={machineProfilesState.loading} />
      <Paper elevation={0}>
        <Box display='flex' mx={1} mt={1}>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              ePlan - Update Machine Profile
            </Typography>
          </Box>
        </Box>
        <Divider variant='middle' light />
        <Box mx={1} mt={1}>
          {!isEmpty(machineProfileState) && !machineProfilesState.loading ? (
            <MachineProfileForm
              machineId={machineProfileState.machine.id}
              commodityId={machineProfileState.commodity.id}
              itemDescription={machineProfileState.itemDescription}
              onSubmit={onSubmit}
              savingData={machineProfilesState.savingData}
              editMode={true}
              machineProfile={machineProfileState}
            />
          ) : null}
        </Box>
      </Paper>
    </>
  );
};

export default UpdateMachineProfile;
