import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setPalletTypeList,
  resetPalletTypeList,
  setPalletTypeListLoading,
  addPalletTypeToList,
  deletePalletTypeFromList,
  updatePalletTypeInList,
  savingPalletTypeData,
  showAddEditPalletTypeForm,
} from './actions';

export const loadPalletTypes = () => (dispatch, getState, api) => {
  dispatch(setPalletTypeListLoading(true));
  axios
    .get(`${api}/pallet-types?limit=1000000`)
    .then((res) => {
      dispatch(resetPalletTypeList());
      dispatch(setPalletTypeList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setPalletTypeListLoading(false));
    });
};

export const createPalletType = (data) => (dispatch, getState, api) => {
  dispatch(setPalletTypeListLoading(true));
  dispatch(savingPalletTypeData(true));
  axios
    .post(`${api}/pallet-types`, data)
    .then((res) => {
      dispatch(addPalletTypeToList(res.data));
      dispatch(showAddEditPalletTypeForm(false));
      const notification = {
        message: '👍🏻 Pallet type created successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setPalletTypeListLoading(false));
      dispatch(savingPalletTypeData(false));
    });
};

export const updatePalletType = (id, data) => (dispatch, getState, api) => {
  dispatch(setPalletTypeListLoading(true));
  dispatch(savingPalletTypeData(true));
  axios
    .put(`${api}/pallet-types/${id}`, data)
    .then((res) => {
      dispatch(updatePalletTypeInList(res.data));
      dispatch(showAddEditPalletTypeForm(false));
      const notification = {
        message: '👍🏻 Pallet type updated successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setPalletTypeListLoading(false));
      dispatch(savingPalletTypeData(false));
    });
};

export const deletePalletType = (id) => (dispatch, getState, api) => {
  dispatch(setPalletTypeListLoading(true));
  axios
    .delete(`${api}/pallet-types/${id}`)
    .then((res) => {
      dispatch(deletePalletTypeFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Pallet type deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setPalletTypeListLoading(false));
};

export const togglePalletTypeStatus =
  (id, currentStatus) => (dispatch, getState, api) => {
    dispatch(setPalletTypeListLoading(true));
    axios
      .post(`${api}/pallet-types/activate/${id}/${!currentStatus}`)
      .then((res) => {
        console.log(res);
        dispatch(loadPalletTypes());
        const notification = {
          message: '👍🏻 Pallet type status updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setPalletTypeListLoading(false));
      });
  };
