import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setEmployeePosition,
  resetEmployeePosition,
  resetEmployeePositionList,
  showAddEditEmployeePositionForm,
} from '../../redux/company/employee-positions/actions';
import {
  loadEmployeePositions,
  createEmployeePosition,
  updateEmployeePosition,
  deleteEmployeePosition,
  toggleEmployeePositionStatus,
} from '../../redux/company/employee-positions/services';
import { getEmployeePositionList } from '../../redux/company/employee-positions/selectors';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Paper,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import Loading from '../../components/UI/Loading';
import EmployeePositionForm from '../../components/company/EmployeePositionForm';
import Empty from '../../components/UI/Empty';
import EmployeePositionsTable from '../../components/company/EmployeePositionsTable';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
const isEmpty = require('is-empty');

const EmployeePositions = () => {
  const dispatch = useDispatch();
  const employeePositionsState = useSelector(
    (state) => state.employee_positions
  );
  const employeePositionState = useSelector(
    (state) => state.employee_positions.item
  );
  const EmployeePositionsList = useSelector(getEmployeePositionList);
  const [editMode, setEditMode] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [
    openRemoveEmployeePositionDialog,
    setOpenRemoveEmployeePositionDialog,
  ] = useState(false);

  useEffect(() => {
    dispatch(loadEmployeePositions());
    return () => {
      dispatch(resetEmployeePositionList());
    };
  }, [dispatch]);

  const handleClickAdd = () => {
    setEditMode(false);
    dispatch(showAddEditEmployeePositionForm(true));
  };

  const handleClickEdit = (employeePosition) => {
    setEditMode(true);
    dispatch(setEmployeePosition(employeePosition));
    dispatch(showAddEditEmployeePositionForm(true));
  };

  const onSubmitAddEdit = (data) => {
    if (editMode) {
      dispatch(updateEmployeePosition(employeePositionState.id, data));
    } else {
      dispatch(createEmployeePosition(data));
    }
  };

  const handleRefresh = () => {
    dispatch(loadEmployeePositions());
  };

  const handleClickRemove = (employeePosition) => {
    dispatch(setEmployeePosition(employeePosition));
    setOpenRemoveEmployeePositionDialog(true);
  };

  const onRemoveEmployeePosition = () => {
    dispatch(deleteEmployeePosition(employeePositionState.id));
    dispatch(resetEmployeePosition());
    setOpenRemoveEmployeePositionDialog(false);
  };

  const handleClickToogleStatus = (employeePosition) => {
    dispatch(setEmployeePosition(employeePosition));
    setOpenStatusDialog(true);
  };

  const onToggleStatus = () => {
    dispatch(
      toggleEmployeePositionStatus(
        employeePositionState.id,
        employeePositionState.active
      )
    );
    dispatch(resetEmployeePosition());
    setOpenStatusDialog(false);
  };

  return (
    <>
      <Loading loading={employeePositionsState.loading} />
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box display='flex' alignContent='center'>
            <IconButton aria-label='reload' onClick={handleRefresh}>
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              Employee Positions
            </Typography>
          </Box>
          <Tooltip title='Add Employee Position'>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<AddIcon />}
              sx={{ marginRight: '0.5rem' }}
              onClick={handleClickAdd}
            >
              Add
            </Button>
          </Tooltip>
        </Box>
        <Box sx={{ width: '100%' }}>
          {!isEmpty(employeePositionsState.list) && (
            <EmployeePositionsTable
              rows={EmployeePositionsList}
              handleClickEdit={handleClickEdit}
              handleClickRemove={handleClickRemove}
              handleClickToogleStatus={handleClickToogleStatus}
            />
          )}
          {isEmpty(employeePositionsState.list) &&
            !employeePositionsState.loading && (
              <Empty description='There are no employee positions to show' />
            )}
        </Box>
      </Paper>
      <ConfirmationDialog
        open={openRemoveEmployeePositionDialog}
        handleNo={() => {
          setOpenRemoveEmployeePositionDialog(false);
        }}
        handleYes={onRemoveEmployeePosition}
        title='ePack - Remove EmployeePosition'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to remove employee position: ${employeePositionState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openStatusDialog}
        handleNo={() => {
          setOpenStatusDialog(false);
        }}
        handleYes={onToggleStatus}
        title='ePack - Employee Position Status'
        YesText={'Yes, Change'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to ${
            employeePositionState?.active ? 'deactivate' : 'activate'
          } employee position: ${employeePositionState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <Dialog
        open={employeePositionsState.showAddEditForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setEditMode(false);
            dispatch(resetEmployeePosition());
            dispatch(showAddEditEmployeePositionForm(false));
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <EmployeePositionForm
            editMode={editMode}
            employeePosition={employeePositionState}
            handleOnCancel={() => {
              setEditMode(false);
              dispatch(resetEmployeePosition());
              dispatch(showAddEditEmployeePositionForm(false));
            }}
            handleOnSubmit={onSubmitAddEdit}
            savingData={employeePositionsState.savingData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EmployeePositions;
