import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setSetupChecklistItems,
  resetSetupChecklistItems,
  setSetupChecklistItemsLoading,
} from './actions';

export const loadSetupChecklistItems = () => (dispatch, getState, api) => {
  dispatch(setSetupChecklistItemsLoading(true));
  axios
    .get(`${api}/list/setup-checklist-items`)
    .then((res) => {
      dispatch(resetSetupChecklistItems());
      dispatch(setSetupChecklistItems(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setSetupChecklistItemsLoading(false));
    });
};
