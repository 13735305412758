import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { resizeFile } from '../../utils/imageResizer';
import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  FormControl,
  Grid,
  FormHelperText,
  Paper,
  InputLabel,
  Select,
  TextField,
  Typography,
  MenuItem,
  Skeleton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FALLBACK_IMAGE from '../../assets/no-image.svg';
import IMAGE_PLACEHOLDER from '../../assets/placeholder.svg';

const Input = styled('input')({
  display: 'none',
});

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1, 'Must be 1 character or more')
    .max(50, 'Must be 50 characters or less')
    .required('Required'),
  lastName: Yup.string()
    .min(1, 'Must be 1 character or more')
    .max(50, 'Must be 50 characters or less')
    .required('Required'),
  employerId: Yup.string().required('Required, please select an employer'),
});

const onMediaFallback = (event) => (event.target.src = FALLBACK_IMAGE);

const EmployeeForm = ({
  editMode,
  employee,
  loadingEmployers,
  handleOnSubmit,
  handleOnCancel,
  employers,
  savingData,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });
  const [selectedImage, setSelectedImage] = useState();
  const [imagePreview, setImagePreview] = useState(IMAGE_PLACEHOLDER);

  useEffect(() => {
    if (!selectedImage) {
      setImagePreview(IMAGE_PLACEHOLDER);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  useEffect(() => {
    if (editMode) {
      setImagePreview(
        'https://ehawk.nyc3.digitaloceanspaces.com/' + employee.photo
      );
      setValue('id', employee.id);
      return;
    }
  }, [editMode, employee, setValue]);

  const onChangeHandlerFile = async (event) => {
    try {
      const file = event.target.files[0];
      let newFile = await resizeFile(file);
      setSelectedImage(newFile);
      setValue('file', newFile);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper elevation={0}>
          <Box component="div" display="flex" mx={1} alignItems="center">
            <Box display="flex" my={1.5} flexGrow={1} alignItems="center">
              <Typography
                color="primary"
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {`eTime - ${editMode ? 'Update' : 'Add'}  Employee`}
              </Typography>
            </Box>
          </Box>
          <Divider light />
          <Grid container spacing={1} mt={1}>
            <Grid item xs={6}>
              <Controller
                name="firstName"
                control={control}
                defaultValue={editMode ? employee.firstName : ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="First Name"
                    fullWidth
                    error={!!errors?.firstName}
                    helperText={errors?.firstName?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="lastName"
                control={control}
                defaultValue={editMode ? employee.lastName : ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Last Name"
                    fullWidth
                    error={!!errors?.lastName}
                    helperText={errors?.lastName?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ minWidth: 250 }}>
                <InputLabel id="employer-select-label">Employer</InputLabel>
                <Controller
                  name="employerId"
                  control={control}
                  defaultValue={editMode ? employee.employer.id : ''}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="employer-select-label"
                      label={'Employer'}
                      fullWidth
                      error={!!errors?.employerId}
                    >
                      {loadingEmployers ? (
                        <Skeleton />
                      ) : (
                        employers.map((employer) => (
                          <MenuItem key={employer.id} value={employer.id}>
                            {employer.name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  )}
                />
              </FormControl>
              <FormHelperText>{errors?.employerId?.message}</FormHelperText>
            </Grid>
          </Grid>
          <Box display="flex" mt={2} justifyContent="center">
            <Card
              elevation={0}
              square
              sx={{
                maxWidth: 300,
                '& .MuiCardHeader-root': {
                  padding: 0,
                  paddingBottom: 1,
                  paddingLeft: 0.5,
                },
              }}
            >
              <CardMedia
                sx={{ height: 300, width: 300 }}
                image={imagePreview}
                onError={(e) => onMediaFallback(e)}
              />
            </Card>
          </Box>

          <Box display="flex" justifyContent="center" mt={0.5}>
            <label htmlFor="contained-button-file">
              <Input
                accept="image/jpeg"
                id="contained-button-file"
                type="file"
                name="file"
                onChange={onChangeHandlerFile}
              />
              <Button
                variant="contained"
                component="span"
                startIcon={<CameraAltIcon />}
              >
                Capture
              </Button>
            </label>
          </Box>
          <Box component="div" mx={1} my={0.5} display="flex">
            <Box my={1.5} flexGrow={1}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                startIcon={<SaveIcon />}
                disabled={savingData}
              >
                {savingData ? 'Saving...' : 'Save'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant="outlined"
                size="large"
                startIcon={<UndoIcon />}
                onClick={handleOnCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default EmployeeForm;
