import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PalletOutIcon from '../UI/icons/PalletOutIcon';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ClipboardInflowTable = ({
  rows,
  readOnly,
  handleClickDeletePallet,
  handleClickMovePalletToOut,
}) => {
  return (
    <Table aria-label='inflow table' size='small'>
      <TableHead>
        <TableRow>
          <TableCell>Pallet</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Lot</TableCell>
          <TableCell>GTIN</TableCell>
          <TableCell>Item</TableCell>
          <TableCell>Cases</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <StyledTableRow key={row.id}>
            <TableCell component='th' scope='row'>
              {row.code}
            </TableCell>
            <TableCell>{row.description}</TableCell>
            <TableCell>{row.lot}</TableCell>
            <TableCell>{row.GTIN}</TableCell>
            <TableCell>{row.item}</TableCell>
            <TableCell>{row.cases}</TableCell>
            <TableCell>
              {!readOnly && (
                <>
                  <Tooltip title='Delete'>
                    <IconButton
                      size='small'
                      onClick={() => handleClickDeletePallet(row)}
                      sx={{
                        color: 'error.main',
                      }}
                      variant='outlined'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Move'>
                    <IconButton
                      size='small'
                      onClick={() => handleClickMovePalletToOut(row)}
                      sx={{
                        color: 'primary.main',
                        marginLeft: 1,
                      }}
                      variant='outlined'
                    >
                      <PalletOutIcon sx={{ width: '22px', height: '22px' }} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </TableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ClipboardInflowTable;
