export const MACHINE_LOADING = 'MACHINE_LOADING';
export const SET_MACHINE = 'SET_MACHINE';
export const MACHINE_RESET = 'MACHINE_RESET';
export const SET_MACHINES = 'SET_MACHINES';
export const MACHINES_LIST_LOADING = 'MACHINES_LIST_LOADING';
export const MACHINES_LIST_RESET = 'MACHINES_LIST_RESET';
export const ADD_MACHINE_TO_LIST = 'ADD_MACHINE_TO_LIST';
export const UPDATE_MACHINE_IN_LIST = 'UPDATE_MACHINE_IN_LIST';
export const DELETE_MACHINE_FROM_LIST = 'DELETE_MACHINE_FROM_LIST';
export const SHOW_ADD_EDIT_MACHINE_FORM = 'SHOW_ADD_EDIT_MACHINE_FORM';
export const SAVING_MACHINE_DATA = 'SAVING_MACHINE_DATA';
