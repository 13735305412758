import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import isEmpty from 'is-empty';

const WIPWidgetWithIcon = ({
  children,
  title,
  titleColor = [grey[700]],
  numericValue,
  numericValueColor = [grey[700]],
  umo,
}) => {
  return (
    <Card variant='outlined'>
      <CardContent
        sx={{
          padding: 1,
          '&:last-child': {
            paddingBottom: 0.8,
          },
        }}
      >
        <Box display='flex' flexDirection='column'>
          <Box display='flex' justifyContent='center'>
            <Typography
              sx={{
                fontSize: '21px',
                fontWeight: 'medium',
                color: titleColor,
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box display='flex' justifyContent='center' mt={1}>
            <Typography
              sx={{
                fontSize: 45,
                fontWeight: 'bold',
                lineHeight: 1,
                color: numericValueColor,
              }}
            >
              {children}
            </Typography>
          </Box>
          <Box display='flex' justifyContent='center' mt={1}>
            <Typography
              sx={{
                fontSize: 30,
                fontWeight: '500',
                lineHeight: 1,
                color: numericValueColor,
              }}
            >
              {numericValue}
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            mt={1}
            sx={{ minHeight: '14px' }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: '500',
                lineHeight: 1,
                color: numericValueColor,
              }}
            >
              {isEmpty(umo) ? null : `${umo}`}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default WIPWidgetWithIcon;
