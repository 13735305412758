import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setGateInList,
  resetGateInList,
  setGateInListLoading,
  showGateInForm,
  savingGateInData,
  deleteGateInFromList,
} from './actions';

export const loadGateInList = () => (dispatch, getState, api) => {
  dispatch(setGateInListLoading(true));
  axios
    .get(`${api}/gate-in?limit=1000000`)
    .then((res) => {
      dispatch(resetGateInList());
      dispatch(setGateInList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setGateInListLoading(false));
    });
};

export const gateInEmployee = (data) => (dispatch, getState, api) => {
  dispatch(setGateInListLoading(true));
  dispatch(savingGateInData(true));
  axios
    .post(`${api}/gate-in`, {
      employeeId: data.id,
      barcode: data.barcode,
    })
    .then((res) => {
      dispatch(deleteGateInFromList(data.id));
      dispatch(showGateInForm(false));
      const notification = {
        message: '👍🏻 Employee signed in successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setGateInListLoading(false));
      dispatch(savingGateInData(false));
    });
};

export const gateInEmployeesById = (data) => (dispatch, getState, api) => {
  dispatch(setGateInListLoading(true));
  axios
    .post(`${api}/gate-in/by-ids`, {
      employeeIds: data,
    })
    .then((res) => {
      dispatch(loadGateInList());
      const notification = {
        message: '👍🏻 Employees signed in successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setGateInListLoading(false));
    });
};
