import React from 'react';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';

const StatusBox = ({ color, text }) => {
  return (
    <Box
      component='span'
      sx={{
        border: '2px solid',
        borderRadius: 1.5,
        padding: 0.3,
        color: color,
        fontWeight: 'medium',
      }}
    >
      {text}
    </Box>
  );
};

const ProductionLineStatus = ({ status }) => {
  switch (status) {
    case 'IN_PROGRESS':
      return <StatusBox color='#2CA02C' text='IN PROGRESS' />;
    case 'NOT_AVAILABLE':
      return <StatusBox color='#D62728' text='UNAVAILABLE' />;
    case 'SETUP':
      return <StatusBox color='#5e35b1' text='SETUP' />;
    case 'SANITATION':
      return <StatusBox color='#fb8c00' text='SANITATION' />;
    default:
      return <StatusBox color={grey['A600']} text={status} />;
  }
};

export default ProductionLineStatus;
