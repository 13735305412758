import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getCommodityList = createSelector(
  (state) => state.list_commodities.list,
  (commodityList) => {
    if (isEmpty(commodityList)) return [];
    return commodityList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
