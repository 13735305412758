import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > svg': {
      marginTop: theme.spacing(),
      marginRight: theme.spacing(0.5),
    },
  },
}));

const ProfitIcon = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 52 58'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M50.8969 11.3389L39.8929 12.2847C39.8373 12.2894 39.7843 12.3102 39.7402 12.3445C39.6961 12.3787 39.6629 12.425 39.6446 12.4777C39.6262 12.5304 39.6235 12.5872 39.6367 12.6415C39.65 12.6957 39.6786 12.7449 39.7192 12.7832L42.4951 15.4012L41.8789 16.0301L41.4678 16.4497L39.3807 18.6164C38.5765 19.464 37.7755 20.3136 36.9777 21.1653C36.4062 21.7778 35.8379 22.3939 35.2728 23.0137C33.92 24.4943 32.5844 25.9918 31.2662 27.5062C30.6102 28.2658 29.9567 29.029 29.3129 29.8007C29.1417 30.0056 28.9729 30.213 28.8029 30.4192L28.1144 30.0322L17.3726 23.9939L15.658 23.0305L14.4294 24.3689L12.8921 26.0436L0.789062 39.227L3.33073 41.706L16.4261 29.0688H16.4273L24.0933 34.0557L28.0131 36.6058L29.4347 37.5305L29.7602 37.7416L31.6098 36.1211C32.367 35.4567 33.1125 34.7815 33.8464 34.0954C34.5831 33.4106 35.3089 32.7173 36.0324 32.0216C37.4768 30.6266 38.9 29.2123 40.3019 27.7787C41.7101 26.3499 43.0919 24.8958 44.47 23.4393L46.521 21.2376L46.7983 20.935L47.5235 20.1436L50.1739 22.6432C50.214 22.6811 50.2642 22.7067 50.3185 22.717C50.3727 22.7273 50.4288 22.7218 50.4801 22.7012C50.5313 22.6806 50.5756 22.6458 50.6077 22.6008C50.6397 22.5559 50.6583 22.5027 50.6611 22.4475L51.2105 11.6417C51.2126 11.6004 51.2059 11.5592 51.1907 11.5208C51.1755 11.4824 51.1523 11.4477 51.1226 11.419C51.0929 11.3903 51.0574 11.3683 51.0184 11.3545C50.9795 11.3407 50.9381 11.3354 50.8969 11.3389Z'
          fill='#2981BE'
        />
        <path
          d='M3.38574 56.2181C3.38611 56.4689 3.48589 56.7093 3.66322 56.8866C3.84054 57.0639 4.08094 57.1637 4.33172 57.1641H10.8891C11.1399 57.1637 11.3803 57.0639 11.5576 56.8866C11.7349 56.7093 11.8347 56.4689 11.8351 56.2181V37.0845L3.38574 45.2383V56.2181Z'
          fill='#E74C3C'
        />
        <path
          d='M15.103 33.9306V56.2181C15.1034 56.4689 15.2032 56.7093 15.3805 56.8866C15.5578 57.064 15.7982 57.1637 16.049 57.1641H22.6064C22.8572 57.1637 23.0976 57.0639 23.2749 56.8866C23.4522 56.7093 23.552 56.4689 23.5524 56.2181V36.7814L16.7402 32.3503L15.103 33.9306Z'
          fill='#2e7d32'
        />
        <path
          d='M29.9942 40.9674L26.8208 38.9067V56.2181C26.8212 56.4689 26.9209 56.7093 27.0983 56.8866C27.2756 57.064 27.516 57.1638 27.7668 57.1641H34.3242C34.5749 57.1637 34.8153 57.064 34.9927 56.8866C35.17 56.7093 35.2698 56.4689 35.2701 56.2181V36.2955C34.6402 36.8792 33.9812 37.4726 33.3115 38.0606L29.9942 40.9674Z'
          fill='#E74C3C'
        />
        <path
          d='M42.1393 29.5898C40.8407 30.912 39.6539 32.0956 38.5381 33.1845V56.2181C38.5385 56.4689 38.6382 56.7093 38.8156 56.8866C38.9929 57.064 39.2333 57.1637 39.4841 57.1641H46.0414C46.2922 57.1637 46.5326 57.0639 46.7099 56.8866C46.8873 56.7093 46.9871 56.4689 46.9874 56.2181V24.5223L46.3446 25.2125C45.0127 26.6196 43.5916 28.1171 42.1393 29.5898Z'
          fill='#2e7d32'
        />
        <path
          d='M29.7407 17.9532C29.3345 18.251 28.5777 18.4006 27.4703 18.4018C26.528 18.4067 25.5898 18.2786 24.6833 18.0213C23.9039 17.8049 23.1444 17.5226 22.4129 17.1774L21.2437 20.3045C21.842 20.5866 22.4551 20.8364 23.0802 21.0528C24.1228 21.3954 25.2055 21.6012 26.3011 21.665V24.9005H29.7279V21.5823C30.5091 21.4713 31.2668 21.2328 31.9707 20.8763C32.5348 20.5848 33.0378 20.1879 33.4525 19.7071C33.822 19.2742 34.0998 18.7707 34.2687 18.2272C34.4304 17.7031 34.5129 17.1578 34.5132 16.6094C34.5295 15.8894 34.3858 15.1749 34.0922 14.5173C33.8136 13.9218 33.4157 13.39 32.923 12.9546C32.3875 12.4856 31.7937 12.0877 31.1563 11.7707C30.4372 11.4083 29.6968 11.0897 28.9392 10.8166C28.4134 10.6346 27.9648 10.4667 27.5935 10.3129C27.2676 10.182 26.9538 10.0227 26.6559 9.83671C26.4278 9.69899 26.2366 9.50781 26.0989 9.27968C25.9746 9.0503 25.9131 8.79221 25.9205 8.53146C25.916 8.30904 25.9656 8.08886 26.0653 7.88994C26.1649 7.69103 26.3114 7.51937 26.4923 7.3898C27.0568 7.05134 27.7134 6.89892 28.3693 6.9541C29.206 6.95017 30.0405 7.04145 30.8566 7.22618C31.5673 7.38318 32.263 7.60154 32.9359 7.87881L33.7797 4.61564C33.2555 4.39228 32.7146 4.21033 32.1619 4.07147C31.3627 3.87093 30.5488 3.73445 29.7279 3.66334V0.835876H26.3011V3.77365C25.5796 3.89252 24.882 4.12652 24.2347 4.46672C23.7068 4.74106 23.239 5.11797 22.8586 5.57538C22.4782 6.03279 22.1929 6.56151 22.0194 7.13056C21.8458 7.68947 21.7584 8.27158 21.7603 8.85683C21.7352 9.50381 21.861 10.1477 22.1278 10.7377C22.3945 11.3277 22.7949 11.8475 23.2972 12.256C23.7703 12.6427 24.2912 12.9671 24.8469 13.2212C25.4187 13.4841 26.0033 13.7243 26.6008 13.9419C27.2166 14.1606 27.7608 14.3628 28.2333 14.554C28.6403 14.7122 29.0317 14.9079 29.4025 15.1387C29.6803 15.3106 29.9204 15.537 30.1085 15.8042C30.277 16.0676 30.3622 16.3758 30.353 16.6884C30.364 16.9335 30.3138 17.1775 30.2069 17.3983C30.1 17.6191 29.9398 17.8098 29.7407 17.9532Z'
          fill='#2e7d32'
        />
      </SvgIcon>
    </div>
  );
};

export default ProfitIcon;
