import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setProductionDashboardData,
  setProductionDashboardDataLoading,
  resetProductionDashboardData,
} from './actions';

export const loadProductionDashboardData = () => (dispatch, getState, api) => {
  dispatch(setProductionDashboardDataLoading(true));
  axios
    .get(`${api}/dashboard/production`)
    .then((res) => {
      dispatch(resetProductionDashboardData());
      dispatch(setProductionDashboardData(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setProductionDashboardDataLoading(false));
    });
};

export const loadProductionDashboardPrivateData =
  (token) => (dispatch, getState, api) => {
    dispatch(setProductionDashboardDataLoading(true));
    axios
      .get(`${api}/private-company/dashboard/production/${token}`)
      .then((res) => {
        dispatch(resetProductionDashboardData());
        dispatch(setProductionDashboardData(res.data));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setProductionDashboardDataLoading(false));
      });
  };
