const themeObject = {
  palette: {
    primary: {
      main: '#2981BE',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f9ae0f',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '7px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '38px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '42px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '42px',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        avatar: {
          marginRight: '10px',
        },
      },
    },
  },
};

export default themeObject;
