import React from 'react';
import { useDispatch } from 'react-redux';
import { CssBaseline } from '@mui/material';
import {
  styled,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import theme from './theme';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { logoutUser, setCurrentUser } from './redux/system/auth/actions';
import MainRouter from './router/index';
import { AdapterDateFns as AdapterMoment } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useNotifier from './hooks/useNotifier';
import OfflineAlert from './components/UI/OfflineAlert';
import { useOnlineStatus } from './hooks/useOnlineStatus';

const StyledDivWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  minHeight: '100vh',
});

function App() {
  const themeConfig = createTheme(theme);
  useNotifier();
  const dispatch = useDispatch();
  const onlineStatus = useOnlineStatus();

  // Check for token to keep user logged in
  if (localStorage.jwt_Tokens) {
    const tokens = JSON.parse(localStorage.getItem('jwt_Tokens'));
    const token = tokens.access.token;
    let decodedToken;

    try {
      decodedToken = jwt_decode(token);
    } catch (error) {
      console.error('Error decoding token:', error);
    }

    if (decodedToken === undefined) {
      // Logout user
      dispatch(logoutUser());
    }
    // Set auth token header auth
    setAuthToken(token);

    // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decodedToken.exp < currentTime) {
      // Logout user
      dispatch(logoutUser());
    }

    if (localStorage.user) {
      const user = JSON.parse(localStorage.getItem('user'));
      dispatch(setCurrentUser(user));
    } else {
      dispatch(logoutUser());
    }
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeConfig}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StyledDivWrapper>
            <CssBaseline>
              <OfflineAlert open={!onlineStatus} />
              <MainRouter />
            </CssBaseline>
          </StyledDivWrapper>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
