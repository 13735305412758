import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setMachineProfileList,
  resetMachineProfileList,
  setMachineProfileListLoading,
  deleteMachineProfileFromList,
  savingMachineProfileData,
  resetMachineProfileFilters,
  setMachineProfile,
  resetMachineProfile,
  setMachineProfileLoading,
  addMachineProfileToList,
  showDuplicateMachineProfileForm,
} from './actions';

export const loadMachineProfiles = () => (dispatch, getState, api) => {
  dispatch(setMachineProfileListLoading(true));
  axios
    .get(`${api}/profile-machine?limit=1000000`)
    .then((res) => {
      dispatch(resetMachineProfileFilters());
      dispatch(resetMachineProfileList());
      dispatch(setMachineProfileList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineProfileListLoading(false));
    });
};

export const loadMachineProfile = (id) => (dispatch, getState, api) => {
  dispatch(setMachineProfileLoading(true));
  axios
    .get(`${api}/profile-machine/by-id/${id}`)
    .then((res) => {
      dispatch(resetMachineProfile());
      dispatch(setMachineProfile(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineProfileLoading(false));
    });
};

export const createMachineProfile =
  (data, navigate) => (dispatch, getState, api) => {
    dispatch(savingMachineProfileData(true));
    axios
      .post(`${api}/profile-machine`, data)
      .then((res) => {
        navigate('/eplan/machine_profiles');
        const notification = {
          message: '👍🏻 Machine Profile created successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(savingMachineProfileData(false));
      });
  };

export const updateMachineProfile =
  (id, data, navigate) => (dispatch, getState, api) => {
    dispatch(savingMachineProfileData(true));
    axios
      .put(`${api}/profile-machine/${id}`, data)
      .then((res) => {
        navigate('/eplan/machine_profiles');
        const notification = {
          message: '👍🏻 Machine Profile updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(savingMachineProfileData(false));
      });
  };

export const deleteMachineProfile = (id) => (dispatch, getState, api) => {
  dispatch(setMachineProfileListLoading(true));
  axios
    .delete(`${api}/profile-machine/${id}`)
    .then((res) => {
      dispatch(deleteMachineProfileFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Machine Profile deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setMachineProfileListLoading(false));
};

export const duplicateMachineProfile =
  (id, data) => (dispatch, getState, api) => {
    dispatch(savingMachineProfileData(true));
    axios
      .post(`${api}/profile-machine/duplicate/${id}`, data)
      .then((res) => {
        dispatch(addMachineProfileToList(res.data));
        dispatch(showDuplicateMachineProfileForm(false));
        const notification = {
          message: '👍🏻 Machine Profile duplicated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        let notification;
        if (err.response.status === 409) {
          notification = {
            message: err.response.data.message,
            options: {
              variant: 'error',
            },
          };
        } else {
          notification = {
            message: '😱 Something went wrong, please try again',
            options: {
              variant: 'error',
            },
          };
        }
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(savingMachineProfileData(false));
      });
  };
