import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  code: Yup.string().required('Maintenance Code is required'),
});

const UnavailableForm = ({ handleOnSubmit, handleOnCancel }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper elevation={0}>
          <Box component="div" display="flex" mx={1} alignItems="center">
            <Box display="flex" my={1.5} flexGrow={1} alignItems="center">
              <Typography
                color="primary"
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'eTime - Unavailable All'}
              </Typography>
            </Box>
          </Box>
          <Divider />

          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ minWidth: 250 }}>
                  <InputLabel id="code-select-label">
                    Maintenance Code
                  </InputLabel>
                  <Controller
                    name="code"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="code-select-label"
                        label={'Maintenance Code'}
                        fullWidth
                        error={!!errors?.code}
                      >
                        <MenuItem
                          key="MAINTENANCE_SCHEDULED"
                          value="MAINTENANCE_SCHEDULED"
                        >
                          Maintenance Scheduled
                        </MenuItem>
                        <MenuItem
                          key="MAINTENANCE_UNSCHEDULED"
                          value="MAINTENANCE_UNSCHEDULED"
                        >
                          Maintenance Unscheduled
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                <FormHelperText>{errors?.code?.message}</FormHelperText>
              </Grid>
            </Grid>
          </Box>
          <Box component="div" mx={1} my={0.5} display="flex">
            <Box my={1.5} flexGrow={1}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
              >
                {'Yes, Unavailable All'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant="outlined"
                size="large"
                startIcon={<UndoIcon />}
                onClick={handleOnCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default UnavailableForm;
