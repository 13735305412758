export const SET_MANUAL_PROFILE = 'SET_MANUAL_PROFILE';
export const MANUAL_PROFILE_LOADING = 'MANUAL_PROFILE_LOADING';
export const MANUAL_PROFILE_RESET = 'MANUAL_PROFILE_RESET';
export const SET_MANUAL_PROFILES = 'SET_MANUAL_PROFILES';
export const MANUAL_PROFILE_LIST_LOADING = 'MANUAL_PROFILE_LIST_LOADING';
export const MANUAL_PROFILE_LIST_RESET = 'MANUAL_PROFILE_LIST_RESET';
export const ADD_MANUAL_PROFILE_TO_LIST = 'ADD_MANUAL_PROFILE_TO_LIST';
export const UPDATE_MANUAL_PROFILE_IN_LIST = 'UPDATE_MANUAL_PROFILE_IN_LIST';
export const DELETE_MANUAL_PROFILE_FROM_LIST = 'DELETE_MANUAL_PROFILE_FROM_LIST';
export const SAVING_MANUAL_PROFILE_DATA = 'SAVING_MANUAL_PROFILE_DATA';
export const SET_MANUAL_PROFILE_FILTERS = 'SET_MANUAL_PROFILE_FILTERS';
export const RESET_MANUAL_PROFILE_FILTERS = 'RESET_MANUAL_PROFILE_FILTERS';
export const SHOW_DUPLICATE_MANUAL_PROFILE_FORM =
  'SHOW_DUPLICATE_MANUAL_PROFILE_FORM';
