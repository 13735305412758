import React, { useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  machine: Yup.string().required('Required'),
  commodity: Yup.string().required('Required'),
});

const MORProfileFilterForm = ({
  machines,
  commodities,
  existingMORProfile,
  handleOnFilter,
  handleClickAdd,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  const watchMachine = watch('machine');
  const watchCommodity = watch('commodity');
  const watchItemDescription = watch('itemDescription');

  const setFilters = useCallback(() => {
    const filters = {
      machine: watchMachine,
      commodity: watchCommodity,
      itemDescription: watchItemDescription,
    };
    handleOnFilter(filters);
  }, [watchMachine, watchCommodity, watchItemDescription, handleOnFilter]);

  useEffect(() => {
    setFilters();
  }, [watchMachine, watchCommodity, watchItemDescription]);

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          handleClickAdd(data.machine, data.commodity, data.itemDescription);
        })}
      >
        <Box display='flex' mx={1} my={1} justifyContent='space-evenly'>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FormControl fullWidth size='small' sx={{ minWidth: 250 }}>
              <InputLabel id='machine-select-label'>Machine</InputLabel>
              <Controller
                name='machine'
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId='machine-select-label'
                    label={'Machine'}
                    fullWidth
                    error={!!errors?.machine}
                  >
                    {machines.map((machine) => (
                      <MenuItem key={machine.id} value={machine.id}>
                        {machine.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FormControl fullWidth size='small' sx={{ minWidth: 250 }}>
              <InputLabel id='commodity-select-label'>Commodity</InputLabel>
              <Controller
                name='commodity'
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId='commodity-select-label'
                    label={'Commodity'}
                    fullWidth
                    error={!!errors?.commodity}
                  >
                    {commodities.map((commodity) => (
                      <MenuItem key={commodity.id} value={commodity.id}>
                        {commodity.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Controller
              name='itemDescription'
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  {...field}
                  size='small'
                  label='Item Description'
                  fullWidth
                  error={!!errors?.itemDescription}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box mx={1.5}>
              <Button
                variant='contained'
                color='secondary'
                type='submit'
                disabled={existingMORProfile}
                startIcon={<AddIcon />}
              >
                {'Add'}
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default MORProfileFilterForm;
