import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';
import isEmpty from 'is-empty';

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string()
    .email('Please provide a valid email')
    .required('Required'),
  role: Yup.string().required('Required'),
});

const UserForm = ({
  user,
  editMode,
  handleOnSubmit,
  handleOnCancel,
  savingData,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          let userData = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
          };
          if (data.role !== '') userData.role = data.role;
          handleOnSubmit(userData);
        })}
      >
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box display='flex' my={1.5} flexGrow={1} alignItems='center'>
              <Typography
                color='primary'
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'User'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name='firstName'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(user?.firstName) ? user.firstName : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='First Name'
                      fullWidth
                      error={!!errors?.firstName}
                      helperText={errors?.firstName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name='lastName'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(user?.lastName) ? user.lastName : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Last Name'
                      fullWidth
                      error={!!errors?.lastName}
                      helperText={errors?.lastName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name='email'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(user?.email) ? user.email : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Email'
                      fullWidth
                      error={!!errors?.email}
                      helperText={errors?.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth sx={{ minWidth: 250 }}>
                  <InputLabel id='role-select-label'>Role</InputLabel>
                  <Controller
                    name='role'
                    control={control}
                    defaultValue={
                      editMode && !isEmpty(user?.role) ? user.role : ''
                    }
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId='state-select-label'
                        label={'State'}
                        fullWidth
                        error={!!errors?.role}
                      >
                        <MenuItem value='user'>User</MenuItem>
                        <MenuItem value='report'>Report</MenuItem>
                        <MenuItem value='gate'>Gate</MenuItem>
                        <MenuItem value='supervisor'>Supervisor</MenuItem>
                        <MenuItem value='admin'>Admin</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                <FormHelperText>{errors?.role?.message}</FormHelperText>
              </Grid>
              {/* {!editMode && (
                <>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name='password'
                      control={control}
                      defaultValue={
                        editMode && !isEmpty(user?.password)
                          ? user.password
                          : ''
                      }
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Password'
                          fullWidth
                          type='password'
                          error={!!errors?.password}
                          helperText={errors?.password?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name='confirmPassword'
                      control={control}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Confirm Password'
                          fullWidth
                          type='password'
                          error={!!errors?.confirmPassword}
                          helperText={errors?.confirmPassword?.message}
                        />
                      )}
                    />
                  </Grid>
                </>
              )} */}
            </Grid>
          </Box>
          <Box component='div' mx={1} my={0.5} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
                startIcon={<SaveIcon />}
              >
                {savingData ? 'Saving...' : 'Save'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UndoIcon />}
                onClick={() => {
                  reset();
                  handleOnCancel();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default UserForm;
