import React from 'react';
import { Divider, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MenuVisibilityGate from '../../utils/MenuVisibilityGate';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

const isDemo = process.env.REACT_APP_DEMO === 'true' ? true : false;

const UserMenu = ({
  anchorEl,
  open,
  companies,
  handleClose,
  handleShowHideFavs,
  handleLogoutClick,
  handleOpenTenantMenu,
  userRole,
}) => {
  let navigate = useNavigate();
  const authState = useSelector((state) => state.auth);

  return (
    <Menu
      id='user-menu-appbar'
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      getcontentanchorel={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MenuItem
        onClick={() => {
          navigate('/user_settings');
          handleClose();
        }}
        disabled={isDemo}
      >
        User Settings
      </MenuItem>
      <MenuVisibilityGate
        userRole={authState.user.role}
        allowedPermissions={['config:company_settings']}
      >
        <Divider />
        <MenuItem
          onClick={() => {
            navigate('/company/profile/main');
            handleClose();
          }}
          disabled={isDemo}
        >
          Company Settings
        </MenuItem>
      </MenuVisibilityGate>
      <MenuVisibilityGate
        userRole={authState.user.role}
        allowedPermissions={['config:companies']}
      >
        <Divider />
        <MenuItem
          onClick={() => {
            navigate('/companies');
            handleClose();
          }}
          disabled={isDemo}
        >
          Companies
        </MenuItem>
      </MenuVisibilityGate>
      <MenuVisibilityGate
        userRole={authState.user.role}
        allowedPermissions={['config:companies']}
      >
        <Divider />
        {companies.length > 0 ? (
          <MenuItem onClick={handleOpenTenantMenu} disabled={isDemo}>
            <ArrowLeftIcon />
            {authState.user.company.name}
          </MenuItem>
        ) : (
          <MenuItem disabled={isDemo}>{authState.user.company.name}</MenuItem>
        )}
      </MenuVisibilityGate>
      <Divider />
      {/* <MenuItem onClick={handleShowHideFavs} disabled={isDemo}>
        Show/Hide Favorites
      </MenuItem> */}

      <MenuItem onClick={handleLogoutClick} disabled={isDemo}>
        Logout
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;
