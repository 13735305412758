export const SET_CUSTOMER = 'SET_CUSTOMER';
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const CUSTOMER_RESET = 'CUSTOMER_RESET';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const CUSTOMER_LIST_LOADING = 'CUSTOMER_LIST_LOADING';
export const CUSTOMER_LIST_RESET = 'CUSTOMER_LIST_RESET';
export const ADD_CUSTOMER_TO_LIST = 'ADD_CUSTOMER_TO_LIST';
export const UPDATE_CUSTOMER_IN_LIST = 'UPDATE_CUSTOMER_IN_LIST';
export const DELETE_CUSTOMER_FROM_LIST = 'DELETE_CUSTOMER_FROM_LIST';
export const SHOW_ADD_EDIT_CUSTOMER_FORM = 'SHOW_ADD_EDIT_CUSTOMER_FORM';
export const SAVING_CUSTOMER_DATA = 'SAVING_CUSTOMER_DATA';
