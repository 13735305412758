import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { loginUser } from '../../redux/system/auth/services';
import LoginForm from '../../components/auth/LoginForm';

const Login = () => {
  let navigate = useNavigate();
  const { location } = useLocation();
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authState.isAuthenticated) {
      Boolean(location?.from?.pathname)
        ? navigate(location.from.pathname, { replace: true })
        : navigate('/', { replace: true });
    }
  }, [authState.isAuthenticated, navigate, location]);

  const handleOnSubmit = (formData) => {
    dispatch(loginUser(formData));
  };

  return (
    <LoginForm handleOnSubmit={handleOnSubmit} loading={authState.loading} />
  );
};

export default Login;
