import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Paper, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import * as Yup from 'yup';
import moment from 'moment';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  start: Yup.date().typeError('Invalid date').required('Required'),
  end: Yup.date().typeError('Invalid date').required('Required'),
});

const SearchLaborLogForm = ({ handleOnSubmit, loading }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  return (
    <Paper elevation={0}>
      <form
        onSubmit={handleSubmit((data) => {
          let searchData = {
            name: data.name,
            start: moment(data.start).format('YYYY-MM-DD'),
            end: moment(data.end).format('YYYY-MM-DD'),
            employer: data.employer,
          };
          handleOnSubmit(searchData);
        })}
      >
        <Box display='flex' mt={1}>
          <Box px={1} sx={{ width: '20%' }}>
            <Controller
              name='name'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Name'
                  fullWidth
                  error={!!errors?.name}
                />
              )}
            />
          </Box>
          <Box px={1} sx={{ width: '20%' }}>
            <Controller
              name='start'
              control={control}
              defaultValue={moment()}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label='Start'
                  renderInput={(params) => (
                    <TextField {...params} error={!!errors?.start} />
                  )}
                />
              )}
            />
          </Box>
          <Box px={1} sx={{ width: '20%' }}>
            <Controller
              name='end'
              control={control}
              defaultValue={moment().day(6)}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label='End'
                  renderInput={(params) => (
                    <TextField {...params} error={!!errors?.end} />
                  )}
                />
              )}
            />
          </Box>
          <Box px={1} sx={{ width: '20%' }}>
            <Controller
              name='employer'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Employer'
                  fullWidth
                  error={!!errors?.employer}
                />
              )}
            />
          </Box>
          <Box sx={{ width: '20%', display: 'flex', alignItems: 'center' }}>
            <Button
              variant='contained'
              color='secondary'
              size='large'
              type='submit'
              startIcon={<SearchIcon />}
            >
              {loading ? 'Searching...' : 'Search'}
            </Button>
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default SearchLaborLogForm;
