import {
  SET_CURRENT_USER,
  SET_TOKENS,
  USER_LOADING,
  CURRENT_USER_RESET,
  UPDATE_CURRENT_USER,
  TOKENS_RESET,
} from './types';

const isEmpty = require('is-empty');
const initialState = {
  isAuthenticated: false,
  user: {},
  tokens: {},
  loading: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
        loading: false,
      };
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        user: {
          ...action.payload,
        },
      };
    case SET_TOKENS:
      return {
        ...state,
        tokens: action.payload,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CURRENT_USER_RESET:
      return {
        ...state,
        user: {},
      };
    case TOKENS_RESET:
      return {
        ...state,
        tokens: {},
      };
    default:
      return state;
  }
}
