import {
  SET_EMPLOYER,
  EMPLOYER_LOADING,
  EMPLOYER_RESET,
  SET_EMPLOYERS,
  EMPLOYER_LIST_LOADING,
  EMPLOYER_LIST_RESET,
  ADD_EMPLOYER_TO_LIST,
  UPDATE_EMPLOYER_IN_LIST,
  DELETE_EMPLOYER_FROM_LIST,
  SHOW_ADD_EDIT_EMPLOYER_FORM,
  SAVING_EMPLOYER_DATA,
} from './types';

export const setEmployer = (item) => {
  return {
    type: SET_EMPLOYER,
    payload: item,
  };
};

export const resetEmployer = () => {
  return {
    type: EMPLOYER_RESET,
  };
};

export const setEmployerLoading = (payload) => {
  return {
    type: EMPLOYER_LOADING,
    payload,
  };
};

export const setEmployerList = (list) => {
  return {
    type: SET_EMPLOYERS,
    payload: list,
  };
};

export const resetEmployerList = () => {
  return {
    type: EMPLOYER_LIST_RESET,
  };
};

export const setEmployerListLoading = (payload) => {
  return {
    type: EMPLOYER_LIST_LOADING,
    payload,
  };
};

export const addEmployerToList = (payload) => {
  return {
    type: ADD_EMPLOYER_TO_LIST,
    payload,
  };
};

export const deleteEmployerFromList = (payload) => {
  return {
    type: DELETE_EMPLOYER_FROM_LIST,
    payload,
  };
};

export const updateEmployerInList = (payload) => {
  return {
    type: UPDATE_EMPLOYER_IN_LIST,
    payload,
  };
};

export const showAddEditEmployerForm = (payload) => {
  return {
    type: SHOW_ADD_EDIT_EMPLOYER_FORM,
    payload,
  };
};

export const savingEmployerData = (payload) => {
  return {
    type: SAVING_EMPLOYER_DATA,
    payload,
  };
};
