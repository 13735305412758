import {
  SET_USER,
  USER_LOADING,
  USER_RESET,
  SET_USERS,
  USER_LIST_LOADING,
  USER_LIST_RESET,
  ADD_USER_TO_LIST,
  UPDATE_USER_IN_LIST,
  DELETE_USER_FROM_LIST,
  SHOW_ADD_EDIT_USER_FORM,
  SAVING_USER_DATA,
} from './types';

export const setUser = (item) => {
  return {
    type: SET_USER,
    payload: item,
  };
};

export const resetUser = () => {
  return {
    type: USER_RESET,
  };
};

export const setUserLoading = (payload) => {
  return {
    type: USER_LOADING,
    payload,
  };
};

export const setUserList = (list) => {
  return {
    type: SET_USERS,
    payload: list,
  };
};

export const resetUserList = () => {
  return {
    type: USER_LIST_RESET,
  };
};

export const setUserListLoading = (payload) => {
  return {
    type: USER_LIST_LOADING,
    payload,
  };
};

export const addUserToList = (payload) => {
  return {
    type: ADD_USER_TO_LIST,
    payload,
  };
};

export const deleteUserFromList = (payload) => {
  return {
    type: DELETE_USER_FROM_LIST,
    payload,
  };
};

export const updateUserInList = (payload) => {
  return {
    type: UPDATE_USER_IN_LIST,
    payload,
  };
};

export const showAddEditUserForm = (payload) => {
  return {
    type: SHOW_ADD_EDIT_USER_FORM,
    payload,
  };
};

export const savingUserData = (payload) => {
  return {
    type: SAVING_USER_DATA,
    payload,
  };
};
