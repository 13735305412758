export const SET_PALLET_TYPE = 'SET_PALLET_TYPE';
export const PALLET_TYPE_LOADING = 'PALLET_TYPE_LOADING';
export const PALLET_TYPE_RESET = 'PALLET_TYPE_RESET';
export const SET_PALLET_TYPES = 'SET_PALLET_TYPES';
export const PALLET_TYPE_LIST_LOADING = 'PALLET_TYPE_LIST_LOADING';
export const PALLET_TYPE_LIST_RESET = 'PALLET_TYPE_LIST_RESET';
export const ADD_PALLET_TYPE_TO_LIST = 'ADD_PALLET_TYPE_TO_LIST';
export const UPDATE_PALLET_TYPE_IN_LIST = 'UPDATE_PALLET_TYPE_IN_LIST';
export const DELETE_PALLET_TYPE_FROM_LIST = 'DELETE_PALLET_TYPE_FROM_LIST';
export const SHOW_ADD_EDIT_PALLET_TYPE_FORM = 'SHOW_ADD_EDIT_PALLET_TYPE_FORM';
export const SAVING_PALLET_TYPE_DATA = 'SAVING_PALLET_TYPE_DATA';
