import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setEmployerList,
  resetEmployerList,
  setEmployerListLoading,
} from './actions';

export const loadEmployerList = () => (dispatch, getState, api) => {
  dispatch(setEmployerListLoading(true));
  axios
    .get(`${api}/list/employers`)
    .then((res) => {
      dispatch(resetEmployerList());
      dispatch(setEmployerList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setEmployerListLoading(false));
    });
};
