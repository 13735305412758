import {
  SET_PRODUCTION_LINE_ITEM,
  PRODUCTION_LINE_LOADING,
  PRODUCTION_LINE_RESET,
  SET_PRODUCTION_WORK_ORDER_VIEW_LIST,
  PRODUCTION_WORK_ORDER_VIEW_LIST_LOADING,
  PRODUCTION_WORK_ORDER_VIEW_LIST_RESET,
  SET_PRODUCTION_COMMODITY_VIEW_LIST,
  PRODUCTION_COMMODITY_VIEW_LIST_LOADING,
  PRODUCTION_COMMODITY_VIEW_LIST_RESET,
  SET_PRODUCTION_MACHINE_VIEW_LIST,
  PRODUCTION_MACHINE_VIEW_LIST_LOADING,
  PRODUCTION_MACHINE_VIEW_LIST_RESET,
  SET_PRODUCTION_TABLE_VIEW_LIST,
  PRODUCTION_TABLE_VIEW_LIST_LOADING,
  PRODUCTION_TABLE_VIEW_LIST_RESET,
  SET_PRODUCTION_WIP_VIEW,
  PRODUCTION_WIP_VIEW_LOADING,
  PRODUCTION_WIP_VIEW_RESET,
  SET_PRODUCTION_LINE_PALLET,
  PRODUCTION_LINE_PALLET_LOADING,
  PRODUCTION_LINE_PALLET_RESET,
  SHOW_PALLET_ADD_SCAN,
  ADD_PALLET_TO_PRODUCTION_LINE,
  ADD_PHOTO_TO_PRODUCTION_LINE,
} from './types';
const initialState = {
  work_order_view_list: [],
  work_order_view_list_loading: false,
  commodity_view_list: [],
  commodity_view_list_loading: false,
  machine_view_list: [],
  machine_view_list_loading: false,
  table_view_list: [],
  table_view_list_loading: false,
  wip_view: {},
  wip_view_loading: false,
  item: {},
  pallet: {},
  palletLoading: false,
  showPalletAddScan: false,
  showAddPalletsToProductionLineFromWorkOrder: false,
  loading: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCTION_LINE_ITEM:
      return {
        ...state,
        item: action.payload,
      };
    case PRODUCTION_LINE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case PRODUCTION_LINE_RESET:
      return {
        ...state,
        item: {},
      };
    case SET_PRODUCTION_WORK_ORDER_VIEW_LIST:
      return {
        ...state,
        work_order_view_list: [...action.payload],
      };
    case PRODUCTION_WORK_ORDER_VIEW_LIST_LOADING:
      return {
        ...state,
        work_order_view_list_loading: action.payload,
      };
    case PRODUCTION_WORK_ORDER_VIEW_LIST_RESET:
      return {
        ...state,
        work_order_view_list: [],
      };
    case SET_PRODUCTION_COMMODITY_VIEW_LIST:
      return {
        ...state,
        commodity_view_list: [...action.payload],
      };
    case PRODUCTION_COMMODITY_VIEW_LIST_LOADING:
      return {
        ...state,
        commodity_view_list_loading: action.payload,
      };
    case PRODUCTION_COMMODITY_VIEW_LIST_RESET:
      return {
        ...state,
        commodity_view_list: [],
      };
    case SET_PRODUCTION_MACHINE_VIEW_LIST:
      return {
        ...state,
        machine_view_list: [...action.payload],
      };
    case PRODUCTION_MACHINE_VIEW_LIST_LOADING:
      return {
        ...state,
        machine_view_list_loading: action.payload,
      };
    case PRODUCTION_MACHINE_VIEW_LIST_RESET:
      return {
        ...state,
        machine_view_list: [],
      };
    case SET_PRODUCTION_TABLE_VIEW_LIST:
      return {
        ...state,
        table_view_list: [...action.payload],
      };
    case PRODUCTION_TABLE_VIEW_LIST_LOADING:
      return {
        ...state,
        table_view_list_loading: action.payload,
      };
    case PRODUCTION_TABLE_VIEW_LIST_RESET:
      return {
        ...state,
        table_view_list: [],
      };
    case SET_PRODUCTION_WIP_VIEW:
      return {
        ...state,
        wip_view: action.payload,
      };
    case PRODUCTION_WIP_VIEW_LOADING:
      return {
        ...state,
        wip_view_loading: action.payload,
      };
    case PRODUCTION_WIP_VIEW_RESET:
      return {
        ...state,
        wip_view: {},
      };
    case SET_PRODUCTION_LINE_PALLET:
      return {
        ...state,
        pallet: action.payload,
      };
    case PRODUCTION_LINE_PALLET_LOADING:
      return {
        ...state,
        palletLoading: action.payload,
      };
    case PRODUCTION_LINE_PALLET_RESET:
      return {
        ...state,
        pallet: {},
      };
    case SHOW_PALLET_ADD_SCAN:
      return {
        ...state,
        showPalletAddScan: action.payload,
      };
    case ADD_PALLET_TO_PRODUCTION_LINE:
      return {
        ...state,
        item: {
          ...state.item,
          pallets: [...state.item.pallets, action.payload],
        },
      };
    case ADD_PHOTO_TO_PRODUCTION_LINE:
      return {
        ...state,
        item: {
          ...state.item,
          photos: [...state.item.photos, action.payload],
        },
      };
    default:
      return state;
  }
}
