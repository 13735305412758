import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Button,
  Checkbox,
  Paper,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ProductionLineStatus from './ProductionLineStatus';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CommodityIcon from '../../components/UI/icons/CommodityIcon';
import MachineIcon from '../../components/UI/icons/MachineIcon';
import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import grey from '@mui/material/colors/grey';
import isEmpty from 'is-empty';
import Empty from '../../components/UI/Empty';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  newItem: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required('Required'),
});

const ProductionLineCheckList = ({
  productionLine,
  defaultChecklistItems,
  onSetupChecklistSave,
  onStartProductionLine,
  handleOnCancel,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });
  const [checkList, setCheckList] = useState(
    productionLine.setupCheckListItems
  );

  const handleToggleActive = (item) => {
    setCheckList(
      checkList.map((checkListItem) => {
        if (checkListItem.id === item.id) {
          return {
            ...checkListItem,
            active: !checkListItem.active,
          };
        }
        return checkListItem;
      })
    );
  };

  const handleAddDefaultList = () => {
    setCheckList(defaultChecklistItems);
  };

  const handleAddNewItem = (data) => {
    setCheckList([
      ...checkList,
      { active: false, name: data.newItem, id: uuidv4() },
    ]);
    reset();
  };

  const handleClickSave = (id) => {
    onSetupChecklistSave(id, checkList);
  };

  return (
    <>
      <AppBar color='primary' elevation={0} sx={{ position: 'relative' }}>
        <Toolbar variant='dense'>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleOnCancel}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            ePack - Production Line Setup Checklist
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        my={0.5}
        mx={1.5}
        mt={1.5}
        display='flex'
        flexDirection='column'
        flexGrow={1}
      >
        <Box>
          <Paper elevation={0} variant='outlined'>
            <Box display='flex' py={2} justifyContent='space-evenly'>
              <Box display='flex' alignItems='center'>
                <MachineIcon
                  sx={{
                    color: grey['500'],
                    width: '18px',
                    height: '18px',
                  }}
                />
                <Typography
                  component='span'
                  sx={{
                    marginLeft: 1,
                    fontSize: '1.3rem',
                  }}
                >
                  {productionLine.machine.name}
                </Typography>
              </Box>
              <Box display='flex' alignItems='center' ml={1}>
                <ListAltIcon
                  sx={{
                    color: grey['500'],
                    width: '18px',
                    height: '18px',
                  }}
                />
                <Typography
                  component='span'
                  sx={{
                    marginLeft: 1,
                    fontSize: '1.3rem',
                  }}
                >
                  {productionLine.workOrder.code}
                </Typography>
              </Box>
              <Box
                display='flex'
                alignItems='center'
                sx={{
                  marginLeft: '1',
                  fontSize: '1.3rem',
                }}
              >
                <CommodityIcon
                  sx={{
                    color: grey['500'],
                    width: '18px',
                    height: '18px',
                  }}
                />
                <Typography
                  component='span'
                  sx={{
                    marginLeft: 1,
                    fontSize: '1.3rem',
                  }}
                >
                  {productionLine.workOrder.commodity.name}
                </Typography>
              </Box>
              <Box
                display='flex'
                alignItems='center'
                sx={{
                  marginLeft: '1',
                  fontSize: '1.3rem',
                }}
              >
                <Typography
                  component='span'
                  sx={{
                    marginLeft: 1,
                    fontSize: '1.3rem',
                  }}
                >
                  <ProductionLineStatus status={productionLine.status} />
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
        {isEmpty(productionLine.setupCheckListItems) && (
          <Box display='flex' justifyContent='flex-end' my={1}>
            <Button
              variant='outlined'
              color='primary'
              startIcon={<SystemUpdateAltOutlinedIcon />}
              onClick={handleAddDefaultList}
            >
              Add Default List
            </Button>
          </Box>
        )}

        <Box display='flex' px={1} my={0.5} mt={2} justifyContent='center'>
          {!isEmpty(checkList) ? (
            <Paper elevation={0} variant='outlined'>
              <List>
                {checkList.map((item, index) => {
                  const labelId = `checkbox-list-label-${item.name}`;

                  return (
                    <ListItem
                      key={index}
                      role={undefined}
                      dense
                      button
                      onClick={() => handleToggleActive(item)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge='start'
                          checked={item.active}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={item.name} />
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
          ) : (
            <Empty description='No setup items' />
          )}
        </Box>
        <Box display='flex' flexGrow={1} my={3} justifyContent='center'>
          <form onSubmit={handleSubmit(handleAddNewItem)}>
            <Box display='flex' justifyContent='center'>
              <Controller
                name='newItem'
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label='New Checklist Item'
                    error={!!errors?.newItem}
                    helperText={errors?.newItem?.message}
                  />
                )}
              />
            </Box>
            <Box display='flex' justifyContent='center' my={1}>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                startIcon={<DoneIcon />}
              >
                Add Item
              </Button>
            </Box>
          </form>
        </Box>
        <Box component='div' mx={1} my={0.5} display='flex'>
          <Box my={1.5} flexGrow={1}>
            <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={() => handleClickSave(productionLine.id)}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Box>
          <Box my={1.5}>
            <Button
              variant='contained'
              color='secondary'
              size='large'
              startIcon={<PlayArrowIcon />}
              onClick={() => onStartProductionLine(productionLine)}
              disabled={
                checkList.filter((item) => item.active === false).length > 0 ||
                productionLine.status === 'IN_PROGRESS' ||
                productionLine.status === 'SANITATION'
              }
            >
              Start Production
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProductionLineCheckList;
