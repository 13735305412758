import React from 'react';
import LazyLoad from 'react-lazyload';
import EmployeeAvatar from './EmployeeAvatar';

let width = 0;
let height = 0;

const EmployeePhoto = ({ photo, size = 'medium' }) => {
  if (size === 'small') {
    width = 65;
    height = 65;
  } else if (size === 'medium') {
    width = 100;
    height = 100;
  } else if (size === 'large') {
    width = 150;
    height = 150;
  }
  return (
    <>
      {photo ? (
        <LazyLoad height={height}>
          <img
            src={photo}
            alt="employee"
            width={width}
            height={height}
            style={{
              borderRadius: '50%',
            }}
          />
        </LazyLoad>
      ) : (
        <EmployeeAvatar
          width={width}
          height={height}
          style={{ borderRadius: '50%' }}
        />
      )}
    </>
  );
};

export default EmployeePhoto;
