import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserDetails } from '../../redux/company/users/services';
import { Box, Paper, Typography } from '@mui/material';
import UserSettingsForm from '../../components/auth/UserSettingsForm';

const UserSettings = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const usersState = useSelector((state) => state.users);

  const onSubmit = (data) => {
    dispatch(updateUserDetails(data));
  };

  return (
    <>
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              User Settings
            </Typography>
          </Box>
        </Box>
        <Box display='flex' justifyContent='center'>
          <Box sx={{ maxWidth: '600px' }}>
            <UserSettingsForm
              user={authState.user}
              handleOnSubmit={onSubmit}
              handleOnCancel={() => navigate('/')}
              handleOnChangePassword={() => navigate('/change_password')}
              savingData={usersState.savingData}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default UserSettings;
