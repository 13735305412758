import setAuthToken from '../../../utils/setAuthToken';
import {
  SET_CURRENT_USER,
  SET_TOKENS,
  USER_LOADING,
  CURRENT_USER_RESET,
  TOKENS_RESET,
  UPDATE_CURRENT_USER,
} from './types';

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const setTokens = (tokens) => {
  return {
    type: SET_TOKENS,
    payload: tokens,
  };
};

export const setUserLoading = (payload) => {
  return {
    type: USER_LOADING,
    payload,
  };
};

export const updateCurrentUser = (payload) => {
  return {
    type: UPDATE_CURRENT_USER,
    payload,
  };
};

export const resetCurrentUser = () => {
  return {
    type: CURRENT_USER_RESET,
  };
};

export const resetTokens = () => {
  return {
    type: TOKENS_RESET,
  };
};

// Log user out
export const logoutUser = (location) => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem('jwt_Tokens');
  localStorage.removeItem('user');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(resetCurrentUser());
  dispatch(resetTokens());

  /// Redirect to login page only if is not on the login page
  if (!location?.pathname.includes('/auth/login')) {
    window.location.href = '/auth/login';
  }
};
