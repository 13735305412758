import {
  SET_LABOR_UNALLOCATED_LIST,
  LABOR_UNALLOCATED_LIST_LOADING,
  LABOR_UNALLOCATED_LIST_RESET,
  SET_LABOR_UNALLOCATED_NAME_FILTER,
  SAVING_LABOR_UNALLOCATED_DATA,
  SHOW_ASSIGN_JOB_FORM,
  TOGGLE_SELECT_LABOR_UNALLOCATED_IN_LIST,
  SET_SELECT_ALL_LABOR_UNALLOCATED_IN_LIST,
} from './types';
const initialState = {
  list: {},
  loading: false,
  filter: {
    name: '',
  },
  showAssignJobForm: false,
  savingData: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LABOR_UNALLOCATED_LIST:
      return {
        ...state,
        list: {
          ...action.payload,
          docs: action.payload.docs.map((row) => {
            return { ...row, selected: false };
          }),
        },
      };
    case LABOR_UNALLOCATED_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LABOR_UNALLOCATED_LIST_RESET:
      return {
        ...state,
        list: {},
      };
    case SET_LABOR_UNALLOCATED_NAME_FILTER:
      return {
        ...state,
        filter: {
          name: action.payload,
        },
      };
    case SHOW_ASSIGN_JOB_FORM:
      return {
        ...state,
        showAssignJobForm: action.payload,
      };
    case SAVING_LABOR_UNALLOCATED_DATA:
      return {
        ...state,
        savingData: action.payload,
      };
    case TOGGLE_SELECT_LABOR_UNALLOCATED_IN_LIST:
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          docs: state.list.docs.map((row) => {
            if (row.id === action.payload.id) {
              return { ...action.payload, selected: !row.selected };
            }
            return row;
          }),
        },
      };
    case SET_SELECT_ALL_LABOR_UNALLOCATED_IN_LIST:
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          docs: state.list.docs.map((row) => {
            return { ...row, selected: action.payload };
          }),
        },
      };

    default:
      return state;
  }
}
