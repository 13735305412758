import {
  SET_MACHINE_PROFILE,
  MACHINE_PROFILE_LOADING,
  MACHINE_PROFILE_RESET,
  SET_MACHINE_PROFILES,
  MACHINE_PROFILE_LIST_LOADING,
  MACHINE_PROFILE_LIST_RESET,
  ADD_MACHINE_PROFILE_TO_LIST,
  UPDATE_MACHINE_PROFILE_IN_LIST,
  DELETE_MACHINE_PROFILE_FROM_LIST,
  SAVING_MACHINE_PROFILE_DATA,
  SET_MACHINE_PROFILE_FILTERS,
  RESET_MACHINE_PROFILE_FILTERS,
  SHOW_DUPLICATE_MACHINE_PROFILE_FORM,
} from './types';

export const setMachineProfile = (item) => {
  return {
    type: SET_MACHINE_PROFILE,
    payload: item,
  };
};

export const resetMachineProfile = () => {
  return {
    type: MACHINE_PROFILE_RESET,
  };
};

export const setMachineProfileLoading = (payload) => {
  return {
    type: MACHINE_PROFILE_LOADING,
    payload,
  };
};

export const setMachineProfileList = (list) => {
  return {
    type: SET_MACHINE_PROFILES,
    payload: list,
  };
};

export const resetMachineProfileList = () => {
  return {
    type: MACHINE_PROFILE_LIST_RESET,
  };
};

export const setMachineProfileListLoading = (payload) => {
  return {
    type: MACHINE_PROFILE_LIST_LOADING,
    payload,
  };
};

export const addMachineProfileToList = (payload) => {
  return {
    type: ADD_MACHINE_PROFILE_TO_LIST,
    payload,
  };
};

export const deleteMachineProfileFromList = (payload) => {
  return {
    type: DELETE_MACHINE_PROFILE_FROM_LIST,
    payload,
  };
};

export const updateMachineProfileInList = (payload) => {
  return {
    type: UPDATE_MACHINE_PROFILE_IN_LIST,
    payload,
  };
};

export const savingMachineProfileData = (payload) => {
  return {
    type: SAVING_MACHINE_PROFILE_DATA,
    payload,
  };
};

export const setMachineProfileFilters = (payload) => {
  return {
    type: SET_MACHINE_PROFILE_FILTERS,
    payload,
  };
};

export const resetMachineProfileFilters = () => {
  return {
    type: RESET_MACHINE_PROFILE_FILTERS,
  };
};

export const showDuplicateMachineProfileForm = (payload) => {
  return {
    type: SHOW_DUPLICATE_MACHINE_PROFILE_FORM,
    payload,
  };
};
