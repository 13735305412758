import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > svg': {
      marginTop: theme.spacing(),
      marginRight: theme.spacing(0.5),
    },
  },
}));

const ClockIcon = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SvgIcon
        width="24"
        height="24"
        viewBox="0 0 58 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M28.3213 56.6425C43.9627 56.6425 56.6425 43.9627 56.6425 28.3213C56.6425 12.6799 43.9627 0 28.3213 0C12.6799 0 0 12.6799 0 28.3213C0 43.9627 12.6799 56.6425 28.3213 56.6425Z"
          fill="#ECF0F1"
        />
        <path
          d="M28.3213 3.90638C41.7836 3.90638 52.7362 14.8589 52.7362 28.3213C52.7362 41.7836 41.7836 52.7362 28.3213 52.7362C14.8589 52.7362 3.90638 41.7836 3.90638 28.3213C3.90638 14.8589 14.8589 3.90638 28.3213 3.90638ZM28.3213 0C12.6801 0 0 12.6801 0 28.3213C0 43.9624 12.6801 56.6425 28.3213 56.6425C43.9624 56.6425 56.6425 43.9624 56.6425 28.3213C56.6425 12.6801 43.9624 0 28.3213 0Z"
          fill="#545E73"
        />
        <path
          d="M28.3213 5.74042C27.7822 5.74042 27.3447 6.17696 27.3447 6.71701V7.69361C27.3447 8.23367 27.7822 8.6702 28.3213 8.6702C28.8604 8.6702 29.2979 8.23367 29.2979 7.69361V6.71701C29.2979 6.17793 28.8604 5.74042 28.3213 5.74042Z"
          fill="#8697CB"
        />
        <path
          d="M28.3213 47.734C27.7822 47.734 27.3447 48.1705 27.3447 48.7106V49.6872C27.3447 50.2273 27.7822 50.6638 28.3213 50.6638C28.8604 50.6638 29.2979 50.2273 29.2979 49.6872V48.7106C29.2979 48.1715 28.8604 47.734 28.3213 47.734Z"
          fill="#8697CB"
        />
        <path
          d="M49.8067 27.2255H48.8301C48.291 27.2255 47.8535 27.6621 47.8535 28.2021C47.8535 28.7422 48.291 29.1787 48.8301 29.1787H49.8067C50.3458 29.1787 50.7833 28.7422 50.7833 28.2021C50.7833 27.6621 50.3458 27.2255 49.8067 27.2255Z"
          fill="#8697CB"
        />
        <path
          d="M7.81305 27.2255H6.83646C6.29738 27.2255 5.85986 27.6621 5.85986 28.2021C5.85986 28.7422 6.29738 29.1787 6.83646 29.1787H7.81305C8.35213 29.1787 8.78965 28.7422 8.78965 28.2021C8.78965 27.6621 8.35213 27.2255 7.81305 27.2255Z"
          fill="#8697CB"
        />
        <path
          d="M42.823 12.3197L42.1326 13.0102C41.7507 13.392 41.7507 14.0092 42.1326 14.3911C42.323 14.5815 42.573 14.6772 42.823 14.6772C43.073 14.6772 43.323 14.5815 43.5135 14.3911L44.2039 13.7006C44.5858 13.3188 44.5858 12.7016 44.2039 12.3197C43.8221 11.9379 43.2049 11.9379 42.823 12.3197Z"
          fill="#8697CB"
        />
        <path
          d="M13.1297 42.0141L12.4392 42.7046C12.0574 43.0864 12.0574 43.7036 12.4392 44.0855C12.6297 44.2759 12.8797 44.3716 13.1297 44.3716C13.3797 44.3716 13.6297 44.2759 13.8201 44.0855L14.5106 43.395C14.8924 43.0132 14.8924 42.396 14.5106 42.0141C14.1287 41.6323 13.5105 41.6323 13.1297 42.0141Z"
          fill="#8697CB"
        />
        <path
          d="M43.5135 42.0141C43.1316 41.6323 42.5144 41.6323 42.1326 42.0141C41.7507 42.396 41.7507 43.0132 42.1326 43.395L42.823 44.0855C43.0135 44.2759 43.2635 44.3716 43.5135 44.3716C43.7635 44.3716 44.0135 44.2759 44.2039 44.0855C44.5858 43.7036 44.5858 43.0864 44.2039 42.7046L43.5135 42.0141Z"
          fill="#8697CB"
        />
        <path
          d="M13.8201 12.3197C13.4383 11.9379 12.8211 11.9379 12.4392 12.3197C12.0574 12.7016 12.0574 13.3188 12.4392 13.7006L13.1297 14.3911C13.3201 14.5815 13.5701 14.6772 13.8201 14.6772C14.0701 14.6772 14.3201 14.5815 14.5106 14.3911C14.8924 14.0092 14.8924 13.392 14.5106 13.0102L13.8201 12.3197Z"
          fill="#8697CB"
        />
        <path
          d="M25.3914 29.1797H17.5786C17.0396 29.1797 16.6021 28.7432 16.6021 28.2031C16.6021 27.663 17.0396 27.2265 17.5786 27.2265H25.3914C25.9305 27.2265 26.368 27.663 26.368 28.2031C26.368 28.7432 25.9305 29.1797 25.3914 29.1797Z"
          fill="#545E73"
        />
        <path
          d="M28.3213 26.2499C27.7822 26.2499 27.3447 25.8134 27.3447 25.2733V14.5308C27.3447 13.9907 27.7822 13.5542 28.3213 13.5542C28.8604 13.5542 29.2979 13.9907 29.2979 14.5308V25.2733C29.2979 25.8124 28.8604 26.2499 28.3213 26.2499Z"
          fill="#545E73"
        />
        <path
          d="M28.3214 32.1095C26.1671 32.1095 24.415 30.3575 24.415 28.2031C24.415 26.0488 26.1671 24.2968 28.3214 24.2968C30.4758 24.2968 32.2278 26.0488 32.2278 28.2031C32.2278 30.3575 30.4758 32.1095 28.3214 32.1095ZM28.3214 26.2499C27.2442 26.2499 26.3682 27.1259 26.3682 28.2031C26.3682 29.2803 27.2442 30.1563 28.3214 30.1563C29.3986 30.1563 30.2746 29.2803 30.2746 28.2031C30.2746 27.1259 29.3986 26.2499 28.3214 26.2499Z"
          fill="#545E73"
        />
        <path
          d="M46.2812 56.6425C52.7535 56.6425 58.0003 51.3957 58.0003 44.9234C58.0003 38.4511 52.7535 33.2042 46.2812 33.2042C39.8089 33.2042 34.562 38.4511 34.562 44.9234C34.562 51.3957 39.8089 56.6425 46.2812 56.6425Z"
          fill="#26B999"
        />
        <path
          d="M52.6981 39.2387C52.2538 38.9301 51.6463 39.0394 51.3387 39.4828L45.8737 47.3346L42.0171 44.1685C41.6011 43.8257 40.9849 43.8873 40.643 44.3043C40.3003 44.7213 40.3608 45.3365 40.7788 45.6783L45.4528 49.5154C45.6286 49.6589 45.8473 49.7371 46.0719 49.7371C46.1159 49.7371 46.1608 49.7341 46.2057 49.7283C46.4753 49.6902 46.7175 49.5427 46.8737 49.3191L52.9423 40.5991C53.2499 40.1557 53.1405 39.5463 52.6981 39.2387Z"
          fill="white"
        />
      </SvgIcon>
    </div>
  );
};

export default ClockIcon;
