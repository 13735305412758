import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getJobTypeList = createSelector(
  (state) => state.job_types.list.docs,
  (jobTypesList) => {
    if (isEmpty(jobTypesList)) return [];
    return jobTypesList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
