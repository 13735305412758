import axios from 'axios';
import { addNotification } from '../notifications/actions';
import {
  setSubscriptionSummary,
  setSubscriptionDetails,
  setSubscriptionLoading,
} from './actions';

export const loadSubscriptionSummary = (id) => (dispatch, getState, api) => {
  dispatch(setSubscriptionLoading(true));
  axios
    .get(`${api}/subscription/summary/${id}`)
    .then((res) => {
      dispatch(setSubscriptionSummary(res.data));
    })
    .catch((err) => {
      console.error(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setSubscriptionLoading(false));
    });
};

export const loadSubscriptionDetails = (id) => (dispatch, getState, api) => {
  dispatch(setSubscriptionLoading(true));
  axios
    .get(`${api}/subscription/details/${id}`)
    .then((res) => {
      dispatch(setSubscriptionDetails(res.data));
    })
    .catch((err) => {
      console.error(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setSubscriptionLoading(false));
    });
};

export const updateSubscription = (id, data) => (dispatch, getState, api) => {
  dispatch(setSubscriptionLoading(true));
  axios
    .put(`${api}/subscription/${id}`, data)
    .then((res) => {
      const notification = {
        message: '👍🏻 Subcription updated successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.error(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setSubscriptionLoading(false));
    });
};
