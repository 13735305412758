import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  FormHelperText,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';

const Input = styled('input')({
  display: 'none',
});

const ValidationSchema = Yup.object().shape({
  file: Yup.string().required('Please select a file to import'),
});

const WorkOrdersImportForm = ({
  handleOnSubmit,
  handleOnCancel,
  importingData,
}) => {
  const {
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });
  const [fileName, setFileName] = useState('');

  const onChangeHandlerFile = async (event) => {
    try {
      const file = event.target.files[0];
      setValue('file', file);
      setFileName(file.name);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box display='flex' my={1.5} flexGrow={1} alignItems='center'>
              <Typography
                color='primary'
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'ePack - Work Orders Import'}
              </Typography>
            </Box>
          </Box>
          <Box my={1.5}></Box>
          <Typography variant='body1' color='textSecondary'>
            CSV import file instructions:
          </Typography>
          <ul>
            <li>Column headers are required on the first row.</li>
            <li>Columns:</li>
            <ul>
              <li>
                WORKORDER: alphanumeric <strong>(required)</strong>
              </li>
              <li>
                COMMODITY: alphanumeric <strong>(required)</strong>
              </li>
              <li>CUSTOMER: alphanumeric</li>
              <li>PALLET: alphanumeric</li>
              <li>LOT: alphanumeric</li>
              <li>DESCRIPTION: alphanumeric</li>
              <li>ITEM: alphanumeric</li>
              <li>CASES: number</li>
            </ul>
          </ul>
          <Divider />
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={4}>
                <label htmlFor='contained-button-file'>
                  <Input
                    accept='.csv'
                    id='contained-button-file'
                    type='file'
                    name='file'
                    onChange={onChangeHandlerFile}
                  />
                  <Button
                    variant='outlined'
                    component='span'
                    disabled={importingData}
                  >
                    Select File
                  </Button>
                </label>
                <FormHelperText>{errors?.file?.message}</FormHelperText>
              </Grid>
              {fileName && (
                <Grid item xs={8}>
                  <Typography variant='body2'>
                    {`Selected File: ${fileName}`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
          <Box component='div' mx={1} my={0.5} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
                startIcon={<SaveIcon />}
              >
                {importingData ? 'Importing...' : 'Import'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                disabled={importingData}
                startIcon={<UndoIcon />}
                onClick={() => {
                  reset();
                  handleOnCancel();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default WorkOrdersImportForm;
