import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { startAll, stopAll } from '../../redux/etime/labor/services';
import {
  Box,
  Button,
  Divider,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

const LaborClipboard = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('unallocated');
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('unallocated')) {
      setActiveTab('unallocated');
    } else if (location.pathname.includes('workorder')) {
      setActiveTab('workorder');
    } else if (location.pathname.includes('job')) {
      setActiveTab('job');
    } else if (location.pathname.includes('machine')) {
      setActiveTab('machine');
    } else if (location.pathname.includes('summary')) {
      setActiveTab('summary');
    }
  }, [location]);

  const handleChangeTab = (event, tab) => {
    setActiveTab(tab);
  };

  const handleClickStartAll = () => {
    dispatch(startAll(location));
  };

  const handleClickStopAll = () => {
    dispatch(stopAll(location));
  };

  return (
    <Paper elevation={0}>
      <Box component='div' display='flex' mx={1}>
        <Box my={1} ml={1.5} flexGrow={1}>
          <Typography variant='h6' color='primary'>
            eTime - Labor Clipboard
          </Typography>
        </Box>
        <Box my={0.5}>
          <Tooltip title='Start All'>
            <Button
              color='primary'
              startIcon={<PlayArrowIcon />}
              sx={{ marginRight: '0.5rem', color: 'success.main' }}
              onClick={handleClickStartAll}
            >
              Start All
            </Button>
          </Tooltip>
          <Tooltip title='Stop All'>
            <Button
              color='primary'
              startIcon={<StopIcon />}
              sx={{ marginRight: '0.5rem', color: 'error.main' }}
              onClick={handleClickStopAll}
            >
              Stop All
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Divider variant='middle' light />

      <Box sx={{ width: '100%' }}>
        <Tabs value={activeTab} variant='fullWidth' onChange={handleChangeTab}>
          <Tab
            component={Link}
            label='Unallocated'
            to='unallocated'
            value='unallocated'
          />
          <Tab
            component={Link}
            label='WorkOrder View'
            to='workorder'
            value='workorder'
          />
          <Tab component={Link} label='Job View' to='job' value='job' />
          <Tab
            component={Link}
            label='Machine View'
            to='machine'
            value='machine'
          />
          <Tab component={Link} label='Summary' to='summary' value='summary' />
        </Tabs>
        <Outlet />
      </Box>
    </Paper>
  );
};

export default LaborClipboard;
