import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getPalletTypeList = createSelector(
  (state) => state.list_pallet_type.list,
  (palletTypeList) => {
    if (isEmpty(palletTypeList)) return [];
    return palletTypeList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
