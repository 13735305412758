import {
  SET_SUBSCRIPTION_LOADING,
  SET_SUBSCRIPTION_SUMMARY,
  SET_SUBSCRIPTION_DETAILS,
} from './types';

export const setSubscriptionLoading = (payload) => {
  return {
    type: SET_SUBSCRIPTION_LOADING,
    payload,
  };
};

export const setSubscriptionSummary = (payload) => {
  return {
    type: SET_SUBSCRIPTION_SUMMARY,
    payload,
  };
};

export const setSubscriptionDetails = (payload) => {
  return {
    type: SET_SUBSCRIPTION_DETAILS,
    payload,
  };
};
