import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

const SubscriptionInactiveNotification = ({ open, handleOnLogout }) => {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        return false;
      }}
      disableEscapeKeyDown
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle>
        <Typography variant='h5'>Important Notice.</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1'>
          Your account is not active because you have not purchased a
          subscription.
        </Typography>
        <Typography variant='body1'>
          Please contact your company administrator for more details.
        </Typography>
        <Button variant='text' onClick={handleOnLogout}>
          Logout
        </Button>
      </DialogContent>
    </Dialog>
  );
};

SubscriptionInactiveNotification.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOnLogout: PropTypes.func.isRequired,
};

export default SubscriptionInactiveNotification;
