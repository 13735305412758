import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setMachineProfile,
  resetMachineProfile,
  resetMachineProfileList,
  setMachineProfileFilters,
  showDuplicateMachineProfileForm,
} from '../../redux/eplan/profile-machine/actions';
import {
  loadMachineProfiles,
  deleteMachineProfile,
  duplicateMachineProfile,
} from '../../redux/eplan/profile-machine/services';
import {
  selectFilteredMachineProfiles,
  selectExistingMachineProfile,
} from '../../redux/eplan/profile-machine/selectors';
import { loadMachineList } from '../../redux/list/machines/services';
import { resetMachineList } from '../../redux/list/machines/actions';
import { getMachineList } from '../../redux/list/machines/selectors';
import { loadCommodityList } from '../../redux/list/commodities/services';
import { resetCommodityList } from '../../redux/list/commodities/actions';
import { getCommodityList } from '../../redux/list/commodities/selectors';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  Divider,
} from '@mui/material';
import Loading from '../../components/UI/Loading';
import MachineProfileFilterForm from '../../components/eplan/MachineProfileFilterForm';
import Empty from '../../components/UI/Empty';
import MachineProfilesTable from '../../components/eplan/MachineProfilesTable';
import MachineProfileDuplicateForm from '../../components/eplan/MachineProfileDuplicateForm';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import SyncIcon from '@mui/icons-material/Sync';

const isEmpty = require('is-empty');

const MachineProfiles = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const machineProfilesState = useSelector((state) => state.machine_profile);
  const machineProfileState = useSelector(
    (state) => state.machine_profile.item
  );
  const MachineProfilesList = useSelector(selectFilteredMachineProfiles);
  const ExistingMachineProfile = useSelector(selectExistingMachineProfile);
  const MachineList = useSelector(getMachineList);
  const CommodityList = useSelector(getCommodityList);
  const [openRemoveMachineProfileDialog, setOpenRemoveMachineProfileDialog] =
    useState(false);

  useEffect(() => {
    dispatch(loadMachineProfiles());
    dispatch(loadMachineList());
    dispatch(loadCommodityList());
    return () => {
      dispatch(resetMachineProfileList());
      dispatch(resetMachineList());
      dispatch(resetCommodityList());
    };
  }, [dispatch]);

  const handleClickAdd = (machine, commodity, itemDescription) => {
    navigate('/eplan/machine_profiles/create', {
      state: {
        machine: machine,
        commodity: commodity,
        itemDescription: itemDescription,
      },
    });
  };

  const handleClickEdit = (machineProfile) => {
    dispatch(setMachineProfile(machineProfile));
    navigate(`/eplan/machine_profiles/update/${machineProfile.id}`);
  };

  const handleOnFilter = (data) => {
    dispatch(setMachineProfileFilters(data));
  };

  const handleRefresh = () => {
    dispatch(loadMachineProfiles());
  };

  const handleClickRemove = (machineProfile) => {
    dispatch(setMachineProfile(machineProfile));
    setOpenRemoveMachineProfileDialog(true);
  };

  const onRemoveMachineProfile = () => {
    dispatch(deleteMachineProfile(machineProfileState.id));
    dispatch(resetMachineProfile());
    setOpenRemoveMachineProfileDialog(false);
  };

  const handleClickDuplicate = (machineProfile) => {
    dispatch(setMachineProfile(machineProfile));
    dispatch(showDuplicateMachineProfileForm(true));
  };

  const handleOnDuplicate = (data) => {
    dispatch(duplicateMachineProfile(machineProfileState.id, data));
  };

  return (
    <>
      <Loading loading={machineProfilesState.loading} />
      <Paper elevation={0}>
        <Box display='flex' mx={1} mt={1}>
          <Box display='flex' alignContent='center'>
            <IconButton aria-label='reload' onClick={handleRefresh}>
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              ePlan - Machine Profile
            </Typography>
          </Box>
        </Box>
        <Divider variant='middle' light />
        <Box mx={1} mt={1}>
          <MachineProfileFilterForm
            machines={MachineList}
            commodities={CommodityList}
            existingMachineProfile={ExistingMachineProfile}
            handleOnFilter={handleOnFilter}
            handleClickAdd={handleClickAdd}
          />
        </Box>

        <Box mx={1}>
          {!isEmpty(machineProfilesState.list) && (
            <MachineProfilesTable
              rows={MachineProfilesList}
              handleClickEdit={handleClickEdit}
              handleClickRemove={handleClickRemove}
              handleClickDuplicate={handleClickDuplicate}
            />
          )}
          {isEmpty(machineProfilesState.list) &&
            !machineProfilesState.loading && (
              <Empty description='There are no machine profiles to show' />
            )}
        </Box>
      </Paper>
      <ConfirmationDialog
        open={openRemoveMachineProfileDialog}
        handleNo={() => {
          setOpenRemoveMachineProfileDialog(false);
        }}
        handleYes={onRemoveMachineProfile}
        title='ePlan - Remove Machine Profile'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to remove machine profile for machine : ${machineProfileState?.machine?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <Dialog
        open={machineProfilesState.showDuplicateForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            dispatch(showDuplicateMachineProfileForm(false));
            dispatch(resetMachineProfile());
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <MachineProfileDuplicateForm
            machines={MachineList}
            commodities={CommodityList}
            handleOnDuplicate={handleOnDuplicate}
            handleOnCancel={() => {
              dispatch(showDuplicateMachineProfileForm(false));
              dispatch(resetMachineProfile());
            }}
            savingData={machineProfilesState.savingData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MachineProfiles;
