import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetMachineViewList } from '../../redux/etime/labor/machine-view/actions';
import {
  loadMachineViewList,
  startByMachine,
  stopByMachine,
  startLaborLog,
  stopLaborLog,
  changePosition,
  moveLaborLog,
  moveByMachine,
  walkOff,
} from '../../redux/etime/labor/machine-view/services';
import { getMachineViewList } from '../../redux/etime/labor/machine-view/selectors';
import { loadWorkOrderList } from '../../redux/list/work-orders/services';
import { resetListWorkOrderList } from '../../redux/list/work-orders/actions';
import { getListWorkOrderList } from '../../redux/list/work-orders/selectors';
import { loadMachineList } from '../../redux/list/machines/services';
import { resetMachineList } from '../../redux/list/machines/actions';
import { getMachineList } from '../../redux/list/machines/selectors';
import { loadJobTypeList } from '../../redux/list/job-types/services';
import { resetJobTypeList } from '../../redux/list/job-types/actions';
import { getJobTypeList } from '../../redux/list/job-types/selectors';
import { loadEmployeePositionList } from '../../redux/list/employee-positions/services';
import { resetEmployeePositionList } from '../../redux/list/employee-positions/actions';
import { getEmployeePositionList } from '../../redux/list/employee-positions/selectors';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import LaborMachineViewTileList from '../../components/etime/LaborMachineViewTileList';
import Empty from '../../components/UI/Empty';
import Loading from '../../components/UI/Loading';
import LaborLogPositionForm from '../../components/etime/LaborLogPositionForm';
import LaborLogMoveForm from '../../components/etime/LaborLogMoveForm';
import WorkOrderMoveForm from '../../components/etime/WorkOrderMoveForm';
import LaborLogWalkOffForm from '../../components/etime/LaborLogWalkOffForm';
const isEmpty = require('is-empty');

const LaborMachineView = () => {
  const dispatch = useDispatch();
  const [expandedMachineTiles, setExpandedMachineTiles] = useState([]);

  const [openChangeLaborLogPosition, setOpenChangeLaborLogPosition] =
    useState(false);
  const [openMoveLaborLog, setOpenMoveLaborLog] = useState(false);
  const [openMoveByMachine, setOpenMoveByMachine] = useState(false);
  const [openWalkOff, setOpenWalkOff] = useState(false);
  const [machine, setMachine] = useState(null);
  const [laborLog, setLaborLog] = useState(null);
  const machineViewState = useSelector((state) => state.labor_machine_view);
  const MachineViewList = useSelector(getMachineViewList);
  const WorkOrderList = useSelector(getListWorkOrderList);
  const MachineList = useSelector(getMachineList);
  const JobTypeList = useSelector(getJobTypeList);
  const EmployeePositionList = useSelector(getEmployeePositionList);

  useEffect(() => {
    dispatch(loadMachineViewList());
    dispatch(loadWorkOrderList());
    dispatch(loadMachineList());
    dispatch(loadJobTypeList());
    dispatch(loadEmployeePositionList());
    return () => {
      dispatch(resetMachineViewList());
      dispatch(resetListWorkOrderList());
      dispatch(resetMachineList());
      dispatch(resetJobTypeList());
      dispatch(resetEmployeePositionList());
    };
  }, [dispatch]);

  useEffect(() => {
    setExpandedMachineTiles([
      ...new Set(MachineViewList.map((item) => item.id)),
    ]);
  }, [MachineViewList, setExpandedMachineTiles]);

  const handleClickStartLaborLog = (id) => {
    dispatch(startLaborLog(id));
  };

  const handleClickStopLaborLog = (id) => {
    dispatch(stopLaborLog(id));
  };

  const handleClickStartByMachine = (id) => {
    dispatch(startByMachine(id));
  };

  const handleClickStopByMachine = (id) => {
    dispatch(stopByMachine(id));
  };

  const handleClickMoveLaborLog = (laborLog, machine) => {
    setLaborLog(laborLog);
    setMachine(machine);
    setOpenMoveLaborLog(true);
  };
  const onMoveLaborLog = (data) => {
    dispatch(moveLaborLog(data));
    setOpenMoveLaborLog(false);
  };
  const handleClickMoveByMachine = (laborLog, machine) => {
    setLaborLog(laborLog);
    setMachine(machine);
    setOpenMoveByMachine(true);
  };
  const onMoveByMachine = (data) => {
    dispatch(moveByMachine(machine.id, data));
    setOpenMoveByMachine(false);
  };
  const onChangePosition = (data) => {
    dispatch(changePosition(data));
    setOpenChangeLaborLogPosition(false);
  };
  const handleClickChangePosition = (laborLog, machine) => {
    setLaborLog(laborLog);
    setMachine(machine);
    setOpenChangeLaborLogPosition(true);
  };

  const handleClickWalkOff = (laborLog, machine) => {
    setLaborLog(laborLog);
    setMachine(machine);
    setOpenWalkOff(true);
  };
  const onWalkOff = (data) => {
    dispatch(walkOff(machine.id, data));
    setOpenWalkOff(false);
  };

  return (
    <>
      <Loading loading={machineViewState.loading} />
      <Box my={1}>
        <Box my={0} display='flex' flexDirection='row'>
          <Box flexGrow={1} mx={1}>
            {!isEmpty(machineViewState.list) && (
              <LaborMachineViewTileList
                list={MachineViewList}
                expandedMachineTiles={expandedMachineTiles}
                setExpandedMachineTiles={setExpandedMachineTiles}
                handleClickStartLaborLog={handleClickStartLaborLog}
                handleClickStopLaborLog={handleClickStopLaborLog}
                handleClickStartByMachine={handleClickStartByMachine}
                handleClickStopByMachine={handleClickStopByMachine}
                handleClickMoveLaborLog={handleClickMoveLaborLog}
                handleClickMoveByMachine={handleClickMoveByMachine}
                handleClickChangePosition={handleClickChangePosition}
                handleClickWalkOff={handleClickWalkOff}
              />
            )}
            {isEmpty(MachineViewList) && !machineViewState.loading && (
              <Empty description='There are no work orders to show' />
            )}
          </Box>
        </Box>
      </Box>

      <Dialog
        open={openChangeLaborLogPosition}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenChangeLaborLogPosition(false);
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <LaborLogPositionForm
            laborLog={laborLog}
            workOrder={laborLog?.workOrder}
            machine={machine}
            jobType={laborLog?.jobType}
            employeePositions={EmployeePositionList}
            handleOnSubmit={onChangePosition}
            handleOnCancel={() => setOpenChangeLaborLogPosition(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openMoveLaborLog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenMoveLaborLog(false);
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <LaborLogMoveForm
            laborLog={laborLog}
            workOrder={laborLog?.workOrder}
            machine={machine}
            jobType={laborLog?.jobType}
            workOrderList={WorkOrderList}
            machineList={MachineList}
            jobTypeList={JobTypeList}
            handleOnSubmit={onMoveLaborLog}
            handleOnCancel={() => setOpenMoveLaborLog(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openMoveByMachine}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenMoveByMachine(false);
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <Typography variant='body2'>{`Are you sure you want to MOVE employees from Machine: ${machine?.name} to a new machine?, If yes please select a new work order below.`}</Typography>
          <WorkOrderMoveForm
            workOrder={laborLog?.workOrder}
            machine={machine}
            jobType={laborLog?.jobType}
            workOrderList={WorkOrderList}
            machineList={MachineList}
            jobTypeList={JobTypeList}
            handleOnSubmit={onMoveByMachine}
            handleOnCancel={() => setOpenMoveByMachine(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openWalkOff}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenWalkOff(false);
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <LaborLogWalkOffForm
            laborLog={laborLog}
            workOrder={laborLog?.workOrder}
            machine={machine}
            jobType={laborLog?.jobType}
            handleOnSubmit={onWalkOff}
            handleOnCancel={() => setOpenWalkOff(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LaborMachineView;
