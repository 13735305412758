import {
  SET_MACHINE_TYPE,
  MACHINE_TYPE_LOADING,
  MACHINE_TYPE_RESET,
  SET_MACHINE_TYPES,
  MACHINE_TYPE_LIST_LOADING,
  MACHINE_TYPE_LIST_RESET,
  ADD_MACHINE_TYPE_TO_LIST,
  UPDATE_MACHINE_TYPE_IN_LIST,
  DELETE_MACHINE_TYPE_FROM_LIST,
  SHOW_ADD_EDIT_MACHINE_TYPE_FORM,
  SAVING_MACHINE_TYPE_DATA,
} from './types';

export const setMachineType = (item) => {
  return {
    type: SET_MACHINE_TYPE,
    payload: item,
  };
};

export const resetMachineType = () => {
  return {
    type: MACHINE_TYPE_RESET,
  };
};

export const setMachineTypeLoading = (payload) => {
  return {
    type: MACHINE_TYPE_LOADING,
    payload,
  };
};

export const setMachineTypeList = (list) => {
  return {
    type: SET_MACHINE_TYPES,
    payload: list,
  };
};

export const resetMachineTypeList = () => {
  return {
    type: MACHINE_TYPE_LIST_RESET,
  };
};

export const setMachineTypeListLoading = (payload) => {
  return {
    type: MACHINE_TYPE_LIST_LOADING,
    payload,
  };
};

export const addMachineTypeToList = (payload) => {
  return {
    type: ADD_MACHINE_TYPE_TO_LIST,
    payload,
  };
};

export const deleteMachineTypeFromList = (payload) => {
  return {
    type: DELETE_MACHINE_TYPE_FROM_LIST,
    payload,
  };
};

export const updateMachineTypeInList = (payload) => {
  return {
    type: UPDATE_MACHINE_TYPE_IN_LIST,
    payload,
  };
};

export const showAddEditMachineTypeForm = (payload) => {
  return {
    type: SHOW_ADD_EDIT_MACHINE_TYPE_FORM,
    payload,
  };
};

export const savingMachineTypeData = (payload) => {
  return {
    type: SAVING_MACHINE_TYPE_DATA,
    payload,
  };
};
