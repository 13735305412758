import {
  SET_EMPLOYER_LIST,
  EMPLOYER_LIST_LOADING,
  EMPLOYER_LIST_RESET,
} from './types';
const initialState = {
  list: [],
  loading: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYER_LIST:
      return {
        ...state,
        list: [...action.payload],
      };
    case EMPLOYER_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case EMPLOYER_LIST_RESET:
      return {
        ...state,
        list: [],
      };
    default:
      return state;
  }
}
