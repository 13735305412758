import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setSetupChecklistItemList,
  resetSetupChecklistItemList,
  setSetupChecklistItemListLoading,
  addSetupChecklistItemToList,
  deleteSetupChecklistItemFromList,
  updateSetupChecklistItemInList,
  savingSetupChecklistItemData,
  showAddEditSetupChecklistItemForm,
} from './actions';

export const loadSetupChecklistItems = () => (dispatch, getState, api) => {
  dispatch(setSetupChecklistItemListLoading(true));
  axios
    .get(`${api}/setup-checklist-items?limit=1000000`)
    .then((res) => {
      dispatch(resetSetupChecklistItemList());
      dispatch(setSetupChecklistItemList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setSetupChecklistItemListLoading(false));
    });
};

export const createSetupChecklistItem = (data) => (dispatch, getState, api) => {
  dispatch(setSetupChecklistItemListLoading(true));
  dispatch(savingSetupChecklistItemData(true));
  axios
    .post(`${api}/setup-checklist-items`, data)
    .then((res) => {
      dispatch(addSetupChecklistItemToList(res.data));
      dispatch(showAddEditSetupChecklistItemForm(false));
      const notification = {
        message: '👍🏻 Setup Checklist Item created successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setSetupChecklistItemListLoading(false));
      dispatch(savingSetupChecklistItemData(false));
    });
};

export const updateSetupChecklistItem =
  (id, data) => (dispatch, getState, api) => {
    dispatch(setSetupChecklistItemListLoading(true));
    dispatch(savingSetupChecklistItemData(true));
    axios
      .put(`${api}/setup-checklist-items/${id}`, data)
      .then((res) => {
        dispatch(updateSetupChecklistItemInList(res.data));
        dispatch(showAddEditSetupChecklistItemForm(false));
        const notification = {
          message: '👍🏻 Setup Checklist Item updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setSetupChecklistItemListLoading(false));
        dispatch(savingSetupChecklistItemData(false));
      });
  };

export const deleteSetupChecklistItem = (id) => (dispatch, getState, api) => {
  dispatch(setSetupChecklistItemListLoading(true));
  axios
    .delete(`${api}/setup-checklist-items/${id}`)
    .then((res) => {
      dispatch(deleteSetupChecklistItemFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Setup Checklist Item deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setSetupChecklistItemListLoading(false));
};

export const toggleSetupChecklistItemStatus =
  (id, currentStatus) => (dispatch, getState, api) => {
    dispatch(setSetupChecklistItemListLoading(true));
    axios
      .post(`${api}/setup-checklist-items/activate/${id}/${!currentStatus}`)
      .then((res) => {
        console.log(res);
        dispatch(loadSetupChecklistItems());
        const notification = {
          message: '👍🏻 Setup Checklist Item status updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setSetupChecklistItemListLoading(false));
      });
  };
