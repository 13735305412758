import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getContactList = createSelector(
  (state) => state.contacts.list.docs,
  (contactList) => {
    if (isEmpty(contactList)) return [];
    return contactList.sort((a, b) => (a.firstName < b.firstName ? -1 : 1));
  }
);
