import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCommodity,
  resetCommodity,
  resetCommodityList,
  showAddEditCommodityForm,
} from '../../redux/company/commodities/actions';
import {
  loadCommodities,
  createCommodity,
  updateCommodity,
  deleteCommodity,
  toggleCommodityStatus,
} from '../../redux/company/commodities/services';
import { getCommodityList } from '../../redux/company/commodities/selectors';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Paper,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import Loading from '../../components/UI/Loading';
import CommodityForm from '../../components/company/CommodityForm';
import Empty from '../../components/UI/Empty';
import CommoditiesTable from '../../components/company/CommoditiesTable';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
const isEmpty = require('is-empty');

const Commodities = () => {
  const dispatch = useDispatch();
  const commoditiesState = useSelector((state) => state.commodities);
  const commodityState = useSelector((state) => state.commodities.item);
  const CommoditiesList = useSelector(getCommodityList);
  const [editMode, setEditMode] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [openRemoveCommodityDialog, setOpenRemoveCommodityDialog] =
    useState(false);

  useEffect(() => {
    dispatch(loadCommodities());
    return () => {
      dispatch(resetCommodityList());
    };
  }, [dispatch]);

  const handleClickAdd = () => {
    setEditMode(false);
    dispatch(showAddEditCommodityForm(true));
  };

  const handleClickEdit = (commodity) => {
    setEditMode(true);
    dispatch(setCommodity(commodity));
    dispatch(showAddEditCommodityForm(true));
  };

  const onSubmitAddEdit = (data) => {
    if (editMode) {
      dispatch(updateCommodity(commodityState.id, data));
    } else {
      dispatch(createCommodity(data));
    }
  };

  const handleRefresh = () => {
    dispatch(loadCommodities());
  };

  const handleClickRemove = (commodity) => {
    dispatch(setCommodity(commodity));
    setOpenRemoveCommodityDialog(true);
  };

  const onRemoveCommodity = () => {
    dispatch(deleteCommodity(commodityState.id));
    dispatch(resetCommodity());
    setOpenRemoveCommodityDialog(false);
  };

  const handleClickToogleStatus = (commodity) => {
    dispatch(setCommodity(commodity));
    setOpenStatusDialog(true);
  };

  const onToggleStatus = () => {
    dispatch(toggleCommodityStatus(commodityState.id, commodityState.active));
    dispatch(resetCommodity());
    setOpenStatusDialog(false);
  };

  return (
    <>
      <Loading loading={commoditiesState.loading} />
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box display='flex' alignContent='center'>
            <IconButton aria-label='reload' onClick={handleRefresh}>
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              Commodities
            </Typography>
          </Box>
          <Tooltip title='Add Commodity'>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<AddIcon />}
              sx={{ marginRight: '0.5rem' }}
              onClick={handleClickAdd}
            >
              Add
            </Button>
          </Tooltip>
        </Box>
        <Box sx={{ width: '100%' }}>
          {!isEmpty(commoditiesState.list) && (
            <CommoditiesTable
              rows={CommoditiesList}
              handleClickEdit={handleClickEdit}
              handleClickRemove={handleClickRemove}
              handleClickToogleStatus={handleClickToogleStatus}
            />
          )}
          {isEmpty(commoditiesState.list) && !commoditiesState.loading && (
            <Empty description='There are no commodities to show' />
          )}
        </Box>
      </Paper>
      <ConfirmationDialog
        open={openRemoveCommodityDialog}
        handleNo={() => {
          setOpenRemoveCommodityDialog(false);
        }}
        handleYes={onRemoveCommodity}
        title='ePack - Remove Commodity'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to remove commodity: ${commodityState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openStatusDialog}
        handleNo={() => {
          setOpenStatusDialog(false);
        }}
        handleYes={onToggleStatus}
        title='ePack - Commodity Status'
        YesText={'Yes, Change'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to ${
            commodityState?.active ? 'deactivate' : 'activate'
          } commodity: ${commodityState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <Dialog
        open={commoditiesState.showAddEditForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setEditMode(false);
            dispatch(resetCommodity());
            dispatch(showAddEditCommodityForm(false));
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <CommodityForm
            editMode={editMode}
            commodity={commodityState}
            handleOnCancel={() => {
              setEditMode(false);
              dispatch(resetCommodity());
              dispatch(showAddEditCommodityForm(false));
            }}
            handleOnSubmit={onSubmitAddEdit}
            savingData={commoditiesState.savingData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Commodities;
