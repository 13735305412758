import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

const getGateOutList = createSelector(
  (state) => state.gateout.list.docs,
  (gateOutList) => {
    if (isEmpty(gateOutList)) return [];
    return gateOutList.sort((a, b) => (a.lastName < b.lastName ? -1 : 1));
  }
);

export const selectLastnameUniqueInitials = createSelector(
  getGateOutList,
  (gateOutList) => {
    if (isEmpty(gateOutList)) return [];
    return [...new Set(gateOutList.map((item) => item.lastName[0]))];
  }
);

export const selectIdsForSelectedEmployees = createSelector(
  getGateOutList,
  (gateOutList) => {
    if (isEmpty(gateOutList)) return [];
    return [
      ...new Set(
        gateOutList
          .filter((item) => item.selected === true)
          .map((item) => item.id)
      ),
    ];
  }
);

const getNameFilter = (state) => state.gateout.filter.name;

export const selectFilteredGateOutList = createSelector(
  getGateOutList,
  getNameFilter,
  (gateOutList, nameFilter) => {
    if (isEmpty(gateOutList)) return [];
    if (isEmpty(nameFilter)) return gateOutList;
    return gateOutList.filter((employee) =>
      (employee.firstName + employee.lastName)
        .toLowerCase()
        .includes(nameFilter.toLowerCase())
    );
  }
);
