import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setMachineList,
  resetMachineList,
  setMachineListLoading,
  addMachineToList,
  deleteMachineFromList,
  updateMachineInList,
  savingMachineData,
  showAddEditMachineForm,
} from './actions';

export const loadMachines = () => (dispatch, getState, api) => {
  dispatch(setMachineListLoading(true));
  axios
    .get(`${api}/machines?limit=1000000`)
    .then((res) => {
      dispatch(resetMachineList());
      dispatch(setMachineList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineListLoading(false));
    });
};

export const toggleMachineStatus =
  (id, currentStatus) => (dispatch, getState, api) => {
    dispatch(setMachineListLoading(true));
    axios
      .post(`${api}/machines/activate/${id}/${!currentStatus}`)
      .then((res) => {
        console.log(res);
        dispatch(loadMachines());
        const notification = {
          message: '👍🏻 Machine status updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setMachineListLoading(false));
      });
  };

export const createMachine = (data) => (dispatch, getState, api) => {
  dispatch(setMachineListLoading(true));
  dispatch(savingMachineData(true));
  axios
    .post(`${api}/machines`, data)
    .then((res) => {
      dispatch(addMachineToList(res.data));
      dispatch(showAddEditMachineForm(false));
      const notification = {
        message: '👍🏻 Machine created successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineListLoading(false));
      dispatch(savingMachineData(false));
    });
};

export const updateMachine = (id, data) => (dispatch, getState, api) => {
  dispatch(setMachineListLoading(true));
  dispatch(savingMachineData(true));
  axios
    .put(`${api}/machines/${id}`, data)
    .then((res) => {
      dispatch(updateMachineInList(res.data));
      dispatch(showAddEditMachineForm(false));
      const notification = {
        message: '👍🏻 Machine updated successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineListLoading(false));
      dispatch(savingMachineData(false));
    });
};

export const deleteMachine = (id) => (dispatch, getState, api) => {
  dispatch(setMachineListLoading(true));
  axios
    .delete(`${api}/machines/${id}`)
    .then((res) => {
      dispatch(deleteMachineFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Machine deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setMachineListLoading(false));
};
