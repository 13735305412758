import {
  SET_PROFIT_PROFILE,
  PROFIT_PROFILE_LOADING,
  PROFIT_PROFILE_RESET,
  SET_PROFIT_PROFILES,
  PROFIT_PROFILE_LIST_LOADING,
  PROFIT_PROFILE_LIST_RESET,
  ADD_PROFIT_PROFILE_TO_LIST,
  UPDATE_PROFIT_PROFILE_IN_LIST,
  DELETE_PROFIT_PROFILE_FROM_LIST,
  SAVING_PROFIT_PROFILE_DATA,
  SET_PROFIT_PROFILE_FILTERS,
  RESET_PROFIT_PROFILE_FILTERS,
  SHOW_DUPLICATE_PROFIT_PROFILE_FORM,
} from './types';

export const setProfitProfile = (item) => {
  return {
    type: SET_PROFIT_PROFILE,
    payload: item,
  };
};

export const resetProfitProfile = () => {
  return {
    type: PROFIT_PROFILE_RESET,
  };
};

export const setProfitProfileLoading = (payload) => {
  return {
    type: PROFIT_PROFILE_LOADING,
    payload,
  };
};

export const setProfitProfileList = (list) => {
  return {
    type: SET_PROFIT_PROFILES,
    payload: list,
  };
};

export const resetProfitProfileList = () => {
  return {
    type: PROFIT_PROFILE_LIST_RESET,
  };
};

export const setProfitProfileListLoading = (payload) => {
  return {
    type: PROFIT_PROFILE_LIST_LOADING,
    payload,
  };
};

export const addProfitProfileToList = (payload) => {
  return {
    type: ADD_PROFIT_PROFILE_TO_LIST,
    payload,
  };
};

export const deleteProfitProfileFromList = (payload) => {
  return {
    type: DELETE_PROFIT_PROFILE_FROM_LIST,
    payload,
  };
};

export const updateProfitProfileInList = (payload) => {
  return {
    type: UPDATE_PROFIT_PROFILE_IN_LIST,
    payload,
  };
};

export const savingProfitProfileData = (payload) => {
  return {
    type: SAVING_PROFIT_PROFILE_DATA,
    payload,
  };
};

export const setProfitProfileFilters = (payload) => {
  return {
    type: SET_PROFIT_PROFILE_FILTERS,
    payload,
  };
};

export const resetProfitProfileFilters = () => {
  return {
    type: RESET_PROFIT_PROFILE_FILTERS,
  };
};

export const showDuplicateProfitProfileForm = (payload) => {
  return {
    type: SHOW_DUPLICATE_PROFIT_PROFILE_FORM,
    payload,
  };
};
