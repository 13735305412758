import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../redux/system/auth/services';
import ForgotPasswordForm from '../../components/auth/ForgotPasswordForm';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleOnSubmit = (data) => {
    dispatch(forgotPassword(data, navigate));
  };

  return <ForgotPasswordForm handleOnSubmit={handleOnSubmit} />;
};

export default ForgotPassword;
