import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Paper,
  Typography,
  InputAdornment,
} from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';
import { Save } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';

const ValidationSchema = Yup.object().shape({
  item: Yup.string().required('Required'),
  amount: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  unitAmount: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  umo: Yup.string().required('Required'),
  costPerUnit: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
});

const ProfitProfileSupplyForm = ({
  supplyItem,
  editMode,
  handleOnSubmit,
  handleOnCancel,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  if (editMode) {
    setValue('id', supplyItem.id);
  }

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          const costTotal = (data.amount / data.unitAmount) * data.costPerUnit;
          handleOnSubmit({
            id: editMode ? data.id : uuidv4(),
            ...data,
            costTotal: isNaN(costTotal) ? 0 : costTotal,
          });
          reset();
        })}
      >
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box display='flex' my={1.5} flexGrow={1} alignItems='center'>
              <Typography
                color='primary'
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'eProfit - Supply Cost'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Controller
                  name='item'
                  control={control}
                  defaultValue={!editMode ? '' : supplyItem.item}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Item'
                      fullWidth
                      error={!!errors?.item}
                      helperText={errors?.item?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name='amount'
                  control={control}
                  defaultValue={!editMode ? '' : supplyItem.amount}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Amount'
                      fullWidth
                      type='number'
                      error={!!errors?.amount}
                      helperText={errors?.amount?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name='unitAmount'
                  control={control}
                  defaultValue={!editMode ? '' : supplyItem.unitAmount}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Unit Amount'
                      fullWidth
                      type='number'
                      error={!!errors?.unitAmount}
                      helperText={errors?.unitAmount?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name='umo'
                  control={control}
                  defaultValue={!editMode ? '' : supplyItem.umo}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='UMO'
                      fullWidth
                      error={!!errors?.umo}
                      helperText={errors?.umo?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name='costPerUnit'
                  control={control}
                  defaultValue={!editMode ? '' : supplyItem.costPerUnit}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Cost Per Unit'
                      fullWidth
                      type='number'
                      error={!!errors?.costPerUnit}
                      helperText={errors?.costPerUnit?.message}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>$</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Box component='div' mx={1} my={0.5} display='flex'>
          <Box my={1.5} flexGrow={1}>
            <Button
              variant='contained'
              color='secondary'
              size='large'
              type='submit'
              startIcon={<Save />}
            >
              {'Save'}
            </Button>
          </Box>
          <Box my={1.5}>
            <Button
              variant='outlined'
              size='large'
              startIcon={<UndoIcon />}
              onClick={handleOnCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ProfitProfileSupplyForm;
