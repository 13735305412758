import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeUserPassword } from '../../redux/company/users/services';
import { Box, Paper, Typography } from '@mui/material';
import ChangePasswordForm from '../../components/auth/ChangePasswordForm';

const ChangePassword = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const usersState = useSelector((state) => state.users);

  const onSubmit = (data) => {
    dispatch(changeUserPassword(data, navigate));
  };

  return (
    <>
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              Change Password
            </Typography>
          </Box>
        </Box>
        <Box display='flex' justifyContent='center'>
          <Box sx={{ maxWidth: '450px' }}>
            <ChangePasswordForm
              handleOnSubmit={onSubmit}
              handleOnCancel={() => navigate(-1)}
              savingData={usersState.savingData}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default ChangePassword;
