import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setLinesList,
  resetLinesList,
  setLinesListLoading,
  setLine,
  resetLine,
  setLineLoading,
} from './actions';

export const loadLinesList = () => (dispatch, getState, api) => {
  dispatch(setLinesListLoading(true));
  axios
    .get(`${api}/lines?limit=1000000`)
    .then((res) => {
      dispatch(resetLinesList());
      dispatch(setLinesList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setLinesListLoading(false));
    });
};

export const loadLine = (id) => (dispatch, getState, api) => {
  dispatch(setLineLoading(true));
  axios
    .get(`${api}/lines/by-id/${id}`)
    .then((res) => {
      dispatch(resetLine());
      dispatch(setLine(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setLineLoading(false));
    });
};

export const activateLine = (id) => (dispatch, getState, api) => {
  dispatch(setLineLoading(true));
  axios
    .post(`${api}/lines/activate/${id}`)
    .then((res) => {
      dispatch(loadLinesList());
      const notification = {
        message: '👍🏻 Line activated successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setLineLoading(false));
    });
};

export const removeLine = (id) => (dispatch, getState, api) => {
  dispatch(setLineLoading(true));
  axios
    .delete(`${api}/lines/${id}`)
    .then((res) => {
      dispatch(loadLinesList());
      const notification = {
        message: '👍🏻 Line deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setLineLoading(false));
    });
};
