import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setMachineTypeList,
  resetMachineTypeList,
  setMachineTypeListLoading,
} from './actions';

export const loadMachineTypeList = () => (dispatch, getState, api) => {
  dispatch(setMachineTypeListLoading(true));
  axios
    .get(`${api}/list/machine-types`)
    .then((res) => {
      dispatch(resetMachineTypeList());
      dispatch(setMachineTypeList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineTypeListLoading(false));
    });
};
