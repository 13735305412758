export const SET_COMMODITY = 'SET_COMMODITY';
export const COMMODITY_LOADING = 'COMMODITY_LOADING';
export const COMMODITY_RESET = 'COMMODITY_RESET';
export const SET_COMMODITYS = 'SET_COMMODITYS';
export const COMMODITY_LIST_LOADING = 'COMMODITY_LIST_LOADING';
export const COMMODITY_LIST_RESET = 'COMMODITY_LIST_RESET';
export const ADD_COMMODITY_TO_LIST = 'ADD_COMMODITY_TO_LIST';
export const UPDATE_COMMODITY_IN_LIST = 'UPDATE_COMMODITY_IN_LIST';
export const DELETE_COMMODITY_FROM_LIST = 'DELETE_COMMODITY_FROM_LIST';
export const SHOW_ADD_EDIT_COMMODITY_FORM = 'SHOW_ADD_EDIT_COMMODITY_FORM';
export const SAVING_COMMODITY_DATA = 'SAVING_COMMODITY_DATA';
