import {
  EMPLOYEE_LOADING,
  SET_EMPLOYEE,
  EMPLOYEE_RESET,
  SET_EMPLOYEES,
  EMPLOYEES_LIST_LOADING,
  EMPLOYEES_LIST_RESET,
  SET_EMPLOYEE_NAME_FILTER,
  ADD_EMPLOYEE_TO_LIST,
  UPDATE_EMPLOYEE_IN_LIST,
  DELETE_EMPLOYEE_FROM_LIST,
  SHOW_ADD_EDIT_EMPLOYEE_FORM,
  SAVING_EMPLOYEE_DATA,
} from './types';

export const setEmployee = (employee) => {
  return {
    type: SET_EMPLOYEE,
    payload: employee,
  };
};

export const setEmployees = (employees) => {
  return {
    type: SET_EMPLOYEES,
    payload: employees,
  };
};

export const resetEmployeeList = () => {
  return {
    type: EMPLOYEES_LIST_RESET,
  };
};

export const resetEmployee = () => {
  return {
    type: EMPLOYEE_RESET,
  };
};

export const setEmployeeListLoading = (payload) => {
  return {
    type: EMPLOYEES_LIST_LOADING,
    payload,
  };
};

export const setEmployeeLoading = (payload) => {
  return {
    type: EMPLOYEE_LOADING,
    payload,
  };
};

export const setEmployeeNameFilter = (payload) => {
  return {
    type: SET_EMPLOYEE_NAME_FILTER,
    payload,
  };
};

export const addEmployeeToList = (payload) => {
  return {
    type: ADD_EMPLOYEE_TO_LIST,
    payload,
  };
};

export const deleteEmployeeFromList = (payload) => {
  return {
    type: DELETE_EMPLOYEE_FROM_LIST,
    payload,
  };
};

export const updateEmployeeinList = (payload) => {
  return {
    type: UPDATE_EMPLOYEE_IN_LIST,
    payload,
  };
};

export const showAddEditEmployeeForm = (payload) => {
  return {
    type: SHOW_ADD_EDIT_EMPLOYEE_FORM,
    payload,
  };
};

export const savingEmployeeData = (payload) => {
  return {
    type: SAVING_EMPLOYEE_DATA,
    payload,
  };
};
