import {
  SET_LIST_WORK_ORDER_LIST,
  LIST_WORK_ORDER_LIST_LOADING,
  LIST_WORK_ORDER_LIST_RESET,
} from './types';

export const setListWorkOrderList = (list) => {
  return {
    type: SET_LIST_WORK_ORDER_LIST,
    payload: list,
  };
};

export const resetListWorkOrderList = () => {
  return {
    type: LIST_WORK_ORDER_LIST_RESET,
  };
};

export const setListWorkOrderListLoading = (payload) => {
  return {
    type: LIST_WORK_ORDER_LIST_LOADING,
    payload,
  };
};
