import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuVisibilityGate from '../../utils/MenuVisibilityGate';

const DashboardsMenu = ({ anchorEl, open, handleClose, userRole }) => {
  let navigate = useNavigate();
  return (
    <Menu
      id='dashboards-menu'
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      getcontentanchorel={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MenuVisibilityGate
        userRole={userRole}
        allowedPermissions={['dashboards:main']}
      >
        <MenuItem
          onClick={() => {
            navigate('/dashboard/main');
            handleClose();
          }}
        >
          <ListItemIcon>
            <DashboardIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Main</ListItemText>
        </MenuItem>
        <Divider />
      </MenuVisibilityGate>
      <MenuVisibilityGate
        userRole={userRole}
        allowedPermissions={['dashboards:production']}
      >
        <MenuItem
          onClick={() => {
            navigate('/dashboard/production');
            handleClose();
          }}
        >
          <ListItemIcon>
            <DashboardIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Production</ListItemText>
        </MenuItem>
        <Divider />
      </MenuVisibilityGate>
      <MenuVisibilityGate
        userRole={userRole}
        allowedPermissions={['dashboards:labor']}
      >
        <MenuItem
          onClick={() => {
            navigate('/dashboard/labor');
            handleClose();
          }}
        >
          <ListItemIcon>
            <DashboardIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Labor</ListItemText>
        </MenuItem>
      </MenuVisibilityGate>
    </Menu>
  );
};

export default DashboardsMenu;
