import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Paper,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  InputAdornment,
} from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';
import { Save } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';

const ValidationSchema = Yup.object().shape({
  month: Yup.number().typeError('Must be a number').required('Required'),
  allowance: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  yield: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
});

const MORProfileMonthForm = ({
  month,
  editMode,
  handleOnSubmit,
  handleOnCancel,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  if (editMode) {
    setValue('id', month.id);
  }

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          handleOnSubmit({
            ...month,
            id: editMode ? data.id : uuidv4(),
            month: data.month,
            allowance: data.allowance / 100,
            yield: data.yield / 100,
          });
          reset();
        })}
      >
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box display='flex' my={1.5} flexGrow={1} alignItems='center'>
              <Typography
                color='primary'
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'ePlan - Month Metrics'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ minWidth: 250 }}>
                  <InputLabel id='month-select-label'>Month</InputLabel>
                  <Controller
                    name='month'
                    control={control}
                    defaultValue={editMode ? month.month : ''}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId='month-select-label'
                        label={'Month'}
                        fullWidth
                        error={!!errors?.month}
                      >
                        <MenuItem value='1'>January</MenuItem>
                        <MenuItem value='2'>February</MenuItem>
                        <MenuItem value='3'>March</MenuItem>
                        <MenuItem value='4'>April</MenuItem>
                        <MenuItem value='5'>May</MenuItem>
                        <MenuItem value='6'>June</MenuItem>
                        <MenuItem value='7'>July</MenuItem>
                        <MenuItem value='8'>August</MenuItem>
                        <MenuItem value='9'>September</MenuItem>
                        <MenuItem value='10'>October</MenuItem>
                        <MenuItem value='11'>November</MenuItem>
                        <MenuItem value='12'>December</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                <FormHelperText>{errors?.month?.message}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Manufacturing Availability (from profile)'
                  fullWidth
                  disabled
                  value={month.manufacturingAvailability * 100}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Throughput Cases/Hour'
                  fullWidth
                  disabled
                  value={parseFloat(month.throughput).toFixed(0)}
                  InputProps={{
                    sx: { fontWeight: 'bolder' },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name='allowance'
                  control={control}
                  defaultValue={!editMode ? '' : month.allowance * 100}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Allowance'
                      fullWidth
                      type='number'
                      error={!!errors?.allowance}
                      helperText={errors?.allowance?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>%</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name='yield'
                  control={control}
                  defaultValue={!editMode ? '' : month.yield * 100}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Yield'
                      fullWidth
                      type='number'
                      error={!!errors?.yield}
                      helperText={errors?.yield?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>%</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Manufacturing Hours Per Week'
                  fullWidth
                  disabled
                  value={month.manufacturingHoursPerWeek}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Manufacturing Capacity Per Week (Cases Per Week)'
                  fullWidth
                  disabled
                  value={month.manufacturingCapacityPerWeek}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Manufacturing Capacity Per Day'
                  fullWidth
                  disabled
                  value={month.manufacturingCapacityPerDay}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Manufacturing Capacity Per Shift'
                  fullWidth
                  disabled
                  value={month.manufacturingCapacityPerShift}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Capacity Avilability'
                  fullWidth
                  disabled
                  value={month.capacityAvailability}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Capacity Hours Per Week'
                  fullWidth
                  disabled
                  value={month.capacityHoursPerWeek}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Total Capacity Per Week'
                  fullWidth
                  disabled
                  value={month.totalCapacityPerWeek}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Box component='div' mx={1} my={0.5} display='flex'>
          <Box my={1.5} flexGrow={1}>
            <Button
              variant='contained'
              color='secondary'
              size='large'
              type='submit'
              startIcon={<Save />}
            >
              {'Save'}
            </Button>
          </Box>
          <Box my={1.5}>
            <Button
              variant='outlined'
              size='large'
              startIcon={<UndoIcon />}
              onClick={handleOnCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default MORProfileMonthForm;
