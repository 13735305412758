import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import { loadWorkOrderViewList } from './work-order-view/services';
import { loadJobViewList } from './job-view/services';
import { loadMachineViewList } from './machine-view/services';
import { loadSummaryList } from './summary/services';

export const startAll = (location) => (dispatch, getState, api) => {
  axios
    .post(`${api}/labor/start/all`)
    .then((res) => {
      if (location.pathname.includes('workorder')) {
        dispatch(loadWorkOrderViewList());
      } else if (location.pathname.includes('job')) {
        dispatch(loadJobViewList());
      } else if (location.pathname.includes('machine')) {
        dispatch(loadMachineViewList());
      } else if (location.pathname.includes('summary')) {
        dispatch(loadSummaryList());
      }
      const notification = {
        message: '👍🏻 All employees started successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
};

export const stopAll = (location) => (dispatch, getState, api) => {
  axios
    .post(`${api}/labor/stop/all`)
    .then((res) => {
      if (location.pathname.includes('workorder')) {
        dispatch(loadWorkOrderViewList());
      } else if (location.pathname.includes('job')) {
        dispatch(loadJobViewList());
      } else if (location.pathname.includes('machine')) {
        dispatch(loadMachineViewList());
      } else if (location.pathname.includes('summary')) {
        dispatch(loadSummaryList());
      }
      const notification = {
        message: '👍🏻 All employees stopped successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
};
