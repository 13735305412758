export const userPermissions = {
  owner: [
    'epack',
    'epack:panel',
    'epack:lines',
    'epack:machines',
    'epack:workorders',
    'etime',
    'etime:clipboard',
    'etime:gatein',
    'etime:gateout',
    'etime:employees',
    'etime:adjustlogs',
    'eprofit',
    'eprofit:profiles',
    'eprofit:reports',
    'eplan',
    'eplan:machineprofile',
    'eplan:mormachine',
    'eplan:mormanual',
    'dashboards',
    'dashboards:main',
    'dashboards:production',
    'dashboards:labor',
    'reports',
    'config',
    'config:companies',
    'config:company_settings',
  ],
  admin: [
    'epack',
    'epack:panel',
    'epack:lines',
    'epack:machines',
    'epack:workorders',
    'etime',
    'etime:clipboard',
    'etime:gatein',
    'etime:gateout',
    'etime:employees',
    'etime:adjustlogs',
    'eprofit',
    'eprofit:profiles',
    'eprofit:reports',
    'eplan',
    'eplan:machineprofile',
    'eplan:mormachine',
    'eplan:mormanual',
    'dashboards',
    'dashboards:main',
    'dashboards:production',
    'dashboards:labor',
    'reports',
    'config',
    'config:company_settings',
  ],
  supervisor: [
    'epack',
    'epack:panel',
    'epack:lines',
    'epack:machines',
    'epack:workorders',
    'etime',
    'etime:clipboard',
    'etime:gatein',
    'etime:gateout',
    'etime:employees',
    'etime:adjustlogs',
    'eprofit',
    'eprofit:profiles',
    'eprofit:reports',
    'eplan',
    'eplan:machineprofile',
    'eplan:mormachine',
    'eplan:mormanual',
    'dashboards',
    'dashboards:main',
    'dashboards:production',
    'dashboards:labor',
    'reports',
    'config',
  ],
  gate: [
    'etime',
    'etime:gatein',
    'etime:gateout',
    'etime:employees',
    'dashboards',
    'dashboards:main',
    'dashboards:labor',
    'reports',
    'config',
  ],
  report: [
    'dashboards',
    'dashboards:main',
    'dashboards:production',
    'dashboards:labor',
    'reports',
    'config',
  ],
  user: [
    'dashboards',
    'dashboards:main',
    'dashboards:production',
    'dashboards:labor',
    'config',
  ],
};
