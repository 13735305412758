import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

const getEmployees = createSelector(
  (state) => state.employees.list.docs,
  (employees) => {
    if (isEmpty(employees)) return [];
    return employees.sort((a, b) => (a.lastName < b.lastName ? -1 : 1));
  }
);

export const selectLastnameUniqueInitials = createSelector(
  getEmployees,
  (employees) => {
    if (isEmpty(employees)) return [];
    return [...new Set(employees.map((item) => item.lastName[0]))];
  }
);

const getNameFilter = (state) => state.employees.filter.name;

export const selectFilteredEmployee = createSelector(
  getEmployees,
  getNameFilter,
  (employees, nameFilter) => {
    if (isEmpty(employees)) return [];
    if (!isEmpty(nameFilter)) {
      return employees.filter((employee) =>
        (employee.firstName + employee.lastName)
          .toLowerCase()
          .includes(nameFilter.toLowerCase())
      );
    } else {
      return employees;
    }
  }
);
