import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Tooltip,
  IconButton,
  Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PalletInIcon from '../UI/icons/PalletInIcon';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ClipboardOutflowTable = ({
  rows,
  readOnly,
  handleClickDeletePallet,
  handleClickMovePalletToIn,
}) => {
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table aria-label='outflow table' size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Pallet</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Lot</TableCell>
            <TableCell>GTIN</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Cases</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.id}>
              <TableCell component='th' scope='row'>
                {row.code}
              </TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.lot}</TableCell>
              {/* <TableCell>
                {isEmpty(row.gtin) ? (
                  <Button
                    color='primary'
                    variant='outlined'
                    size='small'
                    onClick={() => alert('Get GTIN Clicked')}
                  >
                    Get GTIN
                  </Button>
                ) : (
                  row.gtin
                )}
              </TableCell> */}
              <TableCell>{row.gtin}</TableCell>
              <TableCell>{row.item}</TableCell>
              <TableCell>{row.cases}</TableCell>
              <TableCell>
                {!readOnly && (
                  <>
                    <Tooltip title='Delete'>
                      <IconButton
                        size='small'
                        onClick={() => handleClickDeletePallet(row)}
                        sx={{
                          color: 'error.main',
                        }}
                        variant='outlined'
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Move'>
                      <IconButton
                        size='small'
                        onClick={() => handleClickMovePalletToIn(row)}
                        sx={{
                          color: 'primary.main',
                          marginLeft: 1,
                        }}
                        variant='outlined'
                      >
                        <PalletInIcon sx={{ width: '22px', height: '22px' }} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClipboardOutflowTable;
