import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  loadMORProfile,
  updateMORProfile,
} from '../../redux/eplan/profile-mor/services';
import { resetMORProfile } from '../../redux/eplan/profile-mor/actions';
import { Box, Paper, Typography, Divider } from '@mui/material';
import Loading from '../../components/UI/Loading';
import MORProfileForm from '../../components/eplan/MORProfileForm';
import isEmpty from 'is-empty';

const UpdateMORProfile = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const morProfilesState = useSelector((state) => state.mor_profile);
  const morProfileState = useSelector((state) => state.mor_profile.item);
  const onSubmit = (data) => {
    dispatch(updateMORProfile(id, data, navigate));
  };

  useEffect(() => {
    if (isEmpty(id)) {
      navigate('/eplan/mor_profiles');
    } else {
      dispatch(loadMORProfile(id));
    }
    return () => {
      dispatch(resetMORProfile());
    };
  }, [id, dispatch, navigate]);

  return (
    <>
      <Loading loading={morProfilesState.loading} />
      <Paper elevation={0}>
        <Box display='flex' mx={1} mt={1}>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              ePlan - Update MOR Profile
            </Typography>
          </Box>
        </Box>
        <Divider variant='middle' light />
        <Box mx={1} mt={1}>
          {!isEmpty(morProfileState) && !morProfilesState.loading ? (
            <MORProfileForm
              machineId={morProfileState.machine.id}
              commodityId={morProfileState.commodity.id}
              itemDescription={morProfileState.itemDescription}
              onSubmit={onSubmit}
              savingData={morProfilesState.savingData}
              editMode={true}
              morProfile={morProfileState}
            />
          ) : null}
        </Box>
      </Paper>
    </>
  );
};

export default UpdateMORProfile;
