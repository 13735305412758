import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Paper,
  InputLabel,
  Select,
  Typography,
  MenuItem,
} from '@mui/material';
import * as Yup from 'yup';
import EmployeePhoto from './EmployeePhoto';
import truncateString from '../../utils/truncateString';
import isEmpty from 'is-empty';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import QrCodeIcon from '@mui/icons-material/QrCode';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PanToolIcon from '@mui/icons-material/PanTool';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from 'moment';

const ValidationSchema = Yup.object().shape({
  workOrderId: Yup.string().required('Required'),
  machineId: Yup.string().required('Required'),
  jobTypeId: Yup.string().required('Required'),
  employeePositionId: Yup.string().required('Required'),
});

const AssignJobForm = ({
  employee,
  employees,
  handleOnSubmit,
  handleOnCancel,
  workOrderList,
  machineList,
  jobTypeList,
  employeePositionList,
  savingData,
  multipleEmployees,
  handleClickOnSelectTile,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  if (!multipleEmployees) {
    setValue('employeeId', employee.id);
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper elevation={0}>
          <Box component="div" display="flex" mx={1} alignItems="center">
            <Box display="flex" my={1.5} flexGrow={1} alignItems="center">
              <Typography
                color="primary"
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                eTime - Assign Job
              </Typography>
            </Box>
          </Box>
          <Divider light />
          <Grid container spacing={1} mt={1}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ minWidth: 250 }}>
                <InputLabel id="work-order-select-label">Work Order</InputLabel>
                <Controller
                  name="workOrderId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="work-order-select-label"
                      label={'Work Order'}
                      fullWidth
                      error={!!errors?.workOrderId}
                    >
                      {workOrderList.map((workOrder) => (
                        <MenuItem key={workOrder.id} value={workOrder.id}>
                          {`${workOrder.code} - ${workOrder.commodity}`}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <FormHelperText>{errors?.workOrderId?.message}</FormHelperText>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ minWidth: 250 }}>
                <InputLabel id="machine-select-label">Machine</InputLabel>
                <Controller
                  name="machineId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="machine-select-label"
                      label={'Machine'}
                      fullWidth
                      error={!!errors?.machineId}
                    >
                      {machineList.map((machine) => (
                        <MenuItem key={machine.id} value={machine.id}>
                          {machine.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <FormHelperText>{errors?.machineId?.message}</FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ minWidth: 250 }}>
                <InputLabel id="job-select-label">Job</InputLabel>
                <Controller
                  name="jobTypeId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="job-select-label"
                      label={'Job'}
                      fullWidth
                      error={!!errors?.jobTypeId}
                    >
                      {jobTypeList.map((jobType) => (
                        <MenuItem key={jobType.id} value={jobType.id}>
                          {jobType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <FormHelperText>{errors?.jobTypeId?.message}</FormHelperText>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ minWidth: 250 }}>
                <InputLabel id="position-select-label">Position</InputLabel>
                <Controller
                  name="employeePositionId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="machine-select-label"
                      label={'Position'}
                      fullWidth
                      error={!!errors?.employeePositionId}
                    >
                      {employeePositionList.map((position) => (
                        <MenuItem key={position.id} value={position.id}>
                          {position.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <FormHelperText>
                {errors?.employeePositionId?.message}
              </FormHelperText>
            </Grid>
          </Grid>
          {multipleEmployees ? (
            <Box mt={1.5}>
              <FormGroup aria-label="position" row>
                {employees.map((employee) => (
                  <FormControlLabel
                    key={employee.id}
                    control={
                      <Checkbox
                        checked={employee.selected}
                        onClick={() => handleClickOnSelectTile(employee)}
                      />
                    }
                    label={truncateString(
                      employee.lastName + ' ' + employee.firstName,
                      {
                        length: 20,
                        ending: '...',
                      }
                    )}
                  />
                ))}
              </FormGroup>
            </Box>
          ) : (
            <Box
              component="div"
              display="flex"
              mt={2}
              justifyContent="center"
              alignItems={'center'}
            >
              <Box sx={{ margin: 5 }}>
                <EmployeePhoto photo={employee.photo} size="large" />
              </Box>
              <Box flexGrow={1}>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={2}>
                    <BadgeIcon color="primary" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body1">
                      {truncateString(
                        employee.lastName + ' ' + employee.firstName,
                        {
                          length: 25,
                          ending: '...',
                        }
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <AccountBalanceIcon color="primary" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body1">
                      {truncateString(employee.employer.name)}
                    </Typography>
                  </Grid>
                  {!isEmpty(employee.latestPosition) && (
                    <>
                      <Grid item xs={2}>
                        <PanToolIcon color="primary" />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="body1">
                          {truncateString(employee.latestPosition)}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={2}>
                    <AccessTimeIcon color="primary" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body1">
                      {moment(employee.dateIn).format('MM/DD/YYYY hh:mm a')}
                    </Typography>
                  </Grid>
                  {employee.barcode && (
                    <>
                      <Grid item xs={2}>
                        <QrCodeIcon color="primary" />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="body1">
                          {employee.barcode}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Box>
          )}
          <Box component="div" mx={1} my={0.5} display="flex">
            <Box my={1.5} flexGrow={1}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                startIcon={<SaveIcon />}
                disabled={savingData}
              >
                {savingData ? 'Assgning Job...' : 'Yes, Assign'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant="outlined"
                size="large"
                startIcon={<UndoIcon />}
                onClick={handleOnCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default AssignJobForm;
