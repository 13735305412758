import {
  SET_LABOR_SUMMARY_VIEW_LIST,
  LABOR_SUMMARY_VIEW_LIST_LOADING,
  LABOR_SUMMARY_VIEW_LIST_RESET,
} from './types';

export const setSummaryList = (list) => {
  return {
    type: SET_LABOR_SUMMARY_VIEW_LIST,
    payload: list,
  };
};

export const resetSummaryList = () => {
  return {
    type: LABOR_SUMMARY_VIEW_LIST_RESET,
  };
};

export const setSummaryListLoading = (payload) => {
  return {
    type: LABOR_SUMMARY_VIEW_LIST_LOADING,
    payload,
  };
};
