export const SET_SETUP_CHECKLIST_ITEM = 'SET_SETUP_CHECKLIST_ITEM';
export const SETUP_CHECKLIST_ITEM_LOADING = 'SETUP_CHECKLIST_ITEM_LOADING';
export const SETUP_CHECKLIST_ITEM_RESET = 'SETUP_CHECKLIST_ITEM_RESET';
export const SET_SETUP_CHECKLIST_ITEMS = 'SET_SETUP_CHECKLIST_ITEMS';
export const SETUP_CHECKLIST_ITEM_LIST_LOADING = 'SETUP_CHECKLIST_ITEM_LIST_LOADING';
export const SETUP_CHECKLIST_ITEM_LIST_RESET = 'SETUP_CHECKLIST_ITEM_LIST_RESET';
export const ADD_SETUP_CHECKLIST_ITEM_TO_LIST = 'ADD_SETUP_CHECKLIST_ITEM_TO_LIST';
export const UPDATE_SETUP_CHECKLIST_ITEM_IN_LIST = 'UPDATE_SETUP_CHECKLIST_ITEM_IN_LIST';
export const DELETE_SETUP_CHECKLIST_ITEM_FROM_LIST = 'DELETE_SETUP_CHECKLIST_ITEM_FROM_LIST';
export const SHOW_ADD_EDIT_SETUP_CHECKLIST_ITEM_FORM = 'SHOW_ADD_EDIT_SETUP_CHECKLIST_ITEM_FORM';
export const SAVING_SETUP_CHECKLIST_ITEM_DATA = 'SAVING_SETUP_CHECKLIST_ITEM_DATA';
