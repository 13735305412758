import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setLaborLogList,
  resetLaborLogList,
  setLaborLogListLoading,
  updateLaborLogInList,
  showAdjustLaborLogForm,
  savingLaborLogData,
} from './actions';

export const loadLaborLogList =
  (name, start, end, employer) => (dispatch, getState, api) => {
    dispatch(setLaborLogListLoading(true));
    axios
      .get(
        `${api}/labor?employee=${name}&start=${start}&end=${end}&employer=${employer}&limit=1000000`
      )
      .then((res) => {
        dispatch(resetLaborLogList());
        dispatch(setLaborLogList(res.data));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setLaborLogListLoading(false));
      });
  };

export const adjustLaborLog = (data) => (dispatch, getState, api) => {
  dispatch(savingLaborLogData(true));
  axios
    .post(`${api}/labor/adjust-duration/`, data)
    .then((res) => {
      dispatch(updateLaborLogInList(res.data));
      const notification = {
        message: '👍🏻 Duration adjusted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(savingLaborLogData(false));
      dispatch(showAdjustLaborLogForm(false));
    });
};
