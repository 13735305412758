import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';
import isEmpty from 'is-empty';
import MORProfileMonthsTable from './MORProfileMonthsTable';
import MORProfileMonthForm from './MORProfileMonthForm';
import ConfirmationDialog from '../UI/ConfirmationDialog';
import { getMonthName } from '../../utils/commonFunctions';

const ValidationSchema = Yup.object().shape({
  throughputUnitsPerMinute: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
  throughputUnitsPerCase: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
});

const MORProfileForm = ({
  machineId,
  commodityId,
  itemDescription,
  onSubmit,
  savingData,
  editMode,
  morProfile,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
    defaultValues: {
      throughputUnitsPerMinute: editMode
        ? morProfile.throughputUnitsPerMinute
        : '',
      throughputUnitsPerCase: editMode ? morProfile.throughputUnitsPerCase : '',
    },
  });
  const [month, setMonth] = useState({});
  const [months, setMonths] = useState(
    editMode ? morProfile.monthlyMetrics : []
  );
  const [editModeMonth, setEditModeMonth] = useState(false);
  const [openAddMonth, setOpenAddEditMonth] = useState(false);
  const [openDeleteMonth, setOpenDeleteMonth] = useState(false);
  let navigate = useNavigate();

  const watchThroughputUnitsPerMinute = watch('throughputUnitsPerMinute');
  const watchThroughputUnitsPerCase = watch('throughputUnitsPerCase');

  const throughputRateCasesPerHour = parseFloat(
    watchThroughputUnitsPerMinute * 60 * (1 / watchThroughputUnitsPerCase)
  ).toFixed(0);
  useEffect(() => {
    if (editMode === false) {
      setValue('machineId', machineId);
      setValue('commodityId', commodityId);
      isEmpty(itemDescription)
        ? setValue('itemDescription', 'NONE')
        : setValue('itemDescription', itemDescription);
    }
  }, [setValue, machineId, commodityId, itemDescription, editMode]);

  const handleClickAddMonth = useCallback(() => {
    setEditModeMonth(false);
    setOpenAddEditMonth(true);
  }, [setOpenAddEditMonth]);

  const onAddEditMonth = useCallback(
    (data) => {
      if (editModeMonth) {
        setMonths((months) =>
          months.map((month) => {
            if (month.id === data.id) {
              return { ...data };
            }
            return month;
          })
        );
      } else {
        setMonths([...months, data]);
      }
      setOpenAddEditMonth(false);
      setEditModeMonth(false);
    },
    [months, setMonths, setEditModeMonth, editModeMonth]
  );

  const handleClickDelete = useCallback(
    (month) => {
      setMonth(month);
      setOpenDeleteMonth(true);
    },
    [setMonth]
  );

  const onDeleteMonth = useCallback(() => {
    setMonths([...months.filter((item) => item.id !== month.id)]);
    setOpenDeleteMonth(false);
    setMonth({});
  }, [months, setMonths, month, setOpenDeleteMonth, setMonth]);

  const handleClickEdit = useCallback(
    (month) => {
      setMonth(month);
      setEditModeMonth(true);
      setOpenAddEditMonth(true);
    },
    [setMonth, setEditModeMonth, setOpenAddEditMonth]
  );

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          let monthlyMetrics = months.map((item) => {
            return {
              month: item.month,
              allowance: item.allowance,
              yield: item.yield,
            };
          });
          onSubmit({ ...data, monthlyMetrics });
        })}
      >
        <Paper elevation={0}>
          <Box>
            <Grid container spacing={2} mt={1}>
              {editMode ? (
                <>
                  <Grid item xs={4}>
                    <TextField
                      size='small'
                      label='Machine'
                      fullWidth
                      value={morProfile.machine.name}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size='small'
                      label='Commodity'
                      fullWidth
                      value={morProfile.commodity.name}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size='small'
                      label='Item Description'
                      fullWidth
                      value={morProfile.itemDescription}
                      disabled
                    />
                  </Grid>
                </>
              ) : null}

              <Grid item xs={12}>
                <Card variant='outlined'>
                  <CardHeader
                    title='Throughput Calculator'
                    sx={{
                      paddingBottom: 2,
                      paddingTop: 1,
                    }}
                  />
                  <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Controller
                          name='throughputUnitsPerMinute'
                          control={control}
                          defaultValue={
                            editMode ? morProfile.throughputUnitsPerMinute : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size='small'
                              label='How many units per minute does the machine outputs?'
                              fullWidth
                              error={!!errors?.throughputUnitsPerMinute}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name='throughputUnitsPerCase'
                          control={control}
                          defaultValue={
                            editMode ? morProfile.throughputUnitsPerCase : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size='small'
                              label='How many units per case?'
                              fullWidth
                              error={!!errors?.throughputUnitsPerCase}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          size='small'
                          label='Your case/hr throughput rate is'
                          fullWidth
                          value={
                            isNaN(throughputRateCasesPerHour)
                              ? 0
                              : throughputRateCasesPerHour
                          }
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Box my={2.5}>
            <Card variant='outlined'>
              <CardHeader
                title='Monthly Metrics'
                sx={{
                  paddingBottom: 2,
                  paddingTop: 1,
                }}
                action={
                  <Button
                    variant='outlined'
                    startIcon={<AddIcon />}
                    onClick={handleClickAddMonth}
                  >
                    Add Month
                  </Button>
                }
              />
              <MORProfileMonthsTable
                rows={months}
                handleClickDelete={handleClickDelete}
                handleClickEdit={handleClickEdit}
              />
            </Card>
          </Box>
          <Box mx={1} my={2.5} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
                startIcon={<SaveIcon />}
              >
                {savingData ? 'Saving...' : 'Save'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UndoIcon />}
                onClick={() => {
                  reset();
                  navigate('/eplan/mor_profiles');
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
      <Dialog
        open={openAddMonth}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenAddEditMonth(false);
            setEditModeMonth(false);
            setMonth({});
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <MORProfileMonthForm
            editMode={editModeMonth}
            month={month}
            handleOnCancel={() => {
              setOpenAddEditMonth(false);
              setEditModeMonth(false);
              setMonth({});
            }}
            handleOnSubmit={onAddEditMonth}
          />
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        open={openDeleteMonth}
        handleNo={() => {
          setOpenDeleteMonth(false);
        }}
        handleYes={onDeleteMonth}
        title='ePlan - Delete Month'
        YesText={'Yes, Delete'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to delete  ${getMonthName(month?.month)}?`}
        </Typography>
      </ConfirmationDialog>
    </>
  );
};

export default MORProfileForm;
