import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Tooltip, Typography, Paper } from '@mui/material';
import EmployeeStatusRing from './EmployeeStatusRing';
import EmployeeAvatar from './EmployeeAvatar';
import CommodityIcon from '../UI/icons/CommodityIcon';
import MachineIcon from '../UI/icons/MachineIcon';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BuildIcon from '@mui/icons-material/Build';
import LaunchIcon from '@mui/icons-material/Launch';
import { orange, green, grey } from '@mui/material/colors';
import truncateString from '../../utils/truncateString';

const LaborSummaryTile = ({ workOrder }) => {
  const navigate = useNavigate();
  return (
    <Paper variant="outlined">
      <Box display="flex" my={1}>
        <Box display="flex" flexDirection="column" flexGrow={1} ml={1}>
          <Box display="flex" flexDirection="row">
            <Box display="flex" sx={{ width: '50%' }}>
              <Box>
                <ListAltIcon
                  sx={{ color: grey['500'], width: '18px', height: '18px' }}
                />
              </Box>
              <Box ml={0.5}>
                <Typography variant="body2">
                  {truncateString(workOrder.workOrder, {
                    length: 12,
                    ending: '...',
                  })}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" sx={{ width: '50%' }}>
              <Box>
                <CommodityIcon
                  sx={{ color: grey['500'], width: '18px', height: '18px' }}
                />
              </Box>
              <Box ml={0.5}>
                <Typography variant="body2">
                  {truncateString(workOrder.commodity, {
                    length: 12,
                    ending: '...',
                  })}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box display="flex" flexDirection="row" mt={2}>
            <Box display="flex" sx={{ width: '50%' }}>
              <Box>
                <MachineIcon
                  sx={{ color: grey['500'], width: '18px', height: '18px' }}
                />
              </Box>
              <Box ml={0.5}>
                <Typography variant="body2">
                  {truncateString(workOrder.machine, {
                    length: 12,
                    ending: '...',
                  })}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" sx={{ width: '50%' }}>
              <Box>
                <BuildIcon
                  sx={{ color: grey['500'], width: '18px', height: '18px' }}
                />
              </Box>
              <Box l={0.5}>
                <Typography variant="body2">
                  {truncateString(workOrder.jobType, {
                    length: 12,
                    ending: '...',
                  })}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box>
            <EmployeeStatusRing color={green['400']} size="xs">
              <EmployeeAvatar
                width={'35px'}
                height={'35px'}
                style={{ borderRadius: '50%' }}
              />
            </EmployeeStatusRing>
          </Box>
          <Box ml={1}>
            <Typography variant="h5">{workOrder.totalWorking}</Typography>
          </Box>
          <Box ml={1.5}>
            <EmployeeStatusRing color={orange['400']} size="xs">
              <EmployeeAvatar
                width={'35px'}
                height={'35px'}
                style={{ borderRadius: '50%' }}
              />
            </EmployeeStatusRing>
          </Box>
          <Box ml={1}>
            <Typography variant="h5">{workOrder.totalIdle}</Typography>
          </Box>
        </Box>
        <Box display="flex" mx={1.2}>
          <Tooltip title="Go to Job in Job View">
            <IconButton
              onClick={() =>
                navigate('/etime/labor/job', {
                  state: { workOrderId: workOrder.id },
                })
              }
            >
              <LaunchIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Paper>
  );
};

export default LaborSummaryTile;
