import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  FormHelperText,
  FormControl,
  Card,
  CardHeader,
  CardContent,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';
import isEmpty from 'is-empty';
import { states } from '../../utils/states';
import { countries } from '../../utils/countries';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string()
    .email('Please provide a valid email')
    .required('Required'),
  contactFirstName: Yup.string().required('Required'),
  contactLastName: Yup.string().required('Required'),
  contactEmail: Yup.string().email('Please provide a valid email'),
});

const CustomerForm = ({
  customer,
  editMode,
  handleOnSubmit,
  handleOnCancel,
  savingData,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          let customerData = {
            name: data.name,
            email: data.email,
            address: {
              line1: data.addressLine1,
              line2: data.addressLine2,
              city: data.addressCity,
              state: data.addressState,
              zip: data.addressZip,
              country: data.addressCountry,
            },
            contact: {
              firstName: data.contactFirstName,
              lastName: data.contactLastName,
              email: data.contactEmail,
              phone: data.contactPhone,
            },
          };
          Object.keys(customerData.address).forEach(
            (k) =>
              (customerData.address[k] == null ||
                customerData.address[k] === '') &&
              delete customerData.address[k]
          );
          Object.keys(customerData.contact).forEach(
            (k) =>
              (customerData.contact[k] == null ||
                customerData.contact[k] === '') &&
              delete customerData.contact[k]
          );
          handleOnSubmit(customerData);
        })}
      >
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box display='flex' my={1.5} flexGrow={1} alignItems='center'>
              <Typography
                color='primary'
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'Customer'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name='name'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(customer?.name) ? customer.name : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Name'
                      fullWidth
                      error={!!errors?.name}
                      helperText={errors?.name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name='email'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(customer?.email) ? customer.email : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Email'
                      fullWidth
                      error={!!errors?.email}
                      helperText={errors?.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Card variant='outlined'>
                  <CardHeader
                    title='Address'
                    sx={{ paddingBottom: 1, paddingTop: 1 }}
                  />
                  <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name='addressLine1'
                          control={control}
                          defaultValue={
                            editMode && !isEmpty(customer?.address)
                              ? customer.address.line1
                              : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='Street'
                              fullWidth
                              error={!!errors?.addressLine1}
                              helperText={errors?.addressLine1?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name='addressLine2'
                          control={control}
                          defaultValue={
                            editMode && !isEmpty(customer?.address)
                              ? customer.address.line2
                              : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='APT/Suite/PO Box'
                              fullWidth
                              error={!!errors?.addressLine2}
                              helperText={errors?.addressLine2?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name='addressCity'
                          control={control}
                          defaultValue={
                            editMode && !isEmpty(customer?.address)
                              ? customer.address.city
                              : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='City'
                              fullWidth
                              error={!!errors?.addressCity}
                              helperText={errors?.addressCity?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ minWidth: 250 }}>
                          <InputLabel id='state-select-label'>State</InputLabel>
                          <Controller
                            name='addressState'
                            control={control}
                            defaultValue={
                              editMode && !isEmpty(customer?.address)
                                ? customer.address.state
                                : ''
                            }
                            render={({ field }) => (
                              <Select
                                {...field}
                                labelId='state-select-label'
                                label={'State'}
                                fullWidth
                                error={!!errors?.addressState}
                              >
                                {states.map((state) => (
                                  <MenuItem
                                    key={state.abbreviation}
                                    value={state.abbreviation}
                                  >
                                    {`${state.name} (${state.abbreviation})`}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                        <FormHelperText>
                          {errors?.addressState?.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name='addressZip'
                          control={control}
                          defaultValue={
                            editMode && !isEmpty(customer?.address)
                              ? customer.address.zip
                              : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='ZIP'
                              fullWidth
                              error={!!errors?.addressZip}
                              helperText={errors?.addressZip?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormControl fullWidth sx={{ minWidth: 250 }}>
                          <InputLabel id='state-select-label'>
                            Country
                          </InputLabel>
                          <Controller
                            name='addressCountry'
                            control={control}
                            defaultValue={
                              editMode && !isEmpty(customer?.address)
                                ? customer.address.country
                                : ''
                            }
                            render={({ field }) => (
                              <Select
                                {...field}
                                labelId='state-select-label'
                                label={'Country'}
                                fullWidth
                                error={!!errors?.addressCountry}
                              >
                                {countries.map((country) => (
                                  <MenuItem
                                    key={country.code}
                                    value={country.code}
                                  >
                                    {`${country.name} (${country.code})`}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                        <FormHelperText>
                          {errors?.addressCountry?.message}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card variant='outlined'>
                  <CardHeader
                    title='Contact'
                    sx={{ paddingBottom: 1, paddingTop: 1 }}
                  />
                  <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name='contactFirstName'
                          control={control}
                          defaultValue={
                            editMode && !isEmpty(customer?.contact.firstName)
                              ? customer.contact.firstName
                              : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='First Name'
                              fullWidth
                              error={!!errors?.contactFirstName}
                              helperText={errors?.contactFirstName?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name='contactLastName'
                          control={control}
                          defaultValue={
                            editMode && !isEmpty(customer?.contact.lastName)
                              ? customer.contact.lastName
                              : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='Last Name'
                              fullWidth
                              error={!!errors?.contactLastName}
                              helperText={errors?.contactLastName?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name='contactEmail'
                          control={control}
                          defaultValue={
                            editMode && !isEmpty(customer?.contact.email)
                              ? customer.contact.email
                              : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='Email'
                              fullWidth
                              error={!!errors?.contactEmail}
                              helperText={errors?.contactEmail?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name='contactPhone'
                          control={control}
                          defaultValue={
                            editMode && !isEmpty(customer?.contact.phone)
                              ? customer.contact.phone
                              : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='Phone'
                              fullWidth
                              error={!!errors?.contactPhone}
                              helperText={errors?.contactPhone?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Box component='div' mx={1} my={0.5} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
                startIcon={<SaveIcon />}
              >
                {savingData ? 'Saving...' : 'Save'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UndoIcon />}
                onClick={() => {
                  reset();
                  handleOnCancel();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default CustomerForm;
