import {
  SET_SETUP_CHECKLIST_ITEM,
  SETUP_CHECKLIST_ITEM_LOADING,
  SETUP_CHECKLIST_ITEM_RESET,
  SET_SETUP_CHECKLIST_ITEMS,
  SETUP_CHECKLIST_ITEM_LIST_LOADING,
  SETUP_CHECKLIST_ITEM_LIST_RESET,
  ADD_SETUP_CHECKLIST_ITEM_TO_LIST,
  UPDATE_SETUP_CHECKLIST_ITEM_IN_LIST,
  DELETE_SETUP_CHECKLIST_ITEM_FROM_LIST,
  SHOW_ADD_EDIT_SETUP_CHECKLIST_ITEM_FORM,
  SAVING_SETUP_CHECKLIST_ITEM_DATA,
} from './types';

export const setSetupChecklistItem = (item) => {
  return {
    type: SET_SETUP_CHECKLIST_ITEM,
    payload: item,
  };
};

export const resetSetupChecklistItem = () => {
  return {
    type: SETUP_CHECKLIST_ITEM_RESET,
  };
};

export const setSetupChecklistItemLoading = (payload) => {
  return {
    type: SETUP_CHECKLIST_ITEM_LOADING,
    payload,
  };
};

export const setSetupChecklistItemList = (list) => {
  return {
    type: SET_SETUP_CHECKLIST_ITEMS,
    payload: list,
  };
};

export const resetSetupChecklistItemList = () => {
  return {
    type: SETUP_CHECKLIST_ITEM_LIST_RESET,
  };
};

export const setSetupChecklistItemListLoading = (payload) => {
  return {
    type: SETUP_CHECKLIST_ITEM_LIST_LOADING,
    payload,
  };
};

export const addSetupChecklistItemToList = (payload) => {
  return {
    type: ADD_SETUP_CHECKLIST_ITEM_TO_LIST,
    payload,
  };
};

export const deleteSetupChecklistItemFromList = (payload) => {
  return {
    type: DELETE_SETUP_CHECKLIST_ITEM_FROM_LIST,
    payload,
  };
};

export const updateSetupChecklistItemInList = (payload) => {
  return {
    type: UPDATE_SETUP_CHECKLIST_ITEM_IN_LIST,
    payload,
  };
};

export const showAddEditSetupChecklistItemForm = (payload) => {
  return {
    type: SHOW_ADD_EDIT_SETUP_CHECKLIST_ITEM_FORM,
    payload,
  };
};

export const savingSetupChecklistItemData = (payload) => {
  return {
    type: SAVING_SETUP_CHECKLIST_ITEM_DATA,
    payload,
  };
};
