import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  resetUnallocatedList,
  toggleSelectUnallocatedInList,
  showAssignJobForm,
} from '../../redux/etime/labor/unallocated/actions';
import {
  loadUnallocatedList,
  assignEmployeeToJob,
} from '../../redux/etime/labor/unallocated/services';
import {
  selectLastnameUniqueInitials,
  selectFilteredUnallocatedList,
  selectIdsForSelectedEmployees,
  selectSelectedEmployees,
} from '../../redux/etime/labor/unallocated/selectors';
import { loadWorkOrderList } from '../../redux/list/work-orders/services';
import { resetListWorkOrderList } from '../../redux/list/work-orders/actions';
import { getListWorkOrderList } from '../../redux/list/work-orders/selectors';
import { loadMachineList } from '../../redux/list/machines/services';
import { resetMachineList } from '../../redux/list/machines/actions';
import { getMachineList } from '../../redux/list/machines/selectors';
import { loadJobTypeList } from '../../redux/list/job-types/services';
import { resetJobTypeList } from '../../redux/list/job-types/actions';
import { getJobTypeList } from '../../redux/list/job-types/selectors';
import { loadEmployeePositionList } from '../../redux/list/employee-positions/services';
import { resetEmployeePositionList } from '../../redux/list/employee-positions/actions';
import { getEmployeePositionList } from '../../redux/list/employee-positions/selectors';
import { Button, Box, Dialog, DialogContent, Tooltip } from '@mui/material';
import Empty from '../../components/UI/Empty';
import LastnameNavigator from '../../components/etime/LastnameNavigator';
import LaborUnallocatedTileList from '../../components/etime/LaborUnallocatedTileList';
import AssignJobForm from '../../components/etime/AssignJobForm';
import Loading from '../../components/UI/Loading';
import FlashOnIcon from '@mui/icons-material/FlashOn';
const isEmpty = require('is-empty');

const Unallocated = () => {
  const dispatch = useDispatch();
  const [employeeToAssignJob, setEmployeeToAssignJob] = useState(null);
  const unallocatedState = useSelector((state) => state.labor_unallocated);
  const LastNameUniqueInitials = useSelector(selectLastnameUniqueInitials);
  const FilteredUnallocatedList = useSelector(selectFilteredUnallocatedList);
  const IdsForSelectedEmployees = useSelector(selectIdsForSelectedEmployees);
  const SelectedEmployees = useSelector(selectSelectedEmployees);
  const WorkOrderList = useSelector(getListWorkOrderList);
  const MachineList = useSelector(getMachineList);
  const JobTypeList = useSelector(getJobTypeList);
  const EmployeePositionList = useSelector(getEmployeePositionList);

  useEffect(() => {
    dispatch(loadUnallocatedList());
    dispatch(loadWorkOrderList());
    dispatch(loadMachineList());
    dispatch(loadJobTypeList());
    dispatch(loadEmployeePositionList());
    return () => {
      dispatch(resetUnallocatedList());
      dispatch(resetListWorkOrderList());
      dispatch(resetMachineList());
      dispatch(resetJobTypeList());
      dispatch(resetEmployeePositionList());
    };
  }, [dispatch]);

  const multipleEmployeesSelected = SelectedEmployees?.length > 1;

  const handleOnClickAssignJob = (employee) => {
    if (
      isEmpty(WorkOrderList) ||
      isEmpty(MachineList) ||
      isEmpty(JobTypeList) ||
      isEmpty(JobTypeList)
    ) {
      alert('Error loading data. Try refreshing the page.');
      return;
    }
    if (!multipleEmployeesSelected) {
      setEmployeeToAssignJob(employee);
    }
    dispatch(showAssignJobForm(true));
  };

  const handleOnClickAssignJobMultiple = () => {
    if (
      isEmpty(WorkOrderList) ||
      isEmpty(MachineList) ||
      isEmpty(JobTypeList) ||
      isEmpty(JobTypeList)
    ) {
      alert('Error loading data. Try refreshing the page.');
      return;
    }
    dispatch(showAssignJobForm(true));
  };

  const handleCloseAssignJobFormDialog = () => {
    dispatch(showAssignJobForm(false));
  };

  const handleClickOnSelectTile = (employee) => {
    dispatch(toggleSelectUnallocatedInList(employee));
  };

  const handleOnSubmitAssignJobFormDialog = (values) => {
    const asssignData = {
      workOrderId: values.workOrderId,
      machineId: values.machineId,
      jobTypeId: values.jobTypeId,
      employeePositionId: values.employeePositionId,
      gateLogIds: multipleEmployeesSelected
        ? IdsForSelectedEmployees
        : [values.employeeId],
    };
    dispatch(assignEmployeeToJob(asssignData));
  };

  return (
    <>
      <Loading loading={unallocatedState.loading} />
      {IdsForSelectedEmployees?.length > 1 && (
        <Box my={1} display="flex" justifyContent="flex-end">
          <Tooltip title="Asign Job">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<FlashOnIcon />}
              sx={{ marginRight: '0.5rem' }}
              onClick={handleOnClickAssignJobMultiple}
            >
              Asign Job
            </Button>
          </Tooltip>
        </Box>
      )}
      <Box my={1}>
        <Box ml={1} my={0} display="flex" flexDirection="row">
          <Box>
            {!isEmpty(LastNameUniqueInitials) && (
              <LastnameNavigator lastNameInitials={LastNameUniqueInitials} />
            )}
          </Box>
          <Box flexGrow={1} px={1} ml={5}>
            {!isEmpty(unallocatedState.list) && (
              <LaborUnallocatedTileList
                list={FilteredUnallocatedList}
                handleClickOnSelectTile={handleClickOnSelectTile}
                handleOnClickAssignJob={handleOnClickAssignJob}
              />
            )}
            {isEmpty(FilteredUnallocatedList) && !unallocatedState.loading && (
              <Empty description="There are no unallocated employees to show" />
            )}
          </Box>
        </Box>
      </Box>
      <Dialog
        open={unallocatedState.showAssignJobForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseAssignJobFormDialog();
          }
        }}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <AssignJobForm
            employee={employeeToAssignJob}
            employees={SelectedEmployees}
            multipleEmployees={multipleEmployeesSelected}
            workOrderList={WorkOrderList}
            machineList={MachineList}
            jobTypeList={JobTypeList}
            employeePositionList={EmployeePositionList}
            savingData={unallocatedState.savingData}
            handleOnCancel={handleCloseAssignJobFormDialog}
            handleOnSubmit={handleOnSubmitAssignJobFormDialog}
            handleClickOnSelectTile={handleClickOnSelectTile}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Unallocated;
