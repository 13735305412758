import React from 'react';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { Box, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';

const DataGrid = styled(MuiDataGrid)({
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
});

const ContactsTable = ({ rows, handleClickEdit, handleClickRemove }) => {
  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 150,
      hideable: false,
      editable: false,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 150,
      hideable: false,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      hideable: false,
      editable: false,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 120,
      hideable: false,
      editable: false,
    },
    {
      field: 'position',
      headerName: 'Position',
      width: 120,
      hideable: false,
      editable: false,
    },
    {
      field: 'mainContact',
      headerName: 'Main Contact',
      width: 100,
      hideable: false,
      editable: false,
      valueGetter: (params) => {
        return params.row.isMainContact ? 'Yes' : 'No';
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      type: 'actions',
      hideable: false,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <Box>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => {
                  handleClickEdit(cellValues.row);
                }}
                color='primary'
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Remove'>
              <IconButton
                onClick={() => handleClickRemove(cellValues.row)}
                sx={{ color: 'error.main' }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <Box mx={1} my={1} sx={{ height: '550px', width: '98%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default ContactsTable;
