export const SET_MOR_PROFILE = 'SET_MOR_PROFILE';
export const MOR_PROFILE_LOADING = 'MOR_PROFILE_LOADING';
export const MOR_PROFILE_RESET = 'MOR_PROFILE_RESET';
export const SET_MOR_PROFILES = 'SET_MOR_PROFILES';
export const MOR_PROFILE_LIST_LOADING = 'MOR_PROFILE_LIST_LOADING';
export const MOR_PROFILE_LIST_RESET = 'MOR_PROFILE_LIST_RESET';
export const ADD_MOR_PROFILE_TO_LIST = 'ADD_MOR_PROFILE_TO_LIST';
export const UPDATE_MOR_PROFILE_IN_LIST = 'UPDATE_MOR_PROFILE_IN_LIST';
export const DELETE_MOR_PROFILE_FROM_LIST = 'DELETE_MOR_PROFILE_FROM_LIST';
export const SAVING_MOR_PROFILE_DATA = 'SAVING_MOR_PROFILE_DATA';
export const SET_MOR_PROFILE_FILTERS = 'SET_MOR_PROFILE_FILTERS';
export const RESET_MOR_PROFILE_FILTERS = 'RESET_MOR_PROFILE_FILTERS';
export const SHOW_DUPLICATE_MOR_PROFILE_FORM =
  'SHOW_DUPLICATE_MOR_PROFILE_FORM';
