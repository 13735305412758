import {
  SET_MOR_PROFILE,
  MOR_PROFILE_LOADING,
  MOR_PROFILE_RESET,
  SET_MOR_PROFILES,
  MOR_PROFILE_LIST_LOADING,
  MOR_PROFILE_LIST_RESET,
  ADD_MOR_PROFILE_TO_LIST,
  UPDATE_MOR_PROFILE_IN_LIST,
  DELETE_MOR_PROFILE_FROM_LIST,
  SAVING_MOR_PROFILE_DATA,
  SET_MOR_PROFILE_FILTERS,
  RESET_MOR_PROFILE_FILTERS,
  SHOW_DUPLICATE_MOR_PROFILE_FORM,
} from './types';

export const setMORProfile = (item) => {
  return {
    type: SET_MOR_PROFILE,
    payload: item,
  };
};

export const resetMORProfile = () => {
  return {
    type: MOR_PROFILE_RESET,
  };
};

export const setMORProfileLoading = (payload) => {
  return {
    type: MOR_PROFILE_LOADING,
    payload,
  };
};

export const setMORProfileList = (list) => {
  return {
    type: SET_MOR_PROFILES,
    payload: list,
  };
};

export const resetMORProfileList = () => {
  return {
    type: MOR_PROFILE_LIST_RESET,
  };
};

export const setMORProfileListLoading = (payload) => {
  return {
    type: MOR_PROFILE_LIST_LOADING,
    payload,
  };
};

export const addMORProfileToList = (payload) => {
  return {
    type: ADD_MOR_PROFILE_TO_LIST,
    payload,
  };
};

export const deleteMORProfileFromList = (payload) => {
  return {
    type: DELETE_MOR_PROFILE_FROM_LIST,
    payload,
  };
};

export const updateMORProfileInList = (payload) => {
  return {
    type: UPDATE_MOR_PROFILE_IN_LIST,
    payload,
  };
};

export const savingMORProfileData = (payload) => {
  return {
    type: SAVING_MOR_PROFILE_DATA,
    payload,
  };
};

export const setMORProfileFilters = (payload) => {
  return {
    type: SET_MOR_PROFILE_FILTERS,
    payload,
  };
};

export const resetMORProfileFilters = () => {
  return {
    type: RESET_MOR_PROFILE_FILTERS,
  };
};

export const showDuplicateMORProfileForm = (payload) => {
  return {
    type: SHOW_DUPLICATE_MOR_PROFILE_FORM,
    payload,
  };
};
