import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getCompanyList = createSelector(
  (state) => state.list_company.list,
  (companyList) => {
    if (isEmpty(companyList)) return [];
    return companyList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);

const getCurrentCompanyId = (state) => state.auth.user.company.id;

export const getCompanyListExceptCurrent = createSelector(
  (state) => state.list_company.list,
  getCurrentCompanyId,
  (companyList, currentCompanyId) => {
    if (isEmpty(companyList)) return [];
    return companyList
      .filter((item) => item.id !== currentCompanyId)
      .sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
