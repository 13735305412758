import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setMachine,
  resetMachine,
  resetMachineList,
  showAddEditMachineForm,
} from '../../redux/epack/machines/actions';
import {
  loadMachines,
  createMachine,
  updateMachine,
  deleteMachine,
  toggleMachineStatus,
} from '../../redux/epack/machines/services';
import { getMachineList } from '../../redux/epack/machines/selectors';
import { loadMachineTypeList } from '../../redux/list/machine-types/services';
import { resetMachineTypeList } from '../../redux/list/machine-types/actions';
import { getMachineTypeList } from '../../redux/list/machine-types/selectors';
import { loadCommodityList } from '../../redux/list/commodities/services';
import { resetCommodityList } from '../../redux/list/commodities/actions';
import { getCommodityList } from '../../redux/list/commodities/selectors';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Paper,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import Loading from '../../components/UI/Loading';
import MachineForm from '../../components/epack/MachineForm';
import Empty from '../../components/UI/Empty';
import MachinesTable from '../../components/epack/MachinesTable';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
const isEmpty = require('is-empty');

const Machines = () => {
  const dispatch = useDispatch();
  const machinesState = useSelector((state) => state.machines);
  const machineState = useSelector((state) => state.machines.view.record);
  const MachinesList = useSelector(getMachineList);
  const MachineTypeList = useSelector(getMachineTypeList);
  const CommodityList = useSelector(getCommodityList);
  const [editMode, setEditMode] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [openRemoveMachineDialog, setOpenRemoveMachineDialog] = useState(false);

  useEffect(() => {
    dispatch(loadMachines());
    dispatch(loadMachineTypeList());
    dispatch(loadCommodityList());
    return () => {
      dispatch(resetMachineList());
      dispatch(resetMachineTypeList());
      dispatch(resetCommodityList());
    };
  }, [dispatch]);

  const handleClickAdd = () => {
    setEditMode(false);
    dispatch(showAddEditMachineForm(true));
  };

  const handleClickEdit = (machine) => {
    setEditMode(true);
    dispatch(setMachine(machine));
    dispatch(showAddEditMachineForm(true));
  };

  const onSubmitAddEdit = (data) => {
    const machineData = {
      name: data.name,
      model: data.model,
      operators: data.operators,
      manufacturer: data.manufacturer,
      categoryId: data.categoryId,
      commodityIds: [...new Set(data.commodityIds.map((item) => item.id))],
    };
    if (editMode) {
      dispatch(updateMachine(machineState.id, machineData));
    } else {
      dispatch(createMachine(machineData));
    }
  };

  const handleRefresh = () => {
    dispatch(loadMachines());
  };

  const handleClickToogleStatus = (machine) => {
    dispatch(setMachine(machine));
    setOpenStatusDialog(true);
  };

  const onToggleStatus = () => {
    dispatch(toggleMachineStatus(machineState.id, machineState.active));
    dispatch(resetMachine());
    setOpenStatusDialog(false);
  };

  const handleClickRemove = (machine) => {
    dispatch(setMachine(machine));
    setOpenRemoveMachineDialog(true);
  };

  const onRemoveMachine = () => {
    dispatch(deleteMachine(machineState.id));
    dispatch(resetMachine());
    setOpenRemoveMachineDialog(false);
  };

  return (
    <>
      <Loading loading={machinesState.loading} />
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box display='flex' alignContent='center'>
            <IconButton aria-label='reload' onClick={handleRefresh}>
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              ePack - Machines
            </Typography>
          </Box>
          <Tooltip title='Add Machine'>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<AddIcon />}
              sx={{ marginRight: '0.5rem' }}
              onClick={handleClickAdd}
            >
              Add
            </Button>
          </Tooltip>
        </Box>
        <Box sx={{ width: '100%' }}>
          {!isEmpty(machinesState.list) && (
            <MachinesTable
              rows={MachinesList}
              handleClickToogleStatus={handleClickToogleStatus}
              handleClickEdit={handleClickEdit}
              handleClickRemove={handleClickRemove}
            />
          )}
          {isEmpty(machinesState.list) && !machinesState.loading && (
            <Empty description='There are no machines to show' />
          )}
        </Box>
      </Paper>
      <ConfirmationDialog
        open={openRemoveMachineDialog}
        handleNo={() => {
          setOpenRemoveMachineDialog(false);
        }}
        handleYes={onRemoveMachine}
        title='ePack - Remove Machine'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to remove machine: ${machineState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openStatusDialog}
        handleNo={() => {
          setOpenStatusDialog(false);
        }}
        handleYes={onToggleStatus}
        title='ePack - Machine Status'
        YesText={'Yes, Change'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to ${
            machineState?.active ? 'deactivate' : 'activate'
          } machine: ${machineState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <Dialog
        open={machinesState.showAddEditForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setEditMode(false);
            dispatch(resetMachine());
            dispatch(showAddEditMachineForm(false));
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <MachineForm
            editMode={editMode}
            machine={machineState}
            machineTypeList={MachineTypeList}
            commoditiesList={CommodityList}
            handleOnCancel={() => {
              setEditMode(false);
              dispatch(resetMachine());
              dispatch(showAddEditMachineForm(false));
            }}
            handleOnSubmit={onSubmitAddEdit}
            savingData={machinesState.savingData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Machines;
