import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getJobViewList = createSelector(
  (state) => state.labor_job_view.list,
  (jobViewList) => {
    if (isEmpty(jobViewList)) return [];
    return jobViewList.sort((a, b) =>
      a.jobType.name < b.jobType.name ? -1 : 1
    );
  }
);
