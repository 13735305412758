import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';
import isEmpty from 'is-empty';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  operators: Yup.number().typeError('Must be a number'),
  categoryId: Yup.string().required('Category is required'),
  commodityIds: Yup.array().required('Commodities are required'),
});

const MachineForm = ({
  machine,
  editMode,
  machineTypeList,
  commoditiesList,
  handleOnSubmit,
  handleOnCancel,
  savingData,
}) => {
  const {
    setValue,
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });
  const [selectedCommodities, setSelectedCommodities] = useState([]);

  useEffect(() => {
    register('commodityIds');
  }, [register]);

  useEffect(() => {
    if (editMode) {
      if (!isEmpty(machine.commodities)) {
        setSelectedCommodities(machine.commodities);
        setValue('commodityIds', [...machine.commodities]);
      }
    }
  }, [editMode, machine, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box display='flex' my={1.5} flexGrow={1} alignItems='center'>
              <Typography
                color='primary'
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'ePack - Machine'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='name'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(machine?.name) ? machine.name : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Name'
                      fullWidth
                      error={!!errors?.name}
                      helperText={errors?.name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='model'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(machine?.model) ? machine.model : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Model'
                      fullWidth
                      error={!!errors?.model}
                      helperText={errors?.model?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='operators'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(machine?.operators)
                      ? machine.operators
                      : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Operators'
                      fullWidth
                      type='number'
                      error={!!errors?.operators}
                      helperText={errors?.operators?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='manufacturer'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(machine?.manufacturer)
                      ? machine.manufacturer
                      : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Manufacturer'
                      fullWidth
                      error={!!errors?.manufacturer}
                      helperText={errors?.manufacturer?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl fullWidth sx={{ minWidth: 250 }}>
                  <InputLabel id='category-select-label'>Category</InputLabel>
                  <Controller
                    name='categoryId'
                    control={control}
                    defaultValue={
                      editMode && !isEmpty(machine?.category)
                        ? machine.category.id
                        : ''
                    }
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId='category-select-label'
                        label={'Category'}
                        fullWidth
                        error={!!errors?.categoryId}
                      >
                        {machineTypeList.map((machineType) => (
                          <MenuItem key={machineType.id} value={machineType.id}>
                            {machineType.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <FormHelperText>{errors?.categoryId?.message}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  name='commodityIds'
                  options={commoditiesList}
                  value={selectedCommodities}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select Commodities'
                      variant='outlined'
                      error={!!errors?.commodityIds}
                    />
                  )}
                  onChange={(_, values) => {
                    setSelectedCommodities([...values]);
                    setValue('commodityIds', [...values]);
                  }}
                />
                <FormHelperText>{errors?.commodityIds?.message}</FormHelperText>
              </Grid>
            </Grid>
          </Box>
          <Box component='div' mx={1} my={0.5} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
                startIcon={<SaveIcon />}
              >
                {savingData ? 'Saving...' : 'Save'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UndoIcon />}
                onClick={() => {
                  reset();
                  handleOnCancel();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default MachineForm;
