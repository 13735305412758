import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  registerCompany,
  validateCompanyName,
  validateCompanyEmail,
  validateUserEmail,
} from '../../redux/system/registration/services';
import RegisterForm from '../../components/registration/RegisterForm';
import { Typography } from '@mui/material';

function Register() {
  const registrationState = useSelector((state) => state.registration);
  const dispatch = useDispatch();

  const handleOnSubmit = (data) => {
    dispatch(registerCompany(data));
  };

  const handleOnValidateCompanyName = (name) => {
    dispatch(validateCompanyName({ name }));
  };

  const handleOnValidateCompanyEmail = (email) => {
    dispatch(validateCompanyEmail({ email }));
  };

  const handleOnValidateUserEmail = (email) => {
    dispatch(validateUserEmail({ email }));
  };

  return (
    <>
      {registrationState.registrationComplete === true ? (
        <div>
          <Typography variant='h6' sx={{ marginBottom: 3 }}>
            Thank you!
          </Typography>
          <Typography variant='body1'>
            Your registration has been successfully completed. You have just
            been sent an email containing instructions.
          </Typography>
        </div>
      ) : (
        <RegisterForm
          handleOnSubmit={handleOnSubmit}
          loading={registrationState.registrationLoading}
          validating={registrationState.validating}
          companyNameTaken={registrationState.companyNameTaken}
          companyEmailTaken={registrationState.companyEmailTaken}
          userEmailTaken={registrationState.userEmailTaken}
          handleOnValidateCompanyName={handleOnValidateCompanyName}
          handleOnValidateCompanyEmail={handleOnValidateCompanyEmail}
          handleOnValidateUserEmail={handleOnValidateUserEmail}
        />
      )}
    </>
  );
}

export default Register;
