import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import DashboardHeader from './DashboardHeader';

const Layout = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [showFavorites, setShowFavorites] = useState(false);
  const handleShowHideFavs = () => {
    setShowFavorites(!showFavorites);
  };
  return (
    <>
      <DashboardHeader
        showFavorites={showFavorites}
        handleShowHideFavs={handleShowHideFavs}
        isAuthenticated={isAuthenticated}
      />
      <Outlet />
    </>
  );
};

export default Layout;
