import {
  SET_EMPLOYER_LIST,
  EMPLOYER_LIST_LOADING,
  EMPLOYER_LIST_RESET,
} from './types';

export const setEmployerList = (list) => {
  return {
    type: SET_EMPLOYER_LIST,
    payload: list,
  };
};

export const resetEmployerList = () => {
  return {
    type: EMPLOYER_LIST_RESET,
  };
};

export const setEmployerListLoading = (payload) => {
  return {
    type: EMPLOYER_LIST_LOADING,
    payload,
  };
};
