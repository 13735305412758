import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetJobViewList } from '../../redux/etime/labor/job-view/actions';
import {
  loadJobViewList,
  startByJob,
  stopByJob,
  startLaborLog,
  stopLaborLog,
  changePosition,
  moveLaborLog,
  moveByJob,
  walkOff,
} from '../../redux/etime/labor/job-view/services';
import { getJobViewList } from '../../redux/etime/labor/job-view/selectors';
import { loadWorkOrderList } from '../../redux/list/work-orders/services';
import { resetListWorkOrderList } from '../../redux/list/work-orders/actions';
import { getListWorkOrderList } from '../../redux/list/work-orders/selectors';
import { loadMachineList } from '../../redux/list/machines/services';
import { resetMachineList } from '../../redux/list/machines/actions';
import { getMachineList } from '../../redux/list/machines/selectors';
import { loadJobTypeList } from '../../redux/list/job-types/services';
import { resetJobTypeList } from '../../redux/list/job-types/actions';
import { getJobTypeList } from '../../redux/list/job-types/selectors';
import { loadEmployeePositionList } from '../../redux/list/employee-positions/services';
import { resetEmployeePositionList } from '../../redux/list/employee-positions/actions';
import { getEmployeePositionList } from '../../redux/list/employee-positions/selectors';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import LaborJobViewTileList from '../../components/etime/LaborJobViewTileList';
import Empty from '../../components/UI/Empty';
import Loading from '../../components/UI/Loading';
import LaborLogPositionForm from '../../components/etime/LaborLogPositionForm';
import LaborLogMoveForm from '../../components/etime/LaborLogMoveForm';
import WorkOrderMoveForm from '../../components/etime/WorkOrderMoveForm';
import LaborLogWalkOffForm from '../../components/etime/LaborLogWalkOffForm';
const isEmpty = require('is-empty');

const LaborJobView = () => {
  const dispatch = useDispatch();
  const [expandedJobTiles, setExpandedJobTiles] = useState([]);
  const [openChangeLaborLogPosition, setOpenChangeLaborLogPosition] =
    useState(false);
  const [openMoveLaborLog, setOpenMoveLaborLog] = useState(false);
  const [openMoveByJob, setOpenMoveByJob] = useState(false);
  const [openWalkOff, setOpenWalkOff] = useState(false);
  const [job, setJob] = useState(null);
  const [laborLog, setLaborLog] = useState(null);
  const jobViewState = useSelector((state) => state.labor_job_view);
  const JobViewList = useSelector(getJobViewList);
  const WorkOrderList = useSelector(getListWorkOrderList);
  const MachineList = useSelector(getMachineList);
  const JobTypeList = useSelector(getJobTypeList);
  const EmployeePositionList = useSelector(getEmployeePositionList);

  useEffect(() => {
    dispatch(loadJobViewList());
    dispatch(loadWorkOrderList());
    dispatch(loadMachineList());
    dispatch(loadJobTypeList());
    dispatch(loadEmployeePositionList());
    return () => {
      dispatch(resetJobViewList());
      dispatch(resetListWorkOrderList());
      dispatch(resetMachineList());
      dispatch(resetJobTypeList());
      dispatch(resetEmployeePositionList());
    };
  }, [dispatch]);

  useEffect(() => {
    setExpandedJobTiles([...new Set(JobViewList.map((item) => item.id))]);
  }, [setExpandedJobTiles, JobViewList]);

  const handleClickStartLaborLog = (id) => {
    dispatch(startLaborLog(id));
  };

  const handleClickStopLaborLog = (id) => {
    dispatch(stopLaborLog(id));
  };

  const handleClickStartByJob = (id) => {
    dispatch(startByJob(id));
  };

  const handleClickStopByJob = (id) => {
    dispatch(stopByJob(id));
  };

  const handleClickMoveLaborLog = (laborLog, job) => {
    setLaborLog(laborLog);
    setJob(job);
    setOpenMoveLaborLog(true);
  };
  const onMoveLaborLog = (data) => {
    dispatch(moveLaborLog(data));
    setOpenMoveLaborLog(false);
  };
  const handleClickMoveByJob = (job) => {
    setJob(job);
    setOpenMoveByJob(true);
  };
  const onMoveByJob = (data) => {
    dispatch(moveByJob(job.id, data));
    setOpenMoveByJob(false);
  };
  const onChangePosition = (data) => {
    dispatch(changePosition(data));
    setOpenChangeLaborLogPosition(false);
  };
  const handleClickChangePosition = (laborLog, job) => {
    setLaborLog(laborLog);
    setJob(job);
    setOpenChangeLaborLogPosition(true);
  };

  const handleClickWalkOff = (laborLog, job) => {
    setLaborLog(laborLog);
    setJob(job);
    setOpenWalkOff(true);
  };
  const onWalkOff = (data) => {
    dispatch(walkOff(job.id, data));
    setOpenWalkOff(false);
  };

  return (
    <>
      <Loading loading={jobViewState.loading} />
      <Box my={1}>
        <Box my={0} display='flex' flexDirection='row'>
          <Box flexGrow={1} mx={1}>
            {!isEmpty(jobViewState.list) && (
              <LaborJobViewTileList
                list={JobViewList}
                expandedJobTiles={expandedJobTiles}
                setExpandedJobTiles={setExpandedJobTiles}
                handleClickStartLaborLog={handleClickStartLaborLog}
                handleClickStopLaborLog={handleClickStopLaborLog}
                handleClickStartByJob={handleClickStartByJob}
                handleClickStopByJob={handleClickStopByJob}
                handleClickMoveLaborLog={handleClickMoveLaborLog}
                handleClickMoveByJob={handleClickMoveByJob}
                handleClickChangePosition={handleClickChangePosition}
                handleClickWalkOff={handleClickWalkOff}
              />
            )}
            {isEmpty(JobViewList) && !jobViewState.loading && (
              <Empty description='There are no work orders to show' />
            )}
          </Box>
        </Box>
      </Box>

      <Dialog
        open={openChangeLaborLogPosition}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenChangeLaborLogPosition(false);
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <LaborLogPositionForm
            laborLog={laborLog}
            workOrder={job?.workOrder}
            machine={job?.machine}
            jobType={job?.jobType}
            employeePositions={EmployeePositionList}
            handleOnSubmit={onChangePosition}
            handleOnCancel={() => setOpenChangeLaborLogPosition(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openMoveLaborLog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenMoveLaborLog(false);
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <LaborLogMoveForm
            laborLog={laborLog}
            workOrder={job?.workOrder}
            machine={job?.machine}
            jobType={job?.jobType}
            workOrderList={WorkOrderList}
            machineList={MachineList}
            jobTypeList={JobTypeList}
            handleOnSubmit={onMoveLaborLog}
            handleOnCancel={() => setOpenMoveLaborLog(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openMoveByJob}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenMoveByJob(false);
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <Typography variant='body2'>{`Are you sure you want to MOVE employees from Job: ${job?.name} to a new job?, If yes please select a new work order below.`}</Typography>
          <WorkOrderMoveForm
            workOrder={job?.workOrder}
            machine={job?.machine}
            jobType={job?.jobType}
            workOrderList={WorkOrderList}
            machineList={MachineList}
            jobTypeList={JobTypeList}
            handleOnSubmit={onMoveByJob}
            handleOnCancel={() => setOpenMoveByJob(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openWalkOff}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenWalkOff(false);
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <LaborLogWalkOffForm
            laborLog={laborLog}
            workOrder={job?.workOrder}
            machine={job?.machine}
            jobType={job?.jobType}
            handleOnSubmit={onWalkOff}
            handleOnCancel={() => setOpenWalkOff(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LaborJobView;
