import {
  MACHINE_LOADING,
  SET_MACHINE,
  MACHINE_RESET,
  SET_MACHINES,
  MACHINES_LIST_LOADING,
  MACHINES_LIST_RESET,
  ADD_MACHINE_TO_LIST,
  UPDATE_MACHINE_IN_LIST,
  DELETE_MACHINE_FROM_LIST,
  SHOW_ADD_EDIT_MACHINE_FORM,
  SAVING_MACHINE_DATA,
} from './types';

export const setMachine = (machine) => {
  return {
    type: SET_MACHINE,
    payload: machine,
  };
};

export const setMachineList = (list) => {
  return {
    type: SET_MACHINES,
    payload: list,
  };
};

export const resetMachineList = () => {
  return {
    type: MACHINES_LIST_RESET,
  };
};

export const resetMachine = () => {
  return {
    type: MACHINE_RESET,
  };
};

export const setMachineListLoading = (payload) => {
  return {
    type: MACHINES_LIST_LOADING,
    payload,
  };
};

export const setMachineLoading = (payload) => {
  return {
    type: MACHINE_LOADING,
    payload,
  };
};
export const addMachineToList = (payload) => {
  return {
    type: ADD_MACHINE_TO_LIST,
    payload,
  };
};

export const deleteMachineFromList = (payload) => {
  return {
    type: DELETE_MACHINE_FROM_LIST,
    payload,
  };
};

export const updateMachineInList = (payload) => {
  return {
    type: UPDATE_MACHINE_IN_LIST,
    payload,
  };
};

export const showAddEditMachineForm = (payload) => {
  return {
    type: SHOW_ADD_EDIT_MACHINE_FORM,
    payload,
  };
};

export const savingMachineData = (payload) => {
  return {
    type: SAVING_MACHINE_DATA,
    payload,
  };
};
