import {
  SET_MANUAL_PROFILE,
  MANUAL_PROFILE_LOADING,
  MANUAL_PROFILE_RESET,
  SET_MANUAL_PROFILES,
  MANUAL_PROFILE_LIST_LOADING,
  MANUAL_PROFILE_LIST_RESET,
  ADD_MANUAL_PROFILE_TO_LIST,
  UPDATE_MANUAL_PROFILE_IN_LIST,
  DELETE_MANUAL_PROFILE_FROM_LIST,
  SAVING_MANUAL_PROFILE_DATA,
  SET_MANUAL_PROFILE_FILTERS,
  RESET_MANUAL_PROFILE_FILTERS,
  SHOW_DUPLICATE_MANUAL_PROFILE_FORM,
} from './types';
const initialState = {
  item: {},
  list: {},
  filters: {
    commodity: '',
    itemDescription: '',
  },
  loading: false,
  showDuplicateForm: false,
  savingData: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MANUAL_PROFILE:
      return {
        ...state,
        item: { ...action.payload },
      };
    case MANUAL_PROFILE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case MANUAL_PROFILE_RESET:
      return {
        ...state,
        item: {},
      };
    case SET_MANUAL_PROFILES:
      return {
        ...state,
        list: { ...action.payload },
      };
    case MANUAL_PROFILE_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case MANUAL_PROFILE_LIST_RESET:
      return {
        ...state,
        list: {},
      };
    case ADD_MANUAL_PROFILE_TO_LIST:
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          docs: [action.payload, ...state.list.docs],
          totalDocs: state.list.totalDocs + 1,
        },
      };
    case DELETE_MANUAL_PROFILE_FROM_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          docs: state.list.docs.filter((item) => item.id !== action.payload),
          totalDocs: state.list.totalDocs - 1,
        },
      };
    case SAVING_MANUAL_PROFILE_DATA:
      return {
        ...state,
        savingData: action.payload,
      };
    case SHOW_DUPLICATE_MANUAL_PROFILE_FORM:
      return {
        ...state,
        showDuplicateForm: action.payload,
      };
    case SET_MANUAL_PROFILE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case RESET_MANUAL_PROFILE_FILTERS:
      return {
        ...state,
        filters: {
          commodity: '',
          itemDescription: '',
        },
      };
    case UPDATE_MANUAL_PROFILE_IN_LIST:
      return {
        ...state,
        loading: false,
        list: {
          docs: state.list.docs.map((row) => {
            if (row.id === action.payload.id) {
              return action.payload;
            }
            return row;
          }),
        },
      };

    default:
      return state;
  }
}
