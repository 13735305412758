import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getLaborDashboardData = createSelector(
  (state) => state.labor_dashboard.data,
  (list) => {
    if (isEmpty(list)) return [];
    return list.sort((a, b) => (a.firstName < b.firstName ? -1 : 1));
  }
);

export const selectNumberOfActiveEmployees = createSelector(
  getLaborDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter((item) => item.isIdle === false).length;
  }
);

export const selectNumberOfIdleEmployees = createSelector(
  getLaborDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter(
      (item) => item.isIdle === true && item.unallocated === false
    ).length;
  }
);

export const selectNumberOfUnallocatedEmployees = createSelector(
  getLaborDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter((item) => item.unallocated === true).length;
  }
);

export const selectAllocationByJob = createSelector(
  getLaborDashboardData,
  (list) => {
    const data = {
      labels: [],
      values: [],
    };
    if (isEmpty(list)) return data;
    data.labels = [
      ...new Set(
        list
          .filter((item) => item.unallocated !== true)
          .map((item) => item.jobType)
      ),
    ];
    data.labels = data.labels.slice(0, 9);
    for (let i = 0; i < data.labels.length; i++) {
      data.values[i] = list.filter(
        (item) => item.jobType === data.labels[i]
      ).length;
    }
    return data;
  }
);

export const selectAllocationByPosition = createSelector(
  getLaborDashboardData,
  (list) => {
    const data = {
      labels: [],
      values: [],
    };
    if (isEmpty(list)) return data;
    data.labels = [
      ...new Set(
        list
          .filter((item) => item.unallocated !== true)
          .map((item) => item.employeePosition)
      ),
    ];
    data.labels = data.labels.slice(0, 9);
    for (let i = 0; i < data.labels.length; i++) {
      data.values[i] = list.filter(
        (item) => item.employeePosition === data.labels[i]
      ).length;
    }
    return data;
  }
);

export const selectAllocationByCommodity = createSelector(
  getLaborDashboardData,
  (list) => {
    const data = {
      labels: [],
      values: [],
    };
    if (isEmpty(list)) return data;
    data.labels = [
      ...new Set(
        list
          .filter((item) => item.unallocated !== true)
          .map((item) => item.commodity)
      ),
    ];
    data.labels = data.labels.slice(0, 9);
    for (let i = 0; i < data.labels.length; i++) {
      data.values[i] = list.filter(
        (item) => item.commodity === data.labels[i]
      ).length;
    }
    return data;
  }
);

export const selectAllocationByMachine = createSelector(
  getLaborDashboardData,
  (list) => {
    const data = {
      labels: [],
      values: [],
    };
    if (isEmpty(list)) return data;
    data.labels = [
      ...new Set(
        list
          .filter((item) => item.unallocated !== true)
          .map((item) => item.machine)
      ),
    ];
    data.labels = data.labels.slice(0, 9);
    for (let i = 0; i < data.labels.length; i++) {
      data.values[i] = list.filter(
        (item) => item.machine === data.labels[i]
      ).length;
    }
    return data;
  }
);
