import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { resetMainDashboardData } from '../redux/dashboard/actions';
import {
  loadMainDashboardData,
  loadMainDashboardPrivateData,
} from '../redux/dashboard/services';
import {
  selectAverageYield,
  selectAverageAvailability,
  selectAverageUtilization,
  selectAverageThroughput,
  selectAverageWaste,
  selectNumberOfAvailableProductionLines,
  selectNumberOfUnavailableProductionLines,
  selectNumberOfInProgressProductionLines,
  selectNumberOfSetupProductionLines,
  selectNumberOfCasesInProduction,
  selectNumberOfCasesOutProduction,
  selectNumberOfPalletsInProduction,
  selectNumberOfPalletsOutProduction,
  selectMachinesCasesInOut,
  selectNumberOfActiveEmployees,
  selectNumberOfIdleEmployees,
  selectNumberOfUnallocatedEmployees,
  selectAllocationByJob,
  selectAllocationByPosition,
} from '../redux/dashboard/selectors';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  BarElement,
  CategoryScale,
} from 'chart.js';
import { Doughnut, Bar, PolarArea } from 'react-chartjs-2';
import Empty from '../components/UI/Empty';
import DashboardNumericWidgetWithIconSmall from '../components/UI/DashboardNumericWidgetWithIconSmall';
import PersonIcon from '../components/UI/icons/PersonIcon';
import PalletIcon from '../components/UI/icons/PalletIcon';
import PalletInIcon from '../components/UI/icons/PalletInIcon';
import PalletOutIcon from '../components/UI/icons/PalletOutIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ReorderIcon from '@mui/icons-material/Reorder';
import {
  grey,
  green,
  red,
  orange,
  deepOrange,
  blue,
  deepPurple,
  teal,
  cyan,
} from '@mui/material/colors';
import { styled } from '@mui/system';
import { chartLightColors } from '../utils/chartColors';
import LoadingTransparent from '../components/UI/LoadingTransparent';
const isEmpty = require('is-empty');

const YieldCircle = styled('span')({
  width: '12px',
  height: '12px',
  backgroundColor: '#D62728',
  borderRadius: '50%',
  display: 'inline-block',
  marginLeft: '5px',
  marginRight: '5px',
});

const AvailabilityCircle = styled('span')({
  width: '12px',
  height: '12px',
  backgroundColor: '#2CA02C',
  borderRadius: '50%',
  display: 'inline-block',
  marginLeft: '5px',
  marginRight: '5px',
});

const ThroughputCircle = styled('span')({
  width: '12px',
  height: '12px',
  backgroundColor: '#5e35b1',
  borderRadius: '50%',
  display: 'inline-block',
  marginLeft: '5px',
  marginRight: '5px',
});

ChartJS.register(ArcElement, Tooltip, Legend, Title, BarElement, CategoryScale);

const machinesCasesInOutOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      enabled: true,
    },
  },
};

const allocationByStatusChartOptions = {
  responsive: true,
  circumference: 360,
  cutout: 0,
  plugins: {
    legend: {
      display: true,
      position: 'left',
    },
    tooltip: {
      enabled: true,
    },
  },
};

const allocationByJobChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'left',
    },
  },
};

const allocationByPositionChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'left',
    },
  },
};

const averageChartsOptions = {
  responsive: true,
  circumference: 180,
  rotation: -90,
  cutout: 50,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

const MainDashboard = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const [firstLoad, setFirstLoad] = useState(true);
  const mainDashboardState = useSelector((state) => state.main_dashboard);
  const AverageYield = useSelector(selectAverageYield);
  const AverageAvailability = useSelector(selectAverageAvailability);
  const AverageUtilization = useSelector(selectAverageUtilization);
  const AverageWaste = useSelector(selectAverageWaste);
  const AverageThroughput = useSelector(selectAverageThroughput);
  const NumberOfUnavailableProductionLines = useSelector(
    selectNumberOfUnavailableProductionLines
  );
  const NumberOfAvailableProductionLines = useSelector(
    selectNumberOfAvailableProductionLines
  );
  const NumberOfInProgressProductionLines = useSelector(
    selectNumberOfInProgressProductionLines
  );
  const NumberOfSetupProductionLines = useSelector(
    selectNumberOfSetupProductionLines
  );
  const NumberOfCasesInProduction = useSelector(
    selectNumberOfCasesInProduction
  );
  const NumberOfCasesOutProduction = useSelector(
    selectNumberOfCasesOutProduction
  );
  const NumberOfPalletsInProduction = useSelector(
    selectNumberOfPalletsInProduction
  );
  const NumberOfPalletsOutProduction = useSelector(
    selectNumberOfPalletsOutProduction
  );
  const MachinesCasesInOut = useSelector(selectMachinesCasesInOut);

  const NumberOfActiveEmployees = useSelector(selectNumberOfActiveEmployees);
  const NumberOfIdleEmployees = useSelector(selectNumberOfIdleEmployees);
  const NumberOfUnallocatedEmployees = useSelector(
    selectNumberOfUnallocatedEmployees
  );
  const AllocationByJob = useSelector(selectAllocationByJob);
  const AllocationByPosition = useSelector(selectAllocationByPosition);

  const totalEmployees =
    NumberOfActiveEmployees +
    NumberOfIdleEmployees +
    NumberOfUnallocatedEmployees;

  const allocationByStatusChartData = {
    labels: ['Working', 'Idle', 'Unallocated'],
    datasets: [
      {
        data: [
          NumberOfActiveEmployees,
          NumberOfIdleEmployees,
          NumberOfUnallocatedEmployees,
        ],
        backgroundColor: ['#2CA02C', '#FF7F0E', '#D62728'],
      },
    ],
  };

  const allocationByJobChartData = {
    labels: AllocationByJob.labels,
    datasets: [
      {
        data: AllocationByJob.values,
        backgroundColor: chartLightColors,
        borderWidth: 3,
      },
    ],
  };

  const allocationByPositionChartData = {
    labels: AllocationByPosition.labels,
    datasets: [
      {
        data: AllocationByPosition.values,
        backgroundColor: chartLightColors,
        borderWidth: 3,
      },
    ],
  };

  const getDashboardData = useCallback(() => {
    if (isEmpty(token)) {
      dispatch(loadMainDashboardData());
    } else {
      dispatch(loadMainDashboardPrivateData(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    getDashboardData();
    // refresh data every 2 minutes
    const interval = setInterval(getDashboardData, 60000);
    return () => {
      dispatch(resetMainDashboardData());
      clearInterval(interval);
    };
  }, [dispatch, getDashboardData]);

  useEffect(() => {
    setTimeout(() => {
      setFirstLoad(false);
    }, 1000);
    return () => {};
  }, [setFirstLoad]);

  return (
    <Container disableGutters sx={{ marginTop: '48px' }} maxWidth='xl'>
      <Paper elevation={0}>
        {firstLoad ? (
          <LoadingTransparent loading={mainDashboardState.loading} />
        ) : null}

        {isEmpty(mainDashboardState.data.labor) ||
        isEmpty(mainDashboardState.data.production) ? (
          <Empty description='No data to show' />
        ) : (
          <Box display='flex'>
            <Box sx={{ width: '65%' }}>
              <Card variant='outlined'>
                <CardContent
                  sx={{
                    padding: 1.5,
                    '&:last-child': {
                      paddingBottom: 0.8,
                    },
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      container
                      item
                      xs={10}
                      columns={10}
                      sx={{ marginTop: 1 }}
                    >
                      <Grid
                        item
                        xs={3}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '140px',
                        }}
                      >
                        <Box
                          sx={(theme) => ({
                            height: '180px',
                            width: '180px',
                            [theme.breakpoints.up('lg')]: {
                              height: '220px',
                              width: '220px',
                            },
                            position: 'relative',
                          })}
                        >
                          <Typography
                            variant='body2'
                            color={grey['700']}
                            sx={{ fontWeight: 'bold' }}
                          >
                            <YieldCircle />
                            Yield
                          </Typography>
                          <Typography
                            variant='h5'
                            sx={{
                              position: 'absolute',
                              top: '90%',
                              left: '50%',
                              transform: 'translate(-50%, -110%)',
                            }}
                          >{`${AverageYield}%`}</Typography>
                          <Doughnut
                            data={{
                              labels: ['#D62728', '#e0e0e0'],
                              datasets: [
                                {
                                  data: [AverageYield, 100 - AverageYield],
                                  backgroundColor: ['#D62728', '#e0e0e0'],
                                  borderColor: ['#D62728', '#e0e0e0'],
                                },
                              ],
                            }}
                            options={averageChartsOptions}
                            style={{
                              transform: 'translateY(-7px)',
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '140px',
                        }}
                      >
                        <Box
                          sx={(theme) => ({
                            height: '180px',
                            width: '180px',
                            [theme.breakpoints.up('lg')]: {
                              height: '220px',
                              width: '220px',
                            },
                            position: 'relative',
                          })}
                        >
                          <Typography
                            variant='body2'
                            color={grey['700']}
                            sx={{ fontWeight: 'bold' }}
                          >
                            <AvailabilityCircle />
                            Availability
                          </Typography>
                          <Typography
                            variant='h5'
                            sx={{
                              position: 'absolute',
                              top: '90%',
                              left: '50%',
                              transform: 'translate(-50%, -110%)',
                            }}
                          >{`${AverageAvailability}%`}</Typography>
                          <Doughnut
                            data={{
                              labels: ['#2CA02C', '#e0e0e0'],
                              datasets: [
                                {
                                  data: [
                                    AverageAvailability,
                                    100 - AverageAvailability,
                                  ],
                                  backgroundColor: ['#2CA02C', '#e0e0e0'],
                                  borderColor: ['#2CA02C', '#e0e0e0'],
                                },
                              ],
                            }}
                            options={averageChartsOptions}
                            style={{
                              transform: 'translateY(-7px)',
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '140px',
                        }}
                      >
                        <Box
                          sx={(theme) => ({
                            height: '180px',
                            width: '180px',
                            [theme.breakpoints.up('lg')]: {
                              height: '220px',
                              width: '220px',
                            },
                            position: 'relative',
                          })}
                        >
                          <Typography
                            variant='body2'
                            color={grey['700']}
                            sx={{ fontWeight: 'bold' }}
                          >
                            <ThroughputCircle />
                            Throughput
                          </Typography>
                          <Typography
                            variant='h5'
                            sx={{
                              position: 'absolute',
                              top: '90%',
                              left: '50%',
                              transform: 'translate(-50%, -110%)',
                            }}
                          >{`${AverageThroughput}`}</Typography>
                          <Doughnut
                            data={{
                              labels: ['#5e35b1', '#e0e0e0'],
                              datasets: [
                                {
                                  data: [
                                    AverageThroughput,
                                    100 - AverageThroughput,
                                  ],
                                  backgroundColor: ['#5e35b1', '#e0e0e0'],
                                  borderColor: ['#5e35b1', '#e0e0e0'],
                                },
                              ],
                            }}
                            options={averageChartsOptions}
                            style={{
                              transform: 'translateY(-7px)',
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={1}>
                        <Box
                          display='flex'
                          flexDirection='column'
                          sx={{ color: grey[100] }}
                        >
                          <Box
                            sx={{
                              padding: 1,
                              color: blue[700],
                            }}
                          >
                            <Box display='flex' flexDirection='column'>
                              <Box display='flex' justifyContent='center'>
                                <SettingsIcon sx={{ fontSize: 30 }} />
                              </Box>
                              <Box display='flex' justifyContent='center'>
                                <Typography sx={{ fontSize: 30 }}>
                                  {`${AverageUtilization}%`}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              padding: 1,
                              color: deepOrange[700],
                            }}
                          >
                            <Box display='flex' flexDirection='column'>
                              <Box display='flex' justifyContent='center'>
                                <DeleteOutlineIcon sx={{ fontSize: 30 }} />
                              </Box>
                              <Box display='flex' justifyContent='center'>
                                <Typography sx={{ fontSize: 30 }}>
                                  {`${AverageWaste}%`}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 5 }}>
                      <Grid container spacing={1}>
                        <Grid item xs={3}>
                          <DashboardNumericWidgetWithIconSmall
                            title='Idle'
                            titleColor={grey['100']}
                            backgroundImage={`linear-gradient( 135deg, ${grey[600]} 10%, ${grey[700]} 100%);`}
                            numericValue={NumberOfAvailableProductionLines}
                            numericValueColor={grey['100']}
                          >
                            <ReorderIcon
                              sx={{
                                width: '25px',
                                height: '25px',
                                marginRight: 1,
                                color: grey['100'],
                              }}
                            />
                          </DashboardNumericWidgetWithIconSmall>
                        </Grid>
                        <Grid item xs={3}>
                          <DashboardNumericWidgetWithIconSmall
                            title='Unavailable'
                            titleColor={grey['100']}
                            backgroundImage={`linear-gradient( 135deg, ${red[600]} 10%, ${red[700]} 100%);`}
                            numericValue={NumberOfUnavailableProductionLines}
                            numericValueColor={grey['100']}
                          >
                            <ReorderIcon
                              sx={{
                                width: '25px',
                                height: '25px',
                                marginRight: 1,
                                color: grey['100'],
                              }}
                            />
                          </DashboardNumericWidgetWithIconSmall>
                        </Grid>
                        <Grid item xs={3}>
                          <DashboardNumericWidgetWithIconSmall
                            title='In Progress'
                            titleColor={grey['100']}
                            backgroundImage={`linear-gradient( 135deg, ${green[600]} 10%, ${green[700]} 100%);`}
                            numericValue={NumberOfInProgressProductionLines}
                            numericValueColor={grey['100']}
                          >
                            <ReorderIcon
                              sx={{
                                width: '25px',
                                height: '25px',
                                marginRight: 1,
                                color: grey['100'],
                              }}
                            />
                          </DashboardNumericWidgetWithIconSmall>
                        </Grid>
                        <Grid item xs={3}>
                          <DashboardNumericWidgetWithIconSmall
                            title='Setup'
                            titleColor={grey['100']}
                            backgroundImage={`linear-gradient( 135deg, ${deepPurple[600]} 10%, ${deepPurple[700]} 100%);`}
                            numericValue={NumberOfSetupProductionLines}
                            numericValueColor={grey['100']}
                          >
                            <ReorderIcon
                              sx={{
                                width: '25px',
                                height: '25px',
                                marginRight: 1,
                                color: grey['100'],
                              }}
                            />
                          </DashboardNumericWidgetWithIconSmall>
                        </Grid>

                        <Grid item xs={3}>
                          <DashboardNumericWidgetWithIconSmall
                            title='Pallets In'
                            titleColor={grey['100']}
                            backgroundImage={`linear-gradient( 135deg, ${teal[800]} 10%, ${teal[900]} 100%);`}
                            numericValue={NumberOfPalletsInProduction}
                            numericValueColor={grey['100']}
                          >
                            <PalletInIcon
                              sx={{
                                width: '25px',
                                height: '25px',
                                marginRight: 1,
                                color: grey['100'],
                              }}
                            />
                          </DashboardNumericWidgetWithIconSmall>
                        </Grid>
                        <Grid item xs={3}>
                          <DashboardNumericWidgetWithIconSmall
                            title='Pallets Out'
                            titleColor={grey['100']}
                            backgroundImage={`linear-gradient( 135deg, ${teal[800]} 10%, ${teal[900]} 100%);`}
                            numericValue={NumberOfPalletsOutProduction}
                            numericValueColor={grey['100']}
                          >
                            <PalletOutIcon
                              sx={{
                                width: '25px',
                                height: '25px',
                                marginRight: 1,
                                color: grey['100'],
                              }}
                            />
                          </DashboardNumericWidgetWithIconSmall>
                        </Grid>
                        <Grid item xs={3}>
                          <DashboardNumericWidgetWithIconSmall
                            title='Cases In'
                            titleColor={grey['100']}
                            backgroundImage={`linear-gradient( 135deg, ${cyan[600]} 10%, ${cyan[700]} 100%);`}
                            numericValue={NumberOfCasesInProduction}
                            numericValueColor={grey['100']}
                          >
                            <PalletIcon
                              sx={{
                                width: '25px',
                                height: '25px',
                                marginRight: 1,
                                color: grey['100'],
                              }}
                            />
                          </DashboardNumericWidgetWithIconSmall>
                        </Grid>
                        <Grid item xs={3}>
                          <DashboardNumericWidgetWithIconSmall
                            title='Cases Out'
                            titleColor={grey['100']}
                            backgroundImage={`linear-gradient( 135deg, ${cyan[600]} 10%, ${cyan[700]} 100%);`}
                            numericValue={NumberOfCasesOutProduction}
                            numericValueColor={grey['100']}
                          >
                            <PalletIcon
                              sx={{
                                width: '25px',
                                height: '25px',
                                marginRight: 1,
                                color: grey['100'],
                              }}
                            />
                          </DashboardNumericWidgetWithIconSmall>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 1 }}>
                      {!isEmpty(mainDashboardState.data) && (
                        <>
                          <Card variant='outlined'>
                            <CardContent
                              sx={{
                                padding: 0.5,
                                '&:last-child': {
                                  paddingBottom: 0.8,
                                },
                              }}
                            >
                              {!isEmpty(MachinesCasesInOut) && (
                                <Grid container spacing={0}>
                                  <Grid item xs={12}>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <Box
                                        sx={(theme) => ({
                                          width: '450px',
                                          [theme.breakpoints.up('lg')]: {
                                            width: '810px',
                                          },
                                        })}
                                      >
                                        <Bar
                                          data={{
                                            labels: MachinesCasesInOut.map(
                                              (machine) => machine.machine
                                            ),
                                            datasets: [
                                              {
                                                label: 'Cases In',
                                                data: MachinesCasesInOut.map(
                                                  (machine) => machine.casesIn
                                                ),
                                                backgroundColor: ['#2CA02C'],
                                              },
                                              {
                                                label: 'Cases In',
                                                data: MachinesCasesInOut.map(
                                                  (machine) => machine.casesOut
                                                ),
                                                backgroundColor: ['#D62728'],
                                              },
                                            ],
                                          }}
                                          options={machinesCasesInOutOptions}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              )}
                            </CardContent>
                          </Card>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{ width: '35%', marginLeft: 1 }}>
              <Card variant='outlined'>
                <CardContent
                  sx={{
                    padding: 1.5,
                    '&:last-child': {
                      paddingBottom: 0.8,
                    },
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sx={{ marginBottom: 0.5 }}>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: '18px',
                            fontWeight: 'medium',
                          }}
                        >
                          Workforce Status
                        </Typography>
                        <Box display='flex' justifyContent='center'>
                          <Box
                            display='flex'
                            sx={{
                              width: '320px',
                              height: '200px',
                            }}
                          >
                            <Doughnut
                              data={allocationByStatusChartData}
                              options={allocationByStatusChartOptions}
                              style={{ transform: 'translateY(-4em)' }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <DashboardNumericWidgetWithIconSmall
                        title='Total'
                        titleColor={grey['100']}
                        backgroundImage={`linear-gradient( 135deg, ${blue[300]} 10%, ${blue[400]} 100%);`}
                        numericValue={totalEmployees}
                        numericValueColor={grey['100']}
                      >
                        <PersonIcon
                          sx={{
                            width: '25px',
                            height: '25px',
                            marginRight: 1,
                            color: grey['100'],
                          }}
                        />
                      </DashboardNumericWidgetWithIconSmall>
                    </Grid>
                    <Grid item xs={6}>
                      <DashboardNumericWidgetWithIconSmall
                        title='Working'
                        titleColor={grey['100']}
                        backgroundImage={`linear-gradient( 135deg, ${green[300]} 10%, ${green[400]} 100%);`}
                        numericValue={NumberOfActiveEmployees}
                        numericValueColor={grey['100']}
                      >
                        <PersonIcon
                          sx={{
                            width: '25px',
                            height: '25px',
                            marginRight: 1,
                            color: grey['100'],
                          }}
                        />
                      </DashboardNumericWidgetWithIconSmall>
                    </Grid>
                    <Grid item xs={6}>
                      <DashboardNumericWidgetWithIconSmall
                        title='Idle'
                        titleColor={grey['100']}
                        backgroundImage={`linear-gradient( 135deg, ${orange[300]} 10%, ${orange[400]} 100%);`}
                        numericValue={NumberOfIdleEmployees}
                        numericValueColor={grey['100']}
                      >
                        <PersonIcon
                          sx={{
                            width: '25px',
                            height: '25px',
                            marginRight: 1,
                            color: grey['100'],
                          }}
                        />
                      </DashboardNumericWidgetWithIconSmall>
                    </Grid>
                    <Grid item xs={6}>
                      <DashboardNumericWidgetWithIconSmall
                        title='Unallocated'
                        titleColor={grey['100']}
                        numericValue={NumberOfUnallocatedEmployees}
                        backgroundImage={`linear-gradient( 135deg, ${red[300]} 10%, ${red[400]} 100%);`}
                        numericValueColor={grey['100']}
                      >
                        <PersonIcon
                          sx={{
                            width: '25px',
                            height: '25px',
                            marginRight: 1,
                            color: grey['100'],
                          }}
                        />
                      </DashboardNumericWidgetWithIconSmall>
                    </Grid>

                    <Grid item xs={12}>
                      <Box sx={{ marginTop: 1 }}>
                        <Typography
                          sx={{
                            fontSize: '18px',
                            fontWeight: 'medium',
                          }}
                        >
                          Workforce by Job
                        </Typography>
                        <Box
                          display='flex'
                          justifyContent='center'
                          alignContent='center'
                        >
                          <Box
                            sx={{
                              width: '320px',
                              height: '200px',
                            }}
                          >
                            <PolarArea
                              data={allocationByJobChartData}
                              options={allocationByJobChartOptions}
                              style={{ transform: 'translateY(-4em)' }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: { md: 'none', lg: 'block' } }}
                    >
                      <Box sx={{ marginTop: 1 }}>
                        <Typography
                          sx={{
                            fontSize: '18px',
                            fontWeight: 'medium',
                          }}
                        >
                          Workforce by Position
                        </Typography>
                        <Box
                          display='flex'
                          justifyContent='center'
                          alignContent='center'
                        >
                          <Box
                            sx={{
                              width: '320px',
                              height: '200px',
                            }}
                          >
                            <PolarArea
                              data={allocationByPositionChartData}
                              options={allocationByPositionChartOptions}
                              style={{ transform: 'translateY(-4em)' }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Box>
        )}

        <Box sx={{ width: '100%', padding: 2 }}>
          {isEmpty(mainDashboardState.data) && !mainDashboardState.loading && (
            <Empty description='No data to show' />
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default MainDashboard;
