import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setWorkOrder,
  resetWorkOrder,
  resetWorkOrderList,
  showAddEditWorkOrderForm,
  showImportWorkOrdersForm,
} from '../../redux/epack/work-orders/actions';
import {
  loadWorkOrders,
  createWorkOrder,
  updateWorkOrder,
  deleteWorkOrder,
  deletePallet,
  importWorkOrders,
} from '../../redux/epack/work-orders/services';
import { getWorkOrderList } from '../../redux/epack/work-orders/selectors';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Divider,
  Paper,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import WorkOrdersPalletsTable from '../../components/epack/WorkOrdersPalletsTable';
import WorkOrderForm from '../../components/epack/WorkOrderForm';
import WorkOrdersImportForm from '../../components/epack/WorkOrdersImportForm';
import Loading from '../../components/UI/Loading';
import Empty from '../../components/UI/Empty';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import ImportIcon from '../../components/UI/icons/ImportIcon';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
const isEmpty = require('is-empty');

const WorkOrdersPallets = () => {
  const dispatch = useDispatch();
  const workOrdersState = useSelector((state) => state.work_orders);
  const workOrderState = useSelector((state) => state.work_orders.view.record);
  const WorkOrderList = useSelector(getWorkOrderList);
  const [editMode, setEditMode] = useState(false);
  const [workOrderId, setWorkOrderId] = useState(null);
  const [palletId, setPalletId] = useState(null);
  const [openRemoveWorkOrderDialog, setOpenRemoveWorkOrderDialog] =
    useState(false);
  const [openRemovePalletDialog, setOpenRemovePalletDialog] = useState(false);

  useEffect(() => {
    dispatch(loadWorkOrders());
    return () => {
      dispatch(resetWorkOrderList());
    };
  }, [dispatch]);

  const handleRefresh = () => {
    dispatch(loadWorkOrders());
  };

  const handleClickAdd = () => {
    setEditMode(false);
    dispatch(showAddEditWorkOrderForm(true));
  };

  const handleClickImport = () => {
    dispatch(showImportWorkOrdersForm(true));
  };

  const handleClickEdit = (workOrderId, palletId) => {
    let workOrder = workOrdersState.list.docs.find(
      (workOrder) => workOrder.id === workOrderId
    );
    if (palletId !== null) {
      workOrder.pallet = workOrder.pallets.find(
        (pallet) => pallet.id === palletId
      );
    }
    dispatch(setWorkOrder(workOrder));
    setEditMode(true);
    dispatch(showAddEditWorkOrderForm(true));
  };

  const handleClickRemoveWorkOrder = (id) => {
    setWorkOrderId(id);
    setOpenRemoveWorkOrderDialog(true);
  };

  const handleClickRemovePallet = (workOrderId, palletId) => {
    setWorkOrderId(workOrderId);
    setPalletId(palletId);
    setOpenRemovePalletDialog(true);
  };

  const onRemoveWorkOrder = () => {
    dispatch(deleteWorkOrder(workOrderId));
    setOpenRemoveWorkOrderDialog(false);
  };

  const onRemovePallet = () => {
    dispatch(deletePallet(workOrderId, palletId));
    setOpenRemovePalletDialog(false);
  };

  const onSubmitAddEdit = (data) => {
    Object.keys(data).forEach(
      (k) => (data[k] == null || data[k] === '') && delete data[k]
    );
    if (editMode) {
      dispatch(updateWorkOrder(data));
    } else {
      dispatch(createWorkOrder(data));
    }
  };
  const onSubmitImport = (data) => {
    dispatch(importWorkOrders(data));
  };

  return (
    <>
      <Loading loading={workOrdersState.loading} />
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box display='flex' alignContent='center'>
            <IconButton aria-label='reload' onClick={handleRefresh}>
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={1} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              ePack - Work Orders and Pallets
            </Typography>
          </Box>

          <Box my={1}>
            <Tooltip title='Import CSV'>
              <Button
                variant='contained'
                color='primary'
                startIcon={<ImportIcon />}
                onClick={handleClickImport}
              >
                Import
              </Button>
            </Tooltip>
            <Tooltip title='Add Work Order'>
              <Button
                variant='contained'
                color='secondary'
                startIcon={<AddIcon />}
                sx={{ marginLeft: '0.5rem' }}
                onClick={handleClickAdd}
              >
                Add
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <Divider variant='middle' light />
        {!isEmpty(workOrdersState.list) && (
          <WorkOrdersPalletsTable
            rows={WorkOrderList}
            handleClickEdit={handleClickEdit}
            handleClickRemoveWorkOrder={handleClickRemoveWorkOrder}
            handleClickRemovePallet={handleClickRemovePallet}
          />
        )}
        {isEmpty(workOrdersState.list) && !workOrdersState.loading && (
          <Empty description='There are no work orders to show' />
        )}
      </Paper>
      <ConfirmationDialog
        open={openRemoveWorkOrderDialog}
        handleNo={() => {
          setOpenRemoveWorkOrderDialog(false);
        }}
        handleYes={onRemoveWorkOrder}
        title='ePack - Remove Work Order'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to remove this Work Order?`}
        </Typography>
      </ConfirmationDialog>
      <ConfirmationDialog
        open={openRemovePalletDialog}
        handleNo={() => {
          setOpenRemovePalletDialog(false);
        }}
        handleYes={onRemovePallet}
        title='ePack - Remove Pallet'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to remove this Pallet?`}
        </Typography>
      </ConfirmationDialog>

      <Dialog
        open={workOrdersState.showAddEditForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setEditMode(false);
            dispatch(resetWorkOrder());
            dispatch(showAddEditWorkOrderForm(false));
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <WorkOrderForm
            editMode={editMode}
            workOrder={workOrderState}
            handleOnCancel={() => {
              setEditMode(false);
              dispatch(resetWorkOrder());
              dispatch(showAddEditWorkOrderForm(false));
            }}
            handleOnSubmit={onSubmitAddEdit}
            savingData={workOrdersState.savingData}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={workOrdersState.showImportForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            dispatch(showImportWorkOrdersForm(false));
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <WorkOrdersImportForm
            handleOnCancel={() => {
              dispatch(showImportWorkOrdersForm(false));
            }}
            handleOnSubmit={onSubmitImport}
            importingData={workOrdersState.importingData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WorkOrdersPallets;
