import {
  SET_LABOR_SUMMARY_VIEW_LIST,
  LABOR_SUMMARY_VIEW_LIST_LOADING,
  LABOR_SUMMARY_VIEW_LIST_RESET,
} from './types';
const initialState = {
  list: [],
  loading: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LABOR_SUMMARY_VIEW_LIST:
      return {
        ...state,
        list: [...action.payload],
      };
    case LABOR_SUMMARY_VIEW_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LABOR_SUMMARY_VIEW_LIST_RESET:
      return {
        ...state,
        list: [],
      };
    default:
      return state;
  }
}
