import {
  SET_LABOR_WORK_ORDER_VIEW_LIST,
  LABOR_WORK_ORDER_VIEW_LIST_LOADING,
  LABOR_WORK_ORDER_VIEW_LIST_RESET,
  LABOR_WORK_ORDER_VIEW_UPDATE_ITEM,
  LABOR_WORK_ORDER_VIEW_REMOVE_ITEM,
} from './types';

export const setWorkOrderViewList = (list) => {
  return {
    type: SET_LABOR_WORK_ORDER_VIEW_LIST,
    payload: list,
  };
};

export const resetWorkOrderViewList = () => {
  return {
    type: LABOR_WORK_ORDER_VIEW_LIST_RESET,
  };
};

export const setWorkOrderViewListLoading = (payload) => {
  return {
    type: LABOR_WORK_ORDER_VIEW_LIST_LOADING,
    payload,
  };
};

export const updateWorkOrderViewItem = (payload) => {
  return {
    type: LABOR_WORK_ORDER_VIEW_UPDATE_ITEM,
    payload,
  };
};

export const removeWorkOrderViewItem = (payload) => {
  return {
    type: LABOR_WORK_ORDER_VIEW_REMOVE_ITEM,
    payload,
  };
};
