import {
  SET_COMPANY,
  COMPANY_LOADING,
  COMPANY_RESET,
  SET_COMPANIES,
  COMPANY_LIST_LOADING,
  COMPANY_LIST_RESET,
  ADD_COMPANY_TO_LIST,
  UPDATE_COMPANY_IN_LIST,
  DELETE_COMPANY_FROM_LIST,
  SHOW_ADD_EDIT_COMPANY_FORM,
  SAVING_COMPANY_DATA,
  SET_PRIVATE_TOKEN,
  PRIVATE_TOKEN_LOADING,
  SET_COMPANY_NAME_FILTER,
  SET_COMPANY_IS_ACTIVE_FILTER,
  SET_COMPANY_NEED_APPROVAL_FILTER,
} from './types';

export const setCompany = (item) => {
  return {
    type: SET_COMPANY,
    payload: item,
  };
};

export const resetCompany = () => {
  return {
    type: COMPANY_RESET,
  };
};

export const setCompanyLoading = (payload) => {
  return {
    type: COMPANY_LOADING,
    payload,
  };
};

export const setCompanyList = (list) => {
  return {
    type: SET_COMPANIES,
    payload: list,
  };
};

export const resetCompanyList = () => {
  return {
    type: COMPANY_LIST_RESET,
  };
};

export const setCompanyListLoading = (payload) => {
  return {
    type: COMPANY_LIST_LOADING,
    payload,
  };
};

export const addCompanyToList = (payload) => {
  return {
    type: ADD_COMPANY_TO_LIST,
    payload,
  };
};

export const deleteCompanyFromList = (payload) => {
  return {
    type: DELETE_COMPANY_FROM_LIST,
    payload,
  };
};

export const updateCompanyInList = (payload) => {
  return {
    type: UPDATE_COMPANY_IN_LIST,
    payload,
  };
};

export const showAddEditCompanyForm = (payload) => {
  return {
    type: SHOW_ADD_EDIT_COMPANY_FORM,
    payload,
  };
};

export const savingCompanyData = (payload) => {
  return {
    type: SAVING_COMPANY_DATA,
    payload,
  };
};

export const setPrivateToken = (item) => {
  return {
    type: SET_PRIVATE_TOKEN,
    payload: item,
  };
};

export const setPrivateTokenLoading = (payload) => {
  return {
    type: PRIVATE_TOKEN_LOADING,
    payload,
  };
};

export const setCompanyNameFilter = (payload) => {
  return {
    type: SET_COMPANY_NAME_FILTER,
    payload,
  };
};

export const setCompanyIsActiveFilter = (payload) => {
  return {
    type: SET_COMPANY_IS_ACTIVE_FILTER,
    payload,
  };
};

export const setCompanyNeedsApprovalFilter = (payload) => {
  return {
    type: SET_COMPANY_NEED_APPROVAL_FILTER,
    payload,
  };
};
