import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  workOrderId: Yup.string().required('Work Order is required'),
  machineId: Yup.string().required('Machine is required'),
  itemDescription: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required('Description is required'),
});

const ProductionLineAddForm = ({
  workOrderId,
  machineId,
  workOrderList,
  machineList,
  handleOnSubmit,
  handleOnCancel,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box display='flex' my={1.5} flexGrow={1} alignItems='center'>
              <Typography
                color='primary'
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'ePack - New Production Line'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ minWidth: 250 }}>
                  <InputLabel id='wo-select-label'>Work Order</InputLabel>
                  <Controller
                    name='workOrderId'
                    control={control}
                    defaultValue={workOrderId ? workOrderId : ''}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId='wo-select-label'
                        label={'Work Order'}
                        fullWidth
                        disabled={Boolean(workOrderId)}
                        error={!!errors?.workOrderId}
                      >
                        {workOrderList.map((workOrder) => (
                          <MenuItem key={workOrder.id} value={workOrder.id}>
                            {workOrder.code + ' - ' + workOrder.commodity}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <FormHelperText>{errors?.workOrderId?.message}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ minWidth: 250 }}>
                  <InputLabel id='machine-select-label'>Machine</InputLabel>
                  <Controller
                    name='machineId'
                    control={control}
                    defaultValue={machineId ? machineId : ''}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId='machine-select-label'
                        label={'Machine'}
                        fullWidth
                        disabled={Boolean(machineId)}
                        error={!!errors?.machineId}
                      >
                        {machineList.map((machine) => (
                          <MenuItem key={machine.id} value={machine.id}>
                            {machine.name + ' - ' + machine.category}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <FormHelperText>{errors?.machineId?.message}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name='itemDescription'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Item Description'
                      fullWidth
                      error={!!errors?.itemDescription}
                      helperText={errors?.itemDescription?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box component='div' mx={1} my={0.5} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
              >
                {'Submit'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UndoIcon />}
                onClick={handleOnCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default ProductionLineAddForm;
