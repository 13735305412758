import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getMORProfileList = createSelector(
  (state) => state.mor_profile.list.docs,
  (morProfilesList) => {
    if (isEmpty(morProfilesList)) return [];
    return morProfilesList.sort((a, b) =>
      a.machine.name < b.machine.name ? -1 : 1
    );
  }
);

export const selectFilteredMORProfiles = createSelector(
  getMORProfileList,
  (state) => state.mor_profile.filters,
  (morProfilesList, filters) => {
    if (isEmpty(morProfilesList)) return [];

    if (!isEmpty(filters.machine)) {
      morProfilesList = morProfilesList.filter(
        (item) => item.machine.id === filters.machine
      );
    }
    if (!isEmpty(filters.commodity)) {
      morProfilesList = morProfilesList.filter(
        (item) => item.commodity.id === filters.commodity
      );
    }
    if (!isEmpty(filters.itemDescription)) {
      morProfilesList = morProfilesList.filter((item) =>
        item.itemDescription
          .toLowerCase()
          .includes(filters.itemDescription.toLowerCase())
      );
    }
    return morProfilesList;
  }
);

export const selectExistingMORProfile = createSelector(
  getMORProfileList,
  (state) => state.mor_profile.filters,
  (morProfilesList, filters) => {
    if (isEmpty(morProfilesList)) return false;
    return (
      morProfilesList.filter(
        (item) =>
          item.machine.id === filters.machine &&
          item.commodity.id === filters.commodity &&
          item.itemDescription.toLowerCase() ===
            filters.itemDescription.toLowerCase()
      ).length > 0
    );
  }
);
