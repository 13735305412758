import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Box, Divider, Paper, Tab, Tabs, Typography } from '@mui/material';

const tabs = [
  { label: 'Main', value: 'main' },
  { label: 'Users', value: 'users' },
  { label: 'Contacts', value: 'contacts' },
  { label: 'Employers', value: 'employers' },
  { label: 'Customers', value: 'customers' },
  { label: 'Commodities', value: 'commodities' },
  { label: 'Employee Positions', value: 'employee_positions' },
  { label: 'Job Types', value: 'job_types' },
  { label: 'Machine Types', value: 'machine_types' },
  { label: 'Pallet Types', value: 'pallet_types' },
  { label: 'Setup Checklist Items', value: 'setup_checklist_items' },
];

const CompanyProfile = () => {
  const [activeTab, setActiveTab] = useState('main');
  const location = useLocation();

  useEffect(() => {
    for (let i = 0; i < tabs.length; i++) {
      if (location.pathname.includes(tabs[i].value)) {
        setActiveTab(tabs[i].value);
        break;
      }
    }
  }, [location]);

  const handleChangeTab = (event, tab) => {
    setActiveTab(tab);
  };

  return (
    <Paper elevation={0}>
      <Box component='div' display='flex' mx={1}>
        <Box my={1} ml={1.5} flexGrow={1}>
          <Typography variant='h6' color='primary'>
            Company Profile
          </Typography>
        </Box>
      </Box>
      <Divider variant='middle' light />
      <Box
        sx={{
          maxWidth: { xs: 320, sm: 480, md: 1024, lg: 1200 },
        }}
      >
        <Tabs
          variant='scrollable'
          value={activeTab}
          scrollButtons='auto'
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              component={Link}
              label={tab.label}
              to={tab.value}
              value={tab.value}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ border: 1, borderColor: 'divider' }}>
        <Outlet />
      </Box>
    </Paper>
  );
};

export default CompanyProfile;
