import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > svg': {
      marginTop: theme.spacing(),
      marginRight: theme.spacing(0.5),
    },
  },
}));

const DashboardIcon = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 231 229'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <rect width='100' height='127.114' rx='10' fill='#2E7D32' />
        <rect
          x='131'
          y='101.886'
          width='100'
          height='127.114'
          rx='10'
          fill='#f9ae0f'
        />
        <rect y='147.492' width='100' height='81.5085' rx='10' fill='#54C176' />
        <rect x='131' width='100' height='81.5085' rx='10' fill='#E74C3C' />
      </SvgIcon>
    </div>
  );
};

export default DashboardIcon;
