import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import UndoIcon from '@mui/icons-material/Undo';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PanToolIcon from '@mui/icons-material/PanTool';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmployeePhoto from './EmployeePhoto';
import truncateString from '../../utils/truncateString';
import isEmpty from 'is-empty';
import * as Yup from 'yup';
import moment from 'moment';

const ValidationSchema = Yup.object().shape({
  barcode: Yup.string()
    .min(1, 'Must be 1 character or more')
    .max(50, 'Must be 50 characters or less'),
});

const GateOutForm = ({
  employee,
  handleOnSubmit,
  handleOnCancel,
  savingData,
}) => {
  const { handleSubmit, setValue } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  useEffect(() => {
    setValue('id', employee.id);
  }, [employee, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box display='flex' my={1.5} flexGrow={1} alignItems='center'>
              <Typography
                color='primary'
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'eTime - Gate Out'}
              </Typography>
            </Box>
          </Box>
          <Divider light />
          <Box
            component='div'
            display='flex'
            mt={2}
            justifyContent='center'
            alignItems={'center'}
          >
            <Box sx={{ margin: 5 }}>
              <EmployeePhoto photo={employee.photo} size='large' />
            </Box>
            <Box flexGrow={1}>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={2}>
                  <BadgeIcon color='primary' />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body1'>
                    {truncateString(
                      employee.lastName + ' ' + employee.firstName,
                      {
                        length: 25,
                        ending: '...',
                      }
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <AccountBalanceIcon color='primary' />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body1'>
                    {truncateString(employee.employer.name)}
                  </Typography>
                </Grid>
                {!isEmpty(employee.latestPosition) && (
                  <>
                    <Grid item xs={2}>
                      <PanToolIcon color='primary' />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant='body1'>
                        {truncateString(employee.latestPosition)}
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={2}>
                  <AccessTimeIcon color='primary' />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body1'>
                    {moment(employee.dateIn).format('MM/DD/YYYY hh:mm a')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box component='div' mx={1} my={0.5} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
                startIcon={<LogoutIcon />}
                disabled={savingData}
              >
                {savingData ? 'Signing Out...' : 'Sign Out'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UndoIcon />}
                onClick={handleOnCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default GateOutForm;
