import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > svg': {
      marginTop: theme.spacing(),
      marginRight: theme.spacing(0.5),
    },
  },
}));

const PackIcon = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SvgIcon
        width="24"
        height="24"
        viewBox="0 0 58 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.9998 47.18V39.03L54.0598 33.73C54.6693 33.3667 55.0426 32.7095 55.0426 32C55.0426 31.2904 54.6693 30.6332 54.0598 30.27L45.0598 25L54.0598 19.73C54.6717 19.3709 55.0477 18.7145 55.0477 18.005C55.0477 17.2954 54.6717 16.639 54.0598 16.28L40.9498 8.58996C40.3246 8.22994 39.555 8.22994 38.9298 8.58996L27.5 15L17.0698 8.58996C16.4446 8.22994 15.675 8.22994 15.0498 8.58996L1.93977 16.27C1.32666 16.6305 0.950195 17.2887 0.950195 18C0.950195 18.7112 1.32666 19.3694 1.93977 19.73L10.9398 25L1.93977 30.27C1.33029 30.6332 0.956935 31.2904 0.956935 32C0.956935 32.7095 1.33029 33.3667 1.93977 33.73L10.9998 39.03V47.18H44.9998Z"
          fill="#FAC176"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.9997 39.03V47.18C44.9975 47.8882 44.6209 48.5424 44.0097 48.9L29.0097 57.72C28.7054 57.9049 28.3558 58.0018 27.9997 58L27.9497 58L27.9997 57.32V35L38.9297 41.41C39.5549 41.77 40.3245 41.77 40.9497 41.41L44.9997 39.03Z"
          fill="#E57E25"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 35V57.32L27.95 58C27.6113 57.9906 27.2807 57.8942 26.99 57.72L11.99 48.9C11.3788 48.5424 11.0022 47.8882 11 47.18V39.03L15.05 41.41C15.6752 41.77 16.4448 41.77 17.07 41.41L28 35Z"
          fill="#F29C1F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.0599 24.9999L44.9999 25.03L27.9999 35L10.9999 25.03L10.9399 24.9999L24.9999 16.7599L27.5 15H27.9999C27.9999 15.5523 28.5522 15 27.9999 15L30.9999 16.7599L45.0599 24.9999Z"
          fill="#D25627"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6598 22.0002H24.9998L24.9998 1.00015C24.9998 0.447868 25.4475 0.000152588 25.9998 0.000152588H29.9998C30.5521 0.000152588 30.9998 0.447868 30.9998 1.00015L30.9998 22.0002H33.3398C33.7499 21.9919 34.1213 22.2412 34.269 22.6239C34.4167 23.0065 34.3091 23.4407 33.9998 23.7102L28.6598 28.7602C28.2779 29.0799 27.7217 29.0799 27.3398 28.7602L21.9998 23.7102C21.6905 23.4407 21.5829 23.0065 21.7306 22.6239C21.8783 22.2412 22.2497 21.9919 22.6598 22.0002Z"
          fill="#4FBA6F"
        />
      </SvgIcon>
    </div>
  );
};

export default PackIcon;
