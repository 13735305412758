import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Paper,
  Typography,
} from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';
import { Save } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';

const ValidationSchema = Yup.object().shape({
  description: Yup.string().required('Required'),
  yield: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
});

const ManualProfileActivityForm = ({
  activity,
  editMode,
  handleOnSubmit,
  handleOnCancel,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  if (editMode) {
    setValue('id', activity.id);
  }

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          handleOnSubmit({
            ...activity,
            id: editMode ? data.id : uuidv4(),
            descrition: data.descrition,
            yield: data.yield / 100,
          });
          reset();
        })}
      >
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box display='flex' my={1.5} flexGrow={1} alignItems='center'>
              <Typography
                color='primary'
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'ePlan - Manual Machine Profile - Activity'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={8}>
                <Controller
                  name='description'
                  control={control}
                  defaultValue={editMode ? activity.description : ''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size='small'
                      label='Activity'
                      fullWidth
                      error={!!errors?.description}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name='yield'
                  control={control}
                  defaultValue={editMode ? activity.yield * 100 : ''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size='small'
                      label='Yield %'
                      fullWidth
                      type='number'
                      error={!!errors?.yield}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Box component='div' mx={1} my={0.5} display='flex'>
          <Box my={1.5} flexGrow={1}>
            <Button
              variant='contained'
              color='secondary'
              size='large'
              type='submit'
              startIcon={<Save />}
            >
              {'Save'}
            </Button>
          </Box>
          <Box my={1.5}>
            <Button
              variant='outlined'
              size='large'
              startIcon={<UndoIcon />}
              onClick={handleOnCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ManualProfileActivityForm;
