import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setProfitProfileList,
  resetProfitProfileList,
  setProfitProfileListLoading,
  deleteProfitProfileFromList,
  savingProfitProfileData,
  resetProfitProfileFilters,
  setProfitProfile,
  resetProfitProfile,
  setProfitProfileLoading,
  addProfitProfileToList,
  showDuplicateProfitProfileForm,
} from './actions';

export const loadProfitProfiles = () => (dispatch, getState, api) => {
  dispatch(setProfitProfileListLoading(true));
  axios
    .get(`${api}/profile-profit?limit=1000000`)
    .then((res) => {
      dispatch(resetProfitProfileFilters());
      dispatch(resetProfitProfileList());
      dispatch(setProfitProfileList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setProfitProfileListLoading(false));
    });
};

export const loadProfitProfile = (id) => (dispatch, getState, api) => {
  dispatch(setProfitProfileLoading(true));
  axios
    .get(`${api}/profile-profit/by-id/${id}`)
    .then((res) => {
      dispatch(resetProfitProfile());
      dispatch(setProfitProfile(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setProfitProfileLoading(false));
    });
};

export const createProfitProfile =
  (data, navigate) => (dispatch, getState, api) => {
    dispatch(savingProfitProfileData(true));
    axios
      .post(`${api}/profile-profit`, data)
      .then((res) => {
        navigate('/eprofit/profit_profiles');
        const notification = {
          message: '👍🏻 Profit Profile created successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(savingProfitProfileData(false));
      });
  };

export const updateProfitProfile =
  (id, data, navigate) => (dispatch, getState, api) => {
    dispatch(savingProfitProfileData(true));
    axios
      .put(`${api}/profile-profit/${id}`, data)
      .then((res) => {
        navigate('/eprofit/profit_profiles');
        const notification = {
          message: '👍🏻 Profit Profile updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(savingProfitProfileData(false));
      });
  };

export const deleteProfitProfile = (id) => (dispatch, getState, api) => {
  dispatch(setProfitProfileListLoading(true));
  axios
    .delete(`${api}/profile-profit/${id}`)
    .then((res) => {
      dispatch(deleteProfitProfileFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Profit Profile deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setProfitProfileListLoading(false));
};

export const duplicateProfitProfile =
  (id, data) => (dispatch, getState, api) => {
    dispatch(savingProfitProfileData(true));
    axios
      .post(`${api}/profile-profit/duplicate/${id}`, data)
      .then((res) => {
        dispatch(addProfitProfileToList(res.data));
        dispatch(showDuplicateProfitProfileForm(false));
        const notification = {
          message: '👍🏻 Profit Profile duplicated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        let notification;
        if (err.response.status === 409) {
          notification = {
            message: err.response.data.message,
            options: {
              variant: 'error',
            },
          };
        } else {
          notification = {
            message: '😱 Something went wrong, please try again',
            options: {
              variant: 'error',
            },
          };
        }
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(savingProfitProfileData(false));
      });
  };
