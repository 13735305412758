import {
  SET_CONTACT,
  CONTACT_LOADING,
  CONTACT_RESET,
  SET_CONTACTS,
  CONTACT_LIST_LOADING,
  CONTACT_LIST_RESET,
  ADD_CONTACT_TO_LIST,
  UPDATE_CONTACT_IN_LIST,
  DELETE_CONTACT_FROM_LIST,
  SHOW_ADD_EDIT_CONTACT_FORM,
  SAVING_CONTACT_DATA,
} from './types';

export const setContact = (item) => {
  return {
    type: SET_CONTACT,
    payload: item,
  };
};

export const resetContact = () => {
  return {
    type: CONTACT_RESET,
  };
};

export const setContactLoading = (payload) => {
  return {
    type: CONTACT_LOADING,
    payload,
  };
};

export const setContactList = (list) => {
  return {
    type: SET_CONTACTS,
    payload: list,
  };
};

export const resetContactList = () => {
  return {
    type: CONTACT_LIST_RESET,
  };
};

export const setContactListLoading = (payload) => {
  return {
    type: CONTACT_LIST_LOADING,
    payload,
  };
};

export const addContactToList = (payload) => {
  return {
    type: ADD_CONTACT_TO_LIST,
    payload,
  };
};

export const deleteContactFromList = (payload) => {
  return {
    type: DELETE_CONTACT_FROM_LIST,
    payload,
  };
};

export const updateContactInList = (payload) => {
  return {
    type: UPDATE_CONTACT_IN_LIST,
    payload,
  };
};

export const showAddEditContactForm = (payload) => {
  return {
    type: SHOW_ADD_EDIT_CONTACT_FORM,
    payload,
  };
};

export const savingContactData = (payload) => {
  return {
    type: SAVING_CONTACT_DATA,
    payload,
  };
};
