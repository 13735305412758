import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setGateOutList,
  resetGateOutList,
  setGateOutListLoading,
  showGateOutForm,
  savingGateOutData,
  deleteGateOutFromList,
} from './actions';

export const loadGateOutList = () => (dispatch, getState, api) => {
  dispatch(setGateOutListLoading(true));
  axios
    .get(`${api}/gate-out?limit=1000000`)
    .then((res) => {
      dispatch(resetGateOutList());
      dispatch(setGateOutList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setGateOutListLoading(false));
    });
};

export const gateOutEmployee = (data) => (dispatch, getState, api) => {
  dispatch(setGateOutListLoading(true));
  dispatch(savingGateOutData(true));
  axios
    .post(`${api}/gate-out/by-id/${data.id}`)
    .then((res) => {
      dispatch(deleteGateOutFromList(data.id));
      dispatch(showGateOutForm(false));
      const notification = {
        message: '👍🏻 Employee signed out successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setGateOutListLoading(false));
      dispatch(savingGateOutData(false));
    });
};

export const gateOutEmployeesById = (data) => (dispatch, getState, api) => {
  dispatch(setGateOutListLoading(true));
  axios
    .post(`${api}/gate-out/by-ids`, {
      gateLogIds: data,
    })
    .then((res) => {
      dispatch(loadGateOutList());
      const notification = {
        message: '👍🏻 Employees signed out successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setGateOutListLoading(false));
    });
};
