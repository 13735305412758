import axios from 'axios';
import { addNotification } from '../../../system/notifications/actions';
import {
  setWorkOrderViewList,
  resetWorkOrderViewList,
  setWorkOrderViewListLoading,
  updateWorkOrderViewItem,
  removeWorkOrderViewItem,
} from './actions';

export const loadWorkOrderViewList = () => (dispatch, getState, api) => {
  dispatch(setWorkOrderViewListLoading(true));
  axios
    .get(`${api}/labor/work-order-view`)
    .then((res) => {
      dispatch(resetWorkOrderViewList());
      dispatch(setWorkOrderViewList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWorkOrderViewListLoading(false));
    });
};

export const startByWorkOrder = (id) => (dispatch, getState, api) => {
  dispatch(setWorkOrderViewListLoading(true));
  axios
    .post(`${api}/labor/start/by-work-order/${id}`)
    .then((res) => {
      dispatch(loadWorkOrderViewList());
      const notification = {
        message: '👍🏻 Work for all employees started',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWorkOrderViewListLoading(false));
    });
};

export const stopByWorkOrder = (id) => (dispatch, getState, api) => {
  dispatch(setWorkOrderViewListLoading(true));
  axios
    .post(`${api}/labor/stop/by-work-order/${id}`)
    .then((res) => {
      dispatch(loadWorkOrderViewList());
      const notification = {
        message: '👍🏻 Work for all employees stopped',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWorkOrderViewListLoading(false));
    });
};

export const startLaborLog = (id) => (dispatch, getState, api) => {
  dispatch(setWorkOrderViewListLoading(true));
  axios
    .post(`${api}/labor/start/by-id/${id}`)
    .then((res) => {
      dispatch(updateWorkOrderViewItem(res.data));
      const notification = {
        message: '👍🏻 Work started for this employee',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWorkOrderViewListLoading(false));
    });
};

export const stopLaborLog = (id) => (dispatch, getState, api) => {
  dispatch(setWorkOrderViewListLoading(true));
  axios
    .post(`${api}/labor/stop/by-id/${id}`)
    .then((res) => {
      dispatch(updateWorkOrderViewItem(res.data));
      const notification = {
        message: '👍🏻 Work stopped for this employee',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWorkOrderViewListLoading(false));
    });
};

export const changePosition = (data) => (dispatch, getState, api) => {
  dispatch(setWorkOrderViewListLoading(true));
  axios
    .post(`${api}/labor/change-position`, { ...data })
    .then((res) => {
      dispatch(updateWorkOrderViewItem(res.data));
      const notification = {
        message: '👍🏻 Employee position has been changed successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWorkOrderViewListLoading(false));
    });
};

export const moveLaborLog = (data) => (dispatch, getState, api) => {
  dispatch(setWorkOrderViewListLoading(true));
  axios
    .post(`${api}/labor/move/${data.laborLogId}`, {
      workOrderId: data.workOrderId,
      machineId: data.machineId,
      jobTypeId: data.jobTypeId,
    })
    .then((res) => {
      dispatch(loadWorkOrderViewList());
      const notification = {
        message: '👍🏻 Employee has been moved successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWorkOrderViewListLoading(false));
    });
};

export const moveByWorkOrder =
  (workOrderId, data) => (dispatch, getState, api) => {
    dispatch(setWorkOrderViewListLoading(true));
    axios
      .post(`${api}/labor/move/by-work-order/${workOrderId}`, {
        workOrderId: data.workOrderId,
        machineId: data.machineId,
        jobTypeId: data.jobTypeId,
      })
      .then((res) => {
        dispatch(loadWorkOrderViewList());
        const notification = {
          message: '👍🏻 Employees have been moved successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setWorkOrderViewListLoading(false));
      });
  };

export const walkOff = (workOrderId, data) => (dispatch, getState, api) => {
  dispatch(setWorkOrderViewListLoading(true));
  axios
    .post(`${api}/labor/walk-off/`, { ...data })
    .then((res) => {
      dispatch(removeWorkOrderViewItem({ workOrderId: workOrderId, ...data }));
      const notification = {
        message: '👍🏻 Walk off information saved successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWorkOrderViewListLoading(false));
    });
};
