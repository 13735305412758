import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setCustomerList,
  resetCustomerList,
  setCustomerListLoading,
  addCustomerToList,
  deleteCustomerFromList,
  updateCustomerInList,
  savingCustomerData,
  showAddEditCustomerForm,
} from './actions';

export const loadCustomers = () => (dispatch, getState, api) => {
  dispatch(setCustomerListLoading(true));
  axios
    .get(`${api}/customers?limit=1000000`)
    .then((res) => {
      dispatch(resetCustomerList());
      dispatch(setCustomerList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCustomerListLoading(false));
    });
};

export const createCustomer = (data) => (dispatch, getState, api) => {
  dispatch(setCustomerListLoading(true));
  dispatch(savingCustomerData(true));
  axios
    .post(`${api}/customers`, data)
    .then((res) => {
      dispatch(addCustomerToList(res.data));
      dispatch(showAddEditCustomerForm(false));
      const notification = {
        message: '👍🏻 Customer created successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCustomerListLoading(false));
      dispatch(savingCustomerData(false));
    });
};

export const updateCustomer = (id, data) => (dispatch, getState, api) => {
  dispatch(setCustomerListLoading(true));
  dispatch(savingCustomerData(true));
  axios
    .put(`${api}/customers/${id}`, data)
    .then((res) => {
      dispatch(updateCustomerInList(res.data));
      dispatch(showAddEditCustomerForm(false));
      const notification = {
        message: '👍🏻 Customer updated successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCustomerListLoading(false));
      dispatch(savingCustomerData(false));
    });
};

export const deleteCustomer = (id) => (dispatch, getState, api) => {
  dispatch(setCustomerListLoading(true));
  axios
    .delete(`${api}/customers/${id}`)
    .then((res) => {
      dispatch(deleteCustomerFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Customer deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setCustomerListLoading(false));
};

export const toggleCustomerStatus =
  (id, currentStatus) => (dispatch, getState, api) => {
    dispatch(setCustomerListLoading(true));
    axios
      .post(`${api}/customers/activate/${id}/${!currentStatus}`)
      .then((res) => {
        console.log(res);
        dispatch(loadCustomers());
        const notification = {
          message: '👍🏻 Customer status updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setCustomerListLoading(false));
      });
  };
