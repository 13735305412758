import {
  SET_COMMODITY,
  COMMODITY_LOADING,
  COMMODITY_RESET,
  SET_COMMODITYS,
  COMMODITY_LIST_LOADING,
  COMMODITY_LIST_RESET,
  ADD_COMMODITY_TO_LIST,
  UPDATE_COMMODITY_IN_LIST,
  DELETE_COMMODITY_FROM_LIST,
  SHOW_ADD_EDIT_COMMODITY_FORM,
  SAVING_COMMODITY_DATA,
} from './types';

export const setCommodity = (item) => {
  return {
    type: SET_COMMODITY,
    payload: item,
  };
};

export const resetCommodity = () => {
  return {
    type: COMMODITY_RESET,
  };
};

export const setCommodityLoading = (payload) => {
  return {
    type: COMMODITY_LOADING,
    payload,
  };
};

export const setCommodityList = (list) => {
  return {
    type: SET_COMMODITYS,
    payload: list,
  };
};

export const resetCommodityList = () => {
  return {
    type: COMMODITY_LIST_RESET,
  };
};

export const setCommodityListLoading = (payload) => {
  return {
    type: COMMODITY_LIST_LOADING,
    payload,
  };
};

export const addCommodityToList = (payload) => {
  return {
    type: ADD_COMMODITY_TO_LIST,
    payload,
  };
};

export const deleteCommodityFromList = (payload) => {
  return {
    type: DELETE_COMMODITY_FROM_LIST,
    payload,
  };
};

export const updateCommodityInList = (payload) => {
  return {
    type: UPDATE_COMMODITY_IN_LIST,
    payload,
  };
};

export const showAddEditCommodityForm = (payload) => {
  return {
    type: SHOW_ADD_EDIT_COMMODITY_FORM,
    payload,
  };
};

export const savingCommodityData = (payload) => {
  return {
    type: SAVING_COMMODITY_DATA,
    payload,
  };
};
