import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setCommodityList,
  resetCommodityList,
  setCommodityListLoading,
  addCommodityToList,
  deleteCommodityFromList,
  updateCommodityInList,
  savingCommodityData,
  showAddEditCommodityForm,
} from './actions';

export const loadCommodities = () => (dispatch, getState, api) => {
  dispatch(setCommodityListLoading(true));
  axios
    .get(`${api}/commodities?limit=1000000`)
    .then((res) => {
      dispatch(resetCommodityList());
      dispatch(setCommodityList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCommodityListLoading(false));
    });
};

export const createCommodity = (data) => (dispatch, getState, api) => {
  dispatch(setCommodityListLoading(true));
  dispatch(savingCommodityData(true));
  axios
    .post(`${api}/commodities`, data)
    .then((res) => {
      dispatch(addCommodityToList(res.data));
      dispatch(showAddEditCommodityForm(false));
      const notification = {
        message: '👍🏻 Commodity created successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCommodityListLoading(false));
      dispatch(savingCommodityData(false));
    });
};

export const updateCommodity = (id, data) => (dispatch, getState, api) => {
  dispatch(setCommodityListLoading(true));
  dispatch(savingCommodityData(true));
  axios
    .put(`${api}/commodities/${id}`, data)
    .then((res) => {
      dispatch(updateCommodityInList(res.data));
      dispatch(showAddEditCommodityForm(false));
      const notification = {
        message: '👍🏻 Commodity updated successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCommodityListLoading(false));
      dispatch(savingCommodityData(false));
    });
};

export const deleteCommodity = (id) => (dispatch, getState, api) => {
  dispatch(setCommodityListLoading(true));
  axios
    .delete(`${api}/commodities/${id}`)
    .then((res) => {
      dispatch(deleteCommodityFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Commodity deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setCommodityListLoading(false));
};

export const toggleCommodityStatus =
  (id, currentStatus) => (dispatch, getState, api) => {
    dispatch(setCommodityListLoading(true));
    axios
      .post(`${api}/commodities/activate/${id}/${!currentStatus}`)
      .then((res) => {
        console.log(res);
        dispatch(loadCommodities());
        const notification = {
          message: '👍🏻 Commodity status updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setCommodityListLoading(false));
      });
  };
