import React from 'react';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { Box, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import isEmpty from 'is-empty';
import { styled } from '@mui/system';

const DataGrid = styled(MuiDataGrid)({
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
});

const WorkOrdersPalletsTable = ({
  rows,
  handleClickEdit,
  handleClickRemoveWorkOrder,
  handleClickRemovePallet,
}) => {
  const columns = [
    {
      field: 'code',
      headerName: 'Work Order',
      width: 130,
      hideable: false,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <Box>
            {cellValues.row.code && (
              <Tooltip title='Remove Work Order'>
                <IconButton
                  onClick={() => handleClickRemoveWorkOrder(cellValues.row.id)}
                  sx={{ color: 'error.main' }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}

            {cellValues.value}
          </Box>
        );
      },
    },
    {
      field: 'umo',
      headerName: 'UMO',
      width: 75,
      hideable: false,
      editable: false,
    },
    {
      field: 'commodity',
      headerName: 'Commodity',
      width: 100,
      editable: false,
      valueGetter: (params) =>
        isEmpty(params.row.commodity) ? '' : params.row.commodity.name,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,
      editable: false,
      valueGetter: (params) =>
        isEmpty(params.row.pallet) ? '' : params.row.pallet.description,
    },
    {
      field: 'lot',
      headerName: 'Lot',
      width: 100,
      editable: false,
      valueGetter: (params) =>
        isEmpty(params.row.pallet) ? '' : params.row.pallet.lot,
    },
    {
      field: 'gtin',
      headerName: 'GTIN',
      width: 100,
      editable: false,
      valueGetter: (params) =>
        isEmpty(params.row.pallet) ? '' : params.row.pallet.gtin,
    },
    {
      field: 'item',
      headerName: 'Item',
      width: 100,
      editable: false,
      valueGetter: (params) =>
        isEmpty(params.row.pallet) ? '' : params.row.pallet.item,
    },
    {
      field: 'palletCode',
      headerName: 'Pallet',
      width: 200,
      editable: false,
      renderCell: (cellValues) => {
        return isEmpty(cellValues.row.pallet) ? null : (
          <Box>
            <Tooltip title='Remove Pallet'>
              <IconButton
                onClick={() =>
                  handleClickRemovePallet(
                    cellValues.row.id,
                    cellValues.row.pallet.id
                  )
                }
                sx={{ color: 'error.main' }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            {cellValues.row.pallet.code}
          </Box>
        );
      },
    },
    {
      field: 'cases',
      headerName: 'Cases',
      width: 100,
      editable: false,
      valueGetter: (params) =>
        isEmpty(params.row.pallet) ? '' : params.row.pallet.cases,
    },
    {
      field: 'actions',
      width: 50,
      type: 'actions',
      hideable: false,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <Box>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => {
                  const palletId = isEmpty(cellValues.row.pallet)
                    ? null
                    : cellValues.row.pallet.id;
                  handleClickEdit(cellValues.row.id, palletId);
                }}
                color='primary'
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <Box mx={1} my={1} sx={{ height: '650px', width: '98%' }}>
      <DataGrid
        getRowId={(row) => row.uuid}
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default WorkOrdersPalletsTable;
