import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Required'),
});

const ForgotPasswordForm = ({ handleOnSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Box sx={{ minWidth: '300px' }}>
        <Box display='flex' mx={1} alignItems='center'>
          <Box
            display='flex'
            flexGrow={1}
            alignItems='center'
            justifyContent='center'
          >
            <Typography sx={{ fontSize: '1.5rem', fontWeight: 'medium' }}>
              Forgot Password
            </Typography>
          </Box>
        </Box>
        <Box mx={1} my={0.5} mt={2}>
          <Box display='flex' mt={2}>
            <Box flexGrow={1}>
              <Controller
                name='email'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    label='Email'
                    fullWidth
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>

        <Box component='div' mx={1} my={0.5} display='flex'>
          <Box my={1.5} flexGrow={1}>
            <Button
              variant='contained'
              color='primary'
              size='large'
              fullWidth
              type='submit'
            >
              {'Forgot Password'}
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default ForgotPasswordForm;
