import axios from 'axios';
import { updateCurrentUser } from '../../system/auth/actions';
import { addNotification } from '../../system/notifications/actions';
import {
  setUserList,
  resetUserList,
  setUserListLoading,
  addUserToList,
  deleteUserFromList,
  updateUserInList,
  savingUserData,
  showAddEditUserForm,
} from './actions';

export const loadUsers = () => (dispatch, getState, api) => {
  dispatch(setUserListLoading(true));
  axios
    .get(`${api}/users?limit=1000000`)
    .then((res) => {
      dispatch(resetUserList());
      dispatch(setUserList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setUserListLoading(false));
    });
};

export const createUser = (data) => (dispatch, getState, api) => {
  dispatch(setUserListLoading(true));
  dispatch(savingUserData(true));
  axios
    .post(`${api}/users`, data)
    .then((res) => {
      dispatch(addUserToList(res.data));
      dispatch(showAddEditUserForm(false));
      const notification = {
        message: '👍🏻 User created successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: Boolean(err.response.data)
          ? err.response.data.message
          : '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setUserListLoading(false));
      dispatch(savingUserData(false));
    });
};

export const updateUser = (id, data) => (dispatch, getState, api) => {
  dispatch(setUserListLoading(true));
  dispatch(savingUserData(true));
  axios
    .put(`${api}/users/${id}`, data)
    .then((res) => {
      dispatch(updateUserInList(res.data));
      dispatch(showAddEditUserForm(false));
      const notification = {
        message: '👍🏻 User updated successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setUserListLoading(false));
      dispatch(savingUserData(false));
    });
};

export const deleteUser = (id) => (dispatch, getState, api) => {
  dispatch(setUserListLoading(true));
  axios
    .delete(`${api}/users/${id}`)
    .then((res) => {
      dispatch(deleteUserFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  User deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setUserListLoading(false));
};

export const toggleUserStatus =
  (id, currentStatus) => (dispatch, getState, api) => {
    dispatch(setUserListLoading(true));
    axios
      .post(`${api}/users/activate/${id}/${!currentStatus}`)
      .then((res) => {
        console.log(res);
        dispatch(loadUsers());
        const notification = {
          message: '👍🏻 User status updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setUserListLoading(false));
      });
  };

export const changeUserPassword =
  (data, navigate) => (dispatch, getState, api) => {
    dispatch(savingUserData(true));
    axios
      .post(`${api}/users/change-password`, data)
      .then((res) => {
        navigate('/user_settings');
        const notification = {
          message: '👍🏻 User password changed successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: Boolean(err.response.data)
            ? err.response.data.message
            : '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(savingUserData(false));
      });
  };

export const updateUserDetails = (data) => (dispatch, getState, api) => {
  dispatch(savingUserData(true));
  axios
    .post(`${api}/users/update-details`, data)
    .then((res) => {
      dispatch(updateCurrentUser(res.data));
      const notification = {
        message: '👍🏻 User details saved successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: Boolean(err.response.data)
          ? err.response.data.message
          : '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(savingUserData(false));
    });
};
