import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getMachineList = createSelector(
  (state) => state.list_machine.list,
  (machineList) => {
    if (isEmpty(machineList)) return [];
    return machineList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
