import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCustomer,
  resetCustomer,
  resetCustomerList,
  showAddEditCustomerForm,
} from '../../redux/company/customers/actions';
import {
  loadCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  toggleCustomerStatus,
} from '../../redux/company/customers/services';
import { getCustomerList } from '../../redux/company/customers/selectors';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Paper,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import Loading from '../../components/UI/Loading';
import CustomerForm from '../../components/company/CustomerForm';
import Empty from '../../components/UI/Empty';
import CustomersTable from '../../components/company/CustomersTable';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
const isEmpty = require('is-empty');

const Customers = () => {
  const dispatch = useDispatch();
  const customersState = useSelector((state) => state.customers);
  const customerState = useSelector((state) => state.customers.item);
  const CustomersList = useSelector(getCustomerList);
  const [editMode, setEditMode] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [openRemoveCustomerDialog, setOpenRemoveCustomerDialog] =
    useState(false);

  useEffect(() => {
    dispatch(loadCustomers());
    return () => {
      dispatch(resetCustomerList());
    };
  }, [dispatch]);

  const handleClickAdd = () => {
    setEditMode(false);
    dispatch(showAddEditCustomerForm(true));
  };

  const handleClickEdit = (customer) => {
    setEditMode(true);
    dispatch(setCustomer(customer));
    dispatch(showAddEditCustomerForm(true));
  };

  const onSubmitAddEdit = (data) => {
    if (editMode) {
      dispatch(updateCustomer(customerState.id, data));
    } else {
      dispatch(createCustomer(data));
    }
  };

  const handleRefresh = () => {
    dispatch(loadCustomers());
  };

  const handleClickRemove = (customer) => {
    dispatch(setCustomer(customer));
    setOpenRemoveCustomerDialog(true);
  };

  const onRemoveCustomer = () => {
    dispatch(deleteCustomer(customerState.id));
    dispatch(resetCustomer());
    setOpenRemoveCustomerDialog(false);
  };

  const handleClickToogleStatus = (customer) => {
    dispatch(setCustomer(customer));
    setOpenStatusDialog(true);
  };

  const onToggleStatus = () => {
    dispatch(toggleCustomerStatus(customerState.id, customerState.active));
    dispatch(resetCustomer());
    setOpenStatusDialog(false);
  };

  return (
    <>
      <Loading loading={customersState.loading} />
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box display='flex' alignContent='center'>
            <IconButton aria-label='reload' onClick={handleRefresh}>
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              Customers
            </Typography>
          </Box>
          <Tooltip title='Add Customer'>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<AddIcon />}
              sx={{ marginRight: '0.5rem' }}
              onClick={handleClickAdd}
            >
              Add
            </Button>
          </Tooltip>
        </Box>
        <Box sx={{ width: '100%' }}>
          {!isEmpty(customersState.list) && (
            <CustomersTable
              rows={CustomersList}
              handleClickEdit={handleClickEdit}
              handleClickRemove={handleClickRemove}
              handleClickToogleStatus={handleClickToogleStatus}
            />
          )}
          {isEmpty(customersState.list) && !customersState.loading && (
            <Empty description='There are no customers to show' />
          )}
        </Box>
      </Paper>
      <ConfirmationDialog
        open={openRemoveCustomerDialog}
        handleNo={() => {
          setOpenRemoveCustomerDialog(false);
        }}
        handleYes={onRemoveCustomer}
        title='ePack - Remove Customer'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to remove customer: ${customerState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openStatusDialog}
        handleNo={() => {
          setOpenStatusDialog(false);
        }}
        handleYes={onToggleStatus}
        title='ePack - Customer Status'
        YesText={'Yes, Change'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to ${
            customerState?.active ? 'deactivate' : 'activate'
          } customer: ${customerState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <Dialog
        open={customersState.showAddEditForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setEditMode(false);
            dispatch(resetCustomer());
            dispatch(showAddEditCustomerForm(false));
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <CustomerForm
            editMode={editMode}
            customer={customerState}
            handleOnCancel={() => {
              setEditMode(false);
              dispatch(resetCustomer());
              dispatch(showAddEditCustomerForm(false));
            }}
            handleOnSubmit={onSubmitAddEdit}
            savingData={customersState.savingData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Customers;
