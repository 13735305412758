import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, TextField } from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .min(6, 'Password must be at least 6 characters'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

const ChangePasswordForm = ({ handleOnSubmit, handleOnCancel, savingData }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          handleOnSubmit({ password: data.password });
        })}
      >
        <Box>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <Controller
                name='password'
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label='Password'
                    fullWidth
                    type='password'
                    error={!!errors?.password}
                    helperText={errors?.password?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='confirmPassword'
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label='Confirm Password'
                    fullWidth
                    type='password'
                    error={!!errors?.confirmPassword}
                    helperText={errors?.confirmPassword?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box component='div' mx={1} my={0.5} display='flex'>
          <Box my={1.5} flexGrow={1}>
            <Button
              variant='contained'
              color='secondary'
              size='large'
              type='submit'
              startIcon={<PasswordIcon />}
            >
              {savingData ? 'Changing Password...' : 'Change Password'}
            </Button>
          </Box>
          <Box my={1.5}>
            <Button
              variant='outlined'
              size='large'
              startIcon={<UndoIcon />}
              onClick={() => {
                reset();
                handleOnCancel();
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ChangePasswordForm;
