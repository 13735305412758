import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  resetLaborLog,
  resetLaborLogList,
  setLaborLog,
  showAdjustLaborLogForm,
} from '../../redux/etime/labor-log/actions';
import {
  adjustLaborLog,
  loadLaborLogList,
} from '../../redux/etime/labor-log/services';
import { getLaborLogList } from '../../redux/etime/labor-log/selectors';
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import LaborLogsTable from '../../components/etime/LaborLogsTable';
import SearchLaborLogForm from '../../components/etime/SearchLaborLogForm';
import AdjustLaborLogForm from '../../components/etime/AdjustLaborLogForm';
import Loading from '../../components/UI/Loading';

const AdjustLaborLogs = () => {
  const dispatch = useDispatch();
  const laborLogsState = useSelector((state) => state.labor_log);
  const laborLogState = useSelector((state) => state.labor_log.item);
  const LaborLogList = useSelector(getLaborLogList);

  useEffect(() => {
    return () => {
      dispatch(resetLaborLogList());
    };
  }, [dispatch]);

  const handleClickAdjust = (laborLog) => {
    dispatch(setLaborLog(laborLog));
    dispatch(showAdjustLaborLogForm(true));
  };

  const onSubmitAdjust = (data) => {
    dispatch(adjustLaborLog(data));
  };

  const handleOnSearch = (data) => {
    dispatch(loadLaborLogList(data.name, data.start, data.end, data.employer));
  };

  return (
    <>
      <Loading loading={laborLogsState.loading} />
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box my={1} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              eTime - Adjust Labor Logs
            </Typography>
          </Box>
        </Box>
        <Divider variant='middle' light />
        <SearchLaborLogForm
          loading={laborLogsState.loading}
          handleOnSubmit={handleOnSearch}
        />
        <LaborLogsTable
          rows={LaborLogList}
          handleClickAdjust={handleClickAdjust}
        />
      </Paper>
      <Dialog
        open={laborLogsState.showAdjustForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            dispatch(resetLaborLog());
            dispatch(showAdjustLaborLogForm(false));
          }
        }}
        disableEscapeKeyDown
        maxWidth='md'
        fullWidth
      >
        <DialogContent>
          <AdjustLaborLogForm
            laborLog={laborLogState}
            handleOnCancel={() => {
              dispatch(resetLaborLog());
              dispatch(showAdjustLaborLogForm(false));
            }}
            handleOnSubmit={onSubmitAdjust}
            savingData={laborLogsState.savingData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdjustLaborLogs;
