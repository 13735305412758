import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > svg': {
      marginTop: theme.spacing(0),
      marginRight: theme.spacing(0.5),
      backgroundColor: '#E0E0E0',
    },
  },
}));

const EmployeeAvatar = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <svg
        viewBox="0 0 579.698 579.698"
        xmlns="http://www.w3.org/2000/svg"
        fill="#9e9e9e"
        {...props}
      >
        <path
          d="M63.671,428.914v113.088c0,20.836,16.86,37.696,37.696,37.696h376.964c20.836,0,37.696-16.86,37.696-37.696V428.914
			c0-62.47-50.578-113.089-113.088-113.089h-9.667c-5.07,0-9.134,4.122-9.62,9.168c-4.611,47.751-44.845,85.074-93.805,85.074
			s-89.193-37.323-93.801-85.074c-0.486-5.046-4.55-9.168-9.621-9.168h-9.667C114.323,315.825,63.671,366.444,63.671,428.914z"
        />
        <path
          d="M289.848,309.938c74.875,0,135.598-69.389,135.598-154.971S364.723,0,289.848,0S154.25,69.388,154.25,154.971
			C154.25,240.553,214.973,309.938,289.848,309.938z M289.848,154.971c35.377,0,64.888-17.895,74.192-42.222
			c13.924,16.778,22.663,38.06,22.663,61.595c0,53.498-43.397,96.855-96.855,96.855c-53.498,0-96.855-43.357-96.855-96.855
			C192.993,150.809,254.471,154.971,289.848,154.971z"
        />
      </svg>
    </div>
  );
};

export default EmployeeAvatar;
