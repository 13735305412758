import {
  SET_JOB_TYPE_LIST,
  JOB_TYPE_LIST_LOADING,
  JOB_TYPE_LIST_RESET,
} from './types';

export const setJobTypeList = (list) => {
  return {
    type: SET_JOB_TYPE_LIST,
    payload: list,
  };
};

export const resetJobTypeList = () => {
  return {
    type: JOB_TYPE_LIST_RESET,
  };
};

export const setJobTypeListLoading = (payload) => {
  return {
    type: JOB_TYPE_LIST_LOADING,
    payload,
  };
};
