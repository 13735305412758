export const SET_CONTACT = 'SET_CONTACT';
export const CONTACT_LOADING = 'CONTACT_LOADING';
export const CONTACT_RESET = 'CONTACT_RESET';
export const SET_CONTACTS = 'SET_CONTACTS';
export const CONTACT_LIST_LOADING = 'CONTACT_LIST_LOADING';
export const CONTACT_LIST_RESET = 'CONTACT_LIST_RESET';
export const ADD_CONTACT_TO_LIST = 'ADD_CONTACT_TO_LIST';
export const UPDATE_CONTACT_IN_LIST = 'UPDATE_CONTACT_IN_LIST';
export const DELETE_CONTACT_FROM_LIST = 'DELETE_CONTACT_FROM_LIST';
export const SHOW_ADD_EDIT_CONTACT_FORM = 'SHOW_ADD_EDIT_CONTACT_FORM';
export const SAVING_CONTACT_DATA = 'SAVING_CONTACT_DATA';
