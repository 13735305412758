import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CommodityIcon = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 332 330"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="3.29037" y="139.811" width="325" height="25" rx="10" />
      <rect x="31.2903" y="182.811" width="270" height="25" rx="10" />
      <rect x="41.2903" y="223.811" width="250" height="25" rx="10" />
      <rect x="56.2903" y="264.811" width="220" height="25" rx="10" />
      <rect
        x="23.0983"
        y="136.979"
        width="197.446"
        height="25"
        rx="10"
        transform="rotate(67.508 23.0983 136.979)"
      />
      <rect
        x="331.336"
        y="146.03"
        width="197.446"
        height="25"
        rx="10"
        transform="rotate(111.638 331.336 146.03)"
      />
      <rect
        x="244.47"
        y="146.024"
        width="181.289"
        height="25"
        rx="10"
        transform="rotate(101.128 244.47 146.024)"
      />
      <rect
        x="131.012"
        y="151.811"
        width="160.191"
        height="25"
        rx="10"
        transform="rotate(81.4361 131.012 151.811)"
      />
      <rect x="71.2903" y="301.811" width="190" height="25" rx="10" />
      <rect
        x="152"
        y="145.806"
        width="172.448"
        height="35"
        rx="10"
        transform="rotate(-57.2767 152 145.806)"
      />
      <circle cx="100.79" cy="109.311" r="47.5" />
      <ellipse
        cx="79.0815"
        cy="49.6026"
        rx="9.05263"
        ry="18.9363"
        transform="rotate(-45 79.0815 49.6026)"
      />
    </SvgIcon>
  );
};

export default CommodityIcon;
