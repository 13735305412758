import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getWorkOrderViewList = createSelector(
  (state) => state.production.work_order_view_list,
  (workOrderViewList) => {
    if (isEmpty(workOrderViewList)) return [];
    return workOrderViewList.sort((a, b) =>
      a.workOrder < b.workOrder ? -1 : 1
    );
  }
);
export const getCommodityViewList = createSelector(
  (state) => state.production.commodity_view_list,
  (commodityViewList) => {
    if (isEmpty(commodityViewList)) return [];
    return commodityViewList.sort((a, b) =>
      a.commodity < b.commodity ? -1 : 1
    );
  }
);
export const getMachineViewList = createSelector(
  (state) => state.production.machine_view_list,
  (machineViewList) => {
    if (isEmpty(machineViewList)) return [];
    return machineViewList.sort((a, b) => (a.machine < b.machine ? -1 : 1));
  }
);
export const getTableViewList = createSelector(
  (state) => state.production.table_view_list,
  (tableViewList) => {
    if (isEmpty(tableViewList)) return [];
    return tableViewList.sort((a, b) =>
      a.workOrder.code < b.workOrder.code ? -1 : 1
    );
  }
);

export const getPalletsIn = createSelector(
  (state) => state.production.item,
  (productionLine) => {
    if (isEmpty(productionLine)) return [];
    return productionLine.pallets.filter((item) => item.flow === 'IN');
  }
);

export const getPalletsOut = createSelector(
  (state) => state.production.item,
  (productionLine) => {
    if (isEmpty(productionLine)) return [];
    return productionLine.pallets.filter((item) => item.flow === 'OUT');
  }
);
