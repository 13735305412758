import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setCompanyList,
  resetCompanyList,
  setCompanyListLoading,
} from './actions';

export const loadCompanyList = () => (dispatch, getState, api) => {
  dispatch(setCompanyListLoading(true));
  axios
    .get(`${api}/list/companies`)
    .then((res) => {
      dispatch(resetCompanyList());
      dispatch(setCompanyList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCompanyListLoading(false));
    });
};
