import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Divider,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import MachineIcon from '../UI/icons/MachineIcon';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ReorderIcon from '@mui/icons-material/Reorder';
import TableViewIcon from '@mui/icons-material/TableView';
import MenuVisibilityGate from '../../utils/MenuVisibilityGate';

const isDemo = process.env.REACT_APP_DEMO === 'true' ? true : false;

const EPackMenu = ({ anchorEl, open, handleClose, userRole }) => {
  let navigate = useNavigate();
  return (
    <Menu
      id='e-pack-menu'
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      getcontentanchorel={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MenuVisibilityGate
        userRole={userRole}
        allowedPermissions={['epack:panel']}
      >
        <MenuItem
          onClick={() => {
            navigate('/epack/panel');
            handleClose();
          }}
        >
          <ListItemIcon>
            <TableViewIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Panel</ListItemText>
        </MenuItem>
        <Divider />
      </MenuVisibilityGate>
      <MenuVisibilityGate
        userRole={userRole}
        allowedPermissions={['epack:lines']}
      >
        <MenuItem
          disabled={isDemo}
          onClick={() => {
            navigate('/epack/lines');
            handleClose();
          }}
        >
          <ListItemIcon>
            <ReorderIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Lines</ListItemText>
        </MenuItem>
        <Divider />
      </MenuVisibilityGate>
      <MenuVisibilityGate
        userRole={userRole}
        allowedPermissions={['epack:machines']}
      >
        <MenuItem
          disabled={isDemo}
          onClick={() => {
            navigate('/epack/machines');
            handleClose();
          }}
        >
          <ListItemIcon>
            <MachineIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Machines</ListItemText>
        </MenuItem>
        <Divider />
      </MenuVisibilityGate>
      <MenuVisibilityGate
        userRole={userRole}
        allowedPermissions={['epack:workorders']}
      >
        <MenuItem
          disabled={isDemo}
          onClick={() => {
            navigate('/epack/workorders_pallets');
            handleClose();
          }}
        >
          <ListItemIcon>
            <ListAltIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Work Orders</ListItemText>
        </MenuItem>
      </MenuVisibilityGate>
    </Menu>
  );
};

export default EPackMenu;
