import React, { useState } from 'react';
import {
  Button,
  ButtonBase,
  ButtonGroup,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Dialog,
  DialogTitle,
  Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FALLBACK_IMAGE from '../../assets/no-image.svg';
import { grey } from '@mui/material/colors';

const onMediaFallback = (event) => (event.target.src = FALLBACK_IMAGE);

const getFullPhotoTypeName = (type) => {
  if (type === null || type === '') return;
  if (type === 'PALLET') return 'Pallet';
  if (type === 'GRADE_OUT') return 'Grade Out';
  if (type === 'CAT_2') return 'Cat 2';
};

const ClipboardPhotoList = ({ photos, readOnly, handleClickDelete }) => {
  const [photoToView, setPhotoToView] = useState(null);
  const [viewPhotoDialog, setViewPhotoDialog] = useState(false);

  const handleClosePhotoDialog = () => {
    setViewPhotoDialog(false);
    setPhotoToView(null);
  };

  return (
    <Grid container spacing={2}>
      {photos.map((photo) => (
        <Grid item xs={2} key={photo.id}>
          <Card
            elevation={0}
            variant='outlined'
            sx={{
              maxWidth: 150,
              '& .MuiCardHeader-root': {
                padding: 0,
                textAlign: 'center',
              },
            }}
            square
          >
            <CardHeader subheader={getFullPhotoTypeName(photo.photoType)} />
            <ButtonBase
              onClick={() => {
                setPhotoToView(photo);
                setViewPhotoDialog(true);
              }}
            >
              <CardMedia
                sx={{
                  height: 150,
                  width: 150,
                }}
                image={photo.photoUrl}
                title={getFullPhotoTypeName(photo.type)}
                onError={(e) => onMediaFallback(e)}
              />
            </ButtonBase>
            {!readOnly && (
              <CardActions
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  color: grey['600'],
                }}
              >
                <ButtonGroup variant='text' color='inherit' fullWidth>
                  <Button
                    sx={{ color: grey['800'] }}
                    startIcon={<DeleteIcon sx={{ color: 'error.main' }} />}
                    onClick={() => {
                      handleClickDelete(photo.id);
                    }}
                  >
                    Delete
                  </Button>
                </ButtonGroup>
              </CardActions>
            )}
          </Card>
        </Grid>
      ))}
      {photoToView && (
        <Dialog
          onClose={handleClosePhotoDialog}
          aria-labelledby='photo-dialog'
          open={viewPhotoDialog}
        >
          <DialogTitle id='photo-dialog'>
            {getFullPhotoTypeName(photoToView?.photoType)}
          </DialogTitle>
          <img
            src={photoToView?.photoUrl}
            width='600'
            height='600'
            alt={getFullPhotoTypeName(photoToView?.photoType)}
          />
        </Dialog>
      )}
    </Grid>
  );
};

export default ClipboardPhotoList;
