import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setProductionLineItem,
  resetProductionLineItem,
  setProductionLineItemLoading,
  setWorkOrderViewList,
  resetWorkOrderViewList,
  setWorkOrderViewListLoading,
  setCommodityViewList,
  resetCommodityViewList,
  setCommodityViewListLoading,
  setMachineViewList,
  resetMachineViewList,
  setMachineViewListLoading,
  setTableViewList,
  resetTableViewList,
  setTableViewListLoading,
  setWIPView,
  resetWIPView,
  setWIPViewLoading,
  setProductionLinePallet,
  resetProductionLinePallet,
  setProductionLinePalletLoading,
  showPalletAddScan,
  addPalletToProductionLine,
  addPhotoToProductionLine,
} from './actions';

export const loadWorkOrderViewList = () => (dispatch, getState, api) => {
  dispatch(setWorkOrderViewListLoading(true));
  axios
    .get(`${api}/production/work-order-view`)
    .then((res) => {
      dispatch(resetWorkOrderViewList());
      dispatch(setWorkOrderViewList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWorkOrderViewListLoading(false));
    });
};

export const loadCommodityViewList = () => (dispatch, getState, api) => {
  dispatch(setCommodityViewListLoading(true));
  axios
    .get(`${api}/production/commodity-view`)
    .then((res) => {
      dispatch(resetCommodityViewList());
      dispatch(setCommodityViewList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCommodityViewListLoading(false));
    });
};

export const loadMachineViewList = () => (dispatch, getState, api) => {
  dispatch(setMachineViewListLoading(true));
  axios
    .get(`${api}/production/machine-view`)
    .then((res) => {
      dispatch(resetMachineViewList());
      dispatch(setMachineViewList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineViewListLoading(false));
    });
};

export const loadTableViewList = () => (dispatch, getState, api) => {
  dispatch(setTableViewListLoading(true));
  axios
    .get(`${api}/production/table-view`)
    .then((res) => {
      dispatch(resetTableViewList());
      dispatch(setTableViewList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setTableViewListLoading(false));
    });
};

export const loadWIPView = (id) => (dispatch, getState, api) => {
  dispatch(setWIPViewLoading(true));
  axios
    .get(`${api}/production/wip-view/${id}`)
    .then((res) => {
      dispatch(resetWIPView());
      dispatch(setWIPView(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWIPViewLoading(false));
    });
};

export const startAll = (activeTab) => (dispatch, getState, api) => {
  axios
    .post(`${api}/production/start/all`)
    .then((res) => {
      refreshList(activeTab, dispatch);
      const notification = {
        message: '👍🏻 All lines started successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
};

export const stopAll = (activeTab) => (dispatch, getState, api) => {
  axios
    .post(`${api}/production/stop/all`)
    .then((res) => {
      refreshList(activeTab, dispatch);
      const notification = {
        message: '👍🏻 All lines stopped successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
};

export const unavailableAll =
  (activeTab, code) => (dispatch, getState, api) => {
    axios
      .post(`${api}/production/unavailable/all/${code}`)
      .then((res) => {
        refreshList(activeTab, dispatch);
        const notification = {
          message: '👍🏻 All lines are unavailable',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      });
  };

export const closeAll = (activeTab) => (dispatch, getState, api) => {
  axios
    .post(`${api}/production/close/all/`)
    .then((res) => {
      refreshList(activeTab, dispatch);
      const notification = {
        message: '👍🏻 All lines closed successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
};

export const closeByWorkOrder = (id) => (dispatch, getState, api) => {
  dispatch(setWorkOrderViewListLoading(true));
  axios
    .post(`${api}/production/close/by-work-order/${id}`)
    .then((res) => {
      dispatch(loadWorkOrderViewList());
      const notification = {
        message: '👍🏻 All lines closed successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWorkOrderViewListLoading(false));
    });
};

export const closeByCommodity = (id) => (dispatch, getState, api) => {
  dispatch(setCommodityViewListLoading(true));
  axios
    .post(`${api}/production/close/by-commodity/${id}`)
    .then((res) => {
      dispatch(loadCommodityViewList());
      const notification = {
        message: '👍🏻 All lines closed successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCommodityViewListLoading(false));
    });
};

export const closeByMachine = (id) => (dispatch, getState, api) => {
  dispatch(setMachineViewListLoading(true));
  axios
    .post(`${api}/production/close/by-machine/${id}`)
    .then((res) => {
      dispatch(loadCommodityViewList());
      const notification = {
        message: '👍🏻 All lines closed successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineViewListLoading(false));
    });
};

export const startAllByWorkOrder = (id) => (dispatch, getState, api) => {
  dispatch(setWorkOrderViewListLoading(true));
  axios
    .post(`${api}/production/start/by-work-order/${id}`)
    .then((res) => {
      dispatch(loadWorkOrderViewList());
      const notification = {
        message: '👍🏻 All lines started successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWorkOrderViewListLoading(false));
    });
};

export const startAllByCommodity = (id) => (dispatch, getState, api) => {
  dispatch(setCommodityViewListLoading(true));
  axios
    .post(`${api}/production/start/by-commodity/${id}`)
    .then((res) => {
      dispatch(loadCommodityViewList());
      const notification = {
        message: '👍🏻 All lines started successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCommodityViewListLoading(false));
    });
};

export const startAllByMachine = (id) => (dispatch, getState, api) => {
  dispatch(setMachineViewListLoading(true));
  axios
    .post(`${api}/production/start/by-machine/${id}`)
    .then((res) => {
      dispatch(loadMachineViewList());
      const notification = {
        message: '👍🏻 All lines started successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineViewListLoading(false));
    });
};

export const stopAllByWorkOrder = (id) => (dispatch, getState, api) => {
  dispatch(setWorkOrderViewListLoading(true));
  axios
    .post(`${api}/production/stop/by-work-order/${id}`)
    .then((res) => {
      dispatch(loadWorkOrderViewList());
      const notification = {
        message: '👍🏻 All lines stopped successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWorkOrderViewListLoading(false));
    });
};

export const stopAllByCommodity = (id) => (dispatch, getState, api) => {
  dispatch(setCommodityViewListLoading(true));
  axios
    .post(`${api}/production/stop/by-commodity/${id}`)
    .then((res) => {
      dispatch(loadCommodityViewList());
      const notification = {
        message: '👍🏻 All lines stopped successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCommodityViewListLoading(false));
    });
};

export const stopAllByMachine = (id) => (dispatch, getState, api) => {
  dispatch(setMachineViewListLoading(true));
  axios
    .post(`${api}/production/stop/by-machine/${id}`)
    .then((res) => {
      dispatch(loadMachineViewList());
      const notification = {
        message: '👍🏻 All lines stopped successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMachineViewListLoading(false));
    });
};

export const unavailableAllByWorkOrder =
  (id, code) => (dispatch, getState, api) => {
    dispatch(setWorkOrderViewListLoading(true));
    axios
      .post(`${api}/production/unavailable/by-work-order/${id}/${code}`)
      .then((res) => {
        dispatch(loadWorkOrderViewList());
        const notification = {
          message: '👍🏻 All are now lines unavailable ',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setWorkOrderViewListLoading(false));
      });
  };

export const unavailableAllByCommodity =
  (id, code) => (dispatch, getState, api) => {
    dispatch(setCommodityViewListLoading(true));
    axios
      .post(`${api}/production/unavailable/by-commodity/${id}/${code}`)
      .then((res) => {
        dispatch(loadCommodityViewList());
        const notification = {
          message: '👍🏻 All are now lines unavailable ',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setCommodityViewListLoading(false));
      });
  };

export const unavailableAllByMachine =
  (id, code) => (dispatch, getState, api) => {
    dispatch(setMachineViewListLoading(true));
    axios
      .post(`${api}/production/unavailable/by-machine/${id}/${code}`)
      .then((res) => {
        dispatch(loadMachineViewList());
        const notification = {
          message: '👍🏻 All are now lines unavailable ',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setMachineViewListLoading(false));
      });
  };

export const loadProductionLine = (id) => (dispatch, getState, api) => {
  dispatch(setProductionLineItemLoading(true));
  axios
    .get(`${api}/production/by-id/${id}`)
    .then((res) => {
      dispatch(resetProductionLineItem());
      dispatch(setProductionLineItem(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setProductionLineItemLoading(false));
    });
};

export const addProductionLine =
  (activeTab, data, palletScanned) => (dispatch, getState, api) => {
    const state = getState();
    axios
      .post(`${api}/production/`, data)
      .then((res) => {
        if (palletScanned) {
          const updatePallet = {
            ...state.production.pallet,
            lineId: res.data.id,
            itemDescription: res.data.itemDescription,
            machine: res.data.machine,
          };
          dispatch(setProductionLinePallet(updatePallet));
        } else {
          refreshList(activeTab, dispatch);
        }
        const notification = {
          message: '👍🏻 Production line added successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: Boolean(err.response.data)
            ? err.response.data.message
            : '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      });
  };

export const updateProductionLine = (id, data) => (dispatch, getState, api) => {
  axios
    .put(`${api}/production/${id}`, data)
    .then((res) => {
      dispatch(resetProductionLineItem());
      dispatch(setProductionLineItem(res.data));
      const notification = {
        message: '👍🏻 Production Line saved successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
};

export const startProductionLine =
  (activeTab, id) => (dispatch, getState, api) => {
    dispatch(setWorkOrderViewListLoading(true));
    axios
      .post(`${api}/production/start/by-id/${id}`)
      .then((res) => {
        refreshList(activeTab, dispatch);
        const notification = {
          message: '👍🏻 Production line started successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setWorkOrderViewListLoading(false));
      });
  };

export const stopProductionLine =
  (activeTab, id) => (dispatch, getState, api) => {
    dispatch(setWorkOrderViewListLoading(true));
    axios
      .post(`${api}/production/stop/by-id/${id}`)
      .then((res) => {
        refreshList(activeTab, dispatch);
        const notification = {
          message: '👍🏻 Production line stopped successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setWorkOrderViewListLoading(false));
      });
  };

export const closeProductionLine =
  (activeTab, id) => (dispatch, getState, api) => {
    dispatch(setWorkOrderViewListLoading(true));
    axios
      .post(`${api}/production/close/by-id/${id}`)
      .then((res) => {
        refreshList(activeTab, dispatch);
        const notification = {
          message: '👍🏻 Production line closed successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setWorkOrderViewListLoading(false));
      });
  };

export const unavailableProductionLine =
  (activeTab, id, code) => (dispatch, getState, api) => {
    dispatch(setWorkOrderViewListLoading(true));
    axios
      .post(`${api}/production/unavailable/by-id/${id}/${code}`)
      .then((res) => {
        refreshList(activeTab, dispatch);
        const notification = {
          message: '👍🏻 Production line unavailable',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setWorkOrderViewListLoading(false));
      });
  };

export const setupProductionLine =
  (id, data, activeTab) => (dispatch, getState, api) => {
    dispatch(setProductionLineItemLoading(true));
    axios
      .post(`${api}/production/setup/${id}`, data)
      .then((res) => {
        refreshList(activeTab, dispatch);
        const notification = {
          message: '👍🏻 Production line setup saved successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setProductionLineItemLoading(false));
      });
  };

export const updateSanitationOnProductionLine =
  (id, data, activeTab) => (dispatch, getState, api) => {
    dispatch(setProductionLineItemLoading(true));
    axios
      .post(`${api}/production/sanitation/${id}`, data)
      .then((res) => {
        refreshList(activeTab, dispatch);
        const notification = {
          message: '👍🏻 Production line sanitation saved successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setProductionLineItemLoading(false));
      });
  };

export const loadProductionLinePallet = (id) => (dispatch, getState, api) => {
  dispatch(setProductionLinePalletLoading(true));
  axios
    .get(`${api}/production/pallet/by-id/${id}`)
    .then((res) => {
      dispatch(resetProductionLinePallet());
      dispatch(setProductionLinePallet(res.data));
    })
    .catch((err) => {
      console.log(err);
      let notification = {};
      if (err.response.status === 404) {
        notification = {
          message: 'Pallet not in the system',
          options: {
            variant: 'error',
          },
        };
      } else {
        notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
      }

      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setProductionLinePalletLoading(false));
    });
};

export const loadProductionLinePalletByCode =
  (code, location) => (dispatch, getState, api) => {
    dispatch(setProductionLinePalletLoading(true));
    axios
      .get(`${api}/production/pallet/by-code/${code}`)
      .then((res) => {
        dispatch(resetProductionLinePallet());
        dispatch(setProductionLinePallet(res.data));
      })
      .catch((err) => {
        console.log(err);
        let notification = {};
        if (err.response.status === 404) {
          if (location.pathname.includes('epack/production_clipboard/')) {
            dispatch(showPalletAddScan(true));
          } else {
            notification = {
              message: 'Pallet not in the system',
              options: {
                variant: 'error',
              },
            };
            dispatch(addNotification(notification));
          }
        } else {
          notification = {
            message: '😱 Something went wrong, please try again',
            options: {
              variant: 'error',
            },
          };
          dispatch(addNotification(notification));
        }
      })
      .finally(() => {
        dispatch(setProductionLinePalletLoading(false));
      });
  };

export const addProductionLinePallet =
  (id, palletData, weightData, weightType) => (dispatch, getState, api) => {
    let newPalletId = '';
    dispatch(setProductionLineItemLoading(true));
    axios
      .post(`${api}/production/pallet/${id}`, palletData)
      .then((res) => {
        dispatch(addPalletToProductionLine(res.data));
        dispatch(resetProductionLinePallet());
        dispatch(showPalletAddScan(false));
        newPalletId = res.data.id;
        const notification = {
          message: '👍🏻 Pallet added successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .then(() => {
        if (weightType !== 'NONE') {
          dispatch(
            updateProductionLinePalletWeight(
              id,
              newPalletId,
              weightData,
              weightType
            )
          );
        }
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setProductionLineItemLoading(false));
      });
  };

export const updateProductionLinePalletWeight =
  (lineId, palletId, weightData, weightType) => (dispatch, getState, api) => {
    let url = '';
    switch (weightType) {
      case 'BY_SCALE':
        url = `${api}/production/pallet/weight/by-scale/${lineId}/${palletId}`;
        break;
      case 'BY_PALLET':
        url = `${api}/production/pallet/weight/by-pallet/${lineId}/${palletId}`;
        break;
      case 'BY_CASES':
        url = `${api}/production/pallet/weight/by-cases/${lineId}/${palletId}`;
        break;
      default:
        break;
    }
    axios
      .post(url, weightData)
      .then(() => {
        dispatch(resetProductionLinePallet());
        dispatch(showPalletAddScan(false));
        const notification = {
          message: '👍🏻 Weight saved successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      });
  };

export const deleteProductionLinePallet =
  (productionLineId, palletId) => (dispatch, getState, api) => {
    dispatch(setProductionLineItemLoading(true));
    axios
      .delete(`${api}/production/pallet/${productionLineId}/${palletId}`)
      .then((res) => {
        dispatch(loadProductionLine(productionLineId));
        const notification = {
          title: 'Deleted',
          message: '👍🏻  Pallet deleted successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      });
    dispatch(setProductionLineItemLoading(false));
  };

export const addProductionLinePhoto =
  (id, type, data) => (dispatch, getState, api) => {
    dispatch(setProductionLineItemLoading(true));
    axios
      .post(`${api}/production/photo/${id}/${type}`, data)
      .then((res) => {
        dispatch(addPhotoToProductionLine(res.data));
        const notification = {
          message: '👍🏻 Photo added successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setProductionLineItemLoading(false));
      });
  };

export const deleteProductionLinePhoto =
  (productionLineId, photoId) => (dispatch, getState, api) => {
    dispatch(setProductionLineItemLoading(true));
    axios
      .delete(`${api}/production/photo/${productionLineId}/${photoId}`)
      .then((res) => {
        dispatch(loadProductionLine(productionLineId));
        const notification = {
          title: 'Deleted',
          message: '👍🏻  Photo deleted successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      });
    dispatch(setProductionLineItemLoading(false));
  };

export const addPalletsToProductionLineFromWorkOrder =
  (id) => (dispatch, getState, api) => {
    dispatch(setProductionLineItemLoading(true));
    axios
      .post(`${api}/production/pallet/from-work-order/${id}`)
      .then((res) => {
        dispatch(setProductionLineItem(res.data));
        const notification = {
          message: '👍🏻 Pallets added successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setProductionLineItemLoading(false));
      });
  };

export const movePalletToIn =
  (productionLineId, palletId) => (dispatch, getState, api) => {
    dispatch(setProductionLineItemLoading(true));
    axios
      .post(
        `${api}/production/pallet/move-to-in/${productionLineId}/${palletId}`
      )
      .then((res) => {
        dispatch(setProductionLineItem(res.data));
        const notification = {
          message: '👍🏻 Pallet moved successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setProductionLineItemLoading(false));
      });
  };

export const movePalletToOut =
  (productionLineId, palletId) => (dispatch, getState, api) => {
    dispatch(setProductionLineItemLoading(true));
    axios
      .post(
        `${api}/production/pallet/move-to-out/${productionLineId}/${palletId}`
      )
      .then((res) => {
        dispatch(setProductionLineItem(res.data));
        const notification = {
          message: '👍🏻 Pallet moved successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setProductionLineItemLoading(false));
      });
  };

const refreshList = (activeTab, dispatch) => {
  if (activeTab === 0) {
    dispatch(loadTableViewList());
  } else if (activeTab === 1) {
    dispatch(loadWorkOrderViewList());
  } else if (activeTab === 2) {
    dispatch(loadMachineViewList());
  } else if (activeTab === 3) {
    dispatch(loadCommodityViewList());
  }
};
