import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getSummaryList = createSelector(
  (state) => state.labor_summary.list,
  (summaryViewList) => {
    if (isEmpty(summaryViewList)) return [];
    return summaryViewList.sort((a, b) => (a.workOrder < b.workOrder ? -1 : 1));
  }
);
