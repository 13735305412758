import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getProductionDashboardData = createSelector(
  (state) => state.main_dashboard.data.production,
  (list) => {
    if (isEmpty(list)) return [];
    return list.sort((a, b) => (a.productionLine < b.productionLine ? -1 : 1));
  }
);

export const selectAverageYield = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    const filteredList = list.filter(
      (item) => item.status === 'IN_PROGRESS' || item.status === 'AVAILABLE'
    );
    return (
      (filteredList.reduce((acc, val) => {
        return acc + parseFloat(val.yieldCases);
      }, 0) /
        filteredList.length) *
      100
    ).toFixed(1);
  }
);

export const selectAverageAvailability = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return (
      (list.reduce((acc, val) => {
        return acc + parseFloat(val.availability);
      }, 0) /
        list.length) *
      100
    ).toFixed(1);
  }
);

export const selectAverageThroughput = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return (
      list.reduce((acc, val) => {
        return acc + parseFloat(val.rateCases);
      }, 0) / list.length
    ).toFixed(1);
  }
);

export const selectAverageUtilization = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return (
      (list.reduce((acc, val) => {
        return acc + parseFloat(val.utilization);
      }, 0) /
        list.length) *
      100
    ).toFixed(0);
  }
);

export const selectAverageWaste = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    const avgWaste = (
      list.reduce((acc, val) => {
        return acc + parseFloat(val.waste * 100);
      }, 0) / list.length
    ).toFixed(1);
    return avgWaste;
  }
);

export const selectTotalNumberOfProductionLines = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.length;
  }
);

export const selectNumberOfAvailableProductionLines = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter((item) => item.status === 'AVAILABLE').length;
  }
);

export const selectNumberOfUnavailableProductionLines = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter(
      (item) => item.status === 'NOT_AVAILABLE' || item.status === 'SANITATION'
    ).length;
  }
);

export const selectNumberOfInProgressProductionLines = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter((item) => item.status === 'IN_PROGRESS').length;
  }
);

export const selectNumberOfSetupProductionLines = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter((item) => item.status === 'SETUP').length;
  }
);

export const selectNumberOfSanitationProductionLines = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter((item) => item.status === 'SANITATION').length;
  }
);

export const selectNumberOfCasesInProduction = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.reduce((acc, val) => {
      return acc + parseFloat(val.casesIn);
    }, 0);
  }
);

export const selectNumberOfCasesOutProduction = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.reduce((acc, val) => {
      return acc + parseFloat(val.casesOut);
    }, 0);
  }
);
export const selectNumberOfPalletsInProduction = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.reduce((acc, val) => {
      return acc + parseFloat(val.palletsIn);
    }, 0);
  }
);

export const selectNumberOfPalletsOutProduction = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.reduce((acc, val) => {
      return acc + parseFloat(val.palletsOut);
    }, 0);
  }
);

export const selectMachinesCasesInOut = createSelector(
  getProductionDashboardData,
  (list) => {
    if (isEmpty(list)) return [];
    let machines = [
      ...new Set(
        list
          .filter(
            (item) => item.status !== 'SETUP' && item.status !== 'SANITATION'
          )
          .map((item) => item.machine)
      ),
    ];
    for (let i = 0; i < machines.length; i++) {
      machines[i] = {
        machine: machines[i],
        casesIn: list
          .filter((item) => item.machine === machines[i])
          .reduce((acc, val) => {
            return acc + parseFloat(val.casesIn);
          }, 0),
        casesOut: list
          .filter((item) => item.machine === machines[i])
          .reduce((acc, val) => {
            return acc + parseFloat(val.casesOut);
          }, 0),
      };
    }
    return machines;
  }
);

export const getLaborDashboardData = createSelector(
  (state) => state.main_dashboard.data.labor,
  (list) => {
    if (isEmpty(list)) return [];
    return list.sort((a, b) => (a.firstName < b.firstName ? -1 : 1));
  }
);

export const selectNumberOfActiveEmployees = createSelector(
  getLaborDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter((item) => item.isIdle === false).length;
  }
);

export const selectNumberOfIdleEmployees = createSelector(
  getLaborDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter(
      (item) => item.isIdle === true && item.unallocated === false
    ).length;
  }
);

export const selectNumberOfUnallocatedEmployees = createSelector(
  getLaborDashboardData,
  (list) => {
    if (isEmpty(list)) return 0;
    return list.filter((item) => item.unallocated === true).length;
  }
);

export const selectAllocationByJob = createSelector(
  getLaborDashboardData,
  (list) => {
    const data = {
      labels: [],
      values: [],
    };
    if (isEmpty(list)) return data;
    data.labels = [
      ...new Set(
        list
          .filter((item) => item.unallocated !== true)
          .map((item) => item.jobType)
      ),
    ];
    data.labels = data.labels.slice(0, 9);
    for (let i = 0; i < data.labels.length; i++) {
      data.values[i] = list.filter(
        (item) => item.jobType === data.labels[i]
      ).length;
    }
    return data;
  }
);

export const selectAllocationByPosition = createSelector(
  getLaborDashboardData,
  (list) => {
    const data = {
      labels: [],
      values: [],
    };
    if (isEmpty(list)) return data;
    data.labels = [
      ...new Set(
        list
          .filter((item) => item.unallocated !== true)
          .map((item) => item.employeePosition)
      ),
    ];
    data.labels = data.labels.slice(0, 9);
    for (let i = 0; i < data.labels.length; i++) {
      data.values[i] = list.filter(
        (item) => item.employeePosition === data.labels[i]
      ).length;
    }
    return data;
  }
);
