import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setWorkOrderList,
  resetWorkOrderList,
  setWorkOrderListLoading,
  addWorkOrderToList,
  deleteWorkOrderFromList,
  deletePalletFromList,
  updateWorkOrderInList,
  savingWorkOrderData,
  showAddEditWorkOrderForm,
  showImportWorkOrdersForm,
  importingWorkOrdersData,
} from './actions';

export const loadWorkOrders = () => (dispatch, getState, api) => {
  dispatch(setWorkOrderListLoading(true));
  axios
    .get(`${api}/work-orders?limit=1000000`)
    .then((res) => {
      dispatch(resetWorkOrderList());
      dispatch(setWorkOrderList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWorkOrderListLoading(false));
    });
};

export const toggleWorkOrderStatus =
  (id, currentStatus) => (dispatch, getState, api) => {
    dispatch(setWorkOrderListLoading(true));
    axios
      .post(`${api}/work-orders/activate/${id}/${!currentStatus}`)
      .then((res) => {
        console.log(res);
        dispatch(loadWorkOrders());
        const notification = {
          message: '👍🏻 Work Order status updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setWorkOrderListLoading(false));
      });
  };

export const createWorkOrder = (data) => (dispatch, getState, api) => {
  dispatch(setWorkOrderListLoading(true));
  dispatch(savingWorkOrderData(true));
  axios
    .post(`${api}/work-orders`, data)
    .then((res) => {
      dispatch(addWorkOrderToList(res.data));
      dispatch(showAddEditWorkOrderForm(false));
      const notification = {
        message: '👍🏻 Work Order created successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWorkOrderListLoading(false));
      dispatch(savingWorkOrderData(false));
    });
};

export const updateWorkOrder = (data) => (dispatch, getState, api) => {
  dispatch(setWorkOrderListLoading(true));
  dispatch(savingWorkOrderData(true));
  axios
    .post(`${api}/work-orders`, data)
    .then((res) => {
      dispatch(updateWorkOrderInList(res.data));
      dispatch(showAddEditWorkOrderForm(false));
      const notification = {
        message: '👍🏻 Work Order updated successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setWorkOrderListLoading(false));
      dispatch(savingWorkOrderData(false));
    });
};

export const deleteWorkOrder = (workOrderId) => (dispatch, getState, api) => {
  dispatch(setWorkOrderListLoading(true));
  axios
    .delete(`${api}/work-orders/${workOrderId}/,`)
    .then((res) => {
      dispatch(deleteWorkOrderFromList(workOrderId));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Work Order deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setWorkOrderListLoading(false));
};

export const deletePallet =
  (workOrderId, palletId) => (dispatch, getState, api) => {
    dispatch(setWorkOrderListLoading(true));
    axios
      .delete(`${api}/work-orders/${workOrderId}/${palletId}`)
      .then((res) => {
        dispatch(deletePalletFromList(workOrderId, palletId));
        const notification = {
          title: 'Deleted',
          message: '👍🏻  Pallet deleted successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      });
    dispatch(setWorkOrderListLoading(false));
  };

export const importWorkOrders = (data) => (dispatch, getState, api) => {
  dispatch(importingWorkOrdersData(true));
  const formData = new FormData();
  formData.append('file', data.file);
  axios
    .post(`${api}/work-orders/import`, data)
    .then((res) => {
      dispatch(loadWorkOrders(res.data));
      dispatch(showImportWorkOrdersForm(false));
      const notification = {
        message: '👍🏻 Work Orders imported successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(importingWorkOrdersData(false));
    });
};
