import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setMORProfileList,
  resetMORProfileList,
  setMORProfileListLoading,
  deleteMORProfileFromList,
  savingMORProfileData,
  resetMORProfileFilters,
  setMORProfile,
  resetMORProfile,
  setMORProfileLoading,
  addMORProfileToList,
  showDuplicateMORProfileForm,
} from './actions';

export const loadMORProfiles = () => (dispatch, getState, api) => {
  dispatch(setMORProfileListLoading(true));
  axios
    .get(`${api}/profile-mor?limit=1000000`)
    .then((res) => {
      dispatch(resetMORProfileFilters());
      dispatch(resetMORProfileList());
      dispatch(setMORProfileList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMORProfileListLoading(false));
    });
};

export const loadMORProfile = (id) => (dispatch, getState, api) => {
  dispatch(setMORProfileLoading(true));
  axios
    .get(`${api}/profile-mor/by-id/${id}`)
    .then((res) => {
      dispatch(resetMORProfile());
      dispatch(setMORProfile(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setMORProfileLoading(false));
    });
};

export const createMORProfile =
  (data, navigate) => (dispatch, getState, api) => {
    dispatch(savingMORProfileData(true));
    axios
      .post(`${api}/profile-mor`, data)
      .then((res) => {
        navigate('/eplan/mor_profiles');
        const notification = {
          message: '👍🏻 MOR Profile created successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(savingMORProfileData(false));
      });
  };

export const updateMORProfile =
  (id, data, navigate) => (dispatch, getState, api) => {
    dispatch(savingMORProfileData(true));
    axios
      .put(`${api}/profile-mor/${id}`, data)
      .then((res) => {
        navigate('/eplan/mor_profiles');
        const notification = {
          message: '👍🏻 MOR Profile updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(savingMORProfileData(false));
      });
  };

export const deleteMORProfile = (id) => (dispatch, getState, api) => {
  dispatch(setMORProfileListLoading(true));
  axios
    .delete(`${api}/profile-mor/${id}`)
    .then((res) => {
      dispatch(deleteMORProfileFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  MOR Profile deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setMORProfileListLoading(false));
};

export const duplicateMORProfile = (id, data) => (dispatch, getState, api) => {
  dispatch(savingMORProfileData(true));
  axios
    .post(`${api}/profile-mor/duplicate/${id}`, data)
    .then((res) => {
      dispatch(addMORProfileToList(res.data));
      dispatch(showDuplicateMORProfileForm(false));
      const notification = {
        message: '👍🏻 MOR Profile duplicated successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      let notification;
      if (err.response.status === 409) {
        notification = {
          message: err.response.data.message,
          options: {
            variant: 'error',
          },
        };
      } else {
        notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
      }
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(savingMORProfileData(false));
    });
};
