import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getWorkOrderViewList = createSelector(
  (state) => state.labor_work_order_view.list,
  (workOrderViewList) => {
    if (isEmpty(workOrderViewList)) return [];
    return workOrderViewList.sort((a, b) => (a.code < b.code ? -1 : 1));
  }
);
