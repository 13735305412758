import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getSetupChecklistItems = createSelector(
  (state) => state.list_setup_checklist_items.items,
  (setupChecklistItems) => {
    if (isEmpty(setupChecklistItems)) return [];
    return setupChecklistItems.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
