import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Divider,
  Grid,
  CardActions,
  CardContent,
} from '@mui/material';
import EmployeeTile from './EmployeeTile';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import grey from '@mui/material/colors/grey';

const EmployeesTileList = ({
  employees,
  handleClickUpdate,
  handleClickRemove,
}) => {
  return (
    <Grid container spacing={1}>
      {employees.map((employee) => {
        return (
          <Grid item xs={3} key={employee.id}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
              id={employee.lastName[0]}
            >
              <CardContent sx={{ padding: '0' }}>
                <EmployeeTile
                  flags={employee.flags}
                  photo={employee.photo}
                  active={employee.active}
                  firstName={employee.firstName}
                  lastName={employee.lastName}
                  latestPosition={employee.latestPosition}
                  employer={employee.employer}
                />
              </CardContent>
              <Divider />
              <CardActions
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  color: grey['600'],
                }}
              >
                <ButtonGroup variant="text" color="inherit" fullWidth>
                  <Button
                    sx={{ color: grey['800'] }}
                    startIcon={<EditIcon sx={{ color: 'primary.main' }} />}
                    onClick={() => handleClickUpdate(employee)}
                  >
                    Edit
                  </Button>
                  <Button
                    sx={{ color: grey['800'] }}
                    startIcon={<DeleteIcon sx={{ color: 'error.main' }} />}
                    onClick={() => {
                      handleClickRemove(employee);
                    }}
                  >
                    Delete
                  </Button>
                </ButtonGroup>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default EmployeesTileList;
