import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getLinesList = createSelector(
  (state) => state.lines.list.docs,
  (linesList) => {
    if (isEmpty(linesList)) return [];
    return linesList.sort((a, b) =>
      a.workOrder.code < b.workOrder.code ? -1 : 1
    );
  }
);
export const getPalletsIn = createSelector(
  (state) => state.lines.line,
  (productionLine) => {
    if (isEmpty(productionLine)) return [];
    return productionLine.pallets.filter((item) => item.flow === 'IN');
  }
);

export const getPalletsOut = createSelector(
  (state) => state.lines.line,
  (productionLine) => {
    if (isEmpty(productionLine)) return [];
    return productionLine.pallets.filter((item) => item.flow === 'OUT');
  }
);
