import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setEmployer,
  resetEmployer,
  resetEmployerList,
  showAddEditEmployerForm,
} from '../../redux/company/employers/actions';
import {
  loadEmployers,
  createEmployer,
  updateEmployer,
  deleteEmployer,
  toggleEmployerStatus,
} from '../../redux/company/employers/services';
import { getEmployerList } from '../../redux/company/employers/selectors';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Paper,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import Loading from '../../components/UI/Loading';
import EmployerForm from '../../components/company/EmployerForm';
import Empty from '../../components/UI/Empty';
import EmployersTable from '../../components/company/EmployersTable';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
const isEmpty = require('is-empty');

const Employers = () => {
  const dispatch = useDispatch();
  const employersState = useSelector((state) => state.employers);
  const employerState = useSelector((state) => state.employers.item);
  const EmployersList = useSelector(getEmployerList);
  const [editMode, setEditMode] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [openRemoveEmployerDialog, setOpenRemoveEmployerDialog] =
    useState(false);

  useEffect(() => {
    dispatch(loadEmployers());
    return () => {
      dispatch(resetEmployerList());
    };
  }, [dispatch]);

  const handleClickAdd = () => {
    setEditMode(false);
    dispatch(showAddEditEmployerForm(true));
  };

  const handleClickEdit = (employer) => {
    setEditMode(true);
    dispatch(setEmployer(employer));
    dispatch(showAddEditEmployerForm(true));
  };

  const onSubmitAddEdit = (data) => {
    if (editMode) {
      dispatch(updateEmployer(employerState.id, data));
    } else {
      dispatch(createEmployer(data));
    }
  };

  const handleRefresh = () => {
    dispatch(loadEmployers());
  };

  const handleClickRemove = (employer) => {
    dispatch(setEmployer(employer));
    setOpenRemoveEmployerDialog(true);
  };

  const onRemoveEmployer = () => {
    dispatch(deleteEmployer(employerState.id));
    dispatch(resetEmployer());
    setOpenRemoveEmployerDialog(false);
  };

  const handleClickToogleStatus = (employer) => {
    dispatch(setEmployer(employer));
    setOpenStatusDialog(true);
  };

  const onToggleStatus = () => {
    dispatch(toggleEmployerStatus(employerState.id, employerState.active));
    dispatch(resetEmployer());
    setOpenStatusDialog(false);
  };

  return (
    <>
      <Loading loading={employersState.loading} />
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box display='flex' alignContent='center'>
            <IconButton aria-label='reload' onClick={handleRefresh}>
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              Employers
            </Typography>
          </Box>
          <Tooltip title='Add Employer'>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<AddIcon />}
              sx={{ marginRight: '0.5rem' }}
              onClick={handleClickAdd}
            >
              Add
            </Button>
          </Tooltip>
        </Box>
        <Box sx={{ width: '100%' }}>
          {!isEmpty(employersState.list) && (
            <EmployersTable
              rows={EmployersList}
              handleClickEdit={handleClickEdit}
              handleClickRemove={handleClickRemove}
              handleClickToogleStatus={handleClickToogleStatus}
            />
          )}
          {isEmpty(employersState.list) && !employersState.loading && (
            <Empty description='There are no employers to show' />
          )}
        </Box>
      </Paper>
      <ConfirmationDialog
        open={openRemoveEmployerDialog}
        handleNo={() => {
          setOpenRemoveEmployerDialog(false);
        }}
        handleYes={onRemoveEmployer}
        title='ePack - Remove Employer'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to remove employer: ${employerState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openStatusDialog}
        handleNo={() => {
          setOpenStatusDialog(false);
        }}
        handleYes={onToggleStatus}
        title='ePack - Employer Status'
        YesText={'Yes, Change'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to ${
            employerState?.active ? 'deactivate' : 'activate'
          } employer: ${employerState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <Dialog
        open={employersState.showAddEditForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setEditMode(false);
            dispatch(resetEmployer());
            dispatch(showAddEditEmployerForm(false));
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <EmployerForm
            editMode={editMode}
            employer={employerState}
            handleOnCancel={() => {
              setEditMode(false);
              dispatch(resetEmployer());
              dispatch(showAddEditEmployerForm(false));
            }}
            handleOnSubmit={onSubmitAddEdit}
            savingData={employersState.savingData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Employers;
