import {
  SET_LABOR_MACHINE_VIEW_LIST,
  LABOR_MACHINE_VIEW_LIST_LOADING,
  LABOR_MACHINE_VIEW_LIST_RESET,
  LABOR_MACHINE_VIEW_UPDATE_ITEM,
  LABOR_MACHINE_VIEW_REMOVE_ITEM,
} from './types';
const initialState = {
  list: [],
  loading: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LABOR_MACHINE_VIEW_LIST:
      return {
        ...state,
        list: [...action.payload],
      };
    case LABOR_MACHINE_VIEW_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LABOR_MACHINE_VIEW_LIST_RESET:
      return {
        ...state,
        list: [],
      };
    case LABOR_MACHINE_VIEW_UPDATE_ITEM:
      return {
        ...state,
        list: [
          ...state.list.map((item) => {
            if (item.id === action.payload.job.machine.id) {
              return {
                ...item,
                docs: item.docs.map((row) => {
                  if (row.gateLogId === action.payload.gateLog.id) {
                    return {
                      ...row,
                      id: action.payload.id,
                      isIdle: action.payload.isIdle,
                      jobId: action.payload.job.id,
                      employeePosition: action.payload.employeePosition,
                      machine: action.payload.job.machine,
                      jobType: action.payload.job.jobType,
                    };
                  }
                  return row;
                }),
              };
            }
            return item;
          }),
        ],
      };
    case LABOR_MACHINE_VIEW_REMOVE_ITEM:
      return {
        ...state,
        list: [
          ...state.list.map((item) => {
            if (item.id === action.payload.machineId) {
              return {
                ...item,
                docs: item.docs.filter(
                  (item) => item.id !== action.payload.laborLogId
                ),
              };
            }
            return item;
          }),
        ],
      };
    default:
      return state;
  }
}
