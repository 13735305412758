import React from 'react';

let width = 0;
let height = 0;

const EmployeeStatusRing = ({
  children,
  color = '#2981BE',
  size = 'medium',
}) => {
  if (size === 'xs') {
    width = '46px';
    height = '46px';
  } else if (size === 'small') {
    width = '77px';
    height = '77px';
  } else if (size === 'medium') {
    width = '112px';
    height = '112px';
  } else if (size === 'large') {
    width = '155px';
    height = '155px';
  }

  return (
    <div
      style={{
        position: 'relative',
        borderRadius: '999em',
        width: width,
        height: height,
        padding: '3px',
        lineHeight: '0',
        border: `3px solid ${color}`,
        backgroundColor: '#eee',
      }}
    >
      {children}
    </div>
  );
};

export default EmployeeStatusRing;
