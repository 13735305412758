import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

const getGateInList = createSelector(
  (state) => state.gatein.list.docs,
  (gateInList) => {
    if (isEmpty(gateInList)) return [];
    return gateInList.sort((a, b) => (a.lastName < b.lastName ? -1 : 1));
  }
);

export const selectLastnameUniqueInitials = createSelector(
  getGateInList,
  (gateInList) => {
    if (isEmpty(gateInList)) return [];
    return [...new Set(gateInList.map((item) => item.lastName[0]))];
  }
);

export const selectIdsForSelectedEmployees = createSelector(
  getGateInList,
  (gateInList) => {
    if (isEmpty(gateInList)) return [];
    return [
      ...new Set(
        gateInList
          .filter((item) => item.selected === true)
          .map((item) => item.id)
      ),
    ];
  }
);

const getNameFilter = (state) => state.gatein.filter.name;

export const selectFilteredGateInList = createSelector(
  getGateInList,
  getNameFilter,
  (gateInList, nameFilter) => {
    if (isEmpty(gateInList)) return [];
    if (isEmpty(nameFilter)) return gateInList;
    return gateInList.filter((employee) =>
      (employee.firstName + employee.lastName)
        .toLowerCase()
        .includes(nameFilter.toLowerCase())
    );
  }
);
