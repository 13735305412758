import React from 'react';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { Box, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DifferenceIcon from '@mui/icons-material/Difference';
import PreviewIcon from '@mui/icons-material/Preview';
import { styled } from '@mui/system';

const DataGrid = styled(MuiDataGrid)({
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
});

const ManualProfilesTable = ({
  rows,
  handleClickEdit,
  handleClickRemove,
  handleClickDuplicate,
  handleClickPreview,
}) => {
  const columns = [
    {
      field: 'commodity',
      headerName: 'Commodity',
      width: 150,
      editable: false,
      valueGetter: (params) => {
        return params.row.commodity.name;
      },
    },
    {
      field: 'itemDescription',
      headerName: 'Item Description',
      width: 200,
      editable: false,
    },
    {
      field: 'metricsManufacturingAvailability',
      headerName: 'Availability',
      width: 100,
      editable: false,
      valueGetter: (params) => {
        return `${params.row.metricsManufacturingAvailability * 100}%`;
      },
    },
    {
      field: 'metricsThroughput',
      headerName: 'Throughput',
      width: 100,
      editable: false,
      valueGetter: (params) => {
        return `${parseFloat(params.row.metricsThroughput).toFixed(1)}`;
      },
    },
    {
      field: 'metricsAllowance',
      headerName: 'Allowance',
      width: 100,
      editable: false,
      valueGetter: (params) => {
        return `${params.row.metricsAllowance * 100}%`;
      },
    },
    {
      field: 'metricsYield',
      headerName: 'Yield',
      width: 80,
      editable: false,
      valueGetter: (params) => {
        return `${params.row.metricsYield * 100}%`;
      },
    },
    {
      field: 'metricsManufacturingCapacity',
      headerName: 'Capacity',
      width: 90,
      editable: false,
      valueGetter: (params) => {
        return `${parseFloat(params.row.metricsManufacturingCapacity).toFixed(
          0
        )}`;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 160,
      type: 'actions',
      hideable: false,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <Box>
            <Tooltip title='Preview'>
              <IconButton
                onClick={() => handleClickPreview(cellValues.row)}
                color='primary'
              >
                <PreviewIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Duplicate'>
              <IconButton
                onClick={() => handleClickDuplicate(cellValues.row)}
                color='primary'
              >
                <DifferenceIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => {
                  handleClickEdit(cellValues.row);
                }}
                color='primary'
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Remove'>
              <IconButton
                onClick={() => handleClickRemove(cellValues.row)}
                sx={{ color: 'error.main' }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <Box my={1} sx={{ height: '550px', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default ManualProfilesTable;
