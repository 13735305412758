import {
  SET_EMPLOYEE_POSITION_LIST,
  EMPLOYEE_POSITION_LIST_LOADING,
  EMPLOYEE_POSITION_LIST_RESET,
} from './types';

export const setEmployeePositionList = (list) => {
  return {
    type: SET_EMPLOYEE_POSITION_LIST,
    payload: list,
  };
};

export const resetEmployeePositionList = () => {
  return {
    type: EMPLOYEE_POSITION_LIST_RESET,
  };
};

export const setEmployeePositionListLoading = (payload) => {
  return {
    type: EMPLOYEE_POSITION_LIST_LOADING,
    payload,
  };
};
