import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const WeightIcon = (props) => {
  return (
    <SvgIcon
      width='20'
      height='20'
      viewBox='0 0 215.926 286.559'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='scale'>
        <path
          id='path237'
          d='m215.83 254.04l-37.05-172.11c-0.495-2.297-2.842-4.178-5.216-4.178h-53.952v-13.05c42.559-4.19 76.195-31.008 80.922-64.702h-185.14c4.725 33.694 38.362 60.512 80.917 64.702v13.051h-53.95c-2.376 0-4.723 1.881-5.219 4.178l-37.044 172.11c-0.497 2.297 1.041 4.172 3.421 4.172h208.9c2.37 0.01 3.91-1.87 3.41-4.17z'
        />
        <g id='g239'>
          <path
            id='path241'
            d='m174 165.71c0 36.047-29.566 65.27-66.04 65.27-36.471 0-66.04-29.223-66.04-65.27 0-36.056 29.569-65.279 66.04-65.279 36.48 0 66.04 29.22 66.04 65.28z'
            fill='#FFFFFF'
          />
          <path
            id='path243'
            d='m156.59 138.35c-0.953-1.632-3.065-2.195-4.717-1.25l-31.141 17.767c-3.086-3.553-7.66-5.809-12.774-5.809-9.299 0-16.839 7.449-16.839 16.648 0 9.189 7.541 16.643 16.839 16.643 9.301 0 16.839-7.453 16.839-16.643 0-1.691-0.255-3.322-0.729-4.857l31.257-17.839c1.65-0.94 2.22-3.03 1.26-4.66z'
          />
        </g>
        <path
          id='path245'
          d='m215.93 280.59c0 3.281-2.686 5.967-5.968 5.967h-203.99c-3.282 0-5.968-2.686-5.968-5.967v-9.408c0-3.283 2.686-5.969 5.968-5.969h203.99c3.282 0 5.968 2.686 5.968 5.969v9.41z'
        />
      </g>
    </SvgIcon>
  );
};

export default WeightIcon;
