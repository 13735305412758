import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';
import isEmpty from 'is-empty';

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Please provide a valid email'),
});

const ContactForm = ({
  contact,
  editMode,
  handleOnSubmit,
  handleOnCancel,
  savingData,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box display='flex' my={1.5} flexGrow={1} alignItems='center'>
              <Typography
                color='primary'
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'Contact'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='firstName'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(contact?.firstName)
                      ? contact.firstName
                      : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='First Name'
                      fullWidth
                      error={!!errors?.firstName}
                      helperText={errors?.firstName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='lastName'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(contact?.lastName)
                      ? contact.lastName
                      : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Last Name'
                      fullWidth
                      error={!!errors?.lastName}
                      helperText={errors?.lastName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name='position'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(contact?.position)
                      ? contact.position
                      : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Position'
                      fullWidth
                      error={!!errors?.position}
                      helperText={errors?.position?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name='email'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(contact?.email) ? contact.email : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Email'
                      fullWidth
                      error={!!errors?.email}
                      helperText={errors?.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name='phone'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(contact?.phone) ? contact.phone : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Phone'
                      fullWidth
                      error={!!errors?.phone}
                      helperText={errors?.phone?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name='isMainContact'
                  control={control}
                  defaultValue={
                    editMode && !isEmpty(contact?.isMainContact)
                      ? !!contact.isMainContact
                      : false
                  }
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                        />
                      }
                      label='Is Main Contact?'
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box component='div' mx={1} my={0.5} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
                startIcon={<SaveIcon />}
              >
                {savingData ? 'Saving...' : 'Save'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UndoIcon />}
                onClick={() => {
                  reset();
                  handleOnCancel();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default ContactForm;
