import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getUserList = createSelector(
  (state) => state.users.list.docs,
  (userList) => {
    if (isEmpty(userList)) return [];
    return userList.sort((a, b) => (a.firstName < b.firstName ? -1 : 1));
  }
);
