import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getEmployerList = createSelector(
  (state) => state.list_employer.list,
  (employerList) => {
    if (isEmpty(employerList)) return [];
    return employerList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
