import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import MainPage from '../pages/MainPage';
import Layout from '../components/layout/Layout';

//auth
import Login from '../pages/auth/Login';
import ChangePassword from '../pages/auth/ChangePassword';
import ForgotPassword from '../pages/auth/ForgotPassword';
import UserSettings from '../pages/auth/UserSettings';
import ResetPassword from '../pages/auth/ResetPassword';
import ActivateUser from '../pages/auth/ActivateUser';
import ActivateCompany from '../pages/auth/ActivateCompany';

//registration
import Register from '../pages/registration/Register';

//ePack
import WorkOrdersPallets from '../pages/epack/WorkOrdersPallets';
import ProductionPanel from '../pages/epack/ProductionPanel';
import ProductionClipboard from '../pages/epack/ProductionClipboard';
import Lines from '../pages/epack/Lines';
import Machines from '../pages/epack/Machines';
import ProductionLine from '../pages/epack/ProductionLine';
import WIPDashboard from '../pages/epack/WIPDashboard';

//eTime
import Employees from '../pages/etime/Employees';
import GateIn from '../pages/etime/GateIn';
import GateOut from '../pages/etime/GateOut';
import LaborClipboard from '../pages/etime/LaborClipboard';
import Unallocated from '../pages/etime/Unallocated';
import LaborWorkOrderView from '../pages/etime/LaborWorkOrderView';
import LaborJobView from '../pages/etime/LaborJobView';
import LaborMachineView from '../pages/etime/LaborMachineView';
import LaborSummary from '../pages/etime/LaborSummary';
import AdjustLaborLogs from '../pages/etime/AdjustLaborLogs';

//ePlan
import MachineProfiles from '../pages/eplan/MachineProfiles';
import CreateMachineProfile from '../pages/eplan/CreateMachineProfile';
import UpdateMachineProfile from '../pages/eplan/UpdateMachineProfile';
import MORProfiles from '../pages/eplan/MORProfiles';
import CreateMORProfile from '../pages/eplan/CreateMORProfile';
import UpdateMORProfile from '../pages/eplan/UpdateMORProfile';
import ManualProfiles from '../pages/eplan/ManualProfiles';
import UpdateManualProfile from '../pages/eplan/UpdateManualProfile';

//eProfit
import ProfitProfiles from '../pages/eprofit/ProfitProfiles';
import CreateProfitProfile from '../pages/eprofit/CreateProfitProfile';
import UpdateProfitProfile from '../pages/eprofit/UpdateProfitProfile';

//Company
import CompanyProfile from '../pages/company/CompanyProfile';
import Main from '../pages/company/Main';
import Users from '../pages/company/Users';
import Contacts from '../pages/company/Contacts';
import Employers from '../pages/company/Employers';
import Customers from '../pages/company/Customers';
import Commodities from '../pages/company/Commodities';
import EmployeePositions from '../pages/company/EmployeePositions';
import JobTypes from '../pages/company/JobTypes';
import MachineTypes from '../pages/company/MachineTypes';
import PalletTypes from '../pages/company/PalletTypes';
import SetupChecklistItems from '../pages/company/SetupChecklistItems';

//Dashboards
import DashboardLayout from '../components/layout/DashboardLayout';
import MainDashboard from '../pages/MainDashboard';
import ProductionDashboard from '../pages/epack/ProductionDashboard';
import LaborDashboard from '../pages/etime/LaborDashboard';

//General
import Companies from '../pages/general/Companies';
import AuthLayout from '../components/layout/AuthLayout';

const MainRouter = () => {
  return (
    <Routes>
      <Route path='/health' element={<h1>The App is Healthy</h1>} />
      <Route path='/auth' element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
        <Route path='reset-password' element={<ResetPassword />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='activate-user' element={<ActivateUser />} />
        <Route path='activate-company' element={<ActivateCompany />} />
      </Route>

      <Route path='/dashboard' element={<DashboardLayout />}>
        <Route path='main'>
          <Route index element={<MainDashboard />} />
          <Route path=':token' element={<MainDashboard />} />
        </Route>
        <Route path='production'>
          <Route index element={<ProductionDashboard />} />
          <Route path=':token' element={<ProductionDashboard />} />
        </Route>
        <Route path='labor'>
          <Route index element={<LaborDashboard />} />
          <Route path=':token' element={<LaborDashboard />} />
        </Route>
        <Route path='wip/:id' element={<WIPDashboard />} />
      </Route>
      <Route path='/' element={<Layout />}>
        <Route index element={<MainPage />} />
        <Route
          path='epack/workorders_pallets'
          element={<WorkOrdersPallets />}
        />
        <Route path='epack/panel' element={<ProductionPanel />} />
        <Route
          path='epack/production_clipboard/:id'
          element={<ProductionClipboard />}
        />

        <Route path='epack/lines' element={<Lines />} />
        <Route path='epack/line/:id' element={<ProductionLine />} />
        <Route path='epack/machines' element={<Machines />} />
        <Route path='etime/employees' element={<Employees />} />
        <Route path='etime/gatein' element={<GateIn />} />
        <Route path='etime/gateout' element={<GateOut />} />
        <Route path='etime/adjust_logs' element={<AdjustLaborLogs />} />
        <Route path='etime/labor' element={<LaborClipboard />}>
          <Route path='unallocated' element={<Unallocated />} />
          <Route path='workorder' element={<LaborWorkOrderView />} />
          <Route path='job' element={<LaborJobView />} />
          <Route path='machine' element={<LaborMachineView />} />
          <Route path='summary' element={<LaborSummary />} />
        </Route>
        <Route path='eplan/machine_profiles' element={<MachineProfiles />} />
        <Route
          path='eplan/machine_profiles/create'
          element={<CreateMachineProfile />}
        />
        <Route
          path='eplan/machine_profiles/update/:id'
          element={<UpdateMachineProfile />}
        />
        <Route path='eplan/mor_profiles' element={<MORProfiles />} />
        <Route
          path='eplan/mor_profiles/create'
          element={<CreateMORProfile />}
        />
        <Route
          path='eplan/mor_profiles/update/:id'
          element={<UpdateMORProfile />}
        />
        <Route path='eprofit/profit_profiles' element={<ProfitProfiles />} />
        <Route
          path='eprofit/profit_profiles/create'
          element={<CreateProfitProfile />}
        />
        <Route
          path='eprofit/profit_profiles/update/:id'
          element={<UpdateProfitProfile />}
        />
        <Route path='eplan/manual_profiles' element={<ManualProfiles />} />
        <Route
          path='eplan/manual_profiles/update/:id'
          element={<UpdateManualProfile />}
        />
        <Route path='eprofit/profit_profiles' element={<ProfitProfiles />} />
        <Route path='company/profile' element={<CompanyProfile />}>
          <Route path='main' element={<Main />} />
          <Route path='users' element={<Users />} />
          <Route path='contacts' element={<Contacts />} />
          <Route path='employers' element={<Employers />} />
          <Route path='customers' element={<Customers />} />
          <Route path='commodities' element={<Commodities />} />
          <Route path='employee_positions' element={<EmployeePositions />} />
          <Route path='job_types' element={<JobTypes />} />
          <Route path='machine_types' element={<MachineTypes />} />
          <Route path='pallet_types' element={<PalletTypes />} />
          <Route
            path='setup_checklist_items'
            element={<SetupChecklistItems />}
          />
        </Route>
        <Route path='companies' element={<Companies />} />
        <Route path='change_password' element={<ChangePassword />} />
        <Route path='user_settings' element={<UserSettings />} />
      </Route>
      <Route path='*' element={<NoMatch />} />
    </Routes>
  );
};

export default MainRouter;

function NoMatch() {
  return (
    <div>
      <h2>It looks like you're lost...</h2>
      <p>
        <Link to='/'>Go to the home page</Link>
      </p>
    </div>
  );
}
