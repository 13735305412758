import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setManualProfileList,
  resetManualProfileList,
  setManualProfileListLoading,
  deleteManualProfileFromList,
  savingManualProfileData,
  resetManualProfileFilters,
  setManualProfile,
  resetManualProfile,
  setManualProfileLoading,
  addManualProfileToList,
  showDuplicateManualProfileForm,
} from './actions';

export const loadManualProfiles = () => (dispatch, getState, api) => {
  dispatch(setManualProfileListLoading(true));
  axios
    .get(`${api}/profile-manual?limit=1000000`)
    .then((res) => {
      dispatch(resetManualProfileFilters());
      dispatch(resetManualProfileList());
      dispatch(setManualProfileList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setManualProfileListLoading(false));
    });
};

export const loadManualProfile = (id) => (dispatch, getState, api) => {
  dispatch(setManualProfileLoading(true));
  axios
    .get(`${api}/profile-manual/by-id/${id}`)
    .then((res) => {
      dispatch(resetManualProfile());
      dispatch(setManualProfile(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setManualProfileLoading(false));
    });
};

export const createManualProfile =
  (data, navigate) => (dispatch, getState, api) => {
    dispatch(savingManualProfileData(true));
    axios
      .post(`${api}/profile-manual`, data)
      .then((res) => {
        navigate('/eplan/manual_profiles');
        const notification = {
          message: '👍🏻 Manual Profile created successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(savingManualProfileData(false));
      });
  };

export const updateManualProfile =
  (id, data, navigate) => (dispatch, getState, api) => {
    dispatch(savingManualProfileData(true));
    axios
      .put(`${api}/profile-manual/${id}`, data)
      .then((res) => {
        navigate('/eplan/manual_profiles');
        const notification = {
          message: '👍🏻 Manual Profile updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(savingManualProfileData(false));
      });
  };

export const deleteManualProfile = (id) => (dispatch, getState, api) => {
  dispatch(setManualProfileListLoading(true));
  axios
    .delete(`${api}/profile-manual/${id}`)
    .then((res) => {
      dispatch(deleteManualProfileFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Manual Profile deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setManualProfileListLoading(false));
};

export const duplicateManualProfile = (id, data) => (dispatch, getState, api) => {
  dispatch(savingManualProfileData(true));
  axios
    .post(`${api}/profile-manual/duplicate/${id}`, data)
    .then((res) => {
      dispatch(addManualProfileToList(res.data));
      dispatch(showDuplicateManualProfileForm(false));
      const notification = {
        message: '👍🏻 Manual Profile duplicated successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      let notification;
      if (err.response.status === 409) {
        notification = {
          message: err.response.data.message,
          options: {
            variant: 'error',
          },
        };
      } else {
        notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
      }
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(savingManualProfileData(false));
    });
};
