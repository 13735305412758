import {
  SET_COMPANY,
  COMPANY_LOADING,
  COMPANY_RESET,
  SET_COMPANIES,
  COMPANY_LIST_LOADING,
  COMPANY_LIST_RESET,
  ADD_COMPANY_TO_LIST,
  UPDATE_COMPANY_IN_LIST,
  DELETE_COMPANY_FROM_LIST,
  SHOW_ADD_EDIT_COMPANY_FORM,
  SAVING_COMPANY_DATA,
  SET_PRIVATE_TOKEN,
  PRIVATE_TOKEN_LOADING,
  SET_COMPANY_NAME_FILTER,
  SET_COMPANY_IS_ACTIVE_FILTER,
  SET_COMPANY_NEED_APPROVAL_FILTER,
} from './types';
const initialState = {
  item: {},
  list: {},
  loading: false,
  showAddEditForm: false,
  savingData: false,
  privateToken: {
    loading: false,
    token: '',
  },
  filters: {
    name: '',
    isActive: false,
    needsApproval: false,
  },
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_COMPANY:
      return {
        ...state,
        item: { ...action.payload },
      };
    case COMPANY_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case COMPANY_RESET:
      return {
        ...state,
        item: {},
      };
    case SET_COMPANIES:
      return {
        ...state,
        list: { ...action.payload },
      };
    case COMPANY_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case COMPANY_LIST_RESET:
      return {
        ...state,
        list: {},
        filters: {
          name: '',
          isActive: false,
          needsApproval: false,
        },
      };
    case ADD_COMPANY_TO_LIST:
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          docs: [action.payload, ...state.list.docs],
          totalDocs: state.list.totalDocs + 1,
        },
      };
    case DELETE_COMPANY_FROM_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          docs: state.list.docs.filter((item) => item.id !== action.payload),
          totalDocs: state.list.totalDocs - 1,
        },
      };
    case SHOW_ADD_EDIT_COMPANY_FORM:
      return {
        ...state,
        showAddEditForm: action.payload,
      };
    case SAVING_COMPANY_DATA:
      return {
        ...state,
        savingData: action.payload,
      };
    case UPDATE_COMPANY_IN_LIST:
      return {
        ...state,
        loading: false,
        list: {
          docs: state.list.docs.map((row) => {
            if (row.id === action.payload.id) {
              return action.payload;
            }
            return row;
          }),
        },
      };
    case SET_PRIVATE_TOKEN:
      return {
        ...state,
        item: { ...state.item, privateToken: action.payload },
      };
    case PRIVATE_TOKEN_LOADING:
      return {
        ...state,
        privateToken: { ...state.privateToken, loading: action.payload },
      };
    case SET_COMPANY_NAME_FILTER:
      return {
        ...state,
        filters: { ...state.filters, name: action.payload },
      };
    case SET_COMPANY_IS_ACTIVE_FILTER:
      return {
        ...state,
        filters: { ...state.filters, isActive: action.payload },
      };
    case SET_COMPANY_NEED_APPROVAL_FILTER:
      return {
        ...state,
        filters: { ...state.filters, needsApproval: action.payload },
      };
    default:
      return state;
  }
}
