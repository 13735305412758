import React from 'react';
import {
  Box,
  Divider,
  Typography,
  ListItemIcon,
  ListItemText,
  IconButton,
  Card,
  CardContent,
  Collapse,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import MachineIcon from '../UI/icons/MachineIcon';
import PalletIcon from '../UI/icons/PalletIcon';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import grey from '@mui/material/colors/grey';
import isEmpty from 'is-empty';
import ProductionLineTileList from './ProductionLineTileList';

const ProductionMachineViewTileList = ({
  machines,
  expandedMachineTiles,
  setExpandedMachineTiles,
  handleClicAddProductionLine,
  handleClickCloseByMachine,
  onStartAllByMachine,
  onStopAllByMachine,
  handleClickUnavailableAllByMachine,
  onStartProductionLine,
  onStopProductionLine,
  handleClickCloseProductionLine,
  handleClickUnavailableProductionLine,
  handleClickSetupChecklist,
  handleClickSanitation,
  handleClickClipboard,
  handleClickWIPDashboard,
}) => {
  const handleExpandClick = (id) => {
    if (!expandedMachineTiles.includes(id)) {
      setExpandedMachineTiles([...expandedMachineTiles, id]);
    } else {
      setExpandedMachineTiles(expandedMachineTiles.filter((i) => i !== id));
    }
  };

  return (
    <>
      {machines.map((machine) => (
        <Box my={0.5} key={machine.id}>
          <Card variant='outlined'>
            <CardContent
              sx={{
                padding: 0.3,
                '&:last-child': {
                  paddingBottom: 0.8,
                },
              }}
            >
              <Box display='flex'>
                <Box display='flex' flexGrow={1} mt={0.5}>
                  <Box
                    display='flex'
                    alignItems='center'
                    sx={{ width: '40%' }}
                    ml={1}
                  >
                    <MachineIcon
                      sx={{
                        color: grey['600'],
                        width: '18px',
                        height: '18px',
                      }}
                    />
                    <Typography
                      component='span'
                      sx={{
                        marginLeft: '0.5rem',
                        fontWeight: 'bold',
                        color: grey['800'],
                      }}
                    >
                      {` ${machine.machine}`}
                    </Typography>
                  </Box>
                  <Box
                    display='flex'
                    alignItems='center'
                    sx={{
                      width: '10%',
                    }}
                  >
                    <PalletIcon
                      sx={{
                        color: grey['600'],
                        width: '18px',
                        height: '18px',
                      }}
                    />
                    <Typography
                      component='span'
                      sx={{
                        marginLeft: '0.5rem',
                        fontWeight: 'bold',
                        color: grey['800'],
                      }}
                    >
                      {` ${machine.totalPallets}`}
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex'>
                  <Tooltip title='New Production Line'>
                    <IconButton
                      onClick={() => handleClicAddProductionLine(machine)}
                      sx={{
                        color: 'success.main',
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Close Work Order'>
                    <IconButton
                      onClick={() => handleClickCloseByMachine(machine)}
                      sx={{
                        color: 'error.main',
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                  <ThreeDotsMenu
                    data={machine}
                    onStartAllByMachine={onStartAllByMachine}
                    onStopAllByMachine={onStopAllByMachine}
                    handleClickUnavailableAllByMachine={
                      handleClickUnavailableAllByMachine
                    }
                  />
                  <Tooltip title='Expand/Contract'>
                    <IconButton
                      sx={[
                        expandedMachineTiles.includes(machine.id)
                          ? {
                              transform: 'rotate(180deg)',
                            }
                          : {
                              transform: 'rotate(0deg)',
                              marginLeft: 'auto',
                              transition: (theme) =>
                                theme.transitions.create('transform', {
                                  duration: theme.transitions.duration.shortest,
                                }),
                            },
                      ]}
                      onClick={() => handleExpandClick(machine.id)}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </CardContent>
            <Divider variant='fullWidth' />
            <Collapse
              in={expandedMachineTiles.includes(machine.id)}
              timeout='auto'
              unmountOnExit
            >
              <CardContent
                sx={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  backgroundColor: '#f5f5f5',
                  '&:last-child': {
                    paddingBottom: 0,
                  },
                }}
              >
                {!isEmpty(machine.lines) && (
                  <ProductionLineTileList
                    machineView
                    productionLines={machine.lines}
                    onStartProductionLine={onStartProductionLine}
                    onStopProductionLine={onStopProductionLine}
                    handleClickCloseProductionLine={
                      handleClickCloseProductionLine
                    }
                    handleClickUnavailableProductionLine={
                      handleClickUnavailableProductionLine
                    }
                    handleClickSetupChecklist={handleClickSetupChecklist}
                    handleClickSanitation={handleClickSanitation}
                    handleClickClipboard={handleClickClipboard}
                    handleClickWIPDashboard={handleClickWIPDashboard}
                  />
                )}
              </CardContent>
            </Collapse>
          </Card>
        </Box>
      ))}
    </>
  );
};

const ThreeDotsMenu = ({
  data,
  onStartAllByMachine,
  onStopAllByMachine,
  handleClickUnavailableAllByMachine,
}) => {
  const [anchorElWO, setAnchorElWO] = React.useState(null);

  const openWOMenu = Boolean(anchorElWO);

  const handleWOMoreMenuClick = (event) => {
    setAnchorElWO(event.currentTarget);
  };

  const handleCloseWOMenu = () => {
    setAnchorElWO(null);
  };
  return (
    <React.Fragment>
      <Tooltip title='More Options'>
        <IconButton onClick={handleWOMoreMenuClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id={`more-menu`}
        anchorEl={anchorElWO}
        keepMounted
        open={openWOMenu}
        onClose={handleCloseWOMenu}
      >
        <MenuItem
          onClick={() => {
            onStartAllByMachine(data.id);
            handleCloseWOMenu();
          }}
        >
          <ListItemIcon>
            <PlayArrowIcon
              sx={{
                color: 'success.main',
              }}
            />
          </ListItemIcon>
          <ListItemText>Start All</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            onStopAllByMachine(data.id);
            handleCloseWOMenu();
          }}
        >
          <ListItemIcon>
            <StopIcon
              sx={{
                color: 'error.main',
              }}
            />
          </ListItemIcon>
          <ListItemText>Stop All</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClickUnavailableAllByMachine(data);
            handleCloseWOMenu();
          }}
        >
          <ListItemIcon>
            <ReportProblemIcon color='error' />
          </ListItemIcon>
          <ListItemText>Unavailable All</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ProductionMachineViewTileList;
