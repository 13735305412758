import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getManualProfileList = createSelector(
  (state) => state.manual_profile.list.docs,
  (manualProfilesList) => {
    if (isEmpty(manualProfilesList)) return [];
    return manualProfilesList.sort((a, b) =>
      a.commodity.name < b.commodity.name ? -1 : 1
    );
  }
);

export const selectFilteredManualProfiles = createSelector(
  getManualProfileList,
  (state) => state.manual_profile.filters,
  (manualProfilesList, filters) => {
    if (isEmpty(manualProfilesList)) return [];

    if (!isEmpty(filters.commodity)) {
      manualProfilesList = manualProfilesList.filter(
        (item) => item.commodity.id === filters.commodity
      );
    }
    if (!isEmpty(filters.itemDescription)) {
      manualProfilesList = manualProfilesList.filter((item) =>
        item.itemDescription
          .toLowerCase()
          .includes(filters.itemDescription.toLowerCase())
      );
    }
    return manualProfilesList;
  }
);

export const selectExistingManualProfile = createSelector(
  getManualProfileList,
  (state) => state.manual_profile.filters,
  (manualProfilesList, filters) => {
    if (isEmpty(manualProfilesList)) return false;
    return (
      manualProfilesList.filter(
        (item) =>
          item.commodity.id === filters.commodity &&
          item.itemDescription.toLowerCase() ===
            filters.itemDescription.toLowerCase()
      ).length > 0
    );
  }
);
