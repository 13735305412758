import {
  SET_GATE_IN_LIST,
  GATE_IN_LIST_LOADING,
  GATE_IN_LIST_RESET,
  SET_GATE_IN_NAME_FILTER,
  SAVING_GATE_IN_DATA,
  SHOW_GATE_IN_FORM,
  TOGGLE_SELECT_GATE_IN_IN_LIST,
  SET_SELECT_ALL_GATE_IN_IN_LIST,
  DELETE_GATE_IN_FROM_LIST,
} from './types';

export const setGateInList = (list) => {
  return {
    type: SET_GATE_IN_LIST,
    payload: list,
  };
};

export const resetGateInList = () => {
  return {
    type: GATE_IN_LIST_RESET,
  };
};

export const setGateInListLoading = (payload) => {
  return {
    type: GATE_IN_LIST_LOADING,
    payload,
  };
};

export const setGateInNameFilter = (payload) => {
  return {
    type: SET_GATE_IN_NAME_FILTER,
    payload,
  };
};

export const showGateInForm = (payload) => {
  return {
    type: SHOW_GATE_IN_FORM,
    payload,
  };
};

export const savingGateInData = (payload) => {
  return {
    type: SAVING_GATE_IN_DATA,
    payload,
  };
};

export const toggleSelectGateInInList = (payload) => {
  return {
    type: TOGGLE_SELECT_GATE_IN_IN_LIST,
    payload,
  };
};

export const setSelectAllGateInInList = (payload) => {
  return {
    type: SET_SELECT_ALL_GATE_IN_IN_LIST,
    payload,
  };
};

export const deleteGateInFromList = (payload) => {
  return {
    type: DELETE_GATE_IN_FROM_LIST,
    payload,
  };
};
