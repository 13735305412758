import { createSelector } from 'reselect';
import isEmpty from 'is-empty';
import { v4 as uuidv4 } from 'uuid';

export const getWorkOrderList = createSelector(
  (state) => state.work_orders.list.docs,
  (workOrderList) => {
    let newWorkOrderList = [];
    if (isEmpty(workOrderList)) return [];
    const sortedWorkOrderList = workOrderList.sort((a, b) =>
      a.code < b.code ? -1 : 1
    );

    for (let i = 0; i < sortedWorkOrderList.length; i++) {
      if (sortedWorkOrderList[i].pallets.length > 0) {
        for (let y = 0; y < sortedWorkOrderList[i].pallets.length; y++) {
          if (y === 0) {
            newWorkOrderList.push({
              uuid: uuidv4(),
              id: sortedWorkOrderList[i].id,
              umo: sortedWorkOrderList[i].umo,
              code: sortedWorkOrderList[i].code,
              commodity: sortedWorkOrderList[i].commodity,
              readonly: sortedWorkOrderList[i].readonly,
              customer: sortedWorkOrderList[i].customer,
              pallet: {
                id: sortedWorkOrderList[i].pallets[y].id,
                code: sortedWorkOrderList[i].pallets[y].code,
                lot: sortedWorkOrderList[i].pallets[y].lot,
                description: sortedWorkOrderList[i].pallets[y].description,
                gtin: sortedWorkOrderList[i].pallets[y].gtin,
                item: sortedWorkOrderList[i].pallets[y].item,
                cases: sortedWorkOrderList[i].pallets[y].cases,
              },
            });
          } else {
            newWorkOrderList.push({
              uuid: uuidv4(),
              id: sortedWorkOrderList[i].id,
              pallet: {
                id: sortedWorkOrderList[i].pallets[y].id,
                code: sortedWorkOrderList[i].pallets[y].code,
                gtin: sortedWorkOrderList[i].pallets[y].gtin,
                cases: sortedWorkOrderList[i].pallets[y].cases,
                lot:
                  sortedWorkOrderList[i].pallets[y].lot ===
                  sortedWorkOrderList[i].pallets[y - 1].lot
                    ? ''
                    : sortedWorkOrderList[i].pallets[y].lot,
                description:
                  sortedWorkOrderList[i].pallets[y].description ===
                  sortedWorkOrderList[i].pallets[y - 1].description
                    ? ''
                    : sortedWorkOrderList[i].pallets[y].description,
                item:
                  sortedWorkOrderList[i].pallets[y].item ===
                  sortedWorkOrderList[i].pallets[y - 1].item
                    ? ''
                    : sortedWorkOrderList[i].pallets[y].item,
              },
            });
          }
        }
      } else {
        newWorkOrderList.push({ uuid: uuidv4(), ...sortedWorkOrderList[i] });
      }
    }
    return newWorkOrderList;
  }
);
