import axios from 'axios';
import { addNotification } from '../../../system/notifications/actions';
import {
  setJobViewList,
  resetJobViewList,
  setJobViewListLoading,
  updateJobViewItem,
  removeJobViewItem,
} from './actions';

export const loadJobViewList = () => (dispatch, getState, api) => {
  dispatch(setJobViewListLoading(true));
  axios
    .get(`${api}/labor/job-view`)
    .then((res) => {
      dispatch(resetJobViewList());
      dispatch(setJobViewList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setJobViewListLoading(false));
    });
};

export const startByJob = (id) => (dispatch, getState, api) => {
  dispatch(setJobViewListLoading(true));
  axios
    .post(`${api}/labor/start/by-job/${id}`)
    .then((res) => {
      dispatch(loadJobViewList());
      const notification = {
        message: '👍🏻 Work for all employees started',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setJobViewListLoading(false));
    });
};

export const stopByJob = (id) => (dispatch, getState, api) => {
  dispatch(setJobViewListLoading(true));
  axios
    .post(`${api}/labor/stop/by-job/${id}`)
    .then((res) => {
      dispatch(loadJobViewList());
      const notification = {
        message: '👍🏻 Work for all employees stopped',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setJobViewListLoading(false));
    });
};

export const startLaborLog = (id) => (dispatch, getState, api) => {
  dispatch(setJobViewListLoading(true));
  axios
    .post(`${api}/labor/start/by-id/${id}`)
    .then((res) => {
      dispatch(updateJobViewItem(res.data));
      const notification = {
        message: '👍🏻 Work started for this employee',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setJobViewListLoading(false));
    });
};

export const stopLaborLog = (id) => (dispatch, getState, api) => {
  dispatch(setJobViewListLoading(true));
  axios
    .post(`${api}/labor/stop/by-id/${id}`)
    .then((res) => {
      dispatch(updateJobViewItem(res.data));
      const notification = {
        message: '👍🏻 Work stopped for this employee',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setJobViewListLoading(false));
    });
};

export const changePosition = (data) => (dispatch, getState, api) => {
  dispatch(setJobViewListLoading(true));
  axios
    .post(`${api}/labor/change-position`, { ...data })
    .then((res) => {
      dispatch(updateJobViewItem(res.data));
      const notification = {
        message: '👍🏻 Employee position has been changed successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setJobViewListLoading(false));
    });
};

export const moveLaborLog = (data) => (dispatch, getState, api) => {
  dispatch(setJobViewListLoading(true));
  axios
    .post(`${api}/labor/move/${data.laborLogId}`, {
      workOrderId: data.workOrderId,
      machineId: data.machineId,
      jobTypeId: data.jobTypeId,
    })
    .then((res) => {
      dispatch(loadJobViewList());
      const notification = {
        message: '👍🏻 Employee has been moved successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setJobViewListLoading(false));
    });
};

export const moveByJob = (jobId, data) => (dispatch, getState, api) => {
  dispatch(setJobViewListLoading(true));
  axios
    .post(`${api}/labor/move/by-job/${jobId}`, {
      workOrderId: data.workOrderId,
      machineId: data.machineId,
      jobTypeId: data.jobTypeId,
    })
    .then((res) => {
      dispatch(loadJobViewList());
      const notification = {
        message: '👍🏻 Employees have been moved successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setJobViewListLoading(false));
    });
};

export const walkOff = (jobId, data) => (dispatch, getState, api) => {
  dispatch(setJobViewListLoading(true));
  axios
    .post(`${api}/labor/walk-off/`, { ...data })
    .then((res) => {
      dispatch(removeJobViewItem({ jobId: jobId, ...data }));
      const notification = {
        message: '👍🏻 Walk off information saved successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setJobViewListLoading(false));
    });
};
