import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';
import moment from 'moment';

const ValidationSchema = Yup.object().shape({
  hours: Yup.number().typeError('Must be a number').required('Required'),
  minutes: Yup.number().typeError('Must be a number').required('Required'),
});

const AdjustLaborLogForm = ({
  laborLog,
  handleOnSubmit,
  handleOnCancel,
  savingData,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  useEffect(() => {
    setValue('laborLogId', laborLog.id);
    return () => {};
  }, [laborLog, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box display='flex' my={1.5} flexGrow={1} alignItems='center'>
              <Typography
                color='primary'
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'eTime - Adjust Labor Log'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={6}>
                <TextField
                  value={laborLog?.firstName + ' ' + laborLog?.lastName}
                  label='Employee'
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={laborLog?.employer}
                  label='Employer'
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={laborLog?.position}
                  label='Position'
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={laborLog?.job}
                  label='Job'
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={moment(laborLog?.dateStart).format(
                    'MM/DD/YYYY hh:mm A'
                  )}
                  label='Start'
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={moment(laborLog?.dateEnd).format('MM/DD/YYYY hh:mm A')}
                  label='End'
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={`${laborLog?.duration?.hours}h ${laborLog?.duration?.minutes}m`}
                  label='Duration'
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2'>Adjust Hours Below</Typography>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name='hours'
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Hours'
                      type='number'
                      fullWidth
                      error={!!errors?.hours}
                      helperText={errors?.hours?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name='minutes'
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label='Minutes'
                      type='number'
                      fullWidth
                      error={!!errors?.minutes}
                      helperText={errors?.minutes?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box component='div' mx={1} my={0.5} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
                startIcon={<SaveIcon />}
              >
                {savingData ? 'Saving...' : 'Save'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UndoIcon />}
                onClick={() => {
                  reset();
                  handleOnCancel();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default AdjustLaborLogForm;
