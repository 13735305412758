export const SET_MACHINE_TYPE = 'SET_MACHINE_TYPE';
export const MACHINE_TYPE_LOADING = 'MACHINE_TYPE_LOADING';
export const MACHINE_TYPE_RESET = 'MACHINE_TYPE_RESET';
export const SET_MACHINE_TYPES = 'SET_MACHINE_TYPES';
export const MACHINE_TYPE_LIST_LOADING = 'MACHINE_TYPE_LIST_LOADING';
export const MACHINE_TYPE_LIST_RESET = 'MACHINE_TYPE_LIST_RESET';
export const ADD_MACHINE_TYPE_TO_LIST = 'ADD_MACHINE_TYPE_TO_LIST';
export const UPDATE_MACHINE_TYPE_IN_LIST = 'UPDATE_MACHINE_TYPE_IN_LIST';
export const DELETE_MACHINE_TYPE_FROM_LIST = 'DELETE_MACHINE_TYPE_FROM_LIST';
export const SHOW_ADD_EDIT_MACHINE_TYPE_FORM = 'SHOW_ADD_EDIT_MACHINE_TYPE_FORM';
export const SAVING_MACHINE_TYPE_DATA = 'SAVING_MACHINE_TYPE_DATA';
