import {
  SET_PALLET_TYPE,
  PALLET_TYPE_LOADING,
  PALLET_TYPE_RESET,
  SET_PALLET_TYPES,
  PALLET_TYPE_LIST_LOADING,
  PALLET_TYPE_LIST_RESET,
  ADD_PALLET_TYPE_TO_LIST,
  UPDATE_PALLET_TYPE_IN_LIST,
  DELETE_PALLET_TYPE_FROM_LIST,
  SHOW_ADD_EDIT_PALLET_TYPE_FORM,
  SAVING_PALLET_TYPE_DATA,
} from './types';

export const setPalletType = (item) => {
  return {
    type: SET_PALLET_TYPE,
    payload: item,
  };
};

export const resetPalletType = () => {
  return {
    type: PALLET_TYPE_RESET,
  };
};

export const setPalletTypeLoading = (payload) => {
  return {
    type: PALLET_TYPE_LOADING,
    payload,
  };
};

export const setPalletTypeList = (list) => {
  return {
    type: SET_PALLET_TYPES,
    payload: list,
  };
};

export const resetPalletTypeList = () => {
  return {
    type: PALLET_TYPE_LIST_RESET,
  };
};

export const setPalletTypeListLoading = (payload) => {
  return {
    type: PALLET_TYPE_LIST_LOADING,
    payload,
  };
};

export const addPalletTypeToList = (payload) => {
  return {
    type: ADD_PALLET_TYPE_TO_LIST,
    payload,
  };
};

export const deletePalletTypeFromList = (payload) => {
  return {
    type: DELETE_PALLET_TYPE_FROM_LIST,
    payload,
  };
};

export const updatePalletTypeInList = (payload) => {
  return {
    type: UPDATE_PALLET_TYPE_IN_LIST,
    payload,
  };
};

export const showAddEditPalletTypeForm = (payload) => {
  return {
    type: SHOW_ADD_EDIT_PALLET_TYPE_FORM,
    payload,
  };
};

export const savingPalletTypeData = (payload) => {
  return {
    type: SAVING_PALLET_TYPE_DATA,
    payload,
  };
};
