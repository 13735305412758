const defaultSettings = {
  length: 30,
  ending: '...',
};

const truncateString = (str, settings = defaultSettings) => {
  if (str.length > settings.length) {
    return (
      str.substring(0, settings.length - settings.ending.length) +
      settings.ending
    );
  }
  return str;
};

export default truncateString;
