import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ScanAddIcon = (props) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 439.046 439.046"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <g>
          <path
            d="M341.69,172.014c-1.526-0.637-2.969-0.921-4.126-1.157c-0.494-0.101-1.008-0.152-1.475-0.348
			c-1.998-0.831-4.662-1.788-7.533-1.788c-5.661,0-9.932,3.612-11.438,9.668c-1.34,5.414,0.107,9.243,1.572,11.502
			c1.531,2.375,4.648,5.439,10.772,6.548c1.577,0.287,3.188,0.401,4.788,0.506l2.837,0.126l0.812-0.114
			c0.187-0.026,0.383-0.021,0.588-0.021l2.213,0.035c9.549,0,11.598-6.721,12.031-9.603
			C353.433,182.744,352.088,176.329,341.69,172.014z"
          />
          <path
            d="M288.579,30.243h-62.688c-14.911,0-26.997,12.085-26.997,26.997c0,14.916,12.085,26.997,26.997,26.997h62.688
			c14.906,0,26.994-12.085,26.994-26.997C315.582,42.328,303.485,30.243,288.579,30.243z"
          />
          <path
            d="M410.633,84.285c-1.713-1.942-42.326-47.716-71.421-62.611c-20.633-10.566-30.368-14.639-47.366-17.908
			C278.676,1.234,267.055,0,256.301,0c-16.712,0-31.814,2.949-46.157,9.019c-25.646,10.844-37.32,36.202-29.736,64.604
			c5.769,21.62,22.74,32.56,36.368,41.343c12.121,7.817,22.929,12.431,34.009,14.505c9.222,1.725,15.116,6.59,18.033,14.876
			c3.048,8.627,2.101,17.985,1.106,27.891l-0.099,0.966c-0.215,2.185-1.33,6.709-2.511,11.502
			c-4.639,18.811-7.29,31.675-2.222,38.925c2.119,3.039,5.4,4.709,9.218,4.709c2.823,0,5.483-0.896,7.948-1.941
			c1.512,29.422,2.8,58.53,4.158,89.212l1.433,32.142c-0.047,1.018,0.037,1.961,0.112,2.81c0.065,0.682,0.168,1.812,0.111,2.054
			c-13.156,14.36-21.916,25.296-23.723,45.69c-1.67,18.851,6.978,33.622,22.575,38.555c4.593,1.452,9.353,2.185,14.16,2.185
			c2.618,0,5.302-0.22,7.98-0.648c2.521-0.411,4.966-0.98,7.383-1.615l13.46-4.672c6.319-2.73,12.145-5.922,17.423-9.003
			c11.178-6.528,18.757-16.693,23.172-31.073c0.635-2.054,1.302-4.116,1.974-6.197c1.647-5.078,3.342-10.319,4.579-15.626
			c4.62-19.835,5.969-40.076,7.271-59.646c0.443-6.599,0.883-13.198,1.442-19.778c2.096-24.549,2.193-72.422,2.198-74.447
			l-0.019-0.516c-1.055-15.04-0.84-30.48-0.625-45.416c0.121-8.398,0.247-16.799,0.158-25.19l-0.009-0.593
			c-0.02-1.636-0.057-4.368,0.48-4.933c3.159-3.37,5.782-6.977,8.321-10.471c3.253-4.469,6.319-8.683,10.165-11.952
			C414.665,110.273,418.305,93.799,410.633,84.285z M371.499,289.555c-0.574,6.665-1.018,13.357-1.466,20.04
			c-1.273,19.005-2.576,38.648-6.931,57.35c-1.092,4.714-2.697,9.661-4.256,14.459c-0.695,2.146-1.392,4.284-2.035,6.402
			c-3.36,10.931-8.667,18.207-16.708,22.897c-9.979,5.833-20.928,11.509-33.318,13.516c-5.386,0.868-10.576,0.513-15.527-1.046
			c-12.909-4.088-13.124-17.875-12.615-23.615c1.442-16.288,8.2-24.381,20.018-37.271c4.35-4.741,3.836-10.352,3.593-13.039
			c-0.009-0.141-0.027-0.262-0.042-0.374c8.49-28.03,36.039-94.97,71.104-103.487C373.025,260.376,372.493,277.896,371.499,289.555z
			 M397.163,106.33c-5.144,4.373-8.867,9.49-12.461,14.438c-2.39,3.283-4.648,6.38-7.196,9.101
			c-4.48,4.774-4.406,10.877-4.351,14.921v0.562c0.094,8.277-0.027,16.561-0.145,24.838c-0.219,15.145-0.452,30.805,0.635,46.365
			c-0.005,1.213-0.023,6.648-0.107,14.228c-34.135,5.512-59.071,49.42-72.899,81.408c-1.433-32.129-2.781-62.623-4.41-93.62
			c-0.28-5.246-3.977-8.907-8.989-8.907c-2.917,0-5.587,1.239-8.41,2.553c-0.62,0.287-1.414,0.653-2.18,0.984
			c-0.084-6.079,3.108-19.03,4.603-25.071c1.418-5.759,2.538-10.31,2.855-13.507l0.104-0.959
			c1.101-11.035,2.254-22.449-1.849-34.095c-4.648-13.157-14.646-21.529-28.927-24.201c-9.194-1.72-18.369-5.677-28.88-12.454
			c-15.191-9.794-26.254-17.894-30.289-32.989c-4.483-16.781-1.19-38.118,21.464-47.702c12.545-5.304,25.813-7.88,40.58-7.88
			c9.824,0,20.563,1.148,32.828,3.507c15.401,2.963,23.961,6.562,43.539,16.589c26.682,13.663,66.81,58.889,66.996,59.095
			C401.522,95.817,400.374,103.602,397.163,106.33z"
          />
          <rect x="24.198" y="189.53" width="9.558" height="140.489" />
          <rect x="135.312" y="189.53" width="9.558" height="140.489" />
          <rect x="46.899" y="189.53" width="4.779" height="140.489" />
          <rect x="148.851" y="189.53" width="4.779" height="140.489" />
          <rect x="120.177" y="189.53" width="4.779" height="140.489" />
          <rect x="96.683" y="189.53" width="4.779" height="140.489" />
          <rect x="63.625" y="189.53" width="9.554" height="140.489" />
          <rect x="105.442" y="189.53" width="9.554" height="140.489" />
          <rect x="76.371" y="189.53" width="4.775" height="140.489" />
          <rect x="161.597" y="189.53" width="4.779" height="140.489" />
          <rect x="178.324" y="189.53" width="9.553" height="140.489" />
          <rect x="191.069" y="189.53" width="4.774" height="140.489" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default ScanAddIcon;
