import React from 'react';
import {
  Box,
  Divider,
  Typography,
  ListItemIcon,
  ListItemText,
  IconButton,
  Card,
  CardContent,
  Collapse,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CommodityIcon from '../UI/icons/CommodityIcon';
import PalletIcon from '../UI/icons/PalletIcon';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import grey from '@mui/material/colors/grey';
import isEmpty from 'is-empty';
import ProductionLineTileList from './ProductionLineTileList';

const ProductionWorkOrderViewTileList = ({
  workOrders,
  expandedWorkOrderTiles,
  setExpandedWorkOrderTiles,
  handleClicAddProductionLine,
  handleClickCloseByWorkOrder,
  onStartAllByWorkOrder,
  onStopAllByWorkOrder,
  handleClickUnavailableAllByWorkOrder,
  onStartProductionLine,
  onStopProductionLine,
  handleClickCloseProductionLine,
  handleClickUnavailableProductionLine,
  handleClickSetupChecklist,
  handleClickSanitation,
  handleClickClipboard,
  handleClickWIPDashboard,
}) => {
  const handleExpandClick = (id) => {
    if (!expandedWorkOrderTiles.includes(id)) {
      setExpandedWorkOrderTiles([...expandedWorkOrderTiles, id]);
    } else {
      setExpandedWorkOrderTiles(expandedWorkOrderTiles.filter((i) => i !== id));
    }
  };

  return (
    <>
      {workOrders.map((workOrder) => (
        <Box my={0.5} key={workOrder.id}>
          <Card variant='outlined'>
            <CardContent
              sx={{
                padding: 0.3,
                '&:last-child': {
                  paddingBottom: 0.8,
                },
              }}
            >
              <Box display='flex'>
                <Box display='flex' flexGrow={1} mt={0.5}>
                  <Box
                    display='flex'
                    alignItems='center'
                    sx={{ width: '25%' }}
                    ml={1}
                  >
                    <ListAltIcon
                      sx={{
                        color: grey['600'],
                        width: '18px',
                        height: '18px',
                      }}
                    />
                    <Typography
                      component='span'
                      sx={{
                        marginLeft: '0.5rem',
                        fontWeight: 'bold',
                        color: grey['800'],
                      }}
                    >
                      {` ${workOrder.workOrder}`}
                    </Typography>
                  </Box>
                  <Box display='flex' alignItems='center' sx={{ width: '30%' }}>
                    <CommodityIcon
                      sx={{
                        color: grey['600'],
                        width: '18px',
                        height: '18px',
                      }}
                    />
                    <Typography
                      component='span'
                      sx={{
                        marginLeft: '0.5rem',
                        fontWeight: 'bold',
                        color: grey['800'],
                      }}
                    >
                      {` ${workOrder.commodity}`}
                    </Typography>
                  </Box>
                  <Box
                    display='flex'
                    alignItems='center'
                    sx={{
                      width: '10%',
                    }}
                  >
                    <PalletIcon
                      sx={{
                        color: grey['600'],
                        width: '18px',
                        height: '18px',
                      }}
                    />
                    <Typography
                      component='span'
                      sx={{
                        marginLeft: '0.5rem',
                        fontWeight: 'bold',
                        color: grey['800'],
                      }}
                    >
                      {` ${workOrder.totalPallets}`}
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex'>
                  <Tooltip title='New Production Line'>
                    <IconButton
                      onClick={() => handleClicAddProductionLine(workOrder)}
                      sx={{
                        color: 'success.main',
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Close Work Order'>
                    <IconButton
                      onClick={() => handleClickCloseByWorkOrder(workOrder)}
                      sx={{
                        color: 'error.main',
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                  <ThreeDotsMenu
                    data={workOrder}
                    onStartAllByWorkOrder={onStartAllByWorkOrder}
                    onStopAllByWorkOrder={onStopAllByWorkOrder}
                    handleClickUnavailableAllByWorkOrder={
                      handleClickUnavailableAllByWorkOrder
                    }
                  />
                  <Tooltip title='Expand/Contract'>
                    <IconButton
                      sx={[
                        expandedWorkOrderTiles.includes(workOrder.id)
                          ? {
                              transform: 'rotate(180deg)',
                            }
                          : {
                              transform: 'rotate(0deg)',
                              marginLeft: 'auto',
                              transition: (theme) =>
                                theme.transitions.create('transform', {
                                  duration: theme.transitions.duration.shortest,
                                }),
                            },
                      ]}
                      onClick={() => handleExpandClick(workOrder.id)}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </CardContent>
            <Divider variant='fullWidth' />
            <Collapse
              in={expandedWorkOrderTiles.includes(workOrder.id)}
              timeout='auto'
              unmountOnExit
            >
              <CardContent
                sx={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  backgroundColor: '#f5f5f5',
                  '&:last-child': {
                    paddingBottom: 0,
                  },
                }}
              >
                {!isEmpty(workOrder.lines) && (
                  <ProductionLineTileList
                    workOrderView
                    productionLines={workOrder.lines}
                    onStartProductionLine={onStartProductionLine}
                    onStopProductionLine={onStopProductionLine}
                    handleClickCloseProductionLine={
                      handleClickCloseProductionLine
                    }
                    handleClickUnavailableProductionLine={
                      handleClickUnavailableProductionLine
                    }
                    handleClickSetupChecklist={handleClickSetupChecklist}
                    handleClickSanitation={handleClickSanitation}
                    handleClickClipboard={handleClickClipboard}
                    handleClickWIPDashboard={handleClickWIPDashboard}
                  />
                )}
              </CardContent>
            </Collapse>
          </Card>
        </Box>
      ))}
    </>
  );
};

const ThreeDotsMenu = ({
  data,
  onStartAllByWorkOrder,
  onStopAllByWorkOrder,
  handleClickUnavailableAllByWorkOrder,
}) => {
  const [anchorElWO, setAnchorElWO] = React.useState(null);

  const openWOMenu = Boolean(anchorElWO);

  const handleWOMoreMenuClick = (event) => {
    setAnchorElWO(event.currentTarget);
  };

  const handleCloseWOMenu = () => {
    setAnchorElWO(null);
  };
  return (
    <React.Fragment>
      <Tooltip title='More Options'>
        <IconButton onClick={handleWOMoreMenuClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id={`more-menu`}
        anchorEl={anchorElWO}
        keepMounted
        open={openWOMenu}
        onClose={handleCloseWOMenu}
      >
        <MenuItem
          onClick={() => {
            onStartAllByWorkOrder(data.id);
            handleCloseWOMenu();
          }}
        >
          <ListItemIcon>
            <PlayArrowIcon
              sx={{
                color: 'success.main',
              }}
            />
          </ListItemIcon>
          <ListItemText>Start All</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            onStopAllByWorkOrder(data.id);
            handleCloseWOMenu();
          }}
        >
          <ListItemIcon>
            <StopIcon
              sx={{
                color: 'error.main',
              }}
            />
          </ListItemIcon>
          <ListItemText>Stop All</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClickUnavailableAllByWorkOrder(data);
            handleCloseWOMenu();
          }}
        >
          <ListItemIcon>
            <ReportProblemIcon color='error' />
          </ListItemIcon>
          <ListItemText>Unavailable All</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ProductionWorkOrderViewTileList;
