import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  loadProfitProfile,
  updateProfitProfile,
} from '../../redux/eprofit/profile-profit/services';
import { resetProfitProfile } from '../../redux/eprofit/profile-profit/actions';
import { Box, Paper, Typography, Divider } from '@mui/material';
import Loading from '../../components/UI/Loading';
import ProfitProfileForm from '../../components/eprofit/ProfitProfileForm';
import isEmpty from 'is-empty';

const UpdateProfitProfile = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const profitProfilesState = useSelector((state) => state.profit_profile);
  const profitProfileState = useSelector((state) => state.profit_profile.item);

  const onSubmit = (data) => {
    dispatch(updateProfitProfile(id, data, navigate));
  };

  useEffect(() => {
    if (isEmpty(id)) {
      navigate('/eprofit/profit_profiles');
    } else {
      dispatch(loadProfitProfile(id));
    }
    return () => {
      dispatch(resetProfitProfile());
    };
  }, [id, dispatch, navigate]);

  return (
    <>
      <Loading loading={profitProfilesState.loading} />
      <Paper elevation={0}>
        <Box display='flex' mx={1} mt={1}>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              eProfit - Update Profit Profile
            </Typography>
          </Box>
        </Box>
        <Divider variant='middle' light />
        <Box mx={1} mt={1}>
          {!isEmpty(profitProfileState) && !profitProfilesState.loading ? (
            <ProfitProfileForm
              machineId={profitProfileState.machine.id}
              commodityId={profitProfileState.commodity.id}
              itemDescription={profitProfileState.itemDescription}
              onSubmit={onSubmit}
              savingData={profitProfilesState.savingData}
              editMode={true}
              profitProfile={profitProfileState}
            />
          ) : null}
        </Box>
      </Paper>
    </>
  );
};

export default UpdateProfitProfile;
