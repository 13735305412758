import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCompany,
  resetCompany,
  showAddEditCompanyForm,
  setCompanyNameFilter,
  setCompanyIsActiveFilter,
  setCompanyNeedsApprovalFilter,
} from '../../redux/general/companies/actions';
import {
  loadCompanies,
  createCompany,
  updateCompany,
  deleteCompany,
  toggleCompanyStatus,
  emailRegistration,
  approveRegistration,
} from '../../redux/general/companies/services';
import { getCompanyList } from '../../redux/general/companies/selectors';
import {
  validateCompanyName,
  validateCompanyEmail,
  validateUserEmail,
} from '../../redux/system/registration/services';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Paper,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import Loading from '../../components/UI/Loading';
import CompanyForm from '../../components/company/CompanyForm';
import Empty from '../../components/UI/Empty';
import CompaniesTable from '../../components/company/CompaniesTable';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import CompaniesFilterForm from '../../components/company/CompaniesFilterForm';
import SubscriptionForm from '../../components/company/SubscriptionForm';
import {
  loadSubscriptionDetails,
  updateSubscription,
} from '../../redux/system/subscription/services';
const isEmpty = require('is-empty');

const Companies = () => {
  const dispatch = useDispatch();
  const companiesState = useSelector((state) => state.companies);
  const companyState = useSelector((state) => state.companies.item);
  const registrationState = useSelector((state) => state.registration);
  const subscriptionState = useSelector((state) => state.subscription);
  const CompaniesList = useSelector(getCompanyList);
  const [editMode, setEditMode] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false);
  const [openEmailRegistrationDialog, setOpenEmailRegistrationDialog] =
    useState(false);
  const [openApproveRegistrationDialog, setOpenApproveRegistrationDialog] =
    useState(false);
  const [openRemoveCompanyDialog, setOpenRemoveCompanyDialog] = useState(false);

  useEffect(() => {
    dispatch(loadCompanies());
    return () => {};
  }, [dispatch]);

  const handleClickAdd = () => {
    setEditMode(false);
    dispatch(showAddEditCompanyForm(true));
  };

  const handleClickEdit = (company) => {
    setEditMode(true);
    dispatch(setCompany(company));
    dispatch(showAddEditCompanyForm(true));
  };

  const onSubmitAddEdit = (data) => {
    if (editMode) {
      dispatch(updateCompany(companyState.id, data));
    } else {
      dispatch(createCompany(data));
    }
  };

  const handleRefresh = () => {
    dispatch(loadCompanies());
  };

  const handleClickRemove = (company) => {
    dispatch(setCompany(company));
    setOpenRemoveCompanyDialog(true);
  };

  const onRemoveCompany = () => {
    dispatch(deleteCompany(companyState.id));
    dispatch(resetCompany());
    setOpenRemoveCompanyDialog(false);
  };

  const handleClickToogleStatus = (company) => {
    dispatch(setCompany(company));
    setOpenStatusDialog(true);
  };

  const onToggleStatus = () => {
    dispatch(toggleCompanyStatus(companyState.id, companyState.active));
    setOpenStatusDialog(false);
  };

  const handleClickEmailRegistration = (company) => {
    dispatch(setCompany(company));
    setOpenEmailRegistrationDialog(true);
  };

  const onEmailRegistration = () => {
    dispatch(emailRegistration(companyState.id));
    setOpenEmailRegistrationDialog(false);
  };

  const handleClickApproveRegistration = (company) => {
    dispatch(setCompany(company));
    setOpenApproveRegistrationDialog(true);
  };

  const onApproveRegistration = () => {
    dispatch(approveRegistration(companyState.id));
    setOpenApproveRegistrationDialog(false);
  };

  const handleOnChangeNameFilter = (name) => {
    dispatch(setCompanyNameFilter(name));
  };

  const handleOnChangeIsActiveFilter = (isActive) => {
    dispatch(setCompanyIsActiveFilter(isActive));
  };

  const handleOnChangeNeedsApprovalFilter = (isActive) => {
    dispatch(setCompanyNeedsApprovalFilter(isActive));
  };

  const handleOnValidateCompanyName = (name) => {
    dispatch(validateCompanyName({ name }));
  };

  const handleOnValidateCompanyEmail = (email) => {
    dispatch(validateCompanyEmail({ email }));
  };

  const handleOnValidateUserEmail = (email) => {
    dispatch(validateUserEmail({ email }));
  };

  const handleClickSubscription = (company) => {
    dispatch(setCompany(company));
    dispatch(loadSubscriptionDetails(company.id));
    setOpenSubscriptionDialog(true);
  };

  const onSubmitUpdateSubscription = (data) => {
    dispatch(updateSubscription(companyState.id, data));
    setOpenSubscriptionDialog(false);
  };

  return (
    <>
      <Loading loading={companiesState.loading || subscriptionState.loading} />
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box display='flex' alignContent='center'>
            <IconButton aria-label='reload' onClick={handleRefresh}>
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              Companies
            </Typography>
          </Box>
          <CompaniesFilterForm
            filters={companiesState.filters}
            handleOnChangeNameFilter={handleOnChangeNameFilter}
            handleOnChangeIsActiveFilter={handleOnChangeIsActiveFilter}
            handleOnChangeNeedsApprovalFilter={
              handleOnChangeNeedsApprovalFilter
            }
          />
          <Tooltip title='Add Company'>
            <Button
              variant='contained'
              color='secondary'
              size='small'
              startIcon={<AddIcon />}
              sx={{ marginLeft: 3, marginRight: 1 }}
              onClick={handleClickAdd}
            >
              Add
            </Button>
          </Tooltip>
        </Box>
        <Box sx={{ width: '100%' }}>
          {!isEmpty(companiesState.list) && (
            <CompaniesTable
              rows={CompaniesList}
              handleClickEdit={handleClickEdit}
              handleClickRemove={handleClickRemove}
              handleClickToogleStatus={handleClickToogleStatus}
              handleClickEmailRegistration={handleClickEmailRegistration}
              handleClickApproveRegistration={handleClickApproveRegistration}
              handleClickSubscription={handleClickSubscription}
            />
          )}
          {isEmpty(companiesState.list) && !companiesState.loading && (
            <Empty description='There are no companies to show' />
          )}
        </Box>
      </Paper>
      <ConfirmationDialog
        open={openRemoveCompanyDialog}
        handleNo={() => {
          setOpenRemoveCompanyDialog(false);
        }}
        handleYes={onRemoveCompany}
        title='ePack - Remove Company'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to remove company: ${companyState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={
          openStatusDialog &&
          !companyState.loading &&
          !subscriptionState.loading
        }
        handleNo={() => {
          setOpenStatusDialog(false);
        }}
        handleYes={onToggleStatus}
        title='ePack - Company Status'
        YesText={'Yes, Change'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to ${
            companyState?.active ? 'deactivate' : 'activate'
          } company: ${companyState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openEmailRegistrationDialog}
        handleNo={() => {
          setOpenEmailRegistrationDialog(false);
        }}
        handleYes={onEmailRegistration}
        title='Company Registration Email'
        YesText={'Yes, Send'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to send registration email to company: ${companyState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openApproveRegistrationDialog}
        handleNo={() => {
          setOpenApproveRegistrationDialog(false);
        }}
        handleYes={onApproveRegistration}
        title='Company Approve Registration'
        YesText={'Yes, Approve'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to approve registration for company: ${companyState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <Dialog
        open={companiesState.showAddEditForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setEditMode(false);
            dispatch(resetCompany());
            dispatch(showAddEditCompanyForm(false));
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <CompanyForm
            editMode={editMode}
            company={companyState}
            handleOnCancel={() => {
              setEditMode(false);
              dispatch(resetCompany());
              dispatch(showAddEditCompanyForm(false));
            }}
            handleOnSubmit={onSubmitAddEdit}
            savingData={companiesState.savingData}
            validating={registrationState.validating}
            companyNameTaken={registrationState.companyNameTaken}
            companyEmailTaken={registrationState.companyEmailTaken}
            userEmailTaken={registrationState.userEmailTaken}
            handleOnValidateCompanyName={handleOnValidateCompanyName}
            handleOnValidateCompanyEmail={handleOnValidateCompanyEmail}
            handleOnValidateUserEmail={handleOnValidateUserEmail}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openSubscriptionDialog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenSubscriptionDialog(false);
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <SubscriptionForm
            company={companyState}
            subscription={subscriptionState.details}
            handleOnCancel={() => {
              setOpenSubscriptionDialog(false);
            }}
            handleOnSubmit={onSubmitUpdateSubscription}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Companies;
