import {
  EMPLOYEE_LOADING,
  SET_EMPLOYEE,
  EMPLOYEE_RESET,
  SET_EMPLOYEES,
  EMPLOYEES_LIST_LOADING,
  EMPLOYEES_LIST_RESET,
  SET_EMPLOYEE_NAME_FILTER,
  ADD_EMPLOYEE_TO_LIST,
  UPDATE_EMPLOYEE_IN_LIST,
  DELETE_EMPLOYEE_FROM_LIST,
  SHOW_ADD_EDIT_EMPLOYEE_FORM,
  SAVING_EMPLOYEE_DATA,
} from './types';
const initialState = {
  view: {
    record: {},
  },
  list: {},
  loading: false,
  filter: {
    name: '',
  },
  showAddEditForm: false,
  savingData: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case EMPLOYEE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_EMPLOYEE:
      return {
        ...state,
        view: {
          record: action.payload,
        },
        loading: false,
      };
    case EMPLOYEE_RESET:
      return {
        ...state,
        view: {
          record: {},
        },
        loading: false,
      };
    case SET_EMPLOYEES:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case EMPLOYEES_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case EMPLOYEES_LIST_RESET:
      return {
        ...state,
        list: {},
        loading: false,
      };
    case SET_EMPLOYEE_NAME_FILTER:
      return {
        ...state,
        filter: {
          name: action.payload,
        },
      };
    case ADD_EMPLOYEE_TO_LIST:
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          docs: [action.payload, ...state.list.docs],
          totalDocs: state.list.totalDocs + 1,
        },
      };
    case DELETE_EMPLOYEE_FROM_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          docs: state.list.docs.filter((item) => item.id !== action.payload),
          totalDocs: state.list.totalDocs - 1,
        },
      };
    case SHOW_ADD_EDIT_EMPLOYEE_FORM:
      return {
        ...state,
        showAddEditForm: action.payload,
      };
    case SAVING_EMPLOYEE_DATA:
      return {
        ...state,
        savingData: action.payload,
      };
    case UPDATE_EMPLOYEE_IN_LIST:
      return {
        ...state,
        loading: false,
        list: {
          docs: state.list.docs.map((row) => {
            if (row.id === action.payload.id) {
              return action.payload;
            }
            return row;
          }),
        },
      };
    default:
      return state;
  }
}
