import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setJobTypeList,
  resetJobTypeList,
  setJobTypeListLoading,
  addJobTypeToList,
  deleteJobTypeFromList,
  updateJobTypeInList,
  savingJobTypeData,
  showAddEditJobTypeForm,
} from './actions';

export const loadJobTypes = () => (dispatch, getState, api) => {
  dispatch(setJobTypeListLoading(true));
  axios
    .get(`${api}/job-types?limit=1000000`)
    .then((res) => {
      dispatch(resetJobTypeList());
      dispatch(setJobTypeList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setJobTypeListLoading(false));
    });
};

export const createJobType = (data) => (dispatch, getState, api) => {
  dispatch(setJobTypeListLoading(true));
  dispatch(savingJobTypeData(true));
  axios
    .post(`${api}/job-types`, data)
    .then((res) => {
      dispatch(addJobTypeToList(res.data));
      dispatch(showAddEditJobTypeForm(false));
      const notification = {
        message: '👍🏻 Job type created successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setJobTypeListLoading(false));
      dispatch(savingJobTypeData(false));
    });
};

export const updateJobType =
  (id, data) => (dispatch, getState, api) => {
    dispatch(setJobTypeListLoading(true));
    dispatch(savingJobTypeData(true));
    axios
      .put(`${api}/job-types/${id}`, data)
      .then((res) => {
        dispatch(updateJobTypeInList(res.data));
        dispatch(showAddEditJobTypeForm(false));
        const notification = {
          message: '👍🏻 Job type updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setJobTypeListLoading(false));
        dispatch(savingJobTypeData(false));
      });
  };

export const deleteJobType = (id) => (dispatch, getState, api) => {
  dispatch(setJobTypeListLoading(true));
  axios
    .delete(`${api}/job-types/${id}`)
    .then((res) => {
      dispatch(deleteJobTypeFromList(id));
      const notification = {
        title: 'Deleted',
        message: '👍🏻  Job type deleted successfully',
        options: {
          variant: 'success',
        },
      };
      dispatch(addNotification(notification));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    });
  dispatch(setJobTypeListLoading(false));
};

export const toggleJobTypeStatus =
  (id, currentStatus) => (dispatch, getState, api) => {
    dispatch(setJobTypeListLoading(true));
    axios
      .post(`${api}/job-types/activate/${id}/${!currentStatus}`)
      .then((res) => {
        console.log(res);
        dispatch(loadJobTypes());
        const notification = {
          message: '👍🏻 Job type status updated successfully',
          options: {
            variant: 'success',
          },
        };
        dispatch(addNotification(notification));
      })
      .catch((err) => {
        console.log(err);
        const notification = {
          message: '😱 Something went wrong, please try again',
          options: {
            variant: 'error',
          },
        };
        dispatch(addNotification(notification));
      })
      .finally(() => {
        dispatch(setJobTypeListLoading(false));
      });
  };
