import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import BadgeIcon from '@mui/icons-material/Badge';
import PanToolIcon from '@mui/icons-material/PanTool';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import EmployeePhoto from './EmployeePhoto';
import truncateString from '../../utils/truncateString';
import isEmpty from 'is-empty';

const EmployeeDetails = ({ employee }) => {
  return (
    <>
      <Paper elevation={0}>
        <Box
          component="div"
          display="flex"
          justifyContent="center"
          alignItems={'center'}
        >
          <Box sx={{ margin: 5 }}>
            <EmployeePhoto photo={employee.photo} size="large" />
          </Box>
          <Box flexGrow={1}>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={2}>
                <BadgeIcon color="primary" />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body1">
                  {truncateString(
                    employee.lastName + ' ' + employee.firstName,
                    {
                      length: 25,
                      ending: '...',
                    }
                  )}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <AccountBalanceIcon color="primary" />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body1">
                  {truncateString(employee.employer.name)}
                </Typography>
              </Grid>
              {!isEmpty(employee.latestPosition) && (
                <>
                  <Grid item xs={2}>
                    <PanToolIcon color="primary" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body1">
                      {truncateString(employee.latestPosition)}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default EmployeeDetails;
