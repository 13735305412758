import React from 'react';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { Box, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { styled } from '@mui/system';

const DataGrid = styled(MuiDataGrid)({
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
});

const EmployeePositionsTable = ({
  rows,
  handleClickEdit,
  handleClickRemove,
  handleClickToogleStatus,
}) => {
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 450,
      hideable: false,
      editable: false,
    },
    {
      field: 'rate',
      headerName: 'Rate',
      width: 150,
      hideable: false,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      type: 'actions',
      hideable: false,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <Box>
            <Tooltip title={cellValues.row.active ? 'Deactivate' : 'Activate'}>
              <IconButton
                onClick={() => handleClickToogleStatus(cellValues.row)}
                sx={
                  cellValues.row.active
                    ? { color: 'error.main' }
                    : { color: 'success.main' }
                }
              >
                <PowerSettingsNewIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => {
                  handleClickEdit(cellValues.row);
                }}
                color='primary'
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Remove'>
              <IconButton
                onClick={() => handleClickRemove(cellValues.row)}
                sx={{ color: 'error.main' }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <Box mx={1} my={1} sx={{ height: '550px', width: '98%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default EmployeePositionsTable;
