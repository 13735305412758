import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

const getUnallocatedList = createSelector(
  (state) => state.labor_unallocated.list.docs,
  (unallocatedList) => {
    if (isEmpty(unallocatedList)) return [];
    return unallocatedList.sort((a, b) => (a.lastName < b.lastName ? -1 : 1));
  }
);

export const selectLastnameUniqueInitials = createSelector(
  getUnallocatedList,
  (unallocatedList) => {
    if (isEmpty(unallocatedList)) return [];
    return [...new Set(unallocatedList.map((item) => item.lastName[0]))];
  }
);

export const selectIdsForSelectedEmployees = createSelector(
  getUnallocatedList,
  (unallocatedList) => {
    if (isEmpty(unallocatedList)) return [];
    return [
      ...new Set(
        unallocatedList
          .filter((item) => item.selected === true)
          .map((item) => item.id)
      ),
    ];
  }
);

export const selectSelectedEmployees = createSelector(
  getUnallocatedList,
  (unallocatedList) => {
    if (isEmpty(unallocatedList)) return [];
    return [
      ...new Set(unallocatedList.filter((item) => item.selected === true)),
    ];
  }
);

const getNameFilter = (state) => state.labor_unallocated.filter.name;

export const selectFilteredUnallocatedList = createSelector(
  getUnallocatedList,
  getNameFilter,
  (unallocatedList, nameFilter) => {
    if (isEmpty(unallocatedList)) {
      return [];
    }
    if (!isEmpty(nameFilter)) return unallocatedList;
    return unallocatedList.filter((employee) =>
      (employee.firstName + employee.lastName)
        .toLowerCase()
        .includes(nameFilter.toLowerCase())
    );
  }
);
