import React from 'react';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { Box, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';

const DataGrid = styled(MuiDataGrid)({
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
});

const MachinesTable = ({
  rows,
  handleClickToogleStatus,
  handleClickEdit,
  handleClickRemove,
}) => {
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      hideable: false,
      editable: false,
    },
    {
      field: 'model',
      headerName: 'Model',
      width: 150,
      hideable: false,
      editable: false,
    },
    {
      field: 'manufacturer',
      headerName: 'Manufacturer',
      width: 150,
      hideable: false,
      editable: false,
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 100,
      editable: false,
      valueGetter: (params) => {
        return params.row.category.name;
      },
    },
    {
      field: 'operators',
      headerName: 'Operators',
      width: 100,
      hideable: false,
      editable: false,
    },
    {
      field: 'commodities',
      headerName: 'Commodities',
      width: 200,
      hideable: false,
      editable: false,
      valueGetter: (params) => {
        if (params.row.commodities.length > 0) {
          return params.row.commodities.map((commodity) => {
            return ` ${commodity.name}`;
          });
        } else {
          return '';
        }
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      type: 'actions',
      hideable: false,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <Box>
            <Tooltip title={cellValues.row.active ? 'Deactivate' : 'Activate'}>
              <IconButton
                onClick={() => handleClickToogleStatus(cellValues.row)}
                sx={
                  cellValues.row.active
                    ? { color: 'error.main' }
                    : { color: 'success.main' }
                }
              >
                <PowerSettingsNewIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => {
                  handleClickEdit(cellValues.row);
                }}
                color='primary'
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Remove'>
              <IconButton
                onClick={() => handleClickRemove(cellValues.row)}
                sx={{ color: 'error.main' }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <Box mx={1} my={1} sx={{ height: '650px', width: '98%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default MachinesTable;
