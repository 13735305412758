import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetProductionDashboardData } from '../redux/epack/dashboard/actions';
import { loadProductionDashboardData } from '../redux/epack/dashboard/services';
import {
  selectTotalNumberOfProductionLines,
  selectNumberOfAvailableProductionLines,
  selectNumberOfUnavailableProductionLines,
  selectNumberOfSanitationProductionLines,
  selectNumberOfInProgressProductionLines,
  selectNumberOfSetupProductionLines,
  selectTotalNumberOfWorkOrders,
  selectNumberOfWorkOrdersInProduction,
  selectNumberOfCommoditiesInWorkOrders,
  selectNumberOfPalletsInWorkOrders,
  selectNumberOfCasesInWorkOrders,
} from '../redux/epack/dashboard/selectors';
import { resetLaborDashboardData } from '../redux/etime/dashboard/actions';
import { loadLaborDashboardData } from '../redux/etime/dashboard/services';
import {
  selectNumberOfActiveEmployees,
  selectNumberOfIdleEmployees,
  selectNumberOfUnallocatedEmployees,
} from '../redux/etime/dashboard/selectors';
import { logoutUser } from '../redux/system/auth/actions';
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import Empty from '../components/UI/Empty';
import Loading from '../components/UI/Loading';
import Welcome from '../components/UI/Welcome';
import DashboardNumericWidgetWithIcon from '../components/UI/DashboardNumericWidgetWithIcon';
import SubscriptionInactiveNotification from '../components/UI/SubscriptionInactiveNotification';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonIcon from '../components/UI/icons/PersonIcon';
import TableViewIcon from '@mui/icons-material/TableView';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ReorderIcon from '@mui/icons-material/Reorder';
import {
  grey,
  green,
  red,
  orange,
  blue,
  teal,
  cyan,
  deepPurple,
} from '@mui/material/colors';
const isEmpty = require('is-empty');

export default function MainPage() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const productionDashboardState = useSelector(
    (state) => state.production_dashboard
  );
  const laborDashboardState = useSelector((state) => state.labor_dashboard);
  const subscriptionState = useSelector((state) => state.subscription);
  const authState = useSelector((state) => state.auth);

  const TotalNumberOfProductionLines = useSelector(
    selectTotalNumberOfProductionLines
  );
  const NumberOfUnavailableProductionLines = useSelector(
    selectNumberOfUnavailableProductionLines
  );
  const NumberOfAvailableProductionLines = useSelector(
    selectNumberOfAvailableProductionLines
  );
  const NumberOfInProgressProductionLines = useSelector(
    selectNumberOfInProgressProductionLines
  );
  const NumberOfSetupProductionLines = useSelector(
    selectNumberOfSetupProductionLines
  );
  const NumberOfSanitationProductionLines = useSelector(
    selectNumberOfSanitationProductionLines
  );
  const TotalNumberOfWorkOrders = useSelector(selectTotalNumberOfWorkOrders);
  const NumberOfWorkOrdersInProduction = useSelector(
    selectNumberOfWorkOrdersInProduction
  );
  const NumberOfCommoditiesInWorkOrders = useSelector(
    selectNumberOfCommoditiesInWorkOrders
  );
  const NumberOfPalletsInWorkOrders = useSelector(
    selectNumberOfPalletsInWorkOrders
  );
  const NumberOfCasesInWorkOrders = useSelector(
    selectNumberOfCasesInWorkOrders
  );

  const NumberOfActiveEmployees = useSelector(selectNumberOfActiveEmployees);
  const NumberOfIdleEmployees = useSelector(selectNumberOfIdleEmployees);
  const NumberOfUnallocatedEmployees = useSelector(
    selectNumberOfUnallocatedEmployees
  );
  const totalEmployees =
    NumberOfActiveEmployees +
    NumberOfIdleEmployees +
    NumberOfUnallocatedEmployees;

  useEffect(() => {
    dispatch(loadProductionDashboardData());
    dispatch(loadLaborDashboardData());
    return () => {
      dispatch(resetProductionDashboardData());
      dispatch(resetLaborDashboardData());
    };
  }, [dispatch]);

  const handleOnLogout = useCallback(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  return (
    <>
      <Loading
        loading={
          productionDashboardState.loading || laborDashboardState.loading
        }
      />
      {!isEmpty(subscriptionState.summary) && !subscriptionState.loading ? (
        <SubscriptionInactiveNotification
          open={!subscriptionState.summary.active}
          handleOnLogout={handleOnLogout}
        />
      ) : null}

      <Paper elevation={0}>
        <Box sx={{ width: '100%', padding: 2 }}>
          <Welcome currentUser={authState.user} />
          {!isEmpty(laborDashboardState.data) && !laborDashboardState.loading && (
            <Paper
              elevation={0}
              variant='outlined'
              sx={{ padding: 2, marginTop: 2 }}
            >
              <Grid container spacing={2} columns={15}>
                <Grid item xs={15}>
                  <Box display='flex'>
                    <Box flexGrow={1}>
                      <Typography variant='h6'>Work Orders</Typography>
                    </Box>
                    <Box>
                      <Button
                        variant='outlined'
                        startIcon={<ListAltIcon />}
                        onClick={() => navigate('/epack/workorders_pallets')}
                      >
                        Work Orders
                      </Button>
                    </Box>
                  </Box>
                  <Divider light sx={{ marginTop: 1 }} />
                </Grid>
                <Grid item xs={3}>
                  <DashboardNumericWidgetWithIcon
                    title='Total'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${blue[600]} 10%, ${blue[700]} 100%);`}
                    numericValue={TotalNumberOfWorkOrders}
                    numericValueColor={grey['100']}
                  >
                    <ListAltIcon
                      sx={{
                        width: '35px',
                        height: '35px',
                        marginRight: 2,
                        color: grey['100'],
                      }}
                    />
                  </DashboardNumericWidgetWithIcon>
                </Grid>
                <Grid item xs={3}>
                  <DashboardNumericWidgetWithIcon
                    title='In Production'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${green[600]} 10%, ${green[700]} 100%);`}
                    numericValue={NumberOfWorkOrdersInProduction}
                    numericValueColor={grey['100']}
                  >
                    <ListAltIcon
                      sx={{
                        width: '35px',
                        height: '35px',
                        marginRight: 2,
                        color: grey['100'],
                      }}
                    />
                  </DashboardNumericWidgetWithIcon>
                </Grid>
                <Grid item xs={3}>
                  <DashboardNumericWidgetWithIcon
                    title='Commodities'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${orange[600]} 10%, ${orange[700]} 100%);`}
                    numericValue={NumberOfCommoditiesInWorkOrders}
                    numericValueColor={grey['100']}
                  >
                    <ListAltIcon
                      sx={{
                        width: '35px',
                        height: '35px',
                        marginRight: 2,
                        color: grey['100'],
                      }}
                    />
                  </DashboardNumericWidgetWithIcon>
                </Grid>
                <Grid item xs={3}>
                  <DashboardNumericWidgetWithIcon
                    title='Pallets'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${teal[800]} 10%, ${teal[900]} 100%);`}
                    numericValue={NumberOfPalletsInWorkOrders}
                    numericValueColor={grey['100']}
                  >
                    <ListAltIcon
                      sx={{
                        width: '35px',
                        height: '35px',
                        marginRight: 2,
                        color: grey['100'],
                      }}
                    />
                  </DashboardNumericWidgetWithIcon>
                </Grid>
                <Grid item xs={3}>
                  <DashboardNumericWidgetWithIcon
                    title='Cases'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${cyan[600]} 10%, ${cyan[700]} 100%);`}
                    numericValue={NumberOfCasesInWorkOrders}
                    numericValueColor={grey['100']}
                  >
                    <ListAltIcon
                      sx={{
                        width: '35px',
                        height: '35px',
                        marginRight: 2,
                        color: grey['100'],
                      }}
                    />
                  </DashboardNumericWidgetWithIcon>
                </Grid>
              </Grid>
            </Paper>
          )}
          {!isEmpty(productionDashboardState.data) &&
            !productionDashboardState.loading && (
              <Paper
                elevation={0}
                variant='outlined'
                sx={{ padding: 2, marginTop: 2 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box display='flex'>
                      <Box flexGrow={1}>
                        <Typography variant='h6'>Production Lines</Typography>
                      </Box>
                      <Box>
                        <Button
                          variant='outlined'
                          startIcon={<TableViewIcon />}
                          onClick={() => navigate('epack/panel')}
                        >
                          Production Panel
                        </Button>
                      </Box>
                    </Box>
                    <Divider light sx={{ marginTop: 1 }} />
                  </Grid>

                  <Grid item xs={2}>
                    <DashboardNumericWidgetWithIcon
                      title='Total'
                      titleColor={grey['100']}
                      backgroundImage={`linear-gradient( 135deg, ${blue[600]} 10%, ${blue[700]} 100%);`}
                      numericValue={TotalNumberOfProductionLines}
                      numericValueColor={grey['100']}
                    >
                      <ReorderIcon
                        sx={{
                          width: '35px',
                          height: '35px',
                          marginRight: 2,
                          color: grey['100'],
                        }}
                      />
                    </DashboardNumericWidgetWithIcon>
                  </Grid>
                  <Grid item xs={2}>
                    <DashboardNumericWidgetWithIcon
                      title='Production'
                      titleColor={grey['100']}
                      backgroundImage={`linear-gradient( 135deg, ${green[600]} 10%, ${green[700]} 100%);`}
                      numericValue={NumberOfInProgressProductionLines}
                      numericValueColor={grey['100']}
                    >
                      <ReorderIcon
                        sx={{
                          width: '35px',
                          height: '35px',
                          marginRight: 2,
                          color: grey['100'],
                        }}
                      />
                    </DashboardNumericWidgetWithIcon>
                  </Grid>
                  <Grid item xs={2}>
                    <DashboardNumericWidgetWithIcon
                      title='Idle'
                      titleColor={grey['100']}
                      backgroundImage={`linear-gradient( 135deg, ${grey[700]} 10%, ${grey[800]} 100%);`}
                      numericValue={NumberOfAvailableProductionLines}
                      numericValueColor={grey['100']}
                    >
                      <ReorderIcon
                        sx={{
                          width: '35px',
                          height: '35px',
                          marginRight: 2,
                          color: grey['100'],
                        }}
                      />
                    </DashboardNumericWidgetWithIcon>
                  </Grid>
                  <Grid item xs={2}>
                    <DashboardNumericWidgetWithIcon
                      title='Setup'
                      titleColor={grey['100']}
                      backgroundImage={`linear-gradient( 135deg, ${deepPurple[600]} 10%, ${deepPurple[700]} 100%);`}
                      numericValue={NumberOfSetupProductionLines}
                      numericValueColor={grey['100']}
                    >
                      <ReorderIcon
                        sx={{
                          width: '35px',
                          height: '35px',
                          marginRight: 2,
                          color: grey['100'],
                        }}
                      />
                    </DashboardNumericWidgetWithIcon>
                  </Grid>
                  <Grid item xs={2}>
                    <DashboardNumericWidgetWithIcon
                      title='Sanitation'
                      titleColor={grey['100']}
                      backgroundImage={`linear-gradient( 135deg, ${orange[600]} 10%, ${orange[700]} 100%);`}
                      numericValue={NumberOfSanitationProductionLines}
                      numericValueColor={grey['100']}
                    >
                      <ReorderIcon
                        sx={{
                          width: '35px',
                          height: '35px',
                          marginRight: 2,
                          color: grey['100'],
                        }}
                      />
                    </DashboardNumericWidgetWithIcon>
                  </Grid>
                  <Grid item xs={2}>
                    <DashboardNumericWidgetWithIcon
                      title='Maintenance'
                      titleColor={grey['100']}
                      backgroundImage={`linear-gradient( 135deg, ${red[600]} 10%, ${red[700]} 100%);`}
                      numericValue={NumberOfUnavailableProductionLines}
                      numericValueColor={grey['100']}
                    >
                      <ReorderIcon
                        sx={{
                          width: '35px',
                          height: '35px',
                          marginRight: 2,
                          color: grey['100'],
                        }}
                      />
                    </DashboardNumericWidgetWithIcon>
                  </Grid>
                </Grid>
              </Paper>
            )}
          {!isEmpty(laborDashboardState.data) && !laborDashboardState.loading && (
            <Paper
              elevation={0}
              variant='outlined'
              sx={{ padding: 2, marginTop: 2 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display='flex'>
                    <Box flexGrow={1}>
                      <Typography variant='h6'>Workforce Allocation</Typography>
                    </Box>
                    <Box>
                      <Button
                        variant='outlined'
                        startIcon={<AssignmentIcon />}
                        onClick={() => navigate('etime/labor/unallocated')}
                      >
                        Labor Clipboard
                      </Button>
                    </Box>
                  </Box>
                  <Divider light sx={{ marginTop: 1 }} />
                </Grid>

                <Grid item xs={3}>
                  <DashboardNumericWidgetWithIcon
                    title='Total'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${blue[600]} 10%, ${blue[700]} 100%);`}
                    numericValue={totalEmployees}
                    numericValueColor={grey['100']}
                  >
                    <PersonIcon
                      sx={{
                        width: '35px',
                        height: '35px',
                        marginRight: 2,
                        color: grey['100'],
                      }}
                    />
                  </DashboardNumericWidgetWithIcon>
                </Grid>
                <Grid item xs={3}>
                  <DashboardNumericWidgetWithIcon
                    title='Unallocated'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${red[600]} 10%, ${red[700]} 100%);`}
                    numericValue={NumberOfUnallocatedEmployees}
                    numericValueColor={grey['100']}
                  >
                    <PersonIcon
                      sx={{
                        width: '35px',
                        height: '35px',
                        marginRight: 2,
                        color: grey['100'],
                      }}
                    />
                  </DashboardNumericWidgetWithIcon>
                </Grid>
                <Grid item xs={3}>
                  <DashboardNumericWidgetWithIcon
                    title='Working'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${green[600]} 10%, ${green[700]} 100%);`}
                    numericValue={NumberOfActiveEmployees}
                    numericValueColor={grey['100']}
                  >
                    <PersonIcon
                      sx={{
                        width: '35px',
                        height: '35px',
                        marginRight: 2,
                        color: grey['100'],
                      }}
                    />
                  </DashboardNumericWidgetWithIcon>
                </Grid>
                <Grid item xs={3}>
                  <DashboardNumericWidgetWithIcon
                    title='On Break'
                    titleColor={grey['100']}
                    backgroundImage={`linear-gradient( 135deg, ${orange[600]} 10%, ${orange[700]} 100%);`}
                    numericValue={NumberOfIdleEmployees}
                    numericValueColor={grey['100']}
                  >
                    <PersonIcon
                      sx={{
                        width: '35px',
                        height: '35px',
                        marginRight: 2,
                        color: grey['100'],
                      }}
                    />
                  </DashboardNumericWidgetWithIcon>
                </Grid>
              </Grid>
            </Paper>
          )}
          {isEmpty(productionDashboardState.data) &&
            !productionDashboardState.loading && (
              <Empty description='No data to show' />
            )}
        </Box>
      </Paper>
    </>
  );
}
