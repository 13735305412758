import React from 'react';
import {
  Box,
  Card,
  Collapse,
  CardContent,
  Divider,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import LaborLogTileList from './LaborLogTileList';
import BuildIcon from '@mui/icons-material/Build';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import grey from '@mui/material/colors/grey';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MachineIcon from '../UI/icons/MachineIcon';
import Empty from '../UI/Empty';
const isEmpty = require('is-empty');

const LaborJobViewTileList = ({
  list,
  expandedJobTiles,
  setExpandedJobTiles,
  handleClickStartLaborLog,
  handleClickStopLaborLog,
  handleClickStartByJob,
  handleClickStopByJob,
  handleClickMoveLaborLog,
  handleClickMoveByJob,
  handleClickChangePosition,
  handleClickWalkOff,
}) => {
  const handleExpandClick = (id) => {
    if (!expandedJobTiles.includes(id)) {
      setExpandedJobTiles([...expandedJobTiles, id]);
    } else {
      setExpandedJobTiles(expandedJobTiles.filter((i) => i !== id));
    }
  };

  return (
    <>
      {list.map((job) => {
        return (
          <Box my={0.5} key={job.id}>
            <Card variant='outlined'>
              <CardContent
                sx={{
                  padding: 0,
                  '&:last-child': {
                    paddingBottom: 0,
                  },
                }}
              >
                <Box display='flex'>
                  <Box display='flex' flexGrow={1} mt={0.5}>
                    <Box
                      display='flex'
                      alignItems='center'
                      sx={{ width: '30%' }}
                      ml={1}
                    >
                      <BuildIcon
                        sx={{
                          color: grey['600'],
                          width: '18px',
                          height: '18px',
                        }}
                      />
                      <Typography
                        component='span'
                        sx={{
                          marginLeft: '0.5rem',
                          fontWeight: 'bold',
                          color: grey['800'],
                        }}
                      >
                        {job.jobType.name}
                      </Typography>
                    </Box>
                    <Box
                      display='flex'
                      alignItems='center'
                      sx={{ width: '25%' }}
                    >
                      <ListAltIcon
                        sx={{
                          color: grey['600'],
                          width: '18px',
                          height: '18px',
                        }}
                      />
                      <Typography
                        component='span'
                        sx={{
                          marginLeft: '0.5rem',
                          fontWeight: 'bold',
                          color: grey['800'],
                        }}
                      >
                        {` ${job.workOrder.code}`}
                      </Typography>
                    </Box>
                    <Box
                      display='flex'
                      alignItems='center'
                      sx={{ width: '25%' }}
                    >
                      <MachineIcon
                        sx={{
                          color: grey['600'],
                          width: '18px',
                          height: '18px',
                        }}
                      />
                      <Typography
                        component='span'
                        sx={{
                          marginLeft: '0.5rem',
                          fontWeight: 'bold',
                          color: grey['800'],
                        }}
                      >
                        {` ${job.machine.name}`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex'>
                    <Tooltip title='Start'>
                      <IconButton
                        onClick={() => handleClickStartByJob(job.id)}
                        sx={{ color: 'success.main' }}
                      >
                        <PlayArrowIcon
                          sx={{
                            width: '25px',
                            height: '25px',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Stop'>
                      <IconButton
                        onClick={() => handleClickStopByJob(job.id)}
                        sx={{ color: 'error.main' }}
                      >
                        <StopIcon
                          sx={{
                            width: '25px',
                            height: '25px',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Change Job'>
                      <IconButton
                        onClick={() => handleClickMoveByJob(job)}
                        sx={{ color: 'primary.main' }}
                      >
                        <ShuffleIcon
                          sx={{
                            width: '25px',
                            height: '25px',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Expand/Contract'>
                      <IconButton
                        sx={[
                          expandedJobTiles.includes(job.id)
                            ? {
                                transform: 'rotate(180deg)',
                              }
                            : {
                                transform: 'rotate(0deg)',
                                marginLeft: 'auto',
                                transition: (theme) =>
                                  theme.transitions.create('transform', {
                                    duration:
                                      theme.transitions.duration.shortest,
                                  }),
                              },
                        ]}
                        onClick={() => handleExpandClick(job.id)}
                      >
                        <ExpandMoreIcon
                          sx={{
                            width: '25px',
                            height: '25px',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                <Divider variant='fullWidth' />
                <Collapse
                  in={expandedJobTiles.includes(job.id)}
                  collapsedSize={40}
                  timeout='auto'
                  unmountOnExit
                >
                  <CardContent
                    sx={{
                      marginTop: 1,
                      paddingTop: 0,
                      paddingBottom: 0.5,
                      backgroundColor: '#f5f5f5',
                      '&:last-child': {
                        paddingBottom: 1,
                      },
                    }}
                  >
                    {isEmpty(job.docs) ? (
                      <Empty description='There are no employees allocated to this job' />
                    ) : (
                      <LaborLogTileList
                        laborLogs={job.docs}
                        handleClickStartLaborLog={handleClickStartLaborLog}
                        handleClickStopLaborLog={handleClickStopLaborLog}
                        handleClickMoveLaborLog={(laborLog) =>
                          handleClickMoveLaborLog(laborLog, job)
                        }
                        handleClickChangePosition={(laborLog) =>
                          handleClickChangePosition(laborLog, job)
                        }
                        handleClickWalkOff={(laborLog) =>
                          handleClickWalkOff(laborLog, job)
                        }
                      />
                    )}
                  </CardContent>
                </Collapse>
              </CardContent>
            </Card>
          </Box>
        );
      })}
    </>
  );
};

export default LaborJobViewTileList;
