import {
  SET_LABOR_UNALLOCATED_LIST,
  LABOR_UNALLOCATED_LIST_LOADING,
  LABOR_UNALLOCATED_LIST_RESET,
  SET_LABOR_UNALLOCATED_NAME_FILTER,
  SAVING_LABOR_UNALLOCATED_DATA,
  SHOW_ASSIGN_JOB_FORM,
  TOGGLE_SELECT_LABOR_UNALLOCATED_IN_LIST,
  SET_SELECT_ALL_LABOR_UNALLOCATED_IN_LIST,
} from './types';

export const setUnallocatedList = (list) => {
  return {
    type: SET_LABOR_UNALLOCATED_LIST,
    payload: list,
  };
};

export const resetUnallocatedList = () => {
  return {
    type: LABOR_UNALLOCATED_LIST_RESET,
  };
};

export const setUnallocatedListLoading = (payload) => {
  return {
    type: LABOR_UNALLOCATED_LIST_LOADING,
    payload,
  };
};

export const setUnallocatedNameFilter = (payload) => {
  return {
    type: SET_LABOR_UNALLOCATED_NAME_FILTER,
    payload,
  };
};

export const showAssignJobForm = (payload) => {
  return {
    type: SHOW_ASSIGN_JOB_FORM,
    payload,
  };
};

export const savingUnallocatedData = (payload) => {
  return {
    type: SAVING_LABOR_UNALLOCATED_DATA,
    payload,
  };
};

export const toggleSelectUnallocatedInList = (payload) => {
  return {
    type: TOGGLE_SELECT_LABOR_UNALLOCATED_IN_LIST,
    payload,
  };
};

export const setSelectAllUnallocatedInList = (payload) => {
  return {
    type: SET_SELECT_ALL_LABOR_UNALLOCATED_IN_LIST,
    payload,
  };
};
