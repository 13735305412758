import {
  SET_SUBSCRIPTION_LOADING,
  SET_SUBSCRIPTION_SUMMARY,
  SET_SUBSCRIPTION_DETAILS,
} from './types';

const initialState = {
  summary: {},
  details: {},
  loading: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SUBSCRIPTION_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_SUBSCRIPTION_SUMMARY:
      return {
        ...state,
        summary: { ...action.payload },
      };
    case SET_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        details: { ...action.payload },
      };
    default:
      return state;
  }
}
