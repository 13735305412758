import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';
import LaborLogDetails from './LaborLogDetails';

const ValidationSchema = Yup.object().shape({
  reason: Yup.string()
    .min(1, 'Must be 1 character or more')
    .max(100, 'Must be 100 characters or less')
    .required('Reason required'),
});

const LaborLogWalkOffForm = ({
  laborLog,
  workOrder,
  machine,
  jobType,
  handleOnSubmit,
  handleOnCancel,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  useEffect(() => {
    setValue('laborLogId', laborLog.id);
  }, [laborLog, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper elevation={0}>
          <Box component="div" display="flex" mx={1} alignItems="center">
            <Box display="flex" my={1.5} flexGrow={1} alignItems="center">
              <Typography
                color="primary"
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'eTime - Walk Off'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <LaborLogDetails
            laborLog={laborLog}
            workOrderCode={workOrder?.code}
            commodityName={workOrder?.commodity}
            machineName={machine?.name}
            jobTypeName={jobType?.name}
          />
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Controller
                  name="reason"
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Reason"
                      fullWidth
                      error={!!errors?.reason}
                      helperText={errors?.reason?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box component="div" mx={1} my={0.5} display="flex">
            <Box my={1.5} flexGrow={1}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                startIcon={<LoginIcon />}
              >
                {'Yes, Walk Off'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant="outlined"
                size="large"
                startIcon={<UndoIcon />}
                onClick={handleOnCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default LaborLogWalkOffForm;
