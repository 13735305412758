import {
  SET_REGISTRATION_LOADING,
  SET_REGISTRATION_COMPLETE,
  SET_COMPANY_NAME_TAKEN,
  SET_COMPANY_EMAIL_TAKEN,
  SET_USER_EMAIL_TAKEN,
  SET_VALIDATING_REGISTRATION_DATA,
} from './types';

const initialState = {
  registrationLoading: false,
  registrationComplete: false,
  companyNameTaken: false,
  companyEmailTaken: false,
  userEmailTaken: false,
  validating: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_REGISTRATION_LOADING:
      return {
        ...state,
        registrationLoading: action.payload,
      };
    case SET_REGISTRATION_COMPLETE:
      return {
        ...state,
        registrationComplete: action.payload,
      };
    case SET_COMPANY_NAME_TAKEN:
      return {
        ...state,
        companyNameTaken: action.payload,
      };
    case SET_COMPANY_EMAIL_TAKEN:
      return {
        ...state,
        companyEmailTaken: action.payload,
      };
    case SET_USER_EMAIL_TAKEN:
      return {
        ...state,
        userEmailTaken: action.payload,
      };
    case SET_VALIDATING_REGISTRATION_DATA:
      return {
        ...state,
        validating: action.payload,
      };
    default:
      return state;
  }
}
