export const SET_EMPLOYEE_POSITION = 'SET_EMPLOYEE_POSITION';
export const EMPLOYEE_POSITION_LOADING = 'EMPLOYEE_POSITION_LOADING';
export const EMPLOYEE_POSITION_RESET = 'EMPLOYEE_POSITION_RESET';
export const SET_EMPLOYEE_POSITIONS = 'SET_EMPLOYEE_POSITIONS';
export const EMPLOYEE_POSITION_LIST_LOADING = 'EMPLOYEE_POSITION_LIST_LOADING';
export const EMPLOYEE_POSITION_LIST_RESET = 'EMPLOYEE_POSITION_LIST_RESET';
export const ADD_EMPLOYEE_POSITION_TO_LIST = 'ADD_EMPLOYEE_POSITION_TO_LIST';
export const UPDATE_EMPLOYEE_POSITION_IN_LIST = 'UPDATE_EMPLOYEE_POSITION_IN_LIST';
export const DELETE_EMPLOYEE_POSITION_FROM_LIST = 'DELETE_EMPLOYEE_POSITION_FROM_LIST';
export const SHOW_ADD_EDIT_EMPLOYEE_POSITION_FORM = 'SHOW_ADD_EDIT_EMPLOYEE_POSITION_FORM';
export const SAVING_EMPLOYEE_POSITION_DATA = 'SAVING_EMPLOYEE_POSITION_DATA';
