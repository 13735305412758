import React from 'react';
import jwt_decode from 'jwt-decode';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { activateCompanyAndLoginUser } from '../../redux/system/auth/services';
import { Box, Paper, Typography } from '@mui/material';
import ResetPasswordForm from '../../components/auth/ResetPasswordForm';
import isEmpty from 'is-empty';

const ActivateCompany = () => {
  const [params] = useSearchParams();
  const dispatch = useDispatch();

  const token = params.get('token');

  let decodedToken;

  try {
    decodedToken = jwt_decode(token);
  } catch (error) {
    console.error('Error decoding token:', error);
    window.location.href = '/';
  }

  if (isEmpty(decodedToken)) {
    window.location.href = '/';
  }

  const currentTime = Date.now() / 1000;
  if (decodedToken.exp < currentTime) {
    console.error('Token expired');
    window.location.href = '/';
  }

  const onSubmit = (data) => {
    dispatch(
      activateCompanyAndLoginUser({ password: data.password, token: token })
    );
  };

  return (
    <>
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box my={0.5} flexGrow={1}>
            <Typography variant='subtitle1'>
              Please enter your new credentials to access eHawk
            </Typography>
          </Box>
        </Box>
        <Box display='flex' justifyContent='center'>
          <Box sx={{ maxWidth: '450px' }}>
            <ResetPasswordForm
              handleOnSubmit={onSubmit}
              actionButtonTitle={'Activate Company'}
              showAgreement
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default ActivateCompany;
