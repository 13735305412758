import {
  SET_EMPLOYEE_POSITION,
  EMPLOYEE_POSITION_LOADING,
  EMPLOYEE_POSITION_RESET,
  SET_EMPLOYEE_POSITIONS,
  EMPLOYEE_POSITION_LIST_LOADING,
  EMPLOYEE_POSITION_LIST_RESET,
  ADD_EMPLOYEE_POSITION_TO_LIST,
  UPDATE_EMPLOYEE_POSITION_IN_LIST,
  DELETE_EMPLOYEE_POSITION_FROM_LIST,
  SHOW_ADD_EDIT_EMPLOYEE_POSITION_FORM,
  SAVING_EMPLOYEE_POSITION_DATA,
} from './types';

export const setEmployeePosition = (item) => {
  return {
    type: SET_EMPLOYEE_POSITION,
    payload: item,
  };
};

export const resetEmployeePosition = () => {
  return {
    type: EMPLOYEE_POSITION_RESET,
  };
};

export const setEmployeePositionLoading = (payload) => {
  return {
    type: EMPLOYEE_POSITION_LOADING,
    payload,
  };
};

export const setEmployeePositionList = (list) => {
  return {
    type: SET_EMPLOYEE_POSITIONS,
    payload: list,
  };
};

export const resetEmployeePositionList = () => {
  return {
    type: EMPLOYEE_POSITION_LIST_RESET,
  };
};

export const setEmployeePositionListLoading = (payload) => {
  return {
    type: EMPLOYEE_POSITION_LIST_LOADING,
    payload,
  };
};

export const addEmployeePositionToList = (payload) => {
  return {
    type: ADD_EMPLOYEE_POSITION_TO_LIST,
    payload,
  };
};

export const deleteEmployeePositionFromList = (payload) => {
  return {
    type: DELETE_EMPLOYEE_POSITION_FROM_LIST,
    payload,
  };
};

export const updateEmployeePositionInList = (payload) => {
  return {
    type: UPDATE_EMPLOYEE_POSITION_IN_LIST,
    payload,
  };
};

export const showAddEditEmployeePositionForm = (payload) => {
  return {
    type: SHOW_ADD_EDIT_EMPLOYEE_POSITION_FORM,
    payload,
  };
};

export const savingEmployeePositionData = (payload) => {
  return {
    type: SAVING_EMPLOYEE_POSITION_DATA,
    payload,
  };
};
