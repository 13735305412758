import {
  SET_CUSTOMER,
  CUSTOMER_LOADING,
  CUSTOMER_RESET,
  SET_CUSTOMERS,
  CUSTOMER_LIST_LOADING,
  CUSTOMER_LIST_RESET,
  ADD_CUSTOMER_TO_LIST,
  UPDATE_CUSTOMER_IN_LIST,
  DELETE_CUSTOMER_FROM_LIST,
  SHOW_ADD_EDIT_CUSTOMER_FORM,
  SAVING_CUSTOMER_DATA,
} from './types';

export const setCustomer = (item) => {
  return {
    type: SET_CUSTOMER,
    payload: item,
  };
};

export const resetCustomer = () => {
  return {
    type: CUSTOMER_RESET,
  };
};

export const setCustomerLoading = (payload) => {
  return {
    type: CUSTOMER_LOADING,
    payload,
  };
};

export const setCustomerList = (list) => {
  return {
    type: SET_CUSTOMERS,
    payload: list,
  };
};

export const resetCustomerList = () => {
  return {
    type: CUSTOMER_LIST_RESET,
  };
};

export const setCustomerListLoading = (payload) => {
  return {
    type: CUSTOMER_LIST_LOADING,
    payload,
  };
};

export const addCustomerToList = (payload) => {
  return {
    type: ADD_CUSTOMER_TO_LIST,
    payload,
  };
};

export const deleteCustomerFromList = (payload) => {
  return {
    type: DELETE_CUSTOMER_FROM_LIST,
    payload,
  };
};

export const updateCustomerInList = (payload) => {
  return {
    type: UPDATE_CUSTOMER_IN_LIST,
    payload,
  };
};

export const showAddEditCustomerForm = (payload) => {
  return {
    type: SHOW_ADD_EDIT_CUSTOMER_FORM,
    payload,
  };
};

export const savingCustomerData = (payload) => {
  return {
    type: SAVING_CUSTOMER_DATA,
    payload,
  };
};
