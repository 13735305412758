import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setPalletTypeList,
  resetPalletTypeList,
  setPalletTypeListLoading,
} from './actions';

export const loadPalletTypeList = () => (dispatch, getState, api) => {
  dispatch(setPalletTypeListLoading(true));
  axios
    .get(`${api}/list/pallet-types`)
    .then((res) => {
      dispatch(resetPalletTypeList());
      dispatch(setPalletTypeList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setPalletTypeListLoading(false));
    });
};
