import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import * as Yup from 'yup';
import isEmpty from 'is-empty';
import moment from 'moment';

const ValidationSchema = Yup.object().shape({
  pricePerCase: Yup.number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .required('Required'),
});

const SubscriptionForm = ({
  company,
  subscription,
  handleOnSubmit,
  handleOnCancel,
  savingData,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          const formData = {
            active: data.active,
            pricePerCase: data.pricePerCase,
            freeStartDate: isEmpty(data.freeStartDate)
              ? moment().subtract(1, 'years').format('YYYY-MM-DD')
              : moment(data.freeStartDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
            freeEndDate: isEmpty(data.freeEndDate)
              ? moment().subtract(1, 'years').format('YYYY-MM-DD')
              : moment(data.freeEndDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          };
          handleOnSubmit(formData);
        })}
      >
        <Paper elevation={0}>
          <Box component='div' display='flex' mx={1} alignItems='center'>
            <Box display='flex' my={1.5} flexGrow={1} alignItems='center'>
              <Typography
                color='primary'
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.5rem',
                }}
              >
                {'Subscription'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12}>
                <Card variant='outlined'>
                  <CardHeader
                    title='Company Information'
                    sx={{ paddingBottom: 0.5, paddingTop: 1 }}
                  />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          label='Name'
                          fullWidth
                          disabled
                          value={company.name}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label='Email'
                          fullWidth
                          disabled
                          value={company.email}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card elevation={0}>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sx={{ marginBottom: 2 }}>
                        <Controller
                          control={control}
                          name='active'
                          defaultValue={subscription.active}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={field.value}
                                  onClick={(e) => field.onChange(!field.value)}
                                />
                              }
                              label={'Active Subscription'}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name='pricePerCase'
                          control={control}
                          defaultValue={
                            isEmpty(subscription.pricePerCase)
                              ? ''
                              : parseFloat(subscription.pricePerCase).toFixed(2)
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label='Price Per Case'
                              fullWidth
                              type='number'
                              error={!!errors?.pricePerCase}
                              helperText={errors?.pricePerCase?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              {!isEmpty(subscription.agreement) ? (
                <Grid item xs={6}>
                  <Card variant='outlined'>
                    <CardHeader
                      title='Agreement'
                      sx={{ paddingBottom: 0.5, paddingTop: 1 }}
                    />
                    <CardContent>
                      <Paper
                        elevation={0}
                        variant='outlined'
                        sx={{ padding: 1 }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            {subscription.agreement.accepted === true ? (
                              <Typography variant='body1'>Accepted</Typography>
                            ) : (
                              <Typography variant='body1'>
                                Not Accepted
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant='body1'>
                              On:{' '}
                              {moment(subscription.agreement.on).format(
                                'MM/DD/YYYY hh:mm A'
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant='body1'>
                              By:
                              {` ${subscription.agreement.by.firstName} ${subscription.agreement.by.lastName}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </CardContent>
                  </Card>
                </Grid>
              ) : null}
              <Grid item xs={6}>
                <Card variant='outlined'>
                  <CardHeader
                    title='Free Trial Period'
                    sx={{ paddingBottom: 0.5, paddingTop: 1 }}
                  />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Controller
                          name='freeStartDate'
                          control={control}
                          defaultValue={
                            isEmpty(subscription.freeStartDate)
                              ? ''
                              : moment(subscription.freeStartDate).format(
                                  'MM/DD/YYYY'
                                )
                          }
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              label='Start Date'
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!errors?.freeStartDate}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name='freeEndDate'
                          control={control}
                          defaultValue={
                            isEmpty(subscription.freeEndDate)
                              ? ''
                              : moment(subscription.freeEndDate).format(
                                  'MM/DD/YYYY'
                                )
                          }
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              label='End Date'
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!errors?.freeEndDate}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              {!isEmpty(subscription.cancellation) ? (
                <Grid item xs={6}>
                  <Card variant='outlined'>
                    <CardHeader
                      title='Cancellation'
                      sx={{ paddingBottom: 0.5, paddingTop: 1 }}
                    />
                    <CardContent>
                      <Paper
                        elevation={0}
                        variant='outlined'
                        sx={{ padding: 1 }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant='body1'>
                              On:{' '}
                              {moment(subscription.cancellation.on).format(
                                'MM/DD/YYYY hh:mm A'
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant='body1'>
                              By:
                              {` ${subscription.cancellation.by.firstName} ${subscription.cancellation.by.lastName}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </CardContent>
                  </Card>
                </Grid>
              ) : null}
            </Grid>
          </Box>
          <Box component='div' mx={1} my={0.5} display='flex'>
            <Box my={1.5} flexGrow={1}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                type='submit'
                startIcon={<SaveIcon />}
              >
                {savingData ? 'Updating...' : 'Update'}
              </Button>
            </Box>
            <Box my={1.5}>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UndoIcon />}
                onClick={() => {
                  reset();
                  handleOnCancel();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </>
  );
};

export default SubscriptionForm;
