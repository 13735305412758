import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Divider,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import BuildIcon from '@mui/icons-material/Build';
import MenuVisibilityGate from '../../utils/MenuVisibilityGate';

const isDemo = process.env.REACT_APP_DEMO === 'true' ? true : false;

const ETimeMenu = ({ anchorEl, open, handleClose, userRole }) => {
  let navigate = useNavigate();
  return (
    <Menu
      id='e-time-menu'
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      getcontentanchorel={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MenuVisibilityGate
        userRole={userRole}
        allowedPermissions={['etime:clipboard']}
      >
        <MenuItem
          onClick={() => {
            navigate('/etime/labor/job');
            handleClose();
          }}
        >
          <ListItemIcon>
            <AssignmentIcon fontSize='small' />
          </ListItemIcon>

          <ListItemText>Clipboard</ListItemText>
        </MenuItem>
        <Divider />
      </MenuVisibilityGate>
      <MenuVisibilityGate
        userRole={userRole}
        allowedPermissions={['etime:gatein']}
      >
        <MenuItem
          disabled={isDemo}
          onClick={() => {
            navigate('/etime/gatein');
            handleClose();
          }}
        >
          <ListItemIcon>
            <LoginIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Gate In</ListItemText>
        </MenuItem>
        <Divider />
      </MenuVisibilityGate>
      <MenuVisibilityGate
        userRole={userRole}
        allowedPermissions={['etime:gateout']}
      >
        <MenuItem
          disabled={isDemo}
          onClick={() => {
            navigate('/etime/gateout');
            handleClose();
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Gate Out</ListItemText>
        </MenuItem>
        <Divider />
      </MenuVisibilityGate>
      <MenuVisibilityGate
        userRole={userRole}
        allowedPermissions={['etime:employees']}
      >
        <MenuItem
          disabled={isDemo}
          onClick={() => {
            navigate('/etime/employees');
            handleClose();
          }}
        >
          <ListItemIcon>
            <PeopleIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Employees</ListItemText>
        </MenuItem>
        <Divider />
      </MenuVisibilityGate>
      <MenuVisibilityGate
        userRole={userRole}
        allowedPermissions={['etime:adjustlogs']}
      >
        <MenuItem
          disabled={isDemo}
          onClick={() => {
            navigate('/etime/adjust_logs');
            handleClose();
          }}
        >
          <ListItemIcon>
            <BuildIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Adjust Log</ListItemText>
        </MenuItem>
      </MenuVisibilityGate>
    </Menu>
  );
};

export default ETimeMenu;
