export const SET_USER = 'SET_USER';
export const USER_LOADING = 'USER_LOADING';
export const USER_RESET = 'USER_RESET';
export const SET_USERS = 'SET_USERS';
export const USER_LIST_LOADING = 'USER_LIST_LOADING';
export const USER_LIST_RESET = 'USER_LIST_RESET';
export const ADD_USER_TO_LIST = 'ADD_USER_TO_LIST';
export const UPDATE_USER_IN_LIST = 'UPDATE_USER_IN_LIST';
export const DELETE_USER_FROM_LIST = 'DELETE_USER_FROM_LIST';
export const SHOW_ADD_EDIT_USER_FORM = 'SHOW_ADD_EDIT_USER_FORM';
export const SAVING_USER_DATA = 'SAVING_USER_DATA';
