import React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

const Loading = ({ loading }) => {
  return (
    <StyledBackdrop open={loading}>
      <CircularProgress color='inherit' />
    </StyledBackdrop>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Loading;
