import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setJobType,
  resetJobType,
  resetJobTypeList,
  showAddEditJobTypeForm,
} from '../../redux/company/job-types/actions';
import {
  loadJobTypes,
  createJobType,
  updateJobType,
  deleteJobType,
  toggleJobTypeStatus,
} from '../../redux/company/job-types/services';
import { getJobTypeList } from '../../redux/company/job-types/selectors';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Paper,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import Loading from '../../components/UI/Loading';
import JobTypeForm from '../../components/company/JobTypeForm';
import Empty from '../../components/UI/Empty';
import JobTypesTable from '../../components/company/JobTypesTable';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
const isEmpty = require('is-empty');

const JobTypes = () => {
  const dispatch = useDispatch();
  const jobTypesState = useSelector((state) => state.job_types);
  const jobTypeState = useSelector((state) => state.job_types.item);
  const JobTypesList = useSelector(getJobTypeList);
  const [editMode, setEditMode] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [openRemoveJobTypeDialog, setOpenRemoveJobTypeDialog] = useState(false);

  useEffect(() => {
    dispatch(loadJobTypes());
    return () => {
      dispatch(resetJobTypeList());
    };
  }, [dispatch]);

  const handleClickAdd = () => {
    setEditMode(false);
    dispatch(showAddEditJobTypeForm(true));
  };

  const handleClickEdit = (jobType) => {
    setEditMode(true);
    dispatch(setJobType(jobType));
    dispatch(showAddEditJobTypeForm(true));
  };

  const onSubmitAddEdit = (data) => {
    if (editMode) {
      dispatch(updateJobType(jobTypeState.id, data));
    } else {
      dispatch(createJobType(data));
    }
  };

  const handleRefresh = () => {
    dispatch(loadJobTypes());
  };

  const handleClickRemove = (jobType) => {
    dispatch(setJobType(jobType));
    setOpenRemoveJobTypeDialog(true);
  };

  const onRemoveJobType = () => {
    dispatch(deleteJobType(jobTypeState.id));
    dispatch(resetJobType());
    setOpenRemoveJobTypeDialog(false);
  };

  const handleClickToogleStatus = (jobType) => {
    dispatch(setJobType(jobType));
    setOpenStatusDialog(true);
  };

  const onToggleStatus = () => {
    dispatch(toggleJobTypeStatus(jobTypeState.id, jobTypeState.active));
    dispatch(resetJobType());
    setOpenStatusDialog(false);
  };

  return (
    <>
      <Loading loading={jobTypesState.loading} />
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box display='flex' alignContent='center'>
            <IconButton aria-label='reload' onClick={handleRefresh}>
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              Job Types
            </Typography>
          </Box>
          <Tooltip title='Add Job Type'>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<AddIcon />}
              sx={{ marginRight: '0.5rem' }}
              onClick={handleClickAdd}
            >
              Add
            </Button>
          </Tooltip>
        </Box>
        <Box sx={{ width: '100%' }}>
          {!isEmpty(jobTypesState.list) && (
            <JobTypesTable
              rows={JobTypesList}
              handleClickEdit={handleClickEdit}
              handleClickRemove={handleClickRemove}
              handleClickToogleStatus={handleClickToogleStatus}
            />
          )}
          {isEmpty(jobTypesState.list) && !jobTypesState.loading && (
            <Empty description='There are no job types to show' />
          )}
        </Box>
      </Paper>
      <ConfirmationDialog
        open={openRemoveJobTypeDialog}
        handleNo={() => {
          setOpenRemoveJobTypeDialog(false);
        }}
        handleYes={onRemoveJobType}
        title='ePack - Remove Job Type'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to remove job type: ${jobTypeState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openStatusDialog}
        handleNo={() => {
          setOpenStatusDialog(false);
        }}
        handleYes={onToggleStatus}
        title='ePack - Employee Position Status'
        YesText={'Yes, Change'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to ${
            jobTypeState?.active ? 'deactivate' : 'activate'
          } job type: ${jobTypeState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <Dialog
        open={jobTypesState.showAddEditForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setEditMode(false);
            dispatch(resetJobType());
            dispatch(showAddEditJobTypeForm(false));
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <JobTypeForm
            editMode={editMode}
            jobType={jobTypeState}
            handleOnCancel={() => {
              setEditMode(false);
              dispatch(resetJobType());
              dispatch(showAddEditJobTypeForm(false));
            }}
            handleOnSubmit={onSubmitAddEdit}
            savingData={jobTypesState.savingData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default JobTypes;
