import axios from 'axios';
import { addNotification } from '../../system/notifications/actions';
import {
  setCommodityList,
  resetCommodityList,
  setCommodityListLoading,
} from './actions';

export const loadCommodityList = () => (dispatch, getState, api) => {
  dispatch(setCommodityListLoading(true));
  axios
    .get(`${api}/list/commodities`)
    .then((res) => {
      dispatch(resetCommodityList());
      dispatch(setCommodityList(res.data));
    })
    .catch((err) => {
      console.log(err);
      const notification = {
        message: '😱 Something went wrong, please try again',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
    })
    .finally(() => {
      dispatch(setCommodityListLoading(false));
    });
};
