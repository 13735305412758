import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { addNotification } from '../../redux/system/notifications/actions';
import {
  loadProductionLine,
  updateProductionLine,
  loadProductionLinePalletByCode,
  addProductionLinePallet,
  deleteProductionLinePallet,
  addProductionLinePhoto,
  deleteProductionLinePhoto,
  updateProductionLinePalletWeight,
  addPalletsToProductionLineFromWorkOrder,
  movePalletToIn,
  movePalletToOut,
} from '../../redux/epack/production/services';
import {
  resetProductionLineItem,
  resetProductionLinePallet,
  showPalletAddScan,
} from '../../redux/epack/production/actions';
import {
  getPalletsIn,
  getPalletsOut,
} from '../../redux/epack/production/selectors';
import { loadMachineList } from '../../redux/list/machines/services';
import { resetMachineList } from '../../redux/list/machines/actions';
import { getMachineList } from '../../redux/list/machines/selectors';
import { loadPalletTypeList } from '../../redux/list/pallet-types/services';
import { resetPalletTypeList } from '../../redux/list/pallet-types/actions';
import { getPalletTypeList } from '../../redux/list/pallet-types/selectors';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ConfirmationDialod from '../../components/UI/ConfirmationDialog';
import Empty from '../../components/UI/Empty';
import PalletAddScanForm from '../../components/epack/PalletAddScanForm';
import ProductionPhotoForm from '../../components/epack/ProductionPhotoForm';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ScanAddIcon from '../../components/UI/icons/ScanAddIcon';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PalletInIcon from '../../components/UI/icons/PalletInIcon';
import PalletIcon from '../../components/UI/icons/PalletIcon';
import PalletOutIcon from '../../components/UI/icons/PalletOutIcon';
import ClipboardInflowTable from '../../components/epack/ClipboardInflowTable';
import ClipboardOutflowTable from '../../components/epack/ClipboardOutflowTable';
import ClipboardPhotoList from '../../components/epack/ClipboardPhotoList';
import Loading from '../../components/UI/Loading';
import isEmpty from 'is-empty';
import { allowedKeyCodes } from '../../utils/allowKeyCodes';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

let inputString = '';

const StyledAccordion = styled((props) => (
  <Accordion
    disableGutters
    elevation={0}
    square
    variant='outlined'
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '& .MuiAccordionDetails-root': {
    padding: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-root.Mui-expanded ': {
    minHeight: '48px',
  },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const ProductionClipboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [palletId, setPalletId] = useState(null);
  const [pallet, setPallet] = useState(null);
  const [photoDialogOpen, setPhotoDialogOpen] = useState(false);
  const [openPalletDeleteDialog, setOpenPalletDeleteDialog] = useState(false);
  const [openPhotoDeleteDialog, setOpenPhotoDeleteDialog] = useState(false);
  const [openMovePalletToInDialog, setOpenPalletToInDialog] = useState(false);
  const [openMovePalletToOutDialog, setOpenPalletToOutDialog] = useState(false);
  const [
    openAddPalletsFromWorkOrderDialog,
    setOpenAddPalletsFromWorkOrderDialog,
  ] = useState(false);
  const [photoId, setPhotoId] = useState(null);
  const productionState = useSelector((state) => state.production);
  const listMachineState = useSelector((state) => state.list_machine);
  const PalletsIn = useSelector(getPalletsIn);
  const PalletsOut = useSelector(getPalletsOut);
  const MachineList = useSelector(getMachineList);
  const PalletTypeList = useSelector(getPalletTypeList);
  const productionLine = useSelector((state) => state.production.item);
  const gradeOut_fullWeight = watch('gradeOut_fullWeight');
  const gradeOut_binWeight = watch('gradeOut_binWeight');

  useEffect(() => {
    dispatch(loadProductionLine(id));
    dispatch(loadMachineList());
    dispatch(loadPalletTypeList());
    return () => {
      dispatch(resetProductionLineItem());
      dispatch(resetMachineList());
      dispatch(resetPalletTypeList());
      dispatch(resetProductionLinePallet());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (isEmpty(productionState.pallet)) return;
    if (productionState.palletLoading === true) return;
    if (isEmpty(productionState.item)) return;
    if (productionState.itemLoading === true) return;
    if (isEmpty(listMachineState.list)) return;
    if (listMachineState.loading === true) return;
    if (productionState.showPalletAddScan === true) return;

    if (productionState.pallet.lineId !== productionState.item.id) {
      const notification = {
        message: 'Pallet not in production line',
        options: {
          variant: 'error',
        },
      };
      dispatch(addNotification(notification));
      return;
    } else {
      dispatch(showPalletAddScan(true));
    }

    return () => {};
  }, [productionState, listMachineState, dispatch]);

  const handleScanner = useCallback(
    (event) => {
      if (event.code === 'Enter' && inputString.length >= 7) {
        dispatch(loadProductionLinePalletByCode(inputString, location));
        inputString = '';
      } else {
        if (allowedKeyCodes.indexOf(event.code) !== -1) {
          inputString += String.fromCharCode(event.keyCode);
        }
      }
    },
    [dispatch, location]
  );

  useEffect(() => {
    document.addEventListener('keyup', handleScanner);
    return () => {
      document.removeEventListener('keyup', handleScanner);
    };
  }, [handleScanner]);

  useEffect(() => {
    if (gradeOut_fullWeight !== undefined && gradeOut_binWeight !== undefined) {
      setValue(
        'gradeOut_productTotal',
        parseFloat(gradeOut_fullWeight) - parseFloat(gradeOut_binWeight)
      );
    }
  }, [gradeOut_fullWeight, gradeOut_binWeight, setValue]);

  const onUpdateProductionLine = (data) => {
    const updatedProductionLine = {
      gradeOut: {
        fullWeight: data.gradeOut_fullWeight,
        binWeight: data.gradeOut_binWeight,
      },
      cat2: {
        fullWeight: data.cat2_fullWeight,
        binWeight: data.cat2_binWeight,
        cases: data.cat2_cases,
      },
    };
    dispatch(
      updateProductionLine(productionState.item.id, updatedProductionLine)
    );
    navigate(`/epack/panel`);
  };

  const handleOpenAddScanDialog = () => {
    dispatch(showPalletAddScan(true));
  };

  const handleCloseAddScanDialog = () => {
    dispatch(showPalletAddScan(false));
    dispatch(resetProductionLinePallet());
  };

  const onSubmitAddScanForm = (palletData, weightData, weightType) => {
    if (isEmpty(productionState.pallet.pallet)) {
      dispatch(
        addProductionLinePallet(
          productionState.item.id,
          palletData,
          weightData,
          weightType
        )
      );
    } else {
      dispatch(
        updateProductionLinePalletWeight(
          productionState.item.id,
          productionState.pallet.pallet.id,
          weightData,
          weightType
        )
      );
    }
  };

  const handleOpenPhotoDialog = () => {
    setPhotoDialogOpen(true);
  };

  const handleClosePhotoDialog = () => {
    setPhotoDialogOpen(false);
  };

  const handleClickDeletePhoto = (photoId) => {
    setPhotoId(photoId);
    setOpenPhotoDeleteDialog(true);
  };

  const onPhotoDelete = () => {
    dispatch(deleteProductionLinePhoto(productionState.item.id, photoId));
    setPhotoId(null);
    setOpenPhotoDeleteDialog(false);
  };

  const handleOnSubmitPhotoForm = (data) => {
    const type = data.photoType;
    const formData = new FormData();
    formData.append('file', data.file);
    dispatch(addProductionLinePhoto(productionState.item.id, type, formData));
    setPhotoDialogOpen(false);
  };

  const handleClickDeletePallet = (pallet) => {
    setPalletId(pallet.id);
    setOpenPalletDeleteDialog(true);
  };

  const onPalletDelete = () => {
    dispatch(deleteProductionLinePallet(productionState.item.id, palletId));
    setOpenPalletDeleteDialog(false);
    setPalletId(null);
  };

  const handleClickAddPalletsToProductionLineFromWorkOrder = () => {
    setOpenAddPalletsFromWorkOrderDialog(true);
  };

  const onAddPalletsToProductionLineFromWorkOrder = () => {
    dispatch(addPalletsToProductionLineFromWorkOrder(productionState.item.id));
    setOpenAddPalletsFromWorkOrderDialog(false);
  };

  const handleClickMovePalletToIn = (pallet) => {
    setPallet(pallet);
    setOpenPalletToInDialog(true);
  };

  const handleClickMovePalletToOut = (pallet) => {
    setPallet(pallet);
    setOpenPalletToOutDialog(true);
  };

  const onMovePalletToIn = () => {
    dispatch(movePalletToIn(productionState.item.id, pallet.id));
    setOpenPalletToInDialog(false);
    setPallet(null);
  };

  const onMovePalletToOut = () => {
    dispatch(movePalletToOut(productionState.item.id, pallet.id));
    setOpenPalletToOutDialog(false);
    setPallet(null);
  };

  return (
    <>
      <Loading loading={productionState.loading} />
      {!isEmpty(productionState.item) && !productionState.loading && (
        <form>
          <Paper elevation={0}>
            <Box component='div' display='flex' mx={1} alignItems='center'>
              <Box
                display='flex'
                my={1.5}
                ml={1.5}
                flexGrow={1}
                alignItems='center'
              >
                <AssignmentIcon color='primary' />
                <Typography variant='h6' color='primary' sx={{ marginLeft: 1 }}>
                  ePack - Production Clipboard
                </Typography>
              </Box>
              <Box>
                <Tooltip title='Photo'>
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{ marginRight: '0.5rem' }}
                    startIcon={<PhotoCameraIcon />}
                    onClick={handleOpenPhotoDialog}
                  >
                    Photo
                  </Button>
                </Tooltip>
                <Tooltip title='Add Pallet'>
                  <Button
                    variant='outlined'
                    color='secondary'
                    sx={{ marginRight: '0.5rem' }}
                    startIcon={<PalletIcon />}
                    onClick={handleOpenAddScanDialog}
                  >
                    Add Pallet
                  </Button>
                </Tooltip>
                <Tooltip title='Add Pallets From W.O.'>
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{ marginRight: '0.5rem' }}
                    startIcon={<PalletIcon />}
                    onClick={handleClickAddPalletsToProductionLineFromWorkOrder}
                  >
                    Add Pallets From W.O.
                  </Button>
                </Tooltip>
                <TextField
                  id='scan-pallet'
                  label='Scan Pallet'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <ScanAddIcon />
                      </InputAdornment>
                    ),
                  }}
                  size='small'
                />
              </Box>
            </Box>
            <Divider variant='middle' light />
            <Box component='div' mx={1} my={0.5} mt={1.5}>
              <Box display='flex'>
                <Box>
                  <TextField
                    label='Machine'
                    fullWidth
                    disabled
                    value={productionLine.machine.name}
                  />
                </Box>
                <Box ml={1}>
                  <TextField
                    label='Work Order'
                    fullWidth
                    disabled
                    value={productionLine.workOrder.code}
                  />
                </Box>
                <Box ml={1}>
                  <TextField
                    label='Commodity'
                    fullWidth
                    disabled
                    value={productionLine.workOrder.commodity.name}
                  />
                </Box>
                <Box ml={1}>
                  <TextField
                    label='Pallet In'
                    disabled
                    value={PalletsIn.length}
                  />
                </Box>
                <Box ml={1}>
                  <TextField
                    label='Pallet Out'
                    disabled
                    value={PalletsOut.length}
                  />
                </Box>
                <Box ml={1}>
                  <TextField
                    label='Total Pallets'
                    disabled
                    value={productionLine.pallets.length}
                  />
                </Box>
              </Box>
            </Box>
            <Box component='div' mx={1} my={0.5}>
              <StyledAccordion
                defaultExpanded
                sx={{
                  '& .MuiAccordionDetails-root': {
                    padding: 0,
                  },
                  '& .MuiAccordionSummary-content': {
                    margin: 0,
                  },
                  '& .MuiAccordionSummary-root.Mui-expanded ': {
                    minHeight: '48px',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Box display='flex' alignItems='center'>
                    <PalletInIcon color='primary' />
                    <Typography
                      variant='h6'
                      color='primary'
                      sx={{ marginLeft: 1 }}
                    >
                      Inflow
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {isEmpty(PalletsIn) ? (
                    <Empty description='No pallets to show' size='small' />
                  ) : (
                    <ClipboardInflowTable
                      rows={PalletsIn}
                      handleClickDeletePallet={handleClickDeletePallet}
                      handleClickMovePalletToOut={handleClickMovePalletToOut}
                    />
                  )}
                </AccordionDetails>
              </StyledAccordion>
            </Box>
            <Box component='div' mx={1} my={0.5}>
              <StyledAccordion
                defaultExpanded
                sx={{
                  '& .MuiAccordionDetails-root': {
                    padding: 0,
                  },
                  '& .MuiAccordionSummary-content': {
                    margin: 0,
                  },
                  '& .MuiAccordionSummary-root.Mui-expanded ': {
                    minHeight: '48px',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Box display='flex' alignItems='center'>
                    <PalletOutIcon color='primary' />
                    <Typography
                      variant='h6'
                      color='primary'
                      sx={{ marginLeft: 1 }}
                    >
                      Outflow
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {isEmpty(PalletsOut) ? (
                    <Empty description='No pallets to show' size='small' />
                  ) : (
                    <ClipboardOutflowTable
                      rows={PalletsOut}
                      handleClickDeletePallet={handleClickDeletePallet}
                      handleClickMovePalletToIn={handleClickMovePalletToIn}
                    />
                  )}
                </AccordionDetails>
              </StyledAccordion>
            </Box>
            <Box component='div' mx={1} my={0.5} display='flex'>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Card
                    elevation={0}
                    variant='outlined'
                    sx={{
                      '& .MuiCardHeader-root': {
                        padding: 0,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingLeft: 1,
                      },
                    }}
                  >
                    <CardHeader
                      subheader={
                        <Typography variant='h6' color='primary'>
                          Cat 2
                        </Typography>
                      }
                    />
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Controller
                            name='cat2_fullWeight'
                            control={control}
                            defaultValue={
                              isEmpty(productionLine.cat2)
                                ? 0
                                : productionLine.cat2.fullWeight
                            }
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label='Full Pallet Weight (lbs)'
                                type='number'
                                fullWidth
                                error={!!errors?.cat2_fullWeight}
                                helperText={errors?.cat2_fullWeight?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name='cat2_binWeight'
                            control={control}
                            defaultValue={
                              isEmpty(productionLine.cat2)
                                ? 0
                                : productionLine.cat2.binWeight
                            }
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label='Pallet Weight (lbs)'
                                type='number'
                                fullWidth
                                error={!!errors?.cat2_binWeight}
                                helperText={errors?.cat2_binWeight?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name='cat2_cases'
                            control={control}
                            defaultValue={
                              isEmpty(productionLine.cat2)
                                ? 0
                                : productionLine.cat2.cases
                            }
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label='Number Of Cases'
                                type='number'
                                fullWidth
                                error={!!errors?.cat2_cases}
                                helperText={errors?.cat2_cases?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={6}>
                  <Card
                    elevation={0}
                    variant='outlined'
                    sx={{
                      '& .MuiCardHeader-root': {
                        padding: 0,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingLeft: 1,
                      },
                    }}
                  >
                    <CardHeader
                      subheader={
                        <Typography variant='h6' color='primary'>
                          Grade Out
                        </Typography>
                      }
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Controller
                            name='gradeOut_fullWeight'
                            control={control}
                            defaultValue={
                              isEmpty(productionLine.gradeOut)
                                ? 0
                                : productionLine.gradeOut.fullWeight
                            }
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label='Full Bin Weight (lbs)'
                                type='number'
                                fullWidth
                                error={!!errors?.gradeOut_fullWeight}
                                helperText={
                                  errors?.gradeOut_fullWeight?.message
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name='gradeOut_binWeight'
                            control={control}
                            defaultValue={
                              isEmpty(productionLine.gradeOut)
                                ? 0
                                : productionLine.gradeOut.binWeight
                            }
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label='Bin Weight (lbs)'
                                type='number'
                                fullWidth
                                error={!!errors?.gradeOut_binWeight}
                                helperText={errors?.gradeOut_binWeight?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name='gradeOut_productTotal'
                            control={control}
                            defaultValue={0}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label='Product Total'
                                type='number'
                                fullWidth
                                error={!!errors?.gradeOut_productTotal}
                                helperText={
                                  errors?.gradeOut_productTotal?.message
                                }
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <Box component='div' mx={1} my={0.5}>
              <Accordion
                elevation={0}
                variant='outlined'
                sx={{
                  '& .MuiAccordionSummary-content': {
                    margin: 0,
                  },
                  '& .MuiAccordionSummary-root.Mui-expanded ': {
                    minHeight: '48px',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography variant='h6' color='primary'>
                    Photos
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {!isEmpty(productionLine.photos) && (
                    <ClipboardPhotoList
                      photos={productionLine.photos}
                      handleClickDelete={handleClickDeletePhoto}
                    />
                  )}
                  {isEmpty(productionLine.photos) &&
                    !productionState.loading && (
                      <Empty description='There are no photos to show' />
                    )}
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box component='div' mx={1} my={0.5} display='flex'>
              <Box my={1.5} flexGrow={1}>
                <Button
                  variant='contained'
                  color='secondary'
                  size='large'
                  onClick={handleSubmit(onUpdateProductionLine)}
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </Box>
              <Box my={1.5}>
                <Button
                  variant='outlined'
                  size='large'
                  startIcon={<UndoIcon />}
                  onClick={() => {
                    dispatch(resetProductionLinePallet());
                    navigate(`/epack/panel`);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Paper>
        </form>
      )}

      <Dialog
        fullScreen
        open={productionState.showPalletAddScan}
        TransitionComponent={Transition}
      >
        <Loading loading={productionState.palletLoading} />
        <Loading loading={listMachineState.loading} />
        {!isEmpty(productionState.pallet) &&
        isEmpty(productionState.item) &&
        isEmpty(listMachineState.list) ? null : (
          <PalletAddScanForm
            pallet={productionState.pallet.pallet}
            productionLine={productionState.item}
            machines={MachineList}
            palletTypes={PalletTypeList}
            handleOnCancel={handleCloseAddScanDialog}
            handleOnSubmit={onSubmitAddScanForm}
          />
        )}
      </Dialog>
      <Dialog
        open={photoDialogOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClosePhotoDialog();
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <ProductionPhotoForm
            productionLine={productionLine}
            palletsIn={PalletsIn.length}
            palletsOut={PalletsOut.length}
            handleOnCancel={handleClosePhotoDialog}
            handleOnSubmit={handleOnSubmitPhotoForm}
          />
        </DialogContent>
      </Dialog>
      <ConfirmationDialod
        open={openPalletDeleteDialog}
        handleNo={() => {
          setOpenPalletDeleteDialog(false);
        }}
        handleYes={onPalletDelete}
        title='ePack - Delete Pallet'
        YesText={'Yes, Delete'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to delete this pallet?`}
        </Typography>
      </ConfirmationDialod>
      <ConfirmationDialod
        open={openPhotoDeleteDialog}
        handleNo={() => {
          setOpenPhotoDeleteDialog(false);
        }}
        handleYes={onPhotoDelete}
        title='ePack - Delete Photo'
        YesText={'Yes, Delete'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to delete this photo?`}
        </Typography>
      </ConfirmationDialod>
      <ConfirmationDialod
        open={openAddPalletsFromWorkOrderDialog}
        handleNo={() => {
          setOpenAddPalletsFromWorkOrderDialog(false);
        }}
        handleYes={onAddPalletsToProductionLineFromWorkOrder}
        title='ePack - Add Pallets from Work Order'
        YesText={'Yes, Add Pallets'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          Do you want to add all pallets from the work order?
        </Typography>
      </ConfirmationDialod>

      <ConfirmationDialod
        open={openMovePalletToInDialog}
        handleNo={() => {
          setOpenPalletToInDialog(false);
          setPallet(null);
        }}
        handleYes={onMovePalletToIn}
        title='ePack - Move to Pallets In'
        YesText={'Yes, Move'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to pallet: ${pallet?.code} to pallets in?`}
        </Typography>
      </ConfirmationDialod>

      <ConfirmationDialod
        open={openMovePalletToOutDialog}
        handleNo={() => {
          setOpenPalletToOutDialog(false);
          setPallet(null);
        }}
        handleYes={onMovePalletToOut}
        title='ePack - Move to Pallets Out'
        YesText={'Yes, Move'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to pallet: ${pallet?.code} to pallets out?`}
        </Typography>
      </ConfirmationDialod>
    </>
  );
};
export default ProductionClipboard;
