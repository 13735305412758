import { createSelector } from 'reselect';
import isEmpty from 'is-empty';

export const getEmployeePositionList = createSelector(
  (state) => state.list_employee_position.list,
  (employeePositionList) => {
    if (isEmpty(employeePositionList)) return [];
    return employeePositionList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
);
