import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Paper, Typography } from '@mui/material';
import BadgeIcon from '@mui/icons-material/Badge';
import PanToolIcon from '@mui/icons-material/PanTool';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CommodityIcon from '../UI/icons/CommodityIcon';
import MachineIcon from '../UI/icons/MachineIcon';
import BuildIcon from '@mui/icons-material/Build';
import EmployeePhoto from './EmployeePhoto';
import truncateString from '../../utils/truncateString';

const LaborLogDetails = ({
  laborLog,
  workOrderCode,
  commodityName,
  machineName,
  jobTypeName,
}) => {
  return (
    <>
      <Paper elevation={0}>
        <Box
          component="div"
          display="flex"
          justifyContent="center"
          alignItems={'center'}
        >
          <Box sx={{ margin: 5 }}>
            <EmployeePhoto photo={laborLog.photo} size="large" />
          </Box>
          <Box flexGrow={1}>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={2}>
                <BadgeIcon color="primary" />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body1">
                  {truncateString(
                    laborLog.lastName + ' ' + laborLog.firstName,
                    {
                      length: 25,
                      ending: '...',
                    }
                  )}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <PanToolIcon color="primary" />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body1">
                  {truncateString(laborLog.employeePosition.name)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <ListAltIcon color="primary" />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body1">{workOrderCode}</Typography>
              </Grid>

              <Grid item xs={2}>
                <CommodityIcon color="primary" />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body1">{commodityName}</Typography>
              </Grid>

              <Grid item xs={2}>
                <MachineIcon color="primary" />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body1">{machineName}</Typography>
              </Grid>
              <Grid item xs={2}>
                <BuildIcon color="primary" />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body1">{jobTypeName}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

LaborLogDetails.propTypes = {
  laborLog: PropTypes.object.isRequired,
  workOrderCode: PropTypes.string.isRequired,
  commodityName: PropTypes.string.isRequired,
  machineName: PropTypes.string.isRequired,
  jobTypeName: PropTypes.string.isRequired,
};

export default LaborLogDetails;
