import React from 'react';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { Box, IconButton, Tooltip } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import { styled } from '@mui/system';
import moment from 'moment';

const DataGrid = styled(MuiDataGrid)({
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
});

const LaborLogsTable = ({ rows, handleClickAdjust }) => {
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      hideable: false,
      editable: false,
      valueGetter: (params) => params.row.firstName + ' ' + params.row.lastName,
    },
    {
      field: 'position',
      headerName: 'Position',
      width: 120,
      editable: false,
    },
    {
      field: 'job',
      headerName: 'Job',
      width: 90,
      editable: false,
    },
    {
      field: 'employer',
      headerName: 'Employer',
      width: 100,
      editable: false,
    },
    {
      field: 'start',
      headerName: 'Start',
      width: 170,
      editable: false,
      valueGetter: (params) =>
        moment(params.row.dateStart).format('MM/DD/YYYY hh:mm A'),
    },
    {
      field: 'stop',
      headerName: 'Stop',
      width: 170,
      editable: false,
      valueGetter: (params) =>
        moment(params.row.dateStop).format('MM/DD/YYYY hh:mm A'),
    },
    {
      field: 'duration',
      headerName: 'Duration',
      width: 100,
      editable: false,
      valueGetter: (params) =>
        params.row.duration.hours + 'h ' + params.row.duration.minutes + 'm',
    },
    {
      field: 'adjustedBy',
      headerName: 'Adj. By',
      width: 110,
      editable: false,
    },
    {
      field: 'adjustedDuration',
      headerName: 'Adj. Duration',
      width: 100,
      editable: false,
      valueGetter: (params) =>
        params.row.adjustedDuration.hours +
        'h ' +
        params.row.adjustedDuration.minutes +
        'm',
    },
    {
      field: 'actions',
      width: 50,
      type: 'actions',
      hideable: false,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <Box>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => {
                  handleClickAdjust(cellValues.row);
                }}
                color='primary'
              >
                <BuildIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <Box mx={1} my={1} sx={{ height: '600px', width: '98%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default LaborLogsTable;
