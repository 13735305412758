import {
  SET_COMPANY_LIST,
  LIST_COMPANY_LIST_LOADING,
  LIST_COMPANY_LIST_RESET,
} from './types';

export const setCompanyList = (list) => {
  return {
    type: SET_COMPANY_LIST,
    payload: list,
  };
};

export const resetCompanyList = () => {
  return {
    type: LIST_COMPANY_LIST_RESET,
  };
};

export const setCompanyListLoading = (payload) => {
  return {
    type: LIST_COMPANY_LIST_LOADING,
    payload,
  };
};
