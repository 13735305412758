import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetSummaryList } from '../../redux/etime/labor/summary/actions';
import { loadSummaryList } from '../../redux/etime/labor/summary/services';
import { getSummaryList } from '../../redux/etime/labor/summary/selectors';
import { Box } from '@mui/material';
import LaborSummaryTileList from '../../components/etime/LaborSummaryTileList';
import Empty from '../../components/UI/Empty';
import Loading from '../../components/UI/Loading';
const isEmpty = require('is-empty');

const LaborSummary = () => {
  const dispatch = useDispatch();
  const summaryViewState = useSelector((state) => state.labor_summary);
  const SummaryList = useSelector(getSummaryList);

  useEffect(() => {
    dispatch(loadSummaryList());
    return () => {
      dispatch(resetSummaryList());
    };
  }, [dispatch]);

  return (
    <>
      <Loading loading={summaryViewState.loading} />
      <Box my={1}>
        <Box my={0} display="flex" flexDirection="row">
          <Box flexGrow={1} mx={1}>
            {!isEmpty(summaryViewState.list) && (
              <LaborSummaryTileList list={SummaryList} />
            )}
            {isEmpty(SummaryList) && !summaryViewState.loading && (
              <Empty description="There are no work orders to show" />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LaborSummary;
