import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../redux/system/auth/services';
import { Box, Paper, Typography } from '@mui/material';
import ResetPasswordForm from '../../components/auth/ResetPasswordForm';
import isEmpty from 'is-empty';

const ActivateUser = () => {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const token = params.get('token');

  const onSubmit = (data) => {
    dispatch(resetPassword(token, data, navigate));
  };

  if (isEmpty(token)) {
    navigate('/');
  }

  return (
    <>
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box my={0.5} flexGrow={1}>
            <Typography variant='subtitle1'>
              Please enter your new credentials to access you eHawk account
            </Typography>
          </Box>
        </Box>
        <Box display='flex' justifyContent='center'>
          <Box sx={{ maxWidth: '450px' }}>
            <ResetPasswordForm
              handleOnSubmit={onSubmit}
              actionButtonTitle={'Activate Account'}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default ActivateUser;
