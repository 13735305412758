import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

const DashboardNumericWidgetWithIcon = ({
  children,
  title,
  titleColor = [grey[700]],
  numericValue,
  numericValueColor = [grey[700]],
  backgroundImage,
}) => {
  return (
    <Card variant='outlined' sx={{ backgroundImage }}>
      <CardContent
        sx={{
          padding: 1,
          '&:last-child': {
            paddingBottom: 0.8,
          },
        }}
      >
        <Box
          sx={(theme) => ({
            marginLeft: 0,
            [theme.breakpoints.up('lg')]: {
              marginLeft: 1,
            },
          })}
        >
          <Typography
            sx={{
              fontSize: '21px',
              fontWeight: 'medium',
              color: titleColor,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          mt={1}
          sx={(theme) => ({
            marginLeft: 0,
            [theme.breakpoints.up('lg')]: {
              marginLeft: 1,
            },
          })}
        >
          <Typography
            sx={{
              fontSize: 45,
              fontWeight: 'bold',
              lineHeight: 1,
              color: numericValueColor,
            }}
          >
            {children}
            {numericValue}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DashboardNumericWidgetWithIcon;
