import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetWorkOrderViewList } from '../../redux/etime/labor/work-order-view/actions';
import {
  loadWorkOrderViewList,
  startByWorkOrder,
  stopByWorkOrder,
  startLaborLog,
  stopLaborLog,
  changePosition,
  moveLaborLog,
  moveByWorkOrder,
  walkOff,
} from '../../redux/etime/labor/work-order-view/services';
import { getWorkOrderViewList } from '../../redux/etime/labor/work-order-view/selectors';
import { loadWorkOrderList } from '../../redux/list/work-orders/services';
import { resetListWorkOrderList } from '../../redux/list/work-orders/actions';
import { getListWorkOrderList } from '../../redux/list/work-orders/selectors';
import { loadMachineList } from '../../redux/list/machines/services';
import { resetMachineList } from '../../redux/list/machines/actions';
import { getMachineList } from '../../redux/list/machines/selectors';
import { loadJobTypeList } from '../../redux/list/job-types/services';
import { resetJobTypeList } from '../../redux/list/job-types/actions';
import { getJobTypeList } from '../../redux/list/job-types/selectors';
import { loadEmployeePositionList } from '../../redux/list/employee-positions/services';
import { resetEmployeePositionList } from '../../redux/list/employee-positions/actions';
import { getEmployeePositionList } from '../../redux/list/employee-positions/selectors';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import LaborWorkOrderViewTileList from '../../components/etime/LaborWorkOrderViewTileList';
import Empty from '../../components/UI/Empty';
import Loading from '../../components/UI/Loading';
import LaborLogPositionForm from '../../components/etime/LaborLogPositionForm';
import LaborLogMoveForm from '../../components/etime/LaborLogMoveForm';
import WorkOrderMoveForm from '../../components/etime/WorkOrderMoveForm';
import LaborLogWalkOffForm from '../../components/etime/LaborLogWalkOffForm';
const isEmpty = require('is-empty');

const LaborWorkOrderView = () => {
  const dispatch = useDispatch();
  const [expandedWorkOrderTiles, setExpandedWorkOrderTiles] = useState([]);
  const [openChangeLaborLogPosition, setOpenChangeLaborLogPosition] =
    useState(false);
  const [openMoveLaborLog, setOpenMoveLaborLog] = useState(false);
  const [openMoveByWorkOrder, setOpenMoveByWorkOrder] = useState(false);
  const [openWalkOff, setOpenWalkOff] = useState(false);
  const [workOrder, setWorkOrder] = useState(null);
  const [laborLog, setLaborLog] = useState(null);
  const workOrderViewState = useSelector(
    (state) => state.labor_work_order_view
  );
  const WorkOrderViewList = useSelector(getWorkOrderViewList);
  const WorkOrderList = useSelector(getListWorkOrderList);
  const MachineList = useSelector(getMachineList);
  const JobTypeList = useSelector(getJobTypeList);
  const EmployeePositionList = useSelector(getEmployeePositionList);

  useEffect(() => {
    dispatch(loadWorkOrderViewList());
    dispatch(loadWorkOrderList());
    dispatch(loadMachineList());
    dispatch(loadJobTypeList());
    dispatch(loadEmployeePositionList());
    return () => {
      dispatch(resetWorkOrderViewList());
      dispatch(resetListWorkOrderList());
      dispatch(resetMachineList());
      dispatch(resetJobTypeList());
      dispatch(resetEmployeePositionList());
    };
  }, [dispatch]);

  useEffect(() => {
    setExpandedWorkOrderTiles([
      ...new Set(WorkOrderViewList.map((item) => item.id)),
    ]);
  }, [WorkOrderViewList]);

  const handleClickStartLaborLog = (id) => {
    dispatch(startLaborLog(id));
  };

  const handleClickStopLaborLog = (id) => {
    dispatch(stopLaborLog(id));
  };

  const handleClickStartByWorkOrder = (id) => {
    dispatch(startByWorkOrder(id));
  };

  const handleClickStopByWorkOrder = (id) => {
    dispatch(stopByWorkOrder(id));
  };

  const handleClickMoveLaborLog = (laborLog, workOrder) => {
    setLaborLog(laborLog);
    setWorkOrder(workOrder);
    setOpenMoveLaborLog(true);
  };
  const onMoveLaborLog = (data) => {
    dispatch(moveLaborLog(data));
    setOpenMoveLaborLog(false);
  };
  const handleClickMoveByWorkOrder = (workOrder) => {
    setWorkOrder(workOrder);
    setOpenMoveByWorkOrder(true);
  };
  const onMoveByWorkOrder = (data) => {
    dispatch(moveByWorkOrder(workOrder.id, data));
    setOpenMoveByWorkOrder(false);
  };
  const onChangePosition = (data) => {
    dispatch(changePosition(data));
    setOpenChangeLaborLogPosition(false);
  };
  const handleClickChangePosition = (laborLog, workOrder) => {
    setLaborLog(laborLog);
    setWorkOrder(workOrder);
    setOpenChangeLaborLogPosition(true);
  };

  const handleClickWalkOff = (laborLog, workOrder) => {
    setLaborLog(laborLog);
    setWorkOrder(workOrder);
    setOpenWalkOff(true);
  };
  const onWalkOff = (data) => {
    dispatch(walkOff(workOrder.id, data));
    setOpenWalkOff(false);
  };

  return (
    <>
      <Loading loading={workOrderViewState.loading} />
      <Box my={1}>
        <Box my={0} display='flex' flexDirection='row'>
          <Box flexGrow={1} mx={1}>
            {!isEmpty(workOrderViewState.list) && (
              <LaborWorkOrderViewTileList
                list={WorkOrderViewList}
                expandedWorkOrderTiles={expandedWorkOrderTiles}
                setExpandedWorkOrderTiles={setExpandedWorkOrderTiles}
                handleClickStartLaborLog={handleClickStartLaborLog}
                handleClickStopLaborLog={handleClickStopLaborLog}
                handleClickStartByWorkOrder={handleClickStartByWorkOrder}
                handleClickStopByWorkOrder={handleClickStopByWorkOrder}
                handleClickMoveLaborLog={handleClickMoveLaborLog}
                handleClickMoveByWorkOrder={handleClickMoveByWorkOrder}
                handleClickChangePosition={handleClickChangePosition}
                handleClickWalkOff={handleClickWalkOff}
              />
            )}
            {isEmpty(WorkOrderViewList) && !workOrderViewState.loading && (
              <Empty description='There are no work orders to show' />
            )}
          </Box>
        </Box>
      </Box>

      <Dialog
        open={openChangeLaborLogPosition}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenChangeLaborLogPosition(false);
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <LaborLogPositionForm
            laborLog={laborLog}
            workOrder={workOrder}
            machine={laborLog?.machine}
            jobType={laborLog?.jobType}
            employeePositions={EmployeePositionList}
            handleOnSubmit={onChangePosition}
            handleOnCancel={() => setOpenChangeLaborLogPosition(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openMoveLaborLog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenMoveLaborLog(false);
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <LaborLogMoveForm
            laborLog={laborLog}
            workOrder={workOrder}
            machine={laborLog?.machine}
            jobType={laborLog?.jobType}
            workOrderList={WorkOrderList}
            machineList={MachineList}
            jobTypeList={JobTypeList}
            handleOnSubmit={onMoveLaborLog}
            handleOnCancel={() => setOpenMoveLaborLog(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openMoveByWorkOrder}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenMoveByWorkOrder(false);
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <Typography variant='body2'>{`Are you sure you want to MOVE employees from Work Order: ${workOrder?.code} and Commodity: ${workOrder?.commodity} to a new work order?, If yes please select a new work order below.`}</Typography>
          <WorkOrderMoveForm
            workOrder={workOrder}
            machine={workOrder?.machine}
            jobType={workOrder?.jobType}
            workOrderList={WorkOrderList}
            machineList={MachineList}
            jobTypeList={JobTypeList}
            handleOnSubmit={onMoveByWorkOrder}
            handleOnCancel={() => setOpenMoveByWorkOrder(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openWalkOff}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenWalkOff(false);
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <LaborLogWalkOffForm
            laborLog={laborLog}
            workOrder={workOrder}
            machine={laborLog?.machine}
            jobType={laborLog?.jobType}
            handleOnSubmit={onWalkOff}
            handleOnCancel={() => setOpenWalkOff(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LaborWorkOrderView;
