import {
  SET_MACHINE_TYPE_LIST,
  MACHINE_TYPE_LIST_LOADING,
  MACHINE_TYPE_LIST_RESET,
} from './types';

export const setMachineTypeList = (list) => {
  return {
    type: SET_MACHINE_TYPE_LIST,
    payload: list,
  };
};

export const resetMachineTypeList = () => {
  return {
    type: MACHINE_TYPE_LIST_RESET,
  };
};

export const setMachineTypeListLoading = (payload) => {
  return {
    type: MACHINE_TYPE_LIST_LOADING,
    payload,
  };
};
