import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  AppBar,
  Toolbar,
  IconButton,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  Paper,
  TextField,
  List,
  ListItem,
  ListItemText,
  Grid,
} from '@mui/material';
import ProductionLineStatus from './ProductionLineStatus';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CommodityIcon from '../UI/icons/CommodityIcon';
import MachineIcon from '../UI/icons/MachineIcon';
import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteIcon from '@mui/icons-material/Delete';
import grey from '@mui/material/colors/grey';
import isEmpty from 'is-empty';
import Empty from '../UI/Empty';
import TabPanel from '../UI/TabPanel';

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ValidationSchema = Yup.object().shape({
  test: Yup.string().required('Required'),
  results: Yup.string().required('Required'),
});

const ProductionLineSanitation = ({
  productionLine,
  onUpdateSanitation,
  handleOnCancel,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });
  const [activities, setActivities] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activityInput, setActivityInput] = useState('');

  useEffect(() => {
    if (!isEmpty(productionLine.sanitation?.activities)) {
      setActivities(productionLine.sanitation.activities);
    }
  }, [productionLine]);

  const handleAddActivity = () => {
    setActivities([...activities, activityInput]);
    setActivityInput('');
  };

  const handleOnSubmit = (data) => {
    const sanitationData = {
      test: data.test,
      results: data.results,
      activities: activities,
    };
    onUpdateSanitation(productionLine.id, sanitationData);
    reset();
  };

  const handleChangeTab = (event, newTab) => {
    setActiveTab(newTab);
  };

  const handleActivityInputChange = (event) => {
    setActivityInput(event.target.value);
  };

  const handleDeleteActivity = (id) => {
    setActivities(activities.filter((activity) => activity !== id));
  };

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <AppBar color='primary' elevation={0} sx={{ position: 'relative' }}>
        <Toolbar variant='dense'>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleOnCancel}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            ePack - Sanitation Log
          </Typography>
        </Toolbar>
      </AppBar>

      <Box my={0.5} mx={1.5} mt={1.5} display='flex' flexDirection='column'>
        <Box>
          <Paper elevation={0} variant='outlined'>
            <Box display='flex' py={2} justifyContent='space-evenly'>
              <Box display='flex' alignItems='center'>
                <MachineIcon
                  sx={{
                    color: grey['500'],
                    width: '18px',
                    height: '18px',
                  }}
                />
                <Typography
                  component='span'
                  sx={{
                    marginLeft: 1,
                    fontSize: '1.3rem',
                  }}
                >
                  {productionLine.machine.name}
                </Typography>
              </Box>
              <Box display='flex' alignItems='center' ml={1}>
                <ListAltIcon
                  sx={{
                    color: grey['500'],
                    width: '18px',
                    height: '18px',
                  }}
                />
                <Typography
                  component='span'
                  sx={{
                    marginLeft: 1,
                    fontSize: '1.3rem',
                  }}
                >
                  {productionLine.workOrder.code}
                </Typography>
              </Box>
              <Box
                display='flex'
                alignItems='center'
                sx={{
                  marginLeft: '1',
                  fontSize: '1.3rem',
                }}
              >
                <CommodityIcon
                  sx={{
                    color: grey['500'],
                    width: '18px',
                    height: '18px',
                  }}
                />
                <Typography
                  component='span'
                  sx={{
                    marginLeft: 1,
                    fontSize: '1.3rem',
                  }}
                >
                  {productionLine.workOrder.commodity.name}
                </Typography>
              </Box>
              <Box
                display='flex'
                alignItems='center'
                sx={{
                  marginLeft: '1',
                  fontSize: '1.3rem',
                }}
              >
                <Typography
                  component='span'
                  sx={{
                    marginLeft: 1,
                    fontSize: '1.3rem',
                  }}
                >
                  <ProductionLineStatus status={productionLine.status} />
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            variant='fullWidth'
          >
            <Tab label='Activities' {...a11yProps(0)} />
            <Tab label='Test & Results' {...a11yProps(1)} />
          </Tabs>
        </Box>

        <Box display='flex' flexDirection='row' justifyContent='center'>
          <TabPanel value={activeTab} index={0} sx={{ display: 'flex' }}>
            {!isEmpty(activities) ? (
              <Paper elevation={0} variant='outlined' sx={{ marginTop: 1 }}>
                <List>
                  {activities.map((activity, index) => {
                    return (
                      <ListItem
                        key={index}
                        role={undefined}
                        dense
                        secondaryAction={
                          <IconButton
                            edge='end'
                            aria-label='delete'
                            onClick={() => handleDeleteActivity(activity)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <ListItemText primary={`${index + 1} - ${activity}`} />
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            ) : (
              <Empty description='No activities to display' />
            )}
            <Box display='flex' justifyContent='center' mt={2}>
              <Box>
                <TextField
                  label='New activity'
                  value={activityInput}
                  onChange={handleActivityInputChange}
                />
              </Box>
              <Box my={1} ml={1}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleAddActivity}
                  startIcon={<DoneIcon />}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <Grid container spacing={3} sx={{ marginTop: 1 }}>
              <Grid item xs={12}>
                <Controller
                  name='test'
                  control={control}
                  defaultValue={
                    !isEmpty(productionLine.sanitation)
                      ? productionLine.sanitation.test
                      : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label='Test'
                      error={!!errors?.test}
                      helperText={errors?.test?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name='results'
                  control={control}
                  defaultValue={
                    !isEmpty(productionLine.sanitation)
                      ? productionLine.sanitation.results
                      : ''
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      multiline
                      rows={4}
                      label='Results'
                      error={!!errors?.results}
                      helperText={errors?.results?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
        <Box mx={1} my={0.5} display='flex'>
          <Box my={1.5} flexGrow={1}>
            <Button
              variant='contained'
              color='secondary'
              size='large'
              type='submit'
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Box>
          <Box my={1.5}>
            <Button
              variant='outlined'
              size='large'
              startIcon={<UndoIcon />}
              onClick={() => {
                reset();
                handleOnCancel();
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default ProductionLineSanitation;
