export const WORK_ORDER_LOADING = 'WORK_ORDER_LOADING';
export const SET_WORK_ORDER = 'SET_WORK_ORDER';
export const WORK_ORDER_RESET = 'WORK_ORDER_RESET';
export const SET_WORK_ORDERS = 'SET_WORK_ORDERS';
export const WORK_ORDERS_LIST_LOADING = 'WORK_ORDERS_LIST_LOADING';
export const WORK_ORDERS_LIST_RESET = 'WORK_ORDERS_LIST_RESET';
export const ADD_WORK_ORDER_TO_LIST = 'ADD_WORK_ORDER_TO_LIST';
export const UPDATE_WORK_ORDER_IN_LIST = 'UPDATE_WORK_ORDER_IN_LIST';
export const DELETE_WORK_ORDER_FROM_LIST = 'DELETE_WORK_ORDER_FROM_LIST';
export const DELETE_PALLET_FROM_WORK_ORDER_LIST =
  'DELETE_PALLET_FROM_WORK_ORDER_LIST';
export const SHOW_ADD_EDIT_WORK_ORDER_FORM = 'SHOW_ADD_EDIT_WORK_ORDER_FORM';
export const SAVING_WORK_ORDER_DATA = 'SAVING_WORK_ORDER_DATA';
export const SHOW_IMPORT_WORK_ORDERS_FORM = 'SHOW_IMPORT_WORK_ORDERS_FORM';
export const IMPORTING_WORK_ORDERS_DATA = 'IMPORTING_WORK_ORDERS_DATA';
