import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setPalletType,
  resetPalletType,
  resetPalletTypeList,
  showAddEditPalletTypeForm,
} from '../../redux/company/pallet-types/actions';
import {
  loadPalletTypes,
  createPalletType,
  updatePalletType,
  deletePalletType,
  togglePalletTypeStatus,
} from '../../redux/company/pallet-types/services';
import { getPalletTypeList } from '../../redux/company/pallet-types/selectors';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Paper,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import Loading from '../../components/UI/Loading';
import PalletTypeForm from '../../components/company/PalletTypeForm';
import Empty from '../../components/UI/Empty';
import PalletTypesTable from '../../components/company/PalletTypesTable';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
const isEmpty = require('is-empty');

const PalletTypes = () => {
  const dispatch = useDispatch();
  const palletTypesState = useSelector((state) => state.pallet_types);
  const palletTypeState = useSelector((state) => state.pallet_types.item);
  const PalletTypesList = useSelector(getPalletTypeList);
  const [editMode, setEditMode] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [openRemovePalletTypeDialog, setOpenRemovePalletTypeDialog] =
    useState(false);

  useEffect(() => {
    dispatch(loadPalletTypes());
    return () => {
      dispatch(resetPalletTypeList());
    };
  }, [dispatch]);

  const handleClickAdd = () => {
    setEditMode(false);
    dispatch(showAddEditPalletTypeForm(true));
  };

  const handleClickEdit = (palletType) => {
    setEditMode(true);
    dispatch(setPalletType(palletType));
    dispatch(showAddEditPalletTypeForm(true));
  };

  const onSubmitAddEdit = (data) => {
    if (editMode) {
      dispatch(updatePalletType(palletTypeState.id, data));
    } else {
      dispatch(createPalletType(data));
    }
  };

  const handleRefresh = () => {
    dispatch(loadPalletTypes());
  };

  const handleClickRemove = (palletType) => {
    dispatch(setPalletType(palletType));
    setOpenRemovePalletTypeDialog(true);
  };

  const onRemovePalletType = () => {
    dispatch(deletePalletType(palletTypeState.id));
    dispatch(resetPalletType());
    setOpenRemovePalletTypeDialog(false);
  };

  const handleClickToogleStatus = (palletType) => {
    dispatch(setPalletType(palletType));
    setOpenStatusDialog(true);
  };

  const onToggleStatus = () => {
    dispatch(
      togglePalletTypeStatus(palletTypeState.id, palletTypeState.active)
    );
    dispatch(resetPalletType());
    setOpenStatusDialog(false);
  };

  return (
    <>
      <Loading loading={palletTypesState.loading} />
      <Paper elevation={0}>
        <Box component='div' display='flex' mx={1} mt={1}>
          <Box display='flex' alignContent='center'>
            <IconButton aria-label='reload' onClick={handleRefresh}>
              <SyncIcon />
            </IconButton>
          </Box>
          <Box my={0.5} ml={1.5} flexGrow={1}>
            <Typography variant='h6' color='primary'>
              Pallet Types
            </Typography>
          </Box>
          <Tooltip title='Add Pallet Type'>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<AddIcon />}
              sx={{ marginRight: '0.5rem' }}
              onClick={handleClickAdd}
            >
              Add
            </Button>
          </Tooltip>
        </Box>
        <Box sx={{ width: '100%' }}>
          {!isEmpty(palletTypesState.list) && (
            <PalletTypesTable
              rows={PalletTypesList}
              handleClickEdit={handleClickEdit}
              handleClickRemove={handleClickRemove}
              handleClickToogleStatus={handleClickToogleStatus}
            />
          )}
          {isEmpty(palletTypesState.list) && !palletTypesState.loading && (
            <Empty description='There are no pallet types to show' />
          )}
        </Box>
      </Paper>
      <ConfirmationDialog
        open={openRemovePalletTypeDialog}
        handleNo={() => {
          setOpenRemovePalletTypeDialog(false);
        }}
        handleYes={onRemovePalletType}
        title='ePack - Remove Pallet Type'
        YesText={'Yes, Remove'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to remove pallet type: ${palletTypeState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openStatusDialog}
        handleNo={() => {
          setOpenStatusDialog(false);
        }}
        handleYes={onToggleStatus}
        title='ePack - Employee Position Status'
        YesText={'Yes, Change'}
        NoText={'No'}
      >
        <Typography variant='body1'>
          {`Are you sure you want to ${
            palletTypeState?.active ? 'deactivate' : 'activate'
          } pallet type: ${palletTypeState?.name}?`}
        </Typography>
      </ConfirmationDialog>

      <Dialog
        open={palletTypesState.showAddEditForm}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setEditMode(false);
            dispatch(resetPalletType());
            dispatch(showAddEditPalletTypeForm(false));
          }
        }}
        disableEscapeKeyDown
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <PalletTypeForm
            editMode={editMode}
            palletType={palletTypeState}
            handleOnCancel={() => {
              setEditMode(false);
              dispatch(resetPalletType());
              dispatch(showAddEditPalletTypeForm(false));
            }}
            handleOnSubmit={onSubmitAddEdit}
            savingData={palletTypesState.savingData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PalletTypes;
