export const SET_COMPANY = 'SET_COMPANY';
export const COMPANY_LOADING = 'COMPANY_LOADING';
export const COMPANY_RESET = 'COMPANY_RESET';
export const SET_COMPANIES = 'SET_COMPANIES';
export const COMPANY_LIST_LOADING = 'COMPANY_LIST_LOADING';
export const COMPANY_LIST_RESET = 'COMPANY_LIST_RESET';
export const ADD_COMPANY_TO_LIST = 'ADD_COMPANY_TO_LIST';
export const UPDATE_COMPANY_IN_LIST = 'UPDATE_COMPANY_IN_LIST';
export const DELETE_COMPANY_FROM_LIST = 'DELETE_COMPANY_FROM_LIST';
export const SHOW_ADD_EDIT_COMPANY_FORM = 'SHOW_ADD_EDIT_COMPANY_FORM';
export const SAVING_COMPANY_DATA = 'SAVING_COMPANY_DATA';
export const SET_PRIVATE_TOKEN = 'SET_PRIVATE_TOKEN';
export const PRIVATE_TOKEN_LOADING = 'PRIVATE_TOKEN_LOADING';
export const SET_COMPANY_NAME_FILTER = 'SET_COMPANY_NAME_FILTER';
export const SET_COMPANY_IS_ACTIVE_FILTER = 'SET_COMPANY_IS_ACTIVE_FILTER';
export const SET_COMPANY_NEED_APPROVAL_FILTER =
  'SET_COMPANY_NEED_APPROVAL_FILTER';
